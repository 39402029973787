import { type Decimal } from '@x10/lib-core/utils'

import type { MarketStats } from '@src/domain/api/x10'
import type { OrderSide } from '@src/domain/api/x10/common'

import { calcMarketOrderPrice } from '../../utils/calc/calc-market-order-price'

export const getMarketPrice = (
  orderSide: OrderSide,
  marketStats: Pick<MarketStats, 'askPrice' | 'bidPrice'>,
) => {
  return orderSide === 'BUY' ? marketStats.askPrice : marketStats.bidPrice
}

export const getMarketPriceWithSlippage = (
  orderSide: OrderSide,
  marketStats: Pick<MarketStats, 'askPrice' | 'bidPrice'>,
  minPriceChange: Decimal,
) => {
  const marketPrice = getMarketPrice(orderSide, marketStats)

  return calcMarketOrderPrice(orderSide, marketPrice, minPriceChange)
}

export const checkMarketLiquidity = (
  orderSide: OrderSide,
  marketStats: Pick<MarketStats, 'askPrice' | 'bidPrice'>,
) => {
  const marketPrice = getMarketPrice(orderSide, marketStats)

  return marketPrice.gt(0)
}
