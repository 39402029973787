import { useCallback, useMemo } from 'react'

import { checkRequired, invariant, type Decimal } from '@x10/lib-core/utils'

import { useFetchMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import { useSubscribeToCandles } from '@src/domain/api/hooks/stream/use-subscribe-to-candles'
import type { MarketStats } from '@src/domain/api/x10'
import type { CandlePriceSource } from '@src/domain/api/x10/common'
import type { Candle } from '@src/domain/api/x10/stream/candles.schema'
import { getCandles } from '@src/domain/api/x10/trading/markets-info/candles'
import { useLatestTradesStore } from '@src/domain/trade/store/latest-trades'
import { type Bar, type ResolutionString } from '@src/types/charting-library'

import type { TradingViewTicker } from '../../types/common'
import { DataFeed, type FetchBars } from '../../utils/data-feed'
import { parseTradingViewTicker } from '../../utils/data-feed/utils'
import { toX10Interval } from '../../utils/trading-view-chart-api/constants'

const waitForLastBarPriceOverride = (
  priceSource: CandlePriceSource,
  fetchMarketStats: () => Promise<MarketStats | undefined>,
) => {
  return new Promise<Decimal>((resolve) => {
    if (priceSource === 'trades') {
      const cachedLastPrice = useLatestTradesStore.getState().lastPrice

      if (cachedLastPrice) {
        resolve(cachedLastPrice)
        return
      }

      const unsubscribe = useLatestTradesStore.subscribe((value) => {
        if (value.lastPrice) {
          resolve(value.lastPrice)
          unsubscribe()
        }
      })

      return
    }

    fetchMarketStats().then((stats) => {
      invariant(stats, 'stats')

      switch (priceSource) {
        case 'index-prices':
          return resolve(stats.indexPrice)
        case 'mark-prices':
          return resolve(stats.markPrice)
      }
    })
  })
}

/**
 * References:
 * - https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-API/
 * - https://www.tradingview.com/charting-library-docs/latest/tutorials/implement_datafeed_tutorial/
 */
export const useDatafeed = () => {
  const fetchMarketStats = useFetchMarketStats()

  const fetchBars = useCallback<FetchBars>(
    async (ticker, resolution, { countBack, to, firstDataRequest }) => {
      const parsedTicker = parseTradingViewTicker(ticker)

      const { data } = await getCandles(
        parsedTicker.priceSourceId,
        parsedTicker.symbolName,
        toX10Interval(resolution),
        countBack + 1,
        to * 1000,
      )

      const bars: Bar[] = []

      for (let i = data.length - 1; i >= 0; i--) {
        const bar = checkRequired(data[i], 'bar')

        bars.push({
          time: bar.T,
          open: bar.o.toNumber(),
          high: bar.h.toNumber(),
          low: bar.l.toNumber(),
          close: bar.c.toNumber(),
          volume: bar.v?.toNumber(),
        })
      }

      if (bars.length > 0 && firstDataRequest) {
        const lastBarPriceOverride = await waitForLastBarPriceOverride(
          parsedTicker.priceSourceId,
          () => fetchMarketStats({ marketName: parsedTicker.symbolName }),
        )
        const lastBar = checkRequired(bars[bars.length - 1], 'lastBar')

        // Override the close price of the last historical data bar with the latest price
        // (subsequent updates will be covered by candles WS stream)
        lastBar.close = lastBarPriceOverride.toNumber()
      }

      return { bars: bars, meta: { noData: data.length < countBack } }
    },
    [fetchMarketStats],
  )

  const processCandle = useCallback((candle: Candle, updateData: (bar: Bar) => void) => {
    updateData({
      time: candle.T,
      open: candle.o.toNumber(),
      high: candle.h.toNumber(),
      low: candle.l.toNumber(),
      close: candle.c.toNumber(),
      volume: candle.v?.toNumber(),
    })
  }, [])

  const { subscribe, unsubscribe } = useSubscribeToCandles(processCandle)

  const subscribeToBarsWs = useCallback(
    (
      subscriberUid: string,
      ticker: TradingViewTicker,
      resolution: ResolutionString,
      updateData: (bar: Bar) => void,
    ) => {
      const parsedTicker = parseTradingViewTicker(ticker)

      return subscribe(
        subscriberUid,
        parsedTicker.priceSourceId,
        parsedTicker.symbolName,
        toX10Interval(resolution),
        updateData,
      )
    },
    [subscribe],
  )

  const unsubscribeFromBarsWs = useCallback(
    (subscriberUid: string) => {
      unsubscribe(subscriberUid)
    },
    [unsubscribe],
  )

  return useMemo(() => {
    return new DataFeed(fetchBars, subscribeToBarsWs, unsubscribeFromBarsWs)
  }, [fetchBars, subscribeToBarsWs, unsubscribeFromBarsWs])
}
