import { subDays, subWeeks } from 'date-fns'

import { FilterPeriodValue } from '@x10/lib-ui-kit/components'

export const checkIfTimestampMatchesPeriod = (
  period: FilterPeriodValue,
  timestamp: number,
) => {
  const now = new Date()

  switch (period) {
    case FilterPeriodValue.OneDay:
      return timestamp > subDays(now, 1).getTime()
    case FilterPeriodValue.OneWeek:
      return timestamp > subWeeks(now, 1).getTime()
    case FilterPeriodValue.OneMonth:
      return timestamp > subDays(now, 28).getTime()
    default:
      return false
  }
}
