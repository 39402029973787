import { Suspense, useState, type FC } from 'react'
import { first } from 'lodash'
import { useQueryClient } from '@tanstack/react-query'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { checkRequired, Decimal } from '@x10/lib-core/utils'
import { Box, Center, Flex, HStack, styled, VStack } from '@x10/lib-styled-system/jsx'
import {
  Button,
  Callout,
  Cell,
  Dialog,
  Input,
  InputSuffix,
  NeedHelpLink,
  Portal,
  ShevronTrigger,
  Slider,
  Spinner,
} from '@x10/lib-ui-kit/components'

import { useSuspendedBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import { useLeverageSuspense } from '@src/domain/api/hooks/account/use-leverage'
import { useUpdateLeverage } from '@src/domain/api/hooks/account/use-update-leverage'
import { type CachedMarket } from '@src/domain/api/x10'
import { LEVERAGE_SYMBOL } from '@src/domain/core/config/static'
import { AppErrorCode } from '@src/domain/core/errors/base'
import { parseError } from '@src/domain/core/errors/parse-error'
import { useFormatLeverage } from '@src/domain/core/hooks/use-format-leverage'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { FormError } from '@src/domain/core/ui/components/form-error'
import { createNotificationToast } from '@src/domain/core/ui/components/notification'
import { homeRoute } from '@src/domain/core/utils/routes'
import { QueryKey } from '@src/domain/trade/constants'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { useFormData } from './use-form-data'
import { useFormValidation } from './use-form-validation'

const popoverFallback = (
  <Center h="6.25rem">
    <Spinner />
  </Center>
)

const AdjustLeveragePopoverHeader: FC = () => {
  return (
    <Flex
      css={{
        mb: 's-8',
        textStyle: 'primary',
        w: '100%',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <FormattedMessage
          id="workspace.trade.widget.order-form.popover.leverage.title"
          defaultMessage="Adjust Leverage"
        />
      </Box>

      <FeatureGate name="NEED_HELP_LINK">
        <NeedHelpLink href={homeRoute({}).help({}).helpCenter({}).leverage({}).$} />
      </FeatureGate>
    </Flex>
  )
}

const roundDownMaxLeverage = (leverage: Decimal) => {
  // Prevents max leverage from being non integer (e.g. for COMP-USD it is 15.38)
  // Also see `MarginSchedule` for the same logic
  return leverage.setDecimalPlaces(0, Decimal.ROUND_DOWN)
}

type AdjustLeveragePopoverContentProps = {
  market: CachedMarket
  availableBalance: Decimal
  leverage: Decimal
  onClose: () => void
}

const AdjustLeveragePopoverContent: FC<AdjustLeveragePopoverContentProps> = ({
  market,
  availableBalance,
  leverage,
  onClose,
}) => {
  const queryClient = useQueryClient()
  const { formatMessage } = useIntl()
  const { mutate: updateLeverage, isPending, isSuccess, error } = useUpdateLeverage()
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })
  const formatLeverage = useFormatLeverage()

  const [newLeverage, setNewLeverage] = useState(leverage)

  const maxLeverage = roundDownMaxLeverage(market.tradingConfig.maxLeverage)
  const isLeverageChanged = !leverage.eq(newLeverage)
  const isLeverageIncreased = leverage.lt(newLeverage)

  const {
    maxOpenPositionPlusOrdersValue,
    maxAllowedPositionValue,
    requiredInitialMarginCurrent,
    requiredInitialMarginNew,
  } = useFormData(market, leverage, newLeverage)
  const validationResult = useFormValidation({
    availableBalance,
    requiredInitialMarginCurrent,
    requiredInitialMarginNew,
    isPositionAndOrdersValueAboveMax: maxOpenPositionPlusOrdersValue.gt(
      maxAllowedPositionValue,
    ),
    isLeverageIncreased,
  })
  const incrementalRequiredInitialMargin = requiredInitialMarginNew.minus(
    requiredInitialMarginCurrent,
  )

  const handleUpdateLeverage = () => {
    updateLeverage(
      { leverage: newLeverage, marketName: market.name },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({
            queryKey: [QueryKey.UserAccountLeverage],
          })
          createNotificationToast({
            notification: {
              type: 'LEVERAGE',
              kind: 'modified',
            },
          })
          onClose()
        },
        onError: (e) => {
          const error = parseError(e)

          if (error.code && error.code !== AppErrorCode.Unknown) {
            createNotificationToast({
              notification: {
                type: 'LEVERAGE',
                kind: 'rejected',
                reasonCode: error.code,
              },
            })
          }
        },
      },
    )
  }

  return (
    <VStack gap="s-16">
      <AdjustLeveragePopoverHeader />

      <Input
        label={formatMessage({
          id: 'workspace.trade.widget.order-form.popover.leverage.input.leverage.title',
          defaultMessage: 'Leverage',
        })}
        value={newLeverage.toNumber()}
        suffix={<InputSuffix value={newLeverage.toString()} />}
        onChange={(event) => {
          const newValue = Decimal(event.target.value)

          if (newValue.gt(maxLeverage)) {
            setNewLeverage(maxLeverage)
          } else if (newValue.isNaN() || newValue.lt(Decimal.ONE)) {
            setNewLeverage(Decimal.ONE)
          } else {
            setNewLeverage(newValue)
          }
        }}
      />

      <Slider
        minLabel={formatLeverage(1)}
        maxLabel={formatLeverage(maxLeverage.toNumber())}
        min={1}
        max={maxLeverage.toNumber()}
        value={newLeverage.toNumber()}
        onChange={({ value }) => setNewLeverage(Decimal(value))}
      />

      <Cell.Group fontSize="fs-12">
        <Cell.Item>
          <Cell.Title>
            <FormattedMessage
              id="workspace.trade.widget.order-form.popover.leverage.info.max-position.title"
              defaultMessage="Max Position Value"
            />
          </Cell.Title>
          <Cell.Value>
            {formatMarketAsset({
              amount: maxAllowedPositionValue,
              type: 'collateral',
            })}
          </Cell.Value>
        </Cell.Item>

        <Cell.Item>
          <Cell.Title>
            <FormattedMessage
              id="workspace.trade.widget.order-form.popover.leverage.info.required-initial-margin.title"
              defaultMessage="Required Initial Margin"
            />
          </Cell.Title>
          <Cell.Value>
            {requiredInitialMarginNew.gt(Decimal.ZERO)
              ? formatMarketAsset({
                  amount: requiredInitialMarginNew,
                  type: 'collateral',
                })
              : null}
          </Cell.Value>
        </Cell.Item>

        <Cell.Item>
          <Cell.Title>
            <FormattedMessage
              id="workspace.trade.widget.order-form.popover.leverage.info.available-balance.title"
              defaultMessage="Available Balance"
            />
          </Cell.Title>
          <Cell.Value>
            {formatMarketAsset({
              amount: availableBalance.minus(incrementalRequiredInitialMargin),
              type: 'collateral',
            })}
          </Cell.Value>
        </Cell.Item>
      </Cell.Group>

      <Box py="s-8" textStyle="small" color="token.white-50">
        <FormattedMessage
          id="workspace.trade.widget.order-form.popover.leverage.note.leverage-impact.title"
          defaultMessage="Modifying your leverage will impact the leverage of your open position and orders."
        />
      </Box>

      {isLeverageChanged && validationResult.message && (
        <Callout
          visual={validationResult.success && isLeverageIncreased ? 'success' : 'error'}
        >
          {validationResult.message}
        </Callout>
      )}

      <FormError error={error} />

      <HStack w="100%" gap="s-16">
        <Button w="100%" visual="secondary-grey" size="large" onClick={() => onClose()}>
          <FormattedMessage id="common.action.cancel.title" defaultMessage="Cancel" />
        </Button>

        <Button
          w="100%"
          visual="primary-green"
          size="large"
          disabled={!validationResult.success || !isLeverageChanged}
          loading={isPending || isSuccess}
          onClick={() => handleUpdateLeverage()}
        >
          <FormattedMessage id="common.action.confirm.title" defaultMessage="Confirm" />
        </Button>
      </HStack>
    </VStack>
  )
}

type PopoverTriggerProps = {
  open?: boolean
  leverage: Decimal
  onClick?: () => void
}

const PopoverTrigger: FC<PopoverTriggerProps> = ({
  open,
  leverage,
  // Support `asChild`
  ...restProps
}) => {
  const formatLeverage = useFormatLeverage()

  return (
    <ShevronTrigger open={open} textStyle="primary" {...restProps}>
      {formatLeverage(leverage, { showSymbol: false })}
      <styled.span color="token.green">{LEVERAGE_SYMBOL}</styled.span>
    </ShevronTrigger>
  )
}

export const AdjustLeveragePopover: FC = () => {
  const market = useSelectedMarket()
  const balance = useSuspendedBalance()
  const { data: leverage } = useLeverageSuspense({ marketsNames: [market.name] })

  const marketLeverage = checkRequired(first(leverage)?.leverage, 'marketLeverage')

  return (
    <Dialog.Root lazyMount unmountOnExit modal>
      <Dialog.Context>
        {({ open, setOpen }) => {
          return (
            <>
              <Dialog.Trigger asChild>
                <PopoverTrigger open={open} leverage={marketLeverage} />
              </Dialog.Trigger>

              <Portal>
                <Dialog.Backdrop />
                <Dialog.Positioner>
                  <Dialog.Content css={{ minW: 'unset', w: '21.4375rem' }}>
                    <Suspense fallback={popoverFallback}>
                      <AdjustLeveragePopoverContent
                        market={market}
                        leverage={marketLeverage}
                        availableBalance={balance.availableForTrade}
                        onClose={() => setOpen(false)}
                      />
                    </Suspense>
                  </Dialog.Content>
                </Dialog.Positioner>
              </Portal>
            </>
          )
        }}
      </Dialog.Context>
    </Dialog.Root>
  )
}
