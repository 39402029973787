import { Decimal } from '@x10/lib-core/utils'

const DECIMAL_SCALE_FACTOR = 10 ** 6

export const toSmartContractUnits = (value: Decimal): bigint => {
  return value.times(DECIMAL_SCALE_FACTOR).toBigInt()
}

export const fromSmartContractUnits = (value: bigint): Decimal => {
  return Decimal(value).div(DECIMAL_SCALE_FACTOR)
}
