import { subDays, subWeeks } from 'date-fns'

import { notReachable } from '@x10/lib-core/utils'
import { FilterPeriodValue } from '@x10/lib-ui-kit/components'

export const getChartStartTime = (period: FilterPeriodValue, endTime: number) => {
  switch (period) {
    case FilterPeriodValue.OneDay:
      return subDays(endTime, 1).getTime()
    case FilterPeriodValue.OneWeek:
      return subWeeks(endTime, 1).getTime()
    case FilterPeriodValue.OneMonth:
      return subDays(endTime, 28).getTime()
    default:
      notReachable(period)
  }
}
