import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, HStack, VStack } from '@x10/lib-styled-system/jsx'
import { Button, Scrollable, Stat } from '@x10/lib-ui-kit/components'

import { useMassCancelOrder } from '@src/domain/api/hooks/order-management/use-mass-cancel-order'
import type { UserOrder, UserPosition } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'

import { TpSlOrderCard } from './tp-sl-order-card'

type PositionTpSlOrdersDialogContentProps = {
  position: UserPosition
  tpSlOrders: UserOrder[]
  onAdd: () => void
  onEdit: () => void
}

export const PositionTpSlOrdersDialogContent = ({
  position,
  tpSlOrders,
  onAdd,
  onEdit,
}: PositionTpSlOrdersDialogContentProps) => {
  const formatMarketAsset = useFormatMarketAsset({ marketName: position.market })
  const { massCancelOrder, isMassCancelling } = useMassCancelOrder()

  const handleCancelAll = () => {
    massCancelOrder({
      orderIds: tpSlOrders.map((order) => order.id),
    })
  }

  return (
    <VStack css={{ gap: 's-16', alignItems: 'stretch' }}>
      <Box css={{ textStyle: 'primary' }}>
        <FormattedMessage
          id="trade.component.position-tp-sl.title"
          defaultMessage="Edit TP/SL"
        />
      </Box>

      <HStack css={{ gap: 's-16' }}>
        <Stat.Root>
          <Stat.Title
            css={{
              color: 'token.white-50',
            }}
          >
            <FormattedMessage
              id="trade.component.position-tp-sl.stat.position-size.title"
              defaultMessage="Position Size"
            />
          </Stat.Title>
          <Stat.Value css={{ textStyle: 'caption' }}>
            {formatMarketAsset(
              { amount: position.size, type: 'synthetic' },
              { showSymbol: true },
            )}
          </Stat.Value>
        </Stat.Root>

        <Stat.Root>
          <Stat.Title
            css={{
              color: 'token.white-50',
            }}
          >
            <FormattedMessage
              id="trade.component.position-tp-sl.stat.entry-price.title"
              defaultMessage="Entry Price"
            />
          </Stat.Title>
          <Stat.Value css={{ textStyle: 'caption' }}>
            {formatMarketAsset({ amount: position.openPrice, type: 'collateral' })}
          </Stat.Value>
        </Stat.Root>

        <Stat.Root>
          <Stat.Title
            css={{
              color: 'token.white-50',
            }}
          >
            <FormattedMessage
              id="trade.component.position-tp-sl.stat.mark-price.title"
              defaultMessage="Mark Price"
            />
          </Stat.Title>
          <Stat.Value css={{ textStyle: 'caption' }}>
            {formatMarketAsset({ amount: position.markPrice, type: 'collateral' })}
          </Stat.Value>
        </Stat.Root>

        <Stat.Root>
          <Stat.Title
            css={{
              color: 'token.white-50',
            }}
          >
            <FormattedMessage
              id="trade.component.position-tp-sl.stat.liquidation-price.title"
              defaultMessage="Liquidation Price"
            />
          </Stat.Title>
          <Stat.Value css={{ textStyle: 'caption' }}>
            {formatMarketAsset({ amount: position.liquidationPrice, type: 'collateral' })}
          </Stat.Value>
        </Stat.Root>
      </HStack>

      <HStack css={{ gap: 's-16' }}>
        <Button visual="secondary-grey" size="large" onClick={onAdd}>
          <FormattedMessage id="common.action.add" defaultMessage="Add" />
        </Button>
        <Button
          visual="secondary-grey"
          size="large"
          disabled={tpSlOrders.length === 0}
          onClick={handleCancelAll}
        >
          <FormattedMessage id="common.action.cancel-all" defaultMessage="Cancel All" />
        </Button>
      </HStack>

      {tpSlOrders.length > 0 && (
        <Scrollable scrollY css={{ maxH: '25.625rem' }}>
          <VStack css={{ gap: 's-16' }}>
            {tpSlOrders.map((order) => (
              <TpSlOrderCard
                key={order.id.toString()}
                order={order}
                cancelDisabled={isMassCancelling}
                onEdit={onEdit}
              />
            ))}
          </VStack>
        </Scrollable>
      )}
    </VStack>
  )
}
