import { Fragment, useState, type FC } from 'react'

import { FormattedMessage, useFormatDateTime } from '@x10/lib-core/i18n'
import { Box, Flex, Grid, GridItem, HStack, styled } from '@x10/lib-styled-system/jsx'
import { FilterPeriodValue, Scrollable } from '@x10/lib-ui-kit/components'

import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { NavigationLink } from '@src/domain/core/ui/components/navigation-link'
import { formatTime } from '@src/domain/core/utils/format-time'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import { useUpdatedFundingRates } from '@src/domain/trade/hooks/use-updated-funding-rates'
import { useFullScreenContext } from '@src/domain/trade/providers/full-screen-provider'
import { type FundingRateInterval } from '@src/domain/trade/types/funding-rate'
import { ChartFullScreenButton } from '@src/domain/trade/ui/components/chart-full-screen-button'
import { FundingRateFilterInterval } from '@src/domain/trade/ui/components/funding-rate/funding-rate-filter-interval'
import { FundingRateFilterPeriod } from '@src/domain/trade/ui/components/funding-rate/funding-rate-filter-period'
import { FundingRateLineChart } from '@src/domain/trade/ui/components/funding-rate/funding-rate-line-chart'
import { FundingRateValue } from '@src/domain/trade/ui/components/funding-rate/funding-rate-value'

export const FundingRatePopoverContent: FC = () => {
  const formatPercent = useFormatPercent()
  const { formatDate } = useFormatDateTime()
  const { fullScreen } = useFullScreenContext()

  const [selectedPeriod, setSelectedPeriod] = useState(FilterPeriodValue.OneDay)
  const [selectedInterval, setSelectedInterval] = useState<FundingRateInterval>('1H')

  const { data, isFetching } = useUpdatedFundingRates(selectedPeriod, selectedInterval)

  const lastFundingRate = data[0] // data is sorted in descending order by timestamp

  return (
    <>
      <Box>
        <HStack gap="s-16" textStyle="primary" justifyContent="space-between">
          <HStack gap="s-4">
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.market-stats.item.funding-rate.title"
                defaultMessage="Funding Rate"
              />
              :
            </Box>
            <FundingRateValue fundingRate={lastFundingRate?.fundingRate} />
          </HStack>

          <ChartFullScreenButton />
        </HStack>

        <Flex
          mt="s-16"
          alignItems="center"
          justifyContent="space-between"
          textStyle="caption"
        >
          <HStack css={{ gap: 's-24', fontSize: 'fs-14' }}>
            <FundingRateFilterPeriod onChange={setSelectedPeriod} />
            <FundingRateFilterInterval
              value={selectedInterval}
              onChange={setSelectedInterval}
            />
          </HStack>

          <NavigationLink
            url={asExternalRoute(
              documentationRoute({}).trading({}).fundingPayments({}).$,
            )}
            openInNewTab
          >
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.funding-rate.toolbar.documentation.title"
              defaultMessage="Documentation"
            />
          </NavigationLink>
        </Flex>
      </Box>

      <Box
        css={{
          minH: '20rem',
          h: '20rem',
          mt: 's-8',
          mx: '-s-16',
          pr: 's-8',
        }}
      >
        <FundingRateLineChart
          data={data}
          isLoading={isFetching}
          period={selectedPeriod}
          variant={fullScreen ? 'default' : 'popover'}
        />
      </Box>

      <HStack css={{ gap: 's-16', justifyContent: 'space-between', mt: 's-24' }}>
        <styled.span
          css={{
            textStyle: 'caption',
            color: 'token.white-50',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.market-stats.item.funding-rate.details.column.time.title"
            defaultMessage="Time"
          />
        </styled.span>
        <styled.span
          css={{
            textStyle: 'caption',
            color: 'token.white-50',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.market-stats.item.funding-rate.title"
            defaultMessage="Funding Rate"
          />
        </styled.span>
      </HStack>

      <Scrollable scrollY css={{ mt: 's-16', flex: 1 }}>
        <Grid columns={2} css={{ gap: 's-16' }}>
          {data.map((value) => {
            return (
              <Fragment key={value.timestamp}>
                <GridItem textStyle="small">
                  <Box>{formatDate(value.timestamp, { dateStyle: 'medium' })}</Box>
                  <Box>{formatTime(value.timestamp)}</Box>
                </GridItem>

                <GridItem
                  display="flex"
                  textStyle="small"
                  alignItems="center"
                  justifyContent="right"
                >
                  {formatPercent(value.fundingRate, {
                    precision: PercentPrecision.FundingRate,
                  })}
                </GridItem>
              </Fragment>
            )
          })}
        </Grid>
      </Scrollable>
    </>
  )
}
