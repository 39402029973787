import { useEffect, useMemo } from 'react'

import { Decimal } from '@x10/lib-core/utils'

import { openDepositRejectedDialog } from '@src/domain/auth/hooks/use-client-status'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import {
  createNotificationToast,
  dismissNotificationToast,
} from '@src/domain/core/ui/components/notification'
import { GlobalToastIds } from '@src/domain/core/ui/components/notification/notification-toast'
import { useCancellationRequest } from '@src/domain/starkex/hooks/use-cancellation-request'
import { useDepositBalance } from '@src/domain/starkex/hooks/use-deposit-balance'
import { fromSmartContractUnits } from '@src/domain/starkex/utils/smart-contract'
import { haveTwoDaysPassed } from '@src/domain/trade/ui/components/deposit-rejected-dialog'

const DEPOSIT_RECLAIM_NOTIFICATION_DURATION = 30_000

export const useDepositState = () => {
  const isFeatureEnabled = useIsFeatureEnabled()

  /* eslint-disable react-hooks/rules-of-hooks -- it's ok as flags are stable */
  if (isFeatureEnabled('ACCOUNT_BLOCKING')) {
    const {
      data: rejectedDepositBalanceToReclaim,
      isLoading: isRejectedDepositBalanceToReclaimLoading,
    } = useDepositBalance()
    const { data: cancellationRequestBlockTimestamp } = useCancellationRequest()
    const haveTwoDaysPassedSinceDepositCancellation = cancellationRequestBlockTimestamp
      ? haveTwoDaysPassed(cancellationRequestBlockTimestamp)
      : false

    const isRejectedDepositBalanceToReclaimAvailable =
      !isRejectedDepositBalanceToReclaimLoading &&
      rejectedDepositBalanceToReclaim !== 0n &&
      rejectedDepositBalanceToReclaim !== undefined

    const rejectedDepositBalanceToReclaimDecimal = useMemo(
      () =>
        rejectedDepositBalanceToReclaim
          ? fromSmartContractUnits(rejectedDepositBalanceToReclaim)
          : Decimal.ZERO,
      [rejectedDepositBalanceToReclaim],
    )

    const shouldNotifyAboutDepositRejectionReclaim =
      haveTwoDaysPassedSinceDepositCancellation &&
      isRejectedDepositBalanceToReclaimAvailable

    useEffect(() => {
      if (shouldNotifyAboutDepositRejectionReclaim) {
        createNotificationToast({
          id: GlobalToastIds.ReclaimPendingDeposit,
          duration: DEPOSIT_RECLAIM_NOTIFICATION_DURATION,
          notification: {
            type: 'RECLAIM_PENDING_DEPOSIT',
            amount: rejectedDepositBalanceToReclaimDecimal,
            onClaimClick: async () => {
              openDepositRejectedDialog()
            },
          },
        })
      } else {
        dismissNotificationToast(GlobalToastIds.ReclaimPendingDeposit)
      }
    }, [shouldNotifyAboutDepositRejectionReclaim, rejectedDepositBalanceToReclaimDecimal])

    return {
      isRejectedDepositBalanceToReclaimAvailable,
      rejectedDepositBalanceToReclaimDecimal,
    }
  }
  /* eslint-enable react-hooks/rules-of-hooks */
  return {
    isRejectedDepositBalanceToReclaimAvailable: false,
    rejectedDepositBalanceToReclaimDecimal: Decimal.ZERO,
  }
}
