import { useEffect } from 'react'

import type { MarketName } from '@src/domain/api/x10/common'

import { NewOrderStoreActions, useNewOrderStore } from '../../store/new-order'

export const useUpdateMarketPrice = (marketName: MarketName) => {
  const priceType = useNewOrderStore((state) => state.priceType)
  const triggerPrice = useNewOrderStore((state) => state.conditional.triggerPrice)

  useEffect(() => {
    if (priceType !== 'MARKET') {
      return
    }

    if (!triggerPrice) {
      NewOrderStoreActions.setPrice(null, null, false)
      return
    }

    NewOrderStoreActions.setPrice(triggerPrice, triggerPrice, false)
  }, [marketName, triggerPrice, priceType])
}
