import { Decimal, roundToPrecision } from '@x10/lib-core/utils'

type CalcArgs = {
  maxValue: Decimal
  pct: Decimal
  orderPrice: Decimal
  precision: Decimal
}

export const calcOrderSizeBasedOnMaxValuePct = ({
  maxValue,
  pct,
  orderPrice,
  precision,
}: CalcArgs) => {
  return roundToPrecision(
    pct.times(maxValue).div(orderPrice),
    precision,
    Decimal.ROUND_DOWN,
  )
}
