import { Suspense } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Flex, VStack } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Button,
  Dialog,
  GreenDot,
  NeedHelpLink,
  Portal,
  Skeleton,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { useSuspendedBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import {
  openDepositRejectedDialog,
  useIsAccountBlockedWithPendingDeposit,
} from '@src/domain/auth/hooks/use-client-status'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { useClientSettingsStore } from '@src/domain/core/store/user-settings'
import { DialogContent } from '@src/domain/core/ui/components/dialog/dialog-content'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'

import { DepositDialog } from './deposit-dialog'
import { MakeFirstDepositDialogOpener } from './deposit-opener'
import { useAccountState } from './use-account-state'
import { useDepositState } from './use-deposit-state'

export const DepositButton = () => {
  const { isAccountBlocked } = useAccountState()
  const balanceResponse = useSuspendedBalance()
  const hasZeroBalance = balanceResponse.equity.eq(0)
  const isFeatureEnabled = useIsFeatureEnabled()
  const isAccountBlockedWithPendingDeposit = useIsAccountBlockedWithPendingDeposit()
  const { isRejectedDepositBalanceToReclaimAvailable } = useDepositState()
  const closeAutoOpenedDepositDialogDialog = useClientSettingsStore(
    (state) => state.closeAutoOpenedDepositDialogDialog,
  )

  if (isAccountBlockedWithPendingDeposit) {
    return (
      <Button
        css={{ w: '100%' }}
        visual="secondary-grey"
        onClick={openDepositRejectedDialog}
      >
        <FormattedMessage
          id="workspace.trade.widget.account.action.deposit.title"
          defaultMessage="Deposit"
        />
        {isRejectedDepositBalanceToReclaimAvailable && (
          <GreenDot
            css={{
              position: 'absolute',
              top: '-0.5rem',
              right: '0',
            }}
          />
        )}
      </Button>
    )
  }

  return (
    <Dialog.Root
      modal
      lazyMount
      unmountOnExit
      onOpenChange={(details) => {
        if (!details.open) {
          closeAutoOpenedDepositDialogDialog()
        }
      }}
    >
      {
        // Make sure we only open deposits dialog if claim funds feature is disabled
        isFeatureEnabled('MAKE_FIRST_DEPOSIT') && !isFeatureEnabled('CLAIM_FUNDS') && (
          <Dialog.Context>
            {({ setOpen }) => {
              return <MakeFirstDepositDialogOpener onOpen={() => setOpen(true)} />
            }}
          </Dialog.Context>
        )
      }
      <Dialog.Trigger asChild>
        <Button
          css={{ w: '100%' }}
          visual={
            !isFeatureEnabled('CLAIM_FUNDS') && hasZeroBalance
              ? 'primary-green'
              : 'secondary-grey'
          }
          disabled={isAccountBlocked}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.action.deposit.title"
            defaultMessage="Deposit"
          />
        </Button>
      </Dialog.Trigger>
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <DialogContent>
            <Flex
              css={{
                textStyle: 'primary',
                w: '100%',
                justifyContent: 'space-between',
                pr: 's-32',
              }}
            >
              <Dialog.Title>
                <FormattedMessage
                  id="domain.trade.dialog.deposit.title"
                  defaultMessage="Deposit"
                />
              </Dialog.Title>

              <NeedHelpLink
                href={asExternalRoute(
                  documentationRoute({}).accountOperations({}).deposits({}).$,
                )}
              />
            </Flex>

            <Dialog.CloseTrigger asChild>
              <ActionIcon
                css={{
                  top: 's-16',
                  right: 's-16',
                  pos: 'absolute',
                }}
              >
                <SvgIcon.SvgIconCross />
              </ActionIcon>
            </Dialog.CloseTrigger>
            <Suspense
              fallback={
                <VStack
                  css={{
                    gap: 's-32',
                    mt: 's-16',
                  }}
                >
                  <Skeleton
                    css={{
                      h: '17.6875rem',
                      w: '100%',
                      borderRadius: 'r-12',
                    }}
                  />
                  <Skeleton
                    css={{
                      h: '3.25rem',
                      w: '100%',
                      borderRadius: 'r-16',
                    }}
                  />
                </VStack>
              }
            >
              <DepositDialog />
            </Suspense>
          </DialogContent>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
