import { useMemo } from 'react'

import type { UserOrder } from '@src/domain/api/x10'

export const useFilterTpSlOrders = (orders: UserOrder[] | undefined) => {
  return useMemo(() => {
    return (
      orders?.filter((order) => order.type === 'TPSL' && Boolean(order.tpSlType)) ?? []
    )
  }, [orders])
}
