import { useCallback, useRef, useState } from 'react'

import { type Decimal } from '@x10/lib-core/utils'

import type {
  Balance,
  CachedMarket,
  MarketStats,
  UserOrder,
  UserPosition,
} from '@src/domain/api/x10'
import type { MarketName, OrderSide, OrderType } from '@src/domain/api/x10/common'
import { execValidationRules } from '@src/domain/trade/validation/exec-validation-rules'
import { limitOrderPrice } from '@src/domain/trade/validation/order-form/rules/limit-order-price'
import { maxNumOrders } from '@src/domain/trade/validation/order-form/rules/max-num-orders'
import { minOrderPrice } from '@src/domain/trade/validation/order-form/rules/min-order-price'
import { orderCost } from '@src/domain/trade/validation/order-form/rules/order-cost'
import { orderMinSize } from '@src/domain/trade/validation/order-form/rules/order-min-size'
import { orderSizeDivisible } from '@src/domain/trade/validation/order-form/rules/order-size-divisible'
import { requireOrderPrice } from '@src/domain/trade/validation/order-form/rules/require-order-price'
import { requireOrderSize } from '@src/domain/trade/validation/order-form/rules/require-order-size'
import { riskForImmediateLimit } from '@src/domain/trade/validation/order-form/rules/risk-for-immediate-limit'
import { useErrorMessage } from '@src/domain/trade/validation/order-form/use-error-message'
import type {
  FormValidationErrors,
  FormValidationFieldName,
  FormValidationHook,
  FormValidationResult,
  FormValidationTouchedFields,
} from '@src/domain/trade/validation/types'

type FieldName = FormValidationFieldName<'orderPrice' | 'orderSize'>
type Value = {
  orderSide: OrderSide
  orderSize: Decimal | null
  orderPrice: Decimal | null
  orderCost: { buy: Decimal; sell: Decimal } | null
}
type Context = {
  orderType: OrderType
  reduceOnly: boolean
  market: CachedMarket
  marketStats: MarketStats | undefined
  position: UserPosition
  orders: UserOrder[]
  balance: Balance | undefined
}
export type Computed = {
  orderSide: OrderSide
  orderSize: Decimal | null
  orderPrice: Decimal | null
  isMarketReduceOnly: boolean
  isConditionalReduceOnly: boolean
  isConditionalTriggeredAtPlacement: boolean
}
type Result = FormValidationResult<
  { alerts: string[] },
  { errors: FormValidationErrors<FieldName> }
>

const useValidateFn = (marketName: MarketName) => {
  const errorMessage = useErrorMessage(marketName)

  return useCallback<(value: Value, ctx: Context) => Result>(
    (value, ctx) => {
      if (!ctx.marketStats) {
        return { success: false, errors: { '@internal': 'Missing `marketStats`' } }
      }

      const alerts: string[] = []
      const errors: FormValidationErrors<FieldName> = {}
      const computed: Computed = {
        orderSide: value.orderSide,
        orderSize: value.orderSize,
        orderPrice: value.orderPrice,
        isMarketReduceOnly: false,
        isConditionalReduceOnly: false,
        isConditionalTriggeredAtPlacement: false,
      }

      execValidationRules(value, ctx, computed, alerts, errors, errorMessage, [
        maxNumOrders,
        requireOrderPrice,
        requireOrderSize,
        minOrderPrice,
        limitOrderPrice,
        orderMinSize,
        orderSizeDivisible,
        orderCost,
        riskForImmediateLimit,
      ])

      return Object.keys(errors).length > 0
        ? { success: false, errors }
        : { success: true, alerts }
    },
    [errorMessage],
  )
}

export const useLimitOrderFormValidation = (
  marketName: MarketName,
): FormValidationHook<FieldName, Value, Context, Result> => {
  const validateFn = useValidateFn(marketName)

  const [isTouched, setTouched] = useState<FormValidationTouchedFields<FieldName>>({})
  const isValidatedAtLeastOnceRef = useRef(false)

  const touch = useCallback((fieldName: FieldName) => {
    isValidatedAtLeastOnceRef.current &&
      setTouched((prev) => ({ ...prev, [fieldName]: true }))
  }, [])

  const validate = useCallback(
    (value: Value, ctx: Context) => {
      isValidatedAtLeastOnceRef.current = true

      return validateFn(value, ctx)
    },
    [validateFn],
  )

  const reset = useCallback((fields?: FieldName[]) => {
    if (!fields) {
      setTouched({})
      return
    }

    setTouched((prev) => ({
      ...prev,
      ...fields.reduce((acc, field) => {
        acc[field] = false
        return acc
      }, {} as FormValidationTouchedFields<FieldName>),
    }))
  }, [])

  return {
    isTouched,
    touch,
    validate,
    reset,
  }
}
