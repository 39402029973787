import { useCallback } from 'react'

import { Decimal, invariant, Long } from '@x10/lib-core/utils'

import type { MarketL2Config, MarketTradingConfig } from '@src/domain/api/x10'
import type { Fees } from '@src/domain/api/x10/trading/account/fees.schema'
import { useAccountStarkPrivateKey } from '@src/domain/auth/hooks/use-account-stark-private-key'
import { useAccountInfo } from '@src/domain/auth/hooks/use-auth'
import { AppErrorCode, X10AppError } from '@src/domain/core/errors/base'
import type { OrderContext } from '@src/domain/starkex/stark-perpetual-order'

export const useGetOrderCtx = () => {
  const accountInfo = useAccountInfo()
  const { getStarkPrivateKey } = useAccountStarkPrivateKey()

  return useCallback(
    async (
      marketL2Config: MarketL2Config,
      marketTradingConfig: MarketTradingConfig,
      fees: Fees,
    ): Promise<OrderContext> => {
      invariant(accountInfo, 'accountInfo')

      const vaultId = Long(accountInfo.l2Vault)

      const settlementResolutionCollateral = Decimal(marketL2Config.collateralResolution)
      const settlementResolutionSynthetic = Decimal(marketL2Config.syntheticResolution)
      const assetIdCollateral = Decimal(marketL2Config.collateralId, 16)
      const assetIdSynthetic = Decimal(marketL2Config.syntheticId, 16)

      let starkPrivateKey = null

      try {
        const result = await getStarkPrivateKey()
        if (result.isErr()) {
          // hack to keep flow going like it was before
          throw new X10AppError(
            AppErrorCode.CantGetStarkPrivateKey,
            'User rejected request to get stark private key',
            { cause: result.error },
          )
        }
        starkPrivateKey = result.value
      } catch (error) {
        throw new X10AppError(
          AppErrorCode.CantGetStarkPrivateKey,
          'Error getting stark private key',
          { cause: error },
        )
      }

      return {
        assetIdCollateral,
        assetIdSynthetic,
        settlementResolutionCollateral,
        settlementResolutionSynthetic,
        minOrderSizeChange: marketTradingConfig.minOrderSizeChange,
        maxPositionValue: marketTradingConfig.maxPositionValue,
        feeRate: Decimal.max(fees.makerFeeRate, fees.takerFeeRate),
        vaultId,
        starkPrivateKey,
      }
    },
    [accountInfo, getStarkPrivateKey],
  )
}
