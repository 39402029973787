import { useState } from 'react'
import { isAxiosError } from 'axios'

import { checkRequired, getLogger, invariant, type Decimal } from '@x10/lib-core/utils'

import { useFees } from '@src/domain/api/hooks/account/use-fees'
import { usePlaceOrder } from '@src/domain/api/hooks/order-management/use-place-order'
import type { CachedMarket } from '@src/domain/api/x10'
import type { OrderSide } from '@src/domain/api/x10/common'
import { AppErrorCode, X10AppError } from '@src/domain/core/errors/base'
import { createNotificationToast } from '@src/domain/core/ui/components/notification'
import {
  OrderConditionalTrigger,
  StarkPerpetualOrder,
  type OrderPriceType,
  type OrderTpSlTriggerParam,
} from '@src/domain/starkex/stark-perpetual-order'
import { getOppositeOrderSide } from '@src/domain/starkex/utils/get-opposite-side'
import { useGetOrderCtx } from '@src/domain/trade/hooks/use-get-order-ctx'
import { calcMarketOrderPrice } from '@src/domain/trade/utils/calc/calc-market-order-price'
import { toOrderTpSlTriggerParam } from '@src/domain/trade/utils/to-order-tp-sl-trigger-param'

import {
  NewOrderStoreActions,
  useNewOrderStore,
  type NewOrderTimeInForce,
} from '../../../store/new-order'

const LOGGER = getLogger('useCreateOrderObject')

const getApiTimeInForce = (timeInForce: NewOrderTimeInForce) => {
  switch (timeInForce) {
    case 'good-till-cancel':
    case 'good-till-date':
      return 'GTT'
    case 'immediate-or-cancel':
      return 'IOC'
    case 'fill-or-kill':
      return 'FOK'
  }
}

const patchMarketPrice = (
  orderSide: OrderSide,
  orderPrice: Decimal,
  orderPriceType: OrderPriceType,
  minPriceChange: Decimal,
) => {
  if (orderPriceType === 'MARKET') {
    return calcMarketOrderPrice(orderSide, orderPrice, minPriceChange)
  }

  return orderPrice
}

const patchTpSlMarketPrice = (
  param: OrderTpSlTriggerParam | undefined,
  side: OrderSide,
  minPriceChange: Decimal,
) => {
  if (!param || param.priceType !== 'MARKET') {
    return param
  }

  return {
    ...param,
    price: calcMarketOrderPrice(side, param.triggerPrice, minPriceChange),
  }
}

export const useCreateAndPlaceOrder = (market: CachedMarket) => {
  const buyAlerts = useNewOrderStore((state) => state.buy.alerts)
  const buyErrors = useNewOrderStore((state) => state.buy.errors)
  const sellAlerts = useNewOrderStore((state) => state.sell.alerts)
  const sellErrors = useNewOrderStore((state) => state.sell.errors)

  const getOrderCtx = useGetOrderCtx()
  const { data: fees } = useFees({ marketsNames: [market.name] })
  const { placeOrderAsync } = usePlaceOrder()

  const [isPlacingOrder, setPlacingOrder] = useState(false)

  const marketFees = fees?.[0]

  const createAndPlaceOrder = async (side: OrderSide) => {
    invariant(marketFees, 'marketFees')

    setPlacingOrder(true)

    const newOrderState = useNewOrderStore.getState()
    const orderPrice =
      side === 'BUY'
        ? checkRequired(newOrderState.buy.price, 'buyPrice')
        : checkRequired(newOrderState.sell.price, 'sellPrice')
    const orderQty =
      side === 'BUY'
        ? checkRequired(newOrderState.buy.amountOfSynthetic, 'buyAmountOfSynthetic')
        : checkRequired(newOrderState.sell.amountOfSynthetic, 'sellAmountOfSynthetic')

    try {
      const orderCtx = await getOrderCtx(
        market.l2Config,
        market.tradingConfig,
        marketFees,
      )
      const conditionalOrderTrigger = newOrderState.conditional.triggerPrice
        ? new OrderConditionalTrigger({
            triggerPrice: newOrderState.conditional.triggerPrice,
            triggerPriceType: newOrderState.conditional.triggerPriceType,
            direction: newOrderState.conditional.triggerDirection,
            executionPriceType: newOrderState.priceType,
          })
        : undefined
      const tpSlSide = getOppositeOrderSide(side)
      const order = StarkPerpetualOrder.create({
        marketName: market.name,
        orderType: newOrderState.orderType,
        side,
        amountOfSynthetic: orderQty,
        price: patchMarketPrice(
          side,
          orderPrice,
          newOrderState.priceType,
          market.tradingConfig.minPriceChange,
        ),
        timeInForce: getApiTimeInForce(newOrderState.timeInForce),
        expiryTime: newOrderState.expiryTime,
        reduceOnly: newOrderState.reduceOnly,
        postOnly: newOrderState.postOnly,
        trigger: conditionalOrderTrigger,
        tpSlType: newOrderState.tpsl.tpSlType,
        takeProfit: patchTpSlMarketPrice(
          toOrderTpSlTriggerParam(newOrderState.tpsl.takeProfit),
          tpSlSide,
          market.tradingConfig.minPriceChange,
        ),
        stopLoss: patchTpSlMarketPrice(
          toOrderTpSlTriggerParam(newOrderState.tpsl.stopLoss),
          tpSlSide,
          market.tradingConfig.minPriceChange,
        ),
        ctx: orderCtx,
      })

      LOGGER.debug('Serialized order = %o', order.toJSON())

      await placeOrderAsync({ order })
      NewOrderStoreActions.reset({ type: 'order-placed' })
    } catch (error) {
      if (isAxiosError(error)) {
        createNotificationToast({
          notification: {
            type: 'TRADE',
            event: 'rejected',
            marketName: market.name,
            reason: error.response?.data.error.code,
          },
        })

        return
      }

      if (X10AppError.is(error, AppErrorCode.CantGetStarkPrivateKey)) {
        return
      }

      throw error
    } finally {
      setPlacingOrder(false)
    }
  }

  return {
    buyErrors,
    buyAlerts,
    sellErrors,
    sellAlerts,

    isPlacingOrder,
    createAndPlaceOrder,
  }
}
