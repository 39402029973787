import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import { type OrderSide } from '@src/domain/core/types/common'

export const getOrderSideIntlKey = (value: OrderSide): MessageDescriptor => {
  switch (value) {
    case 'BUY':
      return {
        id: 'common.order.side.buy',
        defaultMessage: 'Buy',
      }
    case 'SELL':
      return {
        id: 'common.order.side.sell',
        defaultMessage: 'Sell',
      }
    case 'LONG':
      return {
        id: 'common.order.side.long',
        defaultMessage: 'Long',
      }
    case 'SHORT':
      return {
        id: 'common.order.side.short',
        defaultMessage: 'Short',
      }
    default:
      throw notReachable(value)
  }
}
