import { useCallback, useMemo, useRef, useState } from 'react'

import { notReachable, type Decimal } from '@x10/lib-core/utils'

import type { MarketStats } from '@src/domain/api/x10'
import type { OrderSide } from '@src/domain/api/x10/common'
import type { OrderTriggerPriceType } from '@src/domain/starkex/stark-perpetual-order'
import { execValidationRules } from '@src/domain/trade/validation/exec-validation-rules'
import { conditionalOrderLiquidity } from '@src/domain/trade/validation/order-form/rules/conditional-order-liquidity'
import { conditionalOrderPrice } from '@src/domain/trade/validation/order-form/rules/conditional-order-price'
import { limitOrderPrice } from '@src/domain/trade/validation/order-form/rules/limit-order-price'
import { marketOrderLiquidity } from '@src/domain/trade/validation/order-form/rules/market-order-liquidity'
import { maxNumOrders } from '@src/domain/trade/validation/order-form/rules/max-num-orders'
import { maxPositionValue } from '@src/domain/trade/validation/order-form/rules/max-position-value'
import { minOrderPrice } from '@src/domain/trade/validation/order-form/rules/min-order-price'
import { minTriggerPrice } from '@src/domain/trade/validation/order-form/rules/min-trigger-price'
import { orderCost } from '@src/domain/trade/validation/order-form/rules/order-cost'
import { orderMaxValue } from '@src/domain/trade/validation/order-form/rules/order-max-value'
import { orderMinSize } from '@src/domain/trade/validation/order-form/rules/order-min-size'
import { orderSizeDivisible } from '@src/domain/trade/validation/order-form/rules/order-size-divisible'
import { reduceOnlySameDirection } from '@src/domain/trade/validation/order-form/rules/reduce-only-same-direction'
import { reduceOnlySum } from '@src/domain/trade/validation/order-form/rules/reduce-only-sum'
import { reduceOnlyWithNoPosition } from '@src/domain/trade/validation/order-form/rules/reduce-only-with-no-position'
import { requireOrderPrice } from '@src/domain/trade/validation/order-form/rules/require-order-price'
import { requireOrderSize } from '@src/domain/trade/validation/order-form/rules/require-order-size'
import { requireTriggerPrice } from '@src/domain/trade/validation/order-form/rules/require-trigger-price'
import { riskForImmediateConditionalLimit } from '@src/domain/trade/validation/order-form/rules/risk-for-immediate-conditional-limit'
import { riskForImmediateConditionalMarket } from '@src/domain/trade/validation/order-form/rules/risk-for-immediate-conditional-market'
import { riskForImmediateLimit } from '@src/domain/trade/validation/order-form/rules/risk-for-immediate-limit'
import { riskForOrderCost } from '@src/domain/trade/validation/order-form/rules/risk-for-order-cost'
import { riskForTpSlDirection } from '@src/domain/trade/validation/order-form/rules/risk-for-tpsl-direction'
import {
  type Computed,
  type Context,
  type FieldName,
  type Result,
  type Value,
} from '@src/domain/trade/validation/order-form/types'
import { useErrorMessage } from '@src/domain/trade/validation/order-form/use-error-message'
import type {
  FormValidationErrors,
  FormValidationHook,
  FormValidationTouchedFields,
} from '@src/domain/trade/validation/types'

export type HookResult = {
  buyErrors: FormValidationErrors<FieldName> | undefined
  sellErrors: FormValidationErrors<FieldName> | undefined
  buyAlerts: string[] | undefined
  sellAlerts: string[] | undefined
}

const getTriggerCurrentPrice = (
  triggerPriceType: OrderTriggerPriceType | undefined,
  marketStats: MarketStats,
): Decimal => {
  switch (triggerPriceType) {
    case 'MARK':
      return marketStats.markPrice
    case 'LAST':
      return marketStats.lastPrice
    case 'INDEX':
      return marketStats.indexPrice
    default:
      return notReachable(triggerPriceType)
  }
}

/**
 * See:
 * - https://docs.google.com/spreadsheets/d/1EWSqQmh0CjmJj88apPtsR00jqAO0QYmBb8UHZ6zY1bw/edit#gid=0
 * - https://www.notion.so/ex10/New-Validation-Logic-9757e22312d34be293abebe178e70a0e
 */
const useValidateFn = () => {
  const errorMessage = useErrorMessage()

  const validateSide = useCallback(
    (orderSide: OrderSide, value: Value, ctx: Context): Result => {
      if (!ctx.marketStats) {
        return { success: false, errors: { '@internal': 'Missing `marketStats`' } }
      }

      if (!ctx.balance) {
        return { success: false, errors: { '@internal': 'Missing `balance`' } }
      }

      if (!ctx.leverage) {
        return { success: false, errors: { '@internal': 'Missing `leverage`' } }
      }

      const errors: Partial<Record<FieldName, string>> = {}
      const alerts: string[] = []

      const { triggerPrice } = value

      const isMarketReduceOnly = Boolean(ctx.orderType === 'MARKET' && ctx.reduceOnly)
      const isConditionalReduceOnly = Boolean(
        ctx.orderType === 'CONDITIONAL' && ctx.reduceOnly,
      )
      const isConditionalTriggeredAtPlacement =
        triggerPrice && ctx.orderType === 'CONDITIONAL'
          ? (() => {
              const triggerCurrentPrice = getTriggerCurrentPrice(
                value.triggerPriceType,
                ctx.marketStats,
              )

              return (
                (value.triggerDirection === 'UP' &&
                  triggerCurrentPrice.gte(triggerPrice)) ||
                (value.triggerDirection === 'DOWN' &&
                  triggerCurrentPrice.lte(triggerPrice))
              )
            })()
          : false

      const orderSize = orderSide === 'BUY' ? value.orderSizeBuy : value.orderSizeSell
      const orderPrice: Decimal | null =
        orderSide === 'BUY' ? value.orderPriceBuy : value.orderPriceSell

      const computed: Computed = {
        orderSize,
        orderPrice,
        orderSide,
        isMarketReduceOnly,
        isConditionalReduceOnly,
        isConditionalTriggeredAtPlacement,
      }

      execValidationRules(value, ctx, computed, alerts, errors, errorMessage, [
        maxNumOrders,
        marketOrderLiquidity,
        conditionalOrderLiquidity,
        requireOrderPrice,
        requireOrderSize,
        requireTriggerPrice,
        minOrderPrice,
        minTriggerPrice,
        limitOrderPrice,
        conditionalOrderPrice,
        reduceOnlyWithNoPosition,
        reduceOnlySameDirection,
        reduceOnlySum,
        orderMinSize,
        orderSizeDivisible,
        orderMaxValue,
        maxPositionValue,
        orderCost,
        riskForOrderCost,
        riskForTpSlDirection,
        riskForImmediateConditionalLimit,
        riskForImmediateConditionalMarket,
        riskForImmediateLimit,
      ])

      return Object.keys(errors).length > 0
        ? { success: false, errors }
        : { success: true, alerts }
    },
    [errorMessage],
  )

  return useCallback(
    (value: Value, ctx: Context): { buy: Result; sell: Result } => {
      return {
        buy: validateSide('BUY', value, ctx),
        sell: validateSide('SELL', value, ctx),
      }
    },
    [validateSide],
  )
}

export const useFormValidation = (): FormValidationHook<
  FieldName,
  Value,
  Context,
  HookResult
> => {
  const validateFn = useValidateFn()

  const [isTouched, setTouched] = useState<FormValidationTouchedFields<FieldName>>({})
  const isValidatedAtLeastOnceRef = useRef(false)

  const touch = useCallback((fieldName: FieldName) => {
    isValidatedAtLeastOnceRef.current &&
      setTouched((prev) => ({ ...prev, [fieldName]: true }))
  }, [])

  const validate = useCallback(
    (value: Value, ctx: Context) => {
      const result = validateFn(value, ctx)

      let buyErrors: FormValidationErrors<FieldName> | undefined
      let sellErrors: FormValidationErrors<FieldName> | undefined
      let buyAlerts: string[] | undefined
      let sellAlerts: string[] | undefined

      if (!result.buy.success) {
        buyErrors = result.buy.errors
      } else {
        buyAlerts = result.buy.alerts
      }

      if (!result.sell.success) {
        sellErrors = result.sell.errors
      } else {
        sellAlerts = result.sell.alerts
      }

      isValidatedAtLeastOnceRef.current = true

      return { buyErrors, buyAlerts, sellErrors, sellAlerts }
    },
    [validateFn],
  )

  const reset = useCallback((fields?: FieldName[]) => {
    if (fields) {
      setTouched((prev) => ({
        ...prev,
        ...fields.reduce((acc, field) => {
          acc[field] = false
          return acc
        }, {} as FormValidationTouchedFields<FieldName>),
      }))
    } else {
      setTouched({})
    }
  }, [])

  return useMemo(
    () => ({
      isTouched,
      touch,
      validate,
      reset,
    }),
    [isTouched, touch, validate, reset],
  )
}
