import { removeTrailingZeros } from './remove-trailing-zeros'

const SI_SYMBOL = ['', 'K', 'M', 'G', 'T']

export const abbreviateNumber = (
  number: number,
  {
    minimalTier = 0,
    precision = 2,
    showTrailingZeros = false,
  }: { minimalTier?: number; precision?: number; showTrailingZeros?: boolean } = {},
): string => {
  const tier = Math.floor(Math.log10(Math.abs(number)) / 3)

  if (tier < minimalTier) {
    return number.toFixed(precision)
  }

  const suffix = SI_SYMBOL[tier]

  if (suffix === undefined) {
    throw new Error('Number is out of range')
  }

  const scale = Math.pow(10, tier * 3)

  const scaled = number / scale

  const scaledFixed = scaled.toFixed(precision)

  if (scaledFixed.endsWith('0') && !showTrailingZeros) {
    return removeTrailingZeros(scaledFixed) + suffix
  }

  return scaledFixed + suffix
}
