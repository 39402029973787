import { useCallback, useState } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Flex } from '@x10/lib-styled-system/jsx'
import { Tabs } from '@x10/lib-ui-kit/components'

import { useSubscribeToOrderBooks } from '@src/domain/api/hooks/stream/use-subscribe-to-order-books'
import { HorizontalDepthChart } from '@src/domain/core/ui/components/depth-chart'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'
import type { TradingViewChartApi } from '@src/domain/trade/ui/widgets/trading-chart/components/trading-view-chart/utils/trading-view-chart-api'

import { TradingViewChart } from '../trading-view-chart'
import { TradingViewChartTopToolbar } from '../trading-view-chart-toolbar'
import { useTradingViewChartMachineApi } from '../trading-view-chart/hooks/use-trading-view-chart'

enum DepthVisualMode {
  Depth = 'DEPTH',
  DepthAndPriceStacked = 'DEPTH_AND_PRICE_STACKED',
  DepthAndPriceSideBySide = 'DEPTH_AND_PRICE_SIDE_BY_SIDE',
}

export const DepthTabContent = () => {
  const [depthVisualMode, setDepthVisualMode] = useState(DepthVisualMode.Depth)

  const machine = useTradingViewChartMachineApi()

  const handleApiReady = useCallback(
    (api: TradingViewChartApi) => {
      machine.connect(api)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [machine.connect],
  )
  const market = useSelectedMarket()
  const { data } = useSubscribeToOrderBooks(
    market.name,
    market.tradingConfig.minPriceChange,
    market.tradingConfig.minPriceChange,
  )

  return (
    <>
      <TradeWidget.ContentFixed
        key={depthVisualMode}
        flexDirection={
          depthVisualMode === DepthVisualMode.DepthAndPriceSideBySide ? 'row' : 'column'
        }
      >
        {depthVisualMode !== DepthVisualMode.Depth && (
          <Flex css={{ flex: 1, flexDirection: 'column' }}>
            <TradingViewChartTopToolbar machine={machine} />
            <TradingViewChart hideLeftToolbar onApiReady={handleApiReady} />
          </Flex>
        )}

        <HorizontalDepthChart data={data} />
      </TradeWidget.ContentFixed>

      <Tabs.Root
        css={{ px: 's-16', py: '0.3125rem', hideBelow: 'md', fontSize: 'fs-14' }}
        size="sm"
        value={depthVisualMode}
        onValueChange={({ value }) => setDepthVisualMode(value as DepthVisualMode)}
      >
        <Tabs.List>
          <Tabs.Trigger value={DepthVisualMode.Depth} css={{ fontSize: 'fs-14' }}>
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.depth.visual-mode.depth.title"
              defaultMessage="Depth"
            />
          </Tabs.Trigger>
          <Tabs.Trigger
            value={DepthVisualMode.DepthAndPriceStacked}
            css={{ fontSize: 'fs-14' }}
          >
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.depth.visual-mode.depth-and-price-stacked.title"
              defaultMessage="Depth & Price Stacked"
            />
          </Tabs.Trigger>
          <Tabs.Trigger
            value={DepthVisualMode.DepthAndPriceSideBySide}
            css={{ fontSize: 'fs-14' }}
          >
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.depth.visual-mode.depth-and-price-side-by-side.title"
              defaultMessage="Depth & Price Side by Side"
            />
          </Tabs.Trigger>
        </Tabs.List>
      </Tabs.Root>
    </>
  )
}
