export enum ItemId {
  MarkPrice = 'MARK_PRICE',
  IndexPrice = 'INDEX_PRICE',
  FundingRate = 'FUNDING_RATE',
  Change24h = 'CHANGE_24H',
  OpenInterestBase = 'OPEN_INTEREST_BASE',
  OpenInterestCollateral = 'OPEN_INTEREST_COLLATERAL',
  Volume24hBase = 'VOLUME_24H_BASE',
  Volume24hCollateral = 'VOLUME_24H_COLLATERAL',
  High24h = 'HIGH_24H',
  Low24h = 'LOW_24H',
}
