import { sortBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import { removeRestEnvelope } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { QueryKey } from '@src/domain/trade/constants'

export const useMarketsStats = ({
  marketsNames,
}: { marketsNames?: MarketName[] } = {}) => {
  const sortedMarketsNames = sortBy(marketsNames)

  const { data, isPending, isError } = useQuery({
    queryKey: [QueryKey.MarketsStats, sortedMarketsNames],
    queryFn: () => {
      return API.trading.marketsInfo.getMarketsStats({ marketsNames: sortedMarketsNames })
    },
    ...getCommonPeriodicQueryConfig(),
  })

  return { data: removeRestEnvelope(data), isPending, isError }
}
