import { forwardRef } from 'react'

import { Box, type BoxProps } from '@x10/lib-styled-system/jsx'
import { Scrollable } from '@x10/lib-ui-kit/components'

export const TradeWidgetContentScrollable = forwardRef<HTMLDivElement, BoxProps>(
  ({ children, ...restProps }, ref) => (
    <Box
      data-scope="trade-widget"
      data-part="content-scrollable"
      ref={ref}
      flex={1}
      overflow="hidden"
      {...restProps}
    >
      <Scrollable scrollY css={{ h: '100%' }}>
        {children}
      </Scrollable>
    </Box>
  ),
)

TradeWidgetContentScrollable.displayName = 'TradeWidgetContentScrollable'
