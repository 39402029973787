import { HStack } from '@x10/lib-styled-system/jsx'
import { TableCell } from '@x10/lib-ui-kit/components'

import type { MarketStats, UserOrder, UserPosition } from '@src/domain/api/x10'
import { useClosePositionWithLimitPrice } from '@src/domain/trade/hooks/use-close-position-with-limit-price'
import { useClosePositionWithMarketPrice } from '@src/domain/trade/hooks/use-close-position-with-market-price'
import { useFilterOrdersForCalc } from '@src/domain/trade/hooks/use-filter-orders-for-calc'

import { useMassCancelPositionsStore } from '../../hooks/use-mass-cancel-positions'
import { LimitCloseForm, LimitCloseFormProvider } from '../limit-close-form'
import { MarketCloseActionButton } from './market-close-action-button'

type PositionActionsCellProps = {
  position: UserPosition
  orders: UserOrder[]
  marketStats?: MarketStats
}

export const PositionActionsCell = ({
  position,
  orders,
  marketStats,
}: PositionActionsCellProps) => {
  const { isMassCancelling } = useMassCancelPositionsStore()

  const triggeredOrders = useFilterOrdersForCalc(orders)
  const { closePositionWithMarketPrice, isClosingPosition: isMarketClosingPosition } =
    useClosePositionWithMarketPrice()
  const { closePositionWithLimitPrice, isClosingPosition: isLimitClosingPosition } =
    useClosePositionWithLimitPrice()

  return (
    <TableCell>
      <HStack gap="s-12">
        <MarketCloseActionButton
          position={position}
          triggeredOrders={triggeredOrders}
          marketStats={marketStats}
          loading={isMarketClosingPosition}
          disabled={isMassCancelling || isLimitClosingPosition}
          onConfirm={() => closePositionWithMarketPrice(position)}
        />

        <LimitCloseFormProvider>
          <LimitCloseForm
            position={position}
            triggeredOrders={triggeredOrders}
            marketStats={marketStats}
            loading={isLimitClosingPosition}
            disabled={isMassCancelling || isMarketClosingPosition}
            onConfirm={(orderSize, orderPrice) =>
              closePositionWithLimitPrice({
                position,
                orderSize,
                orderPrice,
              })
            }
          />
        </LimitCloseFormProvider>
      </HStack>
    </TableCell>
  )
}
