import { useEffect, useMemo } from 'react'
import { erc20Abi } from 'viem'
import { useAccount, useBlockNumber } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import { invariant } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useSuspendedAssets } from '@src/domain/api/hooks/markets-info/use-assets'
import { useReadContract } from '@src/domain/core/hooks/use-read-contract'

import { COLLATERAL_ASSET_NAME } from '../constants'
import { fromSmartContractUnits } from '../utils/smart-contract'

export const useWalletAllowance = () => {
  const { address } = useAccount()
  const { starkExContractAddress } = useSettings()

  const [collateral] = useSuspendedAssets({ assetsNames: [COLLATERAL_ASSET_NAME] })
  const queryClient = useQueryClient()
  const { data: blockNumber } = useBlockNumber({ watch: true })

  invariant(collateral, 'Collateral not found')

  const {
    data: walletAllowanceInt,
    isLoading: isLoading,
    queryKey,
  } = useReadContract({
    abi: erc20Abi,
    address: collateral.l1Id,
    functionName: 'allowance',
    args: address ? [address, starkExContractAddress] : undefined,
    query: { enabled: Boolean(address) },
  })

  const walletAllowance = useMemo(
    () => (walletAllowanceInt ? fromSmartContractUnits(walletAllowanceInt) : undefined),
    [walletAllowanceInt],
  )

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey })
  }, [blockNumber, queryClient, queryKey])

  return { value: walletAllowance, isLoading }
}
