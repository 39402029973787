import { addDays } from 'date-fns'

import type { CryptoCurrencyCollateralCode } from '@x10/lib-core/config'
import type { HexString } from '@x10/lib-core/types'
import { checkRequired, Long, type Decimal } from '@x10/lib-core/utils'

import { StarkTransferSettlement } from '../stark-transfer'
import { calcStarkExpiration } from '../utils/calc-stark-expiration'
import { generateNonce } from '../utils/generate-nonce'
import type { TransferContext } from './types'

const TRANSFER_EXPIRATION_DAYS = 7

export class StarkFastWithdrawTransfer {
  private readonly fromAccountId: Long
  private readonly toAccountL2Key: HexString
  private readonly toAccountL2Vault: Long
  private readonly amount: Decimal
  private readonly transferFee: Decimal
  private readonly asset: CryptoCurrencyCollateralCode
  private readonly settlement: StarkTransferSettlement

  private constructor({
    fromAccountId,
    amount,
    asset,
    transferFee,
    settlement,
    toAccountL2Key,
    toAccountL2Vault,
  }: {
    fromAccountId: Long
    amount: Decimal
    transferFee: Decimal
    asset: CryptoCurrencyCollateralCode
    settlement: StarkTransferSettlement
    toAccountL2Key: HexString
    toAccountL2Vault: Long
  }) {
    this.fromAccountId = fromAccountId
    this.amount = amount
    this.asset = asset
    this.transferFee = transferFee
    this.settlement = settlement
    this.toAccountL2Key = toAccountL2Key
    this.toAccountL2Vault = toAccountL2Vault
  }

  toJSON() {
    return {
      transferFee: this.transferFee.toString(10),
      amount: this.amount.toString(10),
      accountId: this.fromAccountId,
      asset: this.asset,
      settlement: this.settlement.toJSON(),
      toAccountL2Key: this.toAccountL2Key.toString(),
      toAccountL2Vault: this.toAccountL2Vault.toString(10),
    }
  }

  static create({
    fromAccountId,
    toAccountL2Key,
    toAccountL2Vault,
    amount,
    asset,
    transferFee,
    ctx,
  }: {
    fromAccountId: Long
    toAccountL2Key: HexString
    toAccountL2Vault: Long
    amount: Decimal
    transferFee: Decimal
    asset: CryptoCurrencyCollateralCode
    ctx: TransferContext
  }) {
    const fromAccount = checkRequired(
      ctx.accounts.find((account) => account.accountId.eq(fromAccountId)),
      'fromAccount',
    )
    const expiryEpochMillis = addDays(new Date(), TRANSFER_EXPIRATION_DAYS).getTime()
    const starkAmount = amount
      .plus(transferFee)
      .times(ctx.l2Config.collateralResolution)
      .toIntegerValueExact()

    return new StarkFastWithdrawTransfer({
      fromAccountId,
      toAccountL2Key,
      toAccountL2Vault,
      amount,
      transferFee,
      asset: asset,
      settlement: StarkTransferSettlement.create({
        amount: starkAmount,
        assetId: ctx.l2Config.collateralId,
        expirationTimestamp: calcStarkExpiration(expiryEpochMillis),
        nonce: Long(generateNonce()),
        receiverPositionId: toAccountL2Vault,
        receiverPublicKey: toAccountL2Key,
        senderPositionId: Long(fromAccount.l2Vault),
        senderPublicKey: fromAccount.l2Key,
        starkPrivateKey: ctx.starkPrivateKey,
      }),
    })
  }
}
