import { useMemo, type FC } from 'react'

import type { CryptoCurrencyCode } from '@x10/lib-core/config'
import { useIntl } from '@x10/lib-core/i18n'
import { Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { InputMessage, Portal, Select, ShevronTrigger } from '@x10/lib-ui-kit/components'

import { getOrderPriceIntlKey } from '@src/domain/i18n/utils/get-order-price-intl-key'
import type { OrderPriceType } from '@src/domain/starkex/stark-perpetual-order'
import { getPlaceholderForPrecision } from '@src/domain/trade/utils/get-placeholder-for-precision'

import { MoneyInput } from '../money-input'

const useOrderPriceOptions = () => {
  const { formatMessage } = useIntl()

  return useMemo<Array<{ label: string; value: OrderPriceType }>>(() => {
    return [
      {
        label: formatMessage(getOrderPriceIntlKey('LIMIT')),
        value: 'LIMIT',
      },
      {
        label: formatMessage(getOrderPriceIntlKey('MARKET')),
        value: 'MARKET',
      },
    ]
  }, [formatMessage])
}

type OrderPriceInputProps = {
  value: Decimal | null
  valueType: OrderPriceType
  currency: CryptoCurrencyCode
  precision: number
  disabled?: boolean
  error?: string
  onBlur?: () => void
  onValueChange: (value: Decimal | null) => void
  onValueTypeChange: (value: OrderPriceType) => void
}

export const OrderPriceInput: FC<OrderPriceInputProps> = ({
  value,
  valueType,
  currency,
  precision,
  disabled,
  error,
  onBlur,
  onValueChange,
  onValueTypeChange,
}) => {
  const { formatMessage } = useIntl()
  const orderPriceOptions = useOrderPriceOptions()

  return (
    <Box aria-invalid={Boolean(error)} className="group">
      <HStack w="100%" gap="s-2">
        <MoneyInput.Item
          value={Decimal.toNullableNumber(value)}
          currency={currency}
          precision={precision}
          placeholder={getPlaceholderForPrecision(precision)}
          overlayValue={
            valueType === 'MARKET'
              ? formatMessage(getOrderPriceIntlKey('MARKET'))
              : undefined
          }
          label={formatMessage({
            id: 'workspace.trade.widget.order-form.shared.input.order-price.title',
            defaultMessage: 'Order Price',
          })}
          disabled={disabled || valueType === 'MARKET'}
          onBlur={onBlur}
          onChange={(value) => onValueChange(Decimal.fromNullableValue(value))}
        />

        <Select.Root
          minW="6.25rem"
          positioning={{ sameWidth: true }}
          items={orderPriceOptions}
          value={[valueType]}
          disabled={disabled}
          onValueChange={(item) => {
            onValueTypeChange(item.value[0] as OrderPriceType)
          }}
        >
          <Select.Context>
            {({ open }) => {
              return (
                <>
                  <Select.Control>
                    <Select.Trigger>
                      <Select.ValueText />
                      <ShevronTrigger open={open} />
                    </Select.Trigger>
                  </Select.Control>

                  <Portal>
                    <Select.Positioner>
                      <Select.Content>
                        {orderPriceOptions.map((item) => {
                          return (
                            <Select.Item key={item.value} item={item}>
                              <Select.ItemText>{item.label}</Select.ItemText>
                            </Select.Item>
                          )
                        })}
                      </Select.Content>
                    </Select.Positioner>
                  </Portal>
                </>
              )
            }}
          </Select.Context>
        </Select.Root>
      </HStack>

      <InputMessage>{error}</InputMessage>
    </Box>
  )
}
