import { useState } from 'react'

import { Dialog, Portal } from '@x10/lib-ui-kit/components'

import type { UserOrder, UserPosition } from '@src/domain/api/x10'
import { useFilterTpSlOrders } from '@src/domain/trade/hooks/use-filter-tp-sl-orders'

import { PositionTpSlOrderDialogContent } from './position-tp-sl-order-dialog-content'
import { PositionTpSlOrdersDialogContent } from './position-tp-sl-orders-dialog-content'

type PositionTpSlDialogProps = {
  open?: boolean
  position?: UserPosition
  orders?: UserOrder[]
  onClose: () => void
  onCloseComplete: () => void
}

export const PositionTpSlDialog = ({
  open,
  position,
  orders,
  onClose,
  onCloseComplete,
}: PositionTpSlDialogProps) => {
  const [isAdding, setAdding] = useState(false)
  const tpSlOrders = useFilterTpSlOrders(orders)

  return (
    <Dialog.Root
      open={open}
      modal
      lazyMount
      unmountOnExit
      onOpenChange={({ open: newOpen }) => {
        if (!newOpen) {
          onClose()
        }
      }}
      onExitComplete={() => {
        setAdding(false)
        onCloseComplete()
      }}
    >
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <Dialog.Content minW="unset!">
            {position && isAdding ? (
              <PositionTpSlOrderDialogContent
                position={position}
                onCancel={() => onClose()}
              />
            ) : (
              position &&
              tpSlOrders && (
                <PositionTpSlOrdersDialogContent
                  position={position}
                  tpSlOrders={tpSlOrders}
                  onAdd={() => setAdding(true)}
                  onEdit={() => {}}
                />
              )
            )}
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
