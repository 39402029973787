import { type Context, type ErrorMessage, type Rule } from '../types'

/**
 * [59:Validation]
 * Number of already open orders per market ≤ 200
 */
export const maxNumOrders: Rule<
  unknown,
  Pick<Context, 'market' | 'orders'>,
  unknown,
  Pick<ErrorMessage, 'getMaxNumOrders'>
> = (_value, ctx, _computed, _alerts, errors, errorMessage) => {
  if (ctx.market.tradingConfig.maxNumOrders.lte(ctx.orders.length)) {
    errors.form = errorMessage.getMaxNumOrders(ctx.market.tradingConfig.maxNumOrders)
  }
}
