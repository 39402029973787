import {
  type Bar,
  type LibrarySymbolInfo,
  type ResolutionString,
  type SubscribeBarsCallback,
} from '@src/types/charting-library'

import type { TradingViewTicker } from '../../types/common'
import { getSymbolInfoTicker } from '../data-feed/utils'

type StreamSubscription = {
  symbolInfo: LibrarySymbolInfo
  resolution: ResolutionString
  lastDailyBar: Bar | undefined
  handler: SubscribeBarsCallback
}

export type SubscribeToBarsWs = (
  subscriberUid: string,
  ticker: TradingViewTicker,
  resolution: ResolutionString,
  updateData: (bar: Bar) => void,
) => Promise<string>
export type UnsubscribeFromBarsWs = (subscriberUid: string) => void

export class DataStreaming {
  subscriptions: Map<string, StreamSubscription>

  subscribeToBarsWs: SubscribeToBarsWs
  unsubscribeFromBarsWs: UnsubscribeFromBarsWs

  constructor(
    subscribeToBarsWs: SubscribeToBarsWs,
    unsubscribeFromBarsWs: UnsubscribeFromBarsWs,
  ) {
    this.subscriptions = new Map()

    this.subscribeToBarsWs = subscribeToBarsWs
    this.unsubscribeFromBarsWs = unsubscribeFromBarsWs
  }

  subscribeOnStream(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriberUid: string,
    _onResetCacheNeededCallback: () => void,
    lastDailyBar: Bar | undefined,
  ) {
    let subscriptionItem = this.subscriptions.get(subscriberUid)

    if (subscriptionItem) {
      throw new Error(`Already subscribed: ${subscriberUid}`)
    }

    subscriptionItem = {
      symbolInfo,
      resolution,
      lastDailyBar,
      handler: onRealtimeCallback,
    }

    this.subscriptions.set(subscriberUid, subscriptionItem)
    this.subscribeToBarsWs(
      subscriberUid,
      getSymbolInfoTicker(symbolInfo),
      resolution,
      (bar) => this.updateData(subscriberUid, bar),
    )
  }

  unsubscribeFromStream(subscriberUid: string) {
    this.subscriptions.delete(subscriberUid)
    this.unsubscribeFromBarsWs(subscriberUid)
  }

  private updateData(subscriberUid: string, newBar: Bar) {
    for (const [uid, subscriptionItem] of this.subscriptions.entries()) {
      if (uid !== subscriberUid) {
        continue
      }

      // Will be undefined if market has no data
      const cachedLastDailyBar = subscriptionItem.lastDailyBar

      let bar = newBar

      if (newBar.time === cachedLastDailyBar?.time) {
        bar = {
          open: cachedLastDailyBar.open,
          time: newBar.time,
          high: Math.max(cachedLastDailyBar.high, newBar.high),
          low: Math.min(cachedLastDailyBar.low, newBar.low),
          close: newBar.close,
          volume: newBar.volume,
        }
      }

      subscriptionItem.lastDailyBar = bar
      subscriptionItem.handler(bar)
    }
  }
}
