import { useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import { removeRestEnvelope } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { queryClient } from '@src/domain/core/providers/app-providers/query-client'
import { QueryKey } from '@src/domain/trade/constants'

export const useMarketStats = ({ marketName }: { marketName: MarketName }) => {
  const { data, isPending, isError } = useQuery({
    queryKey: [QueryKey.MarketStats, marketName],
    queryFn: () => {
      return API.trading.marketsInfo.getMarketStats(marketName)
    },
    // This is needed so we can keep updating the price in the DocumentTitleUpdater (hence the tab title)
    refetchIntervalInBackground: true,
    ...getCommonPeriodicQueryConfig(),
  })

  return { data: removeRestEnvelope(data), isPending, isError }
}

export const useFetchMarketStats = () => {
  return useCallback(async ({ marketName }: { marketName: MarketName }) => {
    const data = await queryClient.fetchQuery({
      queryKey: [QueryKey.MarketStats, marketName],
      queryFn: () => {
        return API.trading.marketsInfo.getMarketStats(marketName)
      },
    })

    return removeRestEnvelope(data)
  }, [])
}
