import { Decimal, notReachable } from '@x10/lib-core/utils'

import type { OrderSide, PositionSide } from '@src/domain/api/x10/common'

export const getSignedSize = <
  T extends
    | { size: Decimal; side: PositionSide }
    | { qty: Decimal; filledQty?: Decimal; side: OrderSide },
>(
  item: T | undefined,
): Decimal => {
  if (!item) {
    return Decimal.ZERO
  }

  if ('qty' in item) {
    if ('filledQty' in item && item.filledQty) {
      return item.side === 'BUY'
        ? item.qty.minus(item.filledQty)
        : item.qty.minus(item.filledQty).negated()
    }

    return item.side === 'BUY' ? item.qty : item.qty.negated()
  }

  if ('size' in item) {
    return item.side === 'LONG' ? item.size : item.size.negated()
  }

  throw notReachable(item)
}
