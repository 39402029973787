import { invariant } from '@x10/lib-core/utils'

import type { Computed, Context, ErrorMessage, Rule } from '../types'
import { checkMarketLiquidity, getMarketPrice } from '../utils'

/**
 * [71:Risk Alert]
 * Limit order (both regular and post-only) executed immediately at placement
 * Sell price ≤ best bid price or Buy price ≥ best ask price
 */
export const riskForImmediateLimit: Rule<
  unknown,
  Pick<Context, 'orderType' | 'marketStats'>,
  Pick<Computed, 'orderPrice' | 'orderSide'>,
  Pick<ErrorMessage, 'getExecutedImmediatelyAsMarket'>
> = (_value, ctx, computed, alerts, errors, errorMessage) => {
  if (errors.orderPrice || ctx.orderType !== 'LIMIT') {
    return
  }

  invariant(ctx.marketStats, '`marketStats` is required')
  invariant(computed.orderPrice, '`orderPrice` is required')

  const marketPrice = getMarketPrice(computed.orderSide, ctx.marketStats)

  if (
    checkMarketLiquidity(computed.orderSide, ctx.marketStats) &&
    ((computed.orderSide === 'BUY' && computed.orderPrice.gte(marketPrice)) ||
      (computed.orderSide === 'SELL' && computed.orderPrice.lte(marketPrice)))
  ) {
    alerts.push(errorMessage.getExecutedImmediatelyAsMarket())
  }
}
