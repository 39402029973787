import type { PropsWithChildren } from 'react'

import { Center, HStack } from '@x10/lib-styled-system/jsx'

/**
 * This component is used to unify spacing between all TableHeaderCells to accommodate
 * for Buttons and other bigger than text elements that may bloat header cell making them uneven by height between tabs
 */
export const HeaderSpacer = ({ children }: PropsWithChildren) => {
  return (
    <Center
      css={{
        minH: '2.25rem',
        justifyContent: 'flex-start',
      }}
    >
      <HStack
        css={{
          gap: 's-2',
          w: '100%',
        }}
      >
        {children}
      </HStack>
    </Center>
  )
}
