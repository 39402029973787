import { useCallback, useMemo, useState } from 'react'

const VISIBILITY_DELAY = 200

export const useInputFloatingElement = () => {
  const [isVisible, setVisible] = useState(false)

  const show = useCallback(() => {
    setTimeout(() => setVisible(true), VISIBILITY_DELAY)
  }, [])

  const hide = useCallback(() => {
    setTimeout(() => setVisible(false), VISIBILITY_DELAY)
  }, [])

  return useMemo(
    () => ({
      isVisible,
      show,
      hide,
    }),
    [isVisible, show, hide],
  )
}
