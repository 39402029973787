import { forwardRef } from 'react'

import { Box, Flex, type FlexProps } from '@x10/lib-styled-system/jsx'
import { SvgIcon } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'

type SelectedMarketProps = FlexProps & {
  marketName: MarketName
  active?: boolean
}

export const SelectedMarket = forwardRef<HTMLDivElement, SelectedMarketProps>(
  ({ marketName, active, ...restProps }, ref) => {
    const { syntheticCode } = parseMarketName(marketName)

    if (!active) {
      return <CryptoLogo currency={syntheticCode} />
    }

    return (
      <Flex
        ref={ref}
        alignItems="center"
        bg="token.white-10"
        p="s-8"
        borderRadius="r-24"
        display="inline-flex"
        cursor="pointer"
        {...restProps}
      >
        <CryptoLogo currency={syntheticCode} />
        <Box css={{ fontWeight: 'fw-500', ml: 's-8', mr: 's-4', whiteSpace: 'nowrap' }}>
          {marketName}
        </Box>
        <SvgIcon.SvgIconShevronDown />
      </Flex>
    )
  },
)

SelectedMarket.displayName = 'SelectedMarket'
