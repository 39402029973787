import { type Long } from '@x10/lib-core/utils'

import { omitUndefined } from '../utils/omit-undefined'

export class StarkPerpetualOrderDebuggingAmounts {
  private readonly collateralAmount: Long
  private readonly feeAmount: Long
  private readonly syntheticAmount: Long

  constructor(amounts: {
    collateralAmount: Long
    feeAmount: Long
    syntheticAmount: Long
  }) {
    this.collateralAmount = amounts.collateralAmount
    this.feeAmount = amounts.feeAmount
    this.syntheticAmount = amounts.syntheticAmount
  }

  toJSON() {
    return omitUndefined({
      collateralAmount: this.collateralAmount.toString(10),
      feeAmount: this.feeAmount.toString(10),
      syntheticAmount: this.syntheticAmount.toString(10),
    })
  }
}
