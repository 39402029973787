import { sortBy } from 'lodash'
import { useSuspenseQuery } from '@tanstack/react-query'

import type { CryptoCurrencyCode } from '@x10/lib-core/config'

import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import { QueryKey } from '@src/domain/trade/constants'

export const useSuspendedAssets = ({
  assetsNames,
  isCollateral,
}: { assetsNames?: CryptoCurrencyCode[]; isCollateral?: boolean } = {}) => {
  const sortedAssetNames = sortBy(assetsNames)

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.Assets, sortedAssetNames, isCollateral],
    queryFn: () => {
      return API.trading.marketsInfo.getAssets({
        assetsNames: sortedAssetNames,
        isCollateral,
      })
    },
  })

  return removeRestEnvelopeSuspense(data)
}
