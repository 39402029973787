import { useSuspenseQuery } from '@tanstack/react-query'

import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import { QueryKey } from '@src/domain/trade/constants'

export const useNextClaim = () => {
  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.UserNextClaim],
    queryFn: () => API.trading.account.getNextClaim(),
    refetchInterval: 60000,
  })

  return removeRestEnvelopeSuspense(data)
}
