import React, { useMemo } from 'react'
import { type ColumnDef } from '@tanstack/react-table'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { DateTimeTableCell, TableCell, ValueColored } from '@x10/lib-ui-kit/components'

import type { AccountTrade, UserOrder } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useFormatUsd } from '@src/domain/core/hooks/use-format-usd'
import { OrderSideColored } from '@src/domain/core/ui/components/order-side-colored'
import { OrderSideText } from '@src/domain/core/ui/components/order-side-text'
import { getTradeTypeIntlKey } from '@src/domain/i18n/utils/get-trade-type-intl-key'

import { ActionableMarketTableCell } from './components/actionable-market-table-cell'

export const useColumns = () => {
  const { formatMessage } = useIntl()

  const formatMarketAsset = useFormatMarketAsset()
  const formatUsd = useFormatUsd()

  return useMemo<Array<ColumnDef<AccountTrade>>>(
    () => [
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.market.title',
          defaultMessage: 'Market',
        }),
        accessorKey: 'market',
        size: 100,
        cell: (info) => {
          const marketName = info.getValue<UserOrder['market']>()

          return <ActionableMarketTableCell market={marketName} />
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.side.title',
          defaultMessage: 'Side',
        }),
        size: 70,
        accessorKey: 'side',
        cell: (info) => {
          const side = info.getValue<AccountTrade['side']>()

          return (
            <TableCell>
              <OrderSideText side={side} />
            </TableCell>
          )
        },
      },
      {
        id: 'filled_price',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.filled-price.title',
          defaultMessage: 'Filled Price',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.price],
        cell: (info) => {
          const [marketName, price] =
            info.getValue<[UserOrder['market'], UserOrder['price']]>()

          return (
            <TableCell>
              <Box>
                {formatMarketAsset(
                  {
                    amount: price,
                    type: 'collateral',
                  },
                  { marketName },
                )}
              </Box>
            </TableCell>
          )
        },
      },
      {
        id: 'filled_amount',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.filled-amount.title',
          defaultMessage: 'Filled Amount',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.qty, row.side],
        cell: (info) => {
          const [marketName, qty, side] =
            info.getValue<
              [AccountTrade['market'], AccountTrade['qty'], AccountTrade['side']]
            >()

          return (
            <TableCell>
              <OrderSideColored side={side}>
                <Box>
                  {formatMarketAsset(
                    {
                      amount: qty,
                      type: 'synthetic',
                    },
                    { marketName, showSymbol: true },
                  )}
                </Box>
              </OrderSideColored>
            </TableCell>
          )
        },
      },
      {
        id: 'total_value',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.total-value.title',
          defaultMessage: 'Total Value',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.value],
        cell: (info) => {
          const [marketName, value] =
            info.getValue<[AccountTrade['market'], AccountTrade['value']]>()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: value,
                  type: 'collateral',
                },
                { marketName, showSymbol: true },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'fee',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.fee-paid.title',
          defaultMessage: 'Fee Paid',
        }),
        size: 70,
        accessorFn: (row) => [row.fee, row.isTaker],
        cell: (info) => {
          const [fee, isTaker] =
            info.getValue<[AccountTrade['fee'], AccountTrade['isTaker']]>()

          return (
            <TableCell>
              <ValueColored>{formatUsd(fee.negated())}</ValueColored>
              <Box>
                {isTaker ? (
                  <FormattedMessage id="common.fee.type.taker" defaultMessage="Taker" />
                ) : (
                  <FormattedMessage id="common.fee.type.maker" defaultMessage="Maker" />
                )}
              </Box>
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.trade-type.title',
          defaultMessage: 'Trade Type',
        }),
        size: 70,
        accessorKey: 'tradeType',
        cell: (info) => {
          const value = info.getValue<AccountTrade['tradeType']>()

          return (
            <TableCell>
              <FormattedMessage {...getTradeTypeIntlKey(value)} />
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.trades.column.time.title',
          defaultMessage: 'Time',
        }),
        size: 70,
        accessorKey: 'createdTime',
        cell: (info) => {
          const value = info.getValue<AccountTrade['createdTime']>()

          return <DateTimeTableCell value={value} />
        },
      },
    ],
    [formatMessage, formatMarketAsset, formatUsd],
  )
}
