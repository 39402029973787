import { FormattedMessage } from '@x10/lib-core/i18n'
import { Tabs } from '@x10/lib-ui-kit/components'

import { useOrders } from '@src/domain/api/hooks/account/use-orders'
import { usePositions } from '@src/domain/api/hooks/account/use-positions'
import { formatValueOverLimit } from '@src/domain/core/utils/format-value-over-limit'
import { TradingHistoryTab } from '@src/domain/trade/ui/widgets/trading-history/common'

const ITEMS_LIMIT = 99

const formatItemsCount = (value: number) => {
  return value <= ITEMS_LIMIT ? value.toString() : formatValueOverLimit(ITEMS_LIMIT)
}

export const TradingHistoryTabsList = () => {
  const { data: positions } = usePositions()
  const { data: orders } = useOrders()

  return (
    <Tabs.List>
      <Tabs.Trigger value={TradingHistoryTab.Positions}>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.navigation.tab.positions.title"
          defaultMessage="Positions ({ count })"
          values={{ count: formatItemsCount(positions.length) }}
        />
      </Tabs.Trigger>
      <Tabs.Trigger value={TradingHistoryTab.OpenOrders}>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.navigation.tab.open-orders.title"
          defaultMessage="Open Orders ({ count })"
          values={{ count: formatItemsCount(orders.length) }}
        />
      </Tabs.Trigger>
      <Tabs.Trigger value={TradingHistoryTab.HistoricalOrders}>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.navigation.tab.orders-history.title"
          defaultMessage="Orders History"
        />
      </Tabs.Trigger>
      <Tabs.Trigger value={TradingHistoryTab.Trades}>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.navigation.tab.trades.title"
          defaultMessage="Trades"
        />
      </Tabs.Trigger>
      <Tabs.Trigger value={TradingHistoryTab.Funding}>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.navigation.tab.funding.title"
          defaultMessage="Funding"
        />
      </Tabs.Trigger>
      <Tabs.Trigger value={TradingHistoryTab.RealisedPnl}>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.navigation.tab.realised-pnl.title"
          defaultMessage="Realised PnL"
        />
      </Tabs.Trigger>

      <Tabs.Trigger value={TradingHistoryTab.Transfers}>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.navigation.tab.transfers.title"
          defaultMessage="Transfers"
        />
      </Tabs.Trigger>
    </Tabs.List>
  )
}
