import type { FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { HexString } from '@x10/lib-core/types'
import { HStack } from '@x10/lib-styled-system/jsx'
import { Button, Link, SvgIcon, TableCell } from '@x10/lib-ui-kit/components'

import type { UserAssetOperation } from '@src/domain/api/x10'
import {
  openDepositRejectedDialog,
  useIsAccountBlockedWithPendingDeposit,
} from '@src/domain/auth/hooks/use-client-status'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { maskString } from '@src/domain/core/mask-string'
import { ValueHex } from '@src/domain/core/ui/components/value-hex'
import { etherscanRoute } from '@src/domain/core/utils/routes'
import {
  useWithdrawalState,
  useWithdrawalStore,
} from '@src/domain/trade/hooks/use-withdrawal-state'

import { TransferOperation } from './transfer-operation'

const TxLink: FC<{ value: HexString }> = ({ value }) => {
  return (
    <HStack css={{ gap: 's-4' }}>
      <ValueHex>{maskString(value)}</ValueHex>
      <Link href={etherscanRoute({ type: 'tx', value })} external>
        <SvgIcon.SvgIconLink size={16} />
      </Link>
    </HStack>
  )
}

export const TransferDetailsCell: FC<{ transfer: UserAssetOperation }> = ({
  transfer,
}) => {
  const isFeatureEnabled = useIsFeatureEnabled()

  const { withdraw } = useWithdrawalState()
  const isWritingContractPending = useWithdrawalStore(
    (state) => state.isWritingContractPending,
  )
  const isPendingWithdrawalAvailable = useWithdrawalStore(
    (state) => state.isPendingWithdrawalAvailable,
  )

  let isAccountBlockedWithPendingDeposit = false

  if (isFeatureEnabled('ACCOUNT_BLOCKING')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks -- stable if feature is enabled or disabled
    isAccountBlockedWithPendingDeposit = useIsAccountBlockedWithPendingDeposit()
  }

  const renderContent = () => {
    switch (transfer.type) {
      case 'DEPOSIT':
        if (transfer.status === 'REJECTED' && isAccountBlockedWithPendingDeposit) {
          return (
            <Button
              visual="primary-green"
              size="action"
              css={{ width: 'auto', fontSize: 'fs-12', fontWeight: 'fw-400' }}
              onClick={() => openDepositRejectedDialog()}
            >
              <FormattedMessage id="common.action.claim" defaultMessage="Claim" />
            </Button>
          )
        }
        return null
      case 'SLOW_WITHDRAWAL':
        if (transfer.status === 'READY_FOR_CLAIM' && isPendingWithdrawalAvailable) {
          return (
            <Button
              visual="primary-green"
              size="action"
              loading={isWritingContractPending}
              css={{ width: 'auto', fontSize: 'fs-12', fontWeight: 'fw-400' }}
              onClick={() => withdraw()}
            >
              <FormattedMessage id="common.action.claim" defaultMessage="Claim" />
            </Button>
          )
        }

        if (transfer.transactionHash) {
          return <TxLink value={transfer.transactionHash} />
        }

        return null
      case 'FAST_WITHDRAWAL':
        if (transfer.transactionHash) {
          return <TxLink value={transfer.transactionHash} />
        }

        return null
      case 'TRANSFER':
        return <TransferOperation transfer={transfer} />
      default:
        return null
    }
  }

  return <TableCell>{renderContent()}</TableCell>
}
