import { useCallback, useRef, useState } from 'react'

import { useIntl } from '@x10/lib-core/i18n'

import type {
  FormValidationErrors,
  FormValidationFieldName,
  FormValidationHook,
  FormValidationResult,
  FormValidationTouchedFields,
} from '@src/domain/trade/validation/types'

export const REFERRAL_CODE_LENGTH = 6

type FieldName = FormValidationFieldName<'referralCode'>
type Value = {
  referralCode: string
}
type Result = FormValidationResult<unknown, { errors: FormValidationErrors<FieldName> }>

export const useFormValidation = (): FormValidationHook<
  FieldName,
  Value,
  unknown,
  Result
> => {
  const { formatMessage } = useIntl()

  const [isTouched, setTouched] = useState<FormValidationTouchedFields<FieldName>>({})
  const isValidatedAtLeastOnceRef = useRef(false)

  const touch = useCallback((fieldName: FieldName) => {
    isValidatedAtLeastOnceRef.current &&
      setTouched((prev) => ({ ...prev, [fieldName]: true }))
  }, [])

  const validate = useCallback(
    (value: Value): Result => {
      isValidatedAtLeastOnceRef.current = true

      return value.referralCode.length === REFERRAL_CODE_LENGTH
        ? { success: true }
        : {
            success: false,
            errors: {
              referralCode: formatMessage({
                id: 'core.component.referral-code-dialog.step.code-form.code-input.error.invalid-format',
                defaultMessage: 'Invalid code format',
              }),
            },
          }
    },
    [formatMessage],
  )

  const reset = useCallback((fields?: FieldName[]) => {
    if (!fields) {
      setTouched({})
      return
    }

    setTouched((prev) => ({
      ...prev,
      ...fields.reduce((acc, field) => {
        acc[field] = false
        return acc
      }, {} as FormValidationTouchedFields<FieldName>),
    }))
  }, [])

  return {
    isTouched,
    touch,
    validate,
    reset,
  }
}
