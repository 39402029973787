import { type FC, type PropsWithChildren } from 'react'
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  type DragEndEvent,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

import { useStableElementId } from '@x10/lib-core/hooks'
import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import type { MarketAssets } from '@x10/lib-core/types'
import { Box, Flex, VStack } from '@x10/lib-styled-system/jsx'
import {
  Checkbox,
  MoreButton,
  Popover,
  Portal,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { CSS_STATIC_COLUMN_WIDTH_VAR } from '@src/domain/core/config/static'
import { getItemTitleIntlKey } from '@src/domain/trade/ui/widgets/market-stats/utils/get-item-title-intl-key'

import { type ItemId } from '../../constants'

const MarketStatsViewOptionsItem = (props: PropsWithChildren<{ id: ItemId }>) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: props.id,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  }

  return (
    <Flex ref={setNodeRef} style={style} w="100%">
      {props.children}

      <Box flex={1} />

      <Box {...listeners} {...attributes}>
        <SvgIcon.SvgIconDrag />
      </Box>
    </Flex>
  )
}

type MarketStatsViewOptionsProps = {
  items: Array<{ id: ItemId; active: boolean }>
  assets: MarketAssets
  onChange: (items: Array<{ id: ItemId; active: boolean }>) => void
}

export const MarketStatsViewOptions: FC<MarketStatsViewOptionsProps> = ({
  items,
  assets,
  onChange,
}) => {
  const { formatMessage } = useIntl()
  const dndContextId = useStableElementId()
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((value) => value.id === active.id)
      const newIndex = items.findIndex((value) => value.id === over.id)

      const newItems = arrayMove(items, oldIndex, newIndex)

      onChange(newItems)
    }
  }

  const handleActiveChange = (itemId: ItemId, active: boolean) => {
    const itemIndex = items.findIndex((value) => value.id === itemId)

    onChange(items.toSpliced(itemIndex, 1, { id: itemId, active }))
  }

  return (
    <Popover.Root portalled lazyMount positioning={{ placement: 'bottom-start' }} modal>
      <Popover.Context>
        {({ open }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <MoreButton active={open} />
              </Popover.Trigger>

              <Portal>
                <Popover.Positioner>
                  <Popover.Content
                    borderRadius="r-16"
                    p="s-16"
                    style={{ width: `var(${CSS_STATIC_COLUMN_WIDTH_VAR})` }}
                  >
                    <Box textStyle="caption">
                      <FormattedMessage
                        id="workspace.trade.widget.market-stats.view-options.title"
                        defaultMessage="View options"
                      />
                    </Box>

                    <DndContext
                      id={dndContextId}
                      sensors={sensors}
                      collisionDetection={closestCenter}
                      onDragEnd={handleDragEnd}
                    >
                      <SortableContext
                        items={items}
                        strategy={verticalListSortingStrategy}
                      >
                        <VStack mt="s-8" gap="s-8">
                          {items.map((item) => {
                            const intlKey = getItemTitleIntlKey(item.id, assets)

                            return (
                              <MarketStatsViewOptionsItem key={item.id} id={item.id}>
                                <Checkbox
                                  defaultChecked={item.active}
                                  onCheckedChange={({ checked }) =>
                                    handleActiveChange(item.id, checked === true)
                                  }
                                >
                                  {formatMessage(
                                    {
                                      id: intlKey.id,
                                      defaultMessage: intlKey.defaultMessage,
                                    },
                                    intlKey.values,
                                  )}
                                </Checkbox>
                              </MarketStatsViewOptionsItem>
                            )
                          })}
                        </VStack>
                      </SortableContext>
                    </DndContext>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
