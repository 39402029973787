import { useMemo } from 'react'
import { create } from 'zustand'

import { FilterPeriodValue } from '@x10/lib-ui-kit/components'

import {
  type OrderSide,
  type OrderStatus,
  type OrderType,
} from '@src/domain/api/x10/common'
import type {
  AssetOperationStatus,
  AssetOperationType,
} from '@src/domain/api/x10/trading/account/asset-operations.schema'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { type MarketFilter } from '../types/filters'

type PeriodFilter = {
  period?: FilterPeriodValue
}

type OpenOrdersFilters = {
  orderSide?: OrderSide[]
  orderType?: OrderType[]
  orderStatus?: OrderStatus[]
}

type HistoricalOrdersFilters = {
  orderType?: OrderType[]
  orderStatus?: OrderStatus[]
} & PeriodFilter

type TradesFilters = PeriodFilter

type FundingFilters = PeriodFilter

type RealisedPnlFilters = PeriodFilter

type TransfersFilters = {
  transferAction?: AssetOperationType[]
  transferStatus?: AssetOperationStatus[]
} & PeriodFilter

const defaultPeriodFilter = { period: FilterPeriodValue.OneWeek }

export const useTradingHistoryTableFiltersStore = create<{
  market: MarketFilter
  openOrders: OpenOrdersFilters
  historicalOrders: HistoricalOrdersFilters
  trades: TradesFilters
  funding: FundingFilters
  realisedPnl: RealisedPnlFilters
  transfers: TransfersFilters
}>(() => ({
  market: 'all',
  openOrders: {},
  historicalOrders: defaultPeriodFilter,
  trades: defaultPeriodFilter,
  funding: defaultPeriodFilter,
  realisedPnl: defaultPeriodFilter,
  transfers: defaultPeriodFilter,
}))

export const TradingHistoryTableFiltersStoreActions = {
  setMarket: (value: MarketFilter) => {
    useTradingHistoryTableFiltersStore.setState({ market: value })
  },

  setOpenOrders: (value: OpenOrdersFilters) => {
    useTradingHistoryTableFiltersStore.setState({ openOrders: value })
  },

  setHistoricalOrders: (value: HistoricalOrdersFilters) => {
    useTradingHistoryTableFiltersStore.setState({ historicalOrders: value })
  },

  setTrades: (value: TradesFilters) => {
    useTradingHistoryTableFiltersStore.setState({ trades: value })
  },

  setFunding: (value: FundingFilters) => {
    useTradingHistoryTableFiltersStore.setState({ funding: value })
  },

  setRealisedPnl: (value: RealisedPnlFilters) => {
    useTradingHistoryTableFiltersStore.setState({ realisedPnl: value })
  },

  setTransfers: (value: TransfersFilters) => {
    useTradingHistoryTableFiltersStore.setState({ transfers: value })
  },
}

export const useFilteredMarketsNames = () => {
  const filterValue = useTradingHistoryTableFiltersStore((state) => state.market)
  const selectedMarket = useSelectedMarket()

  return useMemo(
    () => (filterValue === 'current' ? [selectedMarket.name] : undefined),
    [filterValue, selectedMarket.name],
  )
}
