import { FormattedMessage } from '@x10/lib-core/i18n'
import { Cell } from '@x10/lib-ui-kit/components'

import { useNextClaim } from '@src/domain/api/hooks/account/use-next-claim'
import { useCountdown } from '@src/domain/core/hooks/use-countdown'

export const ClaimCounter = () => {
  const nextClaimTime = useNextClaim()
  const isClaimAllowed = nextClaimTime < Date.now()
  const countdown = useCountdown({ targetDate: nextClaimTime })

  return !isClaimAllowed && countdown ? (
    <Cell.Item>
      <Cell.Title>
        <FormattedMessage
          id="workspace.trade.widget.account.details.claim-funds.title"
          defaultMessage="You can claim more test funds in"
        />
      </Cell.Title>

      <Cell.Value
        css={{
          color: 'dark.green',
        }}
      >
        {countdown}
      </Cell.Value>
    </Cell.Item>
  ) : null
}
