import { useCallback, useEffect, useMemo, useRef, type FC } from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import { checkRequired, Decimal } from '@x10/lib-core/utils'
import { Box, HStack, VStack } from '@x10/lib-styled-system/jsx'
import { InputMessage, SimpleSelect } from '@x10/lib-ui-kit/components'

import { LoggedInGuard } from '@src/domain/auth/components/logged-in-guard'
import { getOrderTriggerPriceIntlKey } from '@src/domain/i18n/utils/get-order-trigger-price-intl-key'
import type {
  OrderTriggerDirection,
  OrderTriggerPriceType,
} from '@src/domain/starkex/stark-perpetual-order'
import { MoneyInput } from '@src/domain/trade/components/money-input'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidgetContent } from '@src/domain/trade/ui/components/trade-widget'
import { getPlaceholderForPrecision } from '@src/domain/trade/utils/get-placeholder-for-precision'

import { useFormValidationWithCtx } from '../../hooks/use-form-validation'
import { NewOrderStoreActions, useNewOrderStore } from '../../store/new-order'
import { ActionButtons } from '../action-buttons'
import { AdvancedSection } from '../advanced-section'
import { MoneyInputWithSlider } from '../money-input-with-slider'
import { OrderInfo } from '../order-info'
import { OrderPriceInput, type OrderPriceInputApi } from './order-price-input'
import { useUpdateMarketPrice } from './use-update-market-price'

const useTriggerPriceTypeOptions = () => {
  const { formatMessage } = useIntl()

  return useMemo<Array<{ label: string; value: OrderTriggerPriceType }>>(() => {
    return [
      {
        label: formatMessage(getOrderTriggerPriceIntlKey('MARK')),
        value: 'MARK',
      },
      {
        label: formatMessage(getOrderTriggerPriceIntlKey('LAST')),
        value: 'LAST',
      },
      {
        label: formatMessage(getOrderTriggerPriceIntlKey('INDEX')),
        value: 'INDEX',
      },
    ]
  }, [formatMessage])
}

const TRIGGER_DIRECTION_OPTIONS: Array<{ label: string; value: OrderTriggerDirection }> =
  [
    {
      label: '≤',
      value: 'DOWN',
    },
    {
      label: '≥',
      value: 'UP',
    },
  ]

export const ConditionalOrder: FC = () => {
  const { formatMessage } = useIntl()

  const market = useSelectedMarket()
  const price = useNewOrderStore((state) => state.buy.price)
  const triggerDirection = useNewOrderStore((state) => state.conditional.triggerDirection)
  const triggerPrice = useNewOrderStore((state) => state.conditional.triggerPrice)
  const triggerPriceType = useNewOrderStore((state) => state.conditional.triggerPriceType)

  const triggerPriceTypeOptions = useTriggerPriceTypeOptions()

  const { form, triggerPriceError, orderPriceError, orderSizeError } =
    useFormValidationWithCtx('CONDITIONAL')
  const orderPriceInputApiRef = useRef<OrderPriceInputApi>(null)

  useUpdateMarketPrice(market.name)

  const getPriceInputApi = useCallback(() => {
    return checkRequired(orderPriceInputApiRef.current, 'orderPriceInputApiRef')
  }, [])

  const handleQtyValueChange = useCallback(
    (value: { buy: number; sell: number; pct?: number } | null) => {
      const newBuyQty = Decimal.fromNullableValue(value?.buy)
      const newSellQty = Decimal.fromNullableValue(value?.sell)

      getPriceInputApi().stopFollowingLatestPrice()

      NewOrderStoreActions.setAmountOfSynthetic(newBuyQty, newSellQty, value?.pct)
    },
    [getPriceInputApi],
  )

  useEffect(() => {
    getPriceInputApi().startFollowingLatestPrice()
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market.name, form.reset, getPriceInputApi])

  const collateralPrecisionDp = market.assets.collateral.precision.getDecimalPlaces()

  return (
    <TradeWidgetContent>
      <VStack css={{ px: 's-16', gap: 's-16', alignItems: 'start' }}>
        <Box aria-invalid={Boolean(triggerPriceError)} className="group">
          <HStack w="100%" gap="s-2">
            <SimpleSelect.Root
              value={triggerDirection}
              options={TRIGGER_DIRECTION_OPTIONS}
              onValueChange={(value) => {
                NewOrderStoreActions.setTriggerDirection(value)
              }}
            >
              <SimpleSelect.Control />
              <SimpleSelect.Content>
                {TRIGGER_DIRECTION_OPTIONS.map((item) => (
                  <SimpleSelect.Item key={item.value} item={item} />
                ))}
              </SimpleSelect.Content>
            </SimpleSelect.Root>

            <MoneyInput.Item
              value={Decimal.toNullableNumber(triggerPrice)}
              currency={market.assets.collateral.code}
              precision={collateralPrecisionDp}
              placeholder={getPlaceholderForPrecision(collateralPrecisionDp)}
              label={formatMessage({
                id: 'workspace.trade.widget.order-form.shared.input.trigger-price.title',
                defaultMessage: 'Trigger Price',
              })}
              onBlur={() => form.touch('triggerPrice')}
              onChange={(value) => {
                NewOrderStoreActions.setTriggerPrice(Decimal.fromNullableValue(value))
              }}
            />

            <SimpleSelect.Root
              css={{ minW: '6.25rem' }}
              value={triggerPriceType}
              options={triggerPriceTypeOptions}
              onValueChange={(value) => {
                NewOrderStoreActions.setTriggerPriceType(value)
              }}
            >
              <SimpleSelect.Control />
              <SimpleSelect.Content>
                {triggerPriceTypeOptions.map((item) => (
                  <SimpleSelect.Item key={item.value} item={item} />
                ))}
              </SimpleSelect.Content>
            </SimpleSelect.Root>
          </HStack>

          <InputMessage>{triggerPriceError}</InputMessage>
        </Box>

        <OrderPriceInput
          ref={orderPriceInputApiRef}
          assets={market.assets}
          error={orderPriceError}
          onBlur={() => form.touch('orderPrice')}
          onPriceTypeChange={() => form.reset(['orderPrice'])}
        />

        <MoneyInputWithSlider
          aria-invalid={Boolean(orderSizeError)}
          message={orderSizeError}
          assets={market.assets}
          syntheticPrice={price}
          onBlur={() => form.touch('orderSize')}
          onSliderChange={() => form.reset(['orderSize'])}
          onValueChange={handleQtyValueChange}
        />
        <OrderInfo marketName={market.name} />
        <LoggedInGuard>
          <AdvancedSection allowPostOnlyAndTimeInForce />
        </LoggedInGuard>
      </VStack>

      <ActionButtons hideBelow="md" />
    </TradeWidgetContent>
  )
}
