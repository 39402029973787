import { Decimal, invariant } from '@x10/lib-core/utils'

import { type Computed, type Context, type ErrorMessage, type Rule } from '../types'

/**
 * [51:Price Validations]
 * 1. [Short Limit Orders] Order Price ≥ Mark Price * (1-Limit Order Floor Ratio)
 * 2. [Long Limit Orders] Order Price ≤ Mark Price * (1+Limit Order Price Cap)
 * [54:Price Validations]
 * If Conditional Order is triggered at placement we also apply the same validations as to Limit orders
 */
export const limitOrderPrice: Rule<
  unknown,
  Pick<Context, 'market' | 'marketStats' | 'orderType'>,
  Pick<Computed, 'orderSide' | 'orderPrice' | 'isConditionalTriggeredAtPlacement'>,
  Pick<ErrorMessage, 'getOrderPriceMark'>
> = (_value, ctx, computed, _alerts, errors, errorMessage) => {
  if (
    errors.orderPrice ||
    (ctx.orderType !== 'LIMIT' && !computed.isConditionalTriggeredAtPlacement)
  ) {
    return
  }

  invariant(ctx.marketStats, '`marketStats` is required')
  invariant(computed.orderPrice, '`orderPrice` is required')

  if (computed.orderSide === 'SELL') {
    const minPrice = ctx.marketStats.markPrice.times(
      Decimal.ONE.minus(ctx.market.tradingConfig.limitPriceFloor),
    )

    if (computed.orderPrice.lt(minPrice)) {
      errors.form = errorMessage.getOrderPriceMark(computed.orderSide, minPrice)
    }
  } else {
    const maxPrice = ctx.marketStats.markPrice.times(
      Decimal.ONE.plus(ctx.market.tradingConfig.limitPriceCap),
    )

    if (computed.orderPrice.gt(maxPrice)) {
      errors.form = errorMessage.getOrderPriceMark(computed.orderSide, maxPrice)
    }
  }
}
