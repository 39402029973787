import { useState } from 'react'

import { CRYPTO_CURRENCY_INFO } from '@x10/lib-core/config'
import { FormattedMessage } from '@x10/lib-core/i18n'
import { Decimal, invariant, Long } from '@x10/lib-core/utils'
import { Box, Flex } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Button,
  Callout,
  Cell,
  Dialog,
  Link,
  SimpleSelect,
  SvgIcon,
  Tooltip,
  ValueChange,
} from '@x10/lib-ui-kit/components'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'
import { useSuspendedBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import { useTransfer } from '@src/domain/api/hooks/account/use-transfer'
import { useAccountStarkPrivateKey } from '@src/domain/auth/hooks/use-account-stark-private-key'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'
import { useFormatUsd } from '@src/domain/core/hooks/use-format-usd'
import { DialogContent } from '@src/domain/core/ui/components/dialog/dialog-content'
import { createNotificationToast } from '@src/domain/core/ui/components/notification'
import { StarkTransfer } from '@src/domain/starkex/stark-transfer'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { BalanceMoneyInputWithSlider } from './balance-money-input-with-slider'
import { CurrencySelect } from './currency-select'

export const TransferDialogContent = () => {
  const [value, setValue] = useState('0')
  const accounts = useAccounts()
  const accountId = useAccountId()
  const formatUsd = useFormatUsd({ usdcSymbol: true })
  const transfer = useTransfer()
  const accountOptions = accounts
    .filter((it) => it.accountId.toString() !== accountId)
    .map((acc) => {
      return {
        label: acc.description,
        value: acc.accountId.toString(),
      }
    })
  invariant(
    accountOptions[0]?.value,
    "Shouldn't render this component if user has only one account",
  )
  const [toAccountId, setToAccountIdId] = useState(accountOptions[0].value)
  const { availableForWithdrawal } = useSuspendedBalance()

  const isFormValid = Decimal(value).gt(0) && Decimal(value).lte(availableForWithdrawal)

  const market = useSelectedMarket()
  const { getStarkPrivateKey } = useAccountStarkPrivateKey()

  const [transferPending, setTransferPending] = useState(false)
  return (
    <DialogContent>
      <Flex
        css={{
          textStyle: 'primary',
          w: '100%',
          justifyContent: 'space-between',
          pr: 's-32',
        }}
      >
        <Dialog.Title>
          <FormattedMessage
            id="workspace.trade.widget.account.action.transfer.dialog.title"
            defaultMessage="Transfer"
          />
        </Dialog.Title>
      </Flex>
      <Dialog.CloseTrigger asChild>
        <ActionIcon
          css={{
            pos: 'absolute',
            top: 's-16',
            right: 's-16',
          }}
        >
          <SvgIcon.SvgIconCross />
        </ActionIcon>
      </Dialog.CloseTrigger>
      <Flex
        css={{
          pt: 's-32',
          gap: 0,
          flexDirection: 'column',
        }}
      >
        <SimpleSelect.Root
          css={{
            width: '100%',
          }}
          value={toAccountId}
          options={accountOptions}
          onValueChange={(value) => {
            setToAccountIdId(value)
          }}
        >
          <SimpleSelect.ControlWithLabel label="To" />

          <SimpleSelect.Content>
            {accountOptions.map((item) => (
              <SimpleSelect.Item key={item.value} item={item} />
            ))}
          </SimpleSelect.Content>
        </SimpleSelect.Root>
        <CurrencySelect />
        <BalanceMoneyInputWithSlider
          balance={availableForWithdrawal}
          currencyInfo={CRYPTO_CURRENCY_INFO['USDC']}
          onValueChange={(value) => setValue(value.toString())}
        />
        <Tooltip.Root
          lazyMount
          unmountOnExit
          closeDelay={100}
          openDelay={50}
          interactive
          positioning={{ placement: 'bottom' }}
        >
          <Tooltip.Trigger asChild>
            <Cell.Group
              css={{
                mt: 's-16',
              }}
            >
              <Cell.Item>
                <Cell.Title
                  css={{
                    textStyle: 'small',
                    fontWeight: 'fw-500',
                  }}
                >
                  <FormattedMessage
                    id="workspace.trade.widget.account.action.transfer.dialog.available-to-transfer"
                    defaultMessage="Available to Transfer"
                  />
                </Cell.Title>
                <Cell.Value
                  css={{
                    textStyle: 'small',
                    fontWeight: 'fw-500',
                  }}
                >
                  <ValueChange.Root>
                    <ValueChange.Item>
                      {formatUsd(availableForWithdrawal, { showSymbol: value === '0' })}
                    </ValueChange.Item>

                    {value !== '0' ? (
                      <ValueChange.Item>
                        {availableForWithdrawal.minus(Decimal(value)).gte(Decimal.ZERO)
                          ? formatUsd(availableForWithdrawal.minus(Decimal(value)))
                          : EMPTY_CELL_VALUE}
                      </ValueChange.Item>
                    ) : null}
                  </ValueChange.Root>
                </Cell.Value>
              </Cell.Item>
            </Cell.Group>
          </Tooltip.Trigger>
          <Tooltip.Positioner>
            <Tooltip.Content
              css={{
                whiteSpace: 'break-spaces',
              }}
            >
              <FormattedMessage
                id="workspace.trade.widget.account.action.transfer.dialog.available-to-transfer.tooltip"
                defaultMessage={`Available Balance for Transfers = Wallet Balance + min(0,Unrealised PnL) - Initial Margin Requirement.\n\nFor more information, please refer to the <a>documentation</a>.`}
                values={{
                  a: (chunks) => {
                    return (
                      <Link
                        href="https://docs.x10.exchange/x10-resources/trading/trading-accounts-and-margin#account-balances"
                        external
                      >
                        {chunks}
                      </Link>
                    )
                  },
                }}
              />
            </Tooltip.Content>
          </Tooltip.Positioner>
        </Tooltip.Root>
        <Box
          css={{
            color: 'token.white-50',
            mt: 's-16',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.action.transfer.dialog.transfer-note"
            defaultMessage="Transfers between accounts are free."
          />
        </Box>
        <Callout
          visual="error"
          css={{
            mt: 's-16',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.action.transfer.dialog.transfer-warn-note"
            defaultMessage="Transfer will affect the liquidation price for your open positions."
          />
        </Callout>
      </Flex>
      <Dialog.Context>
        {({ setOpen }) => (
          <Tooltip.Root
            lazyMount
            unmountOnExit
            closeDelay={0}
            openDelay={50}
            disabled={isFormValid || Decimal(value).eq(Decimal.ZERO)}
            positioning={{ placement: 'top' }}
          >
            <Tooltip.Trigger asChild>
              <Button
                visual="primary-green"
                size="large"
                css={{
                  mt: '2.5rem',
                }}
                disabled={!isFormValid}
                loading={transferPending}
                onClick={async () => {
                  setTransferPending(true)
                  try {
                    const starkPrivateKey = await getStarkPrivateKey()
                    if (starkPrivateKey.isErr()) {
                      return // user rejected signing
                    }
                    invariant(accountId, 'AccountId should be defined')
                    await transfer.mutateAsync(
                      StarkTransfer.create({
                        fromAccountId: Long(accountId),
                        toAccountId: Long(toAccountId),
                        amount: Decimal(value),
                        transferredAsset: 'USD',
                        ctx: {
                          accounts,
                          l2Config: market.l2Config,
                          starkPrivateKey: starkPrivateKey.value,
                        },
                      }),
                    )
                    createNotificationToast({
                      notification: {
                        type: 'TRANSFER',
                        status: 'success',
                      },
                    })
                    setOpen(false)
                    setTransferPending(false)
                  } catch (error) {
                    captureExceptionWithSentry(error)

                    createNotificationToast({
                      notification: {
                        type: 'TRANSFER',
                        status: 'failed',
                      },
                    })
                    setTransferPending(false)
                  }
                }}
              >
                <FormattedMessage
                  id="workspace.trade.widget.account.action.transfer.dialog.action"
                  defaultMessage="Transfer"
                />
              </Button>
            </Tooltip.Trigger>
            <Tooltip.Positioner>
              <Tooltip.Content>
                <FormattedMessage
                  id="workspace.trade.widget.account.action.transfer.dialog.action.tooltip"
                  defaultMessage="Your available balance is not sufficient to complete the transfer."
                />
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Tooltip.Root>
        )}
      </Dialog.Context>
    </DialogContent>
  )
}
