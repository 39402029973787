import { useSubscribeToPublicTrades } from '@src/domain/api/hooks/stream/use-subscribe-to-public-trades'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { LatestTradesHeader } from './components/latest-trades-header'
import { LatestTradesTable } from './components/latest-trades-table'

export const LatestTrades = () => {
  const market = useSelectedMarket()
  const { data } = useSubscribeToPublicTrades(market.name)

  return (
    <TradeWidget.InnerRoot>
      <LatestTradesHeader />
      <LatestTradesTable data={data} />
    </TradeWidget.InnerRoot>
  )
}
