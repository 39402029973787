import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { Cell, Popover, Portal, SvgIcon } from '@x10/lib-ui-kit/components'

import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { useSelectedMarket } from '@src/domain/trade/store/market'

export const TradingRules = () => {
  const market = useSelectedMarket()
  const { tradingConfig } = market

  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })
  const formatPercent = useFormatPercent()

  return (
    <Popover.Root
      portalled
      lazyMount
      unmountOnExit
      positioning={{ placement: 'bottom-start' }}
    >
      <Popover.Context>
        {({ open }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <HStack
                  css={{
                    gap: 's-8',
                    cursor: 'pointer',
                    textStyle: 'primary',
                    color: open ? 'token.white' : 'token.white-50',
                    _hover: { color: 'token.white' },
                  }}
                >
                  <FormattedMessage
                    id="workspace.trade.widget.chart.trading-rules.title"
                    defaultMessage="Trading Rules"
                  />

                  <SvgIcon.SvgIconInfoCircle size={16} />
                </HStack>
              </Popover.Trigger>

              <Portal>
                <Popover.Positioner>
                  <Popover.Content borderRadius="r-16" p="s-16">
                    <Box textStyle="primary" mb="s-24">
                      <FormattedMessage
                        id="workspace.trade.widget.chart.trading-rules.title"
                        defaultMessage="Trading Rules"
                      />
                    </Box>

                    <Cell.Group>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.min-trade-size.title"
                            defaultMessage="Minimum Trade Size"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatMarketAsset(
                            {
                              amount: tradingConfig.minOrderSize,
                              type: 'synthetic',
                            },
                            {
                              precision: AssetPrecision.AsIs,
                            },
                          )}
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.min-trade-size-change.title"
                            defaultMessage="Minimum Change in Trade Size"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatMarketAsset(
                            {
                              amount: tradingConfig.minOrderSizeChange,
                              type: 'synthetic',
                            },
                            {
                              precision: AssetPrecision.AsIs,
                            },
                          )}
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.min-price-change.title"
                            defaultMessage="Minimum Price Change"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatMarketAsset(
                            {
                              amount: tradingConfig.minPriceChange,
                              type: 'collateral',
                            },
                            {
                              precision: AssetPrecision.AsIs,
                            },
                          )}
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.max-market-order.title"
                            defaultMessage="Maximum Market Order"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatMarketAsset(
                            {
                              amount: tradingConfig.maxMarketOrderValue,
                              type: 'collateral',
                            },
                            {
                              precision: AssetPrecision.TradingRules,
                            },
                          )}
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.max-limit-order.title"
                            defaultMessage="Maximum Limit Order"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatMarketAsset(
                            {
                              amount: tradingConfig.maxLimitOrderValue,
                              type: 'collateral',
                            },
                            {
                              precision: AssetPrecision.TradingRules,
                            },
                          )}
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.max-position-value.title"
                            defaultMessage="Max Position Value"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatMarketAsset(
                            {
                              amount: tradingConfig.maxPositionValue,
                              type: 'collateral',
                            },
                            {
                              precision: AssetPrecision.TradingRules,
                            },
                          )}
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.max-open-orders-number.title"
                            defaultMessage="Max № of Open Orders"
                          />
                        </Cell.Title>
                        <Cell.Value>{tradingConfig.maxNumOrders.toString()}</Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.trading-rules.items.limit-price-cap-floor.title"
                            defaultMessage="Limit Price Cap / Floor"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatPercent(tradingConfig.limitPriceCap, {
                            precision: PercentPrecision.TradingRules,
                          })}
                        </Cell.Value>
                      </Cell.Item>
                    </Cell.Group>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
