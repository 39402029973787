import { FormattedMessage } from '@x10/lib-core/i18n'
import { Button, Dialog, Portal, Tooltip } from '@x10/lib-ui-kit/components'

import { useClaimFunds } from '@src/domain/api/hooks/account/use-claim-funds'
import { useNextClaim } from '@src/domain/api/hooks/account/use-next-claim'
import { useClientSettingsStore } from '@src/domain/core/store/user-settings'

import { ClaimFundsDialogContent } from './claim-funds-dialog-content'
import { ClaimFundsOpener } from './claim-funds-opener'

export const ClaimFundsDialog = () => {
  const claimFunds = useClaimFunds()
  const nextClaimTime = useNextClaim()
  const isClaimAllowed = nextClaimTime < Date.now()
  const closeAutoOpenedClaimFundsDialog = useClientSettingsStore(
    (state) => state.closeAutoOpenedClaimFundsDialog,
  )
  return (
    <Dialog.Root
      modal
      lazyMount
      unmountOnExit
      closeOnEscape={!claimFunds.isPending}
      closeOnInteractOutside={!claimFunds.isPending}
      onOpenChange={(isOpen) => {
        if (!isOpen.open) {
          closeAutoOpenedClaimFundsDialog()
        }
      }}
    >
      <Dialog.Context>
        {({ setOpen }) => (
          <>
            <ClaimFundsOpener onOpen={() => setOpen(true)} />
            <Tooltip.Root
              lazyMount
              unmountOnExit
              closeDelay={0}
              openDelay={0}
              disabled={isClaimAllowed}
            >
              <Tooltip.Trigger asChild>
                <Dialog.Trigger asChild>
                  <Button
                    css={{
                      w: '100%',
                    }}
                    visual={isClaimAllowed ? 'primary-green' : 'secondary-grey'}
                    disabled={!isClaimAllowed}
                  >
                    <FormattedMessage
                      id="workspace.trade.widget.account.action.claim-funds.title"
                      defaultMessage="Claim funds"
                    />
                  </Button>
                </Dialog.Trigger>
              </Tooltip.Trigger>
              <Portal>
                <Tooltip.Positioner>
                  <Tooltip.Content>
                    <FormattedMessage
                      id="workspace.trade.widget.account.action.claim-funds.tooltip"
                      defaultMessage="Users can claim test USDC once in 24 hours."
                    />
                  </Tooltip.Content>
                </Tooltip.Positioner>
              </Portal>
            </Tooltip.Root>
            <Portal>
              <Dialog.Backdrop />
              <Dialog.Positioner>
                <ClaimFundsDialogContent
                  isClaimPending={claimFunds.isPending}
                  onClaimFunds={async () => {
                    await claimFunds.mutateAsync()
                    setOpen(false)
                  }}
                />
              </Dialog.Positioner>
            </Portal>
          </>
        )}
      </Dialog.Context>
    </Dialog.Root>
  )
}
