import { type Decimal } from '@x10/lib-core/utils'

import { calcInitialMarginRate } from './calc-initial-margin-rate'

type CalcPositionMargin = {
  positionSize: Decimal
  markPrice: Decimal
  leverage: Decimal
}

export const calcPositionMargin = ({
  positionSize,
  markPrice,
  leverage,
}: CalcPositionMargin) => {
  return positionSize.times(markPrice).times(calcInitialMarginRate(leverage)).abs()
}
