import { invariant } from '@x10/lib-core/utils'

import type { Computed, Context, ErrorMessage, Rule, Value } from '../types'

/**
 * [67:Risk Alert]
 * Order Cost ≤ Available Balance
 * (For Market Reduce-Only)
 */
export const riskForOrderCost: Rule<
  Pick<Value, 'orderCost'>,
  Pick<Context, 'balance'>,
  Pick<Computed, 'isMarketReduceOnly' | 'orderSide'>,
  Pick<ErrorMessage, 'getOrderCostMarketReduceOnly'>
> = (value, ctx, computed, alerts, _errors, errorMessage) => {
  if (!value.orderCost || !computed.isMarketReduceOnly) {
    return
  }

  invariant(ctx.balance, '`balance` is required')

  const orderCost =
    computed.orderSide === 'BUY' ? value.orderCost.buy : value.orderCost.sell

  if (orderCost.gt(ctx.balance.availableForTrade)) {
    alerts.push(errorMessage.getOrderCostMarketReduceOnly())
  }
}
