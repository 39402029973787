import { FormattedMessage } from '@x10/lib-core/i18n'
import {
  Cell,
  Popover,
  Portal,
  ShevronTrigger,
  SimpleSwitch,
} from '@x10/lib-ui-kit/components'

import { useTradeContext } from '@src/domain/trade/providers/trade-provider'

export const DisplayOptions = () => {
  const { quickTradingMode, setQuickTradingMode } = useTradeContext()

  return (
    <Popover.Root portalled lazyMount positioning={{ placement: 'bottom-start' }}>
      <Popover.Context>
        {({ open }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <ShevronTrigger open={open}>
                  <FormattedMessage
                    id="workspace.trade.widget.chart.tab.market.toolbar.display-option.title"
                    defaultMessage="Display"
                  />
                </ShevronTrigger>
              </Popover.Trigger>
              <Portal>
                <Popover.Positioner>
                  <Popover.Content borderRadius="r-16" p="s-16">
                    <Cell.Group gap="s-16">
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.tab.market.toolbar.display-option.quick-trading-market-order.title"
                            defaultMessage="Quick Trading Features (Market Order)"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          <SimpleSwitch
                            checked={quickTradingMode === 'MARKET'}
                            onCheckedChange={({ checked }) => {
                              setQuickTradingMode(checked ? 'MARKET' : undefined)
                            }}
                          />
                        </Cell.Value>
                      </Cell.Item>

                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.tab.market.toolbar.display-option.quick-trading-limit-order.title"
                            defaultMessage="Quick Trading Features (Limit Order)"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          <SimpleSwitch
                            checked={quickTradingMode === 'LIMIT'}
                            onCheckedChange={({ checked }) => {
                              setQuickTradingMode(checked ? 'LIMIT' : undefined)
                            }}
                          />
                        </Cell.Value>
                      </Cell.Item>

                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.tab.market.toolbar.display-option.show-positions-info.title"
                            defaultMessage="Show Positions Info"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          <SimpleSwitch />
                        </Cell.Value>
                      </Cell.Item>

                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.chart.tab.market.toolbar.display-option.show-order-info.title"
                            defaultMessage="Show Order Info"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          <SimpleSwitch />
                        </Cell.Value>
                      </Cell.Item>
                    </Cell.Group>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
