import { Fragment, useEffect, useMemo, useState } from 'react'
import { addDays, addHours } from 'date-fns'
import { orderBy } from 'lodash'

import { FormattedMessage, useFormatDateTime } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import {
  Box,
  Center,
  Flex,
  Grid,
  GridItem,
  HStack,
  styled,
} from '@x10/lib-styled-system/jsx'
import {
  ChartLegend,
  FilterPeriod,
  FilterPeriodValue,
  Scrollable,
  Spinner,
} from '@x10/lib-ui-kit/components'

import { useOpenInterests } from '@src/domain/api/hooks/markets-info/use-open-interests'
import { type OpenInterestsInterval } from '@src/domain/api/x10/trading/markets-info/open-interests'
import { ResponsiveChartWrapper } from '@src/domain/core/ui/components/responsive-chart-wrapper'
import { abbreviateNumber } from '@src/domain/core/utils/abbreviate-number'
import { formatTime } from '@src/domain/core/utils/format-time'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { ChartFullScreenButton } from '@src/domain/trade/ui/components/chart-full-screen-button'
import { getChartStartTime } from '@src/domain/trade/utils/get-chart-start-time'

import { OpenInterestPopoverChart } from '../open-interest-popover-chart'

const getOpenInterestsPeriodInterval = (
  period: FilterPeriodValue,
): OpenInterestsInterval => {
  switch (period) {
    case FilterPeriodValue.OneDay:
      return 'PT1H'
    case FilterPeriodValue.OneWeek:
      return 'P1D'
    case FilterPeriodValue.OneMonth:
      return 'P1D'
    default:
      notReachable(period)
  }
}

const REFETCH_INTERVAL_BUFFER = 10_000 // add buffer of 10 seconds to avoid refetching too early

const getOpenInterestsRefetchInterval = (
  period: FilterPeriodValue,
  lastTimestamp: number,
) => {
  const currentTimestamp = Date.now()

  if (period === FilterPeriodValue.OneDay) {
    const interval =
      addHours(lastTimestamp, 1).getTime() - currentTimestamp + REFETCH_INTERVAL_BUFFER

    if (interval < 0) {
      return addHours(currentTimestamp, 1).getTime() - currentTimestamp
    }

    return interval
  }

  if ([FilterPeriodValue.OneWeek, FilterPeriodValue.OneMonth].includes(period)) {
    const interval =
      addDays(lastTimestamp, 1).getTime() - currentTimestamp + REFETCH_INTERVAL_BUFFER

    if (interval < 0) {
      return addDays(currentTimestamp, 1).getTime() - currentTimestamp
    }

    return interval
  }

  return notReachable(period)
}

const ChartSpinner = () => {
  return (
    <Center
      css={{
        w: '100%',
        h: '100%',
      }}
    >
      <Spinner />
    </Center>
  )
}

export const OpenInterestPopoverContent = () => {
  const { formatDate } = useFormatDateTime()
  const [selectedPeriod, setSelectedPeriod] = useState(FilterPeriodValue.OneDay)
  const [endTime, setEndTime] = useState(Date.now())

  const market = useSelectedMarket()

  const { data, isFetching } = useOpenInterests({
    marketName: market.name,
    startTime: getChartStartTime(selectedPeriod, endTime),
    endTime,
    interval: getOpenInterestsPeriodInterval(selectedPeriod),
  })

  useEffect(() => {
    const lastDataPoint = data[0]
    const lastTimestamp = lastDataPoint?.timestamp ?? Date.now()

    const timerId = setTimeout(
      () => {
        setEndTime(Date.now())
      },
      getOpenInterestsRefetchInterval(selectedPeriod, lastTimestamp),
    )

    return () => clearTimeout(timerId)
  }, [data, selectedPeriod])

  const sortedData = useMemo(() => orderBy(data, 'timestamp', 'desc'), [data])

  const { synthetic, collateral } = market.assets

  return (
    <>
      <Box>
        <HStack
          css={{
            textStyle: 'primary',
            justifyContent: 'space-between',
            gap: 's-16',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.market-stats.item.funding-rate.details.title"
            defaultMessage="Open Interest"
          />

          <ChartFullScreenButton />
        </HStack>

        <Flex
          css={{
            mt: 's-16',
            alignItems: 'center',
            textStyle: 'caption',
          }}
        >
          <FilterPeriod
            periods={[
              FilterPeriodValue.OneDay,
              FilterPeriodValue.OneWeek,
              FilterPeriodValue.OneMonth,
            ]}
            customPeriod={false}
            onChange={setSelectedPeriod}
          />
        </Flex>
      </Box>

      <Box
        css={{
          minH: '20rem',
          h: '20rem',
          mt: 's-8',
          mx: '-s-8',
        }}
      >
        <ResponsiveChartWrapper>
          {({ width, height }) => {
            return isFetching ? (
              <ChartSpinner />
            ) : (
              <OpenInterestPopoverChart
                data={data}
                market={market.name}
                period={selectedPeriod}
                width={width}
                height={height}
              />
            )
          }}
        </ResponsiveChartWrapper>
      </Box>

      <styled.hr css={{ mx: '-s-16', color: 'token.white-10' }} />

      <Box css={{ mt: 's-8' }}>
        <ChartLegend.Root>
          <ChartLegend.Item bg="token.white">
            <FormattedMessage
              id="workspace.trade.widget.market-stats.item.open-interest.details.column.open-interest.title"
              defaultMessage="Open Interest {currency}"
              values={{ currency: collateral.code }}
            />
          </ChartLegend.Item>

          <ChartLegend.Item bg="token.green-50">
            <FormattedMessage
              id="workspace.trade.widget.market-stats.item.open-interest.details.column.open-interest.title"
              defaultMessage="Open Interest {currency}"
              values={{ currency: synthetic.code }}
            />
          </ChartLegend.Item>
        </ChartLegend.Root>
      </Box>

      <Scrollable scrollY css={{ mt: 's-24', flex: 1 }}>
        <Grid columns={3} gap="s-16">
          <GridItem
            css={{
              textStyle: 'caption',
              color: 'token.white-50',
              position: 'sticky',
              top: '0',
              left: '0',
              bg: 'token.grey-90',
            }}
          >
            <FormattedMessage
              id="workspace.trade.widget.market-stats.item.open-interest.details.column.time.title"
              defaultMessage="Time"
            />
          </GridItem>

          <GridItem
            css={{
              textStyle: 'caption',
              color: 'token.white-50',
              position: 'sticky',
              top: '0',
              left: '0',
              bg: 'token.grey-90',
            }}
          >
            <FormattedMessage
              id="workspace.trade.widget.market-stats.item.open-interest.details.column.open-interest.title"
              defaultMessage="Open Interest {currency}"
              values={{ currency: collateral.code }}
            />
          </GridItem>

          <GridItem
            css={{
              textStyle: 'caption',
              textAlign: 'right',
              color: 'token.white-50',
              position: 'sticky',
              top: '0',
              left: '0',
              bg: 'token.grey-90',
            }}
          >
            <FormattedMessage
              id="workspace.trade.widget.market-stats.item.open-interest.details.column.open-interest.title"
              defaultMessage="Open Interest {currency}"
              values={{ currency: synthetic.code }}
            />
          </GridItem>

          {sortedData.map((value) => {
            return (
              <Fragment key={value.timestamp}>
                <GridItem textStyle="small">
                  <Box>{formatDate(value.timestamp, { dateStyle: 'medium' })}</Box>
                  <Box>{formatTime(value.timestamp)}</Box>
                </GridItem>

                <GridItem display="flex" textStyle="small" alignItems="center">
                  {abbreviateNumber(value.openInterest.toNumber())}
                </GridItem>

                <GridItem
                  display="flex"
                  textStyle="small"
                  alignItems="center"
                  justifyContent="right"
                >
                  {abbreviateNumber(value.openInterestBase.toNumber())}
                </GridItem>
              </Fragment>
            )
          })}
        </Grid>
      </Scrollable>
    </>
  )
}
