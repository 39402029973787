import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@src/domain/api/x10'
import { QueryKey } from '@src/domain/trade/constants'

export const useTransfer = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: API.trading.account.transfer,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.UserBalance] })
    },
  })
}
