import { useState, type FC } from 'react'

import { checkRequired } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { useDisclosure } from '@x10/lib-ui-kit/hooks'

import type { OrderSide } from '@src/domain/api/x10/common'
import { useIsTradingDisabled } from '@src/domain/auth/hooks/use-client-status'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { useNewOrderCostStore } from '../../store/new-order-cost'
import { useNewOrderMaxValueStore } from '../../store/new-order-max-value'
import { RiskAlerts } from '../risk-alerts'
import { BuyButton } from './components/buy-button'
import { SellButton } from './components/sell-button'
import { useCreateAndPlaceOrder } from './hooks/use-create-and-place-order'

export const PlaceOrderButtons: FC = () => {
  const market = useSelectedMarket()

  const isTradingDisabled = useIsTradingDisabled()

  const { orderCost } = useNewOrderCostStore()
  const { orderMaxValue } = useNewOrderMaxValueStore()
  const {
    buyErrors,
    buyAlerts,
    sellErrors,
    sellAlerts,
    isPlacingOrder,
    createAndPlaceOrder,
  } = useCreateAndPlaceOrder(market)

  const { onOpen, onClose } = useDisclosure()
  const [alerts, setAlerts] = useState<string[]>()
  const [alertSide, setAlertSide] = useState<OrderSide>()

  const handleBuyOrderPlacement = () => {
    if (buyAlerts && buyAlerts.length > 0) {
      setAlerts(buyAlerts)
      setAlertSide('BUY')
      onOpen()

      return
    }

    return createAndPlaceOrder('BUY')
  }

  const handleSellOrderPlacement = () => {
    if (sellAlerts && sellAlerts.length > 0) {
      setAlerts(sellAlerts)
      setAlertSide('SELL')
      onOpen()

      return
    }

    return createAndPlaceOrder('SELL')
  }

  const handleRiskAlertsClose = () => {
    setAlerts(undefined)
    setAlertSide(undefined)
    onClose()
  }

  const handleRiskAlertsConfirm = () => {
    const result = createAndPlaceOrder(checkRequired(alertSide, 'alertSide'))
    handleRiskAlertsClose()

    return result
  }

  return (
    <Box css={{ position: 'relative' }}>
      <HStack css={{ gap: 0, w: '100%' }}>
        <BuyButton
          disabled={isTradingDisabled}
          orderCost={orderCost?.buy}
          maxOrderValue={orderMaxValue?.buy}
          errors={buyErrors}
          loading={isPlacingOrder}
          onClick={handleBuyOrderPlacement}
        />
        <SellButton
          disabled={isTradingDisabled}
          orderCost={orderCost?.sell}
          maxOrderValue={orderMaxValue?.sell}
          errors={sellErrors}
          loading={isPlacingOrder}
          onClick={handleSellOrderPlacement}
        />
      </HStack>

      {alerts && (
        <RiskAlerts
          alerts={alerts}
          confirm={handleRiskAlertsConfirm}
          close={handleRiskAlertsClose}
        />
      )}
    </Box>
  )
}
