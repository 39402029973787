import { create } from 'zustand'

type AccountSelectorState = {
  isSelectorOpen: boolean
  isCurrentlyEditing: boolean
  isCreatingFormOpen: boolean
  onClose: VoidFunction
  onOpen: VoidFunction
  setIsCreatingFormOpen: (it: boolean) => void
  accountIndexUnderEdit: number | undefined
  startEdit: (accountIndex: number) => void
  stopEdit: VoidFunction
}

export const useAccountSelectorState = create<AccountSelectorState>((set) => ({
  isSelectorOpen: false,
  isCurrentlyEditing: false,
  isCreatingFormOpen: false,
  accountIndexUnderEdit: undefined,
  onClose: () =>
    set({
      isSelectorOpen: false,
    }),
  onOpen: () =>
    set({
      isSelectorOpen: true,
    }),
  setIsCreatingFormOpen: (it: boolean) => {
    set({
      isCreatingFormOpen: it,
    })
  },
  startEdit: (accountIndex) => {
    set({
      accountIndexUnderEdit: accountIndex,
      isCurrentlyEditing: true,
    })
  },
  stopEdit: () => {
    set({
      accountIndexUnderEdit: undefined,
      isCurrentlyEditing: false,
    })
  },
}))
