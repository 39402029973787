import { type FC, type PropsWithChildren } from 'react'

import { cva } from '@x10/lib-styled-system/css'
import { Box } from '@x10/lib-styled-system/jsx'
import { token } from '@x10/lib-styled-system/tokens'
import type { SystemStyleObject } from '@x10/lib-styled-system/types'
import { Portal, Tooltip } from '@x10/lib-ui-kit/components'

const tooltipRecipe = cva({
  base: {
    minW: 'unset',
  },
  variants: {
    visual: {
      info: {},
      error: {
        color: 'token.red',
        bg: 'token.black-80',
        bgImage:
          'linear-gradient(0deg, var(--bg-error-gradient) 0%, var(--bg-error-gradient) 100%)',
      },
    },
  },
})

type InputTooltipProps = {
  open?: boolean
  visual: 'info' | 'error'
  positionerCss?: SystemStyleObject
}

export const InputTooltip: FC<PropsWithChildren<InputTooltipProps>> = ({
  open,
  visual,
  positionerCss,
  children,
}) => {
  return (
    <Tooltip.Root
      lazyMount
      unmountOnExit
      open={open}
      positioning={{ placement: 'top-start' }}
    >
      <Tooltip.Trigger asChild>
        <Box css={{ position: 'absolute', left: 0, bottom: 0, top: 0, w: 1 }} />
      </Tooltip.Trigger>

      <Portal>
        <Tooltip.Positioner css={positionerCss}>
          <Tooltip.Content
            style={{
              '--bg-error-gradient': token('colors.token.red-10'),
            }}
            className={tooltipRecipe({ visual })}
          >
            {children}
          </Tooltip.Content>
        </Tooltip.Positioner>
      </Portal>
    </Tooltip.Root>
  )
}
