import type { CandlePriceSource, MarketName } from '@src/domain/api/x10/common'
import type {
  RangeOptions,
  ResolutionString,
  SeriesType,
  TimezoneInfo,
} from '@src/types/charting-library'

export type TradingViewTicker = `${MarketName}:${CandlePriceSource}:${string}:${string}`

export enum TradingViewSeriesType {
  Bars = 0,
  Candles = 1,
  Line = 2,
  Area = 3,
  HeikenAshi = 8,
  HollowCandles = 9,
  Baseline = 10,
  HiLo = 12,
  Column = 13,
  LineWithMarkers = 14,
  Stepline = 15,
  HLCArea = 16,
  Renko = 4,
  Kagi = 5,
  PointAndFigure = 6,
  LineBreak = 7,
}

export enum TradingViewPriceScaleMode {
  /** Normal mode of the price scale */
  Normal = 0,
  /** Logarithmic mode of the price scale */
  Log = 1,
  /** Percentage mode of the price scale */
  Percentage = 2,
  /** Indexed to 100 mode of the price scale */
  IndexedTo100 = 3,
}

export type CompareOrAddMode = 'new-price-scale' | 'same-pct-scale' | 'new-pane'

export type TradingViewCommand =
  | { name: 'take-snapshot' }
  | {
      name: 'compare-or-add-symbol'
      args: {
        mode: CompareOrAddMode
        symbolName: TradingViewTicker
      }
    }
  | { name: 'set-range'; args: { range: RangeOptions } }
  | { name: 'set-interval'; args: { interval: ResolutionString } }
  | { name: 'set-timezone'; args: { timezoneId: TimezoneInfo['id'] } }
  | { name: 'add-indicator'; args: { indicatorName: string } }
  | { name: 'set-chart-type'; args: { chartType: SeriesType } }
  | {
      name: 'set-price-scale-mode'
      args: { mode: TradingViewPriceScaleMode | undefined; auto: boolean | undefined }
    }
  | { name: 'show-chart-settings' }
