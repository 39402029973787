import { invariant } from '@x10/lib-core/utils'

import type { Context, ErrorMessage, Rule, Value } from '../types'

export const minTriggerPrice: Rule<
  Pick<Value, 'triggerPrice'>,
  Pick<Context, 'market' | 'orderType'>,
  unknown,
  Pick<ErrorMessage, 'getTriggerPriceMin'>
> = (value, ctx, _computed, _alerts, errors, errorMessage) => {
  if (errors.triggerPrice || ctx.orderType !== 'CONDITIONAL') {
    return
  }

  invariant(value.triggerPrice, '`triggerPrice` is required')

  if (value.triggerPrice.lt(ctx.market.tradingConfig.minPriceChange)) {
    errors.triggerPrice = errorMessage.getTriggerPriceMin(
      ctx.market.tradingConfig.minPriceChange,
    )
  }
}
