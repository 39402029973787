import { isUndefined, noop } from 'lodash'

import {
  checkRequired,
  getAssetUrl,
  getUserTimeZone,
  notReachable,
} from '@x10/lib-core/utils'
import { DEFAULT_COLOR_SCHEME } from '@x10/lib-ui-kit/config'

import type { X10Interval } from '@src/domain/api/x10/common'
import { TRADING_VIEW_VERSION } from '@src/domain/core/config/static'
import type {
  ChartingLibraryFeatureset,
  ChartPropertiesOverrides,
  IBasicDataFeed,
  IChartingLibraryWidget,
  LanguageCode,
  Overrides,
  PriceScaleMode,
  SeriesType,
  TimezoneId,
} from '@src/types/charting-library'

import { type TradingViewCommand, type TradingViewTicker } from '../../types/common'
import {
  DEFAULT_INTERVAL_TIMEFRAME,
  toResolutionString,
  X10ToTVTimeframe,
} from '../../utils/trading-view-chart-api/constants'
import { LocalStorageSaveLoadAdapter } from './local-storage-save-load-adapter'

const DEFAULT_CHART_NAME = 'TV'
const ENABLED_FEATURES: ChartingLibraryFeatureset[] = [
  'saveload_separate_drawings_storage',
]
const DISABLED_FEATURES: ChartingLibraryFeatureset[] = [
  'edit_buttons_in_legend',
  'header_widget',
  'timeframes_toolbar',
  'volume_force_overlay',
]

type CreateTradingViewApiArgs = {
  container: HTMLDivElement
  datafeed: IBasicDataFeed
  overrides: Partial<ChartPropertiesOverrides>
  locale: LanguageCode
  symbol: TradingViewTicker
  interval: X10Interval
  hideLeftToolbar?: boolean
}

// Some dirty hack to workaround TrandingView API instability
// This threshold is neeeded to prevent false triggers of zooming in
// barSpacingChanged() events. which is used to detect manual zoom in/out events
// see https://www.tradingview.com/charting-library-docs/latest/api/interfaces/Charting_Library.ITimeScaleApi#barspacingchanged
// Sometimes it is triggered falsy
const BAR_CHANGES_THRESHOLD = 5

/**
 * Wrapper around TradingView API to make it more convenient to use.
 */
export class TradingViewChartApi {
  private api: IChartingLibraryWidget
  private symbol: TradingViewTicker
  private barChanges: number = 0

  static create({
    container,
    datafeed,
    overrides,
    locale,
    symbol,
    interval,
    hideLeftToolbar,
  }: CreateTradingViewApiArgs) {
    const disabledFeatures = [...DISABLED_FEATURES]

    if (hideLeftToolbar) {
      disabledFeatures.push('left_toolbar')
    }

    const saveLoadAdapter = new LocalStorageSaveLoadAdapter()
    const api = new window.TradingView.widget({
      container,
      autosize: true,

      library_path: getAssetUrl({
        type: 'tradingview',
        name: `charting_library_${TRADING_VIEW_VERSION}/`,
      }),
      datafeed,

      locale,
      enabled_features: ENABLED_FEATURES,
      disabled_features: disabledFeatures,
      symbol,
      timeframe: X10ToTVTimeframe[DEFAULT_INTERVAL_TIMEFRAME[interval]],
      interval: toResolutionString(interval),
      timezone: getUserTimeZone() as TimezoneId,
      theme: DEFAULT_COLOR_SCHEME,
      custom_css_url: getAssetUrl({
        type: 'tradingview',
        name: 'customisation/x10-tradingview.css',
      }),
      settings_overrides: overrides as Overrides,
      // https://www.tradingview.com/charting-library-docs/latest/saving_loading/save-load-adapter/
      save_load_adapter: saveLoadAdapter,
    })

    saveLoadAdapter.startStorageSync()

    api.onChartReady(() => {
      api.subscribe('onAutoSaveNeeded', () => {
        // Uses `save_load_adapter` to save chart state
        api.saveChartToServer(noop, noop, {
          defaultChartName: DEFAULT_CHART_NAME,
        })
      })
    })

    return new TradingViewChartApi(api, symbol)
  }

  private constructor(api: IChartingLibraryWidget, symbol: TradingViewTicker) {
    this.api = api
    this.symbol = symbol
  }

  public getTimezone() {
    return this.api.activeChart().getTimezoneApi().getTimezone()
  }

  public getAvailableTimezones() {
    return this.api
      .activeChart()
      .getTimezoneApi()
      .availableTimezones()
      .filter((tz) => tz.id !== 'exchange')
  }

  public getStudies() {
    return this.api.getStudiesList()
  }

  public setSymbol(symbol: TradingViewTicker) {
    this.symbol = symbol
    this.api.activeChart().setSymbol(symbol)
  }

  public getSymbol() {
    // We can't use `api.activeChart().symbol()` because it returns only a symbol name,
    // not an original `TradingViewTicker` string.
    return this.symbol
  }

  public onChartReady(callback: () => void) {
    this.api.onChartReady(callback)
  }

  public onScreenshotReady(callback: (url: string) => void) {
    this.api.subscribe('onScreenshotReady', callback)

    return () => this.api.unsubscribe('onScreenshotReady', callback)
  }

  public onChartZoom(callback: () => void) {
    this.api
      .activeChart()
      .getTimeScale()
      .barSpacingChanged()
      .subscribe(null, () => {
        if (this.barChanges < BAR_CHANGES_THRESHOLD) {
          this.barChanges++
          return
        }

        callback()
      })

    this.api
      .activeChart()
      .onIntervalChanged()
      .subscribe(null, () => {
        this.barChanges = 0
      })
  }

  public remove() {
    this.api.remove()
  }

  // @ts-expect-error it's ok to ignore the return value
  public executeCommand(command: TradingViewCommand) {
    switch (command.name) {
      case 'take-snapshot':
        this.api.takeScreenshot()
        break
      case 'compare-or-add-symbol':
        switch (command.args.mode) {
          case 'new-price-scale':
            return this.api.activeChart().createStudy(
              'Compare',
              false,
              false,
              {
                source: 'open',
                symbol: command.args.symbolName,
              },
              undefined,
              {
                priceScale: 'new-left',
              },
            )
          case 'same-pct-scale':
            return this.api.activeChart().createStudy(
              'Compare',
              false,
              false,
              {
                source: 'open',
                symbol: command.args.symbolName,
              },
              undefined,
              {
                priceScale: 'as-series',
              },
            )
          case 'new-pane':
            return this.api.activeChart().createStudy('Overlay', false, false, {
              source: 'open',
              symbol: command.args.symbolName,
            })
          default:
            throw notReachable(command.args.mode)
        }
      case 'set-range':
        this.api.activeChart().setTimeFrame(command.args.range)
        break
      case 'set-interval':
        this.api.activeChart().setResolution(command.args.interval)
        break
      case 'set-timezone':
        this.api.activeChart().getTimezoneApi().setTimezone(command.args.timezoneId)
        break
      case 'add-indicator':
        return this.api.activeChart().createStudy(command.args.indicatorName)
      case 'set-chart-type':
        this.api
          .activeChart()
          .setChartType(command.args.chartType as unknown as SeriesType)
        break
      case 'set-price-scale-mode':
        const pane = checkRequired(this.api.activeChart().getPanes()[0], 'pane')
        const priceScales = checkRequired(pane.getRightPriceScales()[0], 'priceScales')

        if (!isUndefined(command.args.mode)) {
          priceScales.setMode(command.args.mode as unknown as PriceScaleMode)
        }

        if (!isUndefined(command.args.auto)) {
          priceScales.setAutoScale(command.args.auto)
        }
        break
      case 'show-chart-settings':
        this.api.activeChart().executeActionById('chartProperties')
        break
      default:
        throw notReachable(command)
    }
  }
}
