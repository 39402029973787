import * as z from 'zod'

import { zodDecimal, zodLong } from '@src/domain/api/utils/zod'
import { OrderSideSchema } from '@src/domain/api/x10'
import { MarketNameSchema, TradeTypeSchema } from '@src/domain/api/x10/common'

import { addStreamEnvelope } from '../../utils/add-stream-envelope'

const PublicTradeSchema = z
  .object({
    i: zodLong(),
    m: MarketNameSchema,
    S: OrderSideSchema,
    tT: TradeTypeSchema,
    T: z.number(),
    p: zodDecimal(),
    q: zodDecimal(),
  })
  .transform((value) => {
    return {
      id: value.i,
      market: value.m,
      side: value.S,
      tradeType: value.tT,
      timestamp: value.T,
      price: value.p,
      qty: value.q,
    }
  })

export const EnvelopedPublicTradeSchema = addStreamEnvelope(z.array(PublicTradeSchema))

export type PublicTrade = z.infer<typeof PublicTradeSchema>
