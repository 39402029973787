import { useEffect } from 'react'

import type { MarketName } from '@src/domain/api/x10/common'
import { useOrderbookPricesStore } from '@src/domain/trade/store/orderbook-prices-store'

import { NewOrderStoreActions } from '../../store/new-order'

export const useUpdateMarketPrice = (marketName: MarketName) => {
  const { bestBidPrice, bestAskPrice } = useOrderbookPricesStore()

  useEffect(() => {
    NewOrderStoreActions.setPrice(bestAskPrice ?? null, bestBidPrice ?? null, false)
  }, [marketName, bestBidPrice, bestAskPrice])
}
