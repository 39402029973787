import { invariant } from '@x10/lib-core/utils'

import type { Computed, Context, ErrorMessage, Rule, Value } from '../types'
import { checkMarketLiquidity, getMarketPrice } from '../utils'

/**
 * [69:Risk Alert]
 * Conditional Limit Order (both regular & post-only) with both trigger and order prices that lead to immediate execution
 */
export const riskForImmediateConditionalLimit: Rule<
  Pick<Value, 'orderPriceType' | 'triggerPrice'>,
  Pick<Context, 'orderType' | 'marketStats'>,
  Pick<Computed, 'orderPrice' | 'orderSide' | 'isConditionalTriggeredAtPlacement'>,
  Pick<ErrorMessage, 'getExecutedImmediatelyAsMarket'>
> = (value, ctx, computed, alerts, errors, errorMessage) => {
  if (
    errors.triggerPrice ||
    errors.orderPrice ||
    ctx.orderType !== 'CONDITIONAL' ||
    value.orderPriceType !== 'LIMIT'
  ) {
    return
  }

  invariant(ctx.marketStats, '`marketStats` is required')
  invariant(value.triggerPrice, '`triggerPrice` is required')
  invariant(computed.orderPrice, '`orderPrice` is required')

  const marketPrice = getMarketPrice(computed.orderSide, ctx.marketStats)

  if (
    checkMarketLiquidity(computed.orderSide, ctx.marketStats) &&
    computed.isConditionalTriggeredAtPlacement &&
    ((computed.orderSide === 'BUY' && computed.orderPrice.gte(marketPrice)) ||
      (computed.orderSide === 'SELL' && computed.orderPrice.lte(marketPrice)))
  ) {
    alerts.push(errorMessage.getExecutedImmediatelyAsMarket())
  }
}
