import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import type { AssetOperationStatus } from '@src/domain/api/x10'

export const getAssetOperationStatusIntlKey = (
  value: AssetOperationStatus,
): MessageDescriptor => {
  switch (value) {
    case 'CREATED':
      return {
        id: 'common.asset-operation.status.created',
        defaultMessage: 'Created',
      }
    case 'IN_PROGRESS':
      return {
        id: 'common.asset-operation.status.in-progress',
        defaultMessage: 'In Progress',
      }
    case 'REJECTED':
      return {
        id: 'common.asset-operation.status.rejected',
        defaultMessage: 'Rejected',
      }
    case 'READY_FOR_CLAIM':
      return {
        id: 'common.asset-operation.status.ready-for-claim',
        defaultMessage: 'Ready for Claim',
      }
    case 'COMPLETED':
      return {
        id: 'common.asset-operation.status.completed',
        defaultMessage: 'Completed',
      }
    default:
      throw notReachable(value)
  }
}
