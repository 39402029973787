import type { UserPosition } from '@src/domain/api/x10'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import { getOppositePositionSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'
import { useCreateAndPlaceTpSlOrder } from '@src/domain/trade/hooks/use-create-and-place-tp-sl-order'
import { toOrderTpSlTriggerParam } from '@src/domain/trade/utils/to-order-tp-sl-trigger-param'

import { DialogContent as OrderDialogContent } from '../order-tp-sl-dialog'

type PositionTpSlOrderDialogContentProps = {
  position: UserPosition
  onCancel: () => void
}

export const PositionTpSlOrderDialogContent = ({
  position,
  onCancel,
}: PositionTpSlOrderDialogContentProps) => {
  const getCachedMarket = useGetCachedMarket()
  const market = getCachedMarket(position.market)

  const { createAndPlaceTpSlOrder, isPlacingOrder } = useCreateAndPlaceTpSlOrder(market)

  return (
    <OrderDialogContent
      initiator="POSITIONS_TABLE"
      marketName={position.market}
      confirming={isPlacingOrder}
      value={{
        tpSlEntrySide: position.side,
        tpSlType: undefined,
        tp: undefined,
        sl: undefined,
      }}
      onValueChange={async (value) => {
        await createAndPlaceTpSlOrder(
          toOrderSide(getOppositePositionSide(position.side)),
          'POSITION',
          toOrderTpSlTriggerParam(value.tp),
          toOrderTpSlTriggerParam(value.sl),
        )
        onCancel()
      }}
      onCancel={onCancel}
    />
  )
}
