import { type Decimal, type Long } from '@x10/lib-core/utils'

import { type StarkSignature } from '../types'
import { omitUndefined } from '../utils/omit-undefined'
import { type StarkPerpetualOrderDebuggingAmounts } from './stark-perpetual-order-debugging-amounts'
import { StarkPerpetualOrderSettlement } from './stark-perpetual-order-settlement'
import { type OrderPriceType, type OrderTriggerPriceType } from './types'

export type OrderTpSlTriggerParam = {
  triggerPrice: Decimal
  triggerPriceType: OrderTriggerPriceType
  price: Decimal
  priceType: OrderPriceType
}

export class OrderTpSlTrigger {
  private readonly triggerPrice: Decimal
  private readonly triggerPriceType: OrderTriggerPriceType
  private readonly price: Decimal
  private readonly priceType: OrderPriceType
  private readonly settlement: StarkPerpetualOrderSettlement
  private readonly debuggingAmounts: StarkPerpetualOrderDebuggingAmounts

  private constructor({
    triggerPrice,
    triggerPriceType,
    price,
    priceType,
    settlement,
    debuggingAmounts,
  }: {
    triggerPrice: Decimal
    triggerPriceType: OrderTriggerPriceType
    price: Decimal
    priceType: OrderPriceType
    settlement: StarkPerpetualOrderSettlement
    debuggingAmounts: StarkPerpetualOrderDebuggingAmounts
  }) {
    this.triggerPrice = triggerPrice
    this.triggerPriceType = triggerPriceType
    this.price = price
    this.priceType = priceType
    this.settlement = settlement
    this.debuggingAmounts = debuggingAmounts
  }

  toJSON() {
    return omitUndefined({
      triggerPrice: this.triggerPrice.toString(10),
      triggerPriceType: this.triggerPriceType,
      price: this.price.toString(10),
      priceType: this.priceType,
      settlement: this.settlement.toJSON(),
      debuggingAmounts: this.debuggingAmounts.toJSON(),
    })
  }

  static create(
    vaultId: Long,
    triggerParams: OrderTpSlTriggerParam | undefined,
    orderSignature: { signature: StarkSignature; starkKey: string } | undefined,
    debuggingAmounts: StarkPerpetualOrderDebuggingAmounts | undefined,
  ) {
    if (!triggerParams || !orderSignature || !debuggingAmounts) {
      return undefined
    }

    return new OrderTpSlTrigger({
      triggerPrice: triggerParams.triggerPrice,
      triggerPriceType: triggerParams.triggerPriceType,
      price: triggerParams.price,
      priceType: triggerParams.priceType,
      debuggingAmounts,
      settlement: new StarkPerpetualOrderSettlement({
        signature: orderSignature.signature,
        starkKey: orderSignature.starkKey,
        collateralPosition: vaultId,
      }),
    })
  }
}
