import { useMutation } from '@tanstack/react-query'

import { API } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'

export const usePlaceOrder = () => {
  const accountId = useAccountId()

  const { mutate, mutateAsync, isPending } = useMutation({
    mutationFn: (args: { order: object }) => {
      return API.trading.orderManagement.placeOrder(args, accountId)
    },
  })

  return {
    placeOrder: mutate,
    placeOrderAsync: mutateAsync,
    isPlacingOrder: isPending,
  }
}
