import { Decimal } from '@x10/lib-core/utils'

import type { UserPosition } from '@src/domain/api/x10'

import { getSignedSize } from './get-signed-size'

export const calcPositionValue = (position: UserPosition | undefined) => {
  if (!position) {
    return Decimal.ZERO
  }

  return getSignedSize(position).times(position.markPrice)
}
