import { checkRequired, Decimal } from '@x10/lib-core/utils'

import type { UserOrder } from '@src/domain/api/x10'

import { getSignedSize } from './get-signed-size'

export const calcOrdersValueSum = (items: UserOrder[]) => {
  return items.reduce((acc, order) => {
    const orderPrice = checkRequired(order.price, 'orderPrice')

    return acc.plus(getSignedSize(order).times(orderPrice))
  }, Decimal.ZERO)
}
