import { isUndefined } from 'lodash'

import { type Decimal as B } from '@x10/lib-core/utils'

export const calcSpread = (bestBidPrice: B | undefined, bestAskPrice: B | undefined) => {
  if (isUndefined(bestBidPrice) || isUndefined(bestAskPrice)) {
    return undefined
  }

  const value = bestAskPrice.minus(bestBidPrice)
  const pct = value.div(bestAskPrice)

  return { value, pct }
}
