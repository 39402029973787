import type { HexString } from '@x10/lib-core/types'
import { type Long } from '@x10/lib-core/utils'

import type { StarkSignature } from '../types'
import { signMessage } from '../utils/sign-message'
import { getWithdrawalMsgHash } from './get-withdrawal-msg-hash'

export class StarkWithdrawalSettlement {
  private readonly amount: Long
  private readonly collateralAssetId: HexString
  private readonly ethAddress: HexString
  private readonly expirationTimestamp: number
  private readonly nonce: Long
  private readonly positionId: Long
  private readonly publicKey: HexString
  private readonly signature: StarkSignature

  private constructor({
    amount,
    collateralAssetId,
    ethAddress,
    expirationTimestamp,
    nonce,
    positionId,
    publicKey,
    signature,
  }: {
    amount: Long
    collateralAssetId: HexString
    ethAddress: HexString
    expirationTimestamp: number
    nonce: Long
    positionId: Long
    publicKey: HexString
    signature: StarkSignature
  }) {
    this.amount = amount
    this.ethAddress = ethAddress
    this.collateralAssetId = collateralAssetId
    this.expirationTimestamp = expirationTimestamp
    this.nonce = nonce
    this.positionId = positionId
    this.publicKey = publicKey
    this.signature = signature
  }

  toJSON() {
    return {
      amount: this.amount.toString(10),
      collateralAssetId: this.collateralAssetId,
      ethAddress: this.ethAddress,
      expirationTimestamp: this.expirationTimestamp,
      nonce: this.nonce.toString(10),
      positionId: this.positionId,
      publicKey: this.publicKey,
      signature: this.signature,
    }
  }

  static create({
    amount,
    collateralAssetId,
    ethAddress,
    expirationTimestamp,
    nonce,
    positionId,
    publicKey,
    starkPrivateKey,
  }: {
    amount: Long
    collateralAssetId: HexString
    ethAddress: HexString
    expirationTimestamp: number
    nonce: Long
    positionId: Long
    publicKey: HexString
    starkPrivateKey: HexString
  }) {
    const transferHash = getWithdrawalMsgHash({
      amount: amount.toString(10),
      collateralAssetId,
      ethAddress,
      expirationTimestamp,
      nonce: nonce.toNumber(),
      positionId: positionId.toNumber(),
    })

    const transferSignature = signMessage(transferHash, starkPrivateKey)

    return new StarkWithdrawalSettlement({
      amount,
      collateralAssetId,
      ethAddress,
      expirationTimestamp,
      nonce,
      positionId,
      publicKey,
      signature: transferSignature.signature,
    })
  }
}
