import { subDays } from 'date-fns'
import { sortBy } from 'lodash'
import { match } from 'ts-pattern'
import { useSuspenseQuery } from '@tanstack/react-query'

import { FilterPeriodValue } from '@x10/lib-ui-kit/components'

import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

import { getCommonPeriodicQueryConfig } from '../../utils/get-common-periodic-query-config'
import type { GetAssetOperationsArgs } from '../../x10/trading/account/asset-operations'

const calcTimeFilter = (period: FilterPeriodValue | undefined) => {
  const days = match(period)
    .with(FilterPeriodValue.OneDay, () => 1)
    .with(FilterPeriodValue.OneWeek, () => 7)
    .with(FilterPeriodValue.OneMonth, () => 30)
    .otherwise(() => undefined)

  if (!days) {
    return {}
  }

  const endTime = Date.now()
  const startTime = subDays(endTime, days).getTime()

  return { startTime, endTime }
}

type UseAssetOperationsArgs = Omit<GetAssetOperationsArgs, 'startTime' | 'endTime'> & {
  period?: FilterPeriodValue
}

export const useAssetOperations = (args: UseAssetOperationsArgs = {}) => {
  const accountId = useAccountId()
  const sortedType = args.type ? sortBy(args.type) : undefined
  const sortedStatus = args.status ? sortBy(args.status) : undefined

  const { data } = useSuspenseQuery({
    queryKey: [
      QueryKey.UserAssetOperations,
      sortedType,
      sortedStatus,
      args.period,
      args.limit,
      args.cursor,
      accountId,
    ],
    queryFn: () => {
      const { startTime, endTime } = calcTimeFilter(args.period)

      return API.trading.account.getAssetOperations(
        {
          type: sortedType,
          status: sortedStatus,
          startTime,
          endTime,
          limit: args.limit,
          cursor: args.cursor,
        },
        accountId,
      )
    },
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelopeSuspense(data),
  }
}
