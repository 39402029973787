import { useMemo, type FC } from 'react'

import type { CryptoCurrencyCode } from '@x10/lib-core/config'
import { SimpleSelect } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { useOrderBookDisplayParamsStore, type OrderBookDisplaySizeAs } from '../../store'

type ValueType = `${OrderBookDisplaySizeAs}-${MarketName}`

const useDisplaySizeAsOptions = (): {
  options: Array<{ label: CryptoCurrencyCode; value: ValueType }>
  initialValue: ValueType
} => {
  const market = useSelectedMarket()
  const { displaySizeAs } = useOrderBookDisplayParamsStore()

  return useMemo(
    () => ({
      options: [
        {
          label: market.assets.synthetic.code,
          value: `synthetic-${market.name}`,
        },
        {
          label: market.assets.collateral.code,
          value: `collateral-${market.name}`,
        },
      ],
      initialValue: `${displaySizeAs}-${market.name}`,
    }),
    [
      displaySizeAs,
      market.assets.collateral.code,
      market.assets.synthetic.code,
      market.name,
    ],
  )
}

export const OrderBookDisplaySizeSelection: FC = () => {
  const { setDisplaySizeAs } = useOrderBookDisplayParamsStore()
  const { options, initialValue } = useDisplaySizeAsOptions()

  return (
    <SimpleSelect.Root
      visual="compact"
      positioning={{ placement: 'bottom-end' }}
      options={options}
      value={initialValue}
      onValueChange={(item) => {
        const value = item.split('-')[0]
        setDisplaySizeAs(value as OrderBookDisplaySizeAs)
      }}
    >
      <SimpleSelect.Control css={{ textStyle: 'primary' }} />

      <SimpleSelect.Content>
        {options.map((item) => {
          return <SimpleSelect.Item key={item.value} item={item} />
        })}
      </SimpleSelect.Content>
    </SimpleSelect.Root>
  )
}
