import type { FC } from 'react'
import { useAccount } from 'wagmi'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { WithCss } from '@x10/lib-styled-system/types'
import { Button } from '@x10/lib-ui-kit/components'

import * as analytics from '@src/domain/core/utils/analytics'

import { useWalletMenu } from '../hooks/use-wallet-menu'
import { openCreateAccountDialog } from './dialogs/create-account-dialog'

export const StartTradingButton: FC<WithCss> = (props) => {
  const { isConnecting, address } = useAccount()

  return (
    <Button
      loading={isConnecting}
      visual="primary-green"
      size="large"
      css={{
        w: '100%',
        ...props.css,
      }}
      onClick={() => {
        analytics.trackEvent(analytics.events.startTradingButtonClick())
        address ? openCreateAccountDialog() : useWalletMenu.setState({ isOpen: true })
      }}
    >
      <FormattedMessage
        id="domain.auth.component.start-trading-button.title"
        defaultMessage="Start Trading"
      />
    </Button>
  )
}
