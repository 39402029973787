import type { FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { checkRequired, type Long } from '@x10/lib-core/utils'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'
import type { AccountInfo, UserAssetOperation } from '@src/domain/api/x10'

type TransferDirection = 'IN' | 'OUT'

enum SystemAccountId {
  TradingFees = 1,
  WithdrawalFees = 2,
  InsuranceFund = 3,
  ClaimTransfers = 4,
  FastWithdrawalTransfer = 5,
}

const systemAccountDescription: Record<SystemAccountId, string> = {
  [SystemAccountId.TradingFees]: 'Trading fees',
  [SystemAccountId.WithdrawalFees]: 'Withdrawal fees',
  [SystemAccountId.InsuranceFund]: 'Insurance fund',
  [SystemAccountId.ClaimTransfers]: 'Claim Transfers',
  [SystemAccountId.FastWithdrawalTransfer]: 'Fast withdrawal transfer',
}

const getAccountDescription = (accounts: AccountInfo[], accountId: Long) => {
  if (accountId.gte(1) && accountId.lte(5)) {
    return systemAccountDescription[accountId.toNumber() as SystemAccountId]
  }

  const account = accounts.find((account) => account.accountId.eq(accountId))

  return checkRequired(account, 'account').description
}

const RebatesTransfer: FC = () => {
  return (
    <div>
      <FormattedMessage
        id="workspace.trade.widget.trading-history.mode.transfers.column.transaction-details.rebates"
        defaultMessage="Rebates"
      />
    </div>
  )
}

type GenericTransferProps = {
  direction: TransferDirection
  account1: string
  account2: string
}

const GenericTransfer: FC<GenericTransferProps> = ({ direction, account1, account2 }) => {
  const [fromAccount, toAccount] =
    direction === 'IN' ? [account2, account1] : [account1, account2]

  return (
    <div>
      <div>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.mode.transfers.column.transaction-details.from"
          defaultMessage="From: {value}"
          values={{
            value: fromAccount,
          }}
        />
      </div>
      <div>
        <FormattedMessage
          id="workspace.trade.widget.trading-history.mode.transfers.column.transaction-details.to"
          defaultMessage="To: {value}"
          values={{
            value: toAccount,
          }}
        />
      </div>
    </div>
  )
}

export const TransferOperation: FC<{ transfer: UserAssetOperation }> = ({ transfer }) => {
  const accounts = useAccounts()

  const counterpartyAccountId = checkRequired(
    transfer.counterpartyAccountId,
    'counterpartyAccountId',
  )
  const direction: TransferDirection = transfer.amount.gt(0) ? 'IN' : 'OUT'

  if (direction === 'IN' && counterpartyAccountId.eq(SystemAccountId.TradingFees)) {
    return <RebatesTransfer />
  }

  return (
    <GenericTransfer
      direction={direction}
      account1={getAccountDescription(accounts, transfer.accountId)}
      account2={getAccountDescription(accounts, counterpartyAccountId)}
    />
  )
}
