import { useState, type FC } from 'react'

import type { CryptoCurrencyInfo } from '@x10/lib-core/config'
import { Decimal } from '@x10/lib-core/utils'
import { Box } from '@x10/lib-styled-system/jsx'
import { MaxActionLink, Slider } from '@x10/lib-ui-kit/components'

import { USDC_BALANCE_PRECISION } from '@src/domain/core/config/static'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { MoneyInput } from '@src/domain/trade/components/money-input'
import { getPlaceholderForPrecision } from '@src/domain/trade/utils/get-placeholder-for-precision'

const roundDownToBalancePrecision = (value: Decimal) => {
  return value.setDecimalPlaces(USDC_BALANCE_PRECISION, Decimal.ROUND_DOWN)
}

type BalanceMoneyInputWithSliderProps = {
  balance: Decimal
  currencyInfo: CryptoCurrencyInfo
  onValueChange: (value: number) => void
}

export const BalanceMoneyInputWithSlider: FC<BalanceMoneyInputWithSliderProps> = ({
  balance,
  currencyInfo,
  onValueChange,
}) => {
  const formatPercent = useFormatPercent()

  const [value, setValue] = useState<Decimal | null>(null)
  const [balancePct, setBalancePct] = useState<number>()

  return (
    <Box
      css={{
        w: '100%',
        mt: 's-16',
      }}
    >
      <MoneyInput.Item
        currency={currencyInfo.code}
        precision={USDC_BALANCE_PRECISION}
        placeholder={getPlaceholderForPrecision(USDC_BALANCE_PRECISION)}
        value={Decimal.toNullableNumber(value)}
        quickAction={
          <MaxActionLink
            onClick={() => {
              setValue(balance)
              setBalancePct(100)

              onValueChange(balance.toNumber())
            }}
          />
        }
        overlayValue={
          balancePct && value === null
            ? formatPercent(balancePct / 100, { precision: PercentPrecision.Slider })
            : undefined
        }
        unit={currencyInfo.symbol}
        onFocus={() => {
          if (balancePct !== undefined && value === null) {
            setValue(balance.times(balancePct / 100))
          }
        }}
        onChange={(value) => {
          const nextValue = Decimal.toNullableDecimal(value)
          const percents = nextValue ? nextValue.div(balance).times(100).toNumber() : 0

          setValue(nextValue)
          setBalancePct(percents > 100 ? 100 : percents)

          onValueChange(nextValue?.toNumber() ?? 0)
        }}
      />

      <Box
        css={{
          w: '100%',
          mt: 's-16',
        }}
      >
        <Slider
          minLabel="0"
          maxLabel="100%"
          value={balancePct ?? 0}
          onChange={({ value }) => {
            setValue(null)
            setBalancePct(value)

            onValueChange(
              roundDownToBalancePrecision(
                Decimal(value).times(balance.div(100)),
              ).toNumber(),
            )
          }}
        />
      </Box>
    </Box>
  )
}
