import { useCallback, useMemo, useState, type FC } from 'react'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { getAssetUrl } from '@x10/lib-core/utils'
import { Box, VStack } from '@x10/lib-styled-system/jsx'
import { Button, Input, Link } from '@x10/lib-ui-kit/components'

import { useRegenerateKey, useRegister } from '@src/domain/auth/hooks/use-auth'
import { DISCORD_URL, TWITTER_URL } from '@src/domain/core/config/static'
import { DialogCloseTrigger } from '@src/domain/core/ui/components/dialog/dialog-close-trigger'
import { DialogImageIcon } from '@src/domain/core/ui/components/dialog/dialog-image-icon'

import { REFERRAL_CODE_LENGTH, useFormValidation } from './hooks/use-form-validation'

const sanitizeReferralCode = (value: string) => {
  return value.replace(/[^A-z0-9]+/g, '').toUpperCase()
}

type ReferralCodeFormProps = {
  onSuccessfulSubmit: () => void
  onClose: () => void
}

export const ReferralCodeStepForm: FC<ReferralCodeFormProps> = ({
  onSuccessfulSubmit,
  onClose,
}) => {
  const { formatMessage } = useIntl()
  const register = useRegister()
  const regenerateKey = useRegenerateKey()

  const [isSubmitting, setSubmitting] = useState(false)
  const [referralCode, setReferralCode] = useState('')
  const [showApiError, setShowApiError] = useState(false)

  const { touch, validate, isTouched } = useFormValidation()
  const validationResult = useMemo(() => {
    return validate({ referralCode }, null)
  }, [referralCode, validate])

  const referralCodeApiError = showApiError
    ? formatMessage({
        id: 'core.component.referral-code-dialog.step.code-form.code-input.error.invalid-or-used',
        defaultMessage: 'The code is already used or invalid.',
      })
    : undefined
  const referralCodeValidationError =
    isTouched.referralCode && !validationResult.success
      ? validationResult.errors.referralCode
      : undefined
  const referralCodeError = referralCodeValidationError ?? referralCodeApiError

  const handleCodeSubmit = useCallback(
    async (code: string) => {
      setSubmitting(true)

      try {
        const resultRegenerateKey = await regenerateKey()

        if (resultRegenerateKey.isErr()) {
          return
        }

        const resultRegister = await register({ referralCode: code })

        if (resultRegister.isErr()) {
          if (resultRegister.error === 'USER_REJECTED_REQUEST') {
            return
          }

          setShowApiError(true)
        } else {
          onSuccessfulSubmit()
        }
      } finally {
        setSubmitting(false)
      }
    },
    [regenerateKey, register, onSuccessfulSubmit],
  )

  return (
    <VStack css={{ gap: 's-16' }}>
      <DialogCloseTrigger onClick={onClose} />

      <DialogImageIcon
        src={getAssetUrl({ type: '3d-icon', name: 'rubik-cube@2x' })}
        css={{ mt: 's-48' }}
      />

      <div>
        <Box css={{ textStyle: 'h2', textAlign: 'center', mx: 's-4' }}>
          <FormattedMessage
            id="core.component.referral-code-dialog.step.code-form.title"
            defaultMessage="Your wallet is not currently eligible"
          />
        </Box>

        <Box css={{ textStyle: 'secondary', textAlign: 'center', mt: 's-8' }}>
          <FormattedMessage
            id="core.component.referral-code-dialog.step.code-form.description.title"
            defaultMessage="However, you can gain access with a referral code! Join our <discord-link>Discord</discord-link> community and follow us on <twitter-link>X (Twitter)</twitter-link> to get a referral code."
            values={{
              'discord-link': (chunks) => (
                <Link href={DISCORD_URL} visual="primary-green" external>
                  {chunks}
                </Link>
              ),
              'twitter-link': (chunks) => (
                <Link href={TWITTER_URL} visual="primary-green" external>
                  {chunks}
                </Link>
              ),
            }}
          />
        </Box>
      </div>

      <Box css={{ mt: 's-32', mb: 's-16', w: '100%' }}>
        <Input
          aria-invalid={Boolean(referralCodeError)}
          label={formatMessage({
            id: 'core.component.referral-code-dialog.step.code-form.code-input.label.title',
            defaultMessage: 'Referral code',
          })}
          message={referralCodeError}
          maxLength={REFERRAL_CODE_LENGTH}
          value={referralCode}
          onBlur={() => {
            touch('referralCode')
          }}
          onChange={(e) => {
            setReferralCode(sanitizeReferralCode(e.target.value))
            setShowApiError(false)
          }}
        />
      </Box>

      <Button
        visual="primary-green"
        size="large"
        disabled={Boolean(referralCodeError)}
        loading={isSubmitting}
        onClick={() => handleCodeSubmit(referralCode)}
      >
        <FormattedMessage id="common.action.continue.title" defaultMessage="Continue" />
      </Button>
    </VStack>
  )
}
