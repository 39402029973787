import { useMemo } from 'react'
import { type ColumnDef } from '@tanstack/react-table'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import {
  DateTimeTableCell,
  Link,
  TableCell,
  ValueColored,
} from '@x10/lib-ui-kit/components'

import { type UserFundingEntry } from '@src/domain/api/x10'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { OrderSideText } from '@src/domain/core/ui/components/order-side-text'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'

import { ActionableMarketTableCell } from './components/actionable-market-table-cell'
import { HeaderTooltip } from './components/header-tooltip'

export const useColumns = () => {
  const { formatMessage } = useIntl()
  const formatMarketAsset = useFormatMarketAsset()
  const formatPercent = useFormatPercent()

  return useMemo<Array<ColumnDef<UserFundingEntry>>>(
    () => [
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.funding.column.market.title',
          defaultMessage: 'Market',
        }),
        accessorKey: 'market',
        size: 100,
        cell: (info) => {
          const marketName = info.getValue<UserFundingEntry['market']>()

          return <ActionableMarketTableCell market={marketName} />
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.funding.column.side.title',
          defaultMessage: 'Side',
        }),
        size: 70,
        accessorKey: 'side',
        cell: (info) => {
          const side = info.getValue<UserFundingEntry['side']>()

          return (
            <TableCell>
              <OrderSideText side={side} />
            </TableCell>
          )
        },
      },
      {
        id: 'position',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.funding.column.position.title',
          defaultMessage: 'Position',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.size],
        cell: (info) => {
          const [marketName, size] =
            info.getValue<[UserFundingEntry['market'], UserFundingEntry['size']]>()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: size,
                  type: 'synthetic',
                },
                { marketName, showSymbol: true },
              )}
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.funding.column.mark-price.title',
          defaultMessage: 'Mark Price',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.markPrice],
        cell: (info) => {
          const [marketName, markPrice] =
            info.getValue<[UserFundingEntry['market'], UserFundingEntry['markPrice']]>()

          return (
            <TableCell>
              {formatMarketAsset(
                { amount: markPrice, type: 'collateral' },
                { marketName },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'value',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.funding.column.value.title',
          defaultMessage: 'Value',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.value],
        cell: (info) => {
          const [marketName, position] =
            info.getValue<[UserFundingEntry['market'], UserFundingEntry['value']]>()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: position,
                  type: 'collateral',
                },
                { marketName, showSymbol: true },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'funding_fee',
        header: () => (
          <HeaderTooltip
            interactive
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.funding.column.funding-fee.tooltip',
              defaultMessage:
                'Funding Fee is the payment exchanged between long and short positions every hour. If the funding rate is positive, long position holders pay short position holders. If negative, short position holders pay long position holders.\n\nFunding Fee = Position Value * (-Funding Rate).\n\nFor more information, please refer to the <a>documentation</a>.',
              values: {
                a: (chunks) => {
                  return (
                    <Link
                      href={asExternalRoute(
                        documentationRoute({}).trading({}).fundingPayments({}).$,
                      )}
                      external
                    >
                      {chunks}
                    </Link>
                  )
                },
              },
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.funding.column.funding-fee.title"
                defaultMessage="Funding Fee"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorFn: (row) => [row.market, row.fundingFee],
        cell: (info) => {
          const [marketName, fundingFee] =
            info.getValue<
              [
                UserFundingEntry['market'],
                UserFundingEntry['fundingFee'],
                UserFundingEntry['side'],
              ]
            >()

          return (
            <TableCell>
              <ValueColored>
                {formatMarketAsset(
                  {
                    amount: fundingFee,
                    type: 'collateral',
                  },
                  { marketName, showSymbol: true },
                )}
              </ValueColored>
            </TableCell>
          )
        },
      },
      {
        id: 'funding_rate',
        header: () => (
          <HeaderTooltip
            interactive
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.funding.column.rate.tooltip',
              defaultMessage:
                'The funding rate is used to calculate funding fees exchanged between long and short positions every hour. If the funding rate is positive, long position holders pay short position holders. If negative, short position holders pay long position holders.\n\nFunding Fee = Position Value * (-Funding Rate).\n\nFor more information, please refer to the <a>documentation</a>.',
              values: {
                a: (chunks) => {
                  return (
                    <Link
                      href={asExternalRoute(
                        documentationRoute({}).trading({}).fundingPayments({}).$,
                      )}
                      external
                    >
                      {chunks}
                    </Link>
                  )
                },
              },
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.funding.column.rate.title"
                defaultMessage="Rate"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorFn: (row) => [row.fundingRate, row.market],
        cell: (info) => {
          const [fundingRate] =
            info.getValue<[UserFundingEntry['fundingRate'], UserFundingEntry['side']]>()

          return (
            <TableCell>
              <ValueColored>
                {formatPercent(fundingRate, { precision: PercentPrecision.FundingRate })}
              </ValueColored>
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.funding.column.time.title',
          defaultMessage: 'Time',
        }),
        size: 70,
        accessorKey: 'paidTime',
        cell: (info) => {
          const time = info.getValue<UserFundingEntry['paidTime']>()

          return time ? <DateTimeTableCell value={time.getTime()} /> : EMPTY_CELL_VALUE
        },
      },
    ],
    [formatMessage, formatMarketAsset, formatPercent],
  )
}
