import { addDays } from 'date-fns'
import { getLimitOrderMsgHashWithFee } from '@starkware-industries/starkware-crypto-utils'

import { Decimal, Long, toHexString } from '@x10/lib-core/utils'

import type { MarketName, OrderSide } from '@src/domain/api/x10/common'
import { calcEntirePositionSize } from '@src/domain/trade/utils/calc/calc-entire-position-size'

import { calcStarkExpiration } from '../utils/calc-stark-expiration'
import { generateNonce } from '../utils/generate-nonce'
import { getOppositeOrderSide } from '../utils/get-opposite-side'
import { omitUndefined } from '../utils/omit-undefined'
import { signMessage } from '../utils/sign-message'
import { type OrderConditionalTrigger } from './order-conditional-trigger'
import { OrderTpSlTrigger, type OrderTpSlTriggerParam } from './order-tp-sl-trigger'
import { StarkPerpetualOrderDebuggingAmounts } from './stark-perpetual-order-debugging-amounts'
import { StarkPerpetualOrderSettlement } from './stark-perpetual-order-settlement'
import type {
  OrderContext,
  OrderTimeInForce,
  OrderTpSlType,
  StarkPerpetualOrderType,
} from './types'

const ORDER_EXPIRATION_DAYS = 7
const ROUNDING_MODE_SELL = Decimal.ROUND_DOWN
const ROUNDING_MODE_BUY = Decimal.ROUND_UP
const ROUNDING_MODE_FEE = Decimal.ROUND_UP

export class StarkPerpetualOrder {
  private readonly id: string
  private readonly market: string
  private readonly type: StarkPerpetualOrderType
  private readonly side: OrderSide
  private readonly qty: Decimal
  private readonly price: Decimal
  private readonly timeInForce: OrderTimeInForce
  private readonly expiryEpochMillis: number
  private readonly fee: Decimal
  private readonly nonce: Long
  private readonly settlement?: StarkPerpetualOrderSettlement
  private readonly reduceOnly?: boolean
  private readonly postOnly?: boolean
  private readonly trigger?: OrderConditionalTrigger
  private readonly tpSlType?: OrderTpSlType
  private readonly takeProfit?: OrderTpSlTrigger
  private readonly stopLoss?: OrderTpSlTrigger
  private readonly cancelId?: string
  /**
   * This flag is used to indicate that the order was created
   * as a result of closing a position form positions table.
   * It used to disable certain validations on the BE.
   */
  private readonly fromPositionClose?: boolean
  private readonly debuggingAmounts?: StarkPerpetualOrderDebuggingAmounts

  private constructor({
    id,
    market,
    type,
    side,
    qty,
    price,
    timeInForce,
    expiryEpochMillis,
    fee,
    nonce,
    settlement,
    reduceOnly,
    postOnly,
    trigger,
    tpSlType,
    takeProfit,
    stopLoss,
    cancelId,
    fromPositionClose,
    debuggingAmounts,
  }: {
    id: string
    market: string
    type: StarkPerpetualOrderType
    side: OrderSide
    qty: Decimal
    price: Decimal
    timeInForce: OrderTimeInForce
    expiryEpochMillis: number
    fee: Decimal
    nonce: Long
    settlement?: StarkPerpetualOrderSettlement
    reduceOnly?: boolean
    postOnly?: boolean
    trigger?: OrderConditionalTrigger
    tpSlType?: OrderTpSlType
    takeProfit?: OrderTpSlTrigger
    stopLoss?: OrderTpSlTrigger
    cancelId?: string
    fromPositionClose?: boolean
    debuggingAmounts?: StarkPerpetualOrderDebuggingAmounts
  }) {
    this.id = id
    this.market = market
    this.type = type
    this.side = side
    this.qty = qty
    this.price = price
    this.timeInForce = timeInForce
    this.expiryEpochMillis = expiryEpochMillis
    this.fee = fee
    this.nonce = nonce
    this.settlement = settlement
    this.reduceOnly = reduceOnly
    this.postOnly = postOnly
    this.trigger = trigger
    this.tpSlType = tpSlType
    this.takeProfit = takeProfit
    this.stopLoss = stopLoss
    this.cancelId = cancelId
    this.fromPositionClose = fromPositionClose
    this.debuggingAmounts = debuggingAmounts
  }

  toJSON() {
    return omitUndefined({
      id: this.id ? Decimal(this.id, 16).toString(10) : undefined,
      market: this.market,
      type: this.type,
      side: this.side,
      qty: this.qty.toString(10),
      price: this.price.toString(10),
      timeInForce: this.timeInForce,
      expiryEpochMillis: this.expiryEpochMillis,
      fee: this.fee.toString(10),
      nonce: this.nonce.toString(10),
      settlement: this.settlement?.toJSON(),
      reduceOnly: this.reduceOnly,
      postOnly: this.postOnly,
      trigger: this.trigger?.toJSON(),
      tpSlType: this.tpSlType,
      takeProfit: this.takeProfit?.toJSON(),
      stopLoss: this.stopLoss?.toJSON(),
      cancelId: this.cancelId,
      fromPositionClose: this.fromPositionClose,
      debuggingAmounts: this.debuggingAmounts?.toJSON(),
    })
  }

  static create({
    marketName,
    orderType,
    side,
    amountOfSynthetic,
    price,
    timeInForce,
    expiryTime,
    reduceOnly,
    postOnly,
    trigger,
    tpSlType,
    takeProfit,
    stopLoss,
    fromPositionClose,
    ctx,
  }: {
    marketName: MarketName
    orderType: StarkPerpetualOrderType
    side: OrderSide
    amountOfSynthetic: Decimal
    price: Decimal
    timeInForce: OrderTimeInForce
    expiryTime?: Date
    reduceOnly?: boolean
    postOnly?: boolean
    trigger?: OrderConditionalTrigger
    tpSlType?: OrderTpSlType
    takeProfit?: OrderTpSlTriggerParam
    stopLoss?: OrderTpSlTriggerParam
    fromPositionClose?: boolean
    ctx: OrderContext
  }) {
    const { feeRate, vaultId } = ctx

    const nonce = Long(generateNonce())
    const expiryEpochMillis = (
      expiryTime ?? addDays(new Date(), ORDER_EXPIRATION_DAYS)
    ).getTime()
    const createOrderParamsArgs = {
      side,
      amountOfSynthetic,
      price,
      expiryEpochMillis,
      nonce,
      ctx,
    }

    const tpSlSide = orderType !== 'TPSL' ? getOppositeOrderSide(side) : side
    const tpAmountOfSynthetic =
      takeProfit && tpSlType === 'POSITION'
        ? calcEntirePositionSize(
            takeProfit.price,
            ctx.minOrderSizeChange,
            ctx.maxPositionValue,
          )
        : amountOfSynthetic
    const slAmountOfSynthetic =
      stopLoss && tpSlType === 'POSITION'
        ? calcEntirePositionSize(
            stopLoss.price,
            ctx.minOrderSizeChange,
            ctx.maxPositionValue,
          )
        : amountOfSynthetic

    const createOrderParams =
      StarkPerpetualOrder.getCreateOrderParams(createOrderParamsArgs)
    const createTpOrderParams =
      takeProfit &&
      StarkPerpetualOrder.getCreateOrderParams({
        ...createOrderParamsArgs,
        side: tpSlSide,
        price: takeProfit.price,
        amountOfSynthetic: tpAmountOfSynthetic,
      })
    const createSlOrderParams =
      stopLoss &&
      StarkPerpetualOrder.getCreateOrderParams({
        ...createOrderParamsArgs,
        side: tpSlSide,
        price: stopLoss.price,
        amountOfSynthetic: slAmountOfSynthetic,
      })
    const settlement =
      orderType !== 'TPSL'
        ? new StarkPerpetualOrderSettlement({
            signature: createOrderParams.orderSignature.signature,
            starkKey: createOrderParams.orderSignature.starkKey,
            collateralPosition: vaultId,
          })
        : undefined

    return new StarkPerpetualOrder({
      id: createOrderParams.orderHash,
      market: marketName,
      type: orderType,
      side,
      qty: amountOfSynthetic,
      price,
      timeInForce,
      expiryEpochMillis,
      fee: feeRate,
      nonce,
      settlement,
      reduceOnly,
      postOnly,
      trigger,
      tpSlType,
      takeProfit: OrderTpSlTrigger.create(
        vaultId,
        takeProfit,
        createTpOrderParams?.orderSignature,
        createTpOrderParams?.debuggingAmounts,
      ),
      stopLoss: OrderTpSlTrigger.create(
        vaultId,
        stopLoss,
        createSlOrderParams?.orderSignature,
        createSlOrderParams?.debuggingAmounts,
      ),
      cancelId: undefined,
      fromPositionClose,
      debuggingAmounts: createOrderParams.debuggingAmounts,
    })
  }

  private static getCreateOrderParams({
    side,
    amountOfSynthetic,
    price,
    expiryEpochMillis,
    nonce,
    ctx,
  }: {
    side: OrderSide
    amountOfSynthetic: Decimal
    price: Decimal
    expiryEpochMillis: number
    nonce: Long
    ctx: OrderContext
  }) {
    const roundingMode = side === 'BUY' ? ROUNDING_MODE_BUY : ROUNDING_MODE_SELL

    const {
      assetIdCollateral,
      assetIdSynthetic,
      settlementResolutionCollateral,
      settlementResolutionSynthetic,
      feeRate,
      vaultId,
      starkPrivateKey,
    } = ctx

    const collateralAmount = amountOfSynthetic.times(price)
    const fee = feeRate.times(collateralAmount)

    const collateralAmountStark = collateralAmount
      .times(settlementResolutionCollateral)
      .toIntegerValue(roundingMode)
    const feeStark = fee
      .times(settlementResolutionCollateral)
      .toIntegerValue(ROUNDING_MODE_FEE)
    const syntheticAmountStark = amountOfSynthetic
      .times(settlementResolutionSynthetic)
      .toIntegerValue(roundingMode)

    const orderHash = StarkPerpetualOrder.hash(
      side,
      nonce,
      assetIdCollateral,
      assetIdSynthetic,
      collateralAmountStark,
      feeStark,
      syntheticAmountStark,
      calcStarkExpiration(expiryEpochMillis),
      vaultId,
    )
    const orderSignature = signMessage(orderHash, starkPrivateKey)

    return {
      orderHash,
      orderSignature,
      debuggingAmounts: new StarkPerpetualOrderDebuggingAmounts({
        collateralAmount: collateralAmountStark,
        feeAmount: feeStark,
        syntheticAmount: syntheticAmountStark,
      }),
    }
  }

  private static hash(
    side: 'BUY' | 'SELL',
    nonce: Long,
    assetIdCollateral: Decimal,
    assetIdSynthetic: Decimal,
    collateralAmountStark: Long,
    feeStark: Long,
    syntheticAmountStark: Long,
    expirationTimestamp: number,
    vaultId: Long,
  ): string {
    const isBuyingSynthetic = side === 'BUY'

    const [assetIdSell, assetIdBuy, amountSell, amountBuy] = isBuyingSynthetic
      ? [assetIdCollateral, assetIdSynthetic, collateralAmountStark, syntheticAmountStark]
      : [assetIdSynthetic, assetIdCollateral, syntheticAmountStark, collateralAmountStark]

    return getLimitOrderMsgHashWithFee(
      /* vaultSell           */ vaultId.toNumber(),
      /* vaultBuy            */ vaultId.toNumber(),
      /* amountSell          */ amountSell.toString(10),
      /* amountBuy           */ amountBuy.toString(10),
      /* tokenSell           */ toHexString(assetIdSell.toString(16)),
      /* tokenBuy            */ toHexString(assetIdBuy.toString(16)),
      /* nonce               */ nonce.toNumber(),
      /* expirationTimestamp */ expirationTimestamp,
      /* feeToken            */ toHexString(assetIdCollateral.toString(16)),
      /* feeVaultId          */ vaultId.toNumber(),
      /* feeLimit            */ feeStark.toString(10),
    )
  }
}
