import { useCallback, useRef, useState } from 'react'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { type Long } from '@x10/lib-core/utils'
import { css } from '@x10/lib-styled-system/css'
import { Box } from '@x10/lib-styled-system/jsx'
import {
  Scrollable,
  SvgIcon,
  TableHeaderCell,
  TableHeaderSortDirectionIcon,
} from '@x10/lib-ui-kit/components'
import { useDisclosure } from '@x10/lib-ui-kit/hooks'

import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { PositionTpSlDialog } from '@src/domain/trade/components/position-tp-sl-dialog'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { rowStyle, TradingHistoryTab } from '../common'
import { EmptyMessage } from '../components/empty-message'
import { HeaderSpacer } from '../components/header-spacer'
import { StickyTableHeader } from '../components/sticky-table-header'
import { useFilteredMarketsNames } from '../hooks/use-trading-history-table-filters'
import { usePositionsData } from './hooks/use-positions-data'
import { useColumns } from './trading-history-positions-table.columns'

const MARKET_HEADER_ID = 'market'
const ACTIONS_COLUMN_ID = 'actions'

export const TradingHistoryPositionsTable = () => {
  const filteredMarketsNames = useFilteredMarketsNames()

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const { isOpen: isTpSlDialogOpen, onToggle: onTpSlDialogToggle } = useDisclosure()
  const [editingTpSlPositionId, setEditingTpSlPositionId] = useState<Long>()

  const data = usePositionsData(filteredMarketsNames)

  const editingTpSl = editingTpSlPositionId
    ? data.find((item) => item.position.id.eq(editingTpSlPositionId))
    : undefined

  // `onTpSlEdit` can't use data as dependency because it will cause updates of columns inside useColumns
  const onTpSlEdit = useCallback(
    (positionId: Long) => {
      setEditingTpSlPositionId(positionId)
      onTpSlDialogToggle()
    },
    [onTpSlDialogToggle],
  )

  const columns = useColumns({
    onTpSlEdit,
  })

  const table = useReactTable({
    data,
    columns,
    getRowId: (row) => row.position.id.toString(),
    getCoreRowModel: getCoreRowModel(),
  })

  if (data.length === 0) {
    return <EmptyMessage tab={TradingHistoryTab.Positions} />
  }

  return (
    <TradeWidget.ContentFixed>
      <Scrollable ref={tableContainerRef} scrollX scrollY css={{ flex: 1, px: 's-8' }}>
        <table width="100%">
          <StickyTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sortDirection = header.id === MARKET_HEADER_ID && 'desc'

                  return (
                    <TableHeaderCell
                      key={header.id}
                      cellType={header.id === ACTIONS_COLUMN_ID ? 'action' : undefined}
                      sortDirection={sortDirection}
                      style={{ width: header.getSize() }}
                    >
                      <HeaderSpacer>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <TableHeaderSortDirectionIcon sortDirection={sortDirection} />
                      </HeaderSpacer>
                    </TableHeaderCell>
                  )
                })}
              </tr>
            ))}
          </StickyTableHeader>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className={css(rowStyle)}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Scrollable>

      <FeatureGate name="SHOW_TRADING_HISTORY_TABLES_MORE">
        <Box pl="s-12" w="s-24">
          <SvgIcon.SvgIconMore />
        </Box>
      </FeatureGate>

      <PositionTpSlDialog
        open={isTpSlDialogOpen}
        position={editingTpSl?.position}
        orders={editingTpSl?.orders}
        onClose={() => onTpSlDialogToggle()}
        onCloseComplete={() => setEditingTpSlPositionId(undefined)}
      />
    </TradeWidget.ContentFixed>
  )
}
