import type { AccountInfo } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'

// Hack required to close and finish animation of the popover menu before the account selection happens.
// This needed to avoid jittery reappearance of a popover on an empty screen and then instant disappear
export const setAccount = (account: AccountInfo) => {
  setTimeout(() => {
    useAccountId.setAccountId(account)
  }, 400)
}
