import React, { useMemo } from 'react'
import { type ColumnDef } from '@tanstack/react-table'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import {
  Cell,
  DateTimeTableCell,
  Portal,
  TableCell,
  Tooltip,
  ValueColored,
} from '@x10/lib-ui-kit/components'

import { type UserRealisedPnlEntry } from '@src/domain/api/x10'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useFormatUsd } from '@src/domain/core/hooks/use-format-usd'
import { OrderSideText } from '@src/domain/core/ui/components/order-side-text'
import { getTradeTypeIntlKey } from '@src/domain/i18n/utils/get-trade-type-intl-key'

import { ActionableMarketTableCell } from '../components/actionable-market-table-cell'
import { HeaderTooltip } from '../components/header-tooltip'

export const useColumns = () => {
  const { formatMessage } = useIntl()

  const formatMarketAsset = useFormatMarketAsset()
  const formatUsd = useFormatUsd()

  return useMemo<Array<ColumnDef<UserRealisedPnlEntry>>>(
    () => [
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.market.title',
          defaultMessage: 'Market',
        }),
        accessorKey: 'market',
        size: 100,
        cell: (info) => {
          const marketName = info.getValue<UserRealisedPnlEntry['market']>()

          return <ActionableMarketTableCell market={marketName} />
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.exit-type.title',
          defaultMessage: 'Exit Type',
        }),
        size: 70,
        accessorKey: 'exitType',
        cell: (info) => {
          const exitType = info.getValue<UserRealisedPnlEntry['exitType']>()

          return (
            <TableCell>
              {exitType ? <FormattedMessage {...getTradeTypeIntlKey(exitType)} /> : null}
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.side.title',
          defaultMessage: 'Side',
        }),
        size: 70,
        accessorKey: 'side',
        cell: (info) => {
          const side = info.getValue<UserRealisedPnlEntry['side']>()

          return (
            <TableCell>
              <OrderSideText side={side} />
            </TableCell>
          )
        },
      },
      {
        id: 'position',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.position.title',
          defaultMessage: 'Position',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.maxPositionSize],
        cell: (info) => {
          const [marketName, maxPositionSize] =
            info.getValue<
              [UserRealisedPnlEntry['market'], UserRealisedPnlEntry['maxPositionSize']]
            >()
          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: maxPositionSize,
                  type: 'synthetic',
                },
                { marketName, showSymbol: true },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'open-price',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.entry-price.tooltip',
              defaultMessage: 'The weighted average entry price of your positions.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.realised-pnl.column.entry-price.title"
                defaultMessage="Entry Price"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorFn: (row) => [row.market, row.openPrice],

        cell: (info) => {
          const [marketName, value] =
            info.getValue<
              [UserRealisedPnlEntry['market'], UserRealisedPnlEntry['openPrice']]
            >()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: value,
                  type: 'collateral',
                },
                { marketName },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'exit_price',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.exit-price.tooltip',
              defaultMessage: 'The weighted average exit price of your positions.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.realised-pnl.column.exit-price.title"
                defaultMessage="Exit Price"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorFn: (row) => [row.market, row.exitPrice],

        cell: (info) => {
          const [marketName, value] =
            info.getValue<
              [UserRealisedPnlEntry['market'], UserRealisedPnlEntry['exitPrice']]
            >()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: value,
                  type: 'collateral',
                },
                { marketName },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'realised_pnl',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.realised-pnl.tooltip',
              defaultMessage:
                'The sum of realised position PnL, trading fees, and funding fees.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.realised-pnl.column.realised-pnl.title"
                defaultMessage="Realised PnL"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorFn: (row) => [row.realisedPnl, row.realisedPnlBreakdown],
        cell: (info) => {
          const [realisedPnl, realisedPnlBreakdown] =
            info.getValue<
              [
                UserRealisedPnlEntry['realisedPnl'],
                UserRealisedPnlEntry['realisedPnlBreakdown'],
              ]
            >()

          return (
            <TableCell>
              <Tooltip.Root positioning={{ placement: 'top-start' }}>
                <Tooltip.Trigger asChild>
                  <ValueColored>{formatUsd(realisedPnl)}</ValueColored>
                </Tooltip.Trigger>

                <Portal>
                  <Tooltip.Positioner>
                    <Tooltip.Content>
                      <Cell.Group>
                        <Cell.Item>
                          <Cell.Title>
                            <FormattedMessage
                              id="workspace.trade.widget.trading-history.mode.realised-pnl.column.realised-pnl.valueTooltip.tradePnl"
                              defaultMessage="Trade PnL"
                            />
                          </Cell.Title>
                          <Cell.Value>
                            {formatUsd(realisedPnlBreakdown.tradePnl)}
                          </Cell.Value>
                        </Cell.Item>
                        <Cell.Item>
                          <Cell.Title>
                            <FormattedMessage
                              id="workspace.trade.widget.trading-history.mode.realised-pnl.column.realised-pnl.valueTooltip.tradingFees"
                              defaultMessage="Trading Fees"
                            />
                          </Cell.Title>
                          <Cell.Value>
                            {formatUsd(
                              realisedPnlBreakdown.openFees.plus(
                                realisedPnlBreakdown.closeFees,
                              ),
                            )}
                          </Cell.Value>
                        </Cell.Item>
                        <Cell.Item>
                          <Cell.Title>
                            <FormattedMessage
                              id="workspace.trade.widget.trading-history.mode.realised-pnl.column.realised-pnl.valueTooltip.fundingFees"
                              defaultMessage="Funding Fees"
                            />
                          </Cell.Title>
                          <Cell.Value>
                            {formatUsd(realisedPnlBreakdown.fundingFees)}
                          </Cell.Value>
                        </Cell.Item>
                      </Cell.Group>
                    </Tooltip.Content>
                  </Tooltip.Positioner>
                </Portal>
              </Tooltip.Root>
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.realised-pnl.column.time-closed.title',
          defaultMessage: 'Time Closed',
        }),
        size: 70,
        accessorKey: 'closedTime',
        cell: (info) => {
          const time = info.getValue<UserRealisedPnlEntry['closedTime']>()

          return time ? <DateTimeTableCell value={time.getTime()} /> : EMPTY_CELL_VALUE
        },
      },
    ],
    [formatMessage, formatMarketAsset, formatUsd],
  )
}
