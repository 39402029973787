import { type FC } from 'react'
import { defaultStyles, TooltipWithBounds } from '@visx/tooltip'

import { FormattedMessage, useFormatDateTime } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { Cell } from '@x10/lib-ui-kit/components'

import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { formatTime } from '@src/domain/core/utils/format-time'

const tooltipStyles = {
  ...defaultStyles,
  padding: 0,
  borderRadius: 'none',
  background: 'transparent',
}

export type TooltipData = {
  fundingRate: number
  timestamp: number
}

type TooltipProps = {
  data: TooltipData
  top: number
  left: number
}

export const FundingRateLineChartTooltip: FC<TooltipProps> = ({ data, top, left }) => {
  const { formatDate } = useFormatDateTime()
  const formatPercent = useFormatPercent()

  return (
    <TooltipWithBounds
      // Needed for bounds to update correctly: https://airbnb.io/visx/tooltip
      key={Math.random()}
      top={top}
      left={left}
      style={tooltipStyles}
    >
      <Box
        textStyle="small"
        p="s-16"
        bg="token.grey"
        color="token.white"
        borderRadius="r-16"
      >
        <Cell.Group>
          <Cell.Item gap="s-16">
            <Cell.Title>
              <FormattedMessage
                id="workspace.trade.widget.market-stats.item.funding-rate.title"
                defaultMessage="Funding rate"
              />
            </Cell.Title>
            <Cell.Value>
              {formatPercent(data.fundingRate, {
                precision: PercentPrecision.FundingRate,
              })}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item gap="s-16">
            <Cell.Title>
              <FormattedMessage
                id="workspace.trade.widget.market-stats.item.open-interest.details.column.time.title"
                defaultMessage="Time"
              />
            </Cell.Title>
            <Cell.Value>
              {formatDate(data.timestamp, { dateStyle: 'medium' })}{' '}
              {formatTime(data.timestamp)}
            </Cell.Value>
          </Cell.Item>
        </Cell.Group>
      </Box>
    </TooltipWithBounds>
  )
}
