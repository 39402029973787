import { useMemo, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Tabs } from '@x10/lib-ui-kit/components'

import { useSelectedMarket } from '@src/domain/trade/store/market'

import { type MarketFilter as MarketFilterType } from '../../types/filters'

type Props = {
  marketFilterValue: MarketFilterType
  onMarketFilterChange: (value: MarketFilterType) => void
}

// FIXME: https://linear.app/ex10/issue/X10-3168/add-new-segmentgroup-variant-to-display-filters
export const MarketFilter: FC<Props> = ({ marketFilterValue, onMarketFilterChange }) => {
  const selectedMarket = useSelectedMarket()

  const marketFilterOptions = useMemo(
    () => [
      {
        value: 'all',
        label: (
          <FormattedMessage
            id="workspace.trade.widget.trading-history.filters.market.all"
            defaultMessage="All"
          />
        ),
      },
      { value: 'current', label: selectedMarket.assets.synthetic.code },
    ],
    [selectedMarket.assets.synthetic.code],
  )

  return (
    <Tabs.Root
      size="md"
      value={marketFilterValue}
      onValueChange={({ value }) => onMarketFilterChange(value as MarketFilterType)}
    >
      <Tabs.List
        css={{
          flex: 1,
          gap: 's-12!',
        }}
      >
        {marketFilterOptions.map((option) => {
          return (
            <Tabs.Trigger key={option.value} value={option.value}>
              {option.label}
            </Tabs.Trigger>
          )
        })}
      </Tabs.List>
    </Tabs.Root>
  )
}
