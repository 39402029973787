import { useCallback } from 'react'
import { sortBy } from 'lodash'
import { useQuery } from '@tanstack/react-query'

import { removeRestEnvelope } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { useAccountId, useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { queryClient } from '@src/domain/core/providers/app-providers/query-client'
import { QueryKey } from '@src/domain/trade/constants'

export const useFees = ({ marketsNames }: { marketsNames?: MarketName[] } = {}) => {
  const isLoggedIn = useSuspendedIsLoggedIn()
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(marketsNames)

  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.UserFees, sortedMarketsNames, accountId],
    queryFn: () =>
      API.trading.account.getFees(
        {
          marketsNames: sortedMarketsNames,
        },
        accountId,
      ),
    enabled: isLoggedIn,
  })

  return { data: removeRestEnvelope(data), isLoading }
}

export const useFetchFees = () => {
  const accountId = useAccountId()

  return useCallback(
    async ({ marketsNames }: { marketsNames?: MarketName[] } = {}) => {
      const sortedMarketsNames = sortBy(marketsNames)

      const data = await queryClient.fetchQuery({
        queryKey: [QueryKey.UserFees, sortedMarketsNames, accountId],
        queryFn: () =>
          API.trading.account.getFees(
            {
              marketsNames: sortedMarketsNames,
            },
            accountId,
          ),
      })

      return removeRestEnvelope(data)
    },
    [accountId],
  )
}
