import type { FC, PropsWithChildren } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { ValueColored } from '@x10/lib-ui-kit/components'

import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { calcFundingRateForInterval } from '@src/domain/trade/utils/calc/calc-funding-rate-for-interval'

type FundingRateTooltipPeriodsProps = PropsWithChildren<{
  rate: Decimal
}>

export const FundingRateTooltipPeriods: FC<FundingRateTooltipPeriodsProps> = ({
  rate,
}) => {
  const formatPercent = useFormatPercent()

  return (
    <HStack css={{ gap: 's-32', mb: 's-16' }}>
      <div>
        <ValueColored>
          {formatPercent(calcFundingRateForInterval(rate, '8H'), {
            precision: PercentPrecision.FundingRate,
          })}
        </ValueColored>
        <Box css={{ textStyle: 'small', color: 'token.white-50' }}>
          <FormattedMessage
            id="workspace.trade.widget.market-stats.item.funding-rate.hint.periods.8-hours.title"
            defaultMessage="8 hours"
          />
        </Box>
      </div>

      <div>
        <ValueColored>
          {formatPercent(calcFundingRateForInterval(rate, '1Y'), {
            precision: PercentPrecision.FundingRate,
          })}
        </ValueColored>
        <Box css={{ textStyle: 'small', color: 'token.white-50' }}>
          <FormattedMessage
            id="workspace.trade.widget.market-stats.item.funding-rate.hint.periods.annualized.title"
            defaultMessage="Annualized"
          />
        </Box>
      </div>
    </HStack>
  )
}
