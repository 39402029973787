import { useSuspenseQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

export const useRealisedPnl = () => {
  const accountId = useAccountId()

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.UserRealisedPnl, accountId],
    queryFn: () => {
      return API.trading.account.getRealisedPnl(undefined, accountId)
    },
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelopeSuspense(data),
  }
}
