import { useCallback } from 'react'
import { create } from 'zustand'

import type { UserPosition } from '@src/domain/api/x10'
import { useClosePositionWithMarketPrice } from '@src/domain/trade/hooks/use-close-position-with-market-price'

export const useMassCancelPositionsStore = create<{
  isMassCancelling: boolean
  setMassCancelling: (value: boolean) => void
}>((set) => ({
  isMassCancelling: false,

  setMassCancelling(value) {
    set({ isMassCancelling: value })
  },
}))

export const useMassCancelPositions = () => {
  const { setMassCancelling } = useMassCancelPositionsStore()
  const { closePositionWithMarketPrice } = useClosePositionWithMarketPrice()

  const massCancelPositions = useCallback(
    async (positions: UserPosition[]) => {
      setMassCancelling(true)

      await Promise.allSettled(positions.map(closePositionWithMarketPrice))

      setMassCancelling(false)
    },
    [closePositionWithMarketPrice, setMassCancelling],
  )

  return { massCancelPositions }
}
