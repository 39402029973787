import { Suspense, useMemo } from 'react'
import { createColumnHelper } from '@tanstack/react-table'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { DateTimeTableCell, TableCell, ValueColored } from '@x10/lib-ui-kit/components'

import type { UserAssetOperation } from '@src/domain/api/x10'
import { useFormatUsd } from '@src/domain/core/hooks/use-format-usd'
import { getAssetOperationStatusIntlKey } from '@src/domain/i18n/utils/get-asset-operation-status-intl-key'
import { getAssetOperationTypeIntlKey } from '@src/domain/i18n/utils/get-asset-operation-type-intl-key'

import { TransferDetailsCell } from './transfer-details-cell'

const columnHelper = createColumnHelper<UserAssetOperation>()

export const useColumns = () => {
  const { formatMessage } = useIntl()
  const formatUsd = useFormatUsd({ usdcSymbol: true })

  return useMemo(
    () => [
      columnHelper.accessor('type', {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.transfers.column.action.title',
          defaultMessage: 'Action',
        }),
        cell: (props) => {
          return (
            <TableCell>
              <FormattedMessage {...getAssetOperationTypeIntlKey(props.getValue())} />
            </TableCell>
          )
        },
      }),
      columnHelper.accessor('status', {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.transfers.column.status.title',
          defaultMessage: 'Status',
        }),
        cell: (props) => {
          return (
            <TableCell>
              <FormattedMessage {...getAssetOperationStatusIntlKey(props.getValue())} />
            </TableCell>
          )
        },
      }),
      columnHelper.accessor('amount', {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.transfers.column.amount.title',
          defaultMessage: 'Amount',
        }),
        cell: (props) => {
          return (
            <TableCell>
              <ValueColored>
                {formatUsd(props.getValue(), { showSign: 'always' })}
              </ValueColored>
            </TableCell>
          )
        },
      }),
      columnHelper.accessor('fee', {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.transfers.column.fee.title',
          defaultMessage: 'Fee',
        }),
        cell: (props) => {
          const value = props.getValue()

          return (
            <TableCell>
              <ValueColored>
                {formatUsd(value.eq(0) ? value : value.negated())}
              </ValueColored>
            </TableCell>
          )
        },
      }),
      columnHelper.accessor('time', {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.transfers.column.time.title',
          defaultMessage: 'Time',
        }),
        cell: (props) => {
          return <DateTimeTableCell value={props.getValue()} />
        },
      }),
      columnHelper.display({
        id: 'transaction-details',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.transfers.column.transaction-details.title',
          defaultMessage: 'Transaction Details',
        }),
        cell: (props) => {
          return (
            <Suspense>
              <TransferDetailsCell transfer={props.row.original} />
            </Suspense>
          )
        },
      }),
    ],
    [formatMessage, formatUsd],
  )
}
