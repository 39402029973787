import { toHexString, type Long } from '@x10/lib-core/utils'

import { type StarkSignature } from '../types'
import { omitUndefined } from '../utils/omit-undefined'

export class StarkPerpetualOrderSettlement {
  private readonly signature: StarkSignature
  private readonly starkKey: string
  private readonly collateralPosition: Long

  constructor(settlement: {
    signature: StarkSignature
    starkKey: string
    collateralPosition: Long
  }) {
    this.signature = settlement.signature
    this.starkKey = settlement.starkKey
    this.collateralPosition = settlement.collateralPosition
  }

  toJSON() {
    return omitUndefined({
      signature: {
        r: toHexString(this.signature.r),
        s: toHexString(this.signature.s),
      },
      starkKey: toHexString(this.starkKey),
      collateralPosition: this.collateralPosition.toString(10),
    })
  }
}
