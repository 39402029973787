import { checkRequired, type Decimal } from '@x10/lib-core/utils'

import type { RiskFactorConfig } from '@src/domain/api/x10'

import { getRiskFactorForLeverage } from './get-risk-factor-for-leverage'

type CalcArgs = {
  leverage: Decimal
  riskFactorConfig: RiskFactorConfig
}

export const calcMaxPositionValueForLeverageBasedOnRiskFactor = ({
  leverage,
  riskFactorConfig,
}: CalcArgs) => {
  const factor = getRiskFactorForLeverage({ leverage, riskFactorConfig })

  return checkRequired(factor, 'factor').upperBound
}
