import { useMemo, type FC } from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import { Portal, Select, ShevronTrigger } from '@x10/lib-ui-kit/components'

import type { CandlePriceSource } from '@src/domain/api/x10/common'

const useSelectOptions = () => {
  const { formatMessage } = useIntl()

  return useMemo<Array<{ label: string; value: CandlePriceSource }>>(() => {
    return [
      {
        label: formatMessage({
          id: 'workspace.trade.widget.chart.tab.market.toolbar.data-source.last-price.title',
          defaultMessage: 'Last Price',
        }),
        value: 'trades',
      },
      {
        label: formatMessage({
          id: 'workspace.trade.widget.chart.tab.market.toolbar.data-source.mark-price.title',
          defaultMessage: 'Mark Price',
        }),
        value: 'mark-prices',
      },
      {
        label: formatMessage({
          id: 'workspace.trade.widget.chart.tab.market.toolbar.data-source.index-price.title',
          defaultMessage: 'Index Price',
        }),
        value: 'index-prices',
      },
    ]
  }, [formatMessage])
}

type PriceSourceSelectProps = {
  value: CandlePriceSource
  onValueSelected: (value: CandlePriceSource) => void
}

export const PriceSourceSelect: FC<PriceSourceSelectProps> = ({
  value,
  onValueSelected,
}) => {
  const selectOptions = useSelectOptions()

  return (
    <Select.Root
      visual="compact"
      items={selectOptions}
      value={[value]}
      onValueChange={(item) => {
        onValueSelected(item.value[0] as CandlePriceSource)
      }}
    >
      <Select.Context>
        {({ open }) => {
          return (
            <>
              <Select.Control>
                <Select.Trigger>
                  <Select.ValueText color="token.white-50" />
                  <ShevronTrigger open={open} color="token.white-50" />
                </Select.Trigger>
              </Select.Control>
              <Portal>
                <Select.Positioner>
                  <Select.Content>
                    {selectOptions.map((item) => {
                      return (
                        <Select.Item key={item.value} item={item}>
                          <Select.ItemText>{item.label}</Select.ItemText>
                        </Select.Item>
                      )
                    })}
                  </Select.Content>
                </Select.Positioner>
              </Portal>
            </>
          )
        }}
      </Select.Context>
    </Select.Root>
  )
}
