import { useMemo, type FC } from 'react'

import { FormattedMessage, useIntl, type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Select, SimpleSelect } from '@x10/lib-ui-kit/components'

import { type FundingRateInterval } from '@src/domain/trade/types/funding-rate'

const getFundingIntervalIntlKey = (interval: FundingRateInterval): MessageDescriptor => {
  switch (interval) {
    case '1H':
      return {
        id: 'common.funding-rate-interval.1H',
        defaultMessage: '1h',
      }
    case '8H':
      return {
        id: 'common.funding-rate-interval.8H',
        defaultMessage: '8h',
      }
    case '1Y':
      return {
        id: 'common.funding-rate-interval.1Y',
        defaultMessage: 'Annualized',
      }
    default:
      throw notReachable(interval)
  }
}

const fundingRateOptionsValues: FundingRateInterval[] = ['1H', '8H', '1Y']

const useFundingRateOptions = () => {
  const { formatMessage } = useIntl()

  return useMemo<Array<{ label: string; value: FundingRateInterval }>>(() => {
    return fundingRateOptionsValues.map((interval) => ({
      label: formatMessage(getFundingIntervalIntlKey(interval)),
      value: interval,
    }))
  }, [formatMessage])
}

type Props = {
  value: FundingRateInterval
  onChange: (value: FundingRateInterval) => void
}

export const FundingRateFilterInterval: FC<Props> = ({ value, onChange }) => {
  const fundingRateOptions = useFundingRateOptions()

  return (
    <SimpleSelect.Root
      visual="compact"
      positioning={{ placement: 'bottom-end' }}
      options={fundingRateOptions}
      value={value}
      onValueChange={onChange}
    >
      <SimpleSelect.Control
        value={
          <>
            <Select.ValueText />
            &nbsp;
            <FormattedMessage
              id="core.component.filter-funding-rate.title"
              defaultMessage="Rate"
            />
          </>
        }
      />

      <SimpleSelect.Content>
        {fundingRateOptions.map((item) => (
          <SimpleSelect.Item key={item.value} item={item} />
        ))}
      </SimpleSelect.Content>
    </SimpleSelect.Root>
  )
}
