import { useState, type FC } from 'react'
import { useDraggable } from '@dnd-kit/core'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { type Decimal } from '@x10/lib-core/utils'
import { Box, Flex, HStack, styled, VStack } from '@x10/lib-styled-system/jsx'
import { Button, Input, SvgIcon } from '@x10/lib-ui-kit/components'

type QuickTradingMarketProps = {
  dragId: string
  x: number
  y: number
  bidPrice: Decimal | undefined
  askPrice: Decimal | undefined
}

export const QuickTradingMarket: FC<QuickTradingMarketProps> = ({
  dragId,
  x,
  y,
  bidPrice,
  askPrice,
}) => {
  const { formatMessage } = useIntl()

  const [hovered, setHovered] = useState(false)
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: dragId,
  })

  return (
    <HStack
      gap="s-4"
      position="absolute"
      style={{
        left: x,
        top: y,
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
      }}
      ref={setNodeRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <styled.button
        cursor="grabbing"
        alignSelf="stretch"
        visibility={hovered || isDragging ? 'visible' : 'hidden'}
        bg="token.grey"
        color="token.white-50"
        {...listeners}
        {...attributes}
      >
        <Flex
          bg="token.white-10"
          minW="1.25rem"
          h="100%"
          alignItems="center"
          justifyContent="center"
          borderRadius="r-8"
        >
          <SvgIcon.SvgIconDrag size={16} />
        </Flex>
      </styled.button>

      <HStack gap={0} bg="token.grey" borderRadius="r-16">
        <Button
          visual="primary-green"
          size="large"
          center={false}
          borderRightRadius={0}
          px="s-16"
          py="0.40625rem"
          minW="6.875rem"
        >
          <VStack gap={0} alignItems="start" justifyContent="start">
            <Box textStyle="small">
              <FormattedMessage
                id="workspace.trade.quick-trading.mode.market.action.buy.title"
                defaultMessage="Market Buy"
              />
            </Box>
            <Box textStyle="secondary">{askPrice?.toString()}</Box>
          </VStack>
        </Button>

        <Input
          visual="square"
          label={formatMessage({
            id: 'workspace.trade.quick-trading.mode.market.input.amount.title',
            defaultMessage: 'Amount',
          })}
          placeholder="0"
          minW="6.875rem"
        />

        <Button
          visual="primary-red"
          size="large"
          center={false}
          borderLeftRadius={0}
          px="s-16"
          py="0.40625rem" // 6.5px
          minW="6.875rem"
        >
          <VStack gap={0} alignItems="start">
            <Box textStyle="small">
              <FormattedMessage
                id="workspace.trade.quick-trading.mode.market.action.sell.title"
                defaultMessage="Market Sell"
              />
            </Box>
            <Box textStyle="secondary">{bidPrice?.toString()}</Box>
          </VStack>
        </Button>
      </HStack>
    </HStack>
  )
}
