import { useCallback } from 'react'
import { useWaitForTransactionReceipt } from 'wagmi'

import { type Decimal } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import type { AccountInfo } from '@src/domain/api/x10'
import { useWriteContract } from '@src/domain/core/hooks/use-write-contract'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { starkexAbi } from '../abi/starkex'
import { toSmartContractUnits } from '../utils/smart-contract'

export const useDeposit = () => {
  const market = useSelectedMarket()

  const {
    writeContractAsync,
    isPending: isDepositPending,
    data: txHash,
  } = useWriteContract()
  const { starkExContractAddress } = useSettings()

  const { isSuccess: isConfirmed, isLoading: isConfirming } =
    useWaitForTransactionReceipt({
      hash: txHash,
    })

  const deposit = useCallback(
    ({
      accountInfo,
      depositAmount,
    }: {
      accountInfo: AccountInfo
      depositAmount: Decimal
    }) => {
      return writeContractAsync({
        abi: starkexAbi,
        address: starkExContractAddress,
        functionName: 'deposit',
        args: [
          BigInt(accountInfo.l2Key),
          BigInt(market.l2Config.collateralId),
          BigInt(accountInfo.l2Vault),
          toSmartContractUnits(depositAmount),
        ],
      })
    },
    [market.l2Config.collateralId, starkExContractAddress, writeContractAsync],
  )

  return {
    deposit,
    isDepositPending,
    isDepositConfirming: isConfirming,
    isDepositConfirmed: isConfirmed,
  }
}
