import type { Context, ErrorMessage, Rule } from '../types'

/**
 * [61:Validation]
 * Reduce-only with no open position is not allowed
 * (Validation does not apply to TP/SL orders at order placement.)
 */
export const reduceOnlyWithNoPosition: Rule<
  unknown,
  Pick<Context, 'reduceOnly' | 'position'>,
  unknown,
  Pick<ErrorMessage, 'getReduceOnlyNoPosition'>
> = (_value, ctx, _computed, _alerts, errors, errorMessage) => {
  if (!ctx.reduceOnly || ctx.position) {
    return
  }

  errors.form = errorMessage.getReduceOnlyNoPosition()
}
