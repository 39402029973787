import { Box, VStack } from '@x10/lib-styled-system/jsx'

import { BottomNavigation } from '@src/domain/core/ui/components/bottom-navigation'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'
import { LatestTrades } from '@src/domain/trade/ui/widgets/latest-trades'
import { MarketSelector } from '@src/domain/trade/ui/widgets/market-selector'
import { MarketStats } from '@src/domain/trade/ui/widgets/market-stats'
import { OrderBook } from '@src/domain/trade/ui/widgets/order-book'
import {
  OrderForm,
  ActionButtons as OrderFormActionButtons,
} from '@src/domain/trade/ui/widgets/order-form'
import { TradingChart } from '@src/domain/trade/ui/widgets/trading-chart'

const CSS_WIDGET_HEIGHT = '37.5rem'

export const StaticLayoutWithWidgets = () => {
  return (
    <VStack alignItems="start" gap="s-4">
      <TradeWidget.Root py="s-8">
        <MarketSelector />
      </TradeWidget.Root>

      <TradeWidget.Root p="s-8" fullScreenDisabled>
        <MarketStats />
      </TradeWidget.Root>

      <TradeWidget.Root>
        <OrderForm />
      </TradeWidget.Root>

      <TradeWidget.Root
        h={{ base: CSS_WIDGET_HEIGHT, md: 'auto' }}
        position={{ base: 'relative', md: 'unset' }}
      >
        <OrderBook />
      </TradeWidget.Root>

      <TradeWidget.Root h={{ base: CSS_WIDGET_HEIGHT, md: 'auto' }}>
        <LatestTrades />
      </TradeWidget.Root>

      <TradeWidget.Root h={{ base: CSS_WIDGET_HEIGHT, md: 'auto' }}>
        <TradingChart />
      </TradeWidget.Root>

      <FeatureGate name="MOBILE_WALLET_CONNECTION">
        <BottomNavigation
          actions={
            <Box mb="s-2">
              <OrderFormActionButtons />
            </Box>
          }
        />
      </FeatureGate>
    </VStack>
  )
}
