import { useState, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, Flex, HStack } from '@x10/lib-styled-system/jsx'
import { ActionIcon, ActionLink, Menu, Portal, SvgIcon } from '@x10/lib-ui-kit/components'

import { type X10Interval } from '@src/domain/api/x10/common'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { toMarketName } from '@src/domain/core/utils/to-market-name'

import {
  useTradingViewChartMachineStore,
  type TradingViewChartMachineApi,
} from '../trading-view-chart/hooks/use-trading-view-chart'
import { TradingViewSeriesType } from '../trading-view-chart/types/common'
import {
  makeTradingViewTicker,
  parseTradingViewTicker,
} from '../trading-view-chart/utils/data-feed/utils'
import { CompareMarkets } from './components/compare-markets'
import { DisplayOptions } from './components/display-options'
import { IndicatorSelect } from './components/indicator-select'
import { IntervalSelect, useGetIntervalLabel } from './components/interval-select'
import { PriceSourceSelect } from './components/price-source-select'
import { useFavoriteIntervals } from './hooks/use-favorite-intervals'

type TradingViewChartToolbarProps = {
  machine: TradingViewChartMachineApi
}

export const TradingViewChartTopToolbar: FC<TradingViewChartToolbarProps> = ({
  machine,
}) => {
  const currentInterval = useTradingViewChartMachineStore((state) => state.interval)
  const currentPriceSource = useTradingViewChartMachineStore((state) => state.priceSource)
  const indicators = useTradingViewChartMachineStore((state) => state.indicators)

  const favoriteIntervals = useFavoriteIntervals((state) => state.favoriteIntervals)
  const [lastSelected, setLastSelected] = useState<X10Interval>(currentInterval)
  const getIntervalLabel = useGetIntervalLabel()

  const getShownInterval = (): X10Interval[] => {
    if (favoriteIntervals.includes(currentInterval)) {
      if (favoriteIntervals.includes(lastSelected)) {
        return favoriteIntervals
      }
      return [...favoriteIntervals, lastSelected]
    }

    return [...favoriteIntervals, currentInterval]
  }

  const onDisplaySeriesSelected = (chartType: number) => {
    machine.setChartType(chartType)
  }

  const onChangeInterval = (newInterval: X10Interval) => {
    if (currentInterval && !favoriteIntervals.includes(currentInterval)) {
      setLastSelected(currentInterval)
    }

    machine.setInterval(newInterval)
  }

  return (
    <Flex
      hideBelow="md"
      css={{
        py: '0.3125rem',
        px: 's-16',
        justifyContent: 'space-between',
        textStyle: 'small',
        color: 'token.white-50',
        fontSize: 'fs-14',
      }}
    >
      <HStack
        css={{
          gap: 's-16',
        }}
      >
        {getShownInterval().map((interval) => {
          return (
            <ActionLink
              key={interval}
              data-active={currentInterval === interval ? true : undefined}
              onClick={() => onChangeInterval(interval)}
            >
              {getIntervalLabel(interval).short}
            </ActionLink>
          )
        })}
        <Box
          css={{
            marginLeft: '-s-16',
          }}
        >
          <IntervalSelect
            value={currentInterval}
            onValueSelected={(value) => {
              onChangeInterval(value)
            }}
          />
        </Box>

        <PriceSourceSelect
          value={currentPriceSource}
          onValueSelected={(value) => {
            machine.setPriceSource(value)
          }}
        />
        <IndicatorSelect
          options={indicators}
          onValueSelected={(value) => {
            machine.addIndicator(value)
          }}
        />

        <FeatureGate name="PRICE_CHART_SHOW_DISPLAY">
          <DisplayOptions />
        </FeatureGate>

        <CompareMarkets
          onMarketSelected={(market, mode) => {
            const parsedActiveSymbol = parseTradingViewTicker(machine.getSymbol())
            const compareSymbol = makeTradingViewTicker(
              toMarketName(market),
              parsedActiveSymbol.priceSourceId,
              parsedActiveSymbol.collateralPrecision,
              parsedActiveSymbol.syntheticPrecision,
            )

            machine.compareOrAddSymbol(compareSymbol, mode)
          }}
        />
        <Menu.Root
          onSelect={({ value }) =>
            onDisplaySeriesSelected(parseInt(value) as TradingViewSeriesType)
          }
        >
          <Menu.Trigger asChild>
            <ActionIcon>
              <SvgIcon.SvgIconCandlestickChart size={16} />
            </ActionIcon>
          </Menu.Trigger>
          <Portal>
            <Menu.Positioner>
              <Menu.Content>
                <Menu.Item value={String(TradingViewSeriesType.Bars)}>
                  <HStack>
                    <SvgIcon.SvgIconSeriesBars />
                    <Box>
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.quick-action.display-series.bars.title"
                        defaultMessage="Bars"
                      />
                    </Box>
                  </HStack>
                </Menu.Item>

                <Menu.Item value={String(TradingViewSeriesType.Candles)}>
                  <HStack>
                    <SvgIcon.SvgIconSeriesCandles />
                    <Box>
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.quick-action.display-series.candles.title"
                        defaultMessage="Candles"
                      />
                    </Box>
                  </HStack>
                </Menu.Item>

                <Menu.Item value={String(TradingViewSeriesType.HollowCandles)}>
                  <HStack>
                    <SvgIcon.SvgIconSeriesHollowCandles />
                    <Box>
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.quick-action.display-series.hollow-candles.title"
                        defaultMessage="Hollow Candles"
                      />
                    </Box>
                  </HStack>
                </Menu.Item>

                <Menu.Item value={String(TradingViewSeriesType.HeikenAshi)}>
                  <HStack>
                    <SvgIcon.SvgIconSeriesHeikinAshi />
                    <Box>
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.quick-action.display-series.heikin-ashi.title"
                        defaultMessage="Heikin Ashi"
                      />
                    </Box>
                  </HStack>
                </Menu.Item>

                <Menu.Item value={String(TradingViewSeriesType.Line)}>
                  <HStack>
                    <SvgIcon.SvgIconSeriesLineChart />
                    <Box>
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.quick-action.display-series.line.title"
                        defaultMessage="Line"
                      />
                    </Box>
                  </HStack>
                </Menu.Item>

                <Menu.Item value={String(TradingViewSeriesType.Area)}>
                  <HStack>
                    <SvgIcon.SvgIconSeriesArea />
                    <Box>
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.quick-action.display-series.area.title"
                        defaultMessage="Area"
                      />
                    </Box>
                  </HStack>
                </Menu.Item>

                <Menu.Item value={String(TradingViewSeriesType.Baseline)}>
                  <HStack>
                    <SvgIcon.SvgIconSeriesBaseline />
                    <Box>
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.quick-action.display-series.baseline.title"
                        defaultMessage="Baseline"
                      />
                    </Box>
                  </HStack>
                </Menu.Item>
              </Menu.Content>
            </Menu.Positioner>
          </Portal>
        </Menu.Root>

        <ActionIcon
          onClick={() => {
            machine.takeSnapshot()
          }}
        >
          <SvgIcon.SvgIconCamera size={16} />
        </ActionIcon>

        <ActionIcon
          onClick={() => {
            machine.showChartSettings()
          }}
        >
          <SvgIcon.SvgIconIndustrialGear size={16} />
        </ActionIcon>
      </HStack>
    </Flex>
  )
}
