import { useEffect, useMemo, useState } from 'react'

import { type FilterPeriodValue } from '@x10/lib-ui-kit/components'

import { useFundingRates } from '@src/domain/api/hooks/markets-info/use-funding-rates'
import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { getChartStartTime } from '@src/domain/trade/utils/get-chart-start-time'

import { type FundingRateInterval } from '../types/funding-rate'
import { calcFundingRateForInterval } from '../utils/calc/calc-funding-rate-for-interval'

const DEFAULT_REFRESH_INTERVAL_1_HOUR = 60_000 * 60
const TIME_BUFFER = 10_000

export const useUpdatedFundingRates = (
  period: FilterPeriodValue,
  fundingRateInterval: FundingRateInterval,
) => {
  const market = useSelectedMarket()
  const [endTime, setEndTime] = useState(() => Date.now())

  const { data: marketStats } = useMarketStats({ marketName: market.name })

  const { data, isLoading, isFetching } = useFundingRates({
    marketName: market.name,
    startTime: getChartStartTime(period, endTime),
    endTime,
  })

  useEffect(() => {
    const timeout = marketStats?.nextFundingRate
      ? marketStats.nextFundingRate.toNumber() - Date.now() + TIME_BUFFER
      : 0

    const timerId = setTimeout(
      () => {
        setEndTime(Date.now())
      },
      timeout > 0 ? timeout : DEFAULT_REFRESH_INTERVAL_1_HOUR,
    )

    return () => clearTimeout(timerId)
  }, [data, marketStats?.nextFundingRate])

  return {
    data: useMemo(
      () =>
        data.map((item) => ({
          ...item,
          fundingRate: calcFundingRateForInterval(item.fundingRate, fundingRateInterval),
        })),
      [data, fundingRateInterval],
    ),
    isLoading,
    isFetching,
  }
}
