import type { MarketCollateralAsset } from '@x10/lib-core/types'
import { Decimal } from '@x10/lib-core/utils'

import { useNewOrderStore } from '@src/domain/trade/ui/widgets/order-form/store/new-order'
import { calcMarketOrderPrice } from '@src/domain/trade/utils/calc/calc-market-order-price'

export const calcMarketPrices = (collateral: MarketCollateralAsset) => {
  const newOrderState = useNewOrderStore.getState()

  return {
    buy: newOrderState.buy.price
      ? calcMarketOrderPrice('BUY', newOrderState.buy.price, collateral.precision)
      : null,
    sell: newOrderState.sell.price
      ? calcMarketOrderPrice('SELL', newOrderState.sell.price, collateral.precision)
      : null,
  }
}

export const roundDownToSyntheticPrecision = (value: Decimal, precision: Decimal) => {
  return value.setDecimalPlaces(precision.getDecimalPlaces(), Decimal.ROUND_DOWN)
}
