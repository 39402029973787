import { useCallback, useMemo, type FC } from 'react'
import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Grid } from '@visx/grid'
import { Group } from '@visx/group'
import { scaleBand, scaleLinear } from '@visx/scale'
import { Bar, Line, LinePath } from '@visx/shape'
import { Text } from '@visx/text'
import { withTooltip } from '@visx/tooltip'
import { bisector } from '@visx/vendor/d3-array'

import { invariant } from '@x10/lib-core/utils'
import { Box } from '@x10/lib-styled-system/jsx'
import { type FilterPeriodValue } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { type MarketOpenInterest } from '@src/domain/api/x10/trading/markets-info/open-interests.schema'
import { abbreviateNumber } from '@src/domain/core/utils/abbreviate-number'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'
import { getChartColumnTicks } from '@src/domain/trade/utils/get-chart-column-ticks'
import { getFormattedChartColumnTickValue } from '@src/domain/trade/utils/get-formatted-chart-column-tick-value'
import { scaleBandInvert } from '@src/domain/trade/utils/scale-band-invert'

import {
  OpenInterestPopoverChartTooltip,
  type TooltipData,
} from './open-interest-popover-chart-tooltip'
import { useCalcOpenInterestsChartMargins } from './use-calc-open-interests-chart-margins'
import { getPresetTokens } from './utils/get-preset-tokens'

type DataPoint = {
  openInterestCollateral: number
  openInterestSynthetic: number
  timestamp: number
}

const bisectX = bisector<DataPoint, number>((d) => d.timestamp).left

const ROW_TICKS = 4
const ROWS_OFFSET = 8
const BAR_WIDTH = 8
const DOMAIN_MIN_OFFSET = 0.01

export type OpenInterestPopoverLineChartProps = {
  data: MarketOpenInterest[]
  market: MarketName
  period: FilterPeriodValue
  width: number
  height: number
}

export const OpenInterestPopoverChart: FC<OpenInterestPopoverLineChartProps> =
  withTooltip<OpenInterestPopoverLineChartProps, TooltipData>(
    ({
      data,
      market,
      period,
      width,
      height,
      showTooltip,
      hideTooltip,
      tooltipData,
      tooltipTop = 0,
      tooltipLeft = 0,
    }) => {
      const { margin, setYCollateralAxisRef, setYSyntheticAxisRef } =
        useCalcOpenInterestsChartMargins()

      const gridWidth = width - margin.left - margin.right
      const gridHeight = height - margin.bottom
      const gridColumnsOffset = margin.left / 2

      const transformedData = useMemo<DataPoint[]>(() => {
        return data.map((d) => ({
          openInterestCollateral: d.openInterest.toNumber(),
          openInterestSynthetic: d.openInterestBase.toNumber(),
          timestamp: d.timestamp,
        }))
      }, [data])

      const xScaleBand = useMemo(
        () =>
          scaleBand({
            domain: transformedData.map((d) => d.timestamp),
            range: [0, gridWidth],
            round: true,
          }),
        [gridWidth, transformedData],
      )

      const yCollateralScale = useMemo(() => {
        const dataMin = Math.min(...transformedData.map((d) => d.openInterestCollateral))
        const dataMax = Math.max(...transformedData.map((d) => d.openInterestCollateral))

        return scaleLinear({
          domain: [
            Math.max(0, dataMin - (dataMax - dataMin) * DOMAIN_MIN_OFFSET),
            dataMax,
          ],
          range: [gridHeight, margin.top],
          nice: true,
        })
      }, [gridHeight, margin.top, transformedData])

      const ySyntheticScale = useMemo(() => {
        const dataMin = Math.min(...transformedData.map((d) => d.openInterestSynthetic))
        const dataMax = Math.max(...transformedData.map((d) => d.openInterestSynthetic))

        return scaleLinear({
          domain: [
            Math.max(0, dataMin - (dataMax - dataMin) * DOMAIN_MIN_OFFSET),
            dataMax,
          ],
          range: [gridHeight, margin.top],
          nice: true,
        })
      }, [gridHeight, margin.top, transformedData])

      const handleTooltip = useCallback(
        (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
          const { x } = localPoint(event) ?? { x: 0 }
          const x0 = scaleBandInvert(xScaleBand, x - margin.left, 0)
          const index = bisectX(transformedData, x0, 1)
          const d0 = transformedData[index - 1]
          const d1 = transformedData[index]

          invariant(d0, 'd0 is not defined')

          let d = d0

          if (d1 && d1.timestamp) {
            d = x0 - d0.timestamp > d1.timestamp - x0 ? d1 : d0
          }

          showTooltip({
            tooltipData: {
              market,
              openInterestCollateral: d.openInterestCollateral,
              openInterestSynthetic: d.openInterestSynthetic,
              timestamp: d.timestamp,
            },
            tooltipLeft:
              xScaleBand.bandwidth() / 2 + (xScaleBand(d.timestamp) || 0) + margin.left,
            tooltipTop: yCollateralScale(d.openInterestCollateral),
          })
        },
        [transformedData, showTooltip, market, xScaleBand, margin.left, yCollateralScale],
      )

      const columnTicksAmount = useMemo(
        () => getChartColumnTicks(period, transformedData[0]?.timestamp),
        [period, transformedData],
      )

      const { colorGreen50, colorWhite, colorWhite5, colorTransparent, fontSize10 } =
        getPresetTokens()

      const { collateralCode, syntheticCode } = parseMarketName(market)

      return (
        <Box>
          <svg width={width} height={height}>
            <Grid
              top={ROWS_OFFSET}
              left={margin.left + gridColumnsOffset}
              xScale={xScaleBand}
              yScale={yCollateralScale}
              width={gridWidth - margin.left}
              height={gridHeight}
              stroke={colorWhite5}
              numTicksRows={ROW_TICKS}
              numTicksColumns={columnTicksAmount}
              xOffset={-gridColumnsOffset}
              yOffset={-ROWS_OFFSET}
            />
            <Group top={0} left={margin.left + (xScaleBand.bandwidth() - BAR_WIDTH) / 2}>
              {transformedData.map((d) => {
                const barX = xScaleBand(d.timestamp) ?? 0
                const barY = ySyntheticScale(d.openInterestSynthetic)

                return (
                  <Bar
                    key={`bar-${d.timestamp}`}
                    x={barX}
                    y={barY}
                    width={BAR_WIDTH}
                    height={
                      gridHeight - Math.abs(ySyntheticScale(d.openInterestSynthetic))
                    }
                    fill={colorGreen50}
                  />
                )
              })}
            </Group>
            <Group top={0}>
              <LinePath
                transform={`translate(${margin.left}, 0)`}
                data={transformedData}
                x={(d) => xScaleBand.bandwidth() / 2 + (xScaleBand(d.timestamp) || 0)}
                y={(d) => yCollateralScale(d.openInterestCollateral) || 0}
                stroke={colorWhite}
                strokeWidth={1}
              />

              <AxisLeft
                innerRef={setYCollateralAxisRef}
                scale={yCollateralScale}
                left={12}
                numTicks={ROW_TICKS}
                hideTicks
                hideAxisLine
                tickLabelProps={{
                  fill: colorWhite,
                  style: { fontSize: fontSize10 },
                  textAnchor: 'start',
                }}
                tickFormat={(value) => abbreviateNumber(value.valueOf())}
              />
              <AxisBottom
                scale={xScaleBand}
                top={height - margin.bottom}
                left={margin.left}
                stroke={colorTransparent}
                numTicks={columnTicksAmount}
                tickLineProps={{
                  stroke: colorTransparent,
                }}
                tickLabelProps={{
                  fill: colorWhite,
                  style: { fontSize: fontSize10 },
                }}
                tickFormat={(value) =>
                  getFormattedChartColumnTickValue(period, value.valueOf())
                }
              />
              <AxisRight
                innerRef={setYSyntheticAxisRef}
                scale={ySyntheticScale}
                left={width - 16}
                numTicks={ROW_TICKS}
                hideTicks
                hideAxisLine
                tickLabelProps={{
                  fill: colorWhite,
                  style: { fontSize: fontSize10 },
                  textAnchor: 'end',
                }}
                tickFormat={(value) => abbreviateNumber(value.valueOf())}
              />

              <Text x={0} y={18} fill="white" style={{ fontSize: fontSize10 }}>
                {collateralCode}
              </Text>

              <Text
                x={width - 8}
                y={18}
                textAnchor="end"
                fill="white"
                style={{ fontSize: fontSize10 }}
              >
                {syntheticCode}
              </Text>
            </Group>

            <Bar
              x={margin.left}
              y={ROWS_OFFSET}
              width={gridWidth}
              height={gridHeight}
              fill="transparent"
              rx={14}
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />

            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: ROWS_OFFSET }}
                  to={{ x: tooltipLeft, y: gridHeight }}
                  stroke={colorWhite}
                  strokeWidth={1}
                  pointerEvents="none"
                  strokeDasharray="2,4"
                />

                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop}
                  r={4}
                  fill={colorWhite}
                  pointerEvents="none"
                />
              </g>
            )}
          </svg>

          {tooltipData && (
            <OpenInterestPopoverChartTooltip
              data={tooltipData}
              top={tooltipTop}
              left={tooltipLeft}
            />
          )}
        </Box>
      )
    },
  )
