import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import { isNil } from 'lodash'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import type { MarketAssets } from '@x10/lib-core/types'
import { checkRequired, Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { Button } from '@x10/lib-ui-kit/components'

import { MoneyInput } from '@src/domain/trade/components/money-input'
import { useLatestTradesStore } from '@src/domain/trade/store/latest-trades'
import { useOrderbookPricesStore } from '@src/domain/trade/store/orderbook-prices-store'
import {
  NewOrderStoreActions,
  useNewOrderStore,
} from '@src/domain/trade/ui/widgets/order-form/store/new-order'
import { getPlaceholderForPrecision } from '@src/domain/trade/utils/get-placeholder-for-precision'

export type OrderPriceInputApi = {
  startFollowingLatestPrice: () => void
  stopFollowingLatestPrice: () => void
}

type OrderPriceInputProps = {
  assets: MarketAssets
  error?: string
  onBlur: () => void
}

export const OrderPriceInput = forwardRef<OrderPriceInputApi, OrderPriceInputProps>(
  ({ assets, error, onBlur }, ref) => {
    const { formatMessage } = useIntl()

    const { lastPrice } = useLatestTradesStore()
    const { bestBidPrice, bestAskPrice, midPrice, selectedPrice } =
      useOrderbookPricesStore()

    const isUserPriceSpecified = useNewOrderStore((state) => state.ui.userPriceSpecified)
    const price = useNewOrderStore((state) => state.buy.price)

    const shouldFollowTheLastPriceRef = useRef(!isUserPriceSpecified)

    const startFollowingLatestPrice = useCallback(() => {
      shouldFollowTheLastPriceRef.current = true
    }, [])

    const stopFollowingLatestPrice = useCallback(() => {
      shouldFollowTheLastPriceRef.current = false
    }, [])

    const updatePrice = useCallback(
      (value: Decimal, stopFollowing = true) => {
        if (stopFollowing) {
          stopFollowingLatestPrice()
        }

        NewOrderStoreActions.setPrice(value, value, stopFollowing)
      },
      [stopFollowingLatestPrice],
    )

    useEffect(() => {
      if (shouldFollowTheLastPriceRef.current && lastPrice && !isUserPriceSpecified) {
        updatePrice(lastPrice, false)
      }
    }, [lastPrice, isUserPriceSpecified, updatePrice])

    useEffect(() => {
      if (selectedPrice) {
        updatePrice(selectedPrice)
      }
    }, [selectedPrice, updatePrice])

    useImperativeHandle(ref, () => {
      return {
        startFollowingLatestPrice,
        stopFollowingLatestPrice,
      }
    }, [startFollowingLatestPrice, stopFollowingLatestPrice])

    const handleMidPriceClick = () => {
      updatePrice(checkRequired(midPrice, 'midPrice'))
    }

    const handleLastPriceClick = () => {
      updatePrice(checkRequired(lastPrice, 'lastPrice'))
    }

    const handleBestAskPriceClick = () => {
      updatePrice(checkRequired(bestAskPrice, 'bestAskPrice'))
    }

    const handleBestBidPriceClick = () => {
      updatePrice(checkRequired(bestBidPrice, 'bestBidPrice'))
    }

    const collateralPrecisionDp = assets.collateral.precision.getDecimalPlaces()

    return (
      <Box w="100%">
        <MoneyInput.Item
          aria-invalid={Boolean(error)}
          message={error}
          value={Decimal.toNullableNumber(price)}
          currency={assets.collateral.code}
          precision={collateralPrecisionDp}
          placeholder={getPlaceholderForPrecision(collateralPrecisionDp)}
          unit={formatMessage({
            id: 'workspace.trade.widget.order-form.shared.input.price.title',
            defaultMessage: 'Price',
          })}
          onFocus={stopFollowingLatestPrice}
          onBlur={onBlur}
          onChange={(value) => {
            const nextValue = Decimal.fromNullableValue(value)

            NewOrderStoreActions.setPrice(nextValue, nextValue, true)
          }}
        />

        <HStack mt="s-8" gap="s-4">
          <Button
            visual="secondary-grey"
            size="action"
            disabled={isNil(midPrice)}
            onClick={handleMidPriceClick}
          >
            <FormattedMessage
              id="workspace.trade.widget.order-form.shared.input.price.action.mid.title"
              defaultMessage="Mid"
            />
          </Button>
          <Button
            visual="secondary-grey"
            size="action"
            disabled={isNil(lastPrice)}
            onClick={handleLastPriceClick}
          >
            <FormattedMessage
              id="workspace.trade.widget.order-form.shared.input.price.action.last.title"
              defaultMessage="Last"
            />
          </Button>
          <Button
            visual="secondary-grey"
            size="action"
            disabled={isNil(bestAskPrice)}
            onClick={handleBestAskPriceClick}
          >
            <FormattedMessage
              id="workspace.trade.widget.order-form.shared.input.price.action.best-ask.title"
              defaultMessage="Best Ask"
            />
          </Button>
          <Button
            visual="secondary-grey"
            size="action"
            disabled={isNil(bestBidPrice)}
            onClick={handleBestBidPriceClick}
          >
            <FormattedMessage
              id="workspace.trade.widget.order-form.shared.input.price.action.best-bid.title"
              defaultMessage="Best Bid"
            />
          </Button>
        </HStack>
      </Box>
    )
  },
)

OrderPriceInput.displayName = 'OrderPriceInput'
