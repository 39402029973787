import { Component, type PropsWithChildren, type ReactNode } from 'react'

import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'

type InlineErrorBoundaryState = { error?: Error }

export class InlineErrorBoundary extends Component<
  PropsWithChildren<{ fallback?: ReactNode }>,
  InlineErrorBoundaryState
> {
  constructor(props: PropsWithChildren) {
    super(props)

    this.state = {}
  }

  static getDerivedStateFromError(error: Error) {
    captureExceptionWithSentry(error)

    return { error }
  }

  override render() {
    const { fallback, children } = this.props
    const { error } = this.state

    return error ? fallback : children
  }
}
