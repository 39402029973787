import { useState, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { invariant, type Decimal } from '@x10/lib-core/utils'
import { VStack } from '@x10/lib-styled-system/jsx'
import {
  Button,
  Callout,
  Cell,
  Checkbox,
  Popover,
  Portal,
} from '@x10/lib-ui-kit/components'

import type { UserOrder, UserPosition } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { PopoverHeader } from '@src/domain/core/ui/components/popover-header'
import { InputTooltip } from '@src/domain/trade/components/input-tooltip'
import { useInputFloatingElement } from '@src/domain/trade/hooks/use-input-floating-element'
import { useConfirmationStore } from '@src/domain/trade/store/confirmation-store'

import { useLimitCloseFormContext } from './limit-close-form-provider'
import { LimitCloseOrdersHover, type HoverApi } from './limit-close-orders-hover'

type LimitCloseActionButtonProps = {
  position: UserPosition
  reduceOnlyOrders: UserOrder[]
  orderSize: Decimal | null
  orderPrice: Decimal | null
  alerts?: string[]
  error?: string
  loading?: boolean
  disabled?: boolean
  onConfirm: () => Promise<void>
}

export const LimitCloseActionButton: FC<LimitCloseActionButtonProps> = ({
  position,
  reduceOnlyOrders,
  orderSize,
  orderPrice,
  alerts,
  error,
  loading,
  disabled,
  onConfirm,
}) => {
  const orderCost = useLimitCloseFormContext((state) => state.orderCost)
  const { dontConfirmLimitClose, setDontConfirmLimitClose } = useConfirmationStore()
  const [dontShowConfirmation, setDontShowConfirmation] = useState(
    dontConfirmLimitClose ?? false,
  )
  const submitTooltip = useInputFloatingElement()
  const submitTooltipVisible = submitTooltip.isVisible && Boolean(error)

  const formatMarketAsset = useFormatMarketAsset({
    marketName: position.market,
    showSymbol: true,
  })

  const renderButton = (
    hover: HoverApi,
    confirmationPopoverOpen: boolean,
    onClick: () => void | Promise<void>,
  ) => {
    // `onMouseOver` / `onMouseOut` is used to show/hide the tooltip for disabled button
    return (
      <Button
        visual="secondary-grey"
        loading={loading}
        disabled={disabled}
        css={{ w: '4.5rem' }}
        onMouseOver={() => {
          submitTooltip.show()
          !disabled && !confirmationPopoverOpen && hover.open()
        }}
        onMouseOut={() => {
          submitTooltip.hide()
          hover.close()
        }}
        onClick={onClick}
      >
        <FormattedMessage
          id="workspace.trade.widget.trading-history.mode.positions.action.limit.title"
          defaultMessage="Limit{ ordersCount, select, 0 {} other { ({ordersCount})} }"
          values={{
            ordersCount: reduceOnlyOrders.length,
          }}
        />

        <InputTooltip open={submitTooltipVisible} visual="error">
          {error}
        </InputTooltip>
      </Button>
    )
  }

  if (dontConfirmLimitClose) {
    return (
      <LimitCloseOrdersHover orders={reduceOnlyOrders}>
        {(hover) =>
          renderButton(hover, false, async () => {
            hover.closeImmediately()
            await onConfirm()
          })
        }
      </LimitCloseOrdersHover>
    )
  }

  invariant(!alerts || alerts.length <= 1, 'only one order alert is allowed')

  const orderAlert = alerts?.[0]
  const orderValue = orderSize && orderPrice ? orderSize.times(orderPrice) : null

  return (
    <Popover.Root
      portalled
      lazyMount
      unmountOnExit
      positioning={{ placement: 'top-start' }}
    >
      <Popover.Context>
        {({ open: popoverOpen }) => {
          return (
            <LimitCloseOrdersHover orders={reduceOnlyOrders}>
              {(hover) => {
                return (
                  <Popover.Trigger asChild>
                    {renderButton(hover, popoverOpen, () => {
                      hover.closeImmediately()
                    })}
                  </Popover.Trigger>
                )
              }}
            </LimitCloseOrdersHover>
          )
        }}
      </Popover.Context>

      <Portal>
        <Popover.Positioner
          css={{
            ml: '-5.25rem',
          }}
        >
          <Popover.Content css={{ borderRadius: 'r-16', p: 's-16', w: '21.125rem!' }}>
            <PopoverHeader>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.limit-close-popover.title"
                defaultMessage="Limit Close"
              />
            </PopoverHeader>

            <VStack css={{ gap: 's-24', alignItems: 'start' }}>
              <Cell.Group css={{ textStyle: 'small', gap: 's-8' }}>
                <Cell.Item>
                  <Cell.Title>
                    <FormattedMessage
                      id="workspace.trade.widget.trading-history.mode.positions.limit-close-popover.info.order-size.title"
                      defaultMessage="Closing Order Size"
                    />
                  </Cell.Title>
                  <Cell.Value>
                    {formatMarketAsset({
                      amount: orderSize,
                      type: 'synthetic',
                    })}
                  </Cell.Value>
                </Cell.Item>
                <Cell.Item>
                  <Cell.Title>
                    <FormattedMessage
                      id="workspace.trade.widget.trading-history.mode.positions.limit-close-popover.info.order-price.title"
                      defaultMessage="Order Price"
                    />
                  </Cell.Title>
                  <Cell.Value>
                    {formatMarketAsset({
                      amount: orderPrice,
                      type: 'collateral',
                    })}
                  </Cell.Value>
                </Cell.Item>
                <Cell.Item>
                  <Cell.Title>
                    <FormattedMessage
                      id="workspace.trade.widget.trading-history.mode.positions.limit-close-popover.info.order-value.title"
                      defaultMessage="Order Value"
                    />
                  </Cell.Title>
                  <Cell.Value>
                    {formatMarketAsset({
                      amount: orderValue,
                      type: 'collateral',
                    })}
                  </Cell.Value>
                </Cell.Item>
                <Cell.Item>
                  <Cell.Title>
                    <FormattedMessage
                      id="workspace.trade.widget.trading-history.mode.positions.limit-close-popover.info.order-cost.title"
                      defaultMessage="Order Cost"
                    />
                  </Cell.Title>
                  <Cell.Value>
                    {formatMarketAsset({
                      amount: orderCost,
                      type: 'collateral',
                    })}
                  </Cell.Value>
                </Cell.Item>
              </Cell.Group>

              {orderAlert && <Callout visual="info">{orderAlert}</Callout>}

              <Checkbox
                checked={dontShowConfirmation}
                onCheckedChange={(details) => {
                  setDontShowConfirmation(details.checked as boolean)
                }}
              >
                <FormattedMessage
                  id="workspace.trade.widget.trading-history.mode.positions.limit-close-popover.dont-show-checkbox.title"
                  defaultMessage="Don't show again"
                />
              </Checkbox>

              <Popover.Context>
                {({ setOpen }) => {
                  return (
                    <Button
                      visual="primary-red"
                      size="large"
                      loading={loading}
                      disabled={disabled}
                      onClick={async () => {
                        await onConfirm()
                        setOpen(false)
                        setDontConfirmLimitClose(dontShowConfirmation)
                      }}
                    >
                      <FormattedMessage
                        id="workspace.trade.widget.trading-history.mode.positions.limit-close-popover.cta.title"
                        defaultMessage="Close Position"
                      />
                    </Button>
                  )
                }}
              </Popover.Context>
            </VStack>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  )
}
