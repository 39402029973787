import type { FC } from 'react'

import { ActionIcon, SvgIcon } from '@x10/lib-ui-kit/components'

type ToggleCollapsedButtonProps = {
  variant: 'horizontal' | 'vertical'
  collapsed: boolean
  onClick: () => void
}

export const ToggleCollapsedButton: FC<ToggleCollapsedButtonProps> = ({
  variant,
  collapsed,
  onClick,
}) => {
  const [ShevronCollapsed, ShevronExpanded] =
    variant === 'horizontal'
      ? [SvgIcon.SvgIconShevronLeft, SvgIcon.SvgIconShevronRight]
      : [SvgIcon.SvgIconShevronUp, SvgIcon.SvgIconShevronDown]

  return (
    <ActionIcon
      css={{
        color: 'dark.green',
        borderRadius: 'r-full',
        backgroundColor: 'token.green-10',
      }}
      onClick={onClick}
    >
      {collapsed ? <ShevronCollapsed /> : <ShevronExpanded />}
    </ActionIcon>
  )
}
