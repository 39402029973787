import { type FC } from 'react'

import { FilterPeriod, FilterPeriodValue } from '@x10/lib-ui-kit/components'

type Props = {
  onChange: (value: FilterPeriodValue) => void
}

export const FundingRateFilterPeriod: FC<Props> = ({ onChange }) => (
  <FilterPeriod
    periods={[
      FilterPeriodValue.OneDay,
      FilterPeriodValue.OneWeek,
      FilterPeriodValue.OneMonth,
    ]}
    customPeriod={false}
    onChange={onChange}
  />
)
