import { sortBy } from 'lodash'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'

import {
  removeRestEnvelope,
  removeRestEnvelopeSuspense,
} from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

export const useLeverage = ({ marketsNames }: { marketsNames: MarketName[] }) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(marketsNames)

  const { data, isLoading } = useQuery({
    queryKey: [QueryKey.UserAccountLeverage, sortedMarketsNames, accountId],
    queryFn: () => {
      return API.trading.account.getLeverage(
        {
          marketsNames: sortedMarketsNames,
        },
        accountId,
      )
    },
    enabled: Boolean(accountId),
  })

  return {
    data: removeRestEnvelope(data),
    isLoading,
  }
}

export const useLeverageSuspense = ({ marketsNames }: { marketsNames: MarketName[] }) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(marketsNames)

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.UserAccountLeverage, sortedMarketsNames, accountId],
    queryFn: () => {
      return API.trading.account.getLeverage(
        {
          marketsNames: sortedMarketsNames,
        },
        accountId,
      )
    },
  })

  return {
    data: removeRestEnvelopeSuspense(data),
  }
}
