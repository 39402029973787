import { useCallback, useMemo, useRef, useState } from 'react'

import { checkRequired, Decimal, invariant, notReachable } from '@x10/lib-core/utils'

import type { CachedMarket, MarketStats } from '@src/domain/api/x10'
import type { OrderType } from '@src/domain/api/x10/common'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import type { TpSlEntrySide } from '@src/domain/core/types/common'
import type {
  OrderPriceType,
  OrderTpSlType,
  OrderTriggerPriceType,
} from '@src/domain/starkex/stark-perpetual-order'
import type { TpSlDialogInitiator } from '@src/domain/trade/components/order-tp-sl-dialog/types'
import type {
  FormValidationErrors,
  FormValidationFieldName,
  FormValidationHook,
  FormValidationResult,
  FormValidationTouchedFields,
} from '@src/domain/trade/validation/types'

import { useTpSlErrorMessage, type Direction, type Stat } from './use-tp-sl-error-message'

type FieldName = FormValidationFieldName<
  'tpTriggerPrice' | 'tpOrderPrice' | 'slTriggerPrice' | 'slOrderPrice'
>
type Value = {
  tpSlType: OrderTpSlType
  tpSlSide: TpSlEntrySide

  tpChecked: boolean
  tpTriggerPrice?: Decimal | null
  tpTriggerPriceType?: OrderTriggerPriceType
  tpOrderPrice?: Decimal | null
  tpOrderPriceType?: OrderPriceType

  slChecked: boolean
  slTriggerPrice?: Decimal | null
  slTriggerPriceType?: OrderTriggerPriceType
  slOrderPrice?: Decimal | null
  slOrderPriceType?: OrderPriceType
}
type Context = {
  initiator: TpSlDialogInitiator

  market: CachedMarket
  marketStats?: MarketStats
  minPriceChange?: string

  orderType: OrderType
  buyPrice: Decimal | null
  sellPrice: Decimal | null
}
type Result = FormValidationResult<unknown, { errors: FormValidationErrors<FieldName> }>
export type HookResult = FormValidationErrors<FieldName> | undefined

const validateLongOrder = (
  value: Value,
  ctx: Context,
  formatPercent: ReturnType<typeof useFormatPercent>,
  errorMessage: ReturnType<typeof useTpSlErrorMessage>,
): Result => {
  const { tradingConfig } = ctx.market
  const errors: Partial<Record<FieldName, string>> = {}

  if (value.tpChecked) {
    if (!value.tpTriggerPrice) {
      errors.tpTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.tpOrderPriceType !== 'MARKET' && !value.tpOrderPrice) {
      errors.tpOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.tpTriggerPrice) {
      invariant(value.tpTriggerPrice, '`tpTriggerPrice` is required')

      if (value.tpTriggerPrice.lt(tradingConfig.minPriceChange)) {
        errors.tpTriggerPrice = errorMessage.getTriggerPriceMin(ctx.minPriceChange)
      }

      // Trigger price > Order entry price
      if (
        !errors.tpTriggerPrice &&
        ctx.buyPrice &&
        value.tpTriggerPrice.lte(ctx.buyPrice)
      ) {
        errors.tpTriggerPrice = errorMessage.getTriggerPriceToEntryPrice('ABOVE')
      }

      if (value.tpOrderPriceType !== 'MARKET' && !errors.tpOrderPrice) {
        invariant(value.tpOrderPrice, '`tpOrderPrice` is required')

        const maxOrderPrice = value.tpTriggerPrice.times(
          Decimal.ONE.minus(ctx.market.tradingConfig.limitPriceFloor),
        )

        // Order Price ≥ Trigger price * (1-Limit Order Floor Ratio)
        if (value.tpOrderPrice.lt(maxOrderPrice)) {
          errors.tpOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceFloor, {
              precision: PercentPrecision.TradingRules,
            }),
            'ABOVE',
            'MINUS',
          )
        }
      }
    }
  }

  if (value.slChecked) {
    if (!value.slTriggerPrice) {
      errors.slTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.slOrderPriceType !== 'MARKET' && !value.slOrderPrice) {
      errors.slOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.slTriggerPrice) {
      invariant(value.slTriggerPrice, '`slTriggerPrice` is required')

      if (value.slTriggerPrice.lt(tradingConfig.minPriceChange)) {
        errors.slTriggerPrice = errorMessage.getTriggerPriceMin(ctx.minPriceChange)
      }

      // Trigger price < Entry order price
      if (
        !errors.slTriggerPrice &&
        ctx.buyPrice &&
        value.slTriggerPrice.gte(ctx.buyPrice)
      ) {
        errors.slTriggerPrice = errorMessage.getTriggerPriceToEntryPrice('BELOW')
      }

      if (value.slOrderPriceType !== 'MARKET' && !errors.slOrderPrice) {
        invariant(value.slOrderPrice, '`slOrderPrice` is required')

        const minOrderPrice = value.slTriggerPrice.times(
          Decimal.ONE.minus(ctx.market.tradingConfig.limitPriceFloor),
        )

        // Order Price ≥ Trigger price * (1-Limit Order Floor Ratio)
        if (value.slOrderPrice.lt(minOrderPrice)) {
          errors.slOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceFloor, {
              precision: PercentPrecision.TradingRules,
            }),
            'ABOVE',
            'MINUS',
          )
        }
      }
    }
  }

  return Object.keys(errors).length > 0 ? { success: false, errors } : { success: true }
}

const validateShortOrder = (
  value: Value,
  ctx: Context,
  formatPercent: ReturnType<typeof useFormatPercent>,
  errorMessage: ReturnType<typeof useTpSlErrorMessage>,
): Result => {
  const { tradingConfig } = ctx.market
  const errors: Partial<Record<FieldName, string>> = {}

  if (value.tpChecked) {
    if (!value.tpTriggerPrice) {
      errors.tpTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.tpOrderPriceType !== 'MARKET' && !value.tpOrderPrice) {
      errors.tpOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.tpTriggerPrice) {
      invariant(value.tpTriggerPrice, '`tpTriggerPrice` is required')

      if (value.tpTriggerPrice.lt(tradingConfig.minPriceChange)) {
        errors.tpTriggerPrice = errorMessage.getTriggerPriceMin(ctx.minPriceChange)
      }

      // Trigger price < Entry order price
      if (
        !errors.tpTriggerPrice &&
        ctx.sellPrice &&
        value.tpTriggerPrice.gte(ctx.sellPrice)
      ) {
        errors.tpTriggerPrice = errorMessage.getTriggerPriceToEntryPrice('BELOW')
      }

      if (value.tpOrderPriceType !== 'MARKET' && !errors.tpOrderPrice) {
        invariant(value.tpOrderPrice, '`tpOrderPrice` is required')

        const maxOrderPrice = value.tpTriggerPrice.times(
          Decimal.ONE.plus(ctx.market.tradingConfig.limitPriceCap),
        )

        // Order Price ≤ Trigger Price * (1+Limit Order Price Cap)
        if (value.tpOrderPrice.gt(maxOrderPrice)) {
          errors.tpOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceCap, {
              precision: PercentPrecision.TradingRules,
            }),
            'BELOW',
            'PLUS',
          )
        }
      }
    }
  }

  if (value.slChecked) {
    if (!value.slTriggerPrice) {
      errors.slTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.slOrderPriceType !== 'MARKET' && !value.slOrderPrice) {
      errors.slOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.slTriggerPrice) {
      invariant(value.slTriggerPrice, '`slTriggerPrice` is required')

      if (value.slTriggerPrice.lt(tradingConfig.minPriceChange)) {
        errors.slTriggerPrice = errorMessage.getTriggerPriceMin(ctx.minPriceChange)
      }

      // Trigger price > Entry order price
      if (
        !errors.slTriggerPrice &&
        ctx.sellPrice &&
        value.slTriggerPrice.lte(ctx.sellPrice)
      ) {
        errors.slTriggerPrice = errorMessage.getTriggerPriceToEntryPrice('ABOVE')
      }

      if (value.slOrderPriceType !== 'MARKET' && !errors.slOrderPrice) {
        invariant(value.slOrderPrice, '`slOrderPrice` is required')

        const minOrderPrice = value.slTriggerPrice.times(
          Decimal.ONE.plus(ctx.market.tradingConfig.limitPriceFloor),
        )

        // Order Price ≤ Trigger Price * (1+Limit Order Price Cap)
        if (value.slOrderPrice.gt(minOrderPrice)) {
          errors.slOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceFloor, {
              precision: PercentPrecision.TradingRules,
            }),
            'BELOW',
            'PLUS',
          )
        }
      }
    }
  }

  return Object.keys(errors).length > 0 ? { success: false, errors } : { success: true }
}

const getPositionStatsPrice = (
  triggerPriceType: OrderTriggerPriceType | undefined,
  marketStats: MarketStats,
): Decimal => {
  switch (triggerPriceType) {
    case 'MARK':
      return marketStats.markPrice
    case 'LAST':
      return marketStats.lastPrice
    case 'INDEX':
      return marketStats.indexPrice
    default:
      return notReachable(triggerPriceType)
  }
}

const validateLongPosition = (
  value: Value,
  ctx: Context,
  formatPercent: ReturnType<typeof useFormatPercent>,
  errorMessage: ReturnType<typeof useTpSlErrorMessage>,
): Result => {
  const { tradingConfig } = ctx.market
  const errors: Partial<Record<FieldName, string>> = {}

  if (!ctx.marketStats) {
    return {
      success: false,
      errors: {
        '@internal': 'Missing `marketStats`',
      },
    }
  }

  if (ctx.initiator === 'ORDER_FORM' && !ctx.buyPrice) {
    return {
      success: false,
      errors: {
        '@internal': 'Missing `buyPrice`',
      },
    }
  }

  const getBuyPrice = (
    triggerPriceType: OrderTriggerPriceType | undefined,
    marketStats: MarketStats,
  ) => {
    return ctx.initiator === 'ORDER_FORM'
      ? checkRequired(ctx.buyPrice, 'buyPrice')
      : getPositionStatsPrice(triggerPriceType, marketStats)
  }

  const getTriggerPriceToOtherPriceMessage = (direction: Direction, stat: Stat) => {
    return ctx.initiator === 'ORDER_FORM'
      ? errorMessage.getTriggerPriceToEntryPrice(direction)
      : errorMessage.getTriggerPriceToStatPrice(direction, stat)
  }

  if (value.tpChecked) {
    if (!value.tpTriggerPrice) {
      errors.tpTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.tpOrderPriceType !== 'MARKET' && !value.tpOrderPrice) {
      errors.tpOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.tpTriggerPrice) {
      invariant(value.tpTriggerPrice, '`tpTriggerPrice` is required')

      if (value.tpTriggerPrice.lt(tradingConfig.minPriceChange)) {
        errors.tpTriggerPrice = errorMessage.getTriggerPriceMin(ctx.minPriceChange)
      }

      const buyPrice = getBuyPrice(value.tpTriggerPriceType, ctx.marketStats)

      // Trigger price > Current Last price or Mark price or Index price, depending on the Trigger price type
      // (current order price is used if the TP/SL is created from the order form)
      if (!errors.tpTriggerPrice && value.tpTriggerPrice.lte(buyPrice)) {
        errors.tpTriggerPrice = getTriggerPriceToOtherPriceMessage(
          'ABOVE',
          value.tpTriggerPriceType,
        )
      }

      if (value.tpOrderPriceType !== 'MARKET' && !errors.tpOrderPrice) {
        invariant(value.tpOrderPrice, '`tpOrderPrice` is required')

        const minOrderPrice = value.tpTriggerPrice.times(
          Decimal.ONE.minus(ctx.market.tradingConfig.limitPriceCap),
        )

        // Order Price ≥ Trigger price * (1-Limit Order Floor Ratio)
        if (value.tpOrderPrice.lt(minOrderPrice)) {
          errors.tpOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceCap, {
              precision: PercentPrecision.TradingRules,
            }),
            'ABOVE',
            'MINUS',
          )
        }
      }
    }
  }

  if (value.slChecked) {
    if (!value.slTriggerPrice) {
      errors.slTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.slOrderPriceType !== 'MARKET' && !value.slOrderPrice) {
      errors.slOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.slTriggerPrice) {
      invariant(value.slTriggerPrice, '`slTriggerPrice` is required')

      if (value.slTriggerPrice.lt(tradingConfig.minPriceChange)) {
        errors.slTriggerPrice = errorMessage.getTriggerPriceMin(ctx.minPriceChange)
      }

      const buyPrice = getBuyPrice(value.slTriggerPriceType, ctx.marketStats)

      // Trigger price < Current Last price or Mark price or Index price, depending on the Trigger price type
      // (current order price is used if the TP/SL is created from the order form)
      if (!errors.slTriggerPrice && value.slTriggerPrice.gte(buyPrice)) {
        errors.slTriggerPrice = getTriggerPriceToOtherPriceMessage(
          'BELOW',
          value.slTriggerPriceType,
        )
      }

      if (value.slOrderPriceType !== 'MARKET' && !errors.slOrderPrice) {
        invariant(value.slOrderPrice, '`slOrderPrice` is required')

        const minOrderPrice = value.slTriggerPrice.times(
          Decimal.ONE.minus(ctx.market.tradingConfig.limitPriceFloor),
        )

        // Order Price ≥ Trigger price * (1-Limit Order Floor Ratio)"
        if (value.slOrderPrice.lt(minOrderPrice)) {
          errors.slOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceFloor, {
              precision: PercentPrecision.TradingRules,
            }),
            'ABOVE',
            'MINUS',
          )
        }
      }
    }
  }

  return Object.keys(errors).length > 0 ? { success: false, errors } : { success: true }
}

const validateShortPosition = (
  value: Value,
  ctx: Context,
  formatPercent: ReturnType<typeof useFormatPercent>,
  errorMessage: ReturnType<typeof useTpSlErrorMessage>,
): Result => {
  const { tradingConfig } = ctx.market
  const errors: Partial<Record<FieldName, string>> = {}

  if (!ctx.marketStats) {
    return {
      success: false,
      errors: {
        '@internal': 'Missing `marketStats`',
      },
    }
  }

  if (ctx.initiator === 'ORDER_FORM' && !ctx.sellPrice) {
    return {
      success: false,
      errors: {
        '@internal': 'Missing `sellPrice`',
      },
    }
  }

  const getSellPrice = (
    triggerPriceType: OrderTriggerPriceType | undefined,
    marketStats: MarketStats,
  ) => {
    return ctx.initiator === 'ORDER_FORM'
      ? checkRequired(ctx.sellPrice, 'sellPrice')
      : getPositionStatsPrice(triggerPriceType, marketStats)
  }

  const getTriggerPriceToOtherPriceMessage = (direction: Direction, stat: Stat) => {
    return ctx.initiator === 'ORDER_FORM'
      ? errorMessage.getTriggerPriceToEntryPrice(direction)
      : errorMessage.getTriggerPriceToStatPrice(direction, stat)
  }

  if (value.tpChecked) {
    if (!value.tpTriggerPrice) {
      errors.tpTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.tpOrderPriceType !== 'MARKET' && !value.tpOrderPrice) {
      errors.tpOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.tpTriggerPrice) {
      invariant(value.tpTriggerPrice, '`tpTriggerPrice` is required')

      const sellPrice = getSellPrice(value.tpTriggerPriceType, ctx.marketStats)

      // Trigger price < current Last price or Mark price or Index price, depending on the Trigger price type
      // (current order price is used if the TP/SL is created from the order form)
      if (value.tpTriggerPrice.gte(sellPrice)) {
        errors.tpTriggerPrice = getTriggerPriceToOtherPriceMessage(
          'BELOW',
          value.tpTriggerPriceType,
        )
      }

      if (value.tpOrderPriceType !== 'MARKET' && !errors.tpOrderPrice) {
        invariant(value.tpOrderPrice, '`tpOrderPrice` is required')

        const maxOrderPrice = value.tpTriggerPrice.times(
          Decimal.ONE.plus(ctx.market.tradingConfig.limitPriceCap),
        )

        // Order Price ≤ Trigger Price * (1+Limit Order Price Cap)
        if (value.tpOrderPrice.gt(maxOrderPrice)) {
          errors.tpOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceCap, {
              precision: PercentPrecision.TradingRules,
            }),
            'BELOW',
            'PLUS',
          )
        }
      }
    }
  }

  if (value.slChecked) {
    if (!value.slTriggerPrice) {
      errors.slTriggerPrice = errorMessage.getTriggerPriceRequired()
    }

    if (value.slOrderPriceType !== 'MARKET' && !value.slOrderPrice) {
      errors.slOrderPrice = errorMessage.getOrderPriceRequired()
    }

    if (!errors.slTriggerPrice) {
      invariant(value.slTriggerPrice, '`slTriggerPrice` is required')

      const sellPrice = getSellPrice(value.slTriggerPriceType, ctx.marketStats)

      // Trigger price > current Last price or Mark price or Index price, depending on the Trigger price type
      // (current order price is used if the TP/SL is created from the order form)
      if (value.slTriggerPrice.lte(sellPrice)) {
        errors.slTriggerPrice = getTriggerPriceToOtherPriceMessage(
          'ABOVE',
          value.slTriggerPriceType,
        )
      }

      if (value.slOrderPriceType !== 'MARKET' && !errors.slOrderPrice) {
        invariant(value.slOrderPrice, '`slOrderPrice` is required')

        const maxOrderPrice = value.slTriggerPrice.times(
          Decimal.ONE.plus(ctx.market.tradingConfig.limitPriceFloor),
        )

        // Order Price ≤ Trigger Price * (1+Limit Order Price Cap)
        if (value.slOrderPrice.gt(maxOrderPrice)) {
          errors.slOrderPrice = errorMessage.getOrderPriceToTriggerPrice(
            formatPercent(tradingConfig.limitPriceFloor, {
              precision: PercentPrecision.TradingRules,
            }),
            'BELOW',
            'PLUS',
          )
        }
      }
    }
  }

  return Object.keys(errors).length > 0 ? { success: false, errors } : { success: true }
}

/**
 * See:
 * - https://docs.google.com/spreadsheets/d/1EWSqQmh0CjmJj88apPtsR00jqAO0QYmBb8UHZ6zY1bw/edit#gid=0
 * - https://www.notion.so/ex10/New-Validation-Logic-9757e22312d34be293abebe178e70a0e
 */
const useValidateFn = () => {
  const formatPercent = useFormatPercent()
  const errorMessage = useTpSlErrorMessage()

  return useCallback(
    (value: Value, ctx: Context): Result => {
      if (value.tpSlType === 'ORDER') {
        if (value.tpSlSide === 'LONG') {
          return validateLongOrder(value, ctx, formatPercent, errorMessage)
        } else {
          return validateShortOrder(value, ctx, formatPercent, errorMessage)
        }
      }

      if (value.tpSlType === 'POSITION') {
        if (value.tpSlSide === 'LONG') {
          return validateLongPosition(value, ctx, formatPercent, errorMessage)
        } else {
          return validateShortPosition(value, ctx, formatPercent, errorMessage)
        }
      }

      return { success: true }
    },
    [errorMessage, formatPercent],
  )
}

export const useTpSlFormValidation = (): FormValidationHook<
  FieldName,
  Value,
  Context,
  HookResult
> => {
  const validateFn = useValidateFn()

  const [isTouched, setTouched] = useState<FormValidationTouchedFields<FieldName>>({})
  const isValidatedAtLeastOnceRef = useRef(false)

  const touch = useCallback((fieldName: FieldName) => {
    isValidatedAtLeastOnceRef.current &&
      setTouched((prev) => ({ ...prev, [fieldName]: true }))
  }, [])

  const validate = useCallback(
    (value: Value, ctx: Context) => {
      const result = validateFn(value, ctx)
      const errors = result.success ? undefined : result.errors

      isValidatedAtLeastOnceRef.current = true

      return errors
    },
    [validateFn],
  )

  const reset = useCallback((fields?: FieldName[]) => {
    if (fields) {
      setTouched((prev) => ({
        ...prev,
        ...fields.reduce((acc, field) => {
          acc[field] = false
          return acc
        }, {} as FormValidationTouchedFields<FieldName>),
      }))
    } else {
      setTouched({})
    }
  }, [])

  return useMemo(
    () => ({
      isTouched,
      touch,
      validate,
      reset,
    }),
    [isTouched, touch, validate, reset],
  )
}
