import {
  createContext,
  useContext,
  useMemo,
  useState,
  type FC,
  type PropsWithChildren,
} from 'react'

import { invariant } from '@x10/lib-core/utils'

type QuickTradingMode = 'MARKET' | 'LIMIT'

export const TradeContext = createContext<{
  quickTradingMode: QuickTradingMode | undefined
  setQuickTradingMode: (mode: QuickTradingMode | undefined) => void
} | null>(null)

type TradeProviderProps = PropsWithChildren

export const TradeProvider: FC<TradeProviderProps> = ({ children }) => {
  const [quickTradingMode, setQuickTradingMode] = useState<QuickTradingMode>()

  const value = useMemo(
    () => ({
      quickTradingMode,
      setQuickTradingMode,
    }),
    [quickTradingMode, setQuickTradingMode],
  )

  return <TradeContext.Provider value={value}>{children}</TradeContext.Provider>
}

export const useTradeContext = () => {
  const ctx = useContext(TradeContext)

  invariant(
    ctx !== null,
    'You can access context only in components inside `TradeProvider` component',
  )

  return ctx
}
