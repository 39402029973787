import { useState } from 'react'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { getLogger } from '@x10/lib-core/utils'
import { Flex, styled } from '@x10/lib-styled-system/jsx'
import { ActionIcon, Button, Input, SvgIcon } from '@x10/lib-ui-kit/components'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'
import { useCreateSubAccount } from '@src/domain/auth/hooks/use-auth'
import { createNotificationToast } from '@src/domain/core/ui/components/notification'
import { setAccount } from '@src/domain/trade/ui/widgets/account/components/account-header/set-account'

import { CreateAccountCurrencySelect } from './create-account-currency-select'
import { useAccountSelectorState } from './state'

const LOGGER = getLogger('app-exchange.trade.create-account-form')

export const CreateAccountForm = () => {
  const [createInputValue, setCreateInputValue] = useState('')
  const { setIsCreatingFormOpen, onClose } = useAccountSelectorState()
  const { formatMessage } = useIntl()
  const { createSubAccount, isPending: isCreateSubAccountPending } = useCreateSubAccount()
  const accounts = useAccounts()

  const onCreateAccount = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    try {
      const accountResult = await createSubAccount(createInputValue)
      if (accountResult.isErr()) {
        return
      }
      const account = accountResult.value
      setAccount({
        accountIndex: account.accountIndex,
        accountId: account.id,
        l2Key: account.l2Key || '0x0',
        l2Vault: account.l2Vault || '',
        status: account.status,
        apiKeys: [],
        description: account.description,
      })
      createNotificationToast({
        notification: {
          type: 'ACCOUNT_CREATED',
        },
      })
      onClose()

      setIsCreatingFormOpen(false)
      setCreateInputValue('')
    } catch (error) {
      LOGGER.error('Error creating sub account: %o', error)
    }
  }

  const isFormValid = createInputValue.length > 0 && createInputValue.length <= 10
  const isTooLong = createInputValue.length > 10
  const accountWithSameNameExists = accounts.some(
    (it) => it.description === createInputValue,
  )
  const isInvalidInput = isTooLong || accountWithSameNameExists

  return (
    <styled.form
      css={{
        px: 's-16',
        pb: 's-16',
        pt: 's-8',
      }}
      onSubmit={onCreateAccount}
    >
      <Flex
        css={{
          gap: 's-2',
        }}
      >
        <Input
          name="name"
          aria-invalid={isInvalidInput}
          message={
            isTooLong
              ? formatMessage({
                  id: 'workspace.trade.widget.account.header.account-selector.create-account.input-error-message.too-long',
                  defaultMessage: 'Up to 10 symbols.',
                })
              : accountWithSameNameExists
                ? formatMessage({
                    id: 'workspace.trade.widget.account.header.account-selector.create-account.input-error-message.same-name-exists',
                    defaultMessage: 'Should be unique.',
                  })
                : undefined
          }
          label={formatMessage({
            id: 'workspace.trade.widget.account.header.account-selector.create-account.input-label',
            defaultMessage: 'Name',
          })}
          value={createInputValue}
          quickAction={
            <ActionIcon
              type="button"
              onClick={() => {
                setCreateInputValue('')
              }}
            >
              <SvgIcon.SvgIconClear />
            </ActionIcon>
          }
          onChange={(e) => setCreateInputValue(e.target.value)}
        />
        <CreateAccountCurrencySelect />
      </Flex>
      <Flex
        css={{
          gap: 's-16',
          mt: 's-24',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="button"
          visual="secondary-grey"
          disabled={isCreateSubAccountPending}
          onClick={() => {
            setIsCreatingFormOpen(false)
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.header.account-selector.create-account.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button
          type="submit"
          visual="primary-green"
          disabled={!isFormValid}
          loading={isCreateSubAccountPending}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.header.account-selector.create-account.create"
            defaultMessage="Create"
          />
        </Button>
      </Flex>
    </styled.form>
  )
}
