import { useCallback, useMemo } from 'react'

import { useIntl } from '@x10/lib-core/i18n'

export type Direction = 'ABOVE' | 'BELOW'
export type Stat = 'MARK' | 'LAST' | 'INDEX' | undefined
type Sign = 'PLUS' | 'MINUS'

export const useTpSlErrorMessage = () => {
  const { formatMessage } = useIntl()

  const getTriggerPriceRequired = useCallback(() => {
    return formatMessage({
      id: 'trade.component.order-tp-sl.validation.trigger-price-required',
      defaultMessage: 'Trigger price is required.',
    })
  }, [formatMessage])

  const getTriggerPriceMin = useCallback(
    (value: string | undefined) => {
      return formatMessage(
        {
          id: 'trade.component.order-tp-sl.validation.trigger-price-below-min',
          defaultMessage:
            'You cannot place an order with the trigger price below the {value}.',
        },
        {
          value,
        },
      )
    },
    [formatMessage],
  )

  const getTriggerPriceToEntryPrice = useCallback(
    (direction: Direction) => {
      return formatMessage(
        {
          id: 'trade.component.order-tp-sl.validation.trigger-price-to-order-entry-price',
          defaultMessage:
            'Trigger Price has to be { direction, select, ABOVE {above} other {below} } the Order Entry Price',
        },
        {
          direction,
        },
      )
    },
    [formatMessage],
  )

  const getTriggerPriceToStatPrice = useCallback(
    (direction: Direction, stat: Stat) => {
      return formatMessage(
        {
          id: 'trade.component.order-tp-sl.validation.trigger-price-to-stat',
          defaultMessage:
            'Trigger Price has to be { direction, select, ABOVE {above} other {below} } the current { stat, select, MARK {Mark} LAST {Last} other {Index} } Price',
        },
        {
          direction,
          stat,
        },
      )
    },
    [formatMessage],
  )

  const getOrderPriceRequired = useCallback(() => {
    return formatMessage({
      id: 'trade.component.order-tp-sl.validation.order-price-required',
      defaultMessage: 'Order price is required.',
    })
  }, [formatMessage])

  const getOrderPriceToTriggerPrice = useCallback(
    (value: string | undefined, direction: Direction, sign: Sign) => {
      return formatMessage(
        {
          id: 'trade.component.order-tp-sl.validation.order-price-to-trigger-price',
          defaultMessage:
            'Order Price has to be { direction, select, ABOVE {above} other {below} } Trigger Price * (1 { sign, select, PLUS {+} other {-} } {value})',
        },
        {
          direction,
          sign,
          value,
        },
      )
    },
    [formatMessage],
  )

  return useMemo(
    () => ({
      getTriggerPriceRequired,
      getTriggerPriceMin,
      getTriggerPriceToEntryPrice,
      getTriggerPriceToStatPrice,
      getOrderPriceRequired,
      getOrderPriceToTriggerPrice,
    }),
    [
      getTriggerPriceRequired,
      getTriggerPriceMin,
      getTriggerPriceToEntryPrice,
      getTriggerPriceToStatPrice,
      getOrderPriceRequired,
      getOrderPriceToTriggerPrice,
    ],
  )
}
