import { useCallback, type FC, type ReactNode } from 'react'

import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { HStack } from '@x10/lib-styled-system/jsx'
import { Link, ValueColored } from '@x10/lib-ui-kit/components'

import type { MarketStats as MarketStatsType } from '@src/domain/api/x10'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { NowrapText } from '@src/domain/core/ui/components/nowrap-text'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import { FundingRateTooltipPeriods } from '@src/domain/trade/ui/widgets/market-stats/hooks/use-get-item-props/funding-rate-tooltip-periods'

import { FundingRatePopoverContent } from '../../components/funding-rate-popover-content'
import { NextFundingRateCountdown } from '../../components/next-funding-rate-countdown'
import { OpenInterestPopoverContent } from '../../components/open-interest-popover-content'
import { ItemId } from '../../constants'

export const useGetItemProps = () => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })
  const formatPercent = useFormatPercent()

  return useCallback(
    (
      itemId: ItemId,
      data: MarketStatsType | undefined,
    ): {
      content: ReactNode
      tooltipIntlKey?: MessageDescriptor & {
        values?: Record<string, unknown>
        interactive?: boolean
      }
      popoverContent?: FC
    } => {
      switch (itemId) {
        case ItemId.MarkPrice:
          return {
            content: data && (
              <NowrapText>
                {formatMarketAsset({
                  amount: data.markPrice,
                  type: 'collateral',
                })}
              </NowrapText>
            ),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.mark-price.hint.title',
              defaultMessage:
                "The Mark Price is sourced from independent oracle price providers and represents the fair price of the perpetual contract. It is used to calculate positions' unrealized PnL, account Equity, Margin Ratio, and Balance Available for Trading. Therefore, the liquidity of accounts is also determined based on the Mark Price.",
            },
          }
        case ItemId.IndexPrice:
          return {
            content: data && (
              <NowrapText>
                {formatMarketAsset({
                  amount: data.indexPrice,
                  type: 'collateral',
                })}
              </NowrapText>
            ),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.index-price.hint.title',
              defaultMessage:
                'The Index Price is sourced from independent oracle price providers and represents the fair price of the underlying spot asset. It is used for the calculation of Funding Rate.',
            },
          }
        case ItemId.FundingRate:
          return {
            content: data && (
              <HStack>
                <ValueColored>
                  {formatPercent(data.fundingRate, {
                    precision: PercentPrecision.FundingRate,
                  })}
                </ValueColored>
                <NextFundingRateCountdown
                  nextFundingRateTimestamp={data.nextFundingRate.toNumber()}
                />
              </HStack>
            ),
            tooltipIntlKey: {
              interactive: true,
              id: 'workspace.trade.widget.market-stats.item.funding-rate.hint.title',
              defaultMessage:
                '<periods></periods>The funding rate is used to calculate funding payments exchanged between long and short positions every hour. If the funding rate is positive, long position holders pay short position holders. If negative, short position holders pay long position holders.\n\nFunding Payment = Position Value * (-Funding Rate).\n\nFor more information, please refer to the <a>documentation</a>.',
              values: {
                periods: () =>
                  data && <FundingRateTooltipPeriods rate={data?.fundingRate} />,
                a: (chunks: ReactNode) => {
                  return (
                    <Link
                      href={asExternalRoute(
                        documentationRoute({}).trading({}).fundingPayments({}).$,
                      )}
                      external
                    >
                      {chunks}
                    </Link>
                  )
                },
              },
            },
            popoverContent: FundingRatePopoverContent,
          }
        case ItemId.Change24h:
          return {
            content: data && (
              <HStack>
                <ValueColored>
                  {formatMarketAsset({
                    amount: data.dailyPriceChange,
                    type: 'collateral',
                  })}
                </ValueColored>
                <ValueColored>
                  {formatPercent(data.dailyPriceChangePercentage)}
                </ValueColored>
              </HStack>
            ),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.change-24h.hint.title',
              defaultMessage:
                'Absolute and percentage change in the last traded price of a market compared to its price 24 hours ago.',
            },
          }
        case ItemId.OpenInterestBase:
          return {
            content:
              data &&
              formatMarketAsset(
                {
                  amount: data.openInterestBase,
                  type: 'synthetic',
                },
                {
                  precision: AssetPrecision.VolumeStat,
                },
              ),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.open-interest-base.hint.title',
              defaultMessage:
                'Number of all outstanding contracts on the market, including both long and short positions.',
            },
            popoverContent: OpenInterestPopoverContent,
          }
        case ItemId.OpenInterestCollateral:
          return {
            content:
              data &&
              formatMarketAsset(
                {
                  amount: data.openInterest,
                  type: 'collateral',
                },
                {
                  precision: AssetPrecision.VolumeStat,
                },
              ),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.open-interest-collateral.hint.title',
              defaultMessage:
                'Value of all outstanding contracts on the market, including both long and short positions.',
            },
            popoverContent: OpenInterestPopoverContent,
          }
        case ItemId.Volume24hBase:
          return {
            content:
              data &&
              formatMarketAsset(
                {
                  amount: data.dailyVolumeBase,
                  type: 'synthetic',
                },
                {
                  precision: AssetPrecision.VolumeStat,
                },
              ),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.volume-24h-base.hint.title',
              defaultMessage:
                'Total trading volume in the base currency over the last 24 hours.',
            },
          }
        case ItemId.Volume24hCollateral:
          return {
            content:
              data &&
              formatMarketAsset(
                {
                  amount: data.dailyVolume,
                  type: 'collateral',
                },
                {
                  precision: AssetPrecision.VolumeStat,
                },
              ),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.volume-24h-collateral.hint.title',
              defaultMessage: 'Total trading value in USDC over the last 24 hours.',
            },
          }
        case ItemId.High24h:
          return {
            content:
              data &&
              formatMarketAsset({
                amount: data.dailyHigh,
                type: 'collateral',
              }),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.high-24h.hint.title',
              defaultMessage:
                'The highest traded price on the market within the last 24 hours.',
            },
          }
        case ItemId.Low24h:
          return {
            content:
              data &&
              formatMarketAsset({
                amount: data.dailyLow,
                type: 'collateral',
              }),
            tooltipIntlKey: {
              id: 'workspace.trade.widget.market-stats.item.low-24h.hint.title',
              defaultMessage:
                'The lowest traded price on the market within the last 24 hours.',
            },
          }
        default:
          throw notReachable(itemId)
      }
    },
    [formatMarketAsset, formatPercent],
  )
}
