import { useEffect, useRef, useState, type FC } from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import { getAssetUrl, invariant } from '@x10/lib-core/utils'
import { Box, Center, Flex, type BoxProps } from '@x10/lib-styled-system/jsx'
import { Spinner } from '@x10/lib-ui-kit/components'

import { TRADING_VIEW_VERSION } from '@src/domain/core/config/static'
import { asyncScriptLoader } from '@src/domain/core/utils/async-script-loader'
import { waitForGlobalProperty } from '@src/domain/core/utils/wait-for'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { type LanguageCode } from '@src/types/charting-library'

import { useDatafeed } from './hooks/use-datafeed'
import { useThemeAndOverrides } from './hooks/use-theme-and-overrides'
import { useTradingViewChartMachineStore } from './hooks/use-trading-view-chart'
import { makeTradingViewTicker } from './utils/data-feed/utils'
import { TradingViewChartApi } from './utils/trading-view-chart-api'

const loadTradingViewScript = (): Promise<void> =>
  asyncScriptLoader(
    getAssetUrl({
      type: 'tradingview',
      name: `charting_library_${TRADING_VIEW_VERSION}/charting_library.js`,
    }),
  ).then(() => waitForGlobalProperty('TradingView'))

type TradingViewChartProps = BoxProps & {
  hideLeftToolbar?: boolean
  onApiReady: (api: TradingViewChartApi) => void
}

/**
 * https://www.tradingview.com/charting-library-docs/latest/getting_started/
 */
export const TradingViewChart: FC<TradingViewChartProps> = ({
  hideLeftToolbar,
  onApiReady,
  ...restProps
}) => {
  const { locale } = useIntl()
  const market = useSelectedMarket()

  const priceSource = useTradingViewChartMachineStore((state) => state.priceSource)
  const interval = useTradingViewChartMachineStore((state) => state.interval)

  const [isScriptReady, setScriptReady] = useState(false)
  const [isChartReady, setChartReady] = useState(false)
  const apiRef = useRef<TradingViewChartApi>()
  const containerRef = useRef<HTMLDivElement>(null)

  const datafeed = useDatafeed()
  const { overrides } = useThemeAndOverrides()

  useEffect(() => {
    loadTradingViewScript().then(() => {
      setScriptReady(true)
    })
  }, [])

  useEffect(() => {
    if (!isScriptReady || apiRef.current) {
      return
    }

    invariant(containerRef.current, 'containerRef')

    const symbol = makeTradingViewTicker(
      market.name,
      'trades',
      market.assets.collateral.precision,
      market.assets.synthetic.precision,
    )
    const api = TradingViewChartApi.create({
      container: containerRef.current,
      datafeed,
      overrides,
      // For languages supported see:
      // https://www.tradingview.com/charting-library-docs/latest/core_concepts/Localization
      locale: locale as LanguageCode,
      symbol,
      interval,
      hideLeftToolbar,
    })

    api.onChartReady(() => {
      setChartReady(true)
    })

    apiRef.current = api
    onApiReady(api)
  }, [
    datafeed,
    hideLeftToolbar,
    isScriptReady,
    locale,
    interval,
    market.assets.collateral.precision,
    market.assets.synthetic.precision,
    market.name,
    overrides,
    onApiReady,
  ])

  useEffect(() => {
    if (!apiRef.current) {
      return
    }

    apiRef.current.setSymbol(
      makeTradingViewTicker(
        market.name,
        priceSource,
        market.assets.collateral.precision,
        market.assets.synthetic.precision,
      ),
    )
  }, [
    market.name,
    market.assets.collateral.precision,
    market.assets.synthetic.precision,
    priceSource,
  ])

  return (
    <Flex css={{ flex: 1, position: 'relative' }}>
      <Center
        css={{
          w: '100%',
          h: '100%',
          position: 'absolute',
          display: isChartReady ? 'none' : undefined,
        }}
      >
        <Spinner />
      </Center>

      <Box
        ref={containerRef}
        css={{ flex: 1, visibility: !isChartReady ? 'hidden' : undefined }}
        {...restProps}
      />
    </Flex>
  )
}
