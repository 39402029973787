import findLast from 'lodash/findLast'

import { checkRequired, type Decimal } from '@x10/lib-core/utils'

import type { RiskFactorConfig } from '@src/domain/api/x10'

import { calcInitialMarginRate } from './calc-initial-margin-rate'

export const getRiskFactorForLeverage = ({
  leverage,
  riskFactorConfig,
}: {
  leverage: Decimal
  riskFactorConfig: RiskFactorConfig
}) => {
  const initialMargin = calcInitialMarginRate(leverage)
  const minRiskFactor = checkRequired(riskFactorConfig[0], 'minRiskFactor')

  return (
    findLast(riskFactorConfig, ({ riskFactor }) => riskFactor.lte(initialMargin)) ??
    minRiskFactor
  )
}
