import { FormattedMessage } from '@x10/lib-core/i18n'
import { Decimal } from '@x10/lib-core/utils'
import { Box, styled } from '@x10/lib-styled-system/jsx'

import type { MarketName } from '@src/domain/api/x10/common'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import type { TpSlEntrySide } from '@src/domain/core/types/common'
import type { OrderTpSlType } from '@src/domain/starkex/stark-perpetual-order'
import { getOppositeOrderSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'
import { usePositionForCalc } from '@src/domain/trade/hooks/use-position-for-calc'
import type { OrderTriggerState } from '@src/domain/trade/types/common'
import { calcTpSlPnl } from '@src/domain/trade/utils/calc/calc-tp-sl-pnl'
import { getSignedSize } from '@src/domain/trade/utils/calc/get-signed-size'

import { useNewOrderStore } from '../../ui/widgets/order-form/store/new-order'
import type { TpSlDialogInitiator } from './types'

type PnlHintProps = {
  initiator: TpSlDialogInitiator
  applicableTo: OrderTpSlType
  entrySide: TpSlEntrySide
  marketName: MarketName
  value: OrderTriggerState
}

export const PnlNote = ({
  initiator,
  applicableTo,
  entrySide,
  marketName,
  value,
}: PnlHintProps) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true, marketName })

  const buyAmountOfSynthetic = useNewOrderStore((state) => state.buy.amountOfSynthetic)
  const buyPrice = useNewOrderStore((state) => state.buy.price)
  const sellAmountOfSynthetic = useNewOrderStore((state) => state.sell.amountOfSynthetic)
  const sellPrice = useNewOrderStore((state) => state.sell.price)

  const position = usePositionForCalc(marketName)

  const positionPrice = position?.openPrice ?? Decimal.ZERO
  const positionSize = getSignedSize(position)
  const newOrderPrice = (entrySide === 'LONG' ? buyPrice : sellPrice) ?? Decimal.ZERO
  const newOrderSize = getSignedSize({
    size:
      (entrySide === 'LONG' ? buyAmountOfSynthetic : sellAmountOfSynthetic) ??
      Decimal.ZERO,
    side: entrySide,
  })

  const entryPrice = initiator === 'ORDER_FORM' ? newOrderPrice : positionPrice
  const size = initiator === 'ORDER_FORM' ? newOrderSize : positionSize

  const tpSlOrderSide = getOppositeOrderSide(toOrderSide(entrySide))
  const tpSlTriggerPrice = value.triggerPrice ?? Decimal.ZERO
  const tpSlOrderPrice = value.priceType === 'MARKET' ? value.triggerPrice : value.price

  const pnl = tpSlOrderPrice
    ? calcTpSlPnl({ entryPrice, size, tpSlOrderPrice, tpSlOrderSide })
    : Decimal.ZERO

  return (
    <Box py="s-8" textStyle="small" color="token.white-50">
      <FormattedMessage
        id="trade.component.order-tp-sl.note.order-and-pnl.title"
        defaultMessage="When { triggerPriceType, select, MARK {mark} LAST {last} other {index} } price reaches <highlight>{triggerPrice}</highlight>, a { tpSlOrderSide, select, BUY {long} other {short} } { priceType, select, LIMIT {limit} other {market} } order { priceType, select, LIMIT {at <highlight>{tpSlOrderPrice}</highlight>} other {} } for { applicableTo, select, POSITION {the position size} other {{orderSize}} } will be placed. Your estimated PnL will be <highlight>{pnl}</highlight>."
        values={{
          triggerPriceType: value.triggerPriceType,
          triggerPrice: formatMarketAsset({
            amount: tpSlTriggerPrice,
            type: 'collateral',
          }),
          priceType: value.priceType,
          applicableTo,
          tpSlOrderSide,
          tpSlOrderPrice: formatMarketAsset({
            amount: tpSlOrderPrice,
            type: 'collateral',
          }),
          orderSize: formatMarketAsset({ amount: size.abs(), type: 'synthetic' }),
          pnl: formatMarketAsset(
            { amount: pnl, type: 'collateral' },
            { precision: AssetPrecision.UserOrTradeMetric },
          ),

          highlight: (chunks) => {
            return <styled.span color="token.white">{chunks}</styled.span>
          },
        }}
      />
    </Box>
  )
}
