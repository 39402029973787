import type { FC } from 'react'

import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { Button, HoverCard, Portal } from '@x10/lib-ui-kit/components'
import { pct } from '@x10/lib-ui-kit/utils'

type LimitCloseFormQtySelectorProps = {
  open?: boolean
  onSelect: (value: number) => void
}

export const LimitCloseFormQtySelector: FC<LimitCloseFormQtySelectorProps> = ({
  open,
  onSelect,
}) => {
  return (
    <HoverCard.Root
      open={open}
      animated={false}
      lazyMount
      positioning={{ placement: 'bottom-end', offset: { mainAxis: 8 } }}
    >
      <HoverCard.Trigger asChild>
        <Box css={{ position: 'absolute', right: 0, bottom: 0, top: 0, w: 1 }} />
      </HoverCard.Trigger>

      <Portal>
        <HoverCard.Positioner>
          <HoverCard.Content>
            <HStack>
              <Button visual="secondary-grey" size="small" onClick={() => onSelect(0.25)}>
                {pct(25)}
              </Button>
              <Button visual="secondary-grey" size="small" onClick={() => onSelect(0.5)}>
                {pct(50)}
              </Button>
              <Button visual="secondary-grey" size="small" onClick={() => onSelect(0.75)}>
                {pct(75)}
              </Button>
              <Button
                visual="secondary-grey"
                size="small"
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  onSelect(1)
                }}
              >
                {pct(100)}
              </Button>
            </HStack>
          </HoverCard.Content>
        </HoverCard.Positioner>
      </Portal>
    </HoverCard.Root>
  )
}
