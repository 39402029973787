import { Suspense, type FC } from 'react'

import { Center } from '@x10/lib-styled-system/jsx'
import { Spinner } from '@x10/lib-ui-kit/components'

import { type TableMarket } from '@src/domain/core/types/common'
import { MarketsTable } from '@src/domain/core/ui/components/markets-table'
import { useSelectedMarket } from '@src/domain/trade/store/market'

const MarketsTableFallback = () => (
  <Center h="6.25rem">
    <Spinner />
  </Center>
)

type Props = {
  selectedCategory: string
  search: string
  onRowClick: (row: TableMarket) => void
}

export const TradeMarketsTable: FC<Props> = ({
  selectedCategory,
  search,
  onRowClick,
}) => {
  const selectedMarket = useSelectedMarket()

  return (
    <Suspense fallback={<MarketsTableFallback />}>
      <MarketsTable
        variant="trade-popover"
        selectedCategory={selectedCategory}
        selectedMarket={selectedMarket}
        containerProps={{
          css: {
            w: '100%',
            mt: 's-16',
            flex: 1,
            h: 'auto',
          },
        }}
        search={search}
        onRowClick={onRowClick}
      />
    </Suspense>
  )
}
