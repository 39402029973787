import { invariant } from '@x10/lib-core/utils'

import type { Computed, Context, ErrorMessage, Rule, Value } from '../types'

/**
 * [65:Validation]
 * Order Cost ≤ Available Balance
 * (For all orders apart from Market Reduce-Only)
 * (Validation Not applied to TP/SL and Reduce-Only Orders)
 */
export const orderCost: Rule<
  Pick<Value, 'orderCost'>,
  Pick<Context, 'balance' | 'reduceOnly'>,
  Pick<Computed, 'orderSide'>,
  Pick<ErrorMessage, 'getOrderCost'>
> = (value, ctx, computed, _alerts, errors, errorMessage) => {
  if (!value.orderCost || ctx.reduceOnly) {
    return
  }

  invariant(ctx.balance, '`balance` is required')

  const orderCost =
    computed.orderSide === 'BUY' ? value.orderCost.buy : value.orderCost.sell

  if (orderCost.gt(ctx.balance.availableForTrade)) {
    errors.form = errorMessage.getOrderCost()
  }
}
