import { invariant } from '@x10/lib-core/utils'

import type { Computed, Context, ErrorMessage, Rule } from '../types'

export const orderSizeDivisible: Rule<
  unknown,
  Pick<Context, 'market'>,
  Pick<Computed, 'orderSize'>,
  Pick<ErrorMessage, 'getAmountDivisible'>
> = (_value, ctx, computed, _alerts, errors, errorMessage) => {
  if (errors.orderSize) {
    return
  }

  invariant(computed.orderSize, '`orderSize` is required')

  if (
    !computed.orderSize
      .minus(ctx.market.tradingConfig.minOrderSize)
      .mod(ctx.market.tradingConfig.minOrderSizeChange)
      .lte(0)
  ) {
    errors.orderSize = errorMessage.getAmountDivisible(
      ctx.market.tradingConfig.minOrderSizeChange,
    )
  }
}
