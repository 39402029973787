import { type Decimal } from '@x10/lib-core/utils'

import type { OrderSide } from '@src/domain/api/x10/common'
import { getSignedSize } from '@src/domain/trade/utils/calc/get-signed-size'

type CalcTpSlPnlArgs = {
  entryPrice: Decimal
  size: Decimal
  /**
   * TP/SL trigger (if TP/SL price type is `MARKET`) or order price (if TP/SL price type is `LIMIT`)
   */
  tpSlOrderPrice: Decimal
  tpSlOrderSide: OrderSide
}

export const calcTpSlPnl = ({
  entryPrice,
  size,
  tpSlOrderPrice,
  tpSlOrderSide,
}: CalcTpSlPnlArgs) => {
  const result = tpSlOrderPrice
    .minus(entryPrice)
    .times(getSignedSize({ qty: size, side: tpSlOrderSide }))

  if (tpSlOrderSide === 'SELL') {
    return result.negated()
  }

  return result
}
