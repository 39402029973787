import { invariant } from '@x10/lib-core/utils'

export const asyncScriptLoader = (url: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const scriptTag = document.getElementsByTagName('script')[0]
    invariant(scriptTag, 'scriptTag is null')
    const tag = document.createElement('script')

    tag.src = url
    tag.async = true
    tag.type = 'text/javascript'
    tag.onload = () => resolve()
    tag.onerror = () => reject()

    scriptTag.parentNode && scriptTag.parentNode.insertBefore(tag, scriptTag)
  })
