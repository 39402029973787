import { useMemo } from 'react'

import { Decimal } from '@x10/lib-core/utils'

import type { CachedMarket } from '@src/domain/api/x10'
import { useOrdersForCalc } from '@src/domain/trade/hooks/use-orders-for-calc'
import { usePositionForCalc } from '@src/domain/trade/hooks/use-position-for-calc'
import { calcInitialMarginRate } from '@src/domain/trade/utils/calc/calc-initial-margin-rate'
import { calcMaxOpenPositionPlusOrdersValue } from '@src/domain/trade/utils/calc/calc-max-open-position-plus-orders-value'
import { calcMaxPositionValueForLeverageBasedOnRiskFactor } from '@src/domain/trade/utils/calc/calc-max-position-value-for-leverage-based-on-risk-factor'

export const useFormData = (
  market: CachedMarket,
  leverage: Decimal,
  newLeverage: Decimal,
) => {
  const position = usePositionForCalc(market.name)
  const orders = useOrdersForCalc(market.name)

  const maxPositionForNewLeverageBasedOnRiskFactor =
    calcMaxPositionValueForLeverageBasedOnRiskFactor({
      leverage: newLeverage,
      riskFactorConfig: market.tradingConfig.riskFactorConfig,
    })
  const maxAllowedPositionValue = Decimal.min(
    market.tradingConfig.maxPositionValue,
    maxPositionForNewLeverageBasedOnRiskFactor,
  )

  const maxOpenPositionPlusOrdersValue = calcMaxOpenPositionPlusOrdersValue(
    position,
    orders,
  )
  const requiredInitialMarginCurrent = maxOpenPositionPlusOrdersValue.times(
    calcInitialMarginRate(leverage),
  )
  const requiredInitialMarginNew = maxOpenPositionPlusOrdersValue.times(
    calcInitialMarginRate(newLeverage),
  )

  return useMemo(
    () => ({
      maxOpenPositionPlusOrdersValue,
      maxAllowedPositionValue,
      requiredInitialMarginCurrent,
      requiredInitialMarginNew,
    }),
    [
      maxOpenPositionPlusOrdersValue,
      maxAllowedPositionValue,
      requiredInitialMarginCurrent,
      requiredInitialMarginNew,
    ],
  )
}
