import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import type { OrderTriggerPriceType } from '@src/domain/starkex/stark-perpetual-order'

export const getOrderTriggerPriceIntlKey = (
  value: OrderTriggerPriceType | 'UNKNOWN',
): MessageDescriptor => {
  switch (value) {
    case 'LAST':
      return {
        id: 'common.price.last',
        defaultMessage: 'Last',
      }
    case 'MARK':
      return {
        id: 'common.price.mark',
        defaultMessage: 'Mark',
      }
    case 'INDEX':
      return {
        id: 'common.price.index',
        defaultMessage: 'Index',
      }
    case 'UNKNOWN':
      return {
        id: 'common.price.unknown',
        defaultMessage: 'Unknown',
      }
    default:
      throw notReachable(value)
  }
}
