import { TradeWidgetActions } from '@src/domain/trade/ui/components/trade-widget/trade-widget-actions'

import { TradeWidgetContentFixed } from './trade-widget-content-fixed'
import { TradeWidgetContentScrollable } from './trade-widget-content-scrollable'
import { TradeWidgetHeader } from './trade-widget-header'
import { TradeWidgetInnerRoot } from './trade-widget-inner-root'
import { TradeWidgetRootProviders } from './trade-widget-root'

export { TradeWidgetContent } from './trade-widget-content'

export const TradeWidget = {
  Root: TradeWidgetRootProviders,
  InnerRoot: TradeWidgetInnerRoot,
  Header: TradeWidgetHeader,
  ContentFixed: TradeWidgetContentFixed,
  ContentScrollable: TradeWidgetContentScrollable,
  Actions: TradeWidgetActions,
}
