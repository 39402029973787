import { useCallback, useEffect, useMemo, useState, type FC } from 'react'
import { useAccount } from 'wagmi'

import { CRYPTO_CURRENCY_INFO } from '@x10/lib-core/config'
import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Decimal, invariant, Long } from '@x10/lib-core/utils'
import { Box, HStack, VStack } from '@x10/lib-styled-system/jsx'
import {
  Button,
  Callout,
  Cell,
  Dialog,
  Input,
  Link,
  Portal,
  SegmentGroup,
  SimpleSelect,
  Skeleton,
  SvgIcon,
  Tooltip,
  ValueChange,
} from '@x10/lib-ui-kit/components'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'
import { useBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import {
  useWithdraw,
  useWithdrawalFee,
  useWithdrawalRemainingLimit,
  useWithdrawalTarget,
} from '@src/domain/api/hooks/account/use-withdraw'
import { useAccountStarkPrivateKey } from '@src/domain/auth/hooks/use-account-stark-private-key'
import { useGuardedAccountId } from '@src/domain/auth/hooks/use-auth'
import {
  openWithdrawalRejectedDialog,
  useIsAccountBlocked,
} from '@src/domain/auth/hooks/use-client-status'
import { maskAddress } from '@src/domain/auth/utils/mask-address'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { ApiErrorCode } from '@src/domain/core/errors/base'
import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'
import { parseError } from '@src/domain/core/errors/parse-error'
import { useFormatUsd } from '@src/domain/core/hooks/use-format-usd'
import { createNotificationToast } from '@src/domain/core/ui/components/notification'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import { StarkFastWithdrawTransfer } from '@src/domain/starkex/stark-fast-withdraw-transfer'
import { StarkWithdrawal } from '@src/domain/starkex/stark-witdrawal'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { WithdrawMoneyInputWithSlider } from './withdraw-money-input-with-slider'

const InputAndSliderSkeleton = () => (
  <VStack
    css={{
      w: '100%',
      gap: 's-16',
    }}
  >
    <Skeleton
      css={{
        borderRadius: 'r-16',
        w: '100%',
        h: '3.5rem',
      }}
    />
    <Box
      css={{
        w: '100%',
        pb: '1.375rem',
      }}
    >
      <Skeleton
        css={{
          h: '1.5rem',
        }}
      />
    </Box>
  </VStack>
)

const useErrorMessages = () => {
  const { formatMessage } = useIntl()

  return useMemo(() => {
    return {
      insufficientFundsError: formatMessage({
        id: 'domain.trade.dialog.withdraw.input.error.insufficient-funds',
        defaultMessage:
          'Your available balance is not sufficient to complete the withdrawal.',
      }),
      fastWithdrawalLimitOverflowError: formatMessage({
        id: 'domain.trade.dialog.withdraw.input.error.fast-withdrawal-limit-overflow',
        defaultMessage:
          'The requested withdrawal amount exceeds the 24-hour limit for fast withdrawals of $50,000. Please consider reducing the withdrawal amount or proceed with slow withdrawals, which do not have withdrawal limits.',
      }),
    }
  }, [formatMessage])
}

type WithdrawMode = 'FAST' | 'SLOW'

const getAvailableForWithdrawal = (
  withdrawMode: WithdrawMode,
  availableForWithdrawal: Decimal | undefined,
  feeResult: ReturnType<typeof useWithdrawalFee>,
) => {
  if (!availableForWithdrawal) {
    return Decimal.ZERO
  }

  switch (withdrawMode) {
    case 'FAST':
      return feeResult.isOk()
        ? availableForWithdrawal.minus(feeResult.value).maxZero()
        : Decimal.ZERO
    case 'SLOW':
      return availableForWithdrawal
  }
}

export const WithdrawDialog: FC = () => {
  const { insufficientFundsError, fastWithdrawalLimitOverflowError } = useErrorMessages()
  const [withdrawAmount, setWithdrawAmount] = useState('0')
  const { formatMessage } = useIntl()
  const accounts = useAccounts()
  const accountId = useGuardedAccountId()
  const { address } = useAccount()
  const withdrawal = useWithdraw()
  const market = useSelectedMarket()
  const remainingLimit = useWithdrawalRemainingLimit({ asset: 'USD' })
  const fastWithdrawalTarget = useWithdrawalTarget()
  const feeResult = useWithdrawalFee()
  const isAccountBlocked = useIsAccountBlocked()
  const isFastWithdrawalLimitDepleted = remainingLimit.eq(0)
  const [withdrawMode, setWithdrawMode] = useState<WithdrawMode>(
    (isAccountBlocked && feeResult.isErr()) || isFastWithdrawalLimitDepleted
      ? 'SLOW'
      : 'FAST',
  )
  const [gasSurgeError, setGasSurgeError] = useState(false)
  const formatUsd = useFormatUsd({ usdcSymbol: true })
  const accountOptions = accounts.map((acc) => {
    return {
      label: acc.description,
      value: acc.accountId.toString(),
    }
  })
  const [fromAccountId, setFromAccountIdId] = useState(accountId)
  const { isLoading, data } = useBalance({ accountId: fromAccountId })
  const availableForWithdrawal = getAvailableForWithdrawal(
    withdrawMode,
    data?.availableForWithdrawal,
    feeResult,
  )

  // reset all the state on account and mode change
  useEffect(() => {
    setWithdrawAmount('0')
  }, [fromAccountId, withdrawMode])

  const maxValue =
    withdrawMode === 'FAST'
      ? remainingLimit.gt(availableForWithdrawal)
        ? availableForWithdrawal
        : remainingLimit
      : availableForWithdrawal

  const isNotGreaterThanAvailableForWithdrawal =
    availableForWithdrawal && Decimal(withdrawAmount).lte(availableForWithdrawal)

  const isNotGreaterFastWithdrawalLimit =
    withdrawMode === 'FAST' ? Decimal(withdrawAmount).lte(remainingLimit) : true

  // usually unavailable due to insufficient money in the pool, or in case fast withdrawals are disabled
  const isWithdrawalFeeIsAvailable = !feeResult.isErr()

  const isFormValid =
    ((withdrawMode === 'FAST' && isWithdrawalFeeIsAvailable) ||
      withdrawMode === 'SLOW') &&
    Decimal(withdrawAmount).gt(Decimal.ZERO) &&
    isNotGreaterThanAvailableForWithdrawal &&
    isNotGreaterFastWithdrawalLimit

  const [isValidationsActive, setValidationsActive] = useState(false)

  const isInsufficientFundsErrorShown =
    isValidationsActive && !isNotGreaterThanAvailableForWithdrawal
  const isFastWithdrawalLimitOverflowErrorShown =
    isValidationsActive && !isNotGreaterFastWithdrawalLimit
  const isAmountZero = Decimal(withdrawAmount).eq(Decimal.ZERO)

  const { getStarkPrivateKey } = useAccountStarkPrivateKey()
  const [withdrawalPending, setWithdrawalPending] = useState(false)

  const handleWithdraw = useCallback(
    ({ onSuccess }: { onSuccess: VoidFunction }) =>
      async () => {
        setWithdrawalPending(true)
        try {
          const starkPrivateKey = await getStarkPrivateKey()
          if (starkPrivateKey.isErr()) {
            return // user rejected signing
          }
          if (withdrawMode === 'SLOW') {
            invariant(accountId, 'AccountId should be defined')
            invariant(address, 'EthAddress should be defined')
            await withdrawal.mutateAsync({
              type: 'SLOW_SELF',
              ...StarkWithdrawal.create({
                accountId: Long(fromAccountId),
                amount: Decimal(withdrawAmount),
                asset: 'USD',
                ctx: {
                  ethAddress: address,
                  accounts,
                  l2Config: market.l2Config,
                  starkPrivateKey: starkPrivateKey.value,
                },
              }).toJSON(),
            })
          } else {
            invariant(isWithdrawalFeeIsAvailable, 'Fee should be available')
            await withdrawal
              .mutateAsync({
                type: 'FAST',
                ...StarkFastWithdrawTransfer.create({
                  fromAccountId: Long(fromAccountId),
                  toAccountL2Key: fastWithdrawalTarget.l2Key,
                  toAccountL2Vault: fastWithdrawalTarget.l2Vault,
                  amount: Decimal(withdrawAmount),
                  transferFee: Decimal(feeResult.value),
                  asset: 'USD',
                  ctx: {
                    accounts,
                    l2Config: market.l2Config,
                    starkPrivateKey: starkPrivateKey.value,
                  },
                }).toJSON(),
              })
              .catch((e) => {
                const error = parseError(e)
                if (error.code === ApiErrorCode.AccountBlocked) {
                  openWithdrawalRejectedDialog()
                  return
                }
                if (error.code === ApiErrorCode.WithdrawalTransferFeeIsTooLow) {
                  setGasSurgeError(true)
                  return
                }
                throw error
              })
          }
          const accountDescription = accounts.find((it) =>
            it.accountId.eq(fromAccountId),
          )?.description
          invariant(accountDescription, 'Account description should be defined')
          createNotificationToast({
            notification: {
              type: 'WITHDRAW',
              state: 'created',
              amount: Decimal(withdrawAmount),
              duration:
                withdrawMode === 'FAST'
                  ? formatMessage({
                      id: 'domain.trade.dialog.withdraw.cell.arrival-eta.fast.value.title',
                      defaultMessage: '5 minutes',
                    })
                  : formatMessage({
                      id: 'domain.trade.dialog.withdraw.cell.arrival-eta.slow.value.title',
                      defaultMessage: '12 hours',
                    }),
            },
          })
          onSuccess()
        } catch (error) {
          captureExceptionWithSentry(error)
        } finally {
          setWithdrawalPending(false)
        }
      },
    [
      getStarkPrivateKey,
      withdrawMode,
      accounts,
      withdrawAmount,
      formatMessage,
      accountId,
      address,
      withdrawal,
      fromAccountId,
      market.l2Config,
      isWithdrawalFeeIsAvailable,
      fastWithdrawalTarget.l2Key,
      fastWithdrawalTarget.l2Vault,
      feeResult,
    ],
  )
  return (
    <>
      <VStack
        css={{
          mt: 's-16',
          gap: 's-16',
          alignItems: 'start',
        }}
      >
        <SimpleSelect.Root
          css={{
            width: '100%',
          }}
          value={fromAccountId}
          options={accountOptions}
          onValueChange={(value) => {
            setFromAccountIdId(value)
          }}
        >
          <SimpleSelect.ControlWithLabel label="From X10 Account" />

          <SimpleSelect.Content>
            {accountOptions.map((item) => (
              <SimpleSelect.Item key={item.value} item={item} />
            ))}
          </SimpleSelect.Content>
        </SimpleSelect.Root>
        <Input label="To wallet" disabled placeholder={maskAddress(address)} />
        {isLoading || !availableForWithdrawal ? (
          <InputAndSliderSkeleton />
        ) : (
          <WithdrawMoneyInputWithSlider
            key={fromAccountId + withdrawMode} // reset input's internal state on account change or mode change
            balance={availableForWithdrawal}
            maxValue={maxValue}
            currencyInfo={CRYPTO_CURRENCY_INFO['USDC']}
            aria-invalid={
              isInsufficientFundsErrorShown || isFastWithdrawalLimitOverflowErrorShown
            }
            message={isInsufficientFundsErrorShown ? insufficientFundsError : undefined}
            onBlur={() => setValidationsActive(true)}
            onValueChange={(value) => setWithdrawAmount(value.toString())}
          />
        )}

        <Cell.Group textStyle="small">
          <Cell.Item>
            <Tooltip.Root
              positioning={{ placement: 'top-start' }}
              openDelay={250}
              closeDelay={50}
              interactive
            >
              <Tooltip.Trigger asChild>
                <Cell.Title>
                  <FormattedMessage
                    id="domain.trade.dialog.deposit.cell.available-to-withdraw.title"
                    defaultMessage="Available to Withdraw"
                  />
                </Cell.Title>
              </Tooltip.Trigger>

              <Portal>
                <Tooltip.Positioner>
                  <Tooltip.Content
                    css={{
                      whiteSpace: 'break-spaces',
                    }}
                  >
                    <FormattedMessage
                      id="domain.trade.dialog.withdraw.cell.available-to-withdraw.hint.title"
                      defaultMessage="Available Balance for Withdrawals = Wallet Balance + min(0,Unrealised PnL) - Initial Margin Requirement.\n\nFor more information, please refer to the <a>documentation</a>."
                      values={{
                        a: (chunks) => (
                          <Link
                            href={asExternalRoute(
                              documentationRoute({}).trading({}).accountsAndMargin({}).$,
                            )}
                            external
                          >
                            {chunks}
                          </Link>
                        ),
                      }}
                    />
                  </Tooltip.Content>
                </Tooltip.Positioner>
              </Portal>
            </Tooltip.Root>
            <Cell.Value
              css={{
                textStyle: 'small',
                fontWeight: 'fw-500',
              }}
            >
              <HStack css={{ gap: 0 }}>
                {availableForWithdrawal ? (
                  <>
                    <ValueChange.Root>
                      <ValueChange.Item>
                        {formatUsd(availableForWithdrawal, { showSymbol: isAmountZero })}
                      </ValueChange.Item>

                      {!isAmountZero ? (
                        <ValueChange.Item>
                          {availableForWithdrawal
                            .minus(Decimal(withdrawAmount))
                            .gte(Decimal.ZERO)
                            ? formatUsd(
                                availableForWithdrawal.minus(Decimal(withdrawAmount)),
                              )
                            : EMPTY_CELL_VALUE}
                        </ValueChange.Item>
                      ) : null}
                    </ValueChange.Root>
                  </>
                ) : (
                  <Box
                    css={{
                      py: 's-4',
                    }}
                  >
                    <Skeleton size="small" />
                  </Box>
                )}
              </HStack>
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <Cell.Title>
                  <FormattedMessage
                    id="domain.trade.dialog.withdraw.cell.you-receive.title"
                    defaultMessage="You Receive"
                  />
                </Cell.Title>
              </Tooltip.Trigger>
              <Portal>
                <Tooltip.Positioner>
                  <Tooltip.Content>
                    <FormattedMessage
                      id="domain.trade.dialog.withdraw.cell.you-receive.hint.title"
                      defaultMessage="The amount that you will receive on your L1 wallet."
                    />
                  </Tooltip.Content>
                </Tooltip.Positioner>
              </Portal>
            </Tooltip.Root>
            <Cell.Value>
              {isFormValid ? formatUsd(Decimal(withdrawAmount)) : EMPTY_CELL_VALUE}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <Cell.Title>
                  <FormattedMessage
                    id="domain.trade.dialog.withdraw.cell.fee.title"
                    defaultMessage="Withdrawal Fee"
                  />
                </Cell.Title>
              </Tooltip.Trigger>
              <Portal>
                <Tooltip.Positioner>
                  <Tooltip.Content>
                    <FormattedMessage
                      id="domain.trade.dialog.withdraw.cell.fee.hint.title"
                      defaultMessage="Both fast and slow withdrawals are free of charge. However, for fast withdrawals, X10 charges a gas fee required to settle the transaction."
                    />
                  </Tooltip.Content>
                </Tooltip.Positioner>
              </Portal>
            </Tooltip.Root>
            <Cell.Value>
              {withdrawMode === 'FAST' && isWithdrawalFeeIsAvailable
                ? formatUsd(feeResult.value)
                : EMPTY_CELL_VALUE}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <Cell.Title>
                  <FormattedMessage
                    id="domain.trade.dialog.withdraw.cell.arrival-eta.title"
                    defaultMessage="Estimated Time of Arrival"
                  />
                </Cell.Title>
              </Tooltip.Trigger>
              <Portal>
                <Tooltip.Positioner>
                  <Tooltip.Content>
                    <FormattedMessage
                      id="domain.trade.dialog.withdraw.cell.arrival-eta.hint.title"
                      defaultMessage="Estimated time for the wirhdrawal to be confirmed on Ethereum L1."
                    />
                  </Tooltip.Content>
                </Tooltip.Positioner>
              </Portal>
            </Tooltip.Root>
            <Cell.Value>
              {withdrawMode === 'SLOW' ? (
                <FormattedMessage
                  id="domain.trade.dialog.withdraw.cell.arrival-eta.slow.value.title"
                  defaultMessage="Up to 12 hours"
                />
              ) : (
                <FormattedMessage
                  id="domain.trade.dialog.withdraw.cell.arrival-eta.fast.value.title"
                  defaultMessage="≈ 5 minutes"
                />
              )}
            </Cell.Value>
          </Cell.Item>
        </Cell.Group>

        <SegmentGroup.Root
          orientation="horizontal"
          visual="filled"
          defaultValue={withdrawMode}
          css={{
            gap: {
              _horizontal: 's-16',
            },
          }}
          onValueChange={(e) => {
            if (e.value === 'FAST') {
              setWithdrawMode('FAST')
            } else {
              setWithdrawMode('SLOW')
            }
          }}
        >
          <SegmentGroup.Item
            value={'FAST' as WithdrawMode}
            disabled={isAccountBlocked || isFastWithdrawalLimitDepleted}
            css={{
              pr: 's-16',
              textStyle: 'primary',
              color: withdrawMode === 'FAST' ? undefined : 'token.white-50',
            }}
          >
            <SegmentGroup.ItemControl />
            <SegmentGroup.ItemText>
              <Tooltip.Root
                positioning={{ placement: 'top' }}
                openDelay={250}
                closeDelay={50}
              >
                <Tooltip.Trigger asChild>
                  <HStack>
                    <SvgIcon.SvgIconLightning />
                    <Box>
                      <FormattedMessage
                        id="domain.trade.dialog.withdraw.mode.fast.title"
                        defaultMessage="Fast"
                      />
                    </Box>
                  </HStack>
                </Tooltip.Trigger>
                <Portal>
                  <Tooltip.Positioner>
                    <Tooltip.Content>
                      {isAccountBlocked ? (
                        <FormattedMessage
                          id="domain.trade.dialog.withdraw.mode.fast-blocked.hint.title"
                          defaultMessage="This functionality is not available for your account. Please close all positions and withdraw all funds via slow withdrawals."
                        />
                      ) : isFastWithdrawalLimitDepleted ? (
                        <FormattedMessage
                          id="domain.trade.dialog.withdraw.mode.fast-limit-depleted.hint.title"
                          defaultMessage="A maximum limit of fast withdrawals per calendar day is fully used. Please try again later or proceed with slow withdrawals, which do not have withdrawal limits."
                        />
                      ) : (
                        <FormattedMessage
                          id="domain.trade.dialog.withdraw.mode.fast.hint.title"
                          defaultMessage="Fast withdrawals are almost immediate but are subject to a maximum limit of $50,000 per calendar day. Fast withdrawals are free, but X10 charges a gas fee that is required to settle a fast withdrawal."
                        />
                      )}
                    </Tooltip.Content>
                  </Tooltip.Positioner>
                </Portal>
              </Tooltip.Root>
            </SegmentGroup.ItemText>
            <SegmentGroup.ItemHiddenInput />
          </SegmentGroup.Item>

          <SegmentGroup.Item
            value={'SLOW' as WithdrawMode}
            css={{
              px: 's-16!',
              textStyle: 'primary',
              color: withdrawMode === 'SLOW' ? undefined : 'token.white-50',
            }}
          >
            <SegmentGroup.ItemControl />
            <SegmentGroup.ItemText>
              <Tooltip.Root
                positioning={{ placement: 'top' }}
                openDelay={250}
                closeDelay={50}
              >
                <Tooltip.Trigger asChild>
                  <Box>
                    <FormattedMessage
                      id="domain.trade.dialog.withdraw.mode.slow.title"
                      defaultMessage="Slow"
                    />
                  </Box>
                </Tooltip.Trigger>
                <Portal>
                  <Tooltip.Positioner>
                    <Tooltip.Content>
                      <FormattedMessage
                        id="domain.trade.dialog.withdraw.mode.slow.hint.title"
                        defaultMessage="Slow withdrawals involve a two-step process. The first step initiates your withdrawal request, which is processed on Layer 2. Once your funds are ready (this can take up to 12 hours), you will receive a notification to withdraw your funds to your wallet. Please note that you are responsible for the withdrawal transaction gas fees. There is no maximum limit on the amount for slow withdrawals."
                      />
                    </Tooltip.Content>
                  </Tooltip.Positioner>
                </Portal>
              </Tooltip.Root>
            </SegmentGroup.ItemText>
            <SegmentGroup.ItemHiddenInput />
          </SegmentGroup.Item>

          <SegmentGroup.Indicator />
        </SegmentGroup.Root>
        {(!isWithdrawalFeeIsAvailable || gasSurgeError) && withdrawMode === 'FAST' && (
          <Callout visual="error">
            {!isWithdrawalFeeIsAvailable && (
              <FormattedMessage
                id="domain.trade.dialog.withdraw.error.trading-disabled.title"
                defaultMessage="Insufficient funds in the liquidity pool. Please try again later or proceed with a slow withdrawal."
              />
            )}
            {gasSurgeError && (
              <FormattedMessage
                id="domain.trade.dialog.withdraw.error.gas-surge.title"
                defaultMessage="Your previous withdrawal request was cancelled due to a surge in gas fees. Please sign the withdrawal request again."
              />
            )}
          </Callout>
        )}
      </VStack>
      <Dialog.Context>
        {({ setOpen }) => (
          <Tooltip.Root
            disabled={!isFastWithdrawalLimitOverflowErrorShown}
            positioning={{ placement: 'top' }}
            openDelay={250}
            closeDelay={50}
          >
            <Tooltip.Trigger asChild>
              <Button
                css={{
                  mt: 's-32',
                }}
                visual="primary-red"
                size="large"
                w="100%"
                disabled={!isFormValid}
                loading={withdrawalPending}
                onClick={handleWithdraw({ onSuccess: () => setOpen(false) })}
              >
                <FormattedMessage
                  id="domain.trade.dialog.withdraw.action.withdraw.title"
                  defaultMessage="Withdraw"
                />
              </Button>
            </Tooltip.Trigger>
            <Portal>
              <Tooltip.Positioner>
                <Tooltip.Content>{fastWithdrawalLimitOverflowError}</Tooltip.Content>
              </Tooltip.Positioner>
            </Portal>
          </Tooltip.Root>
        )}
      </Dialog.Context>
    </>
  )
}
