'use client'

import { type FC } from 'react'

import { PageLayout } from '@x10/lib-ui-kit/components'
import { useBreakpoints } from '@x10/lib-ui-kit/hooks'

import { ClosedMainnetBanner } from '@src/domain/auth/components/closed-mainnet-banner'
import { ClosedMainnetFooter } from '@src/domain/auth/components/closed-mainnet-footer'
import { ClosedMainnetHeader } from '@src/domain/auth/components/closed-mainnet-header'
import { ReferralCodeDialog } from '@src/domain/auth/components/dialogs/referral-code-dialog'
import { WalletConnectedGuard } from '@src/domain/auth/components/wallet-connected-guard'
import { useWalletEligibilityStore } from '@src/domain/auth/store/wallet-eligibility'
import { BlowupSuspenseBoundary } from '@src/domain/core/ui/components/blowup-suspense-boundary'
import { Header } from '@src/domain/core/ui/components/header'

import { TradeProvider } from '../../providers/trade-provider'
import { FlexibleLayoutWithWidgets } from '../../ui/components/flexible-layout-with-widgets'
import { StaticLayoutWithWidgets } from '../../ui/components/static-layout-with-widgets'

const renderContent = (breakpointMdMatched: boolean | null) => {
  switch (breakpointMdMatched) {
    case true:
      return <FlexibleLayoutWithWidgets />
    case false:
      return <StaticLayoutWithWidgets />
    default:
      return null
  }
}

export const Trade: FC = () => {
  const { md: breakpointMdMatched } = useBreakpoints()

  const isBannerVisible = useWalletEligibilityStore(
    (state) => state.isClosedMainnetBannerVisible,
  )

  return (
    <BlowupSuspenseBoundary>
      <PageLayout.Root>
        {isBannerVisible ? (
          <>
            <ClosedMainnetBanner />
            <ClosedMainnetHeader />
            <ClosedMainnetFooter />
          </>
        ) : (
          <>
            <Header />

            <PageLayout.Content position="relative">
              <TradeProvider>{renderContent(breakpointMdMatched)}</TradeProvider>
            </PageLayout.Content>
          </>
        )}

        <WalletConnectedGuard>
          <ReferralCodeDialog />
        </WalletConnectedGuard>
      </PageLayout.Root>
    </BlowupSuspenseBoundary>
  )
}
