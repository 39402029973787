import {
  type DatafeedConfiguration,
  type ResolutionString,
} from '@src/types/charting-library'

import { toResolutionString } from '../trading-view-chart-api/constants'

type ExtendedDatafeedConfiguration = DatafeedConfiguration & {
  supports_search: boolean
  supports_group_request: boolean
}

export const SUPPORTED_RESOLUTIONS: ResolutionString[] = [
  toResolutionString('PT1M'),
  toResolutionString('PT5M'),
  toResolutionString('PT15M'),
  toResolutionString('PT30M'),
  toResolutionString('PT1H'),
  toResolutionString('PT2H'),
  toResolutionString('PT4H'),
  toResolutionString('P1D'),
]

export const CONFIG: ExtendedDatafeedConfiguration = {
  /**
   * https://www.tradingview.com/charting-library-docs/latest/core_concepts/Resolution
   */
  supported_resolutions: SUPPORTED_RESOLUTIONS,
  supports_search: false,
  supports_group_request: false,
  supports_marks: false,
  supports_timescale_marks: false,
  supports_time: false,
  exchanges: [{ value: 'X10', name: 'X10', desc: 'X10' }],
  symbols_types: [{ name: 'Crypto', value: 'crypto' }],
}
