import { checkRequired, Decimal } from '@x10/lib-core/utils'

import type { CandlePriceSource, MarketName } from '@src/domain/api/x10/common'
import { type LibrarySymbolInfo } from '@src/types/charting-library'

import type { TradingViewTicker } from '../../types/common'
import { SUPPORTED_RESOLUTIONS } from './constants'

const PRICE_SOURCES: Array<{ id: CandlePriceSource; name: string }> = [
  { id: 'trades', name: 'Last' },
  { id: 'mark-prices', name: 'Mark' },
  { id: 'index-prices', name: 'Index' },
]

export const makeTradingViewTicker = (
  symbolName: MarketName,
  priceSourceId: CandlePriceSource,
  collateralPrecision: Decimal,
  syntheticPrecision: Decimal,
): TradingViewTicker => {
  return `${symbolName}:${priceSourceId}:${collateralPrecision.toString()}:${syntheticPrecision.toString()}`
}

export const parseTradingViewTicker = (ticker: TradingViewTicker) => {
  const [symbolName, priceSourceId, collateralPrecision, syntheticPrecision] =
    ticker.split(':')

  return {
    symbolName: checkRequired(symbolName, 'symbolName') as MarketName,
    priceSourceId: checkRequired(priceSourceId, 'priceSourceId') as CandlePriceSource,
    collateralPrecision: Decimal(
      checkRequired(collateralPrecision, 'collateralPrecision'),
    ),
    syntheticPrecision: Decimal(checkRequired(syntheticPrecision, 'syntheticPrecision')),
  }
}

export const getSymbolInfoTicker = (symbolInfo: LibrarySymbolInfo) => {
  return checkRequired(symbolInfo.ticker, 'ticker') as TradingViewTicker
}

export const makeSymbolInfo = (
  priceSourceId: CandlePriceSource,
  symbolName: MarketName,
  collateralPrecision: Decimal,
  syntheticPrecision: Decimal,
): LibrarySymbolInfo => ({
  name: symbolName,
  ticker: makeTradingViewTicker(
    symbolName,
    priceSourceId,
    collateralPrecision,
    syntheticPrecision,
  ),
  description: symbolName,
  type: 'crypto',
  session: '24x7',
  exchange: 'X10',
  listed_exchange: 'X10',
  timezone: 'Etc/UTC',
  format: 'price',
  pricescale: 10 ** collateralPrecision.getDecimalPlaces(),
  visible_plots_set: 'ohlcv',
  volume_precision: syntheticPrecision.getDecimalPlaces(),
  minmov: 1,
  has_intraday: true,
  has_daily: true,
  data_status: 'streaming',
  intraday_multipliers: ['1', '5', '15', '30', '60', '120', '240'],
  supported_resolutions: SUPPORTED_RESOLUTIONS,
  currency_code: symbolName,
  price_sources: PRICE_SOURCES,
  price_source_id: priceSourceId,
})
