import { invariant } from '@x10/lib-core/utils'

import { getMarketPriceWithSlippage } from '@src/domain/trade/validation/order-form/utils'

import type { Computed, Context, ErrorMessage, Rule, Value } from '../types'

/**
 * [58:Validation]
 * Order Value < Max Order Value
 */
export const orderMaxValue: Rule<
  Pick<Value, 'orderPriceType'>,
  Pick<Context, 'reduceOnly' | 'market' | 'marketStats'>,
  Pick<Computed, 'orderSide' | 'orderPrice' | 'orderSize'>,
  Pick<ErrorMessage, 'getMaxOrderValue'>
> = (value, ctx, computed, _alerts, errors, errorMessage) => {
  if (errors.orderPrice || errors.orderSize || ctx.reduceOnly) {
    return
  }

  invariant(ctx.marketStats, '`marketStats` is required')
  invariant(computed.orderPrice, '`orderPrice` is required')
  invariant(computed.orderSize, '`orderSize` is required')

  const { tradingConfig } = ctx.market
  const orderPrice =
    value.orderPriceType === 'MARKET'
      ? getMarketPriceWithSlippage(
          computed.orderSide,
          ctx.marketStats,
          tradingConfig.minPriceChange,
        )
      : computed.orderPrice
  const orderValue = orderPrice.times(computed.orderSize)
  const maxOrderValue =
    value.orderPriceType === 'MARKET'
      ? tradingConfig.maxMarketOrderValue
      : tradingConfig.maxLimitOrderValue

  if (orderValue.gt(maxOrderValue)) {
    errors.form = errorMessage.getMaxOrderValue(maxOrderValue)
  }
}
