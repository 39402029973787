import { type FC, type ReactNode } from 'react'
import { ParentSize } from '@visx/responsive'

import { Box, type BoxProps } from '@x10/lib-styled-system/jsx'
import { DEFAULT_TRANSITION_HEIGHT } from '@x10/lib-styled-system/presets'

const CHART_RESIZE_DEBOUNCE = 10

type ResponsiveChartWrapperProps = Omit<BoxProps, 'children'> & {
  children: (args: { width: number; height: number }) => ReactNode
}

export const ResponsiveChartWrapper: FC<ResponsiveChartWrapperProps> = ({
  h = '100%',
  children,
}) => {
  return (
    <Box
      data-scope="chart"
      data-part="wrapper"
      h={h}
      maxH={h}
      w="100%"
      transition={DEFAULT_TRANSITION_HEIGHT}
    >
      <ParentSize debounceTime={CHART_RESIZE_DEBOUNCE}>{children}</ParentSize>
    </Box>
  )
}
