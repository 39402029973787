import { type FC } from 'react'

import { type Decimal } from '@x10/lib-core/utils'
import { ValueColored } from '@x10/lib-ui-kit/components'

import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'

type Props = {
  fundingRate: Decimal | undefined
}

export const FundingRateValue: FC<Props> = ({ fundingRate }) => {
  const formatPercent = useFormatPercent()

  return fundingRate ? (
    <ValueColored>
      {formatPercent(fundingRate, {
        precision: PercentPrecision.FundingRate,
      })}
    </ValueColored>
  ) : (
    EMPTY_CELL_VALUE
  )
}
