import { useCallback, type FC } from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import { Grid, GridItem, HStack } from '@x10/lib-styled-system/jsx'
import { Popover, Portal, ShevronTrigger } from '@x10/lib-ui-kit/components'

import { type X10Interval } from '@src/domain/api/x10/common'

import { SUPPORTED_INTERVALS } from '../hooks/use-favorite-intervals'
import { FavoriteIntervalToggle } from './favorite-interval-toggle'

export const useGetIntervalLabel = () => {
  const { formatMessage } = useIntl()

  return useCallback(
    (value: X10Interval) => {
      switch (value) {
        case 'PT1M':
          return {
            short: formatMessage({
              id: 'common.range.interval.PT1M.short',
              defaultMessage: '1m',
            }),
            long: formatMessage({
              id: 'common.range.interval.PT1M',
              defaultMessage: '1 Minute',
            }),
          }
        case 'PT5M':
          return {
            short: formatMessage({
              id: 'common.range.interval.PT5M.short',
              defaultMessage: '5m',
            }),
            long: formatMessage({
              id: 'common.range.interval.PT5M',
              defaultMessage: '5 Minutes',
            }),
          }
        case 'PT15M':
          return {
            short: formatMessage({
              id: 'common.range.interval.PT15M.short',
              defaultMessage: '15m',
            }),
            long: formatMessage({
              id: 'common.range.interval.PT15M',
              defaultMessage: '15 Minutes',
            }),
          }
        case 'PT30M':
          return {
            short: formatMessage({
              id: 'common.range.interval.PT30M.short',
              defaultMessage: '30m',
            }),
            long: formatMessage({
              id: 'common.range.interval.PT30M',
              defaultMessage: '30 Minutes',
            }),
          }
        case 'PT1H':
          return {
            short: formatMessage({
              id: 'common.range.interval.PT1H.short',
              defaultMessage: '1h',
            }),
            long: formatMessage({
              id: 'common.range.interval.PT1H',
              defaultMessage: '1 Hour',
            }),
          }
        case 'PT2H':
          return {
            short: formatMessage({
              id: 'common.range.interval.PT2H.short',
              defaultMessage: '2h',
            }),
            long: formatMessage({
              id: 'common.range.interval.PT2H',
              defaultMessage: '2 Hours',
            }),
          }
        case 'PT4H':
          return {
            short: formatMessage({
              id: 'common.range.interval.PT4H.short',
              defaultMessage: '4h',
            }),
            long: formatMessage({
              id: 'common.range.interval.PT4H',
              defaultMessage: '4 Hours',
            }),
          }
        case 'P1D':
          return {
            short: formatMessage({
              id: 'common.range.interval.P1D.short',
              defaultMessage: '1D',
            }),
            long: formatMessage({
              id: 'common.range.interval.P1D',
              defaultMessage: '1 Day',
            }),
          }
      }
    },
    [formatMessage],
  )
}

type IntervalSelectProps = {
  value: X10Interval
  onValueSelected: (value: X10Interval) => void
}

export const IntervalSelect: FC<IntervalSelectProps> = ({ value, onValueSelected }) => {
  const getOptionLabel = useGetIntervalLabel()

  return (
    <Popover.Root portalled lazyMount positioning={{ placement: 'bottom' }}>
      <Popover.Context>
        {({ open, setOpen }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <ShevronTrigger open={open} />
              </Popover.Trigger>
              <Portal>
                <Popover.Positioner>
                  <Popover.Content
                    borderRadius="r-16"
                    css={{
                      width: 'auto',
                    }}
                  >
                    <Grid
                      css={{
                        gridTemplateColumns: '1fr 1fr 1fr',
                        gridTemplateRows: '1fr 1fr 1fr',
                        gridAutoFlow: 'column',
                        gap: 0,
                        p: 's-8',
                      }}
                    >
                      {SUPPORTED_INTERVALS.map((optionValue) => (
                        <GridItem
                          className="group"
                          key={optionValue}
                          css={{
                            px: 's-16',
                            py: 's-12',
                            borderRadius: 'r-8',
                            cursor: 'pointer',
                            whiteSpace: 'nowrap',
                            color:
                              optionValue === value ? 'token.white' : 'token.white-50',
                            _hover: { color: 'token.white', bg: 'token.white-10' },
                          }}
                          onClick={() => {
                            onValueSelected(optionValue)
                            setOpen(false)
                          }}
                        >
                          <HStack css={{ justifyContent: 'space-between' }}>
                            {getOptionLabel(optionValue).long}
                            <FavoriteIntervalToggle value={optionValue} />
                          </HStack>
                        </GridItem>
                      ))}
                    </Grid>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
