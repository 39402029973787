import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { css } from '@x10/lib-styled-system/css'
import { Box, Flex } from '@x10/lib-styled-system/jsx'

import { getStorageKey } from '@src/domain/core/utils/storage/get-storage-key'
import { QuickTrading } from '@src/domain/trade/ui/components/quick-trading'
import { Account } from '@src/domain/trade/ui/widgets/account'
import { LatestTrades } from '@src/domain/trade/ui/widgets/latest-trades'
import { MarketSelector } from '@src/domain/trade/ui/widgets/market-selector'
import { MarketStats } from '@src/domain/trade/ui/widgets/market-stats'
import { OrderBook } from '@src/domain/trade/ui/widgets/order-book'
import { OrderForm } from '@src/domain/trade/ui/widgets/order-form'
import { TradingChart } from '@src/domain/trade/ui/widgets/trading-chart'
import { TradingHistory } from '@src/domain/trade/ui/widgets/trading-history'

import { TradeWidget } from '../trade-widget'

const ORDER_FORM_ID = 'x10-order-form'

type TradeLayoutState = {
  historyTableCollapsed: boolean
  chartHorizontallyExpanded: boolean
}

export const useTradeLayoutState = create(
  persist<TradeLayoutState>(
    () => ({
      historyTableCollapsed: false,
      chartHorizontallyExpanded: false,
    }),
    { name: getStorageKey('trade-layout') },
  ),
)

export const useHistoryTableCollapsed = () =>
  useTradeLayoutState((state) => state.historyTableCollapsed)
export const useChartHorizontallyExpanded = () =>
  useTradeLayoutState((state) => state.chartHorizontallyExpanded)

export const toggleHistoryTableCollapsed = () => {
  useTradeLayoutState.setState((state) => ({
    historyTableCollapsed: !state.historyTableCollapsed,
  }))
}

export const toggleChartHorizontallyExpanded = () => {
  useTradeLayoutState.setState((state) => ({
    chartHorizontallyExpanded: !state.chartHorizontallyExpanded,
  }))
}

const HEADER_HEIGHT = '4.5rem'
const MARKET_STATS_HEIGHT = '4.625rem'
const TABLE_HEADER_HEIGHT = '3.5rem'
const BANNER_HEIGHT = '1.375rem'

const areasWhenChartHorizontallyExpanded = css.raw({
  gridTemplateAreas: {
    md: `
              "market-stats    market-stats    market-stats    order-form"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-history trading-history trading-history account"
              "trading-history trading-history trading-history account"
          `,
    xl: `
              "market-stats    market-stats    market-stats    market-stats"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-history trading-history trading-history account"
              "trading-history trading-history trading-history account"
          `,
  },
})

const areasWhenTableCollapsed = css.raw({
  gridTemplateAreas: {
    md: `
              "market-stats    market-stats    market-stats    order-form"
              "trading-chart   trading-chart   order-book      order-form"
              "trading-chart   trading-chart   order-book      order-form"
              "trading-chart   trading-chart   latest-trades   account"
              "trading-history trading-history trading-history account"
          `,
    xl: `
              "market-stats    market-stats    market-stats  market-stats"
              "trading-chart   trading-chart   order-book    order-form"
              "trading-chart   trading-chart   order-book    order-form"
              "trading-chart   trading-chart   latest-trades account"
              "trading-history trading-history latest-trades account"
          `,
  },
})

const areasWhenTableIsCollapsedAndChartExpanded = css.raw({
  gridTemplateAreas: {
    md: `
              "market-stats    market-stats    market-stats    order-form"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-chart   trading-chart   trading-chart   account"
              "trading-history trading-history trading-history account"
          `,
    xl: `
              "market-stats    market-stats    market-stats    market-stats"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-chart   trading-chart   trading-chart   order-form"
              "trading-chart   trading-chart   trading-chart   account"
              "trading-history trading-history trading-history account"
          `,
  },
})

export const FlexibleLayoutWithWidgets = () => {
  const { chartHorizontallyExpanded, historyTableCollapsed } = useTradeLayoutState()

  return (
    <>
      <Box
        style={{
          '--screen-height-offset': `calc(${BANNER_HEIGHT} + ${HEADER_HEIGHT} + 0.25rem)`,
          '--screen-height':
            'max(calc(100vh - var(--screen-height-offset)), calc(var(--x10-min-screen-height) - var(--screen-height-offset)))',
        }}
        css={css.raw(
          {
            display: 'grid',
            gap: 's-2',
            minH: 'var(--screen-height)',
            maxH: 'var(--screen-height)',
            gridTemplateColumns: `1fr 1fr var(--x10-static-column-width) var(--x10-static-column-width)`,
            gridTemplateRows: `${MARKET_STATS_HEIGHT} 1.5fr 1fr 1fr ${TABLE_HEADER_HEIGHT}`,
            gridTemplateAreas: {
              md: `
              "market-stats    market-stats    market-stats    order-form"
              "trading-chart   trading-chart   order-book      order-form"
              "trading-chart   trading-chart   latest-trades   order-form"
              "trading-history trading-history trading-history account"
              "trading-history trading-history trading-history account"
          `,
              xl: `
              "market-stats    market-stats    market-stats  market-stats"
              "trading-chart   trading-chart   order-book    order-form"
              "trading-chart   trading-chart   order-book    order-form"
              "trading-history trading-history latest-trades account"
              "trading-history trading-history latest-trades account"
          `,
            },
          },
          chartHorizontallyExpanded &&
            !historyTableCollapsed &&
            areasWhenChartHorizontallyExpanded,
          !chartHorizontallyExpanded && historyTableCollapsed && areasWhenTableCollapsed,
          chartHorizontallyExpanded &&
            historyTableCollapsed &&
            areasWhenTableIsCollapsedAndChartExpanded,
        )}
      >
        <Flex
          css={{
            gridArea: 'market-stats',
            gap: 's-2',
          }}
        >
          <TradeWidget.Root
            css={{
              borderRightRadius: 0,
            }}
          >
            <MarketSelector />
          </TradeWidget.Root>

          <TradeWidget.Root
            css={{
              borderLeftRadius: 0,
              width: '100%',
              flex: 1,
            }}
            fullScreenDisabled
          >
            <MarketStats />
          </TradeWidget.Root>
        </Flex>

        <TradeWidget.Root
          css={{
            gridArea: 'trading-chart',
          }}
        >
          <TradingChart />
        </TradeWidget.Root>

        <TradeWidget.Root
          css={{
            gridArea: 'trading-history',
          }}
        >
          <TradingHistory />
        </TradeWidget.Root>

        <TradeWidget.Root
          css={{
            gridArea: 'order-book',
            display: chartHorizontallyExpanded ? 'none' : 'block',
          }}
        >
          <OrderBook />
        </TradeWidget.Root>

        <TradeWidget.Root
          css={{
            gridArea: 'latest-trades',
            display: chartHorizontallyExpanded ? 'none' : 'block',
          }}
        >
          <LatestTrades />
        </TradeWidget.Root>

        <TradeWidget.Root
          id={ORDER_FORM_ID}
          css={{
            h: '100%',
            gridArea: 'order-form',
          }}
        >
          <OrderForm />
        </TradeWidget.Root>
        <TradeWidget.Root
          css={{
            minH: '14.75rem',
            gridArea: 'account',
          }}
        >
          <Account />
        </TradeWidget.Root>
      </Box>

      <QuickTrading />
    </>
  )
}
