import { addDays } from 'date-fns'

import type { CryptoCurrencyCollateralCode } from '@x10/lib-core/config'
import { checkRequired, Long, type Decimal } from '@x10/lib-core/utils'

import { calcStarkExpiration } from '../utils/calc-stark-expiration'
import { generateNonce } from '../utils/generate-nonce'
import { StarkWithdrawalSettlement } from './stark-withdrawal-settlement'
import type { WithdrawalConfig } from './types'

const WITHDRAWAL_EXPIRATION_DAYS = 15

export class StarkWithdrawal {
  private readonly accountId: Long
  private readonly amount: Decimal
  private readonly asset: CryptoCurrencyCollateralCode
  private readonly settlement: StarkWithdrawalSettlement

  private constructor({
    accountId,
    amount,
    asset,
    settlement,
  }: {
    accountId: Long
    amount: Decimal
    asset: CryptoCurrencyCollateralCode
    settlement: StarkWithdrawalSettlement
  }) {
    this.accountId = accountId
    this.amount = amount
    this.asset = asset
    this.settlement = settlement
  }

  toJSON() {
    return {
      accountId: this.accountId.toString(10),
      amount: this.amount.toString(10),
      asset: this.asset,
      settlement: this.settlement.toJSON(),
    }
  }

  static create({
    accountId,
    amount,
    asset,
    ctx,
  }: {
    accountId: Long
    amount: Decimal
    asset: CryptoCurrencyCollateralCode
    ctx: WithdrawalConfig
  }) {
    const fromAccount = checkRequired(
      ctx.accounts.find((account) => account.accountId.eq(accountId)),
      'fromAccount',
    )
    const expiryEpochMillis = addDays(new Date(), WITHDRAWAL_EXPIRATION_DAYS).getTime()
    const starkAmount = amount
      .times(ctx.l2Config.collateralResolution)
      .toIntegerValueExact()

    return new StarkWithdrawal({
      accountId,
      amount,
      asset,
      settlement: StarkWithdrawalSettlement.create({
        amount: starkAmount,
        expirationTimestamp: calcStarkExpiration(expiryEpochMillis),
        nonce: Long(generateNonce()),
        collateralAssetId: ctx.l2Config.collateralId,
        ethAddress: ctx.ethAddress,
        positionId: Long(fromAccount.l2Vault),
        publicKey: fromAccount.l2Key,
        starkPrivateKey: ctx.starkPrivateKey,
      }),
    })
  }
}
