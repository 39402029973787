import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { type Decimal } from '@x10/lib-core/utils'
import { Box, VStack } from '@x10/lib-styled-system/jsx'
import { Button, HoverCard, Link, Portal, Tooltip } from '@x10/lib-ui-kit/components'

import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import { getValidationErrorText } from '@src/domain/trade/validation/get-validation-error-text'
import { type FieldName } from '@src/domain/trade/validation/order-form/types'
import type { FormValidationErrors } from '@src/domain/trade/validation/types'

type SellButtonProps = {
  orderCost: Decimal | undefined
  maxOrderValue: Decimal | undefined
  errors?: FormValidationErrors<FieldName>
  loading?: boolean
  onClick: () => void
  disabled?: boolean
}

export const SellButton: FC<SellButtonProps> = ({
  orderCost,
  maxOrderValue,
  errors,
  loading,
  onClick,
  disabled,
}) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })

  const formattedOrderCost = formatMarketAsset(
    { amount: orderCost, type: 'collateral' },
    { precision: AssetPrecision.UserOrTradeMetric },
  )
  const formattedMaxOrderValue = formatMarketAsset(
    { amount: maxOrderValue, type: 'collateral' },
    { precision: AssetPrecision.UserOrTradeMetric },
  )
  const errorText = getValidationErrorText(errors)

  return (
    <VStack
      borderRightRadius="r-16"
      py="s-16"
      pl="s-8"
      pr="s-16"
      width="100%"
      bg="token.red-10"
      alignItems="end"
      gap="s-2"
    >
      <HoverCard.Root>
        <HoverCard.Trigger>
          <Box textStyle="x-small">
            <FormattedMessage
              id="workspace.trade.widget.order-form.shared.order-info.cell.order-cost.title"
              defaultMessage="Cost: {value}"
              values={{
                value: formattedOrderCost ?? EMPTY_CELL_VALUE,
              }}
            />
          </Box>
        </HoverCard.Trigger>

        <Portal>
          <HoverCard.Positioner>
            <HoverCard.Content>
              <FormattedMessage
                id="workspace.trade.widget.order-form.shared.order-info.cell.order-cost.hint.title"
                defaultMessage="The margin required to place this order. Reducing a position does not cost additional margin. For more information, please refer to the <a>documentation</a>."
                values={{
                  a: (chunks) => (
                    <Link
                      href={asExternalRoute(
                        documentationRoute({}).trading({}).orderCost({}).$,
                      )}
                      external
                    >
                      {chunks}
                    </Link>
                  ),
                }}
              />
            </HoverCard.Content>
          </HoverCard.Positioner>
        </Portal>
      </HoverCard.Root>

      <Box textStyle="x-small">
        <FormattedMessage
          id="workspace.trade.widget.order-form.shared.order-info.cell.max-sell.title"
          defaultMessage="Max Sell: {value}"
          values={{
            value: formattedMaxOrderValue ?? EMPTY_CELL_VALUE,
          }}
        />
      </Box>

      <Tooltip.Root
        lazyMount
        closeDelay={0}
        openDelay={100}
        disabled={!errorText}
        positioning={{ placement: 'top' }}
      >
        <Tooltip.Trigger asChild>
          <Button
            disabled={Boolean(errors) || disabled}
            loading={loading}
            visual="primary-red"
            size="large"
            mt="s-8"
            onClick={onClick}
          >
            <FormattedMessage id="common.action.sell.title" defaultMessage="Sell" />
          </Button>
        </Tooltip.Trigger>

        <Portal>
          <Tooltip.Positioner>
            <Tooltip.Content>{errorText}</Tooltip.Content>
          </Tooltip.Positioner>
        </Portal>
      </Tooltip.Root>
    </VStack>
  )
}
