import * as z from 'zod'

export const addStreamEnvelope = <T extends z.Schema>(data: T) => {
  return z.object({
    type: z.enum(['SNAPSHOT', 'DELTA']).optional(),
    data: data,
    error: z.string().optional(),
    ts: z.number(),
    seq: z.number(),
  })
}
