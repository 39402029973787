import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import { type UserOrder } from '@src/domain/api/x10'

export const getOrderStatusIntlKey = (value: UserOrder['status']): MessageDescriptor => {
  switch (value) {
    case 'UNKNOWN':
      return {
        id: 'common.order.status.unknown.title',
        defaultMessage: 'Unknown',
      }
    case 'NEW':
      return {
        id: 'common.order.status.new.title',
        defaultMessage: 'New',
      }
    case 'UNTRIGGERED':
      return {
        id: 'common.order.status.untriggered.title',
        defaultMessage: 'Pending',
      }
    case 'PARTIALLY_FILLED':
      return {
        id: 'common.order.status.partially-filled.title',
        defaultMessage: 'Partially Filled',
      }
    case 'FILLED':
      return {
        id: 'common.order.status.filled.title',
        defaultMessage: 'Filled',
      }
    case 'CANCELLED':
      return {
        id: 'common.order.status.cancelled.title',
        defaultMessage: 'Cancelled',
      }
    case 'EXPIRED':
      return {
        id: 'common.order.status.expired.title',
        defaultMessage: 'Expired',
      }
    case 'REJECTED':
      return {
        id: 'common.order.status.rejected.title',
        defaultMessage: 'Rejected',
      }
    default:
      throw notReachable(value)
  }
}
