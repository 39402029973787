import type { FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'

import { parseError } from '@src/domain/core/errors/parse-error'

export const FormError: FC<{ error: Error | null | undefined }> = ({ error }) => {
  if (!error) {
    return null
  }

  const parsedError = parseError(error)

  return (
    <Box color="token.red" textStyle="small" w="100%">
      <FormattedMessage {...parsedError.message} />
    </Box>
  )
}
