import { Decimal } from '@x10/lib-core/utils'

import type { UserOrder, UserPosition } from '@src/domain/api/x10'
import { calcOrdersValueSumBySide } from '@src/domain/trade/utils/calc/calc-orders-value-sum-by-side'
import { calcPositionValue } from '@src/domain/trade/utils/calc/calc-position-value'

export const calcMaxOpenPositionPlusOrdersValue = (
  position: UserPosition | undefined,
  orders: UserOrder[],
) => {
  const positionValue = calcPositionValue(position)
  const { longOrdersValueSum, shortOrdersValueSum } = calcOrdersValueSumBySide(orders)

  return Decimal.max(
    positionValue.plus(longOrdersValueSum).abs(),
    positionValue.plus(shortOrdersValueSum).abs(),
  )
}
