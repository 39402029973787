import type { FC } from 'react'
import { useRouter } from 'next/navigation'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { getAssetUrl } from '@x10/lib-core/utils'
import { Box, VStack } from '@x10/lib-styled-system/jsx'
import { Button } from '@x10/lib-ui-kit/components'

import { DialogCloseTrigger } from '@src/domain/core/ui/components/dialog/dialog-close-trigger'
import { DialogImageIcon } from '@src/domain/core/ui/components/dialog/dialog-image-icon'
import { homeRoute } from '@src/domain/core/utils/routes'

export const ReferralCodeStepEligible: FC<{ onClose: () => void }> = ({ onClose }) => {
  const router = useRouter()

  return (
    <VStack css={{ gap: 's-16' }}>
      <DialogCloseTrigger onClick={onClose} />

      <DialogImageIcon
        src={getAssetUrl({ type: '3d-icon', name: 'rubik-cube@2x' })}
        css={{ mt: 's-48' }}
      />

      <div>
        <Box css={{ textStyle: 'h2', textAlign: 'center' }}>
          <FormattedMessage
            id="core.component.referral-code-dialog.step.wallet-eligible.title"
            defaultMessage="Your wallet is eligible!"
          />
        </Box>

        <Box css={{ textStyle: 'secondary', textAlign: 'center', mt: 's-8' }}>
          <FormattedMessage
            id="core.component.referral-code-dialog.step.wallet-eligible.description.title"
            defaultMessage="Start trading on the Closed Mainnet, earn referral codes, and share them to become an early ambassador."
          />
        </Box>
      </div>

      <Button
        visual="primary-green"
        size="large"
        css={{ mt: 's-32' }}
        onClick={() => {
          onClose()
          router.push(homeRoute({}).referrals({}).$)
        }}
      >
        <FormattedMessage
          id="core.component.referral-code-dialog.step.wallet-eligible.action.title"
          defaultMessage="Learn more"
        />
      </Button>
    </VStack>
  )
}
