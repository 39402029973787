import { useCallback, useState } from 'react'
import { isAxiosError } from 'axios'

import { Decimal, getLogger, invariant } from '@x10/lib-core/utils'

import { useFees } from '@src/domain/api/hooks/account/use-fees'
import { usePlaceOrder } from '@src/domain/api/hooks/order-management/use-place-order'
import type { CachedMarket } from '@src/domain/api/x10'
import type { OrderSide } from '@src/domain/api/x10/common'
import { AppErrorCode, X10AppError } from '@src/domain/core/errors/base'
import { createNotificationToast } from '@src/domain/core/ui/components/notification'
import {
  StarkPerpetualOrder,
  type OrderTpSlTriggerParam,
  type OrderTpSlType,
} from '@src/domain/starkex/stark-perpetual-order'
import { calcMarketOrderPrice } from '@src/domain/trade/utils/calc/calc-market-order-price'

import { useGetOrderCtx } from './use-get-order-ctx'

const LOGGER = getLogger('app-exchange.trade.use-create-and-place-tp-sl-order')

const patchTpSlMarketPrice = (
  param: OrderTpSlTriggerParam | undefined,
  side: OrderSide,
  minPriceChange: Decimal,
) => {
  if (!param || param.priceType !== 'MARKET') {
    return param
  }

  return {
    ...param,
    price: calcMarketOrderPrice(side, param.triggerPrice, minPriceChange),
  }
}

export const useCreateAndPlaceTpSlOrder = (market: CachedMarket) => {
  const getOrderCtx = useGetOrderCtx()
  const { data: fees } = useFees({ marketsNames: [market.name] })
  const { placeOrderAsync } = usePlaceOrder()

  const [isPlacingOrder, setPlacingOrder] = useState(false)

  const marketFees = fees?.[0]

  const createAndPlaceTpSlOrder = useCallback(
    async (
      side: OrderSide,
      tpSlType: OrderTpSlType,
      takeProfit?: OrderTpSlTriggerParam,
      stopLoss?: OrderTpSlTriggerParam,
    ) => {
      invariant(marketFees, 'marketFees')

      setPlacingOrder(true)

      try {
        const orderCtx = await getOrderCtx(
          market.l2Config,
          market.tradingConfig,
          marketFees,
        )
        const orderTp = patchTpSlMarketPrice(
          takeProfit,
          side,
          market.tradingConfig.minPriceChange,
        )
        const orderSl = patchTpSlMarketPrice(
          stopLoss,
          side,
          market.tradingConfig.minPriceChange,
        )

        const order = StarkPerpetualOrder.create({
          marketName: market.name,
          orderType: 'TPSL',
          side,
          amountOfSynthetic: Decimal.ZERO,
          price: Decimal.ZERO,
          timeInForce: 'GTT',
          expiryTime: undefined,
          reduceOnly: true,
          postOnly: false,
          tpSlType,
          takeProfit: orderTp,
          stopLoss: orderSl,
          ctx: orderCtx,
        })

        LOGGER.debug('Serialized TP/SL order = %o', order.toJSON())

        await placeOrderAsync({ order })
      } catch (error) {
        if (isAxiosError(error)) {
          createNotificationToast({
            notification: {
              type: 'TRADE',
              event: 'rejected',
              marketName: market.name,
              reason: error.response?.data?.error?.code,
            },
          })

          return
        }

        if (X10AppError.is(error, AppErrorCode.CantGetStarkPrivateKey)) {
          return
        }

        throw error
      } finally {
        setPlacingOrder(false)
      }
    },
    [market, marketFees, getOrderCtx, placeOrderAsync, setPlacingOrder],
  )

  return { createAndPlaceTpSlOrder, isPlacingOrder }
}
