import { type FC } from 'react'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Box, Flex, HStack, styled, VStack } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Button,
  Cell,
  InputWithSlider,
  Popover,
  Portal,
  ShevronTrigger,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { CSS_STATIC_COLUMN_WIDTH_VAR } from '@src/domain/core/config/static'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'

const AccountProtectionPopoverHeader: FC<{ closePopover: () => void }> = ({
  closePopover,
}) => {
  return (
    <Flex textStyle="primary" w="100%" justifyContent="space-between">
      <Box flex={1}>
        <FormattedMessage
          id="workspace.trade.widget.account.popover.account-protection.title"
          defaultMessage="Account Protection"
        />
      </Box>

      <HStack gap="s-8">
        <FeatureGate name="NEED_HELP_LINK">
          <Box color="token.white-50">
            <FormattedMessage
              id="workspace.trade.widget.account.popover.account-protection.help.title"
              defaultMessage="Need help?"
            />
          </Box>
        </FeatureGate>

        <ActionIcon onClick={() => closePopover()}>
          <SvgIcon.SvgIconCross />
        </ActionIcon>
      </HStack>
    </Flex>
  )
}

const AccountProtectionPopoverContent: FC<{ closePopover: () => void }> = ({
  closePopover,
}) => {
  const { formatMessage } = useIntl()

  return (
    <VStack gap="s-16">
      <AccountProtectionPopoverHeader closePopover={closePopover} />

      <Cell.Item textStyle="small">
        <Cell.Title>
          <FormattedMessage
            id="workspace.trade.widget.account.popover.current-equity.title"
            defaultMessage="Your current equity"
          />
        </Cell.Title>
        <Cell.Value>3 USDT</Cell.Value>
      </Cell.Item>

      <Box textStyle="small" fontWeight="fw-500" color="token.white-50">
        <FormattedMessage
          id="workspace.trade.widget.account.popover.info.equity-will-protect.title"
          defaultMessage="Equity of <highlight>{equity}</highlight> will protect you from a margin call in the next <highlight>{hours}</highlight> hours with a <highlight>{probability}</highlight> probability."
          values={{
            equity: '15 USDT',
            hours: '12',
            probability: '95%',
            highlight: (chunks) => {
              return <styled.span color="token.white">{chunks}</styled.span>
            },
          }}
        />
      </Box>

      <Box textStyle="small" fontWeight="fw-500" color="token.white-50">
        <FormattedMessage
          id="workspace.trade.widget.account.popover.info.protection-preferences.title"
          defaultMessage="Check your protection preferences and deposit funds if needed."
        />
      </Box>

      <InputWithSlider
        inputProps={{
          unit: formatMessage({
            id: 'workspace.trade.widget.account.popover.input.probability.title',
            defaultMessage: 'Probability',
          }),
        }}
        sliderProps={{ minLabel: '0', maxLabel: '99.9%' }}
      />
      <InputWithSlider
        inputProps={{
          unit: formatMessage({
            id: 'workspace.trade.widget.account.popover.input.hours.title',
            defaultMessage: 'Hours',
          }),
        }}
        sliderProps={{ minLabel: '0', maxLabel: '24' }}
      />

      <Button mt="s-16" visual="primary-green" size="small" w="100%">
        <FormattedMessage
          id="workspace.trade.widget.account.popover.action.deposit.title"
          defaultMessage="Deposit"
        />
      </Button>
    </VStack>
  )
}

export const AccountProtectionPopover = () => {
  return (
    <Popover.Root portalled lazyMount positioning={{ fitViewport: true }}>
      <Popover.Context>
        {({ open, setOpen }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <ShevronTrigger open={open} />
              </Popover.Trigger>

              <Portal>
                <Popover.Positioner
                  position="fixed!"
                  left="unset!"
                  top="unset!"
                  transform="unset!"
                  right="s-2"
                  bottom="s-2"
                >
                  <Popover.Content
                    borderRadius="r-16"
                    p="s-16"
                    style={{ width: `var(${CSS_STATIC_COLUMN_WIDTH_VAR})` }}
                  >
                    <AccountProtectionPopoverContent
                      closePopover={() => setOpen(false)}
                    />
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
