import { useCallback, useEffect, useState } from 'react'
import delay from 'lodash/delay'

import { getAssetUrl } from '@x10/lib-core/utils'
import { Center } from '@x10/lib-styled-system/jsx'

import { Video } from '@src/domain/core/ui/components/video'

import {
  useWalletEligibilityStore,
  walletEligibilityActions,
} from '../store/wallet-eligibility'
import { StartTradingButton } from './start-trading-button'

const UNLOCKING_DELAY = 1_000

export const ClosedMainnetBanner = () => {
  const walletStatus = useWalletEligibilityStore((state) => state.status)
  const [isAutoPlay, setAutoPlay] = useState(false)

  useEffect(() => {
    if (walletStatus === 'UNLOCKING') {
      setAutoPlay(true)
    }
  }, [walletStatus])

  const handleEnded = useCallback(() => {
    delay(() => walletEligibilityActions.finishUnlocking(), UNLOCKING_DELAY)
  }, [])

  const isStartTradingButtonVisible =
    walletStatus === 'UNKNOWN' || walletStatus === 'REFERRAL_CODE_REQUIRED'

  return (
    <Center
      css={{
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
      }}
    >
      <Video
        src={getAssetUrl({
          type: 'video',
          name: 'exchange/closed-mainnet-unlocking',
        })}
        autoPlay={isAutoPlay}
        loop={false}
        onEnded={handleEnded}
      />

      <StartTradingButton
        css={{
          display: isStartTradingButtonVisible ? undefined : 'none',
          position: 'absolute',
          mt: '15rem',
          w: '13rem',
        }}
      />
    </Center>
  )
}
