import find from 'lodash/find'

import type {
  FormValidationErrors,
  FormValidationFieldName,
} from '@src/domain/trade/validation/types'

export const getValidationErrorText = <T extends string>(
  errors: FormValidationErrors<FormValidationFieldName<T>> | undefined,
) => {
  if (!errors) {
    return undefined
  }

  if (errors.form) {
    return errors.form
  }

  return find(errors, (_value, key) => {
    return !(key === '@internal' || key === 'form')
  })
}
