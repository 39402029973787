import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import type { MarketAssets } from '@x10/lib-core/types'
import { Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { InputMessage, MoneyInput, SimpleSelect } from '@x10/lib-ui-kit/components'

import { getOrderPriceIntlKey } from '@src/domain/i18n/utils/get-order-price-intl-key'
import type { OrderPriceType } from '@src/domain/starkex/stark-perpetual-order'
import { useLatestTradesStore } from '@src/domain/trade/store/latest-trades'
import { useOrderbookPricesStore } from '@src/domain/trade/store/orderbook-prices-store'
import {
  NewOrderStoreActions,
  useNewOrderStore,
} from '@src/domain/trade/ui/widgets/order-form/store/new-order'
import { getPlaceholderForPrecision } from '@src/domain/trade/utils/get-placeholder-for-precision'

const useOrderPriceOptions = () => {
  const { formatMessage } = useIntl()

  return useMemo<Array<{ label: string; value: OrderPriceType }>>(() => {
    return [
      {
        label: formatMessage(getOrderPriceIntlKey('LIMIT')),
        value: 'LIMIT',
      },
      {
        label: formatMessage(getOrderPriceIntlKey('MARKET')),
        value: 'MARKET',
      },
    ]
  }, [formatMessage])
}

export type OrderPriceInputApi = {
  startFollowingLatestPrice: () => void
  stopFollowingLatestPrice: () => void
}

type OrderPriceInputProps = {
  assets: MarketAssets
  error?: string
  onBlur: () => void
  onPriceTypeChange: () => void
}

export const OrderPriceInput = forwardRef<OrderPriceInputApi, OrderPriceInputProps>(
  ({ assets, error, onBlur }, ref) => {
    const { formatMessage } = useIntl()

    const { lastPrice } = useLatestTradesStore()
    const { selectedPrice } = useOrderbookPricesStore()

    const isUserPriceSpecified = useNewOrderStore((state) => state.ui.userPriceSpecified)
    const priceType = useNewOrderStore((state) => state.priceType)
    const price = useNewOrderStore((state) => state.buy.price)

    const orderPriceOptions = useOrderPriceOptions()

    const shouldFollowTheLastPriceRef = useRef(!isUserPriceSpecified)

    const startFollowingLatestPrice = useCallback(() => {
      shouldFollowTheLastPriceRef.current = true
    }, [])

    const stopFollowingLatestPrice = useCallback(() => {
      shouldFollowTheLastPriceRef.current = false
    }, [])

    const updatePrice = useCallback(
      (value: Decimal, stopFollowing = true) => {
        if (stopFollowing) {
          stopFollowingLatestPrice()
        }

        NewOrderStoreActions.setPrice(value, value, stopFollowing)
      },
      [stopFollowingLatestPrice],
    )

    useEffect(() => {
      if (shouldFollowTheLastPriceRef.current && lastPrice && !isUserPriceSpecified) {
        updatePrice(lastPrice, false)
      }
    }, [lastPrice, isUserPriceSpecified, updatePrice])

    useEffect(() => {
      if (selectedPrice) {
        updatePrice(selectedPrice)
      }
    }, [selectedPrice, updatePrice])

    useImperativeHandle(ref, () => {
      return {
        startFollowingLatestPrice,
        stopFollowingLatestPrice,
      }
    }, [startFollowingLatestPrice, stopFollowingLatestPrice])

    const collateralPrecisionDp = assets.collateral.precision.getDecimalPlaces()

    return (
      <Box aria-invalid={Boolean(error)} className="group">
        <HStack w="100%" gap="s-2">
          <MoneyInput.Item
            value={Decimal.toNullableNumber(price)}
            overlayValue={
              priceType === 'MARKET'
                ? formatMessage({ id: 'common.price.market', defaultMessage: 'Market' })
                : undefined
            }
            currency={assets.collateral.code}
            precision={collateralPrecisionDp}
            placeholder={getPlaceholderForPrecision(collateralPrecisionDp)}
            disabled={priceType === 'MARKET'}
            label={formatMessage({
              id: 'workspace.trade.widget.order-form.shared.input.order-price.title',
              defaultMessage: 'Order Price',
            })}
            onFocus={stopFollowingLatestPrice}
            onBlur={onBlur}
            onChange={(value) => {
              const nextValue = Decimal.fromNullableValue(value)

              NewOrderStoreActions.setPrice(nextValue, nextValue, true)
            }}
          />

          <SimpleSelect.Root
            css={{ minW: '6.25rem' }}
            value={priceType}
            options={orderPriceOptions}
            onValueChange={(value) => {
              NewOrderStoreActions.setPriceType(value)
              NewOrderStoreActions.setPrice(null, null, false)
            }}
          >
            <SimpleSelect.Control />
            <SimpleSelect.Content>
              {orderPriceOptions.map((item) => (
                <SimpleSelect.Item key={item.value} item={item} />
              ))}
            </SimpleSelect.Content>
          </SimpleSelect.Root>
        </HStack>

        <InputMessage>{error}</InputMessage>
      </Box>
    )
  },
)

OrderPriceInput.displayName = 'OrderPriceInput'
