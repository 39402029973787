import { invariant } from '@x10/lib-core/utils'

import { calcMaxPositionValueForLeverageBasedOnRiskFactor } from '@src/domain/trade/utils/calc/calc-max-position-value-for-leverage-based-on-risk-factor'
import { calcOrdersValueSum } from '@src/domain/trade/utils/calc/calc-orders-value-sum'
import { calcPositionValue } from '@src/domain/trade/utils/calc/calc-position-value'
import { getSignedSize } from '@src/domain/trade/utils/calc/get-signed-size'
import { getMarketPriceWithSlippage } from '@src/domain/trade/validation/order-form/utils'

import {
  type Computed,
  type Context,
  type ErrorMessage,
  type Rule,
  type Value,
} from '../types'

/**
 * [64:Validation]
 * New Order Keeps the Position below Max Position Value for the selected leverage
 */
export const maxPositionValue: Rule<
  Pick<Value, 'orderPriceType'>,
  Pick<Context, 'leverage' | 'position' | 'market' | 'marketStats' | 'orders'>,
  Pick<Computed, 'orderPrice' | 'orderSize' | 'orderSide'>,
  Pick<ErrorMessage, 'getMaxPosition'>
> = (value, ctx, computed, _alerts, errors, errorMessage) => {
  if (errors.orderPrice || errors.orderSize) {
    return
  }

  invariant(ctx.marketStats, '`marketStats` is required')
  invariant(ctx.leverage, '`leverage` is required')
  invariant(computed.orderPrice, '`orderPrice` is required')
  invariant(computed.orderSize, '`orderSize` is required')

  const positionValue = calcPositionValue(ctx.position)
  const { tradingConfig } = ctx.market
  const orderPrice =
    value.orderPriceType === 'MARKET'
      ? getMarketPriceWithSlippage(
          computed.orderSide,
          ctx.marketStats,
          tradingConfig.minPriceChange,
        )
      : computed.orderPrice
  const orderValue = orderPrice.times(
    getSignedSize({ side: computed.orderSide, qty: computed.orderSize }),
  )
  const sameSideOrders = ctx.orders.filter((order) => order.side === computed.orderSide)
  const sameSideOrdersValue = calcOrdersValueSum(sameSideOrders)
  const maxPositionValueForTheSelectedLeverage =
    calcMaxPositionValueForLeverageBasedOnRiskFactor({
      leverage: ctx.leverage,
      riskFactorConfig: tradingConfig.riskFactorConfig,
    })

  const totalAbsValue = positionValue.plus(orderValue).plus(sameSideOrdersValue).abs()

  if (totalAbsValue.gt(maxPositionValueForTheSelectedLeverage)) {
    errors.form = errorMessage.getMaxPosition()
  }
}
