import { useCallback } from 'react'
import { useAccount } from 'wagmi'

import { invariant } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useWriteContract } from '@src/domain/core/hooks/use-write-contract'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { starkexAbi } from '../abi/starkex'

export const useWithdraw = () => {
  const market = useSelectedMarket()
  const { address } = useAccount()
  const { starkExContractAddress } = useSettings()

  const { writeContractAsync, isPending: isWritingContractPending } = useWriteContract()

  const withdraw = useCallback(() => {
    invariant(address, 'Address is not defined')

    return writeContractAsync({
      abi: starkexAbi,
      address: starkExContractAddress,
      functionName: 'withdraw',
      args: [BigInt(address), BigInt(market.l2Config.collateralId)],
    })
  }, [address, market.l2Config.collateralId, starkExContractAddress, writeContractAsync])

  return { withdraw, isWritingContractPending }
}
