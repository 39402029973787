import { useEffect, useMemo } from 'react'

import { useSubscribeToOrderBooks } from '@src/domain/api/hooks/stream/use-subscribe-to-order-books'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { useOrderbookPricesStore } from '@src/domain/trade/store/orderbook-prices-store'

import { TradeWidget } from '../../components/trade-widget'
import { OrderBookHeader } from './components/order-book-header'
import { OrderBookTable } from './components/order-book-table'
import { useOrderBookDisplayParamsStore } from './store'

export const OrderBook = () => {
  const market = useSelectedMarket()
  const { setPrices } = useOrderbookPricesStore()
  const { aggregationLevel, setAggregationLevel } = useOrderBookDisplayParamsStore()

  const { minPriceChange: minMarketPriceChange } = market.tradingConfig
  const minAggregationPriceChange = useMemo(() => {
    return minMarketPriceChange.times(aggregationLevel)
  }, [aggregationLevel, minMarketPriceChange])

  const { data } = useSubscribeToOrderBooks(
    market.name,
    minMarketPriceChange,
    minAggregationPriceChange,
  )

  useEffect(() => {
    setPrices(data.bestBidPrice, data.bestAskPrice, data.midPrice)
  }, [data.bestBidPrice, data.bestAskPrice, data.midPrice, setPrices])

  useEffect(() => {
    setAggregationLevel(1)
  }, [market.name, setAggregationLevel])

  return (
    <TradeWidget.InnerRoot>
      <OrderBookHeader minPriceChange={minMarketPriceChange} />

      <OrderBookTable
        bestBidPrice={data.bestBidPrice}
        bestAskPrice={data.bestAskPrice}
        midPrice={data.midPrice}
        precision={minAggregationPriceChange.getDecimalPlaces()}
        rows={data.rows}
      />
    </TradeWidget.InnerRoot>
  )
}
