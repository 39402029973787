import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { throttle, zip } from 'lodash'

import { Decimal, getLogger, safeParse } from '@x10/lib-core/utils'

import type { MarketName } from '@src/domain/api/x10/common'
import { EnvelopedOrderBookUpdateSchema } from '@src/domain/api/x10/stream/order-books.schema'
import { useWebSocket } from '@src/domain/core/hooks/use-web-socket'

import { aggregateOrderBookData } from './utils/aggregate-order-book-data'
import { calcMidPrice } from './utils/calc-mid-price'

const LOGGER = getLogger('app-exchange.api.use-subscribe-to-orderbooks')
const INITIAL_ORDER_BOOK_DATA = {
  bid: [],
  ask: [],
  seq: 0,
}
const DRAIN_BUFFER_DELAY = 200

type OrderBookDataItem = { qty: Decimal; price: Decimal }
export type OrderBookData = {
  bid: OrderBookDataItem[]
  ask: OrderBookDataItem[]
  seq: number
}

const applyUpdates = (snapshot: OrderBookDataItem[], update: OrderBookDataItem[]) => {
  const cache: Record<string, OrderBookDataItem> = {}
  const updatedSnapshot = [...snapshot]

  updatedSnapshot.forEach((item) => {
    cache[item.price.toString()] = item
  })

  update.forEach((item) => {
    const priceAsString = item.price.toString()
    const cachedItem = cache[priceAsString]

    if (cachedItem) {
      cachedItem.qty = cachedItem.qty.plus(item.qty)
    } else {
      const newEntry = { qty: item.qty, price: item.price }

      cache[priceAsString] = newEntry
      updatedSnapshot.push(newEntry)
    }
  })

  return updatedSnapshot.filter((item) => item.qty.gt(0))
}

export const useProcessData = (
  minMarketPriceChange: Decimal,
  minAggregationPriceChange: Decimal,
  buffer: OrderBookData[],
) => {
  const [orderBookData, setOrderBookData] = useState<OrderBookData>(
    INITIAL_ORDER_BOOK_DATA,
  )

  const data = useMemo(() => {
    const bid = aggregateOrderBookData(
      orderBookData.bid,
      'BUY',
      minAggregationPriceChange,
    )
    const ask = aggregateOrderBookData(
      orderBookData.ask,
      'SELL',
      minAggregationPriceChange,
    )

    return {
      bestBidPrice: bid.bestPrice,
      bestAskPrice: ask.bestPrice,
      midPrice: calcMidPrice(bid.bestPrice, ask.bestPrice, minMarketPriceChange),
      rows: zip(bid.rows, ask.rows),
    }
  }, [minMarketPriceChange, minAggregationPriceChange, orderBookData])

  // eslint-disable-next-line react-hooks/exhaustive-deps -- required because of the `throttle` wrapper
  const drainBuffer = useCallback(
    throttle(
      () => {
        const bufferCopy = Array.from(buffer)

        buffer.length = 0

        setOrderBookData((prevData) => {
          return bufferCopy.reduce((acc, update) => {
            if (update.seq <= acc.seq) {
              LOGGER.debug(
                'Invalid `seqNo` (skipping): %s (previous) + 1 != %s (current)',
                acc.seq,
                update.seq,
              )

              return acc
            }

            return {
              bid: applyUpdates(acc.bid, update.bid),
              ask: applyUpdates(acc.ask, update.ask),
              seq: update.seq,
            }
          }, prevData)
        })
      },
      DRAIN_BUFFER_DELAY,
      { leading: false },
    ),
    [],
  )

  const processEvent = useCallback(
    (event: MessageEvent) => {
      const jsonData = safeParse(event.data)
      const parsedData = EnvelopedOrderBookUpdateSchema.parse(jsonData)
      const updateObj = {
        bid: parsedData.data.bid,
        ask: parsedData.data.ask,
        seq: parsedData.seq,
      }

      if (parsedData.type === 'SNAPSHOT') {
        setOrderBookData(updateObj)
        buffer.length = 0
      } else {
        buffer.push(updateObj)
        drainBuffer()
      }
    },
    [buffer, drainBuffer],
  )

  const reset = useCallback(() => {
    setOrderBookData(INITIAL_ORDER_BOOK_DATA)
  }, [])

  return { data, processEvent, reset }
}

export const useSubscribeToOrderBooks = (
  marketName: MarketName,
  minMarketPriceChange: Decimal,
  minAggregationPriceChange: Decimal = Decimal.ONE,
) => {
  const buffer = useRef<OrderBookData[]>([])
  const { data, processEvent, reset } = useProcessData(
    minMarketPriceChange,
    minAggregationPriceChange,
    buffer.current,
  )
  const { subscribe, unsubscribe } = useWebSocket()

  useEffect(() => {
    const topic = { name: 'ORDERBOOKS', marketName } as const
    const subscriberUid = subscribe({ topic, callback: processEvent })

    reset()

    return () => {
      subscriberUid.then((id) => unsubscribe(id))
    }
  }, [marketName, processEvent, reset, subscribe, unsubscribe])

  return { data }
}
