import {
  useIsAccountBlocked,
  useIsAccountBlockedWithPendingDeposit,
} from '@src/domain/auth/hooks/use-client-status'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'

export const useAccountState = () => {
  const isFeatureEnabled = useIsFeatureEnabled()

  let isAccountBlocked = false
  let isAccountBlockedWithPendingDeposit = false

  /* eslint-disable react-hooks/rules-of-hooks -- it's ok as flags are stable */
  if (isFeatureEnabled('ACCOUNT_BLOCKING')) {
    isAccountBlocked = useIsAccountBlocked()
    isAccountBlockedWithPendingDeposit = useIsAccountBlockedWithPendingDeposit()
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return {
    isAccountBlocked,
    isAccountBlockedWithPendingDeposit,
  }
}
