import { useMutation } from '@tanstack/react-query'

import { type Decimal } from '@x10/lib-core/utils'

import { API } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'

export const useUpdateLeverage = () => {
  const accountId = useAccountId()

  return useMutation({
    mutationFn: (args: { marketName: MarketName; leverage: Decimal }) => {
      return API.trading.account.updateLeverage(args, accountId)
    },
  })
}
