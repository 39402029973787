import { useCallback } from 'react'

import { safeParse } from '@x10/lib-core/utils'

import type {
  CandlePriceSource,
  MarketName,
  X10Interval,
} from '@src/domain/api/x10/common'
import {
  EnvelopedCandlesSchema,
  type Candle,
} from '@src/domain/api/x10/stream/candles.schema'
import { useWebSocket } from '@src/domain/core/hooks/use-web-socket'
import type { Bar } from '@src/types/charting-library'

export const useSubscribeToCandles = (
  processCandle: (candle: Candle, updateData: (bar: Bar) => void) => void,
) => {
  const processEvent = useCallback(
    (event: MessageEvent, updateData: (bar: Bar) => void) => {
      const jsonData = safeParse(event.data)
      const parsedData = EnvelopedCandlesSchema.parse(jsonData)

      parsedData.data.forEach((candle) => processCandle(candle, updateData))
    },
    [processCandle],
  )

  const { subscribe: wsSubscribe, unsubscribe: wsUnsubscribe } = useWebSocket()

  const subscribe = useCallback(
    (
      subscriberUid: string,
      source: CandlePriceSource,
      marketName: MarketName,
      interval: X10Interval,
      updateData: (bar: Bar) => void,
    ) => {
      return wsSubscribe({
        topic: { name: 'CANDLES', source, marketName, interval },
        callback: (event) => processEvent(event, updateData),
        subscriberUid,
      })
    },
    [processEvent, wsSubscribe],
  )

  const unsubscribe = useCallback(
    (subscriberUid: string) => {
      wsUnsubscribe(subscriberUid)
    },
    [wsUnsubscribe],
  )

  return { subscribe, unsubscribe, processEvent }
}
