import { Flex, type FlexProps } from '@x10/lib-styled-system/jsx'

export const TradeWidgetInnerRoot = (props: FlexProps) => (
  <Flex
    data-scope="trade-widget"
    data-part="inner-root"
    flexDirection="column"
    h="100%"
    {...props}
  />
)
