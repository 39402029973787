import { useState, type FC } from 'react'
import { DndContext } from '@dnd-kit/core'

import { useStableElementId } from '@x10/lib-core/hooks'
import { notReachable } from '@x10/lib-core/utils'

import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import { useTradeContext } from '@src/domain/trade/providers/trade-provider'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { QuickTradingLimit } from './quick-trading-limit'
import { QuickTradingMarket } from './quick-trading-market'

export const QuickTrading: FC = () => {
  const market = useSelectedMarket()
  const { quickTradingMode } = useTradeContext()
  const dragId = useStableElementId()

  const { data: marketStats } = useMarketStats({ marketName: market.name })

  const [x, setX] = useState(0)
  const [y, setY] = useState(0)

  const renderComponent = () => {
    if (!quickTradingMode) {
      return null
    }

    switch (quickTradingMode) {
      case 'MARKET':
        return (
          <QuickTradingMarket
            dragId={`${dragId}:${quickTradingMode}`}
            x={x}
            y={y}
            bidPrice={marketStats?.bidPrice}
            askPrice={marketStats?.askPrice}
          />
        )
      case 'LIMIT':
        return (
          <QuickTradingLimit
            dragId={`${dragId}:${quickTradingMode}`}
            x={x}
            y={y}
            lastPrice={marketStats?.lastPrice.toString()}
          />
        )
      default:
        throw notReachable(quickTradingMode)
    }
  }

  return (
    <DndContext
      onDragEnd={(e) => {
        setX((prevValue) => prevValue + e.delta.x)
        setY((prevValue) => prevValue + e.delta.y)
      }}
    >
      {renderComponent()}
    </DndContext>
  )
}
