import { type FC } from 'react'

import { type Market } from '@src/domain/api/x10'
import { type MarketName } from '@src/domain/api/x10/common'
import { useManageSelectedMarket } from '@src/domain/trade/store/market'

import { MarketsPopover } from '../markets-popover'
import { SelectedMarket } from './selected-market'

type Props = {
  marketName: MarketName
  onMarketRowClick?: (market: Market) => void
}

export const MarketSelect: FC<Props> = ({ marketName, onMarketRowClick }) => {
  const setMarket = useManageSelectedMarket()

  return (
    <MarketsPopover
      onMarketRowClick={(newMarket) => {
        onMarketRowClick?.(newMarket)
        setMarket(newMarket)
      }}
    >
      <SelectedMarket marketName={marketName} active />
    </MarketsPopover>
  )
}
