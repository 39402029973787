import React, { useRef, type FC, type PropsWithChildren, type ReactNode } from 'react'
import { noop } from 'lodash'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Long } from '@x10/lib-core/utils'
import { Box, styled } from '@x10/lib-styled-system/jsx'
import { Button, HoverCard, Portal, TableCell } from '@x10/lib-ui-kit/components'

import { useCancelOrder } from '@src/domain/api/hooks/order-management/use-cancel-order'
import type { UserOrder } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'

import { OrderPriceCell } from '../../../components/order-price-cell'

const HOVER_DELAY = 500

const HeaderCell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <styled.th
      css={{
        textStyle: 'caption',
        color: 'token.white-50',
        p: 's-8',
      }}
    >
      {children}
    </styled.th>
  )
}

const CancelOrderCell: FC<{ orderId: Long }> = ({ orderId }) => {
  const { cancelOrder, isCancelling } = useCancelOrder()

  return (
    <TableCell>
      <Button
        visual="secondary-grey"
        loading={isCancelling}
        css={{ w: '100%', textStyle: 'caption' }}
        onClick={() => cancelOrder({ orderId })}
      >
        <FormattedMessage id="common.action.cancel" defaultMessage="Cancel" />
      </Button>
    </TableCell>
  )
}

export type HoverApi = {
  open: () => void
  close: () => void
  closeImmediately: () => void
}

type LimitCloseOrdersHoverProps = {
  orders: UserOrder[]
  children: (value: HoverApi) => ReactNode
}

export const LimitCloseOrdersHover: FC<LimitCloseOrdersHoverProps> = ({
  orders,
  children,
}) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })

  const hoverDelayTimerRef = useRef<NodeJS.Timeout>()

  if (orders.length === 0) {
    return children({ open: noop, close: noop, closeImmediately: noop })
  }

  return (
    <HoverCard.Root
      lazyMount
      unmountOnExit
      openDelay={0}
      positioning={{ placement: 'top' }}
    >
      <HoverCard.Context>
        {({ setOpen }) => {
          return (
            <Box css={{ position: 'relative' }}>
              <HoverCard.Trigger
                css={{ position: 'absolute', left: '50%', w: 1, h: 1 }}
              />

              {children({
                open() {
                  clearTimeout(hoverDelayTimerRef.current)

                  hoverDelayTimerRef.current = setTimeout(() => {
                    setOpen(true)
                    hoverDelayTimerRef.current = undefined
                  }, HOVER_DELAY)
                },

                close() {
                  clearTimeout(hoverDelayTimerRef.current)

                  hoverDelayTimerRef.current = setTimeout(() => {
                    setOpen(false)
                    hoverDelayTimerRef.current = undefined
                  }, HOVER_DELAY)
                },

                closeImmediately() {
                  clearTimeout(hoverDelayTimerRef.current)
                  setOpen(false)
                  hoverDelayTimerRef.current = undefined
                },
              })}
            </Box>
          )
        }}
      </HoverCard.Context>

      <Portal>
        <HoverCard.Positioner>
          <HoverCard.Content
            css={{ maxW: 'unset!', p: 's-8' }}
            onMouseEnter={() => {
              clearTimeout(hoverDelayTimerRef.current)
            }}
          >
            <table>
              <thead>
                <tr>
                  <HeaderCell>
                    <FormattedMessage
                      id="workspace.trade.widget.trading-history.mode.positions.limit-close-orders-hover.column.order-price.title"
                      defaultMessage="Order Price"
                    />
                  </HeaderCell>
                  <HeaderCell>
                    <FormattedMessage
                      id="workspace.trade.widget.trading-history.mode.positions.limit-close-orders-hover.column.size.title"
                      defaultMessage="Size"
                    />
                  </HeaderCell>
                  <th />
                </tr>
              </thead>

              <tbody>
                {orders.map((order) => {
                  return (
                    <tr key={order.id.toString()}>
                      <td>
                        <OrderPriceCell order={order} />
                      </td>
                      <td>
                        <TableCell>
                          {formatMarketAsset(
                            {
                              amount: order.qty,
                              type: 'synthetic',
                            },
                            {
                              marketName: order.market,
                            },
                          )}
                        </TableCell>
                      </td>
                      <td>
                        <CancelOrderCell orderId={order.id} />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </HoverCard.Content>
        </HoverCard.Positioner>
      </Portal>
    </HoverCard.Root>
  )
}
