import { token } from '@x10/lib-styled-system/tokens'

export const getPresetTokens = () => {
  const tokenColorGreen = token('colors.dark.green')
  const tokenColorGrey = token('colors.dark.grey')
  const tokenColorRed = token('colors.dark.red')
  const tokenColorWhite = token('colors.dark.white')
  const tokenColorWhite50 = token('colors.dark.white-5')

  return {
    colorGreen: tokenColorGreen,
    colorGrey: tokenColorGrey,
    colorRed: tokenColorRed,
    colorWhite: tokenColorWhite,
    colorWhite50: tokenColorWhite50,
  }
}
