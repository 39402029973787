import { type Computed, type ErrorMessage, type Rule } from '../types'

export const requireOrderPrice: Rule<
  unknown,
  unknown,
  Pick<Computed, 'orderPrice'>,
  Pick<ErrorMessage, 'getOrderPrice'>
> = (_value, _ctx, computed, _alerts, errors, errorMessage) => {
  if (computed.orderPrice === null) {
    errors.orderPrice = errorMessage.getOrderPrice()
  }
}
