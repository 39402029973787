import { invariant } from '@x10/lib-core/utils'

import type { Computed, Context, ErrorMessage, Rule } from '../types'
import { checkMarketLiquidity } from '../utils'

export const conditionalOrderLiquidity: Rule<
  unknown,
  Pick<Context, 'orderType' | 'marketStats'>,
  Pick<Computed, 'orderSide' | 'isConditionalTriggeredAtPlacement'>,
  Pick<ErrorMessage, 'getMarketOrderLiquidity'>
> = (_value, ctx, computed, _alerts, errors, errorMessage) => {
  if (!computed.isConditionalTriggeredAtPlacement) {
    return
  }

  invariant(ctx.marketStats, '`marketStats` is required')

  if (!checkMarketLiquidity(computed.orderSide, ctx.marketStats)) {
    errors.form = errorMessage.getMarketOrderLiquidity()
  }
}
