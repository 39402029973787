import React, { useCallback, useMemo, type FC } from 'react'
import { AxisBottom, AxisRight } from '@visx/axis'
import { curveStepAfter, curveStepBefore } from '@visx/curve'
import { localPoint } from '@visx/event'
import { LinearGradient } from '@visx/gradient'
import { GridColumns, GridRows } from '@visx/grid'
import { scaleLinear } from '@visx/scale'
import { AreaClosed, Bar, Line } from '@visx/shape'
import { withTooltip } from '@visx/tooltip'
import { type WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip'
import { bisector, extent, max } from '@visx/vendor/d3-array'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { type Decimal } from '@x10/lib-core/utils'
import { Box, Flex, type FlexProps } from '@x10/lib-styled-system/jsx'

import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { ResponsiveChartWrapper } from '@src/domain/core/ui/components/responsive-chart-wrapper'
import { abbreviateNumber } from '@src/domain/core/utils/abbreviate-number'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { usePrepareData } from '../../hooks/use-prepare-data'
import { getItemPrice } from '../../utils/get-item-price'
import { getItemSizeSum } from '../../utils/get-item-size-sum'
import { getPresetTokens } from '../../utils/get-preset-tokens'
import { Tooltip, type TooltipData } from '../tooltip'

const Y_OFFSET = 10

const bisectPrice = bisector<AggOrderBookDataItem, number>(getItemPrice).left

type AreaProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
}

type DepthChartComponentProps = AreaProps & {
  midPrice: number | undefined
  bestBidPrice: number | undefined
  bestAskPrice: number | undefined
  bids: AggOrderBookDataItem[]
  asks: AggOrderBookDataItem[]
}

const DepthChartComponent = withTooltip<DepthChartComponentProps, TooltipData>(
  ({
    midPrice,
    bestBidPrice = Number.MIN_SAFE_INTEGER,
    bestAskPrice = Number.MAX_SAFE_INTEGER,
    bids,
    asks,
    width,
    height,
    margin = { top: 12, right: 50, left: 0, bottom: 26 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  }: DepthChartComponentProps & WithTooltipProvidedProps<TooltipData>) => {
    const presetTokens = getPresetTokens()
    const formatMarketAsset = useFormatMarketAsset()
    const market = useSelectedMarket()
    const bidsAndAsks = useMemo(() => bids.concat(asks), [bids, asks])

    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    const priceScale = useMemo(
      () =>
        scaleLinear({
          range: [margin.left, innerWidth + margin.left],
          domain: extent(bidsAndAsks, getItemPrice) as [number, number],
        }),
      [bidsAndAsks, innerWidth, margin.left],
    )

    const sizeScale = useMemo(
      () =>
        scaleLinear({
          range: [innerHeight + margin.top, margin.top + Y_OFFSET],
          domain: [0, max(bidsAndAsks, getItemSizeSum) ?? 0],
        }),
      [bidsAndAsks, margin.top, innerHeight],
    )

    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) ?? { x: 0 }
        const x0 = priceScale.invert(x)

        if (x0 > bestBidPrice && x0 < bestAskPrice) {
          hideTooltip()
          return
        }

        let itemIndex = Math.min(
          Math.max(1, bisectPrice(bidsAndAsks, x0)),
          bidsAndAsks.length - 1,
        )

        if (x0 >= bestAskPrice) {
          itemIndex -= 1
        }

        const item = bidsAndAsks[itemIndex]

        if (item) {
          showTooltip({
            tooltipData: item,
            tooltipLeft: priceScale(getItemPrice(item)),
            tooltipTop: sizeScale(getItemSizeSum(item)),
          })
        } else {
          hideTooltip()
        }
      },
      [
        bestBidPrice,
        bestAskPrice,
        bidsAndAsks,
        priceScale,
        sizeScale,
        showTooltip,
        hideTooltip,
      ],
    )

    return (
      <Box>
        <svg width={width} height={height}>
          <LinearGradient
            id="area-gradient-bids"
            from={presetTokens.colorGradientGreen}
            to={presetTokens.colorGradientGreen}
            toOpacity={0.1}
          />
          <LinearGradient
            id="area-gradient-asks"
            from={presetTokens.colorGradientRed}
            to={presetTokens.colorGradientRed}
            toOpacity={0.1}
          />

          <GridRows
            left={margin.left}
            width={innerWidth}
            scale={sizeScale}
            stroke={presetTokens.colorGrid}
            opacity={0.5}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            height={innerHeight}
            scale={priceScale}
            stroke={presetTokens.colorGrid}
            opacity={0.5}
            pointerEvents="none"
          />

          <AreaClosed<AggOrderBookDataItem>
            data={bids}
            x={(d) => priceScale(getItemPrice(d)) ?? 0}
            y={(d) => sizeScale(getItemSizeSum(d)) ?? 0}
            yScale={sizeScale}
            stroke={presetTokens.colorGreen}
            strokeWidth={1}
            fill="url(#area-gradient-bids)"
            curve={curveStepBefore}
          />

          <AreaClosed<AggOrderBookDataItem>
            data={asks}
            x={(d) => priceScale(getItemPrice(d)) ?? 0}
            y={(d) => sizeScale(getItemSizeSum(d)) ?? 0}
            yScale={sizeScale}
            stroke={presetTokens.colorRed}
            strokeWidth={1}
            fill="url(#area-gradient-asks)"
            curve={curveStepAfter}
          />

          <Line
            from={{ x: 0, y: margin.top }}
            to={{ x: 0, y: innerHeight + margin.top + 1 }}
            stroke={presetTokens.colorBlack}
            strokeWidth={1}
            pointerEvents="none"
          />

          <Line
            from={{ x: innerWidth, y: margin.top }}
            to={{ x: innerWidth, y: innerHeight + margin.top + 1 }}
            stroke={presetTokens.colorBlack}
            strokeWidth={1}
            pointerEvents="none"
          />

          <AxisBottom
            scale={priceScale}
            top={innerHeight + margin.top}
            left={0}
            tickLabelProps={{
              fill: presetTokens.colorWhite,
              style: { fontSize: presetTokens.fontSize10 },
            }}
            numTicks={Math.ceil(width / 80)} // ~80px is how much is needed to render a single tick with good gutters around it
            tickFormat={(value) =>
              formatMarketAsset({ amount: value.valueOf(), type: 'collateral' })
            }
          />
          <text
            fill={presetTokens.colorWhite}
            style={{
              fontSize: presetTokens.fontSize10,
            }}
            y={margin.top + 1}
            x={innerWidth + 8}
            dx="0.25em"
          >
            {market.assets.synthetic.code}
          </text>
          <AxisRight
            scale={sizeScale}
            top={0}
            left={width - margin.right}
            tickLabelProps={{
              fill: presetTokens.colorWhite,
              style: { fontSize: presetTokens.fontSize10 },
            }}
            tickFormat={(value) => abbreviateNumber(value.valueOf())}
          />

          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />

          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={presetTokens.colorWhite}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="2,4"
              />

              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={
                  tooltipData.side === 'BUY'
                    ? presetTokens.colorGreen
                    : presetTokens.colorRed
                }
                pointerEvents="none"
              />
            </g>
          )}
        </svg>

        {tooltipData && (
          <Tooltip
            data={tooltipData}
            midPrice={midPrice}
            top={tooltipTop}
            left={tooltipLeft}
          />
        )}
      </Box>
    )
  },
)

type DepthChartProps = FlexProps & {
  data: {
    bestBidPrice: Decimal | undefined
    bestAskPrice: Decimal | undefined
    midPrice: Decimal | undefined
    rows: Array<[AggOrderBookDataItem | undefined, AggOrderBookDataItem | undefined]>
  }
}

export const HorizontalDepthChart: FC<DepthChartProps> = ({ data }) => {
  const { bids, asks } = usePrepareData({ rows: data.rows, midPrice: data.midPrice })
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })

  return (
    <Flex flex={1} direction="column" overflow="hidden">
      <Box textStyle="caption" textAlign="center">
        <FormattedMessage
          id="core.component.horizontal-depth-chart.mid-price.title"
          defaultMessage="Mid-Market Price: {price}"
          values={{
            price:
              formatMarketAsset({
                amount: data.midPrice,
                type: 'collateral',
              }) ?? EMPTY_CELL_VALUE,
          }}
        />
      </Box>

      <Box flex={1} overflow="hidden">
        <ResponsiveChartWrapper>
          {({ width, height }) => (
            <DepthChartComponent
              midPrice={data.midPrice?.toNumber()}
              bestBidPrice={data.bestBidPrice?.toNumber()}
              bestAskPrice={data.bestAskPrice?.toNumber()}
              bids={bids}
              asks={asks}
              width={width}
              height={height}
            />
          )}
        </ResponsiveChartWrapper>
      </Box>
    </Flex>
  )
}
