import { Component, type PropsWithChildren } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, Flex } from '@x10/lib-styled-system/jsx'
import { Button } from '@x10/lib-ui-kit/components'

import { captureExceptionWithSentry } from '@src/domain/core/errors/capture-exception-with-sentry'
import { parseError } from '@src/domain/core/errors/parse-error'

type TradeWidgetErrorBoundaryState = { error?: Error }

export class TradeWidgetErrorBoundary extends Component<
  PropsWithChildren,
  TradeWidgetErrorBoundaryState
> {
  constructor(props: PropsWithChildren) {
    super(props)

    this.state = {}
  }

  static getDerivedStateFromError(error: Error) {
    captureExceptionWithSentry(error)

    return { error }
  }

  override render() {
    const { children } = this.props
    const { error } = this.state

    if (!error) {
      return children
    }

    const parsedError = parseError(error)

    return (
      <Flex h="100%" flex={1} justifyContent="center" alignItems="center">
        <Box textAlign="center">
          <FormattedMessage {...parsedError.message} />

          <Button
            mt="s-8"
            visual="secondary-grey"
            size="small"
            onClick={() => this.resetError()}
          >
            <FormattedMessage
              id="common.action.try-again.title"
              defaultMessage="Try again"
            />
          </Button>
        </Box>
      </Flex>
    )
  }

  resetError() {
    this.setState({ error: undefined })
  }
}
