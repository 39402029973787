import { useMutation, useQueryClient } from '@tanstack/react-query'

import type { Long } from '@x10/lib-core/utils'

import { API } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

export const useCancelOrder = () => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const { mutate, isPending } = useMutation({
    mutationFn: (args: { orderId: Long }) => {
      return API.trading.orderManagement.cancelOrder(args, accountId)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.UserOrders] })
    },
  })

  return {
    cancelOrder: mutate,
    isCancelling: isPending,
  }
}
