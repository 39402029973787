import { Decimal } from '@x10/lib-core/utils'

import { useBalance } from '@src/domain/api/hooks/account/use-balance'
import { useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'
import type { AccountDetailsProps } from '@src/domain/trade/ui/widgets/account/components/account-details/account-details'

import { AccountActions } from './components/account-actions'
import { AccountDetails } from './components/account-details'
import { AccountHeader } from './components/account-header'

const ACCOUNT_DETAILS_PLACEHOLDER_DATA: AccountDetailsProps['data'] = {
  equity: Decimal(0),
  unrealisedPnl: Decimal(0),
  collateralName: 'USD',
  initialMargin: Decimal(0),
  marginRatio: Decimal(0),
}

export const Account = () => {
  const { data: balance } = useBalance()
  const isLoggedIn = useSuspendedIsLoggedIn()

  return (
    <TradeWidget.InnerRoot>
      <AccountHeader />

      {balance ? (
        <AccountDetails data={balance} />
      ) : (
        <AccountDetails data={ACCOUNT_DETAILS_PLACEHOLDER_DATA} />
      )}

      {isLoggedIn && <AccountActions />}
    </TradeWidget.InnerRoot>
  )
}
