import { defer } from 'lodash'

import {
  type Bar,
  type ErrorCallback,
  type HistoryCallback,
  type HistoryMetadata,
  type IBasicDataFeed,
  type LibrarySymbolInfo,
  type OnReadyCallback,
  type PeriodParams,
  type ResolutionString,
  type ResolveCallback,
  type SearchSymbolsCallback,
  type SubscribeBarsCallback,
  type SymbolResolveExtension,
} from '@src/types/charting-library'

import type { TradingViewTicker } from '../../types/common'
import { CONFIG } from './constants'
import {
  DataStreaming,
  type SubscribeToBarsWs,
  type UnsubscribeFromBarsWs,
} from './data-streaming'
import { getSymbolInfoTicker, makeSymbolInfo, parseTradingViewTicker } from './utils'

export type FetchBars = (
  ticker: TradingViewTicker,
  resolution: ResolutionString,
  params: PeriodParams,
) => Promise<{ bars: Bar[]; meta?: HistoryMetadata }>

/**
 * Note that all callbacks should be evoked asynchronously.
 * Otherwise, the `Uncaught RangeError: Maximum call stack size exceeded` issue might occur.
 * https://www.tradingview.com/charting-library-docs/latest/connecting_data/Datafeed-API#asynchronous-callbacks
 */
export class DataFeed implements IBasicDataFeed {
  streaming: DataStreaming
  lastBarsCache: Map<string, Bar>

  fetchBars: FetchBars

  constructor(
    fetchBars: FetchBars,
    subscribeToBarsWs: SubscribeToBarsWs,
    unsubscribeFromBarsWs: UnsubscribeFromBarsWs,
  ) {
    this.streaming = new DataStreaming(subscribeToBarsWs, unsubscribeFromBarsWs)
    this.lastBarsCache = new Map()

    this.fetchBars = fetchBars
  }

  onReady(callback: OnReadyCallback) {
    defer(() => callback(CONFIG))
  }

  searchSymbols(
    _userInput: string,
    _exchange: string,
    _symbolType: string,
    onResultReadyCallback: SearchSymbolsCallback,
  ) {
    onResultReadyCallback([])
  }

  resolveSymbol(
    symbolName: string,
    onSymbolResolvedCallback: ResolveCallback,
    _onResolveErrorCallback: ErrorCallback,
    _extension?: SymbolResolveExtension,
  ) {
    const parsedTicker = parseTradingViewTicker(symbolName as TradingViewTicker)

    defer(() =>
      onSymbolResolvedCallback(
        makeSymbolInfo(
          parsedTicker.priceSourceId,
          parsedTicker.symbolName,
          parsedTicker.collateralPrecision,
          parsedTicker.syntheticPrecision,
        ),
      ),
    )
  }

  async getBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    periodParams: PeriodParams,
    onHistoryCallback: HistoryCallback,
    _onErrorCallback: ErrorCallback,
  ) {
    const historyBars = await this.fetchBars(
      getSymbolInfoTicker(symbolInfo),
      resolution,
      periodParams,
    )

    if (historyBars.bars.length > 0) {
      this.lastBarsCache.set(
        getSymbolInfoTicker(symbolInfo),
        historyBars.bars[historyBars.bars.length - 1]!,
      )
    }

    onHistoryCallback(historyBars.bars, historyBars.meta)
  }

  subscribeBars(
    symbolInfo: LibrarySymbolInfo,
    resolution: ResolutionString,
    onRealtimeCallback: SubscribeBarsCallback,
    subscriberUID: string,
    onResetCacheNeededCallback: () => void,
  ) {
    this.streaming.subscribeOnStream(
      symbolInfo,
      resolution,
      onRealtimeCallback,
      subscriberUID,
      onResetCacheNeededCallback,
      this.lastBarsCache.get(getSymbolInfoTicker(symbolInfo)),
    )
  }

  unsubscribeBars(subscriberUID: string) {
    this.streaming.unsubscribeFromStream(subscriberUID)
  }
}
