import { useEffect, useMemo } from 'react'
import { debounce, first } from 'lodash'

import { getLogger, notReachable } from '@x10/lib-core/utils'

import { useBalance } from '@src/domain/api/hooks/account/use-balance'
import { useLeverage } from '@src/domain/api/hooks/account/use-leverage'
import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import type { MarketTradingConfig } from '@src/domain/api/x10'
import type { OrderType } from '@src/domain/api/x10/common'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import type { OrderPriceType } from '@src/domain/starkex/stark-perpetual-order'
import { useOrdersForCalc } from '@src/domain/trade/hooks/use-orders-for-calc'
import { usePositionForCalc } from '@src/domain/trade/hooks/use-position-for-calc'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { useNewOrderStore } from '@src/domain/trade/ui/widgets/order-form/store/new-order'
import { useNewOrderMaxValueStore } from '@src/domain/trade/ui/widgets/order-form/store/new-order-max-value'
import { calcMarketOrderPrice } from '@src/domain/trade/utils/calc/calc-market-order-price'
import { calcMaxBuySell } from '@src/domain/trade/utils/calc/calc-max-buy-sell'

const LOGGER = getLogger('trade.hooks.use-max-buy-sell')
const CALC_WAIT = 1000

const getMaxOrderValue = (
  orderType: OrderType,
  priceType: OrderPriceType,
  tradingConfig: MarketTradingConfig,
) => {
  if (orderType === 'MARKET' || (orderType === 'CONDITIONAL' && priceType === 'MARKET')) {
    return tradingConfig.maxMarketOrderValue
  }

  if (orderType === 'LIMIT' || (orderType === 'CONDITIONAL' && priceType === 'LIMIT')) {
    return tradingConfig.maxLimitOrderValue
  }

  notReachable(`Unsupported: orderType=${orderType}, priceType=${priceType}`)
}

const useMaxBuySellCtx = () => {
  const market = useSelectedMarket()

  const priceType = useNewOrderStore((state) => state.priceType)
  const orderType = useNewOrderStore((state) => state.orderType)
  const buyPrice = useNewOrderStore((state) => state.buy.price)
  const sellPrice = useNewOrderStore((state) => state.sell.price)
  const buyAmountOfSynthetic = useNewOrderStore((state) => state.buy.amountOfSynthetic)
  const sellAmountOfSynthetic = useNewOrderStore((state) => state.sell.amountOfSynthetic)

  const { data: marketStats } = useMarketStats({ marketName: market.name })
  const { data: balance } = useBalance()
  const { data: leverage } = useLeverage({ marketsNames: [market.name] })
  const position = usePositionForCalc(market.name)
  const orders = useOrdersForCalc(market.name)

  const isNewOrderHasQty = Boolean(buyAmountOfSynthetic && sellAmountOfSynthetic)

  return useMemo(
    () => ({
      market,
      marketStats,
      buyPrice,
      sellPrice,
      isNewOrderHasQty,
      priceType,
      orderType,
      balance,
      leverage: first(leverage)?.leverage,
      position,
      orders,
    }),
    [
      market,
      marketStats,
      buyPrice,
      sellPrice,
      isNewOrderHasQty,
      priceType,
      orderType,
      balance,
      leverage,
      position,
      orders,
    ],
  )
}

export const useMaxBuySell = () => {
  const isFeatureEnabled = useIsFeatureEnabled()
  const { setOrderMaxValue } = useNewOrderMaxValueStore()

  const ctx = useMaxBuySellCtx()

  const updateOrderMaxValue = useMemo(() => {
    return debounce(
      (ctx: ReturnType<typeof useMaxBuySellCtx>) => {
        if (
          !ctx.marketStats ||
          !ctx.balance ||
          !ctx.leverage ||
          !ctx.buyPrice ||
          !ctx.sellPrice
        ) {
          setOrderMaxValue(null)
          return
        }

        let buyPrice = ctx.buyPrice
        let sellPrice = ctx.sellPrice

        if (ctx.priceType === 'MARKET') {
          const { minPriceChange } = ctx.market.tradingConfig

          buyPrice = calcMarketOrderPrice('BUY', buyPrice, minPriceChange)
          sellPrice = calcMarketOrderPrice('SELL', sellPrice, minPriceChange)
        }

        const maxOrderValue = getMaxOrderValue(
          ctx.orderType,
          ctx.priceType,
          ctx.market.tradingConfig,
        )
        const maxBuySell = calcMaxBuySell({
          newOrderBuyPrice: buyPrice,
          newOrderSellPrice: sellPrice,
          isNewOrderHasQty: ctx.isNewOrderHasQty,
          marketMarkPrice: ctx.marketStats.markPrice,
          availableBalance: ctx.balance.availableForTrade,
          leverage: ctx.leverage,
          position: ctx.position,
          orders: ctx.orders,
          maxOrderValue,
          tradingConfig: ctx.market.tradingConfig,
        })

        if (isFeatureEnabled('DEBUG_INFO')) {
          LOGGER.debug('Max buy/sell: %o', {
            newOrder: {
              newOrderBuyPrice5Pct: buyPrice,
              newOrderSellPrice5Pct: sellPrice,
            },
            ctx: {
              buyPrice: ctx.buyPrice,
              sellPrice: ctx.sellPrice,
              marketMarkPrice: ctx.marketStats.markPrice,
              availableBalance: ctx.balance.availableForTrade,
              leverage: ctx.leverage,
              position: ctx.position,
              orders: ctx.orders,
              maxOrderValue,
            },
            result: maxBuySell,
          })
        }

        setOrderMaxValue(maxBuySell)
      },
      CALC_WAIT,
      {
        trailing: true,
        maxWait: CALC_WAIT,
      },
    )
  }, [isFeatureEnabled, setOrderMaxValue])

  useEffect(() => {
    updateOrderMaxValue(ctx)
  }, [ctx, updateOrderMaxValue])
}
