import { useEffect } from 'react'

import { useSuspendedBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import { useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { useClientSettingsStore } from '@src/domain/core/store/user-settings'

// This is a component that triggers opening of the deposit modal in case user has 0 funds on his account so he makes first deposit
export const MakeFirstDepositDialogOpener = ({ onOpen }: { onOpen: VoidFunction }) => {
  const balance = useSuspendedBalance()
  const isLoggedIn = useSuspendedIsLoggedIn()
  const isClosedOnce = useClientSettingsStore(
    (state) => state.isAutoOpenedDepositDialogClosedOnce,
  )
  const isBalanceZero = balance.equity.eq(0)

  useEffect(() => {
    if (isBalanceZero && isLoggedIn && isClosedOnce !== undefined && !isClosedOnce) {
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- onOpen is unstable we have to ignore it as a dependency
  }, [isBalanceZero, isClosedOnce, isLoggedIn])

  return null
}
