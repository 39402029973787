import { ActionIcon, SvgIcon } from '@x10/lib-ui-kit/components'

import { useFullScreenContext } from '@src/domain/trade/providers/full-screen-provider'

export const ChartFullScreenButton = () => {
  const { fullScreen, toggleFullScreen } = useFullScreenContext()

  return (
    <ActionIcon onClick={toggleFullScreen}>
      {fullScreen ? <SvgIcon.SvgIconExitFullScreen /> : <SvgIcon.SvgIconFullScreen />}
    </ActionIcon>
  )
}
