import { FormattedMessage } from '@x10/lib-core/i18n'
import { TableCell } from '@x10/lib-ui-kit/components'

import type { UserOrder } from '@src/domain/api/x10'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'

export const OrderPriceCell = ({ order }: { order: UserOrder }) => {
  const formatMarketAsset = useFormatMarketAsset()

  return (
    <TableCell>
      <div>
        {order.averagePrice
          ? formatMarketAsset(
              {
                amount: order.averagePrice,
                type: 'collateral',
              },
              { marketName: order.market },
            )
          : EMPTY_CELL_VALUE}
      </div>
      <div>
        {order.type === 'MARKET' ||
        (order.type === 'CONDITIONAL' &&
          order.trigger?.executionPriceType === 'MARKET') ? (
          <FormattedMessage
            id="workspace.trade.widget.trading-history.mode.orders-history.column.order-filled-price.market.value"
            defaultMessage="Market"
          />
        ) : (
          formatMarketAsset(
            {
              amount: order.price,
              type: 'collateral',
            },
            { marketName: order.market },
          )
        )}
      </div>
    </TableCell>
  )
}
