import { useDeferredValue, useState, type FC, type ReactNode } from 'react'

import { Box } from '@x10/lib-styled-system/jsx'
import { Popover, Portal, SearchInput } from '@x10/lib-ui-kit/components'

import { type Market } from '@src/domain/api/x10'
import { DefaultMarketCategory } from '@src/domain/core/constants'
import { MarketsCategorySelector } from '@src/domain/core/ui/components/markets-table'

import { TradeMarketsTable } from './trade-markets-table'

type Props = {
  children: ReactNode
  onMarketRowClick: (row: Market) => void
}

export const MarketsPopover: FC<Props> = ({ children, onMarketRowClick }) => {
  const [selectedCategory, setSelectedCategory] = useState<string>(
    DefaultMarketCategory.All,
  )
  const [search, setSearch] = useState('')
  const deferredSearch = useDeferredValue(search)

  return (
    <Popover.Root
      size="wide"
      portalled
      lazyMount
      unmountOnExit
      responsive="fullScreen"
      positioning={{ placement: 'right-start' }}
      onOpenChange={() => {
        setSearch('')
      }}
    >
      <Popover.Trigger asChild>{children}</Popover.Trigger>

      <Portal>
        <Popover.Positioner>
          <Popover.Content
            css={{
              borderRadius: 'r-16',
              p: 's-16',
              position: 'relative',
              overflow: 'hidden',
              w: '40rem!',
              maxH: 'calc(100vh - 1rem)',
              display: 'flex',
              flexDir: 'column',
            }}
          >
            <Popover.Context>
              {({ setOpen }) => {
                const handleMarketRowClick = (market: Market) => {
                  setOpen(false)
                  onMarketRowClick(market)
                }

                return (
                  <>
                    <Box mb="s-16">
                      <SearchInput onChange={setSearch} />
                    </Box>

                    <MarketsCategorySelector
                      selectedCategory={selectedCategory}
                      onCategoryChange={setSelectedCategory}
                    />

                    <TradeMarketsTable
                      selectedCategory={selectedCategory}
                      search={deferredSearch}
                      onRowClick={handleMarketRowClick}
                    />
                  </>
                )
              }}
            </Popover.Context>
          </Popover.Content>
        </Popover.Positioner>
      </Portal>
    </Popover.Root>
  )
}
