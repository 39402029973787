import { lightFormat } from 'date-fns'

import { notReachable } from '@x10/lib-core/utils'
import { FilterPeriodValue } from '@x10/lib-ui-kit/components'

import { formatTime } from '@src/domain/core/utils/format-time'

export const getFormattedChartColumnTickValue = (
  period: FilterPeriodValue,
  timestamp: number,
) => {
  switch (period) {
    case FilterPeriodValue.OneDay:
      return formatTime(timestamp)
    case FilterPeriodValue.OneWeek:
    case FilterPeriodValue.OneMonth:
      return lightFormat(timestamp, 'MM.dd')
    default:
      throw notReachable(period)
  }
}
