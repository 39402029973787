import { useEffect, useState } from 'react'

const DEFAULT_MARGIN = { top: 40, right: 36, bottom: 40, left: 36 }
const AXIS_CHART_SPACE = 12

export const useCalcOpenInterestsChartMargins = () => {
  const [yCollateralAxisRef, setYCollateralAxisRef] = useState<SVGElement | null>(null)
  const [ySyntheticAxisRef, setYSyntheticAxisRef] = useState<SVGElement | null>(null)
  const [margin, setMargin] = useState(DEFAULT_MARGIN)

  useEffect(() => {
    if (yCollateralAxisRef && ySyntheticAxisRef) {
      const collateralAxisWidth = yCollateralAxisRef.getBoundingClientRect().width
      const syntheticAxisWidth = ySyntheticAxisRef.getBoundingClientRect().width
      const newMargin = {
        ...DEFAULT_MARGIN,
        left: collateralAxisWidth + AXIS_CHART_SPACE,
        right: syntheticAxisWidth + AXIS_CHART_SPACE,
      }
      setMargin(newMargin)
    }
  }, [yCollateralAxisRef, ySyntheticAxisRef])

  return {
    margin,
    setYCollateralAxisRef,
    setYSyntheticAxisRef,
  }
}
