import { Decimal, roundToPrecision } from '@x10/lib-core/utils'

import { type OrderSide } from '@src/domain/api/x10/common'

export const roundOrderSizeToPrecision = (
  type: OrderSide,
  value: Decimal,
  precision: Decimal,
) => {
  return roundToPrecision(
    value,
    precision,
    type === 'BUY' ? Decimal.ROUND_DOWN : Decimal.ROUND_UP,
  )
}
