import { create } from 'zustand'

import type { Decimal } from '@x10/lib-core/utils'

type OrderbookPricesStore = {
  bestBidPrice?: Decimal
  bestAskPrice?: Decimal
  midPrice?: Decimal
  selectedPrice?: Decimal

  setPrices(
    bid: Decimal | undefined,
    ask: Decimal | undefined,
    mid: Decimal | undefined,
  ): void
  setSelectedPrice(value: Decimal): void
}

export const useOrderbookPricesStore = create<OrderbookPricesStore>((set) => ({
  setPrices(bid, ask, mid) {
    set({
      bestBidPrice: bid,
      bestAskPrice: ask,
      midPrice: mid,
    })
  },
  setSelectedPrice(value) {
    set({ selectedPrice: value })
  },
}))
