import { token } from '@x10/lib-styled-system/tokens'

const COLOR_TRANSPARENT = 'transparent'

export const getPresetTokens = () => {
  const tokenColorGreen50 = token('colors.token.green-50')
  const tokenColorWhite = token('colors.token.white')
  const tokenColorWhite5 = token('colors.token.white-5')
  const tokenFontSize10 = token('fontSizes.fs-10')

  return {
    colorGreen50: tokenColorGreen50,
    colorWhite: tokenColorWhite,
    colorWhite5: tokenColorWhite5,
    colorTransparent: COLOR_TRANSPARENT,
    fontSize10: tokenFontSize10,
  }
}
