import { useMemo } from 'react'

import { Decimal } from '@x10/lib-core/utils'

import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { useSelectedMarket } from '@src/domain/trade/store/market'

const WORST_PRICE_BUFFER = 0.0003

export const usePrepareData = (data: {
  rows: Array<[AggOrderBookDataItem | undefined, AggOrderBookDataItem | undefined]>
  midPrice: Decimal | undefined
}) => {
  const market = useSelectedMarket()

  const bids = useMemo(() => {
    if (!data.midPrice) {
      return []
    }

    const threshold = data.midPrice.times(
      Decimal.ONE.minus(market.tradingConfig.limitPriceCap),
    )

    const result = data.rows
      .filter((item) => item[0] && Decimal(item[0].price).gte(threshold))
      .map((item) => ({ ...(item[0] as AggOrderBookDataItem) }))
      .reverse()
    const worstBid = result[0]

    if (worstBid) {
      result.unshift({
        ...worstBid,
        price: worstBid.price * (1 - WORST_PRICE_BUFFER),
      })
    }

    return result
  }, [data.midPrice, data.rows, market.tradingConfig.limitPriceCap])

  const asks = useMemo(() => {
    if (!data.midPrice) {
      return []
    }

    const threshold = data.midPrice.times(
      Decimal.ONE.plus(market.tradingConfig.limitPriceFloor),
    )

    const result = data.rows
      .filter((item) => item[1] && Decimal(item[1].price).lte(threshold))
      .map((item) => ({ ...(item[1] as AggOrderBookDataItem) }))
    const worstAsk = result[result.length - 1]

    if (worstAsk) {
      result.push({
        ...worstAsk,
        price: worstAsk.price * (1 + WORST_PRICE_BUFFER),
      })
    }

    return result
  }, [data.midPrice, data.rows, market.tradingConfig.limitPriceFloor])

  return { bids, asks }
}
