import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import type { AssetOperationType } from '@src/domain/api/x10'

export const getAssetOperationTypeIntlKey = (
  value: AssetOperationType,
): MessageDescriptor => {
  switch (value) {
    case 'CLAIM':
      return {
        id: 'common.asset-operation.type.claim',
        defaultMessage: 'Claim',
      }
    case 'DEPOSIT':
      return {
        id: 'common.asset-operation.type.deposit',
        defaultMessage: 'Deposit',
      }
    case 'SLOW_WITHDRAWAL':
      return {
        id: 'common.asset-operation.type.slow-withdrawal',
        defaultMessage: 'Slow Withdrawal',
      }
    case 'FAST_WITHDRAWAL':
      return {
        id: 'common.asset-operation.type.fast-withdrawal',
        defaultMessage: 'Fast Withdrawal',
      }
    case 'TRANSFER':
      return {
        id: 'common.asset-operation.type.transfer',
        defaultMessage: 'Transfer',
      }
    default:
      throw notReachable(value)
  }
}
