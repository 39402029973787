import type { Rule } from '../types'

/**
 * [68:Risk Alert]
 * The TP/SL order was inputted for one direction but the user tries
 * to execute the original order in the opposite direction
 */
export const riskForTpSlDirection: Rule<unknown, unknown, unknown, unknown> = () => {
  // FIXME: Not implemented
  //   https://linear.app/ex10/issue/X10-1980/add-wrong-direction-risk-alert
}
