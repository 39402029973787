import { Flex } from '@x10/lib-styled-system/jsx'
import { ActionIcon, SvgIcon } from '@x10/lib-ui-kit/components'

import { useAddPinnedMarket } from '@src/domain/trade/hooks/use-pinned-markets'
import { useManageSelectedMarket } from '@src/domain/trade/store/market'

import { MarketsPopover } from '../markets-popover'

export const AddPinnedMarketButton = () => {
  const addPinnedMarket = useAddPinnedMarket()
  const setMarket = useManageSelectedMarket()

  return (
    <MarketsPopover
      onMarketRowClick={(market) => {
        addPinnedMarket(market.name)
        setMarket(market)
      }}
    >
      <Flex>
        <ActionIcon aria-label="Pin market">
          <SvgIcon.SvgIconPlus size={24} />
        </ActionIcon>
      </Flex>
    </MarketsPopover>
  )
}
