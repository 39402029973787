import { type FC, type PropsWithChildren } from 'react'

import { FormattedMessage, type MessageDescriptor } from '@x10/lib-core/i18n'
import type { MarketAssets } from '@x10/lib-core/types'
import { Box, VStack, type BoxProps } from '@x10/lib-styled-system/jsx'
import { Popover, Portal, Skeleton, Tooltip } from '@x10/lib-ui-kit/components'

import {
  FullScreenConsumer,
  FullScreenProvider,
} from '@src/domain/trade/providers/full-screen-provider'

import { type ItemId } from '../../constants'
import { getItemTitleIntlKey } from '../../utils/get-item-title-intl-key'
import { MarketStatsPopoverRoot } from './market-stats-popover-root'

type MarketStatsItemProps = PropsWithChildren<{
  itemId: ItemId
  assets: MarketAssets
  pending?: boolean
  tooltipIntlKey?: MessageDescriptor & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- matching the type inside Props
    values?: Record<string, any>
    interactive?: boolean
  }
  popoverContent?: FC
}>

const Stat: FC<
  Omit<MarketStatsItemProps, 'tooltipIntlKey' | 'popoverContent'> & BoxProps
> = ({ itemId, assets, pending, children, ...restProps }) => {
  return (
    <VStack
      css={{
        alignItems: 'start',
        gap: 's-4',
        borderRadius: 'r-16',
        p: 's-8',
        whiteSpace: 'nowrap',
      }}
      {...restProps}
    >
      <Box css={{ textStyle: 'small' }}>
        <FormattedMessage {...getItemTitleIntlKey(itemId, assets)} />
      </Box>
      {pending ? (
        <Skeleton
          size="small"
          css={{
            my: '0.40625rem',
          }}
        />
      ) : (
        children
      )}
    </VStack>
  )
}

export const MarketStatsItem: FC<MarketStatsItemProps> = ({
  itemId,
  assets,
  pending,
  tooltipIntlKey,
  popoverContent: PopoverContent,
  children,
}) => {
  if (tooltipIntlKey && PopoverContent) {
    return (
      <FullScreenProvider>
        <MarketStatsPopoverRoot size="wide">
          <Popover.Context>
            {({ open: isPopoverOpen }) => {
              return (
                <>
                  <Popover.Trigger>
                    <Tooltip.Root
                      positioning={{ placement: 'bottom-start' }}
                      interactive={tooltipIntlKey.interactive}
                    >
                      <Tooltip.Trigger asChild>
                        <Stat
                          itemId={itemId}
                          assets={assets}
                          pending={pending}
                          cursor="pointer"
                          bg={isPopoverOpen ? 'token.white-10' : undefined}
                          _hover={{ bg: 'token.white-10' }}
                        >
                          {children}
                        </Stat>
                      </Tooltip.Trigger>

                      <Portal>
                        <Tooltip.Positioner>
                          <Tooltip.Content
                            css={{
                              whiteSpace: 'break-spaces',
                            }}
                          >
                            <FormattedMessage {...tooltipIntlKey} />
                          </Tooltip.Content>
                        </Tooltip.Positioner>
                      </Portal>
                    </Tooltip.Root>
                  </Popover.Trigger>

                  <Portal>
                    <Popover.Positioner>
                      <FullScreenConsumer>
                        {({ fullScreen }) => (
                          <Popover.Content
                            style={{
                              '--screen-height-offset': '12rem',
                            }}
                            css={{
                              display: 'flex',
                              flexDir: 'column',
                              p: 's-16',
                              borderRadius: 'r-16',
                              position: 'relative',
                              overflow: 'hidden',
                              h: '100%',
                              maxH: fullScreen
                                ? undefined
                                : 'max(calc(100vh - var(--screen-height-offset)), calc(var(--x10-min-screen-height) - var(--screen-height-offset)))',
                            }}
                          >
                            <PopoverContent />
                          </Popover.Content>
                        )}
                      </FullScreenConsumer>
                    </Popover.Positioner>
                  </Portal>
                </>
              )
            }}
          </Popover.Context>
        </MarketStatsPopoverRoot>
      </FullScreenProvider>
    )
  }

  if (tooltipIntlKey) {
    return (
      <Tooltip.Root positioning={{ placement: 'bottom-start' }}>
        <Tooltip.Trigger asChild>
          <Stat itemId={itemId} assets={assets} pending={pending}>
            {children}
          </Stat>
        </Tooltip.Trigger>

        <Portal>
          <Tooltip.Positioner>
            <Tooltip.Content
              css={{
                whiteSpace: 'break-spaces',
              }}
            >
              <FormattedMessage {...tooltipIntlKey} />
            </Tooltip.Content>
          </Tooltip.Positioner>
        </Portal>
      </Tooltip.Root>
    )
  }

  if (PopoverContent) {
    return (
      <FullScreenProvider>
        <MarketStatsPopoverRoot>
          <Popover.Context>
            {({ open: isPopoverOpen }) => {
              return (
                <>
                  <Popover.Trigger>
                    <Stat
                      itemId={itemId}
                      assets={assets}
                      pending={pending}
                      cursor="pointer"
                      bg={isPopoverOpen ? 'token.white-10' : undefined}
                      _hover={{ bg: 'token.white-10' }}
                    >
                      {children}
                    </Stat>
                  </Popover.Trigger>

                  <Portal>
                    <Popover.Positioner>
                      <Popover.Content css={{ borderRadius: 'r-16', p: 's-16' }}>
                        <PopoverContent />
                      </Popover.Content>
                    </Popover.Positioner>
                  </Portal>
                </>
              )
            }}
          </Popover.Context>
        </MarketStatsPopoverRoot>
      </FullScreenProvider>
    )
  }

  return (
    <Stat itemId={itemId} assets={assets} pending={pending}>
      {children}
    </Stat>
  )
}
