import * as z from 'zod'

import { addStreamEnvelope } from '@src/domain/api/utils/add-stream-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'

const CandleSchema = z.object({
  o: zodDecimal(),
  l: zodDecimal(),
  h: zodDecimal(),
  c: zodDecimal(),
  v: zodDecimal().optional(),
  T: z.number(),
})

export const EnvelopedCandlesSchema = addStreamEnvelope(z.array(CandleSchema))

export type Candle = z.infer<typeof CandleSchema>
