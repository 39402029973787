import { useState, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { Button, Cell, Checkbox, Popover, Portal } from '@x10/lib-ui-kit/components'

import type { MarketStats, UserOrder, UserPosition } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { PopoverHeader } from '@src/domain/core/ui/components/popover-header'
import { useConfirmationStore } from '@src/domain/trade/store/confirmation-store'

import { MarketOrderCostValue } from './market-order-cost-value'

type MarketCloseActionButtonProps = {
  position: UserPosition
  triggeredOrders: UserOrder[]
  marketStats?: MarketStats
  loading?: boolean
  disabled?: boolean
  onConfirm: () => Promise<void>
}

export const MarketCloseActionButton: FC<MarketCloseActionButtonProps> = ({
  position,
  triggeredOrders,
  marketStats,
  loading,
  disabled,
  onConfirm,
}) => {
  const { dontConfirmMarketClose, setDontConfirmMarketClose } = useConfirmationStore()
  const [dontShowConfirmation, setDontShowConfirmation] = useState(
    dontConfirmMarketClose ?? false,
  )

  const formatMarketAsset = useFormatMarketAsset({
    marketName: position.market,
    showSymbol: true,
  })

  if (dontConfirmMarketClose) {
    return (
      <Button
        visual="secondary-grey"
        loading={loading}
        disabled={disabled}
        css={{ w: '4.5rem' }}
        onClick={onConfirm}
      >
        <FormattedMessage
          id="workspace.trade.widget.trading-history.mode.positions.action.market.title"
          defaultMessage="Market"
        />
      </Button>
    )
  }

  return (
    <Popover.Root
      portalled
      lazyMount
      unmountOnExit
      positioning={{ placement: 'top-start' }}
    >
      <Popover.Context>
        {({ setOpen }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <Button
                  visual="secondary-grey"
                  loading={loading}
                  disabled={disabled}
                  css={{ w: '4.5rem' }}
                >
                  <FormattedMessage
                    id="workspace.trade.widget.trading-history.mode.positions.action.market.title"
                    defaultMessage="Market"
                  />
                </Button>
              </Popover.Trigger>

              <Portal>
                <Popover.Positioner>
                  <Popover.Content
                    css={{ borderRadius: 'r-16', p: 's-16', w: '21.125rem!' }}
                  >
                    <PopoverHeader>
                      <FormattedMessage
                        id="workspace.trade.widget.trading-history.mode.positions.market-close-popover.title"
                        defaultMessage="Market Close"
                      />
                    </PopoverHeader>

                    <Cell.Group css={{ textStyle: 'small', gap: 's-8' }}>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.trading-history.mode.positions.market-close-popover.info.order-size.title"
                            defaultMessage="Closing Order Size"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          {formatMarketAsset({
                            amount: position.size,
                            type: 'synthetic',
                          })}
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.trading-history.mode.positions.market-close-popover.info.order-price.title"
                            defaultMessage="Order Price"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          <FormattedMessage
                            id="common.price.market"
                            defaultMessage="Market"
                          />
                        </Cell.Value>
                      </Cell.Item>
                      <Cell.Item>
                        <Cell.Title>
                          <FormattedMessage
                            id="workspace.trade.widget.trading-history.mode.positions.market-close-popover.info.order-cost.title"
                            defaultMessage="Order Cost"
                          />
                        </Cell.Title>
                        <Cell.Value>
                          <MarketOrderCostValue
                            position={position}
                            triggeredOrders={triggeredOrders}
                            marketStats={marketStats}
                          />
                        </Cell.Value>
                      </Cell.Item>
                    </Cell.Group>

                    <Box css={{ my: 's-24' }}>
                      <Checkbox
                        checked={dontShowConfirmation}
                        onCheckedChange={(details) => {
                          setDontShowConfirmation(details.checked as boolean)
                        }}
                      >
                        <FormattedMessage
                          id="workspace.trade.widget.trading-history.mode.positions.market-close-popover.dont-show-checkbox.title"
                          defaultMessage="Don't show again"
                        />
                      </Checkbox>
                    </Box>

                    <Button
                      visual="primary-red"
                      size="large"
                      loading={loading}
                      disabled={disabled}
                      onClick={async () => {
                        await onConfirm()
                        setOpen(false)
                        setDontConfirmMarketClose(dontShowConfirmation)
                      }}
                    >
                      <FormattedMessage
                        id="workspace.trade.widget.trading-history.mode.positions.market-close-popover.cta.title"
                        defaultMessage="Close Position"
                      />
                    </Button>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
