import { useState } from 'react'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { css } from '@x10/lib-styled-system/css'
import { Flex, styled } from '@x10/lib-styled-system/jsx'
import { ActionIcon, Button, Input, SvgIcon } from '@x10/lib-ui-kit/components'

import type { AccountInfo } from '@src/domain/api/x10'
import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'
import { DEFAULT_ACCOUNT_COLLATERAL_CODE } from '@src/domain/trade/constants'

import { useAccountSelectorState } from './state'

export const EditAccountForm = ({ account }: { account: AccountInfo }) => {
  const { stopEdit } = useAccountSelectorState()
  const [editInputValue, setEditInputValue] = useState(account.description)
  const { formatMessage } = useIntl()
  return (
    <styled.form
      py="s-8"
      onSubmit={(e) => {
        e.preventDefault()
        stopEdit()
      }}
    >
      <Flex
        px="s-16"
        gap="s-16"
        className={css({
          '& label': {
            flex: '1 0 0',
          },
        })}
      >
        <Input
          name="name"
          label={formatMessage({
            id: 'workspace.trade.widget.account.header.account-selector.edit-account.input-label',
            defaultMessage: 'Name',
          })}
          value={editInputValue}
          quickAction={
            <ActionIcon
              type="button"
              onClick={() => {
                setEditInputValue('')
              }}
            >
              <SvgIcon.SvgIconClear />
            </ActionIcon>
          }
          onChange={(e) => setEditInputValue(e.target.value)}
        />
        <Flex py="s-8" gap="s-4" mixBlendMode="difference" flex="1 0 0" align="center">
          <CryptoLogo currency={DEFAULT_ACCOUNT_COLLATERAL_CODE} boxSize={24} />
          <styled.span color="dark.white">{DEFAULT_ACCOUNT_COLLATERAL_CODE}</styled.span>
        </Flex>
      </Flex>
      <Flex p="s-16" gap="s-16" justify="space-between">
        <Button
          type="button"
          visual="secondary-grey"
          onClick={() => {
            stopEdit()
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.header.account-selector.edit-account.cancel"
            defaultMessage="Cancel"
          />
        </Button>
        <Button type="submit" visual="primary-green">
          <FormattedMessage
            id="workspace.trade.widget.account.header.account-selector.edit-account.save"
            defaultMessage="Save"
          />
        </Button>
      </Flex>
    </styled.form>
  )
}
