import type { FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { HStack } from '@x10/lib-styled-system/jsx'

import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'

type PctOrderSizeProps = {
  buy: Decimal
  sell: Decimal
}

export const PctOrderSize: FC<PctOrderSizeProps> = ({ buy, sell }) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })

  return (
    <HStack
      css={{
        mb: 's-4',
        textStyle: 'small',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <FormattedMessage
          id="workspace.trade.widget.order-form.shared.money-input.buy.title"
          defaultMessage="Buy: {value}"
          values={{
            value: formatMarketAsset({ amount: buy, type: 'synthetic' }),
          }}
        />
      </div>

      <div>
        <FormattedMessage
          id="workspace.trade.widget.order-form.shared.money-input.sell.title"
          defaultMessage="Sell: {value}"
          values={{
            value: formatMarketAsset({ amount: sell, type: 'synthetic' }),
          }}
        />
      </div>
    </HStack>
  )
}
