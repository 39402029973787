import { create } from 'zustand'

import { type Decimal } from '@x10/lib-core/utils'

type NewOrderCostStoreState = {
  orderCost: {
    buy: Decimal
    sell: Decimal
  } | null
}

type NewOrderCostStoreActions = {
  setOrderCost: (
    value: {
      buy: Decimal
      sell: Decimal
    } | null,
  ) => void

  reset: () => void
}

type NewOrderCostStore = Readonly<NewOrderCostStoreState> & NewOrderCostStoreActions

const INITIAL_STATE: NewOrderCostStoreState = {
  orderCost: null,
}

export const useNewOrderCostStore = create<NewOrderCostStore>((set) => ({
  ...INITIAL_STATE,

  setOrderCost(value) {
    set({ orderCost: value })
  },

  reset() {
    set(INITIAL_STATE)
  },
}))
