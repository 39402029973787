import { useState, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { HStack } from '@x10/lib-styled-system/jsx'
import { Tabs } from '@x10/lib-ui-kit/components'

import { TradeWidget } from '../../components/trade-widget'
import { DepthTabContent } from './components/depth-tab-content'
import { FundingRateTabContent } from './components/funding-rate-tab-content'
import { MarginSchedule } from './components/margin-schedule'
import { MarketTabContent } from './components/market-tab-content'
import { TradingRules } from './components/trading-rules'
import { TradingViewChartQuickActions } from './components/trading-view-chart-quick-actions'

enum TradingChartTab {
  Market = 'MARKET',
  Depth = 'DEPTH',
  FundingRate = 'FUNDING_RATE',
}

export const TradingChart: FC = () => {
  const [activeTab, setActiveTab] = useState(TradingChartTab.Market)

  return (
    <Tabs.Root
      size="md"
      asChild
      lazyMount
      value={activeTab}
      onValueChange={({ value }) => setActiveTab(value as TradingChartTab)}
    >
      <TradeWidget.InnerRoot>
        <TradeWidget.Header
          css={{
            mb: 0,
          }}
        >
          <HStack gap="s-24">
            <Tabs.List>
              <Tabs.Trigger value={TradingChartTab.Market} css={{ textStyle: 'primary' }}>
                <FormattedMessage
                  id="workspace.trade.widget.chart.tab.market.title"
                  defaultMessage="Market"
                />
              </Tabs.Trigger>
              <Tabs.Trigger value={TradingChartTab.Depth} css={{ textStyle: 'primary' }}>
                <FormattedMessage
                  id="workspace.trade.widget.chart.tab.depth.title"
                  defaultMessage="Depth"
                />
              </Tabs.Trigger>
              <Tabs.Trigger
                value={TradingChartTab.FundingRate}
                css={{ textStyle: 'primary' }}
              >
                <FormattedMessage
                  id="workspace.trade.widget.chart.tab.funding-rate.title"
                  defaultMessage="Funding Rate"
                />
              </Tabs.Trigger>
            </Tabs.List>

            <TradingRules />
            <MarginSchedule />
          </HStack>

          <TradingViewChartQuickActions />
        </TradeWidget.Header>

        <Tabs.Content value={TradingChartTab.Market} asChild>
          <TradeWidget.ContentFixed>
            <MarketTabContent />
          </TradeWidget.ContentFixed>
        </Tabs.Content>

        <Tabs.Content value={TradingChartTab.Depth} asChild>
          <TradeWidget.ContentFixed>
            <DepthTabContent />
          </TradeWidget.ContentFixed>
        </Tabs.Content>

        <Tabs.Content value={TradingChartTab.FundingRate} asChild>
          <TradeWidget.ContentFixed>
            <FundingRateTabContent />
          </TradeWidget.ContentFixed>
        </Tabs.Content>
      </TradeWidget.InnerRoot>
    </Tabs.Root>
  )
}
