import { forwardRef } from 'react'

import { Flex, type FlexProps } from '@x10/lib-styled-system/jsx'

export const TradeWidgetContentFixed = forwardRef<HTMLDivElement, FlexProps>(
  (props: FlexProps, ref) => (
    <Flex
      data-scope="trade-widget"
      data-part="content-fixed"
      ref={ref}
      flexDirection="column"
      flex={1}
      overflow="hidden"
      {...props}
    />
  ),
)

TradeWidgetContentFixed.displayName = 'TradeWidgetContentFixed'
