import { useCallback, useEffect, useState, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, Flex, HStack, VStack } from '@x10/lib-styled-system/jsx'
import { ActionIcon, Checkbox, SvgIcon } from '@x10/lib-ui-kit/components'

import { useIsAccountBlocked } from '@src/domain/auth/hooks/use-client-status'
import type { TpSlEntrySide } from '@src/domain/core/types/common'
import type { OrderTpSlType } from '@src/domain/starkex/stark-perpetual-order'
import { OrderTpSlDialog } from '@src/domain/trade/components/order-tp-sl-dialog'
import type { OrderTriggerState } from '@src/domain/trade/types/common'

import { NewOrderStoreActions, useNewOrderStore } from '../../store/new-order'
import { TimeInForce } from './time-in-force'

type AdvancedSectionProps = {
  allowPostOnlyAndTimeInForce?: boolean
}

export const AdvancedSection: FC<AdvancedSectionProps> = ({
  allowPostOnlyAndTimeInForce,
}) => {
  const [tpSlDialogVisible, setTpSlDialogVisible] = useState(false)

  const postOnly = useNewOrderStore((state) => state.postOnly)
  const reduceOnly = useNewOrderStore((state) => state.reduceOnly)
  const timeInForce = useNewOrderStore((state) => state.timeInForce)
  const tpSlType = useNewOrderStore((state) => state.tpsl.tpSlType)
  const tp = useNewOrderStore((state) => state.tpsl.takeProfit)
  const sl = useNewOrderStore((state) => state.tpsl.stopLoss)

  const tpSlChecked = Boolean(tp || sl)

  const handleTpSlChange = (value: {
    tpSlEntrySide: TpSlEntrySide | undefined
    tpSlType: OrderTpSlType | undefined
    tp: OrderTriggerState | undefined | null
    sl: OrderTriggerState | undefined | null
  }) => {
    NewOrderStoreActions.setTpSlEntrySide(value.tpSlEntrySide)
    NewOrderStoreActions.setTpSlType(value.tpSlType)
    NewOrderStoreActions.setTakeProfit(value.tp)
    NewOrderStoreActions.setStopLoss(value.sl)
  }

  const resetTpSl = useCallback(() => {
    NewOrderStoreActions.setTpSlEntrySide(undefined)
    NewOrderStoreActions.setTpSlType(undefined)
    NewOrderStoreActions.setTakeProfit(undefined)
    NewOrderStoreActions.setStopLoss(undefined)
  }, [])

  const isAccountBlocked = useIsAccountBlocked()
  useEffect(() => {
    if (isAccountBlocked) {
      resetTpSl()
      NewOrderStoreActions.setReduceOnly(true)
    }
  }, [isAccountBlocked, resetTpSl])

  return (
    <Box css={{ w: '100%' }}>
      <VStack css={{ alignItems: 'start', mt: 's-8', gap: 's-4', display: 'flex' }}>
        <HStack css={{ w: '100%', justifyContent: 'space-between' }}>
          <Checkbox
            checked={tpSlChecked}
            disabled={reduceOnly}
            onClick={(e) => {
              // Prevent `onClick` to be called twice (label and input click)
              if (!(e.target instanceof HTMLInputElement)) {
                return
              }

              if (!tpSlChecked) {
                setTpSlDialogVisible(true)
              } else {
                resetTpSl()
              }
            }}
          >
            <FormattedMessage
              id="workspace.trade.widget.order-form.shared.advanced.input.tp-sp.title"
              defaultMessage="Take profit / Stop loss"
            />
          </Checkbox>

          {tpSlChecked && (
            <ActionIcon onClick={() => setTpSlDialogVisible(true)}>
              <SvgIcon.SvgIconPencil size={20} />
            </ActionIcon>
          )}
        </HStack>

        <Checkbox
          checked={reduceOnly}
          disabled={tpSlChecked || isAccountBlocked}
          onCheckedChange={(value) => {
            NewOrderStoreActions.setReduceOnly(value.checked === true)
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.order-form.shared.advanced.reduce-only.title"
            defaultMessage="Reduce-only"
          />
        </Checkbox>

        {allowPostOnlyAndTimeInForce && (
          <Flex css={{ w: '100%', alignItems: 'center' }}>
            <Box css={{ flex: 1 }}>
              <Checkbox
                disabled={timeInForce !== 'good-till-cancel'}
                checked={postOnly}
                onCheckedChange={(value) => {
                  NewOrderStoreActions.setPostOnly(value.checked === true)
                }}
              >
                <FormattedMessage
                  id="workspace.trade.widget.order-form.shared.input.post-only.title"
                  defaultMessage="Post-only"
                />
              </Checkbox>
            </Box>

            <TimeInForce />
          </Flex>
        )}
      </VStack>

      <OrderTpSlDialog
        open={tpSlDialogVisible}
        initiator="ORDER_FORM"
        value={{
          // Entry side to be always selected by user
          tpSlEntrySide: undefined,
          tpSlType,
          tp,
          sl,
        }}
        onValueChange={(value) => handleTpSlChange(value)}
        onClose={() => setTpSlDialogVisible(false)}
      />
    </Box>
  )
}
