import { Suspense, type FC, type PropsWithChildren } from 'react'

const ThrowError: FC = () => {
  throw new Error(
    'This suspense boundary should never be triggered. Please handle suspense in downstream components.',
  )
}

export const BlowupSuspenseBoundary = ({ children }: PropsWithChildren) => {
  return <Suspense fallback={<ThrowError />}>{children}</Suspense>
}
