import { Suspense, useState, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { getAssetUrl } from '@x10/lib-core/utils'
import { Box, Center, HStack } from '@x10/lib-styled-system/jsx'
import { LONG_MENU_MAX_HEIGHT } from '@x10/lib-styled-system/presets'
import {
  ActionIcon,
  Button,
  Cell,
  Logo,
  Popover,
  Portal,
  Scrollable,
  SearchInput,
  Spinner,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { useSuspendedCachedMarkets } from '@src/domain/api/hooks/markets-info/use-markets'
import type { MarketName } from '@src/domain/api/x10/common'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'

import type { CompareOrAddMode } from '../../trading-view-chart/types/common'

type MarketItemCellProps = {
  marketName: MarketName
  onClick: (market: MarketName, mode: CompareOrAddMode) => void
}

const MarketItemCell: FC<MarketItemCellProps> = ({ marketName, onClick }) => {
  const { syntheticCode } = parseMarketName(marketName)

  return (
    <Cell.Item
      className="group"
      css={{
        alignItems: 'center',
        color: 'token.white-50',
        py: 's-8',
        px: 's-16',
        _hover: { color: 'token.white', bg: 'token.white-10' },
      }}
    >
      <Cell.Title mr="s-16" whiteSpace="nowrap">
        <HStack css={{ gap: 's-8' }}>
          <Logo url={getAssetUrl({ type: 'crypto', name: syntheticCode })} />
          <div>{marketName}</div>
        </HStack>
      </Cell.Title>
      <Cell.Value>
        <HStack visibility="hidden" _groupHover={{ visibility: 'visible' }}>
          <Button
            whiteSpace="nowrap"
            visual="secondary-grey"
            onClick={() => onClick(marketName, 'same-pct-scale')}
          >
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.market.toolbar.compare-markets.cell.action.same-pct-scale.title"
              defaultMessage="Same % Scale"
            />
          </Button>
          <Button
            whiteSpace="nowrap"
            visual="secondary-grey"
            onClick={() => onClick(marketName, 'new-price-scale')}
          >
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.market.toolbar.compare-markets.cell.action.new-scale.title"
              defaultMessage="New Scale"
            />
          </Button>
          <Button
            whiteSpace="nowrap"
            visual="secondary-grey"
            onClick={() => onClick(marketName, 'new-pane')}
          >
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.market.toolbar.compare-markets.cell.action.new-pan.title"
              defaultMessage="New Pan"
            />
          </Button>
        </HStack>
      </Cell.Value>
    </Cell.Item>
  )
}

const popoverContentFallback = (
  <Center h="3rem">
    <Spinner />
  </Center>
)

const isMarketNameMatched = (marketName: MarketName, filter: string) => {
  return marketName.toLowerCase().startsWith(filter.toLowerCase())
}

type MarketListProps = {
  marketNameFilter: string
  onMarketSelected: (market: MarketName, mode: CompareOrAddMode) => void
}

const MarketsList: FC<MarketListProps> = ({ marketNameFilter, onMarketSelected }) => {
  const { data } = useSuspendedCachedMarkets()

  return (
    <Scrollable scrollY css={{ maxHeight: LONG_MENU_MAX_HEIGHT }}>
      <Cell.Group gap={0} textStyle="small">
        {data
          .filter((market) => isMarketNameMatched(market.name, marketNameFilter))
          .map((market) => (
            <MarketItemCell
              key={market.name}
              marketName={market.name}
              onClick={onMarketSelected}
            />
          ))}
      </Cell.Group>
    </Scrollable>
  )
}

type CompareMarketsProps = {
  onMarketSelected: (market: string, mode: CompareOrAddMode) => void
}

export const CompareMarkets: FC<CompareMarketsProps> = ({ onMarketSelected }) => {
  const [marketNameFilter, setMarketNameFilter] = useState('')

  return (
    <Popover.Root
      portalled
      lazyMount
      positioning={{ placement: 'top-start' }}
      onExitComplete={() => setMarketNameFilter('')}
    >
      <Popover.Context>
        {({ open, setOpen }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <ActionIcon active={open}>
                  <SvgIcon.SvgIconPlusCircle size={16} />
                </ActionIcon>
              </Popover.Trigger>
              <Portal>
                <Popover.Positioner>
                  <Popover.Content
                    borderRadius="r-16"
                    overflow="hidden"
                    minW="26rem"
                    w="auto"
                  >
                    <Box m="s-16" textStyle="caption">
                      <FormattedMessage
                        id="workspace.trade.widget.chart.tab.market.toolbar.compare-markets.title"
                        defaultMessage="Compare Markets"
                      />
                    </Box>

                    <Box m="s-16">
                      <SearchInput onChange={(value) => setMarketNameFilter(value)} />
                    </Box>

                    <Suspense fallback={popoverContentFallback}>
                      <MarketsList
                        marketNameFilter={marketNameFilter}
                        onMarketSelected={(market, mode) => {
                          onMarketSelected(market, mode)
                          setOpen(false)
                        }}
                      />
                    </Suspense>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
