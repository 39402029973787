import { type FC, type ReactNode } from 'react'
import NextLink from 'next/link'
import { usePathname } from 'next/navigation'

import { FormattedMessage } from '@x10/lib-core/i18n'
import {
  BottomNavigation as KitBottomNavigation,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { homeRoute } from '@src/domain/core/utils/routes'

type BottomNavigationProps = {
  actions?: ReactNode
}

export const BottomNavigation: FC<BottomNavigationProps> = ({ actions }) => {
  const pathname = usePathname()

  return (
    <KitBottomNavigation.Root actions={actions}>
      <KitBottomNavigation.Link
        url={homeRoute({}).portfolio({}).$}
        pathname={pathname}
        RouterLink={NextLink}
      >
        <KitBottomNavigation.Item icon={<SvgIcon.SvgIconLineChart />}>
          <FormattedMessage
            id="core.component.bottom-navigation.item.portfolio.title"
            defaultMessage="Portfolio"
          />
        </KitBottomNavigation.Item>
      </KitBottomNavigation.Link>

      <KitBottomNavigation.Link
        home
        url={homeRoute({}).trade({}).$}
        pathname={pathname}
        RouterLink={NextLink}
      >
        <KitBottomNavigation.Item icon={<SvgIcon.SvgIconTrade />}>
          <FormattedMessage
            id="core.component.bottom-navigation.item.trade.title"
            defaultMessage="Trade"
          />
        </KitBottomNavigation.Item>
      </KitBottomNavigation.Link>
    </KitBottomNavigation.Root>
  )
}
