import { FormattedMessage } from '@x10/lib-core/i18n'
import { Button, Dialog, Portal } from '@x10/lib-ui-kit/components'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'

import { TransferDialogContent } from './transfer-dialog-content'
import { useAccountState } from './use-account-state'

export const TransferButton = () => {
  const accounts = useAccounts()
  const { isAccountBlocked } = useAccountState()
  const isTransferAvailable = accounts.length > 1

  if (!isTransferAvailable) {
    return null
  }

  return (
    <Dialog.Root modal lazyMount unmountOnExit>
      <Dialog.Trigger asChild>
        <Button css={{ w: '100%' }} visual="secondary-grey" disabled={isAccountBlocked}>
          <FormattedMessage
            id="workspace.trade.widget.account.action.transfer.title"
            defaultMessage="Transfer"
          />
        </Button>
      </Dialog.Trigger>
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <TransferDialogContent />
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
