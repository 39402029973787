import { useCallback, useState } from 'react'

import { checkRequired, getLogger, type Decimal } from '@x10/lib-core/utils'

import { useFetchFees } from '@src/domain/api/hooks/account/use-fees/use-fees'
import { usePlaceOrder } from '@src/domain/api/hooks/order-management/use-place-order'
import type { UserPosition } from '@src/domain/api/x10'
import { AppErrorCode, X10AppError } from '@src/domain/core/errors/base'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import { StarkPerpetualOrder } from '@src/domain/starkex/stark-perpetual-order'
import { getOppositePositionSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'

import { useGetOrderCtx } from './use-get-order-ctx'

const LOGGER = getLogger('app-exchange.trade.use-close-position-with-limit-price')

export const useClosePositionWithLimitPrice = () => {
  const getOrderCtx = useGetOrderCtx()
  const fetchFees = useFetchFees()
  const getCachedMarket = useGetCachedMarket()
  const { placeOrderAsync } = usePlaceOrder()

  const [isClosingPosition, setClosingPosition] = useState(false)

  const closePositionWithLimitPrice = useCallback(
    async ({
      position,
      orderSize,
      orderPrice,
    }: {
      position: UserPosition
      orderSize: Decimal
      orderPrice: Decimal
    }) => {
      const side = toOrderSide(getOppositePositionSide(position.side))

      setClosingPosition(true)

      try {
        const market = getCachedMarket(position.market)
        const marketFees = await fetchFees({ marketsNames: [market.name] })

        const orderCtx = await getOrderCtx(
          market.l2Config,
          market.tradingConfig,
          checkRequired(marketFees?.[0], 'marketFees'),
        )

        const order = StarkPerpetualOrder.create({
          marketName: market.name,
          orderType: 'LIMIT',
          side,
          amountOfSynthetic: orderSize,
          price: orderPrice,
          timeInForce: 'GTT',
          expiryTime: undefined,
          reduceOnly: true,
          postOnly: false,
          fromPositionClose: true,
          ctx: orderCtx,
        })

        LOGGER.debug('Close position order = %o', order.toJSON())

        await placeOrderAsync({ order })
      } catch (error) {
        if (X10AppError.is(error, AppErrorCode.CantGetStarkPrivateKey)) {
          return
        }

        throw error
      } finally {
        setClosingPosition(false)
      }
    },
    [getOrderCtx, placeOrderAsync, getCachedMarket, fetchFees, setClosingPosition],
  )

  return { closePositionWithLimitPrice, isClosingPosition }
}
