import BN from 'bn.js'
import { assertInRange, pedersen } from '@starkware-industries/starkware-crypto-utils'

import type { HexString } from '@x10/lib-core/types'

// 2^64.
const Bn64 = new BN('10000000000000000', 16)

// 2^32.
const Bn32 = new BN('100000000', 16)

// 2^250.
const Bn250 = new BN(
  '400000000000000000000000000000000000000000000000000000000000000',
  16,
)

const Bn0 = new BN('0', 16)

// For more info about the payload etc. look here:
// https://docs.starkware.co/starkex/perpetual/signature_construction_perpetual.html#withdrawaltoaddress
const WithdrawalV2 = '7'

export function getWithdrawalMsgHash({
  collateralAssetId,
  ethAddress,
  positionId,
  nonce,
  expirationTimestamp,
  amount,
}: {
  collateralAssetId: HexString
  ethAddress: HexString
  positionId: number
  nonce: number
  expirationTimestamp: number
  amount: string
}) {
  const collateralAssetIdBn = new BN(collateralAssetId.substring(2), 16)
  const ethAddressBn = new BN(ethAddress.substring(2), 16)
  const positionIdBn = new BN(positionId, 10)
  const nonceBn = new BN(nonce, 10)
  const amountBn = new BN(amount, 10)
  const expirationTimestampBn = new BN(expirationTimestamp, 10)
  // 0 <= assetIdCollateral < 2^250
  assertInRange(collateralAssetIdBn, Bn0, Bn250, 'collateralAssetId')

  // 0 <= ethAddress < 2^250
  assertInRange(ethAddressBn, Bn0, Bn250, 'ethAddress')

  // 0 <= nonce < 2^32
  assertInRange(nonceBn, Bn0, Bn32, 'nonce')

  // 0 <= positionId < 2^64
  assertInRange(positionIdBn, Bn0, Bn64, 'positionId')

  // 0 <= expirationTimestamp < 2^32
  assertInRange(expirationTimestampBn, Bn0, Bn32, 'expirationTimestamp')

  // 0 <= amount < 2^64
  assertInRange(amountBn, Bn0, Bn64, 'amount')

  let packedMsg = new BN(WithdrawalV2, 10)
  packedMsg = packedMsg.ushln(64).add(positionIdBn)
  packedMsg = packedMsg.ushln(32).add(nonceBn)
  packedMsg = packedMsg.ushln(64).add(amountBn)
  packedMsg = packedMsg.ushln(32).add(expirationTimestampBn)
  packedMsg = packedMsg.ushln(49)
  return pedersen([
    pedersen([collateralAssetId.substring(2), ethAddress.substring(2)]),
    packedMsg.toString(16),
  ])
}
