import { useEffect, useMemo } from 'react'
import { create } from 'zustand'

import { Decimal } from '@x10/lib-core/utils'

import { useSuspendedWithdrawals } from '@src/domain/api/hooks/account/use-withdrawals'
import { isUserRejectedRequestError } from '@src/domain/core/errors/base'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import {
  createNotificationToast,
  dismissNotificationToast,
} from '@src/domain/core/ui/components/notification'
import { GlobalToastIds } from '@src/domain/core/ui/components/notification/notification-toast'
import { usePendingWithdrawalBalance } from '@src/domain/starkex/hooks/use-pending-withdrawal-balance'
import { useWithdraw } from '@src/domain/starkex/hooks/use-withdraw'
import { fromSmartContractUnits } from '@src/domain/starkex/utils/smart-contract'

const WITHDRAWAL_NOTIFICATION_DURATION = 30_000

export const useWithdrawalStore = create<{
  isWritingContractPending?: boolean
  isPendingWithdrawalAvailable?: boolean
}>(() => ({}))

export const useWithdrawalState = () => {
  const isFeatureEnabled = useIsFeatureEnabled()
  const { withdraw, isWritingContractPending } = useWithdraw()

  /* eslint-disable react-hooks/rules-of-hooks -- it's ok as flags are stable */
  if (isFeatureEnabled('ACCOUNT_DEPOSIT_WITHDRAW_ACTIONS')) {
    const { data: withdrawalBalance, isLoading: isPendingWithdrawalBalanceLoading } =
      usePendingWithdrawalBalance()
    const withdrawals = useSuspendedWithdrawals()

    const readyForClaimWithdrawals = useMemo(() => {
      return withdrawals.filter((withdrawal) => withdrawal.status === 'READY_FOR_CLAIM')
    }, [withdrawals])

    const isPendingWithdrawalAvailable =
      !isPendingWithdrawalBalanceLoading &&
      withdrawalBalance !== 0n &&
      withdrawalBalance !== undefined

    const withdrawalBalanceDecimal = useMemo(
      () =>
        withdrawalBalance ? fromSmartContractUnits(withdrawalBalance) : Decimal.ZERO,
      [withdrawalBalance],
    )

    useEffect(() => {
      if (isPendingWithdrawalAvailable) {
        createNotificationToast({
          id: GlobalToastIds.PendingWithdrawal,
          duration: WITHDRAWAL_NOTIFICATION_DURATION,
          notification: {
            type: 'PENDING_WITHDRAWAL',
            amount: withdrawalBalanceDecimal,
            onClaimClick: async () => {
              try {
                await withdraw()
              } catch (e) {
                if (!isUserRejectedRequestError(e)) {
                  throw e
                }
              }
            },
          },
        })
      } else {
        dismissNotificationToast(GlobalToastIds.PendingWithdrawal)
      }
    }, [
      isPendingWithdrawalAvailable,
      withdrawalBalanceDecimal,
      withdraw,
      isFeatureEnabled,
    ])

    useEffect(() => {
      useWithdrawalStore.setState({
        isWritingContractPending,
        isPendingWithdrawalAvailable,
      })
    }, [isWritingContractPending, isPendingWithdrawalAvailable])

    return {
      isPendingWithdrawalAvailable,
      isPendingWithdrawalBalanceLoading,
      readyForClaimWithdrawals,
      withdraw,
      isWritingContractPending,
    }
  }
  /* eslint-enable react-hooks/rules-of-hooks */

  return {
    isPendingWithdrawalBalanceLoading: false,
    readyForClaimWithdrawals: [],
    withdraw,
  }
}
