import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'

import { type Decimal } from '@x10/lib-core/utils'

import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import type { CachedMarket } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import type { TpSlEntrySide } from '@src/domain/core/types/common'
import type {
  OrderPriceType,
  OrderTpSlType,
  OrderTriggerPriceType,
} from '@src/domain/starkex/stark-perpetual-order'
import type { TpSlDialogInitiator } from '@src/domain/trade/components/order-tp-sl-dialog/types'
import { useNewOrderStore } from '@src/domain/trade/ui/widgets/order-form/store/new-order'

import { useTpSlFormValidation, type HookResult } from './use-tp-sl-form-validation'

type FormData = {
  tpSlType: OrderTpSlType
  tpSlSide: TpSlEntrySide

  tpChecked: boolean
  tpTriggerPrice?: Decimal | null
  tpTriggerPriceType?: OrderTriggerPriceType
  tpOrderPrice?: Decimal | null
  tpOrderPriceType?: OrderPriceType

  slChecked: boolean
  slTriggerPrice?: Decimal | null
  slTriggerPriceType?: OrderTriggerPriceType
  slOrderPrice?: Decimal | null
  slOrderPriceType?: OrderPriceType
}

const VALIDATION_WAIT = 150
const VALIDATION_MAX_WAIT = 1000

export const useTpSlValidationCtx = (
  initiator: TpSlDialogInitiator,
  market: CachedMarket,
) => {
  const orderType = useNewOrderStore((state) => state.orderType)
  const buyPrice = useNewOrderStore((state) => state.buy.price)
  const sellPrice = useNewOrderStore((state) => state.sell.price)

  const { data: marketStats } = useMarketStats({ marketName: market.name })
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })

  const minPriceChange = formatMarketAsset({
    amount: market.tradingConfig.minPriceChange,
    type: 'collateral',
  })

  return useMemo(
    () => ({
      initiator,
      market,
      marketStats,
      minPriceChange,
      orderType,
      buyPrice,
      sellPrice,
    }),
    [initiator, market, marketStats, minPriceChange, orderType, buyPrice, sellPrice],
  )
}

export const useTpSlFormValidationWithCtx = (
  initiator: TpSlDialogInitiator,
  market: CachedMarket,
  formData: FormData,
) => {
  const [errors, setErrors] = useState<HookResult>()

  const form = useTpSlFormValidation()
  const ctx = useTpSlValidationCtx(initiator, market)

  const validate = useMemo(() => {
    return debounce(
      (formData: FormData, ctx: ReturnType<typeof useTpSlValidationCtx>) => {
        const errors = form.validate(formData, ctx)

        setErrors(errors)
      },
      VALIDATION_WAIT,
      {
        trailing: true,
        maxWait: VALIDATION_MAX_WAIT,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.validate, setErrors])

  useEffect(() => {
    validate(formData, ctx)
  }, [formData, ctx, validate])

  const tpTriggerPriceError = form.isTouched.tpTriggerPrice
    ? (errors?.tpTriggerPrice ?? errors?.tpTriggerPrice)
    : undefined
  const tpOrderPriceError = form.isTouched.tpOrderPrice
    ? (errors?.tpOrderPrice ?? errors?.tpOrderPrice)
    : undefined
  const slTriggerPriceError = form.isTouched.slTriggerPrice
    ? (errors?.slTriggerPrice ?? errors?.slTriggerPrice)
    : undefined
  const slOrderPriceError = form.isTouched.slOrderPrice
    ? (errors?.slOrderPrice ?? errors?.slOrderPrice)
    : undefined

  const isValid = !errors

  return {
    form,
    isValid,
    tpTriggerPriceError,
    tpOrderPriceError,
    slTriggerPriceError,
    slOrderPriceError,
  }
}
