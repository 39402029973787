import { useMemo, type ReactNode } from 'react'
import { type ColumnDef } from '@tanstack/react-table'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { type Long } from '@x10/lib-core/utils'
import { Box } from '@x10/lib-styled-system/jsx'
import { Indicator, Link, TableCell, ValueColored } from '@x10/lib-ui-kit/components'

import { type UserOrder, type UserPosition } from '@src/domain/api/x10'
import { useFormatLeverage } from '@src/domain/core/hooks/use-format-leverage'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'
import { useFormatUsd } from '@src/domain/core/hooks/use-format-usd'
import { NowrapText } from '@src/domain/core/ui/components/nowrap-text'
import { OrderSideText } from '@src/domain/core/ui/components/order-side-text'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import { calcPnlPct } from '@src/domain/trade/utils/calc/calc-pnl-pct'
import { calcPositionMargin } from '@src/domain/trade/utils/calc/calc-position-margin'

import { ActionableMarketTableCell } from '../components/actionable-market-table-cell'
import { HeaderTooltip } from '../components/header-tooltip'
import { HeaderCloseAllPositionsAction } from './components/header-close-all-positions-action'
import { PositionActionsCell } from './components/position-actions-cell'
import { PositionTpSlCell } from './components/position-tp-sl-cell'
import type { PositionData } from './hooks/use-positions-data'

export const useColumns = ({
  onTpSlEdit,
}: {
  onTpSlEdit: (positionId: Long) => void
}) => {
  const { formatMessage } = useIntl()

  const formatMarketAsset = useFormatMarketAsset()
  const formatLeverage = useFormatLeverage()
  const formatPercent = useFormatPercent()
  const formatUsd = useFormatUsd()

  return useMemo<Array<ColumnDef<PositionData>>>(
    () => [
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.positions.column.market.title',
          defaultMessage: 'Market',
        }),
        accessorKey: 'position.market',
        size: 100,
        cell: (info) => {
          const marketName = info.getValue<UserPosition['market']>()

          return <ActionableMarketTableCell market={marketName} />
        },
      },
      {
        id: 'side_leverage',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.positions.column.side.title',
          defaultMessage: 'Side',
        }),
        size: 70,
        accessorFn: (row) => [row.position.side, row.position.leverage],
        cell: (info) => {
          const [side, leverage] =
            info.getValue<[UserPosition['side'], UserPosition['leverage']]>()

          return (
            <TableCell>
              <OrderSideText side={side} />
              <Box>{formatLeverage(leverage.toNumber())}</Box>
            </TableCell>
          )
        },
      },
      {
        id: 'size_value',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.size-value.tooltip',
              defaultMessage:
                'Position value is calculated as the position size multiplied by the mark price.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.size-value.title"
                defaultMessage="Size/Value"
              />
            </Box>
          </HeaderTooltip>
        ),
        accessorFn: (row) => [row.position.market, row.position.size, row.position.value],
        cell: (info) => {
          const [marketName, size, value] =
            info.getValue<
              [UserPosition['market'], UserPosition['size'], UserPosition['value']]
            >()

          return (
            <TableCell>
              <NowrapText>
                {formatMarketAsset(
                  {
                    amount: size,
                    type: 'synthetic',
                  },
                  { marketName, showSymbol: true },
                )}
              </NowrapText>
              <NowrapText>
                {formatMarketAsset(
                  {
                    amount: value,
                    type: 'collateral',
                  },
                  { marketName, showSymbol: true },
                )}
              </NowrapText>
            </TableCell>
          )
        },
      },
      {
        id: 'open_price',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.entry-price.tooltip',
              defaultMessage: 'The weighted average entry price of your positions.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.entry-price.title"
                defaultMessage="Entry Price"
              />
            </Box>
          </HeaderTooltip>
        ),
        accessorFn: (row) => [row.position.market, row.position.openPrice],
        cell: (info) => {
          const [marketName, openPrice] =
            info.getValue<[UserPosition['market'], UserPosition['openPrice']]>()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: openPrice,
                  type: 'collateral',
                },
                { marketName },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'mark_price',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.positions.column.mark-price.title',
          defaultMessage: 'Mark Price',
        }),
        accessorFn: (row) => [row.position.market, row.position.markPrice],
        cell: (info) => {
          const [marketName, markPrice] =
            info.getValue<[UserPosition['market'], UserPosition['markPrice']]>()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: markPrice,
                  type: 'collateral',
                },
                { marketName },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'liq_price',
        header: () => (
          <HeaderTooltip
            interactive
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.liq-price.tooltip',
              defaultMessage:
                "The Liquidation Price is the position's Mark price at which the account Margin Ratio exceeds 100%, indicating that the liquidation process should begin. It's important to note that the Liquidation Price serves as a reference number, and actual liquidation occurs only when the Margin Ratio exceeds 100%.\n\nFor more information, please refer to the <a>documentation</a>.",
              values: {
                a: (chunks: ReactNode) => {
                  return (
                    <Link
                      href={asExternalRoute(
                        documentationRoute({})
                          .trading({})
                          .liquidationAndBankruptcyPrices({}).$,
                      )}
                      external
                    >
                      {chunks}
                    </Link>
                  )
                },
              },
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.liq-price.title"
                defaultMessage="Liq. Price"
              />
            </Box>
          </HeaderTooltip>
        ),
        accessorFn: (row) => [row.position.market, row.position.liquidationPrice],
        cell: (info) => {
          const [marketName, liquidationPrice] =
            info.getValue<[UserPosition['market'], UserPosition['liquidationPrice']]>()
          const formattedValue = liquidationPrice?.gt(0)
            ? formatMarketAsset(
                {
                  amount: liquidationPrice,
                  type: 'collateral',
                },
                { marketName },
              )
            : null

          return <TableCell>{formattedValue}</TableCell>
        },
      },
      {
        id: 'margin',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.margin.tooltip',
              defaultMessage:
                'Position margin = abs( Position size * Mark price * 1/Leverage )',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.maring.title"
                defaultMessage="Margin"
              />
            </Box>
          </HeaderTooltip>
        ),
        accessorFn: (row) => [
          row.position.market,
          row.position.size,
          row.position.markPrice,
          row.position.leverage,
        ],
        cell: (info) => {
          const [marketName, positionSize, markPrice, leverage] =
            info.getValue<
              [
                UserPosition['market'],
                UserPosition['size'],
                UserPosition['markPrice'],
                UserPosition['leverage'],
              ]
            >()

          return (
            <TableCell>
              {formatMarketAsset(
                {
                  amount: calcPositionMargin({
                    positionSize,
                    markPrice,
                    leverage,
                  }),
                  type: 'collateral',
                },
                { marketName, showSymbol: true },
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'unrealized_pnl',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.unrealized-pnl.tooltip',
              defaultMessage:
                'Unrealised PnL = Position Size * (Mark Price - Entry Price)',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.unrealized-pnl.title"
                defaultMessage="U. PnL"
              />
            </Box>
          </HeaderTooltip>
        ),
        accessorFn: (row) => [
          row.position.size,
          row.position.markPrice,
          row.position.leverage,
          row.position.unrealisedPnl,
        ],
        cell: (info) => {
          const [positionSize, markPrice, leverage, unrealizedPnl] =
            info.getValue<
              [
                UserPosition['size'],
                UserPosition['markPrice'],
                UserPosition['leverage'],
                UserPosition['unrealisedPnl'],
              ]
            >()

          const unrealizedPnlPct = calcPnlPct(
            unrealizedPnl,
            calcPositionMargin({
              positionSize,
              markPrice,
              leverage,
            }),
          )

          return (
            <TableCell>
              <ValueColored>{formatUsd(unrealizedPnl)}</ValueColored>
              <ValueColored>{formatPercent(unrealizedPnlPct)}</ValueColored>
            </TableCell>
          )
        },
      },
      {
        id: 'realized_pnl',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.realized-pnl.tooltip',
              defaultMessage:
                'The sum of realised position PnL, trading fees, and funding fees.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.realized-pnl.title"
                defaultMessage="R. PnL"
              />
            </Box>
          </HeaderTooltip>
        ),

        accessorFn: (row) => [
          row.position.size,
          row.position.markPrice,
          row.position.leverage,
          row.position.realisedPnl,
        ],
        cell: (info) => {
          const [positionSize, markPrice, leverage, realizedPnl] =
            info.getValue<
              [
                UserPosition['size'],
                UserPosition['markPrice'],
                UserPosition['leverage'],
                UserPosition['realisedPnl'],
              ]
            >()

          const realizedPnlPct = calcPnlPct(
            realizedPnl,
            calcPositionMargin({
              positionSize,
              markPrice,
              leverage,
            }),
          )

          return (
            <TableCell>
              <ValueColored>{formatUsd(realizedPnl)}</ValueColored>
              <ValueColored>{formatPercent(realizedPnlPct)}</ValueColored>
            </TableCell>
          )
        },
      },
      {
        id: 'take_profit_stop_loss',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.tp-sl.tooltip',
              defaultMessage:
                'You can set, edit, and cancel take profit and stop-loss orders for open positions here.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.tp-sl.title"
                defaultMessage="TP/SL"
              />
            </Box>
          </HeaderTooltip>
        ),
        accessorFn: (row) => [row.position, row.orders],
        cell: (info) => {
          const [position, orders] = info.getValue<[UserPosition, UserOrder[]]>()

          return (
            <PositionTpSlCell
              position={position}
              orders={orders}
              onTpSlEdit={onTpSlEdit}
            />
          )
        },
      },
      {
        header: () => (
          <HeaderTooltip
            interactive
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.positions.column.adl.tooltip',
              defaultMessage:
                'The bars below represent the likelihood of your position being auto-deleveraged against the liquidated position at the Bankruptcy Price of the Liquidated Position. The more green bars, the higher the likelihood. If your position is selected for ADL, all open orders in that market are canceled, while open orders for other markets remain unaffected.\n\nFor more information, please refer to the <a>documentation</a>.',
              values: {
                a: (chunks: ReactNode) => {
                  return (
                    <Link
                      href={asExternalRoute(
                        documentationRoute({}).trading({}).autoDeleveraging({}).$,
                      )}
                      external
                    >
                      {chunks}
                    </Link>
                  )
                },
              },
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.adl.title"
                defaultMessage="ADL"
              />
            </Box>
          </HeaderTooltip>
        ),
        accessorKey: 'position.adl',
        cell: (info) => {
          const value = info.getValue<UserPosition['adl']>()

          return <Indicator value={value ?? 0} max={4} />
        },
      },
      {
        id: 'actions',
        header: ({ table }) => (
          <HeaderCloseAllPositionsAction rows={table.getRowModel().rows} />
        ),
        size: 50,
        cell: (info) => {
          return (
            <PositionActionsCell
              position={info.row.original.position}
              orders={info.row.original.orders}
              marketStats={info.row.original.marketStats}
            />
          )
        },
      },
    ],
    [
      formatLeverage,
      formatMarketAsset,
      formatUsd,
      formatMessage,
      formatPercent,
      onTpSlEdit,
    ],
  )
}
