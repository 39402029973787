export const calcBidAskRatio = (
  bidSizeSum: number | undefined,
  askSizeSum: number | undefined,
) => {
  if (!bidSizeSum && !askSizeSum) {
    return { bid: 0, ask: 0 }
  }

  if (!bidSizeSum) {
    return { bid: 0, ask: 1 }
  }

  if (!askSizeSum) {
    return { bid: 1, ask: 0 }
  }

  const bidRatio = bidSizeSum / (bidSizeSum + askSizeSum)

  return { bid: bidRatio, ask: 1 - bidRatio }
}
