import { useState } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, Flex, HStack } from '@x10/lib-styled-system/jsx'
import { FilterPeriodValue } from '@x10/lib-ui-kit/components'

import { NavigationLink } from '@src/domain/core/ui/components/navigation-link'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import { useUpdatedFundingRates } from '@src/domain/trade/hooks/use-updated-funding-rates'
import { type FundingRateInterval } from '@src/domain/trade/types/funding-rate'
import { FundingRateFilterInterval } from '@src/domain/trade/ui/components/funding-rate/funding-rate-filter-interval'
import { FundingRateFilterPeriod } from '@src/domain/trade/ui/components/funding-rate/funding-rate-filter-period'
import { FundingRateLineChart } from '@src/domain/trade/ui/components/funding-rate/funding-rate-line-chart'
import { FundingRateValue } from '@src/domain/trade/ui/components/funding-rate/funding-rate-value'

export const FundingRateTabContent = () => {
  const [selectedPeriod, setSelectedPeriod] = useState(FilterPeriodValue.OneDay)
  const [selectedInterval, setSelectedInterval] = useState<FundingRateInterval>('1H')

  const { data, isFetching } = useUpdatedFundingRates(selectedPeriod, selectedInterval)

  const lastFundingRate = data[0] // data is sorted in descending order by timestamp

  return (
    <>
      <HStack mt="s-8" mx="s-16" gap="s-16" textStyle="caption">
        <HStack gap="s-4">
          <Box>
            <FormattedMessage
              id="workspace.trade.widget.chart.tab.funding-rate.stat.funding-rate.title"
              defaultMessage="Funding Rate"
            />
            :
          </Box>
          <FundingRateValue fundingRate={lastFundingRate?.fundingRate} />
        </HStack>
      </HStack>

      <Box flex={1} overflow="hidden">
        <FundingRateLineChart
          data={data}
          isLoading={isFetching}
          period={selectedPeriod}
        />
      </Box>

      <Flex
        px="s-16"
        py="s-4"
        textStyle="small"
        borderTopColor="token.white-5"
        borderTopWidth={1}
        justifyContent="space-between"
      >
        <HStack css={{ fontSize: 'fs-14', gap: 's-24' }}>
          <FundingRateFilterPeriod onChange={setSelectedPeriod} />

          <FundingRateFilterInterval
            value={selectedInterval}
            onChange={setSelectedInterval}
          />
        </HStack>

        <NavigationLink
          url={asExternalRoute(documentationRoute({}).trading({}).fundingPayments({}).$)}
          openInNewTab
        >
          <FormattedMessage
            id="workspace.trade.widget.chart.tab.funding-rate.toolbar.documentation.title"
            defaultMessage="Documentation"
          />
        </NavigationLink>
      </Flex>
    </>
  )
}
