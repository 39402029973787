import { type Decimal } from '@x10/lib-core/utils'

import { omitUndefined } from '../utils/omit-undefined'
import {
  type OrderPriceType,
  type OrderTriggerDirection,
  type OrderTriggerPriceType,
} from './types'

export class OrderConditionalTrigger {
  private readonly triggerPrice: Decimal
  private readonly triggerPriceType: OrderTriggerPriceType
  private readonly direction: OrderTriggerDirection
  private readonly executionPriceType: OrderPriceType

  constructor({
    triggerPrice,
    triggerPriceType,
    direction,
    executionPriceType,
  }: {
    triggerPrice: Decimal
    triggerPriceType: OrderTriggerPriceType
    direction: OrderTriggerDirection
    executionPriceType: OrderPriceType
  }) {
    this.triggerPrice = triggerPrice
    this.triggerPriceType = triggerPriceType
    this.direction = direction
    this.executionPriceType = executionPriceType
  }

  toJSON() {
    return omitUndefined({
      triggerPrice: this.triggerPrice.toString(10),
      triggerPriceType: this.triggerPriceType,
      direction: this.direction,
      executionPriceType: this.executionPriceType,
    })
  }
}
