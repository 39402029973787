import React, { useCallback, useMemo } from 'react'
import { type ColumnDef } from '@tanstack/react-table'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import {
  Button,
  DateTimeTableCell,
  EditableValue,
  HoverCard,
  IndicatorProgress,
  Portal,
  TableCell,
} from '@x10/lib-ui-kit/components'

import { useCancelOrder } from '@src/domain/api/hooks/order-management/use-cancel-order'
import { useMassCancelOrder } from '@src/domain/api/hooks/order-management/use-mass-cancel-order'
import { type UserOrder } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { OrderSideColored } from '@src/domain/core/ui/components/order-side-colored'
import { OrderSideText } from '@src/domain/core/ui/components/order-side-text'
import { getOrderStatusIntlKey } from '@src/domain/i18n/utils/get-order-status-intl-key'
import { getOrderTypeIntlKey } from '@src/domain/i18n/utils/get-order-type-intl-key'
import { HeaderTooltip } from '@src/domain/trade/ui/widgets/trading-history/components/header-tooltip'

import { ActionableMarketTableCell } from './components/actionable-market-table-cell'
import { OrderPriceCell } from './components/order-price-cell'
import { OrderTriggerPriceCell } from './components/order-trigger-price-cell'
import { TpSlValues } from './components/tp-sl-values'

export const useColumns = () => {
  const { formatMessage } = useIntl()

  const formatMarketAsset = useFormatMarketAsset()
  const isFeatureEnabled = useIsFeatureEnabled()

  const { massCancelOrder, isMassCancelling } = useMassCancelOrder()

  const handleEdit = useCallback(() => {}, [])

  return useMemo<Array<ColumnDef<UserOrder>>>(
    () => [
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders.column.market.title',
          defaultMessage: 'Market',
        }),
        accessorKey: 'market',
        size: 100,
        cell: (info) => {
          const marketName = info.getValue<UserOrder['market']>()

          return <ActionableMarketTableCell market={marketName} />
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders.column.status.title',
          defaultMessage: 'Status',
        }),
        size: 70,
        accessorKey: 'status',
        cell: (info) => {
          const status = info.getValue<UserOrder['status']>()

          return (
            <TableCell>
              <FormattedMessage {...getOrderStatusIntlKey(status)} />
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders.column.order-type.title',
          defaultMessage: 'Order Type',
        }),
        size: 70,
        accessorKey: 'type',
        cell: (info) => {
          const orderType = info.getValue<UserOrder['type']>()

          return (
            <TableCell>
              <FormattedMessage {...getOrderTypeIntlKey(orderType)} />
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders.column.side.title',
          defaultMessage: 'Side',
        }),
        size: 70,
        accessorKey: 'side',
        cell: (info) => {
          const side = info.getValue<UserOrder['side']>()

          return (
            <TableCell>
              <OrderSideText side={side} />
            </TableCell>
          )
        },
      },
      {
        id: 'trigger_price',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.orders.column.trigger-price.tooltip',
              defaultMessage:
                'When the trigger price is reached, the conditional order is placed in the order book at the order price.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.orders.column.trigger-price.title"
                defaultMessage="Trigger Price"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        cell: (info) => {
          return <OrderTriggerPriceCell order={info.row.original} />
        },
      },
      {
        id: 'order_price',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders.column.order-price.title',
          defaultMessage: 'Order Price',
        }),
        size: 70,
        cell: (info) => {
          return <OrderPriceCell order={info.row.original} />
        },
      },
      {
        id: 'order_size',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders.column.order-size.title',
          defaultMessage: 'Order Size',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.qty, row.side, row.type, row.tpSlType],
        cell: (info) => {
          const [marketName, qty, side, orderType, tpSlType] =
            info.getValue<
              [
                UserOrder['market'],
                UserOrder['qty'],
                UserOrder['side'],
                UserOrder['type'],
                UserOrder['tpSlType'],
              ]
            >()
          const formattedQty =
            orderType === 'TPSL' && tpSlType === 'POSITION'
              ? formatMessage({
                  id: 'workspace.trade.widget.trading-history.mode.orders.column.order-size.entire-position-value.title',
                  defaultMessage: 'Entire Position',
                })
              : formatMarketAsset(
                  {
                    amount: qty,
                    type: 'synthetic',
                  },
                  { marketName, showSymbol: true },
                )

          return (
            <TableCell>
              {isFeatureEnabled('EDITABLE_TABLE_CELL') ? (
                <EditableValue onEdit={handleEdit}>
                  <OrderSideColored side={side}>{formattedQty}</OrderSideColored>
                </EditableValue>
              ) : (
                <OrderSideColored side={side}>{formattedQty}</OrderSideColored>
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'filled',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.orders.column.filled.tooltip',
              defaultMessage:
                'Filled represents the share of the order size that has been executed.',
            }}
          >
            <FormattedMessage
              id="workspace.trade.widget.trading-history.mode.orders.column.filled.title"
              defaultMessage="Filled"
            />
          </HeaderTooltip>
        ),
        size: 70,
        accessorFn: (row) => [row.status, row.filledQty, row.qty],
        cell: (info) => {
          const [status, filledQty, qty] =
            info.getValue<
              [UserOrder['status'], UserOrder['filledQty'], UserOrder['qty']]
            >()

          const [progressValue, progressMax] =
            status === 'UNTRIGGERED'
              ? [0, 1]
              : [filledQty?.toNumber() ?? 0, qty.toNumber()]

          return (
            <TableCell>
              <IndicatorProgress min={0} max={progressMax} value={progressValue} />
            </TableCell>
          )
        },
      },
      {
        id: 'tp_sl_actions',
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.orders.column.tp-sl.tooltip',
              defaultMessage:
                'You can set, edit, and cancel take profit and stop-loss orders for your open orders here.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.orders.column.tp-sl.title"
                defaultMessage="TP/SL"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorFn: (row) => [row.market, row.takeProfit, row.stopLoss, row.type],
        cell: (info) => {
          const [market, takeProfit, stopLoss, orderType] =
            info.getValue<
              [
                UserOrder['market'],
                UserOrder['takeProfit'],
                UserOrder['stopLoss'],
                UserOrder['type'],
              ]
            >()

          const tpTriggerPrice =
            orderType === 'TPSL' ? undefined : takeProfit?.triggerPrice
          const slTriggerPrice = orderType === 'TPSL' ? undefined : stopLoss?.triggerPrice

          return (
            <TableCell>
              <TpSlValues
                marketName={market}
                takeProfit={tpTriggerPrice}
                stopLoss={slTriggerPrice}
              />
            </TableCell>
          )
        },
      },
      {
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.orders.column.reduce-only.tooltip',
              defaultMessage:
                'A reduce-only order is a type of order that can only reduce or close out an existing open position. It is designed to prevent the order from increasing the size of the position. If the reduce-only order would increase the position size, it will be either partially executed or fully canceled.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.orders.column.reduce-only.title"
                defaultMessage="Reduce only"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorKey: 'reduceOnly',
        cell: (info) => {
          const reduceOnly = info.getValue<UserOrder['reduceOnly']>()

          return (
            <TableCell>
              {reduceOnly ? (
                <FormattedMessage
                  id="workspace.trade.widget.trading-history.mode.orders-history.column.reduce-only.yes.value"
                  defaultMessage="Yes"
                />
              ) : (
                <FormattedMessage
                  id="workspace.trade.widget.trading-history.mode.orders-history.column.reduce-only.no.value"
                  defaultMessage="No"
                />
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'time',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders.column.time.title',
          defaultMessage: 'Time',
        }),
        size: 70,
        accessorFn: (row) => [row.createdTime, row.expiryTime],
        cell: (info) => {
          const [createTime, expireTime] =
            info.getValue<[UserOrder['createdTime'], UserOrder['expiryTime']]>()

          return (
            <HoverCard.Root>
              <HoverCard.Trigger asChild>
                <DateTimeTableCell value={createTime} />
              </HoverCard.Trigger>

              <Portal>
                <HoverCard.Positioner>
                  <HoverCard.Content>
                    {expireTime ? (
                      <FormattedMessage
                        id="workspace.trade.widget.trading-history.mode.orders.column.time.hint.with-expire.title"
                        defaultMessage="Valid till {{expireTime}}"
                        values={{ expireTime }}
                      />
                    ) : (
                      <FormattedMessage
                        id="workspace.trade.widget.trading-history.mode.orders.column.time.hint.without-expire.title"
                        defaultMessage="Good Till Cancel"
                      />
                    )}
                  </HoverCard.Content>
                </HoverCard.Positioner>
              </Portal>
            </HoverCard.Root>
          )
        },
      },
      {
        id: 'actions',
        header: (ctx) => {
          const handleCancelAll = () => {
            massCancelOrder({
              orderIds: ctx.table.getRowModel().flatRows.map((row) => row.original.id),
            })
          }

          return (
            <Button
              visual="secondary-grey"
              loading={isMassCancelling}
              css={{ w: '100%', textStyle: 'caption' }}
              onClick={handleCancelAll}
            >
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.orders.action.cancel-all.title"
                defaultMessage="Cancel All"
              />
            </Button>
          )
        },
        size: 50,
        cell: (info) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { cancelOrder, isCancelling } = useCancelOrder()

          return (
            <TableCell>
              <Button
                visual="secondary-grey"
                loading={isCancelling}
                disabled={isMassCancelling}
                onClick={() => cancelOrder({ orderId: info.row.original.id })}
              >
                <FormattedMessage id="common.action.cancel" defaultMessage="Cancel" />
              </Button>
            </TableCell>
          )
        },
      },
    ],
    [
      formatMessage,
      formatMarketAsset,
      isFeatureEnabled,
      handleEdit,
      isMassCancelling,
      massCancelOrder,
    ],
  )
}
