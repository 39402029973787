import { sortBy } from 'lodash'
import { useSuspenseQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import { removeRestEnvelope } from '@src/domain/api/utils/remove-rest-envelope'
import { API, type AccountTrade } from '@src/domain/api/x10'
import type { TradesArgs } from '@src/domain/api/x10/trading/account/trades'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

export const useTrades = ({ marketsNames, orderType }: TradesArgs = {}) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(marketsNames)

  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.UserTrades, sortedMarketsNames, orderType, accountId],
    queryFn: () => {
      return API.trading.account.getTrades(
        {
          marketsNames: sortedMarketsNames,
          orderType,
        },
        accountId,
      )
    },
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelope(data) as AccountTrade[],
  }
}
