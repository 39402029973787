import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { checkRequired } from '@x10/lib-core/utils'

import { removeRestEnvelope } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { type FundingRate } from '@src/domain/api/x10/trading/markets-info/funding-rates.schema'
import { QueryKey } from '@src/domain/trade/constants'

const EMPTY_FUNDING_RATES: FundingRate[] = []

export const useFundingRates = ({
  marketName,
  startTime,
  endTime,
}: {
  marketName: MarketName
  startTime: number
  endTime: number
}) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QueryKey.FundingRate, marketName, startTime, endTime],
    queryFn: () => {
      return API.trading.marketsInfo.getFundingRates(marketName, startTime, endTime)
    },
    placeholderData: keepPreviousData,
  })

  const fundingRates = data
    ? checkRequired(removeRestEnvelope(data), 'fundingRates')
    : EMPTY_FUNDING_RATES

  return { data: fundingRates, isLoading, isFetching }
}
