import { type FC } from 'react'
import { filter, last } from 'lodash'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { notReachable, type Decimal } from '@x10/lib-core/utils'
import { Box, Flex } from '@x10/lib-styled-system/jsx'
import { Portal, Tooltip } from '@x10/lib-ui-kit/components'

import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'
import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { useOrderBookDisplayParamsStore } from '../../store'
import { calcBidAskRatio } from '../../utils/calc-bid-ask-ratio'
import { calcSpread } from '../../utils/calc-spread'
import { OrderBookTableCell } from './order-book-table-cell'
import { OrderBookTableCellHover } from './order-book-table-cell-hover'
import { OrderBookTableTotal } from './order-book-table-total'

const useDisplaySizeLabel = () => {
  const displaySizeAs = useOrderBookDisplayParamsStore((store) => store.displaySizeAs)
  const market = useSelectedMarket()

  switch (displaySizeAs) {
    case 'synthetic':
      return market.assets.synthetic.code
    case 'collateral':
      return market.assets.collateral.code
    default:
      notReachable(displaySizeAs)
  }
}

type OrderBookTableProps = {
  bestBidPrice: Decimal | undefined
  bestAskPrice: Decimal | undefined
  midPrice: Decimal | undefined
  precision: number
  rows: Array<[AggOrderBookDataItem | undefined, AggOrderBookDataItem | undefined]>
}

const PERCENTAGE_CALC_ORDERS_LEVEL = 20

export const OrderBookTable: FC<OrderBookTableProps> = ({
  bestBidPrice,
  bestAskPrice,
  midPrice,
  precision,
  rows,
}) => {
  const market = useSelectedMarket()
  const sizeLabel = useDisplaySizeLabel()

  const formatMarketAsset = useFormatMarketAsset()
  const formatPercent = useFormatPercent()

  const pairWithBid = last(
    filter(rows, (item) => Boolean(item[0])).slice(0, PERCENTAGE_CALC_ORDERS_LEVEL),
  )
  const pairWithAsk = last(
    filter(rows, (item) => Boolean(item[1])).slice(0, PERCENTAGE_CALC_ORDERS_LEVEL),
  )

  const spread = calcSpread(bestBidPrice, bestAskPrice)
  const bidAskRatio = calcBidAskRatio(
    pairWithBid?.[0]?.sizeSum,
    pairWithAsk?.[1]?.sizeSum,
  )

  return (
    <TradeWidget.ContentFixed>
      <Flex px="s-16" gap="s-4" mb="s-8">
        <Flex flex={1 / 2} justifyContent="space-between">
          <Tooltip.Root lazyMount unmountOnExit closeDelay={0} openDelay={100}>
            <Tooltip.Trigger asChild>
              <Box>
                <FormattedMessage
                  id="workspace.trade.widget.order-book.spread.title"
                  defaultMessage="Spread"
                />
              </Box>
            </Tooltip.Trigger>
            <Portal>
              <Tooltip.Positioner>
                <Tooltip.Content>
                  <FormattedMessage
                    id="workspace.trade.widget.order-book.spread.tooltip"
                    defaultMessage="Spread is the difference between the best bid price and the best ask price in a market. Spread % is calculated as the absolute spread divided by the mid price, where the mid price is the average between the best bid and best ask prices"
                  />
                </Tooltip.Content>
              </Tooltip.Positioner>
            </Portal>
          </Tooltip.Root>

          <Box>
            {formatMarketAsset({ amount: spread?.value, type: 'collateral' }) ??
              EMPTY_CELL_VALUE}
          </Box>
        </Flex>

        {spread && <Flex flex={1 / 2}>{formatPercent(spread.pct)}</Flex>}
      </Flex>

      <Flex px="s-16" mb="s-4" textStyle="caption" gap="s-24">
        <Flex flex={1 / 2}>
          <Box flex={1 / 2}>
            <FormattedMessage
              id="workspace.trade.widget.order-book.column.bid-size.title"
              defaultMessage="Bid Size"
            />
          </Box>

          <Box flex={1 / 2} textAlign="right">
            <FormattedMessage
              id="workspace.trade.widget.order-book.column.bid-price.title"
              defaultMessage="Bid Price"
            />
          </Box>
        </Flex>

        <Flex flex={1 / 2}>
          <Box flex={1 / 2}>
            <FormattedMessage
              id="workspace.trade.widget.order-book.column.ask-price.title"
              defaultMessage="Ask Price"
            />
          </Box>

          <Box flex={1 / 2} textAlign="right">
            <FormattedMessage
              id="workspace.trade.widget.order-book.column.ask-size.title"
              defaultMessage="Ask Size"
            />
          </Box>
        </Flex>
      </Flex>

      <Flex px="s-16" textStyle="small" gap="s-24">
        <Flex flex={1 / 2}>
          <Box flex={1 / 2}>{sizeLabel}</Box>
          <Box flex={1 / 2} textAlign="right">
            {market.assets.collateral.code}
          </Box>
        </Flex>

        <Flex flex={1 / 2}>
          <Box flex={1 / 2}>{market.assets.collateral.code}</Box>
          <Box flex={1 / 2} textAlign="right">
            {sizeLabel}
          </Box>
        </Flex>
      </Flex>

      <TradeWidget.ContentScrollable>
        {rows.map((value, idx) => {
          const [bid, ask] = value

          return (
            <Flex key={idx}>
              <OrderBookTableCellHover
                placement="left"
                dataItem={bid}
                midPrice={midPrice}
                market={market}
              >
                <OrderBookTableCell mode="bid" precision={precision} dataItem={bid} />
              </OrderBookTableCellHover>

              <OrderBookTableCellHover
                placement="left"
                dataItem={ask}
                midPrice={midPrice}
                market={market}
              >
                <OrderBookTableCell mode="ask" precision={precision} dataItem={ask} />
              </OrderBookTableCellHover>
            </Flex>
          )
        })}
      </TradeWidget.ContentScrollable>

      <OrderBookTableTotal bidRatio={bidAskRatio.bid} askRatio={bidAskRatio.ask} />
    </TradeWidget.ContentFixed>
  )
}
