import { type Decimal } from '@x10/lib-core/utils'

export const calcMidPrice = (
  bid: Decimal | undefined,
  ask: Decimal | undefined,
  minMarketPriceChange: Decimal,
) => {
  if (!bid || !ask) {
    return undefined
  }

  return bid.plus(ask).div(2).setDecimalPlaces(minMarketPriceChange.getDecimalPlaces())
}
