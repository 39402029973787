import { useCallback, useMemo, type FC } from 'react'
import { AxisRight } from '@visx/axis'
import { curveStep } from '@visx/curve'
import { localPoint } from '@visx/event'
import { LinearGradient } from '@visx/gradient'
import { GridColumns, GridRows } from '@visx/grid'
import { scaleLinear } from '@visx/scale'
import { AreaClosed, Bar, Line } from '@visx/shape'
import { withTooltip } from '@visx/tooltip'
import { type WithTooltipProvidedProps } from '@visx/tooltip/lib/enhancers/withTooltip'
import { bisector, extent, max } from '@visx/vendor/d3-array'

import { invariant, type Decimal } from '@x10/lib-core/utils'
import { Box } from '@x10/lib-styled-system/jsx'

import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { ResponsiveChartWrapper } from '@src/domain/core/ui/components/responsive-chart-wrapper'

import { usePrepareData } from '../../hooks/use-prepare-data'
import { getItemPrice } from '../../utils/get-item-price'
import { getItemSizeSum } from '../../utils/get-item-size-sum'
import { getPresetTokens } from '../../utils/get-preset-tokens'
import { Tooltip, type TooltipData } from '../tooltip'

const Y_OFFSET = 10

const bisectPrice = bisector<AggOrderBookDataItem, number>(getItemPrice).left

type AreaProps = {
  width: number
  height: number
  margin?: { top: number; right: number; bottom: number; left: number }
}

type DepthChartComponentProps = AreaProps & {
  bids: AggOrderBookDataItem[]
  asks: AggOrderBookDataItem[]
}

const DepthChartComponent = withTooltip<DepthChartComponentProps, TooltipData>(
  ({
    bids,
    asks,
    width,
    height,
    margin = { top: 0, right: 0, bottom: 40, left: 0 },
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  }: DepthChartComponentProps & WithTooltipProvidedProps<TooltipData>) => {
    const presetTokens = getPresetTokens()
    const bidsAndAsks = useMemo(() => bids.concat(asks), [bids, asks])

    const innerWidth = width - margin.left - margin.right
    const innerHeight = height - margin.top - margin.bottom

    const priceScale1 = useMemo(() => {
      const domain = extent(bidsAndAsks, getItemPrice) as [number, number]

      return scaleLinear({
        range: [margin.top + Y_OFFSET, innerHeight + margin.top],
        domain: domain,
      })
    }, [bidsAndAsks, innerHeight, margin.top])
    const priceScale2 = useMemo(() => {
      return scaleLinear({
        range: [innerHeight + margin.top, margin.top + Y_OFFSET],
        domain: extent(bidsAndAsks, getItemPrice) as [number, number],
      })
    }, [bidsAndAsks, innerHeight, margin.top])

    const sizeScale = useMemo(
      () =>
        scaleLinear({
          range: [margin.left, innerWidth + margin.left],
          domain: [0, max(bidsAndAsks, getItemSizeSum) ?? 0],
        }),
      [bidsAndAsks, innerWidth, margin.left],
    )

    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) ?? { x: 0 }
        const x0 = priceScale1.invert(x)
        const itemIndex = bisectPrice(bidsAndAsks, x0, 1)
        const item0 = bidsAndAsks[itemIndex - 1]
        const item1 = bidsAndAsks[itemIndex]

        invariant(item0, 'item0 is required')
        let item = item0

        if (item1 && getItemPrice(item1)) {
          item =
            x0.valueOf() - getItemPrice(item0).valueOf() >
            getItemPrice(item1).valueOf() - x0.valueOf()
              ? item1
              : item0
        }

        showTooltip({
          tooltipData: item,
          tooltipLeft: x,
          tooltipTop: sizeScale(getItemSizeSum(item)),
        })
      },
      [bidsAndAsks, sizeScale, priceScale1, showTooltip],
    )

    const midY = margin.top + (margin.top + innerHeight) / 2

    return (
      <Box>
        <svg width={width} height={height}>
          <LinearGradient
            id="area-gradient-bids"
            from={presetTokens.colorGradientGreen}
            to={presetTokens.colorGradientGreen}
            toOpacity={0.1}
          />
          <LinearGradient
            id="area-gradient-asks"
            from={presetTokens.colorGradientRed}
            to={presetTokens.colorGradientRed}
            toOpacity={0.1}
          />

          <GridRows
            left={margin.left}
            width={innerWidth}
            scale={sizeScale}
            stroke={presetTokens.colorGrid}
            opacity={0.5}
            pointerEvents="none"
          />
          <GridColumns
            top={margin.top}
            height={innerHeight}
            scale={priceScale1}
            stroke={presetTokens.colorGrid}
            opacity={0.5}
            pointerEvents="none"
          />

          <Line
            from={{ x: margin.left, y: midY }}
            to={{ x: innerWidth + margin.right, y: midY }}
            stroke={presetTokens.colorMidLine}
            strokeWidth={0.5}
            pointerEvents="none"
          />

          <AreaClosed<AggOrderBookDataItem>
            data={bids}
            x={(d) => sizeScale(getItemSizeSum(d)) ?? 0}
            y={(d) => priceScale2(getItemPrice(d)) ?? 0}
            yScale={priceScale2}
            stroke={presetTokens.colorGreen}
            strokeWidth={1}
            fill="url(#area-gradient-bids)"
            curve={curveStep}
          />

          <AreaClosed<AggOrderBookDataItem>
            data={asks}
            x={(d) => sizeScale(getItemSizeSum(d)) ?? 0}
            y={(d) => priceScale1(getItemPrice(d)) ?? 0}
            yScale={priceScale1}
            stroke={presetTokens.colorRed}
            strokeWidth={1}
            fill="url(#area-gradient-asks)"
            curve={curveStep}
          />

          <Line
            from={{ x: 0, y: margin.top }}
            to={{ x: 0, y: innerHeight + margin.top + 1 }}
            stroke={presetTokens.colorBlack}
            strokeWidth={1}
            pointerEvents="none"
          />

          <Line
            from={{ x: innerWidth, y: margin.top }}
            to={{ x: innerWidth, y: innerHeight + margin.top + 1 }}
            stroke={presetTokens.colorBlack}
            strokeWidth={1}
            pointerEvents="none"
          />

          <AxisRight
            scale={priceScale2}
            top={0}
            tickLabelProps={{
              fill: presetTokens.colorWhite,
              style: { fontSize: presetTokens.fontSize10 },
            }}
            tickFormat={(value) => value.toString()}
          />

          <Bar
            x={margin.left}
            y={margin.top}
            width={innerWidth}
            height={innerHeight}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
          />

          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: margin.top }}
                to={{ x: tooltipLeft, y: innerHeight + margin.top }}
                stroke={presetTokens.colorWhite}
                strokeWidth={1}
                pointerEvents="none"
                strokeDasharray="2,4"
              />

              <circle
                cx={tooltipLeft}
                cy={tooltipTop}
                r={4}
                fill={
                  tooltipData.side === 'BUY'
                    ? presetTokens.colorGreen
                    : presetTokens.colorRed
                }
                pointerEvents="none"
              />
            </g>
          )}
        </svg>

        {tooltipData && (
          <Tooltip data={tooltipData} midPrice={0} top={tooltipTop} left={tooltipLeft} />
        )}
      </Box>
    )
  },
)

type DepthChartProps = {
  data: {
    midPrice: Decimal | undefined
    rows: Array<[AggOrderBookDataItem | undefined, AggOrderBookDataItem | undefined]>
  }
}

export const VerticalDepthChart: FC<DepthChartProps> = ({ data }) => {
  const { bids, asks } = usePrepareData({ rows: data.rows, midPrice: data.midPrice })

  return (
    <Box w="100%">
      <Box textStyle="caption" textAlign="center" mb="s-12">
        Mid Market Price: 26,325.9
      </Box>

      <ResponsiveChartWrapper h={400}>
        {({ width, height }) => (
          <DepthChartComponent bids={bids} asks={asks} width={width} height={height} />
        )}
      </ResponsiveChartWrapper>
    </Box>
  )
}
