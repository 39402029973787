import React, { useMemo } from 'react'
import { type ColumnDef } from '@tanstack/react-table'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { DateTimeTableCell, TableCell } from '@x10/lib-ui-kit/components'

import { type UserOrder } from '@src/domain/api/x10'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { OrderSideColored } from '@src/domain/core/ui/components/order-side-colored'
import { OrderSideText } from '@src/domain/core/ui/components/order-side-text'
import { getOrderStatusIntlKey } from '@src/domain/i18n/utils/get-order-status-intl-key'
import { getOrderTypeIntlKey } from '@src/domain/i18n/utils/get-order-type-intl-key'

import { ActionableMarketTableCell } from './components/actionable-market-table-cell'
import { HeaderTooltip } from './components/header-tooltip'
import { OrderPriceCell } from './components/historical-order-price-cell'

export const useColumns = () => {
  const { formatMessage } = useIntl()

  const formatMarketAsset = useFormatMarketAsset()

  return useMemo<Array<ColumnDef<UserOrder>>>(
    () => [
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders-history.column.market.title',
          defaultMessage: 'Market',
        }),
        accessorKey: 'market',
        size: 100,
        cell: (info) => {
          const marketName = info.getValue<UserOrder['market']>()

          return <ActionableMarketTableCell market={marketName} />
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders-history.column.status.title',
          defaultMessage: 'Status',
        }),
        size: 70,
        accessorKey: 'status',
        cell: (info) => {
          const status = info.getValue<UserOrder['status']>()

          return (
            <TableCell>
              <FormattedMessage {...getOrderStatusIntlKey(status)} />
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders-history.column.order-type.title',
          defaultMessage: 'Order Type',
        }),
        size: 70,
        accessorKey: 'type',
        cell: (info) => {
          const orderType = info.getValue<UserOrder['type']>()

          return (
            <TableCell>
              <FormattedMessage {...getOrderTypeIntlKey(orderType)} />
            </TableCell>
          )
        },
      },
      {
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders-history.column.side.title',
          defaultMessage: 'Side',
        }),
        size: 70,
        accessorKey: 'side',
        cell: (info) => {
          const side = info.getValue<UserOrder['side']>()

          return (
            <TableCell>
              <OrderSideText side={side} />
            </TableCell>
          )
        },
      },
      {
        id: 'order_filled_price',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders-history.column.order-filled-price.title',
          defaultMessage: 'Filled / Order Price',
        }),
        size: 70,
        cell: (info) => {
          return <OrderPriceCell order={info.row.original} />
        },
      },
      {
        id: 'order_filled_amount',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders-history.column.order-filled-amount.title',
          defaultMessage: 'Filled / Order Amount',
        }),
        size: 70,
        accessorFn: (row) => [row.market, row.qty, row.side, row.filledQty],
        cell: (info) => {
          const [marketName, qty, side, filledQty] =
            info.getValue<
              [
                UserOrder['market'],
                UserOrder['qty'],
                UserOrder['side'],
                UserOrder['filledQty'],
              ]
            >()

          return (
            <TableCell>
              <OrderSideColored side={side}>
                <div>
                  {filledQty
                    ? formatMarketAsset(
                        { amount: filledQty, type: 'synthetic' },
                        { marketName, showSymbol: true },
                      )
                    : EMPTY_CELL_VALUE}
                </div>
                <div>
                  {formatMarketAsset(
                    {
                      amount: qty,
                      type: 'synthetic',
                    },
                    { marketName, showSymbol: true },
                  )}
                </div>
              </OrderSideColored>
            </TableCell>
          )
        },
      },
      {
        header: () => (
          <HeaderTooltip
            tooltipMessage={{
              id: 'workspace.trade.widget.trading-history.mode.orders-history.column.reduce-only.tooltip',
              defaultMessage:
                'A reduce-only order is a type of order that can only reduce or close out an existing open position. It is designed to prevent the order from increasing the size of the position. If the reduce-only order would increase the position size, it will be either partially executed or fully canceled.',
            }}
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.orders-history.column.reduce-only.title"
                defaultMessage="Reduce only"
              />
            </Box>
          </HeaderTooltip>
        ),
        size: 70,
        accessorKey: 'reduceOnly',
        cell: (info) => {
          const reduceOnly = info.getValue<UserOrder['reduceOnly']>()

          return (
            <TableCell>
              {reduceOnly ? (
                <FormattedMessage
                  id="workspace.trade.widget.trading-history.mode.orders-history.column.reduce-only.yes.value"
                  defaultMessage="Yes"
                />
              ) : (
                <FormattedMessage
                  id="workspace.trade.widget.trading-history.mode.orders-history.column.reduce-only.no.value"
                  defaultMessage="No"
                />
              )}
            </TableCell>
          )
        },
      },
      {
        id: 'time',
        header: formatMessage({
          id: 'workspace.trade.widget.trading-history.mode.orders-history.column.time.title',
          defaultMessage: 'Time',
        }),
        size: 70,
        accessorFn: (row) => row.createdTime,
        cell: (info) => {
          const createdTime = info.getValue<UserOrder['createdTime']>()
          return <DateTimeTableCell value={createdTime} />
        },
      },
    ],
    [formatMessage, formatMarketAsset],
  )
}
