import { isEmpty } from 'lodash'

import { type FilterPeriodValue } from '@x10/lib-ui-kit/components'

import type { UserRealisedPnlEntry } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { checkIfTimestampMatchesPeriod } from '@src/domain/trade/utils/matchers'

export const filterByMarkets = (
  markets: MarketName[] | undefined,
  item: UserRealisedPnlEntry,
) => {
  if (!markets || isEmpty(markets)) {
    return true
  }

  return markets.includes(item.market)
}

export const filterByPeriod = (
  period: FilterPeriodValue | undefined,
  item: UserRealisedPnlEntry,
) => {
  if (!period) {
    return true
  }

  // Always show open positions
  if (!item.closedTime) {
    return true
  }

  return checkIfTimestampMatchesPeriod(period, item.closedTime.getTime())
}

export const compareByClosedTime = (
  valueA: UserRealisedPnlEntry,
  valueB: UserRealisedPnlEntry,
) => {
  if (!valueA.closedTime && valueB.closedTime) {
    return -1
  }

  if (valueA.closedTime && !valueB.closedTime) {
    return 1
  }

  if (valueA.closedTime && valueB.closedTime) {
    const diff = valueB.closedTime.getTime() - valueA.closedTime.getTime()

    if (diff !== 0) {
      return diff
    }
  }

  return valueA.market.localeCompare(valueB.market)
}
