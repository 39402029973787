import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import type { AccountTrade } from '@src/domain/api/x10'

export const getTradeTypeIntlKey = (
  value: AccountTrade['tradeType'],
): MessageDescriptor => {
  switch (value) {
    case 'TRADE':
      return {
        id: 'common.trade.type.trade',
        defaultMessage: 'Trade',
      }
    case 'LIQUIDATION':
      return {
        id: 'common.trade.type.liquidation',
        defaultMessage: 'Liquidation',
      }
    case 'ADL':
    case 'DELEVERAGE':
      return {
        id: 'common.trade.type.deleverage',
        defaultMessage: 'Deleverage',
      }
    default:
      throw notReachable(value)
  }
}
