import { Suspense, type FC, type PropsWithChildren, type ReactNode } from 'react'

import { HStack } from '@x10/lib-styled-system/jsx'
import { Skeleton } from '@x10/lib-ui-kit/components'

import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { InlineErrorBoundary } from '@src/domain/core/ui/components/inline-error-boundary'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { ClaimCounter } from './claim-counter'
import { ClaimFundsDialog } from './claim-funds-dialog'
import { DepositButton } from './deposit-button'
import { TransferButton } from './transfer-button'
import { WithdrawButton } from './withdraw-button'

const ButtonSkeleton = () => {
  return <Skeleton css={{ h: '2rem', w: '100%' }} />
}

const AccountActionWrapper: FC<PropsWithChildren<{ fallback?: ReactNode }>> = ({
  fallback,
  children,
}) => {
  return (
    <InlineErrorBoundary fallback={fallback}>
      <Suspense fallback={fallback}>{children}</Suspense>
    </InlineErrorBoundary>
  )
}

export const AccountActions = () => {
  return (
    <TradeWidget.Actions
      css={{
        fontSize: 'fs-12',
        p: 's-16',
      }}
    >
      <FeatureGate name="CLAIM_FUNDS">
        <AccountActionWrapper>
          <ClaimCounter />
        </AccountActionWrapper>
      </FeatureGate>

      <HStack
        css={{
          gap: 's-16',
          mt: 's-16',
        }}
      >
        <FeatureGate name="CLAIM_FUNDS">
          <AccountActionWrapper fallback={<ButtonSkeleton />}>
            <ClaimFundsDialog />
          </AccountActionWrapper>
        </FeatureGate>

        <FeatureGate name="ACCOUNT_DEPOSIT_WITHDRAW_ACTIONS">
          <AccountActionWrapper fallback={<ButtonSkeleton />}>
            <DepositButton />
          </AccountActionWrapper>
        </FeatureGate>

        <AccountActionWrapper fallback={<ButtonSkeleton />}>
          <TransferButton />
        </AccountActionWrapper>

        <FeatureGate name="ACCOUNT_DEPOSIT_WITHDRAW_ACTIONS">
          <AccountActionWrapper fallback={<ButtonSkeleton />}>
            <WithdrawButton />
          </AccountActionWrapper>
        </FeatureGate>
      </HStack>
    </TradeWidget.Actions>
  )
}
