import { useCallback, useMemo, type FC } from 'react'
import { sortBy } from 'lodash'
import { AxisBottom, AxisRight } from '@visx/axis'
import { localPoint } from '@visx/event'
import { Grid } from '@visx/grid'
import { Group } from '@visx/group'
import { scaleLinear, scaleTime } from '@visx/scale'
import { Line, LinePath } from '@visx/shape'
import { withTooltip } from '@visx/tooltip'
import { bisector } from '@visx/vendor/d3-array'

import { invariant } from '@x10/lib-core/utils'
import { Center } from '@x10/lib-styled-system/jsx'
import { Spinner, type FilterPeriodValue } from '@x10/lib-ui-kit/components'

import type { FundingRate } from '@src/domain/api/x10/trading/markets-info/funding-rates.schema'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { ResponsiveChartWrapper } from '@src/domain/core/ui/components/responsive-chart-wrapper'
import { getChartColumnTicks } from '@src/domain/trade/utils/get-chart-column-ticks'
import { getFormattedChartColumnTickValue } from '@src/domain/trade/utils/get-formatted-chart-column-tick-value'

import {
  FundingRateLineChartTooltip,
  type TooltipData,
} from './funding-rate-line-chart-tooltip'
import { getPresetTokens } from './utils/get-preset-tokens'

type DataPoint = {
  fundingRate: number
  timestamp: number
}

type FundingRateLineChartProps = {
  data: FundingRate[]
  width: number
  height: number
  period: FilterPeriodValue
  variant?: 'popover' | 'default'
}

const ROW_TICKS = 6
const MARGIN = { top: 8, right: 84, bottom: 30, left: 0 } as const
const MAX_COLUMN_TICKS = 16
const MAX_POPOVER_COLUMN_TICKS = 12

const bisectX = bisector<DataPoint, number>((d) => d.timestamp).left

const FundingRateLineChart: FC<FundingRateLineChartProps> = withTooltip<
  FundingRateLineChartProps,
  TooltipData
>(
  ({
    data,
    width,
    height,
    period,
    variant = 'default',
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipTop = 0,
    tooltipLeft = 0,
  }) => {
    const { colorGreen, colorWhite, colorWhite10, fontSize10 } = getPresetTokens()

    const formatPercent = useFormatPercent()

    const transformedData = useMemo<DataPoint[]>(() => {
      return sortBy(data, 'timestamp').map((d) => ({
        fundingRate: d.fundingRate.toNumber(),
        timestamp: d.timestamp,
      }))
    }, [data])

    const chartHeight = height - MARGIN.top
    const xMax = width - MARGIN.right
    const yMax = chartHeight - MARGIN.bottom
    const marginLeft = variant === 'popover' ? 16 : MARGIN.left

    const xScale = scaleTime({
      domain: [
        Math.min(...transformedData.map((d) => d.timestamp)),
        Math.max(...transformedData.map((d) => d.timestamp)),
      ],
      range: [marginLeft, width - MARGIN.right],
    })

    const yScale = scaleLinear({
      domain: [
        Math.min(...transformedData.map((d) => d.fundingRate)),
        Math.max(...transformedData.map((d) => d.fundingRate)),
      ],
      range: [yMax, 0],
    })

    const handleTooltip = useCallback(
      (event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 }
        const x0 = xScale.invert(x - marginLeft).valueOf()

        const index = bisectX(transformedData, x0, 1)
        const d0 = transformedData[index - 1]
        const d1 = transformedData[index]

        invariant(d0, 'd0 is not defined')

        let d = d0

        if (d1 && d1.timestamp) {
          d = x0 - d0.timestamp > d1.timestamp - x0 ? d1 : d0
        }

        showTooltip({
          tooltipData: {
            timestamp: d.timestamp,
            fundingRate: d.fundingRate,
          },
          tooltipLeft: xScale(d.timestamp),
          tooltipTop: yScale(d.fundingRate),
        })
      },
      [marginLeft, showTooltip, transformedData, xScale, yScale],
    )

    const columnTicksAmount = useMemo(
      () =>
        getChartColumnTicks(
          period,
          transformedData[0]?.timestamp,
          variant === 'popover' ? MAX_POPOVER_COLUMN_TICKS : MAX_COLUMN_TICKS,
        ),
      [period, transformedData, variant],
    )

    return (
      <>
        <svg width={width} height={chartHeight}>
          <Group top={MARGIN.top}>
            <Grid
              xScale={xScale}
              yScale={yScale}
              width={xMax}
              height={yMax}
              stroke={colorWhite10}
              numTicksRows={ROW_TICKS}
              numTicksColumns={columnTicksAmount}
            />
            <AxisBottom
              top={yMax}
              scale={xScale}
              numTicks={columnTicksAmount}
              stroke={colorWhite10}
              tickStroke={colorWhite10}
              tickLabelProps={{
                fill: colorWhite,
                style: { fontSize: fontSize10 },
              }}
              tickFormat={(value) =>
                getFormattedChartColumnTickValue(period, value.valueOf())
              }
            />
            <AxisRight
              left={xMax}
              scale={yScale}
              numTicks={ROW_TICKS}
              stroke={colorWhite10}
              tickStroke={colorWhite10}
              tickLabelProps={{
                fill: colorWhite,
                style: {
                  fontSize: fontSize10,
                  textAlign: 'center',
                  marginLeft: '0.75rem',
                },
                dx: '12',
              }}
              tickFormat={(value) =>
                formatPercent(value.valueOf(), {
                  precision: PercentPrecision.FundingRate,
                })
              }
            />
            <LinePath
              data={transformedData}
              x={(d) => xScale(d.timestamp) || 0}
              y={(d) => yScale(d.fundingRate) || 0}
              stroke={colorGreen}
              strokeWidth={1}
            />

            {tooltipData && (
              <g>
                <Line
                  from={{ x: tooltipLeft, y: 0 }}
                  to={{ x: tooltipLeft, y: yMax }}
                  stroke={colorWhite}
                  strokeWidth={1}
                  pointerEvents="none"
                  strokeDasharray="2,4"
                />

                <circle
                  cx={tooltipLeft}
                  cy={tooltipTop}
                  r={4}
                  fill={colorWhite}
                  pointerEvents="none"
                />
              </g>
            )}

            <rect
              x={0}
              y={0}
              width={xMax}
              height={yMax}
              fill="transparent"
              onTouchStart={handleTooltip}
              onTouchMove={handleTooltip}
              onMouseMove={handleTooltip}
              onMouseLeave={() => hideTooltip()}
            />
          </Group>
        </svg>
        {tooltipData && (
          <FundingRateLineChartTooltip
            data={tooltipData}
            top={tooltipTop}
            left={tooltipLeft}
          />
        )}
      </>
    )
  },
)

type FundingRateLineChartWrapperProps = {
  isLoading: boolean
} & Omit<FundingRateLineChartProps, 'width' | 'height'>

export const FundingRateLineChartWrapper: FC<FundingRateLineChartWrapperProps> = ({
  isLoading,
  ...chartProps
}) => (
  <ResponsiveChartWrapper>
    {({ width, height }) => {
      return isLoading ? (
        <Center
          css={{
            w: '100%',
            h: '100%',
          }}
        >
          <Spinner />
        </Center>
      ) : (
        <FundingRateLineChart {...chartProps} width={width} height={height} />
      )
    }}
  </ResponsiveChartWrapper>
)
