import { invariant } from '@x10/lib-core/utils'

import { getOppositeOrderSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'

import type { Computed, Context, ErrorMessage, Rule } from '../types'

/**
 * [56:Validation]
 * Order Size < Min Trade Size (and current position size > min trade size)
 *
 * [57:Validation]
 * Reduce-only requirement when:
 * - Current position < Min Trade Size
 * - Order size < Min Trade Size
 */
export const orderMinSize: Rule<
  unknown,
  Pick<Context, 'reduceOnly' | 'position' | 'market' | 'orders'>,
  Pick<Computed, 'orderSize'>,
  Pick<ErrorMessage, 'getMinAmount' | 'getReduceOnly'>
> = (_value, ctx, computed, _alerts, errors, errorMessage) => {
  if (errors.orderSize) {
    return
  }

  invariant(computed.orderSize, '`orderSize` is required')

  if (!computed.orderSize.lt(ctx.market.tradingConfig.minOrderSize)) {
    return
  }

  // Closing small position with reduce-only order
  if (ctx.position && ctx.position.size.lt(ctx.market.tradingConfig.minOrderSize)) {
    const oppositeOrderSide = getOppositeOrderSide(toOrderSide(ctx.position.side))
    const hasOppositeSideOrders = ctx.orders.some(
      (order) => order.side === oppositeOrderSide,
    )

    if (
      hasOppositeSideOrders ||
      !ctx.reduceOnly ||
      !computed.orderSize.eq(ctx.position.size)
    ) {
      errors.orderSize = errorMessage.getReduceOnly()
    }

    return
  }

  errors.orderSize = errorMessage.getMinAmount(ctx.market.tradingConfig.minOrderSize)
}
