import { useCallback, useMemo } from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'

import type { MarketName, OrderSide } from '@src/domain/api/x10/common'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'

export const useErrorMessage = (marketName?: MarketName) => {
  const { formatMessage } = useIntl()

  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true, marketName })
  const formatPercent = useFormatPercent()

  const getMaxNumOrders = useCallback(
    (value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.max-open-orders',
          defaultMessage: 'Maximum number of open orders per market is {value}.',
        },
        {
          value: value.toString(),
        },
      )
    },
    [formatMessage],
  )

  const getTriggerPrice = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.trigger-price-required',
      defaultMessage: 'Trigger price is required',
    })
  }, [formatMessage])

  const getTriggerPriceMin = useCallback(
    (value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.trigger-price-below-min',
          defaultMessage:
            'You cannot place an order with the trigger price below the {value}.',
        },
        {
          value: formatMarketAsset({
            amount: value,
            type: 'collateral',
          }),
        },
      )
    },
    [formatMessage, formatMarketAsset],
  )

  const getOrderPrice = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.order-price-required',
      defaultMessage: 'Order price is required',
    })
  }, [formatMessage])

  const getOrderPriceMin = useCallback(
    (value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.order-price-below-min',
          defaultMessage:
            'You cannot place an order with the order price below the {value}.',
        },
        {
          value: formatMarketAsset({
            amount: value,
            type: 'collateral',
          }),
        },
      )
    },
    [formatMessage, formatMarketAsset],
  )

  const getOrderPriceMark = useCallback(
    (side: OrderSide, value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.order-price-mark',
          defaultMessage:
            'You cannot place a { side, select, BUY {long} other {short} } limit order with a price { side, select, BUY {greater} other {lower} } than {value}.',
        },
        {
          side,
          value: formatMarketAsset({
            amount: value,
            type: 'collateral',
          }),
        },
      )
    },
    [formatMessage, formatMarketAsset],
  )

  const getOrderPriceTrigger = useCallback(
    (side: OrderSide, value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.order-price-trigger',
          defaultMessage:
            'Order Price has to be { side, select, BUY {below} other {above} } Trigger Price * (1 { side, select, BUY {+} other {-} } {value})',
        },
        {
          side,
          value: formatPercent(value, {
            precision: PercentPrecision.TradingRules,
          }),
        },
      )
    },
    [formatMessage, formatPercent],
  )

  const getOrderSize = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.order-size-required',
      defaultMessage: 'Order size is required',
    })
  }, [formatMessage])

  const getReduceOnly = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.reduce-only',
      defaultMessage:
        'A position below the minimum trade size can only be closed using a reduce-only order.',
    })
  }, [formatMessage])

  const getMinAmount = useCallback(
    (value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.min-amount',
          defaultMessage: 'Your order size is below the minimum trade size of {value}.',
        },
        {
          value: formatMarketAsset(
            {
              amount: value,
              type: 'synthetic',
            },
            { precision: AssetPrecision.AsIs },
          ),
        },
      )
    },
    [formatMessage, formatMarketAsset],
  )

  const getAmountDivisible = useCallback(
    (value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.amount-divisible',
          defaultMessage: 'Your order size must be in multiples of {value}.',
        },
        {
          value: formatMarketAsset({
            amount: value,
            type: 'synthetic',
          }),
        },
      )
    },
    [formatMessage, formatMarketAsset],
  )

  const getReduceOnlySum = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.reduce-only-sum',
      defaultMessage:
        'The size of your reduce-only order should be less than or equal to the open position size minus the sum of all open orders intended to reduce the position size.',
    })
  }, [formatMessage])

  const getReduceOnlySameSide = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.reduce-only-same-side',
      defaultMessage:
        'Reduce-only orders in the same direction as the position are not permitted.',
    })
  }, [formatMessage])

  const getReduceOnlyNoPosition = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.reduce-only-no-position',
      defaultMessage: 'You cannot place a reduce-only order without an open position.',
    })
  }, [formatMessage])

  const getMaxOrderValue = useCallback(
    (value: Decimal) => {
      return formatMessage(
        {
          id: 'workspace.trade.widget.order-form.validation.max-order-value',
          defaultMessage:
            'Your order value exceeds the maximum permitted order value of {value}. If you are trying to close a position, enable the reduce-only mode, as this validation is not applied to reduce-only orders.',
        },
        {
          value: formatMarketAsset({
            amount: value,
            type: 'collateral',
          }),
        },
      )
    },
    [formatMessage, formatMarketAsset],
  )

  const getOrderCost = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.order-cost',
      defaultMessage:
        'Your available balance is insufficient to place the order. Please adjust the leverage or decrease the order size accordingly. Alternatively, reduce the open position size or cancel other orders in the same direction.',
    })
  }, [formatMessage])

  const getMaxPosition = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.max-position',
      defaultMessage:
        'If executed, this order will exceed the maximum permitted position value for this leverage or market. Please adjust the leverage or decrease the order size accordingly. Alternatively, reduce the open position size or cancel other orders in the same direction.',
    })
  }, [formatMessage])

  const getMarketOrderLiquidity = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.market-order-liquidity',
      defaultMessage:
        'The current liquidity in the order book is insufficient to execute a market order at the fair price. Please place a limit order instead.',
    })
  }, [formatMessage])

  const getOrderCostMarketReduceOnly = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.risk-alert.order-cost-market-reduce-only',
      defaultMessage:
        'If this order is executed, all non-reduce-only open orders in the same direction as this order will be canceled due to insufficient available balance.',
    })
  }, [formatMessage])

  const getExecutedImmediatelyAsMarket = useCallback(() => {
    return formatMessage({
      id: 'workspace.trade.widget.order-form.validation.risk-alert.executed-immediately-as-market',
      defaultMessage: 'The current order may be executed immediately as a market order.',
    })
  }, [formatMessage])

  return useMemo(
    () => ({
      getMaxNumOrders,
      getTriggerPrice,
      getTriggerPriceMin,
      getOrderPrice,
      getOrderPriceMin,
      getOrderPriceMark,
      getOrderPriceTrigger,
      getOrderSize,
      getReduceOnly,
      getMinAmount,
      getAmountDivisible,
      getReduceOnlySum,
      getReduceOnlySameSide,
      getReduceOnlyNoPosition,
      getMaxOrderValue,
      getOrderCost,
      getOrderCostMarketReduceOnly,
      getMaxPosition,
      getExecutedImmediatelyAsMarket,
      getMarketOrderLiquidity,
    }),
    [
      getMaxNumOrders,
      getTriggerPrice,
      getTriggerPriceMin,
      getOrderPrice,
      getOrderPriceMin,
      getOrderPriceMark,
      getOrderPriceTrigger,
      getOrderSize,
      getReduceOnly,
      getMinAmount,
      getAmountDivisible,
      getReduceOnlySum,
      getReduceOnlySameSide,
      getReduceOnlyNoPosition,
      getMaxOrderValue,
      getOrderCost,
      getOrderCostMarketReduceOnly,
      getMaxPosition,
      getMarketOrderLiquidity,
      getExecutedImmediatelyAsMarket,
    ],
  )
}
