import { Flex, styled, VStack } from '@x10/lib-styled-system/jsx'
import { ActionIcon, SvgIcon } from '@x10/lib-ui-kit/components'

import type { AccountInfo } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { DEFAULT_ACCOUNT_COLLATERAL_CODE } from '@src/domain/trade/constants'

import { setAccount } from './set-account'
import { useAccountSelectorState } from './state'

export const AccountEntry = ({ account }: { account: AccountInfo }) => {
  const accountId = useAccountId()
  const { startEdit, isCreatingFormOpen, isCurrentlyEditing, onClose } =
    useAccountSelectorState()
  const isSelected = account.accountId.toString() === accountId
  return (
    <styled.div
      tabIndex={0}
      role="checkbox"
      aria-label={`Select account named: ${account.description}`}
      aria-disabled={isCreatingFormOpen || isCurrentlyEditing}
      aria-checked={isSelected}
      css={{
        p: 's-16',
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        transitionDuration: 'fast',
        transitionProperty: 'background, color',
        transitionTimingFunction: 'default',
        _disabled: {
          cursor: 'not-allowed',
          opacity: 0.5,
        },
        _checked: {
          bg: 'token.white-5',
        },
        _highlighted: {
          bg: 'token.white-10',
        },
        "&:hover button[data-scope='action-icon']": {
          display: 'block',
        },
      }}
      onClick={() => {
        setAccount(account)
        onClose()
      }}
    >
      <Flex
        css={{
          gap: 's-16',
          alignItems: 'center',
        }}
      >
        <CryptoLogo
          currency={DEFAULT_ACCOUNT_COLLATERAL_CODE}
          boxSize={40}
          badge={isSelected ? <SvgIcon.SvgIconCheckmarkBadge /> : null}
        />
        <VStack
          css={{
            alignItems: 'flex-start',
          }}
        >
          <span>{account.description}</span>
          <span>USDC</span>
        </VStack>
      </Flex>
      <FeatureGate name="ACCOUNT_MENU_NAME_EDITING">
        {!isCurrentlyEditing && !isCreatingFormOpen && (
          <ActionIcon
            css={{
              display: 'none',
            }}
            onClick={(e) => {
              e.stopPropagation()
              startEdit(account.accountIndex)
            }}
          >
            <SvgIcon.SvgIconPencil />
          </ActionIcon>
        )}
      </FeatureGate>
    </styled.div>
  )
}
