import { useEffect, useMemo, type FC } from 'react'
import { debounce, first } from 'lodash'

import { type Decimal } from '@x10/lib-core/utils'

import { useLeverage } from '@src/domain/api/hooks/account/use-leverage'
import type { MarketStats, UserOrder, UserPosition } from '@src/domain/api/x10'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import { getOppositeOrderSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'
import { calcOrderCost } from '@src/domain/trade/utils/calc/calc-order-cost'

import { useLimitCloseFormContext } from './limit-close-form-provider'

const CALC_WAIT = 1000

type LimitOrderCostValueProps = {
  position: UserPosition
  triggeredOrders: UserOrder[]
  orderSize: Decimal | null
  orderPrice: Decimal | null
  marketStats?: MarketStats
}

export const LimitOrderCostCalc: FC<LimitOrderCostValueProps> = ({
  position,
  triggeredOrders,
  orderSize,
  orderPrice,
  marketStats,
}) => {
  const getCachedMarket = useGetCachedMarket()
  const setOrderCost = useLimitCloseFormContext((state) => state.setOrderCost)

  const market = getCachedMarket(position.market)

  const { data: leverage } = useLeverage({ marketsNames: [market.name] })

  const marketLeverage = first(leverage)?.leverage

  const orderCostCtx = useMemo(
    () => ({
      position,
      triggeredOrders,
      orderSize,
      orderPrice,
      marketStats,
      marketLeverage,
    }),
    [position, triggeredOrders, orderSize, orderPrice, marketStats, marketLeverage],
  )

  const updateOrderCost = useMemo(() => {
    return debounce(
      (ctx: typeof orderCostCtx) => {
        if (
          !ctx.orderSize ||
          !ctx.orderPrice ||
          !ctx.marketStats ||
          !ctx.marketLeverage
        ) {
          setOrderCost(null)
          return
        }

        const orderCost = calcOrderCost({
          leverage: ctx.marketLeverage,
          position: ctx.position,
          orders: ctx.triggeredOrders,
          newOrder: {
            price: ctx.orderPrice,
            qty: ctx.orderSize,
            side: getOppositeOrderSide(toOrderSide(ctx.position.side)),
          },
          markPrice: ctx.marketStats.markPrice,
        })

        setOrderCost(orderCost)
      },
      CALC_WAIT,
      {
        trailing: true,
        maxWait: CALC_WAIT,
      },
    )
  }, [setOrderCost])

  useEffect(() => {
    updateOrderCost(orderCostCtx)
  }, [orderCostCtx, updateOrderCost])

  return null
}
