import * as z from 'zod'

import { zodDecimal } from '@src/domain/api/utils/zod'
import { MarketNameSchema } from '@src/domain/api/x10/common'

import { addStreamEnvelope } from '../../utils/add-stream-envelope'

const orderbookQuantitySchema = z
  .object({
    q: zodDecimal(),
    p: zodDecimal(),
  })
  .transform((value) => {
    return {
      qty: value.q,
      price: value.p,
    }
  })

const orderBookUpdateSchema = z
  .object({
    m: MarketNameSchema,
    b: z.array(orderbookQuantitySchema),
    a: z.array(orderbookQuantitySchema),
  })
  .transform((value) => {
    return {
      market: value.m,
      bid: value.b,
      ask: value.a,
    }
  })

export const EnvelopedOrderBookUpdateSchema = addStreamEnvelope(orderBookUpdateSchema)
