import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, Flex, styled } from '@x10/lib-styled-system/jsx'
import { Portal, Tooltip } from '@x10/lib-ui-kit/components'

import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'

type OrderBookTableBidAskRatioProps = {
  bidRatio: number
  askRatio: number
}

export const OrderBookTableTotal: FC<OrderBookTableBidAskRatioProps> = ({
  bidRatio,
  askRatio,
}) => {
  const formatPercent = useFormatPercent()

  return (
    <Tooltip.Root lazyMount unmountOnExit closeDelay={0} openDelay={200}>
      <Tooltip.Trigger asChild>
        <Flex textStyle="small">
          <Box
            pl={bidRatio > 0 ? 's-16' : 0}
            h="1.5rem"
            bg="token.green-10-opaque"
            position="relative"
            style={{ flex: bidRatio }}
          />

          <Box
            pr={askRatio > 0 ? 's-16' : 0}
            h="1.5rem"
            bg="token.red-10-opaque"
            position="relative"
            style={{ flex: askRatio }}
          />

          <Flex
            h="1.5rem"
            position="absolute"
            left="s-16"
            right="s-16"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              <FormattedMessage
                id="workspace.trade.widget.order-book.total.bid.title"
                defaultMessage="Bid"
              />
              &nbsp;
              <styled.span color="token.green">{formatPercent(bidRatio)}</styled.span>
            </Box>

            <Box>
              <styled.span color="token.red">{formatPercent(askRatio)}</styled.span>
              &nbsp;
              <FormattedMessage
                id="workspace.trade.widget.order-book.total.ask.title"
                defaultMessage="Ask"
              />
            </Box>
          </Flex>
        </Flex>
      </Tooltip.Trigger>
      <Portal>
        <Tooltip.Positioner>
          <Tooltip.Content>
            <FormattedMessage
              id="workspace.trade.widget.order-book.total.tooltip"
              defaultMessage="The value of the top 20 bid orders compared to the value of the top 20 ask orders, compared in real time."
            />
          </Tooltip.Content>
        </Tooltip.Positioner>
      </Portal>
    </Tooltip.Root>
  )
}
