import { type FC } from 'react'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { css } from '@x10/lib-styled-system/css'
import { Box } from '@x10/lib-styled-system/jsx'
import {
  Scrollable,
  SvgIcon,
  TableHeaderCell,
  TableHeaderSortDirectionIcon,
} from '@x10/lib-ui-kit/components'

import { useAssetOperations } from '@src/domain/api/hooks/account/use-asset-operations'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { rowStyle, TradingHistoryTab } from '../common'
import { EmptyMessage } from '../components/empty-message'
import { HeaderSpacer } from '../components/header-spacer'
import { StickyTableHeader } from '../components/sticky-table-header'
import { useTradingHistoryTableFiltersStore } from '../hooks/use-trading-history-table-filters'
import { useColumns } from './trading-history-transfers-table.columns'

const TIME_COLUMN_ID = 'time'

export const TradingHistoryTransfersTable: FC = () => {
  const transfersFilters = useTradingHistoryTableFiltersStore((state) => state.transfers)

  const { data } = useAssetOperations({
    type: transfersFilters.transferAction,
    status: transfersFilters.transferStatus,
    period: transfersFilters.period,
  })

  const columns = useColumns()
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  if (data.length === 0) {
    return <EmptyMessage tab={TradingHistoryTab.Transfers} />
  }

  return (
    <TradeWidget.ContentFixed>
      <Scrollable scrollX scrollY css={{ flex: 1, px: 's-8' }}>
        <table width="100%">
          <StickyTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sortDirection = header.id === TIME_COLUMN_ID && 'desc'

                  return (
                    <TableHeaderCell
                      key={header.id}
                      sortDirection={sortDirection}
                      style={{ width: header.getSize() }}
                    >
                      <HeaderSpacer>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <TableHeaderSortDirectionIcon sortDirection={sortDirection} />
                      </HeaderSpacer>
                    </TableHeaderCell>
                  )
                })}
              </tr>
            ))}
          </StickyTableHeader>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className={css(rowStyle)}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Scrollable>

      <FeatureGate name="SHOW_TRADING_HISTORY_TABLES_MORE">
        <Box pl="s-12" w="s-24">
          <SvgIcon.SvgIconMore />
        </Box>
      </FeatureGate>
    </TradeWidget.ContentFixed>
  )
}
