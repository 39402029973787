const CHECK_INTERVAL = 100
const RETRIES_COUNT = 10

export const waitFor = (
  predicate: () => boolean,
  checkInterval = CHECK_INTERVAL,
): Promise<void> =>
  new Promise((resolve, reject) => {
    let retriesCount = 0

    const checkPredicate = () => {
      if (predicate()) {
        resolve()
      } else {
        retriesCount++

        if (retriesCount >= RETRIES_COUNT) {
          reject(`Predicate is not true after ${RETRIES_COUNT} retries`)
        } else {
          setTimeout(checkPredicate, checkInterval)
        }
      }
    }

    checkPredicate()
  })

export const waitForGlobalProperty = (propertyName: string): Promise<void> =>
  waitFor(() => propertyName in window)
