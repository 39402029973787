import { addDays } from 'date-fns'

import type { CryptoCurrencyCollateralCode } from '@x10/lib-core/config'
import { checkRequired, Long, type Decimal } from '@x10/lib-core/utils'

import { calcStarkExpiration } from '../utils/calc-stark-expiration'
import { generateNonce } from '../utils/generate-nonce'
import { StarkTransferSettlement } from './stark-transfer-settlement'
import type { TransferContext } from './types'

const TRANSFER_EXPIRATION_DAYS = 7

export class StarkTransfer {
  private readonly fromAccountId: Long
  private readonly toAccountId: Long
  private readonly amount: Decimal
  private readonly transferredAsset: CryptoCurrencyCollateralCode
  private readonly settlement: StarkTransferSettlement

  private constructor({
    fromAccountId,
    toAccountId,
    amount,
    transferredAsset,
    settlement,
  }: {
    fromAccountId: Long
    toAccountId: Long
    amount: Decimal
    transferredAsset: CryptoCurrencyCollateralCode
    settlement: StarkTransferSettlement
  }) {
    this.fromAccountId = fromAccountId
    this.toAccountId = toAccountId
    this.amount = amount
    this.transferredAsset = transferredAsset
    this.settlement = settlement
  }

  toJSON() {
    return {
      fromAccount: this.fromAccountId.toString(10),
      toAccount: this.toAccountId.toString(10),
      amount: this.amount.toString(10),
      transferredAsset: this.transferredAsset,
      settlement: this.settlement.toJSON(),
    }
  }

  static create({
    fromAccountId,
    toAccountId,
    amount,
    transferredAsset,
    ctx,
  }: {
    fromAccountId: Long
    toAccountId: Long
    amount: Decimal
    transferredAsset: CryptoCurrencyCollateralCode
    ctx: TransferContext
  }) {
    const fromAccount = checkRequired(
      ctx.accounts.find((account) => account.accountId.eq(fromAccountId)),
      'fromAccount',
    )
    const toAccount = checkRequired(
      ctx.accounts.find((account) => account.accountId.eq(toAccountId)),
      'toAccount',
    )
    const expiryEpochMillis = addDays(new Date(), TRANSFER_EXPIRATION_DAYS).getTime()
    const starkAmount = amount
      .times(ctx.l2Config.collateralResolution)
      .toIntegerValueExact()

    return new StarkTransfer({
      fromAccountId,
      toAccountId,
      amount,
      transferredAsset,
      settlement: StarkTransferSettlement.create({
        amount: starkAmount,
        assetId: ctx.l2Config.collateralId,
        expirationTimestamp: calcStarkExpiration(expiryEpochMillis),
        nonce: Long(generateNonce()),
        receiverPositionId: Long(toAccount.l2Vault),
        receiverPublicKey: toAccount.l2Key,
        senderPositionId: Long(fromAccount.l2Vault),
        senderPublicKey: fromAccount.l2Key,
        starkPrivateKey: ctx.starkPrivateKey,
      }),
    })
  }
}
