import { useMemo, type FC } from 'react'

import type { CryptoCurrencyCode } from '@x10/lib-core/config'
import { useIntl } from '@x10/lib-core/i18n'
import { Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { InputMessage, Portal, Select, ShevronTrigger } from '@x10/lib-ui-kit/components'

import { getOrderTriggerPriceIntlKey } from '@src/domain/i18n/utils/get-order-trigger-price-intl-key'
import type { OrderTriggerPriceType } from '@src/domain/starkex/stark-perpetual-order'
import { getPlaceholderForPrecision } from '@src/domain/trade/utils/get-placeholder-for-precision'

import { MoneyInput } from '../money-input'

const useTriggerPriceOptions = () => {
  const { formatMessage } = useIntl()

  return useMemo<Array<{ label: string; value: OrderTriggerPriceType }>>(() => {
    return [
      {
        label: formatMessage(getOrderTriggerPriceIntlKey('MARK')),
        value: 'MARK',
      },
      {
        label: formatMessage(getOrderTriggerPriceIntlKey('LAST')),
        value: 'LAST',
      },
      {
        label: formatMessage(getOrderTriggerPriceIntlKey('INDEX')),
        value: 'INDEX',
      },
    ]
  }, [formatMessage])
}

type TriggerPriceInputProps = {
  value: Decimal | null
  valueType: OrderTriggerPriceType
  currency: CryptoCurrencyCode
  precision: number
  disabled?: boolean
  error?: string
  onBlur?: () => void
  onValueChange: (value: Decimal | null) => void
  onValueTypeChange: (value: OrderTriggerPriceType) => void
}

export const TriggerPriceInput: FC<TriggerPriceInputProps> = ({
  value,
  valueType,
  currency,
  precision,
  disabled,
  error,
  onBlur,
  onValueChange,
  onValueTypeChange,
}) => {
  const { formatMessage } = useIntl()
  const triggerPriceOptions = useTriggerPriceOptions()

  return (
    <Box aria-invalid={Boolean(error)} className="group">
      <HStack css={{ w: '100%', gap: 's-2' }}>
        <MoneyInput.Item
          value={Decimal.toNullableNumber(value)}
          currency={currency}
          precision={precision}
          placeholder={getPlaceholderForPrecision(precision)}
          label={formatMessage({
            id: 'workspace.trade.widget.order-form.shared.input.trigger-price.title',
            defaultMessage: 'Trigger Price',
          })}
          disabled={disabled}
          onBlur={onBlur}
          onChange={(value) => onValueChange(Decimal.fromNullableValue(value))}
        />

        <Select.Root
          minW="6.25rem"
          positioning={{ sameWidth: true }}
          items={triggerPriceOptions}
          value={[valueType]}
          disabled={disabled}
          onValueChange={(item) => {
            onValueTypeChange(item.value[0] as OrderTriggerPriceType)
          }}
        >
          <Select.Context>
            {({ open }) => {
              return (
                <>
                  <Select.Control>
                    <Select.Trigger>
                      <Select.ValueText />
                      <ShevronTrigger open={open} />
                    </Select.Trigger>
                  </Select.Control>

                  <Portal>
                    <Select.Positioner>
                      <Select.Content>
                        {triggerPriceOptions.map((item) => {
                          return (
                            <Select.Item key={item.value} item={item}>
                              <Select.ItemText>{item.label}</Select.ItemText>
                            </Select.Item>
                          )
                        })}
                      </Select.Content>
                    </Select.Positioner>
                  </Portal>
                </>
              )
            }}
          </Select.Context>
        </Select.Root>
      </HStack>

      <InputMessage>{error}</InputMessage>
    </Box>
  )
}
