import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import { type UserOrder } from '@src/domain/api/x10'

export const getOrderTypeIntlKey = (value: UserOrder['type']): MessageDescriptor => {
  switch (value) {
    case 'LIMIT':
      return {
        id: 'common.order.type.limit',
        defaultMessage: 'Limit',
      }
    case 'MARKET':
      return {
        id: 'common.order.type.market',
        defaultMessage: 'Market',
      }
    case 'CONDITIONAL':
      return {
        id: 'common.order.type.conditional',
        defaultMessage: 'Conditional',
      }
    case 'TPSL':
      return {
        id: 'common.order.type.tpsl',
        defaultMessage: 'TP/SL',
      }
    default:
      throw notReachable(value)
  }
}
