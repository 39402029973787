import type { Row } from '@tanstack/react-table'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Button } from '@x10/lib-ui-kit/components'

import {
  useMassCancelPositions,
  useMassCancelPositionsStore,
} from '../hooks/use-mass-cancel-positions'
import type { PositionData } from '../hooks/use-positions-data'

export const HeaderCloseAllPositionsAction = ({
  rows,
}: {
  rows: Array<Row<PositionData>>
}) => {
  const { isMassCancelling } = useMassCancelPositionsStore()
  const { massCancelPositions } = useMassCancelPositions()

  return (
    <Button
      visual="secondary-grey"
      css={{ w: '9.75rem', textStyle: 'caption' }}
      loading={isMassCancelling}
      onClick={() => {
        const positions = rows.map((value) => {
          return value.original.position
        })

        return massCancelPositions(positions)
      }}
    >
      <FormattedMessage
        id="workspace.trade.widget.trading-history.mode.positions.action.close-all-positions.title"
        defaultMessage="Close All Positions"
      />
    </Button>
  )
}
