import { useMutation, useQueryClient } from '@tanstack/react-query'

import { API } from '@src/domain/api/x10'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

import { type MassCancelOrderArgs } from '../../x10/trading/order-management/orders'

export const useMassCancelOrder = () => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()

  const { mutate, isPending } = useMutation({
    mutationFn: (args: MassCancelOrderArgs) => {
      return API.trading.orderManagement.massCancelOrder(args, accountId)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.UserOrders] })
    },
  })

  return {
    massCancelOrder: mutate,
    isMassCancelling: isPending,
  }
}
