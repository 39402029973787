import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'
import { HStack, VStack } from '@x10/lib-styled-system/jsx'
import { Button, Stat, Widget } from '@x10/lib-ui-kit/components'

import { useCancelOrder } from '@src/domain/api/hooks/order-management/use-cancel-order'
import type { OrderPriceType, UserOrder } from '@src/domain/api/x10'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { getOrderPriceIntlKey } from '@src/domain/i18n/utils/get-order-price-intl-key'

type TpSlOrderCardProps = {
  order: UserOrder
  cancelDisabled?: boolean
  onEdit: () => void
}

export const TpSlOrderCard = ({ order, cancelDisabled, onEdit }: TpSlOrderCardProps) => {
  const formatMarketAsset = useFormatMarketAsset({ marketName: order.market })
  const { cancelOrder, isCancelling } = useCancelOrder()

  const renderOrderPrice = (priceType: OrderPriceType, price: Decimal) => {
    if (priceType === 'MARKET') {
      return <FormattedMessage {...getOrderPriceIntlKey(priceType)} />
    }

    return formatMarketAsset({
      amount: price,
      type: 'collateral',
    })
  }

  return (
    <Widget.Root css={{ bg: 'token.white-5' }}>
      <Widget.Content>
        <VStack css={{ gap: 's-16', alignItems: 'stretch' }}>
          <HStack css={{ gap: 's-16' }}>
            <FeatureGate name="POSITION_TP_SL_ORDER_EDIT">
              <Button visual="secondary-grey" css={{ w: 'auto' }} onClick={onEdit}>
                <FormattedMessage id="common.action.edit.title" defaultMessage="Edit" />
              </Button>
            </FeatureGate>

            <Button
              visual="secondary-grey"
              disabled={cancelDisabled}
              loading={isCancelling}
              css={{ w: 'auto' }}
              onClick={() => cancelOrder({ orderId: order.id })}
            >
              <FormattedMessage id="common.action.cancel" defaultMessage="Cancel" />
            </Button>
          </HStack>

          <HStack css={{ justifyContent: 'space-between' }}>
            <Stat.Root>
              <Stat.Title>
                <FormattedMessage
                  id="trade.component.position-tp-sl.order-card.stat.take-profit.title"
                  defaultMessage="Take Profit"
                />
              </Stat.Title>
              {order.takeProfit ? (
                <Stat.Value css={{ color: 'token.green' }}>
                  {'>='}
                  {formatMarketAsset({
                    amount: order.takeProfit.triggerPrice,
                    type: 'collateral',
                  })}
                </Stat.Value>
              ) : (
                <Stat.Value>{EMPTY_CELL_VALUE}</Stat.Value>
              )}
            </Stat.Root>

            <Stat.Root css={{ textAlign: 'right' }}>
              <Stat.Title>
                <FormattedMessage
                  id="trade.component.position-tp-sl.order-card.stat.stop-loss.title"
                  defaultMessage="Stop Loss"
                />
              </Stat.Title>
              {order.stopLoss ? (
                <Stat.Value css={{ color: 'token.red' }}>
                  {'<='}
                  {formatMarketAsset({
                    amount: order.stopLoss.triggerPrice,
                    type: 'collateral',
                  })}
                </Stat.Value>
              ) : (
                <Stat.Value>{EMPTY_CELL_VALUE}</Stat.Value>
              )}
            </Stat.Root>
          </HStack>

          <HStack css={{ justifyContent: 'space-between' }}>
            <Stat.Root>
              <Stat.Title>
                <FormattedMessage
                  id="trade.component.position-tp-sl.order-card.stat.tp-order-price.title"
                  defaultMessage="TP Order Price"
                />
              </Stat.Title>
              {order.takeProfit ? (
                <Stat.SubValue css={{ color: 'token.green' }}>
                  {renderOrderPrice(order.takeProfit.priceType, order.takeProfit.price)}
                </Stat.SubValue>
              ) : (
                <Stat.SubValue>{EMPTY_CELL_VALUE}</Stat.SubValue>
              )}
            </Stat.Root>

            <Stat.Root css={{ textAlign: 'center' }}>
              <Stat.Title>
                <FormattedMessage
                  id="trade.component.position-tp-sl.order-card.stat.size.title"
                  defaultMessage="Size"
                />
              </Stat.Title>
              <Stat.SubValue>
                {order.tpSlType === 'POSITION' ? (
                  <FormattedMessage
                    id="trade.component.position-tp-sl.order-card.stat.size.entire-position.title"
                    defaultMessage="Entire Position"
                  />
                ) : (
                  formatMarketAsset(
                    { amount: order.qty, type: 'synthetic' },
                    { showSymbol: true },
                  )
                )}
              </Stat.SubValue>
            </Stat.Root>

            <Stat.Root css={{ textAlign: 'right' }}>
              <Stat.Title>
                <FormattedMessage
                  id="trade.component.position-tp-sl.order-card.stat.sl-order-price.title"
                  defaultMessage="SL Order Price"
                />
              </Stat.Title>
              {order.stopLoss ? (
                <Stat.SubValue css={{ color: 'token.red' }}>
                  {renderOrderPrice(order.stopLoss.priceType, order.stopLoss.price)}
                </Stat.SubValue>
              ) : (
                <Stat.SubValue>{EMPTY_CELL_VALUE}</Stat.SubValue>
              )}
            </Stat.Root>
          </HStack>
        </VStack>
      </Widget.Content>
    </Widget.Root>
  )
}
