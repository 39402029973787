import { type FC } from 'react'

import { Footer as KitFooter } from '@x10/lib-ui-kit/components'

export const ClosedMainnetFooter: FC = () => {
  return (
    <KitFooter
      css={{
        pl: 's-16',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
      }}
    />
  )
}
