import { token } from '@x10/lib-styled-system/tokens'

const COLOR_GRADIENT_GREEN = '#0f4d29'
const COLOR_GRADIENT_RED = '#692428'
const COLOR_GRID = '#272a30'
const COLOR_MID_LINE = '#bbc4cd'

export const getPresetTokens = () => {
  const tokenColorGreen = token('colors.token.green')
  const tokenColorRed = token('colors.token.red')
  const tokenColorBlack = token('colors.token.black')
  const tokenColorWhite = token('colors.token.white')
  const tokenFontSize10 = token('fontSizes.fs-10')

  return {
    colorGreen: tokenColorGreen,
    colorRed: tokenColorRed,
    colorBlack: tokenColorBlack,
    colorWhite: tokenColorWhite,
    colorGradientGreen: COLOR_GRADIENT_GREEN,
    colorGradientRed: COLOR_GRADIENT_RED,
    colorGrid: COLOR_GRID,
    colorMidLine: COLOR_MID_LINE,
    fontSize10: tokenFontSize10,
  }
}
