import { create } from 'zustand'

import { type Decimal } from '@x10/lib-core/utils'

type NewOrderMaxValueStoreState = {
  orderMaxValue: {
    buy: Decimal
    sell: Decimal
  } | null
}

type NewOrderMaxValueStoreActions = {
  setOrderMaxValue: (
    value: {
      buy: Decimal
      sell: Decimal
    } | null,
  ) => void

  reset: () => void
}

type NewOrderMaxValueStore = Readonly<NewOrderMaxValueStoreState> &
  NewOrderMaxValueStoreActions

const INITIAL_STATE: NewOrderMaxValueStoreState = {
  orderMaxValue: null,
}

export const useNewOrderMaxValueStore = create<NewOrderMaxValueStore>((set) => ({
  ...INITIAL_STATE,

  setOrderMaxValue(value) {
    set({ orderMaxValue: value })
  },

  reset() {
    set(INITIAL_STATE)
  },
}))
