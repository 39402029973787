import { useCallback } from 'react'

import { useFormatDecimal } from '@x10/lib-core/i18n'
import { invariant, type Decimal } from '@x10/lib-core/utils'

import { LEVERAGE_SYMBOL } from '@src/domain/core/config/static'

export const LeveragePrecision = {
  Default: 2,
  MarginSchedule: 1,
} as const

export const useFormatLeverage = () => {
  const formatDecimal = useFormatDecimal()

  return useCallback(
    (
      amount: number | Decimal,
      { showSymbol = true, precision }: { showSymbol?: boolean; precision?: 1 } = {},
    ) => {
      const formattedValue = formatDecimal(amount, {
        minimumFractionDigits: precision ? precision : 0,
        maximumFractionDigits: precision ? precision : LeveragePrecision.Default,
      })

      invariant(formattedValue, '`formattedValue` is required')

      if (!showSymbol) {
        return formattedValue
      }

      return `${formattedValue}${LEVERAGE_SYMBOL}`
    },
    [formatDecimal],
  )
}
