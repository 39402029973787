import { Decimal } from '@x10/lib-core/utils'

import type { OrderSide } from '@src/domain/api/x10/common'
import { MARKET_ORDER_PRICE_SLIPPAGE_PCT } from '@src/domain/core/config/static'

export const calcMarketOrderPrice = (
  side: OrderSide,
  price: Decimal,
  minPriceChange: Decimal,
) => {
  const slippage = price.times(MARKET_ORDER_PRICE_SLIPPAGE_PCT)
  const marketPrice = side === 'BUY' ? price.plus(slippage) : price.minus(slippage)
  const priceRounding = side === 'BUY' ? Decimal.ROUND_UP : Decimal.ROUND_DOWN
  const pricePrecision = minPriceChange.getDecimalPlaces()

  return Decimal.max(
    minPriceChange,
    marketPrice.setDecimalPlaces(pricePrecision, priceRounding),
  )
}
