import { useMemo, useState, type FC } from 'react'

import { useFormatDateTime, useIntl } from '@x10/lib-core/i18n'
import { checkRequired } from '@x10/lib-core/utils'
import { Box } from '@x10/lib-styled-system/jsx'
import { SimpleSelect } from '@x10/lib-ui-kit/components'

import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'

import {
  NewOrderStoreActions,
  useNewOrderStore,
  type NewOrderTimeInForce,
} from '../../store/new-order'
import { GoodTillDateDatePicker } from './good-till-date-date-picker'

const DATE_PICKER_DELAY_MS = 50

export const TimeInForce: FC = () => {
  const { formatMessage } = useIntl()
  const isFeatureEnabled = useIsFeatureEnabled()

  const orderType = useNewOrderStore((state) => state.orderType)
  const priceType = useNewOrderStore((state) => state.priceType)
  const postOnly = useNewOrderStore((state) => state.postOnly)
  const timeInForce = useNewOrderStore((state) => state.timeInForce)
  const expiryTime = useNewOrderStore((state) => state.expiryTime)

  const [isDatePickerOpen, setDatePickerOpen] = useState(false)

  const { formatDate } = useFormatDateTime()
  const selectOptions = useMemo(() => {
    const options: Array<{ label: string; value: NewOrderTimeInForce }> = [
      {
        label: formatMessage({
          id: 'workspace.trade.widget.order-form.shared.input.post-only.good-till-cancel.title',
          defaultMessage: 'Good Till Cancel',
        }),
        value: 'good-till-cancel',
      },
      {
        label: formatMessage({
          id: 'workspace.trade.widget.order-form.shared.input.post-only.good-till-date.title',
          defaultMessage: 'Good Till Date',
        }),
        value: 'good-till-date',
      },
      {
        label: formatMessage({
          id: 'workspace.trade.widget.order-form.shared.input.post-only.immediate-or-cancel.title',
          defaultMessage: 'Immediate or Cancel',
        }),
        value: 'immediate-or-cancel',
      },
      {
        label: formatMessage({
          id: 'workspace.trade.widget.order-form.shared.input.post-only.fill-or-kill.title',
          defaultMessage: 'Fill or Kill',
        }),
        value: 'fill-or-kill',
      },
    ]

    return options.filter((item) =>
      item.value === 'good-till-date'
        ? isFeatureEnabled('ORDER_FORM_ALLOW_GOOD_TILL_DATE')
        : true,
    )
  }, [formatMessage, isFeatureEnabled])

  const isDisabled = postOnly || (orderType === 'CONDITIONAL' && priceType === 'MARKET')

  return (
    <Box>
      <SimpleSelect.Root
        css={{ fontWeight: 'fw-500' }}
        visual="compact"
        positioning={{ sameWidth: false }}
        disabled={isDisabled}
        value={timeInForce}
        options={selectOptions}
        onValueChange={(value) => {
          if (value === 'good-till-date') {
            setTimeout(() => setDatePickerOpen(true), DATE_PICKER_DELAY_MS)
          } else {
            NewOrderStoreActions.setTimeInForce(value, undefined)
          }
        }}
      >
        <SimpleSelect.Control
          value={
            timeInForce === 'good-till-date'
              ? formatDate(checkRequired(expiryTime, 'expiryTime'))
              : undefined
          }
        />

        <SimpleSelect.Content>
          {selectOptions.map((item) => (
            <SimpleSelect.Item key={item.value} item={item} />
          ))}
        </SimpleSelect.Content>
      </SimpleSelect.Root>

      <FeatureGate name="ORDER_FORM_ALLOW_GOOD_TILL_DATE">
        {/*
        FIXME: Triggers `[Violation] 'setTimeout' handler took XXms`
        */}
        <GoodTillDateDatePicker
          open={isDatePickerOpen}
          onChange={(value) => {
            NewOrderStoreActions.setTimeInForce('good-till-date', value)
          }}
          onClose={() => setDatePickerOpen(false)}
        />
      </FeatureGate>
    </Box>
  )
}
