import { CRYPTO_CURRENCY_INFO, type CryptoCurrencyCode } from '@x10/lib-core/config'
import { HStack } from '@x10/lib-styled-system/jsx'
import { Portal, Select, ShevronTrigger } from '@x10/lib-ui-kit/components'

import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'

const COLLATERAL_CURRENCIES_OPTIONS = [
  {
    ...CRYPTO_CURRENCY_INFO['USDC'],
    value: CRYPTO_CURRENCY_INFO['USDC'].code,
    label: CRYPTO_CURRENCY_INFO['USDC'].code,
  },
] as const

export const CreateAccountCurrencySelect = () => {
  return (
    <Select.Root
      css={{
        w: '100%',
      }}
      positioning={{ placement: 'bottom-start', sameWidth: true }}
      items={COLLATERAL_CURRENCIES_OPTIONS}
      defaultValue={[COLLATERAL_CURRENCIES_OPTIONS[0].value]}
      disabled
    >
      <Select.Context>
        {({ open: isCurrencyPickerOpen, value }) => (
          <>
            <Select.Control>
              <Select.Trigger>
                <HStack css={{ gap: 0 }}>
                  <CryptoLogo currency={value[0] as CryptoCurrencyCode} boxSize={24} />
                  <Select.ValueText
                    css={{
                      textStyle: 'primary',
                      ml: 's-8',
                    }}
                  />
                </HStack>
                <ShevronTrigger open={isCurrencyPickerOpen} />
              </Select.Trigger>
            </Select.Control>
            <Portal>
              <Select.Positioner>
                <Select.Content>
                  {COLLATERAL_CURRENCIES_OPTIONS.map((item) => {
                    return (
                      <Select.Item key={item.code} item={item}>
                        <Select.ItemText
                          css={{
                            display: 'flex',
                            gap: 's-8',
                            alignItems: 'center',
                            fontSize: 'fs-16',
                          }}
                        >
                          <CryptoLogo currency={item.code} boxSize={24} />
                          <span>{item.code}</span>
                        </Select.ItemText>
                      </Select.Item>
                    )
                  })}
                </Select.Content>
              </Select.Positioner>
            </Portal>
          </>
        )}
      </Select.Context>
    </Select.Root>
  )
}
