import { useState, type FC } from 'react'

import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { Skeleton, SnapScroll } from '@x10/lib-ui-kit/components'

import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { NowrapText } from '@src/domain/core/ui/components/nowrap-text'
import { appLocalStorage } from '@src/domain/core/utils/storage/app-local-storage'
import { useLatestTradesStore } from '@src/domain/trade/store/latest-trades'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { TradeWidget } from '../../components/trade-widget'
import { MarketStatsItem } from './components/market-stats-item'
import { MarketStatsViewOptions } from './components/market-stats-view-options'
import { ItemId } from './constants'
import { useGetItemProps } from './hooks/use-get-item-props'

type MarketStatsOptionItem = { id: ItemId; active: boolean }

const DEFAULT_ITEMS: MarketStatsOptionItem[] = [
  { id: ItemId.MarkPrice, active: true },
  { id: ItemId.IndexPrice, active: true },
  { id: ItemId.FundingRate, active: true },
  { id: ItemId.Change24h, active: true },
  { id: ItemId.OpenInterestCollateral, active: true },
  { id: ItemId.OpenInterestBase, active: false },
  { id: ItemId.Volume24hCollateral, active: true },
  { id: ItemId.Volume24hBase, active: false },
  { id: ItemId.High24h, active: false },
  { id: ItemId.Low24h, active: false },
]

const getInitialItems = () => {
  const storedItems = appLocalStorage.getItem<MarketStatsOptionItem[]>('market-stats')

  if (storedItems) {
    return storedItems
  }

  return DEFAULT_ITEMS
}

export const MarketStats: FC = () => {
  const market = useSelectedMarket()
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })
  const getItemProps = useGetItemProps()

  const {
    data: marketStats,
    isPending,
    isError,
  } = useMarketStats({ marketName: market.name })
  const { lastPrice } = useLatestTradesStore()

  const [items, setItems] = useState(getInitialItems)

  const isLoading = isPending || isError
  const currentLastPrice = lastPrice ?? marketStats?.lastPrice

  return (
    <TradeWidget.InnerRoot css={{ px: 's-16' }}>
      <HStack css={{ h: '100%' }}>
        <NowrapText
          css={{
            textStyle: 'h2',
            alignSelf: 'center',
          }}
        >
          {currentLastPrice ? (
            formatMarketAsset({
              amount: currentLastPrice,
              type: 'collateral',
            })
          ) : (
            <Skeleton size="small" />
          )}
        </NowrapText>

        <SnapScroll.Root
          css={{
            alignSelf: 'stretch',
          }}
        >
          <SnapScroll.LeftControl />
          <SnapScroll.Content
            css={{
              tabularNums: true,
              h: '100%',
              gap: 's-24',
              alignItems: 'center',
            }}
          >
            {items
              .filter((item) => item.active)
              .map((item) => {
                const itemProps = getItemProps(item.id, marketStats)

                return (
                  <SnapScroll.Item key={item.id}>
                    <MarketStatsItem
                      itemId={item.id}
                      assets={market.assets}
                      pending={isLoading}
                      tooltipIntlKey={itemProps.tooltipIntlKey}
                      popoverContent={itemProps.popoverContent}
                    >
                      {itemProps.content}
                    </MarketStatsItem>
                  </SnapScroll.Item>
                )
              })}
          </SnapScroll.Content>
          <SnapScroll.RightControl />
        </SnapScroll.Root>

        <Box css={{ marginLeft: 'auto' }}>
          <MarketStatsViewOptions
            items={items}
            assets={market.assets}
            onChange={(newItems) => {
              setItems(newItems)
              appLocalStorage.setItem('market-stats', newItems)
            }}
          />
        </Box>
      </HStack>
    </TradeWidget.InnerRoot>
  )
}
