import { useCallback, useEffect, useMemo, useState, type FC } from 'react'

import { CRYPTO_CURRENCY_INFO } from '@x10/lib-core/config'
import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Decimal, invariant } from '@x10/lib-core/utils'
import { Box, HStack, VStack } from '@x10/lib-styled-system/jsx'
import {
  Button,
  Callout,
  Cell,
  Dialog,
  Portal,
  SimpleSelect,
  Skeleton,
  Tooltip,
  ValueChange,
} from '@x10/lib-ui-kit/components'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'
import { useBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import { useGuardedAccountId } from '@src/domain/auth/hooks/use-auth'
import { useFormatUsd } from '@src/domain/core/hooks/use-format-usd'
import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'
import { createNotificationToast } from '@src/domain/core/ui/components/notification'
import { useApproveSpending } from '@src/domain/starkex/hooks/use-approve-spending'
import { useDeposit } from '@src/domain/starkex/hooks/use-deposit'
import { useWalletAllowance } from '@src/domain/starkex/hooks/use-wallet-allowance'
import { useWalletBalance } from '@src/domain/starkex/hooks/use-wallet-balance'

import { DepositMoneyInputWithSlider } from './deposit-money-input-with-slider'

const InputAndSliderSkeleton = () => (
  <VStack
    css={{
      w: '100%',
      gap: 's-16',
    }}
  >
    <Skeleton
      css={{
        borderRadius: 'r-16',
        w: '100%',
        h: '3.5rem',
      }}
    />
    <Box
      css={{
        w: '100%',
        pb: '1.375rem',
      }}
    >
      <Skeleton
        css={{
          h: '1.5rem',
        }}
      />
    </Box>
  </VStack>
)

const DepositDialogCloser = ({
  isDepositConfirmed,
  setOpen,
}: {
  isDepositConfirmed: boolean
  setOpen: (value: boolean) => void
}) => {
  useEffect(() => {
    if (isDepositConfirmed) {
      setOpen(false)
    }
  }, [isDepositConfirmed, setOpen])

  return null
}

export const DepositDialog: FC = () => {
  const formatUsd = useFormatUsd({ usdcSymbol: true })

  const [depositAmount, setDepositAmount] = useState('0')
  const [gaslessDeposit] = useState(false)
  const accounts = useAccounts()
  const accountOptions = accounts.map((acc) => {
    return {
      label: acc.description,
      value: acc.accountId.toString(),
    }
  })
  const accountId = useGuardedAccountId()
  const [toAccountId, setToAccountIdId] = useState(accountId)
  // reset all the state on account change
  useEffect(() => {
    setDepositAmount('0')
  }, [toAccountId])
  const { isLoading, data } = useBalance({ accountId: toAccountId })
  const availableEquity = data?.equity

  const { value: walletAllowance, isLoading: isWalletAllowanceLoading } =
    useWalletAllowance()

  const isSpendingApproved = walletAllowance?.gt(0)
  const spendingLimit = walletAllowance ? walletAllowance : Decimal.ZERO

  const walletBalance = useWalletBalance()

  const isNotGreaterThanMaxBalance = Decimal(depositAmount).lte(walletBalance.value)

  const isFormValid =
    !isWalletAllowanceLoading &&
    !walletBalance.isLoading &&
    Decimal(depositAmount).gt(Decimal.ZERO) &&
    isNotGreaterThanMaxBalance

  const [showValidationError, setShowValidationError] = useState(false)
  const { formatMessage } = useIntl()
  const insufficientFundsError = useMemo(
    () =>
      formatMessage({
        id: 'domain.trade.dialog.deposit.input.error.insufficient-funds',
        defaultMessage: "You don't have sufficient funds to complete the deposit.",
      }),
    [formatMessage],
  )
  const isInsufficientFundsErrorShown = showValidationError && !isNotGreaterThanMaxBalance
  const isAmountZero = Decimal(depositAmount).eq(Decimal.ZERO)

  const { deposit, isDepositPending, isDepositConfirming, isDepositConfirmed } =
    useDeposit()
  const { approveSpending, isApproveSpendingPending, isConfirming, isConfirmed } =
    useApproveSpending()

  useEffect(() => {
    if (isConfirmed) {
      // placeholder should be an approval confirmed notification
      createNotificationToast({
        notification: {
          type: 'DEPOSITS_ENABLED',
        },
      })
    }
  }, [isConfirmed])

  const handleDeposit = useCallback(async () => {
    const accountInfo = accounts.find((acc) => acc.accountId.toString() === toAccountId)
    invariant(accountInfo, 'Account info not found')
    return deposit({
      accountInfo,
      depositAmount: Decimal(depositAmount),
    })
  }, [accounts, deposit, depositAmount, toAccountId])

  return (
    <>
      <Dialog.Context>
        {({ setOpen }) => {
          return (
            <DepositDialogCloser
              isDepositConfirmed={isDepositConfirmed}
              setOpen={setOpen}
            />
          )
        }}
      </Dialog.Context>
      <VStack
        css={{
          mt: 's-16',
          gap: 's-16',
          alignItems: 'start',
        }}
      >
        <SimpleSelect.Root
          css={{
            width: '100%',
          }}
          value={toAccountId}
          options={accountOptions}
          onValueChange={(value) => {
            setToAccountIdId(value)
          }}
        >
          <SimpleSelect.ControlWithLabel label="To X10 Account" />

          <SimpleSelect.Content>
            {accountOptions.map((item) => (
              <SimpleSelect.Item key={item.value} item={item} />
            ))}
          </SimpleSelect.Content>
        </SimpleSelect.Root>
        {isLoading || walletBalance.isLoading ? (
          <InputAndSliderSkeleton />
        ) : (
          <DepositMoneyInputWithSlider
            key={toAccountId} // reset input's internal state on account change
            balance={walletBalance.value}
            currencyInfo={CRYPTO_CURRENCY_INFO['USDC']}
            aria-invalid={isInsufficientFundsErrorShown}
            message={isInsufficientFundsErrorShown ? insufficientFundsError : undefined}
            onBlur={() => setShowValidationError(true)}
            onValueChange={(value) => setDepositAmount(value.toString())}
          />
        )}

        {gaslessDeposit && (
          <Callout>
            <FormattedMessage
              id="domain.trade.dialog.deposit.gasless-deposit-criteria-met.title"
              defaultMessage="Your deposit meets the gasless deposit minimum of {threshold}, X10 will cover the gas fees for this deposit."
              values={{
                threshold: formatUsd(500),
              }}
            />
          </Callout>
        )}
        <Cell.Group textStyle="small">
          <Cell.Item>
            <Tooltip.Root openDelay={300}>
              <Tooltip.Trigger asChild>
                <Cell.Title>
                  <FormattedMessage
                    id="domain.trade.dialog.deposit.cell.available-to-deposit.title"
                    defaultMessage="Available to Deposit"
                  />
                </Cell.Title>
              </Tooltip.Trigger>
              <Portal>
                <Tooltip.Positioner>
                  <Tooltip.Content>
                    <FormattedMessage
                      id="domain.trade.dialog.deposit.tooltip.available-to-deposit.title"
                      defaultMessage="Funds available in the L1 wallet for depositing on X10."
                    />
                  </Tooltip.Content>
                </Tooltip.Positioner>
              </Portal>
            </Tooltip.Root>
            <Cell.Value
              pending={walletBalance.isLoading}
              css={{
                textStyle: 'small',
                fontWeight: 'fw-500',
              }}
            >
              <HStack css={{ gap: 0 }}>
                {!walletBalance.isLoading ? (
                  <>
                    <ValueChange.Root>
                      <ValueChange.Item>
                        {formatUsd(walletBalance.value, { showSymbol: isAmountZero })}
                      </ValueChange.Item>

                      {!isAmountZero ? (
                        <ValueChange.Item>
                          {formatUsd(walletBalance.value.minus(Decimal(depositAmount)))}
                        </ValueChange.Item>
                      ) : null}
                    </ValueChange.Root>
                  </>
                ) : (
                  <Box
                    css={{
                      py: 's-4',
                    }}
                  >
                    <Skeleton size="small" />
                  </Box>
                )}
              </HStack>
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Cell.Title>
              <FormattedMessage
                id="domain.trade.dialog.deposit.cell.equity-on-x10.title"
                defaultMessage="Equity on X10"
              />
            </Cell.Title>

            <Cell.Value
              css={{
                textStyle: 'small',
                fontWeight: 'fw-500',
              }}
            >
              <HStack css={{ gap: 0 }}>
                {availableEquity ? (
                  <>
                    <ValueChange.Root>
                      <ValueChange.Item>
                        {formatUsd(availableEquity, { showSymbol: false })}
                      </ValueChange.Item>
                      <ValueChange.Item>
                        {formatUsd(availableEquity.plus(Decimal(depositAmount)))}
                      </ValueChange.Item>
                    </ValueChange.Root>
                  </>
                ) : (
                  <Box
                    css={{
                      py: 's-4',
                    }}
                  >
                    <Skeleton size="small" />
                  </Box>
                )}
              </HStack>
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Tooltip.Root openDelay={300}>
              <Tooltip.Trigger asChild>
                <Cell.Title>
                  <FormattedMessage
                    id="domain.trade.dialog.withdraw.cell.arrival-eta.title"
                    defaultMessage="Estimated Time of Arrival"
                  />
                </Cell.Title>
              </Tooltip.Trigger>
              <Portal>
                <Tooltip.Positioner>
                  <Tooltip.Content>
                    <FormattedMessage
                      id="domain.trade.dialog.deposit.tooltip.estimated-time-of-arrival.title"
                      defaultMessage="Estimated time for the deposit to be confirmed on Ethereum L1."
                    />
                  </Tooltip.Content>
                </Tooltip.Positioner>
              </Portal>
            </Tooltip.Root>
            <Cell.Value>
              <FormattedMessage
                id="domain.trade.dialog.deposit.cell.arrival-eta.value.title"
                defaultMessage="2 minutes"
              />
            </Cell.Value>
          </Cell.Item>
        </Cell.Group>
      </VStack>

      {isSpendingApproved && spendingLimit.gte(depositAmount) ? (
        <Button
          css={{
            mt: 's-32',
          }}
          visual="primary-green"
          size="large"
          w="100%"
          disabled={!isFormValid}
          loading={isDepositPending || isDepositConfirming}
          onClick={handleDeposit}
        >
          <FormattedMessage
            id="domain.trade.dialog.deposit.action.deposit.title"
            defaultMessage="Deposit"
          />
        </Button>
      ) : (
        <Tooltip.Root openDelay={300}>
          <Tooltip.Trigger asChild>
            <Button
              visual="primary-green"
              css={{
                mt: 's-32',
              }}
              size="large"
              w="100%"
              loading={
                isApproveSpendingPending ||
                isConfirming ||
                isWalletAllowanceLoading ||
                walletBalance.isLoading
              }
              onClick={() => approveSpending()}
            >
              <CryptoLogo currency="USDC" boxSize={24} />
              <Box
                css={{
                  ml: 's-8',
                }}
              >
                <FormattedMessage
                  id="domain.trade.dialog.withdraw.action.deposit-enable.title"
                  defaultMessage="Enable USDC Deposits"
                />
              </Box>
            </Button>
          </Tooltip.Trigger>
          <Portal>
            <Tooltip.Positioner>
              <Tooltip.Content>
                <FormattedMessage
                  id="domain.trade.dialog.deposit.tooltip.deposit-action.title"
                  defaultMessage="Set or increase your deposit cap to enable USDC deposits to X10."
                />
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Portal>
        </Tooltip.Root>
      )}
    </>
  )
}
