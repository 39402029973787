import { useCallback, useEffect, useState, type FC } from 'react'
import { useAccount, useDisconnect } from 'wagmi'

import { Dialog, Portal } from '@x10/lib-ui-kit/components'
import { useDisclosure } from '@x10/lib-ui-kit/hooks'

import { useCheckWalletEligibility } from '@src/domain/auth/hooks/use-auth'
import {
  useWalletEligibilityStore,
  walletEligibilityActions,
} from '@src/domain/auth/store/wallet-eligibility'
import { DialogContent } from '@src/domain/core/ui/components/dialog/dialog-content'

import { ReferralCodeStepEligible } from './referral-code-step-eligible'
import { ReferralCodeStepForm } from './referral-code-step-form'

export const ReferralCodeDialog: FC = () => {
  const { address } = useAccount()
  const { disconnectAsync } = useDisconnect()
  const checkWalletEligibility = useCheckWalletEligibility()

  const status = useWalletEligibilityStore(({ status }) => status)
  const isClosedMainnetBannerVisible = useWalletEligibilityStore(
    ({ isClosedMainnetBannerVisible }) => isClosedMainnetBannerVisible,
  )

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [step, setStep] = useState<'CODE_FORM' | 'WALLET_ELIGIBLE'>('CODE_FORM')

  useEffect(() => {
    if (address && status !== 'REFERRAL_CODE_REQUIRED') {
      checkWalletEligibility(address).then((eligibilityResult) => {
        if (eligibilityResult.isErr()) {
          walletEligibilityActions.setReferralCodeRequired()
        } else {
          walletEligibilityActions.triggerPostAuthUnlocking()
        }
      })
    }
  }, [status, address, checkWalletEligibility, isClosedMainnetBannerVisible])

  useEffect(() => {
    switch (status) {
      case 'REFERRAL_CODE_REQUIRED':
        onOpen()
        break
      case 'ELIGIBLE':
        // Show wallet eligibility message only if the user was requested
        // to provide a referral code
        setStep('WALLET_ELIGIBLE')
        onOpen()
        break
      case 'ELIGIBLE_CONFIRMED':
        break
      default:
    }
  }, [status, onOpen])

  const handleCloseAndDisconnect = useCallback(() => {
    disconnectAsync().finally(() => {
      walletEligibilityActions.reset()
      onClose()
    })
  }, [disconnectAsync, onClose])

  const handleSuccessfulCodeSubmit = useCallback(() => {
    onClose()
    walletEligibilityActions.startUnlocking()
  }, [onClose])

  const renderStep = () => {
    switch (step) {
      case 'CODE_FORM':
        return (
          <ReferralCodeStepForm
            onSuccessfulSubmit={handleSuccessfulCodeSubmit}
            onClose={handleCloseAndDisconnect}
          />
        )
      case 'WALLET_ELIGIBLE':
        return (
          <ReferralCodeStepEligible
            onClose={() => {
              onClose()
              walletEligibilityActions.finishFlow()
            }}
          />
        )
    }
  }

  return (
    <Dialog.Root open={isOpen} modal lazyMount unmountOnExit>
      <Portal>
        <Dialog.Backdrop />

        <Dialog.Positioner>
          <DialogContent>{renderStep()}</DialogContent>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
