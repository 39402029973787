import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type ConsumerProps,
  type FC,
  type PropsWithChildren,
} from 'react'

import { invariant } from '@x10/lib-core/utils'

type FullScreenContextProps = {
  fullScreen: boolean
  toggleFullScreen: () => void
}

export const FullScreenContext = createContext<FullScreenContextProps | null>(null)

export const FullScreenProvider: FC<PropsWithChildren> = ({ children }) => {
  const [fullScreen, setFullScreen] = useState(false)

  const toggleFullScreen = useCallback(() => {
    setFullScreen((prevValue) => {
      document.body.style.overflow = prevValue ? 'visible' : 'hidden'

      return !prevValue
    })
  }, [])

  const value = useMemo(
    () => ({
      fullScreen,
      toggleFullScreen,
    }),
    [fullScreen, toggleFullScreen],
  )

  return <FullScreenContext.Provider value={value}>{children}</FullScreenContext.Provider>
}

export const FullScreenConsumer = ({
  children,
}: ConsumerProps<FullScreenContextProps>) => {
  const ctx = useFullScreenContext()

  return children(ctx)
}

export const useFullScreenContext = () => {
  const ctx = useContext(FullScreenContext)

  invariant(
    ctx !== null,
    'You can access context only in components inside `FullScreenProvider` component',
  )

  return ctx
}
