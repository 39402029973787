import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'

import type { OrderPriceType } from '@src/domain/starkex/stark-perpetual-order'

export const getOrderPriceIntlKey = (value: OrderPriceType): MessageDescriptor => {
  switch (value) {
    case 'MARKET':
      return {
        id: 'common.price.market',
        defaultMessage: 'Market',
      }
    case 'LIMIT':
      return {
        id: 'common.price.limit',
        defaultMessage: 'Limit',
      }
    default:
      throw notReachable(value)
  }
}
