import { FormattedMessage } from '@x10/lib-core/i18n'
import { getAssetUrl } from '@x10/lib-core/utils'
import { Flex, styled, VStack } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Button,
  Callout,
  Dialog,
  NeedHelpLink,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { DialogContent } from '@src/domain/core/ui/components/dialog/dialog-content'
import { DialogVideoIcon } from '@src/domain/core/ui/components/dialog/dialog-video-icon'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'

export const ClaimFundsDialogContent = ({
  onClaimFunds,
  isClaimPending,
}: {
  onClaimFunds: VoidFunction
  isClaimPending: boolean
}) => {
  return (
    <DialogContent>
      <Flex textStyle="primary" w="100%" justifyContent="space-between" pr="s-32">
        <Dialog.Title>
          <FormattedMessage
            id="workspace.trade.widget.account.action.claim-funds.dialog.title"
            defaultMessage="Funds distribution"
          />
        </Dialog.Title>

        <NeedHelpLink
          href={asExternalRoute(
            documentationRoute({}).testnet({}).claimingCollateral({}).$,
          )}
        />
      </Flex>
      <Dialog.CloseTrigger disabled={isClaimPending} asChild>
        <ActionIcon
          css={{
            top: 's-16',
            right: 's-16',
            pos: 'absolute',
          }}
        >
          <SvgIcon.SvgIconCross />
        </ActionIcon>
      </Dialog.CloseTrigger>
      <VStack
        css={{
          pt: '2.5rem',
        }}
      >
        <DialogVideoIcon
          srcMp4={getAssetUrl({
            type: '3d-icon-video',
            name: 'coin-usdc.mp4',
          })}
          srcWebm={getAssetUrl({
            type: '3d-icon-video',
            name: 'coin-usdc.webm',
          })}
        />

        <styled.h2
          css={{
            textStyle: 'h2',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.action.claim-funds.dialog.info-title"
            defaultMessage="Claim 100,000 test USDC"
          />
        </styled.h2>
        <styled.p
          css={{
            textAlign: 'center',
            textStyle: 'secondary',
            mt: 's-8',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.action.claim-funds.dialog.info-text"
            defaultMessage="Each user is eligible for one distribution in 24 hours."
          />
        </styled.p>
        <Callout
          visual="error"
          css={{
            mt: 's-48',
            px: 's-12',
            py: '0.4375rem',
          }}
        >
          <FormattedMessage
            id="workspace.trade.widget.account.action.claim-funds.dialog.disclaimer"
            defaultMessage="These are virtual funds that can only be used on X10 Testnet."
          />
        </Callout>
      </VStack>
      <Button
        visual="primary-green"
        size="large"
        css={{
          mt: 's-24',
        }}
        loading={isClaimPending}
        onClick={onClaimFunds}
      >
        <FormattedMessage
          id="workspace.trade.widget.account.action.claim-funds.dialog.action"
          defaultMessage="Claim funds"
        />
      </Button>
    </DialogContent>
  )
}
