import { useCallback, useEffect, useRef, useState } from 'react'
import { throttle } from 'lodash'

import { safeParse } from '@x10/lib-core/utils'

import type { MarketName, TradeType } from '@src/domain/api/x10/common'
import {
  EnvelopedPublicTradeSchema,
  type PublicTrade,
} from '@src/domain/api/x10/stream/public-trades.schema'
import { useWebSocket } from '@src/domain/core/hooks/use-web-socket'
import { useLatestTradesStore } from '@src/domain/trade/store/latest-trades'

export const MAX_ENTRIES = 20
const IGNORED_TRADE_TYPES: TradeType[] = ['DELEVERAGE', 'ADL']
const DRAIN_BUFFER_DELAY = 200

export const useProcessData = (buffer: PublicTrade[]) => {
  const [data, setData] = useState<PublicTrade[]>([])

  // eslint-disable-next-line react-hooks/exhaustive-deps -- required because of the `throttle` wrapper
  const drainBuffer = useCallback(
    throttle(
      () => {
        const bufferCopy = buffer.slice(-MAX_ENTRIES).reverse()

        buffer.length = 0

        setData((prev) =>
          ([] as PublicTrade[]).concat(bufferCopy, prev).slice(0, MAX_ENTRIES),
        )
      },
      DRAIN_BUFFER_DELAY,
      { leading: false },
    ),
    [],
  )

  const processEvent = useCallback(
    (event: MessageEvent) => {
      const jsonData = safeParse(event.data)
      const parsedData = EnvelopedPublicTradeSchema.parse(jsonData)

      parsedData.data.forEach((trade) => {
        if (IGNORED_TRADE_TYPES.includes(trade.tradeType)) {
          return
        }

        buffer.push(trade)
      })

      drainBuffer()
    },
    [buffer, drainBuffer],
  )

  const reset = useCallback(() => {
    setData([])
  }, [])

  return { data, processEvent, reset }
}

export const useSubscribeToPublicTrades = (marketName: MarketName) => {
  const buffer = useRef<PublicTrade[]>([])
  const { data, processEvent, reset } = useProcessData(buffer.current)
  const { subscribe, unsubscribe } = useWebSocket()
  const { setLastPrice } = useLatestTradesStore()

  useEffect(() => {
    const topic = { name: 'PUBLIC_TRADES', marketName } as const
    const subscriberUid = subscribe({ topic, callback: processEvent })

    reset()

    return () => {
      subscriberUid.then((id) => unsubscribe(id))
    }
  }, [marketName, processEvent, reset, subscribe, unsubscribe])

  useEffect(() => {
    const firstTrade = data[0]

    if (firstTrade && firstTrade.market === marketName) {
      setLastPrice(firstTrade.price)
    }
  }, [marketName, data, setLastPrice])

  return { data }
}
