import { type FC, type PropsWithChildren } from 'react'

import { Box } from '@x10/lib-styled-system/jsx'

type TradeWidgetContentProps = PropsWithChildren

/**
 * @deprecated
 */
export const TradeWidgetContent: FC<TradeWidgetContentProps> = ({ children }) => {
  return <Box pb={{ base: 's-16', md: '9.375rem' }}>{children}</Box>
}
