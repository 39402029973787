import { FormattedMessage, type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Center } from '@x10/lib-styled-system/jsx'

import { TradingHistoryTab } from '../common'

export const getEmptyStateMessage = (tab: TradingHistoryTab): MessageDescriptor => {
  switch (tab) {
    case TradingHistoryTab.Positions:
      return {
        id: 'workspace.trade.widget.trading-history.placeholder.positions',
        defaultMessage: 'You have no positions.',
      }
    case TradingHistoryTab.OpenOrders:
      return {
        id: 'workspace.trade.widget.trading-history.placeholder.open-orders',
        defaultMessage: 'You have no open orders.',
      }
    case TradingHistoryTab.HistoricalOrders:
      return {
        id: 'workspace.trade.widget.trading-history.placeholder.historical-orders',
        defaultMessage: 'You have no historical orders.',
      }
    case TradingHistoryTab.Trades:
      return {
        id: 'workspace.trade.widget.trading-history.placeholder.trades',
        defaultMessage: 'You have no trades.',
      }
    case TradingHistoryTab.Funding:
      return {
        id: 'workspace.trade.widget.trading-history.placeholder.funding',
        defaultMessage: 'You have no funding payments.',
      }
    case TradingHistoryTab.RealisedPnl:
      return {
        id: 'workspace.trade.widget.trading-history.placeholder.realised-pnl',
        defaultMessage: 'You have no realised PnL.',
      }
    case TradingHistoryTab.Transfers:
      return {
        id: 'workspace.trade.widget.trading-history.placeholder.transfers',
        defaultMessage: 'You have no transfers.',
      }
    default:
      throw notReachable(tab)
  }
}

export const EmptyMessage = ({ tab }: { tab: TradingHistoryTab }) => {
  return (
    <Center
      css={{
        flex: 1,
        color: 'token.white-50',
        textAlign: 'center',
        h: 'full',
      }}
    >
      <FormattedMessage {...getEmptyStateMessage(tab)} />
    </Center>
  )
}
