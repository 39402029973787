import type { PropsWithChildren } from 'react'

import { styled } from '@x10/lib-styled-system/jsx'

export const StickyTableHeader = ({ children }: PropsWithChildren) => {
  return (
    <styled.thead
      css={{ position: 'sticky', top: 0, bg: 'token.grey', zIndex: 'token.app' }}
    >
      {children}
    </styled.thead>
  )
}
