import { token } from '@x10/lib-styled-system/tokens'

export const getPresetTokens = () => {
  const tokenColorGreen = token('colors.token.green')
  const tokenColorWhite = token('colors.token.white')
  const tokenColorWhite5 = token('colors.token.white-5')
  const tokenColorWhite10 = token('colors.token.white-10')
  const tokenFontSize10 = token('fontSizes.fs-10')

  return {
    colorGreen: tokenColorGreen,
    colorWhite: tokenColorWhite,
    colorWhite5: tokenColorWhite5,
    colorWhite10: tokenColorWhite10,
    fontSize10: tokenFontSize10,
  }
}
