import { useOrders } from '@src/domain/api/hooks/account/use-orders'
import type { UserOrder } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'

import { useFilterOrdersForCalc } from './use-filter-orders-for-calc'

export const useOrdersForCalc = (marketName: MarketName): UserOrder[] => {
  const { data: orders } = useOrders({ marketsNames: [marketName] })

  return useFilterOrdersForCalc(orders)
}
