import type { ReactNode } from 'react'

import { type MessageDescriptor } from '@x10/lib-core/i18n'
import { type MarketAssets } from '@x10/lib-core/types'
import { notReachable } from '@x10/lib-core/utils'

import { ItemId } from '../constants'

export const getItemTitleIntlKey = (
  itemId: ItemId,
  assets: MarketAssets,
): MessageDescriptor & { values?: Record<string, ReactNode> } => {
  switch (itemId) {
    case ItemId.MarkPrice:
      return {
        id: 'workspace.trade.widget.market-stats.item.mark-price.title',
        defaultMessage: 'Mark Price',
      }
    case ItemId.IndexPrice:
      return {
        id: 'workspace.trade.widget.market-stats.item.index-price.title',
        defaultMessage: 'Index Price',
      }
    case ItemId.FundingRate:
      return {
        id: 'workspace.trade.widget.market-stats.item.funding-rate.title',
        defaultMessage: 'Funding Rate',
      }
    case ItemId.Change24h:
      return {
        id: 'workspace.trade.widget.market-stats.item.change-24h.title',
        defaultMessage: '24h Change',
      }
    case ItemId.OpenInterestBase:
    case ItemId.OpenInterestCollateral:
      return {
        id: 'workspace.trade.widget.market-stats.item.open-interest.title',
        defaultMessage: 'Open Interest {currency}',
        values: {
          currency:
            itemId === ItemId.OpenInterestBase
              ? assets.synthetic.code
              : assets.collateral.code,
        },
      }
    case ItemId.Volume24hBase:
    case ItemId.Volume24hCollateral:
      return {
        id: 'workspace.trade.widget.market-stats.item.volume-24h.title',
        defaultMessage: '24h Volume {currency}',
        values: {
          currency:
            itemId === ItemId.Volume24hBase
              ? assets.synthetic.code
              : assets.collateral.code,
        },
      }
    case ItemId.High24h:
      return {
        id: 'workspace.trade.widget.market-stats.item.high-24h.title',
        defaultMessage: '24h High',
      }
    case ItemId.Low24h:
      return {
        id: 'workspace.trade.widget.market-stats.item.low-24h.title',
        defaultMessage: '24h Low',
      }
    default:
      return notReachable(itemId)
  }
}
