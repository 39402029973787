import {
  createContext,
  useContext,
  useState,
  type FC,
  type PropsWithChildren,
} from 'react'
import { createStore, useStore, type StoreApi } from 'zustand'

import { invariant, type Decimal } from '@x10/lib-core/utils'

type StoreState = {
  orderCost: Decimal | null
}

type StoreActions = {
  setOrderCost: (value: Decimal | null) => void
}

type Store = StoreApi<StoreState & StoreActions>

const LimitCloseFormContext = createContext<Store | null>(null)

export const LimitCloseFormProvider: FC<PropsWithChildren> = ({ children }) => {
  const [store] = useState<Store>(() => {
    return createStore<StoreState & StoreActions>()((set) => ({
      orderCost: null,
      setOrderCost: (value) => set(() => ({ orderCost: value })),
    }))
  })

  return (
    <LimitCloseFormContext.Provider value={store}>
      {children}
    </LimitCloseFormContext.Provider>
  )
}

export function useLimitCloseFormContext<T>(
  selector: (state: StoreState & StoreActions) => T,
): T {
  const ctx = useContext(LimitCloseFormContext)

  invariant(
    ctx !== null,
    'You can access context only in components inside `LimitCloseFormProvider` component',
  )

  return useStore(ctx, selector)
}
