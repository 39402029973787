import { create } from 'zustand'

export type OrderBookDisplaySizeAs = 'synthetic' | 'collateral'

type OrderBookDisplayParamsStore = {
  displaySizeAs: OrderBookDisplaySizeAs
  aggregationLevel: number

  setDisplaySizeAs: (dataItemKey: OrderBookDisplaySizeAs) => void
  setAggregationLevel: (aggregationLevel: number) => void
}

export const useOrderBookDisplayParamsStore = create<OrderBookDisplayParamsStore>(
  (set) => ({
    displaySizeAs: 'synthetic',
    aggregationLevel: 1,

    setDisplaySizeAs(displaySizeAs) {
      set({ displaySizeAs })
    },

    setAggregationLevel(aggregationLevel) {
      set({ aggregationLevel })
    },
  }),
)
