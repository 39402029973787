import { Fragment, type FC, type ReactNode } from 'react'
import { isEmpty, omit } from 'lodash'

import { FormattedMessage, type MessageDescriptor } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Filter, FilterPeriod, FilterPeriodValue } from '@x10/lib-ui-kit/components'

import {
  type OrderSide,
  type OrderStatus,
  type OrderType,
} from '@src/domain/api/x10/common'
import type {
  AssetOperationStatus,
  AssetOperationType,
} from '@src/domain/api/x10/trading/account/asset-operations.schema'
import { getClientConfig } from '@src/domain/core/config/env'
import { getAssetOperationStatusIntlKey } from '@src/domain/i18n/utils/get-asset-operation-status-intl-key'
import { getAssetOperationTypeIntlKey } from '@src/domain/i18n/utils/get-asset-operation-type-intl-key'
import { getOrderSideIntlKey } from '@src/domain/i18n/utils/get-order-side-intl-key'
import { getOrderStatusIntlKey } from '@src/domain/i18n/utils/get-order-status-intl-key'
import { getOrderTypeIntlKey } from '@src/domain/i18n/utils/get-order-type-intl-key'
import { useFullScreenContext } from '@src/domain/trade/providers/full-screen-provider'

import { TradingHistoryTab } from './common'
import { MarketFilter } from './components/filters/market-filter'
import {
  TradingHistoryTableFiltersStoreActions as FiltersStoreActions,
  useTradingHistoryTableFiltersStore as useFiltersStore,
} from './hooks/use-trading-history-table-filters'

export const getTradingHistoryFilterIntlKey = (
  key: 'orderSide' | 'orderType' | 'orderStatus' | 'transferAction' | 'transferStatus',
): MessageDescriptor => {
  switch (key) {
    case 'orderSide':
      return {
        id: 'workspace.trade.widget.trading-history.filters.side',
        defaultMessage: 'Side',
      }
    case 'orderType':
      return {
        id: 'workspace.trade.widget.trading-history.filters.orderType',
        defaultMessage: 'Order Type',
      }
    case 'orderStatus':
      return {
        id: 'workspace.trade.widget.trading-history.filters.status',
        defaultMessage: 'Status',
      }
    case 'transferAction':
      return {
        id: 'workspace.trade.widget.trading-history.filters.transfer-action',
        defaultMessage: 'Action',
      }
    case 'transferStatus':
      return {
        id: 'workspace.trade.widget.trading-history.filters.transfer-status',
        defaultMessage: 'Status',
      }
    default:
      throw notReachable(key)
  }
}

const getStatusOptions = (statuses: OrderStatus[]) =>
  statuses.map((status: OrderStatus) => ({
    label: <FormattedMessage {...getOrderStatusIntlKey(status)} />,
    value: status,
  }))

const openOrdersDirectionOptions: Array<{ label: ReactNode; value: OrderSide }> = (
  ['BUY', 'SELL'] as const
).map((side: OrderSide) => ({
  label: <FormattedMessage {...getOrderSideIntlKey(side)} />,
  value: side,
}))

const openOrdersStatusOptions: Array<{ label: ReactNode; value: OrderStatus }> =
  getStatusOptions(['NEW', 'PARTIALLY_FILLED', 'UNTRIGGERED'])

const orderTypeOptions: Array<{ label: ReactNode; value: OrderType }> = (
  ['LIMIT', 'CONDITIONAL', 'TPSL'] as const
).map((type: OrderType) => ({
  label: <FormattedMessage {...getOrderTypeIntlKey(type)} />,
  value: type,
}))

const historicalOrdersStatusOptions: Array<{ label: ReactNode; value: OrderStatus }> =
  getStatusOptions(['FILLED', 'CANCELLED', 'EXPIRED', 'REJECTED'])

const getTransferActionOptions = (): Array<{
  label: ReactNode
  value: AssetOperationType
}> => {
  const assetTypes: AssetOperationType[] = [
    'DEPOSIT',
    'SLOW_WITHDRAWAL',
    'FAST_WITHDRAWAL',
    'TRANSFER',
  ]

  if (!getClientConfig().isProdRuntimeEnv) {
    assetTypes.unshift('CLAIM')
  }

  return assetTypes.map((type: AssetOperationType) => ({
    label: <FormattedMessage {...getAssetOperationTypeIntlKey(type)} />,
    value: type,
  }))
}

const transferStatusOptions: Array<{ label: ReactNode; value: AssetOperationStatus }> = (
  ['IN_PROGRESS', 'REJECTED', 'READY_FOR_CLAIM', 'COMPLETED'] as const
).map((type: AssetOperationStatus) => ({
  label: <FormattedMessage {...getAssetOperationStatusIntlKey(type)} />,
  value: type,
}))

const filterOptions = {
  [TradingHistoryTab.OpenOrders]: (
    [
      {
        key: 'orderSide',
        options: openOrdersDirectionOptions,
      },
      {
        key: 'orderType',
        options: orderTypeOptions,
      },
      {
        key: 'orderStatus',
        options: openOrdersStatusOptions,
      },
    ] as const
  ).map((item) => ({
    ...item,
    title: <FormattedMessage {...getTradingHistoryFilterIntlKey(item.key)} />,
  })),
  [TradingHistoryTab.HistoricalOrders]: (
    [
      {
        key: 'orderType',
        options: orderTypeOptions,
      },
      {
        key: 'orderStatus',
        options: historicalOrdersStatusOptions,
      },
    ] as const
  ).map((item) => ({
    ...item,
    title: <FormattedMessage {...getTradingHistoryFilterIntlKey(item.key)} />,
  })),
  [TradingHistoryTab.Transfers]: (
    [
      {
        key: 'transferAction',
        options: getTransferActionOptions(),
      },
      {
        key: 'transferStatus',
        options: transferStatusOptions,
      },
    ] as const
  ).map((item) => ({
    ...item,
    title: <FormattedMessage {...getTradingHistoryFilterIntlKey(item.key)} />,
  })),
}

type Props = {
  tab: TradingHistoryTab
}

export const TradingHistoryFilters: FC<Props> = ({ tab }) => {
  const { fullScreen } = useFullScreenContext()

  const marketFilter = useFiltersStore((state) => state.market)
  const openOrdersFilters = useFiltersStore((state) => state.openOrders)
  const historicalOrdersFilters = useFiltersStore((state) => state.historicalOrders)
  const tradesFilters = useFiltersStore((state) => state.trades)
  const fundingFilters = useFiltersStore((state) => state.funding)
  const realisedPnlFilters = useFiltersStore((state) => state.realisedPnl)
  const transfersFilters = useFiltersStore((state) => state.transfers)

  const MarketFilterContent = (
    <MarketFilter
      marketFilterValue={marketFilter}
      onMarketFilterChange={FiltersStoreActions.setMarket}
    />
  )

  if (tab === TradingHistoryTab.Positions) {
    return MarketFilterContent
  }

  if (tab === TradingHistoryTab.OpenOrders) {
    return (
      <Fragment key={TradingHistoryTab.OpenOrders}>
        {filterOptions[TradingHistoryTab.OpenOrders].map((filter) => (
          <Filter
            key={filter.key}
            visual="ghost"
            label={filter.title}
            placement="top-start"
            options={filter.options}
            value={openOrdersFilters[filter.key]}
            positionerProps={
              fullScreen
                ? {
                    css: {
                      zIndex: 'token.fullscreen',
                    },
                  }
                : undefined
            }
            onApply={(value) => {
              FiltersStoreActions.setOpenOrders(
                isEmpty(value)
                  ? omit(openOrdersFilters, filter.key)
                  : {
                      ...openOrdersFilters,
                      [filter.key]: value,
                    },
              )
            }}
            onClear={() => {
              FiltersStoreActions.setOpenOrders(omit(openOrdersFilters, filter.key))
            }}
          />
        ))}
        {MarketFilterContent}
      </Fragment>
    )
  }

  if (tab === TradingHistoryTab.HistoricalOrders) {
    return (
      <Fragment key={TradingHistoryTab.HistoricalOrders}>
        {filterOptions[TradingHistoryTab.HistoricalOrders].map((filter) => (
          <Filter
            key={filter.key}
            visual="ghost"
            label={filter.title}
            placement="top-start"
            options={filter.options}
            value={historicalOrdersFilters[filter.key]}
            positionerProps={
              fullScreen
                ? {
                    css: {
                      zIndex: 'token.fullscreen',
                    },
                  }
                : undefined
            }
            onApply={(value) => {
              FiltersStoreActions.setHistoricalOrders(
                isEmpty(value)
                  ? omit(historicalOrdersFilters, filter.key)
                  : {
                      ...historicalOrdersFilters,
                      [filter.key]: value,
                    },
              )
            }}
            onClear={() => {
              FiltersStoreActions.setHistoricalOrders(
                omit(historicalOrdersFilters, filter.key),
              )
            }}
          />
        ))}
        <FilterPeriod
          defaultValue={historicalOrdersFilters.period}
          periods={[
            FilterPeriodValue.OneDay,
            FilterPeriodValue.OneWeek,
            FilterPeriodValue.OneMonth,
          ]}
          customPeriod={false}
          onChange={(period) => {
            FiltersStoreActions.setHistoricalOrders({
              ...historicalOrdersFilters,
              period,
            })
          }}
        />
        {MarketFilterContent}
      </Fragment>
    )
  }

  if (tab === TradingHistoryTab.Trades) {
    return (
      <Fragment key={TradingHistoryTab.Trades}>
        <FilterPeriod
          defaultValue={tradesFilters.period}
          periods={[
            FilterPeriodValue.OneDay,
            FilterPeriodValue.OneWeek,
            FilterPeriodValue.OneMonth,
          ]}
          customPeriod={false}
          onChange={(period) => {
            FiltersStoreActions.setTrades({
              period,
            })
          }}
        />
        {MarketFilterContent}
      </Fragment>
    )
  }

  if (tab === TradingHistoryTab.Funding) {
    return (
      <Fragment key={TradingHistoryTab.Funding}>
        <FilterPeriod
          defaultValue={fundingFilters.period}
          periods={[
            FilterPeriodValue.OneDay,
            FilterPeriodValue.OneWeek,
            FilterPeriodValue.OneMonth,
          ]}
          customPeriod={false}
          onChange={(period) => {
            FiltersStoreActions.setFunding({
              period,
            })
          }}
        />
        {MarketFilterContent}
      </Fragment>
    )
  }

  if (tab === TradingHistoryTab.RealisedPnl) {
    return (
      <Fragment key={TradingHistoryTab.RealisedPnl}>
        <FilterPeriod
          defaultValue={realisedPnlFilters.period}
          periods={[
            FilterPeriodValue.OneDay,
            FilterPeriodValue.OneWeek,
            FilterPeriodValue.OneMonth,
          ]}
          customPeriod={false}
          onChange={(period) => {
            FiltersStoreActions.setRealisedPnl({
              period,
            })
          }}
        />
        {MarketFilterContent}
      </Fragment>
    )
  }

  if (tab === TradingHistoryTab.Transfers) {
    return (
      <Fragment key={TradingHistoryTab.Transfers}>
        {filterOptions[TradingHistoryTab.Transfers].map((filter) => (
          <Filter
            key={filter.key}
            visual="ghost"
            label={filter.title}
            placement="top-start"
            options={filter.options}
            value={transfersFilters[filter.key]}
            positionerProps={
              fullScreen
                ? {
                    css: {
                      zIndex: 'z-1!',
                    },
                  }
                : undefined
            }
            onApply={(value) => {
              FiltersStoreActions.setTransfers(
                isEmpty(value)
                  ? omit(transfersFilters, filter.key)
                  : {
                      ...transfersFilters,
                      [filter.key]: value,
                    },
              )
            }}
            onClear={() => {
              FiltersStoreActions.setTransfers(omit(transfersFilters, filter.key))
            }}
          />
        ))}
        <FilterPeriod
          defaultValue={transfersFilters.period}
          periods={[
            FilterPeriodValue.OneDay,
            FilterPeriodValue.OneWeek,
            FilterPeriodValue.OneMonth,
          ]}
          customPeriod={false}
          onChange={(period) => {
            FiltersStoreActions.setTransfers({
              ...transfersFilters,
              period,
            })
          }}
        />
      </Fragment>
    )
  }

  return null
}
