import { useEffect, useMemo } from 'react'
import { erc20Abi } from 'viem'
import { useAccount, useBlockNumber } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'

import type { HexString } from '@x10/lib-core/types'
import { Decimal, invariant } from '@x10/lib-core/utils'

import { useSuspendedAssets } from '@src/domain/api/hooks/markets-info/use-assets'
import { useReadContract } from '@src/domain/core/hooks/use-read-contract'

import { COLLATERAL_ASSET_NAME } from '../constants'
import { fromSmartContractUnits } from '../utils/smart-contract'

export const useWalletBalance = () => {
  const { address } = useAccount()
  const [collateral] = useSuspendedAssets({ assetsNames: [COLLATERAL_ASSET_NAME] })
  const queryClient = useQueryClient()
  const { data: blockNumber } = useBlockNumber({ watch: true })

  invariant(collateral, 'Collateral not defined')

  const {
    data: walletBalanceInt,
    isLoading,
    queryKey,
  } = useReadContract({
    abi: erc20Abi,
    address: collateral.l1Id as HexString,
    functionName: 'balanceOf',
    args: address ? [address] : undefined,
    query: { enabled: Boolean(address) },
  })

  const walletBalance = useMemo(
    () => (walletBalanceInt ? fromSmartContractUnits(walletBalanceInt) : undefined),
    [walletBalanceInt],
  )
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey })
  }, [blockNumber, queryClient, queryKey])

  return { value: walletBalance ?? Decimal.ZERO, isLoading }
}
