import { checkRequired } from '@x10/lib-core/utils'

import { getStorageKey } from '@src/domain/core/utils/storage/get-storage-key'
import {
  type ChartData,
  type ChartMetaInfo,
  type ChartTemplate,
  type ChartTemplateContent,
  type IExternalSaveLoadAdapter,
  type LineToolsAndGroupsLoadRequestContext,
  type LineToolsAndGroupsLoadRequestType,
  type LineToolsAndGroupsState,
  type LineToolState,
  type StudyTemplateData,
  type StudyTemplateMetaInfo,
} from '@src/types/charting-library'

const SAVE_DRAWINGS_ONLY = true
const STORAGE_SYNC_INTERVAL = 1_000

interface SavedChartData extends ChartData {
  timestamp: number
  id: string
}

interface DrawingTemplate {
  name: string
  toolName: string
  content: string
}

interface SavedChartTemplate extends ChartTemplate {
  name: string
}

const StorageKey = {
  Charts: getStorageKey('tv-charts'),
  StudyTemplates: getStorageKey('tv-study-templates'),
  DrawingTemplates: getStorageKey('tv-drawing-templates'),
  ChartTemplates: getStorageKey('tv-chart-templates'),
  DrawingsSourceSymbols: getStorageKey('tv-drawing-source-symbols'),
  Drawings: getStorageKey('tv-drawings'),
} as const

type LayoutDrawings = Record<string, LineToolState>
type SavedDrawings = Record<string, LayoutDrawings>

export class LocalStorageSaveLoadAdapter implements IExternalSaveLoadAdapter {
  private readonly charts: SavedChartData[] = []
  private readonly studyTemplates: StudyTemplateData[] = []
  private readonly drawingTemplates: DrawingTemplate[] = []
  private readonly drawingSourceSymbols: Record<string, string> = {}
  private readonly drawings: SavedDrawings = {}
  private chartTemplates: SavedChartTemplate[] = []
  private isDirty = false

  public constructor() {
    this.charts = this.getFromLocalStorage<SavedChartData[]>(StorageKey.Charts) ?? []
    this.studyTemplates =
      this.getFromLocalStorage<StudyTemplateData[]>(StorageKey.StudyTemplates) ?? []
    this.drawingTemplates =
      this.getFromLocalStorage<DrawingTemplate[]>(StorageKey.DrawingTemplates) ?? []
    this.chartTemplates =
      this.getFromLocalStorage<SavedChartTemplate[]>(StorageKey.ChartTemplates) ?? []
    this.drawingSourceSymbols =
      this.getFromLocalStorage<Record<string, string>>(
        StorageKey.DrawingsSourceSymbols,
      ) ?? {}
    this.drawings = this.getFromLocalStorage<SavedDrawings>(StorageKey.Drawings) ?? {}
  }

  public startStorageSync() {
    setInterval(() => {
      if (this.isDirty) {
        this.saveAllToLocalStorage()
        this.isDirty = false
      }
    }, STORAGE_SYNC_INTERVAL)
  }

  public getAllCharts(): Promise<ChartMetaInfo[]> {
    // @ts-expect-error -- TradingView internal types mismatch
    return Promise.resolve(this.charts)
  }

  public removeChart(id: string | number) {
    for (let i = 0; i < this.charts.length; ++i) {
      const chart = this.charts[i]

      if (chart && chart.id === id) {
        this.charts.splice(i, 1)
        this.isDirty = true

        return Promise.resolve()
      }
    }

    return Promise.reject(new Error('The chart does not exist'))
  }

  public saveChart(chartData: ChartData): Promise<string> {
    if (!chartData.id) {
      chartData.id = this.generateUniqueChartId()
    } else {
      this.removeChart(chartData.id)
    }

    const savedChartData: SavedChartData = {
      ...chartData,
      id: chartData.id,
      timestamp: Math.round(Date.now() / 1_000),
    }

    this.charts.push(savedChartData)
    this.isDirty = true

    return Promise.resolve(savedChartData.id)
  }

  public getChartContent(id: string | number): Promise<string> {
    for (let i = 0; i < this.charts.length; ++i) {
      const chart = this.charts[i]

      if (chart && chart.id === id) {
        return Promise.resolve(chart.content)
      }
    }

    return Promise.reject(new Error('The chart does not exist'))
  }

  public removeStudyTemplate(studyTemplateData: StudyTemplateMetaInfo): Promise<void> {
    for (let i = 0; i < this.studyTemplates.length; ++i) {
      const template = this.studyTemplates[i]

      if (template && template.name === studyTemplateData.name) {
        this.studyTemplates.splice(i, 1)
        this.isDirty = true

        return Promise.resolve()
      }
    }

    return Promise.reject(new Error('The study template does not exist'))
  }

  public getStudyTemplateContent(
    studyTemplateData: StudyTemplateMetaInfo,
  ): Promise<string> {
    for (let i = 0; i < this.studyTemplates.length; ++i) {
      const template = this.studyTemplates[i]

      if (template && template.name === studyTemplateData.name) {
        return Promise.resolve(template.content)
      }
    }

    return Promise.reject(new Error('The study template does not exist'))
  }

  public saveStudyTemplate(studyTemplateData: StudyTemplateData) {
    for (let i = 0; i < this.studyTemplates.length; ++i) {
      const template = this.studyTemplates[i]

      if (template && template.name === studyTemplateData.name) {
        this.studyTemplates.splice(i, 1)
        break
      }
    }

    this.studyTemplates.push(studyTemplateData)
    this.isDirty = true

    return Promise.resolve()
  }

  public getAllStudyTemplates(): Promise<StudyTemplateData[]> {
    return Promise.resolve(this.studyTemplates)
  }

  public removeDrawingTemplate(toolName: string, templateName: string): Promise<void> {
    for (let i = 0; i < this.drawingTemplates.length; ++i) {
      const template = this.drawingTemplates[i]

      if (template && template.name === templateName && template.toolName === toolName) {
        this.drawingTemplates.splice(i, 1)
        this.isDirty = true

        return Promise.resolve()
      }
    }

    return Promise.reject(new Error('The drawing template does not exist'))
  }

  public loadDrawingTemplate(toolName: string, templateName: string): Promise<string> {
    for (let i = 0; i < this.drawingTemplates.length; ++i) {
      const template = this.drawingTemplates[i]

      if (template && template.name === templateName && template.toolName === toolName) {
        return Promise.resolve(template.content)
      }
    }

    return Promise.reject(new Error('The drawing template does not exist'))
  }

  public saveDrawingTemplate(
    toolName: string,
    templateName: string,
    content: string,
  ): Promise<void> {
    for (let i = 0; i < this.drawingTemplates.length; ++i) {
      const template = this.drawingTemplates[i]

      if (template && template.name === templateName && template.toolName === toolName) {
        this.drawingTemplates.splice(i, 1)
        break
      }
    }

    this.drawingTemplates.push({
      name: templateName,
      content: content,
      toolName: toolName,
    })
    this.isDirty = true

    return Promise.resolve()
  }

  public getDrawingTemplates(): Promise<string[]> {
    return Promise.resolve(
      this.drawingTemplates.map(function (template: DrawingTemplate) {
        return template.name
      }),
    )
  }

  public async getAllChartTemplates(): Promise<string[]> {
    return this.chartTemplates.map((x) => x.name)
  }

  public async saveChartTemplate(
    templateName: string,
    content: ChartTemplateContent,
  ): Promise<void> {
    const theme = this.chartTemplates.find((x) => x.name === templateName)

    if (theme) {
      theme.content = content
    } else {
      this.chartTemplates.push({ name: templateName, content })
    }

    this.isDirty = true
  }

  public async removeChartTemplate(templateName: string): Promise<void> {
    this.chartTemplates = this.chartTemplates.filter((x) => x.name !== templateName)
    this.isDirty = true
  }

  public async getChartTemplateContent(templateName: string): Promise<ChartTemplate> {
    const content = this.chartTemplates.find((x) => x.name === templateName)?.content

    return {
      content: structuredClone(content),
    }
  }

  /**
   * Only used if `saveload_separate_drawings_storage` feature is enabled
   */
  public async saveLineToolsAndGroups(
    _layoutId: string,
    chartId: string | number,
    state: LineToolsAndGroupsState,
  ): Promise<void> {
    const drawings = state.sources

    if (!drawings) {
      return
    }

    for (const [key, state] of drawings) {
      const symbolCheckKey = `${chartId}/${key}`
      const symbol = state?.symbol ?? this.drawingSourceSymbols[symbolCheckKey]

      if (!symbol) {
        continue
      }

      if (!this.drawings[symbol]) {
        this.drawings[symbol] = {}
      }

      const rawSources = checkRequired(this.drawings[symbol], 'rawSources')

      if (state === null) {
        delete rawSources[key]
        delete this.drawingSourceSymbols[symbolCheckKey]
      } else {
        rawSources[key] = state
        this.drawingSourceSymbols[symbolCheckKey] = symbol
      }
    }

    this.isDirty = true
  }

  /**
   * Only used if `saveload_separate_drawings_storage` feature is enabled
   */
  public async loadLineToolsAndGroups(
    _layoutId: string | undefined,
    _chartId: string | number,
    _requestType: LineToolsAndGroupsLoadRequestType,
    requestContext: LineToolsAndGroupsLoadRequestContext,
  ): Promise<Partial<LineToolsAndGroupsState> | null> {
    const symbol = requestContext.symbol

    if (!symbol) {
      return null
    }

    const rawSources = this.drawings[symbol]

    if (!rawSources) {
      return null
    }

    const sources = new Map()

    for (const [key, state] of Object.entries(rawSources)) {
      sources.set(key, state)
    }

    return {
      sources,
    }
  }

  protected getFromLocalStorage<T>(key: string): T {
    const dataFromStorage = window.localStorage.getItem(key)

    return JSON.parse(dataFromStorage || 'null')
  }

  protected saveToLocalStorage(key: string, data: unknown): void {
    const dataString = JSON.stringify(data)

    window.localStorage.setItem(key, dataString)
  }

  protected saveAllToLocalStorage(): void {
    if (!SAVE_DRAWINGS_ONLY) {
      this.saveToLocalStorage(StorageKey.Charts, this.charts)
      this.saveToLocalStorage(StorageKey.StudyTemplates, this.studyTemplates)
      this.saveToLocalStorage(StorageKey.DrawingTemplates, this.drawingTemplates)
      this.saveToLocalStorage(StorageKey.ChartTemplates, this.chartTemplates)
    }

    this.saveToLocalStorage(StorageKey.DrawingsSourceSymbols, this.drawingSourceSymbols)
    this.saveToLocalStorage(StorageKey.Drawings, this.drawings)
  }

  private generateUniqueChartId(): string {
    const existingIds = this.charts.map((i) => i.id)

    while (true) {
      const uid = Math.random().toString(16).slice(2)

      if (!existingIds.includes(uid)) {
        return uid
      }
    }
  }
}
