import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Cell, Portal, Tooltip, ValueColored } from '@x10/lib-ui-kit/components'

import { type Balance } from '@src/domain/api/x10'
import { useFormatBalance } from '@src/domain/core/hooks/use-format-balance'
import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { formatValueOverLimit } from '@src/domain/core/utils/format-value-over-limit'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { AccountProtectionPopover } from '../account-protection-popover'

export type AccountDetailsProps = {
  data: Pick<
    Balance,
    'collateralName' | 'equity' | 'unrealisedPnl' | 'initialMargin' | 'marginRatio'
  >
}

export const AccountDetails: FC<AccountDetailsProps> = ({ data }) => {
  const formatBalance = useFormatBalance()
  const formatPercent = useFormatPercent()

  return (
    <TradeWidget.ContentScrollable css={{ px: 's-16', mb: '5rem' }}>
      <Cell.Group css={{ textStyle: 'small', gap: 's-8' }}>
        <Tooltip.Root lazyMount unmountOnExit closeDelay={0} openDelay={100}>
          <Tooltip.Trigger asChild>
            <Cell.Item>
              <Cell.Title>
                <FormattedMessage
                  id="workspace.trade.widget.account.details.unrealized-pnl.title"
                  defaultMessage="Unrealised PnL"
                />
              </Cell.Title>

              <Cell.Value>
                <ValueColored>{formatBalance(data.unrealisedPnl)}</ValueColored>
              </Cell.Value>
            </Cell.Item>
          </Tooltip.Trigger>

          <Portal>
            <Tooltip.Positioner>
              <Tooltip.Content>
                <FormattedMessage
                  id="workspace.trade.widget.account.details.unrealized-pnl.tooltip"
                  defaultMessage="The sum of unrealized PnL across opened positions, calculated as the position size multiplied by the difference between the mark price and the entry price."
                />
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Portal>
        </Tooltip.Root>

        <Tooltip.Root lazyMount unmountOnExit closeDelay={0} openDelay={100}>
          <Tooltip.Trigger asChild>
            <Cell.Item>
              <Cell.Title>
                <FormattedMessage
                  id="workspace.trade.widget.account.details.equity.title"
                  defaultMessage="Equity"
                />
              </Cell.Title>

              <Cell.Value>{formatBalance(data.equity)}</Cell.Value>
            </Cell.Item>
          </Tooltip.Trigger>

          <Portal>
            <Tooltip.Positioner>
              <Tooltip.Content>
                <FormattedMessage
                  id="workspace.trade.widget.account.details.equity.tooltip"
                  defaultMessage="Equity is the sum of the wallet balance and unrealized PnL. The wallet balance is equal to Deposits - Withdrawals + Realised PnL."
                />
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Portal>
        </Tooltip.Root>

        <Tooltip.Root lazyMount unmountOnExit closeDelay={0} openDelay={100}>
          <Tooltip.Trigger asChild>
            <Cell.Item>
              <Cell.Title>
                <FormattedMessage
                  id="workspace.trade.widget.account.details.margin-ratio.title"
                  defaultMessage="Margin Ratio"
                />
              </Cell.Title>

              <Cell.Value>
                {data.marginRatio.gt(1)
                  ? formatValueOverLimit(formatPercent(1))
                  : formatPercent(data.marginRatio)}
              </Cell.Value>
            </Cell.Item>
          </Tooltip.Trigger>

          <Portal>
            <Tooltip.Positioner>
              <Tooltip.Content>
                <FormattedMessage
                  id="workspace.trade.widget.account.details.margin-ratio.tooltip"
                  defaultMessage="The Margin Ratio is calculated as the sum of Maintenance Margin Requirements for all open positions divided by the Equity, multiplied by 100%."
                />
              </Tooltip.Content>
            </Tooltip.Positioner>
          </Portal>
        </Tooltip.Root>

        <FeatureGate name="ACCOUNT_PROTECTION">
          <Cell.Item>
            <Cell.Title>
              <FormattedMessage
                id="workspace.trade.widget.account.details.account-protection.title"
                defaultMessage="Account Protection"
              />
            </Cell.Title>

            <Cell.Value>
              <AccountProtectionPopover />
            </Cell.Value>
          </Cell.Item>
        </FeatureGate>
      </Cell.Group>
    </TradeWidget.ContentScrollable>
  )
}
