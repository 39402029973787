import { useMemo } from 'react'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { css } from '@x10/lib-styled-system/css'
import { Box } from '@x10/lib-styled-system/jsx'
import {
  Scrollable,
  SvgIcon,
  TableHeaderCell,
  TableHeaderSortDirectionIcon,
} from '@x10/lib-ui-kit/components'

import { useTrades } from '@src/domain/api/hooks/account/use-trades'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { checkIfTimestampMatchesPeriod } from '@src/domain/trade/utils/matchers'

import { TradeWidget } from '../../components/trade-widget'
import { rowStyle, TradingHistoryTab } from './common'
import { EmptyMessage } from './components/empty-message'
import { HeaderSpacer } from './components/header-spacer'
import { StickyTableHeader } from './components/sticky-table-header'
import {
  useFilteredMarketsNames,
  useTradingHistoryTableFiltersStore,
} from './hooks/use-trading-history-table-filters'
import { useColumns } from './trading-history-trades-table.columns'

const MARKET_HEADER_ID = 'market'

export const TradingHistoryTradesTable = () => {
  const filteredMarketsNames = useFilteredMarketsNames()
  const tradesFilters = useTradingHistoryTableFiltersStore((state) => state.trades)

  const { data } = useTrades({ marketsNames: filteredMarketsNames })
  const columns = useColumns()

  const filteredData = useMemo(
    () =>
      data.filter(
        (item) =>
          !tradesFilters.period ||
          checkIfTimestampMatchesPeriod(tradesFilters.period, item.createdTime),
      ),
    [data, tradesFilters.period],
  )

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  if (filteredData.length === 0) {
    return <EmptyMessage tab={TradingHistoryTab.Trades} />
  }

  return (
    <TradeWidget.ContentFixed>
      <Scrollable scrollX scrollY css={{ flex: 1, px: 's-8' }}>
        <table width="100%">
          <StickyTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sortDirection = header.id === MARKET_HEADER_ID && 'desc'
                  return (
                    <TableHeaderCell
                      key={header.id}
                      sortDirection={sortDirection}
                      style={{ width: header.getSize() }}
                    >
                      <HeaderSpacer>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <TableHeaderSortDirectionIcon sortDirection={sortDirection} />
                      </HeaderSpacer>
                    </TableHeaderCell>
                  )
                })}
              </tr>
            ))}
          </StickyTableHeader>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className={css(rowStyle)}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Scrollable>

      <FeatureGate name="SHOW_TRADING_HISTORY_TABLES_MORE">
        <Box pl="s-12" w="s-24">
          <SvgIcon.SvgIconMore />
        </Box>
      </FeatureGate>
    </TradeWidget.ContentFixed>
  )
}
