import type { FormValidationErrors, FormValidationFieldName } from './types'

/**
 * @deprecated
 */
export const deprecated_execValidationRules = <T extends string>(
  errors: FormValidationErrors<FormValidationFieldName<T>>,
  rules: Array<[() => unknown, () => false | void]>,
) => {
  for (const rule of rules) {
    if (errors.form) {
      // Skip the rest of the rules if there is a form error
      // (`errors.form` can be set as a side effect of executing a rule)
      break
    }

    const [shouldValidate, validation] = rule

    if (shouldValidate()) {
      validation()
    }
  }
}

type ValidationRule<Value, Context, Computed, FieldName extends string, ErrorMessage> = (
  value: Value,
  ctx: Context,
  computed: Computed,
  alerts: string[],
  errors: FormValidationErrors<FormValidationFieldName<FieldName>>,
  errorMessage: ErrorMessage,
) => void

export const execValidationRules = <
  Value,
  Context,
  Computed,
  FieldName extends string,
  ErrorMessage,
  Rule extends ValidationRule<Value, Context, Computed, FieldName, ErrorMessage>,
>(
  value: Value,
  ctx: Context,
  computed: Computed,
  alerts: string[],
  errors: FormValidationErrors<FormValidationFieldName<FieldName>>,
  errorMessage: ErrorMessage,
  rules: Rule[],
) => {
  for (const rule of rules) {
    if (errors.form) {
      // Skip the rest of the rules if there is a form error
      // (`errors.form` can be set as a side effect of executing a rule)
      break
    }

    rule(value, ctx, computed, alerts, errors, errorMessage)
  }
}
