import { FormattedMessage } from '@x10/lib-core/i18n'
import { TableCell } from '@x10/lib-ui-kit/components'

import type { UserOrder } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'

export const OrderPriceCell = ({ order }: { order: UserOrder }) => {
  const formatMarketAsset = useFormatMarketAsset()

  if (order.type === 'TPSL') {
    const tp = order.takeProfit
    const sl = order.stopLoss

    return (
      <TableCell>
        {tp && (
          <div>
            <FormattedMessage
              id="common.price.table-cell.take-profit.price"
              defaultMessage="TP { priceType, select, MARKET {Market} other {{price}} }"
              values={{
                priceType: tp.priceType,
                price:
                  tp.priceType !== 'MARKET' &&
                  formatMarketAsset(
                    { amount: tp.price, type: 'collateral' },
                    { marketName: order.market },
                  ),
              }}
            />
          </div>
        )}

        {sl && (
          <div>
            <FormattedMessage
              id="common.price.table-cell.stop-loss.price"
              defaultMessage="SL { priceType, select, MARKET {Market} other {{price}} }"
              values={{
                priceType: sl.priceType,
                price:
                  sl.priceType !== 'MARKET' &&
                  formatMarketAsset(
                    { amount: sl.price, type: 'collateral' },
                    { marketName: order.market },
                  ),
              }}
            />
          </div>
        )}
      </TableCell>
    )
  }

  if (order.type === 'CONDITIONAL' && order.trigger?.executionPriceType === 'MARKET') {
    return (
      <TableCell>
        <FormattedMessage
          id="common.price.table-cell.market.value"
          defaultMessage="Market"
        />
      </TableCell>
    )
  }

  return (
    <TableCell>
      {formatMarketAsset(
        {
          amount: order.price,
          type: 'collateral',
        },
        { marketName: order.market },
      )}
    </TableCell>
  )
}
