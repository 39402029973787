import { sortBy } from 'lodash'
import { useSuspenseQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { OrdersHistoryArgs } from '@src/domain/api/x10/trading/account/orders-history'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

export const useOrdersHistory = (args: OrdersHistoryArgs = {}) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(args.marketsNames)

  const { data } = useSuspenseQuery({
    queryKey: [
      QueryKey.UserOrdersHistory,
      sortedMarketsNames,
      args.orderType,
      args.orderSide,
      accountId,
    ],
    queryFn: () => {
      return API.trading.account.getOrdersHistory(
        {
          marketsNames: sortedMarketsNames,
          orderSide: args.orderSide,
          orderType: args.orderType,
        },
        accountId,
      )
    },
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelopeSuspense(data),
  }
}
