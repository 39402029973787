import { Decimal } from '@x10/lib-core/utils'

import type { UserOrder, UserPosition } from '@src/domain/api/x10'
import type { OrderSide } from '@src/domain/api/x10/common'

import { calcInitialMarginRate } from './calc-initial-margin-rate'
import { calcSignedSizeSum } from './calc-signed-size-sum'
import { getSignedSize } from './get-signed-size'

const ROUNDING_PRECISION = 6

export type NewOrder = {
  price: Decimal
  qty: Decimal
  side: OrderSide
}

type CalcOrderCostArgs = {
  leverage: Decimal
  /**
   * Current market position
   */
  position: UserPosition | undefined
  /**
   * Current market orders
   */
  orders: UserOrder[]
  /**
   * New order for the current market
   */
  newOrder: NewOrder
  markPrice: Decimal
}

export const calcOrderCost = ({
  leverage,
  position,
  orders,
  newOrder,
  markPrice,
}: CalcOrderCostArgs) => {
  const initialMarginRate = calcInitialMarginRate(leverage)
  const positionsAndOrdersSizeSum = getSignedSize(position).plus(
    calcSignedSizeSum(orders),
  )

  if (newOrder.side === 'BUY') {
    const base = initialMarginRate
      .times(newOrder.price)
      .times(getSignedSize(newOrder).plus(positionsAndOrdersSizeSum.times(2).minZero()))
      .setDecimalPlaces(ROUNDING_PRECISION, Decimal.ROUND_UP)
    const openLoss = getSignedSize(newOrder)
      .plus(positionsAndOrdersSizeSum.minZero())
      .maxZero()
      .times(markPrice.minus(newOrder.price))
      .minZero()
      .setDecimalPlaces(ROUNDING_PRECISION, Decimal.ROUND_UP)

    return base.minus(openLoss).maxZero()
  } else {
    const base = initialMarginRate
      .negated()
      .times(newOrder.price)
      .times(getSignedSize(newOrder).plus(positionsAndOrdersSizeSum.times(2).maxZero()))
      .setDecimalPlaces(ROUNDING_PRECISION, Decimal.ROUND_UP)
    const openLoss = getSignedSize(newOrder)
      .plus(positionsAndOrdersSizeSum.maxZero())
      .minZero()
      .times(markPrice.minus(newOrder.price))
      .minZero()
      .setDecimalPlaces(ROUNDING_PRECISION, Decimal.ROUND_UP)

    return base.minus(openLoss).maxZero()
  }
}
