import { useCallback } from 'react'
import { erc20Abi } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

import type { HexString } from '@x10/lib-core/types'
import { invariant } from '@x10/lib-core/utils'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useSuspendedAssets } from '@src/domain/api/hooks/markets-info/use-assets'
import { useWriteContract } from '@src/domain/core/hooks/use-write-contract'

import { COLLATERAL_ASSET_NAME } from '../constants'

const UINT_256_MAX = BigInt(
  '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
)

export const useApproveSpending = () => {
  const [collateral] = useSuspendedAssets({ assetsNames: [COLLATERAL_ASSET_NAME] })
  const { starkExContractAddress } = useSettings()

  invariant(collateral, 'Collateral not found')

  const {
    writeContractAsync,
    isPending: isApproveSpendingPending,
    data: txHash,
  } = useWriteContract()

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash: txHash,
    })

  const approveSpending = useCallback(() => {
    return writeContractAsync({
      abi: erc20Abi,
      address: collateral.l1Id as HexString,
      functionName: 'approve',
      args: [starkExContractAddress, UINT_256_MAX],
    })
  }, [collateral.l1Id, starkExContractAddress, writeContractAsync])

  return { approveSpending, isApproveSpendingPending, isConfirming, isConfirmed }
}
