import type { FC, PropsWithChildren } from 'react'

import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { ActionIcon, Popover, SvgIcon } from '@x10/lib-ui-kit/components'

export const PopoverHeader: FC<PropsWithChildren> = ({ children }) => {
  return (
    <HStack justifyContent="space-between" mb="s-24">
      <Box textStyle="primary">{children}</Box>

      <Popover.CloseTrigger asChild>
        <ActionIcon>
          <SvgIcon.SvgIconCross />
        </ActionIcon>
      </Popover.CloseTrigger>
    </HStack>
  )
}
