import * as z from 'zod'

import { addRestEnvelope } from '@src/domain/api/utils/add-rest-envelope'
import { zodDecimal } from '@src/domain/api/utils/zod'

const CandleSchema = z.object({
  o: zodDecimal(),
  l: zodDecimal(),
  h: zodDecimal(),
  c: zodDecimal(),
  v: zodDecimal().optional(),
  T: z.number(),
})

export const EnvelopedCandlesSchema = addRestEnvelope(z.array(CandleSchema))
