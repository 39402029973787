import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'

import type { PositionSide } from '@src/domain/api/x10/common'
import { type OrderSide } from '@src/domain/core/types/common'
import { getOrderSideIntlKey } from '@src/domain/i18n/utils/get-order-side-intl-key'

import { OrderSideColored } from '../order-side-colored'

type OrderSideProps = {
  side: OrderSide | PositionSide
}

export const OrderSideText: FC<OrderSideProps> = ({ side }) => {
  return (
    <OrderSideColored side={side}>
      <FormattedMessage {...getOrderSideIntlKey(side)} />
    </OrderSideColored>
  )
}
