import { type FC } from 'react'

import { Decimal, notReachable } from '@x10/lib-core/utils'
import { cx } from '@x10/lib-styled-system/css'
import { Box, Flex } from '@x10/lib-styled-system/jsx'
import { DEFAULT_TRANSITION_WIDTH } from '@x10/lib-styled-system/presets'
import { pct } from '@x10/lib-ui-kit/utils'

import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { useOrderbookPricesStore } from '@src/domain/trade/store/orderbook-prices-store'

import { useOrderBookDisplayParamsStore } from '../../store'

type OrderBookTableCellProps = {
  mode: 'bid' | 'ask'
  precision: number
  dataItem: AggOrderBookDataItem | undefined
  className?: string
}

const useOrderSize = (dataItem: AggOrderBookDataItem | undefined) => {
  const formatMarketAsset = useFormatMarketAsset()
  const displaySizeAs = useOrderBookDisplayParamsStore((store) => store.displaySizeAs)

  if (!dataItem) {
    return undefined
  }

  switch (displaySizeAs) {
    case 'synthetic':
      return formatMarketAsset({ amount: dataItem.size, type: 'synthetic' })
    case 'collateral':
      return formatMarketAsset(
        { amount: dataItem.sizeCollateral, type: 'collateral' },
        { precision: AssetPrecision.VolumeStat },
      )
    default:
      notReachable(displaySizeAs)
  }
}

export const OrderBookTableCell: FC<OrderBookTableCellProps> = ({
  mode,
  precision,
  dataItem,
  // Support `asChild` (HoverCardTriggerProps)
  className,
  ...restProps
}) => {
  const { setSelectedPrice } = useOrderbookPricesStore()
  const formatMarketAsset = useFormatMarketAsset()

  const orderSize = useOrderSize(dataItem)

  if (!dataItem) {
    switch (mode) {
      case 'bid':
        return (
          <Flex flex={1 / 2} pl="s-16" textStyle="small">
            <Box flex={1 / 2} py="s-2" />
            <Box flex={1 / 2} pr="s-12" />
          </Flex>
        )
      case 'ask':
        return (
          <Flex flex={1 / 2} pr="s-16" textStyle="small">
            <Box flex={1 / 2} pl="s-12" />
            <Box flex={1 / 2} py="s-2" />
          </Flex>
        )
      default:
        return notReachable(mode)
    }
  }

  switch (mode) {
    case 'bid':
      return (
        <Flex
          css={{
            flex: 1 / 2,
            pl: 's-16',
            textStyle: 'small',
            userSelect: 'none',
            position: 'relative',
            _hover: { bg: 'token.white-10', cursor: 'pointer' },
          }}
          onClick={() => {
            setSelectedPrice(Decimal(dataItem.price))
          }}
          {...restProps}
          className={cx('group', className)}
        >
          <Box
            style={{ width: pct(dataItem.sizeSumRatio) }}
            css={{
              transition: DEFAULT_TRANSITION_WIDTH,
              bg: 'token.green-10',
              h: '100%',
              position: 'absolute',
              right: 0,
            }}
          />

          <Box css={{ flex: 1 / 2, py: 's-2' }}>{orderSize}</Box>

          <Box css={{ flex: 1 / 2 }}>
            <Box
              css={{
                position: 'relative',
                color: 'token.green',
                textAlign: 'right',
                py: 's-2',
                pr: 's-12',
                _groupHover: {
                  fontWeight: 'bold',
                },
              }}
            >
              {formatMarketAsset(
                { amount: dataItem.price, type: 'collateral' },
                { precision },
              )}
            </Box>
          </Box>
        </Flex>
      )
    case 'ask':
      return (
        <Flex
          css={{
            flex: 1 / 2,
            pr: 's-16',
            textStyle: 'small',
            userSelect: 'none',
            position: 'relative',
            _hover: { bg: 'token.white-10', cursor: 'pointer' },
          }}
          onClick={() => {
            setSelectedPrice(Decimal(dataItem.price))
          }}
          {...restProps}
          className={cx('group', className)}
        >
          <Box css={{ flex: 1 / 2 }}>
            <Box
              style={{ width: pct(dataItem.sizeSumRatio) }}
              css={{
                transition: DEFAULT_TRANSITION_WIDTH,
                bg: 'token.red-10',
                h: '100%',
                position: 'absolute',
                left: 0,
              }}
            />

            <Box
              css={{
                position: 'relative',
                color: 'token.red',
                py: 's-2',
                pl: 's-12',
                _groupHover: {
                  fontWeight: 'bold',
                },
              }}
            >
              {formatMarketAsset(
                { amount: dataItem.price, type: 'collateral' },
                { precision },
              )}
            </Box>
          </Box>

          <Box css={{ flex: 1 / 2, textAlign: 'right', py: 's-2' }}>{orderSize}</Box>
        </Flex>
      )
    default:
      notReachable(mode)
  }
}

OrderBookTableCell.displayName = 'OrderBookTableCell'
