import { CRYPTO_CURRENCY_INFO, type CryptoCurrencyCode } from '@x10/lib-core/config'
import { FormattedMessage } from '@x10/lib-core/i18n'
import { Portal, Select, ShevronTrigger } from '@x10/lib-ui-kit/components'

import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'

const COLLATERAL_CURRENCIES_OPTIONS = [
  {
    ...CRYPTO_CURRENCY_INFO['USDC'],
    value: CRYPTO_CURRENCY_INFO['USDC'].code,
    label: CRYPTO_CURRENCY_INFO['USDC'].code,
  },
] as const

export const CurrencySelect = () => {
  return (
    <Select.Root
      css={{
        flex: '1 0 0',
        w: '100%',
        mt: 's-16',
      }}
      disabled
      positioning={{ placement: 'bottom-start', sameWidth: true }}
      items={COLLATERAL_CURRENCIES_OPTIONS}
      defaultValue={[COLLATERAL_CURRENCIES_OPTIONS[0].value]}
    >
      <Select.Context>
        {({ open: isCurrencyPickerOpen, value }) => (
          <>
            <Select.Control>
              <Select.Trigger>
                <Select.InlineLabelWrapper>
                  <Select.ValueText
                    textStyle="primary"
                    css={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: 's-4',
                    }}
                  >
                    <CryptoLogo currency={value[0] as CryptoCurrencyCode} boxSize={24} />
                    {value[0]}
                  </Select.ValueText>
                  <Select.InlineLabel>
                    <FormattedMessage
                      id="workspace.trade.widget.account.action.transfer.dialog.inputs.asset.label"
                      defaultMessage="Asset"
                    />
                  </Select.InlineLabel>
                </Select.InlineLabelWrapper>
                <ShevronTrigger open={isCurrencyPickerOpen} />
              </Select.Trigger>
            </Select.Control>
            <Portal>
              <Select.Positioner>
                <Select.Content>
                  {COLLATERAL_CURRENCIES_OPTIONS.map((item) => {
                    return (
                      <Select.Item key={item.code} item={item}>
                        <Select.ItemText
                          css={{
                            alignItems: 'center',
                            display: 'flex',
                            gap: 's-4',
                            fontSize: 'fs-16',
                          }}
                        >
                          <CryptoLogo currency={item.code} boxSize={24} />
                          <span>{item.code}</span>
                        </Select.ItemText>
                      </Select.Item>
                    )
                  })}
                </Select.Content>
              </Select.Positioner>
            </Portal>
          </>
        )}
      </Select.Context>
    </Select.Root>
  )
}
