import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { css } from '@x10/lib-styled-system/css'
import { Box } from '@x10/lib-styled-system/jsx'
import {
  Scrollable,
  SvgIcon,
  TableHeaderCell,
  TableHeaderSortDirectionIcon,
} from '@x10/lib-ui-kit/components'

import { useOrdersHistory } from '@src/domain/api/hooks/account/use-orders-history'
import { FeatureGate } from '@src/domain/core/ui/components/feature-gate'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'
import { checkIfTimestampMatchesPeriod } from '@src/domain/trade/utils/matchers'

import { rowStyle, TradingHistoryTab } from './common'
import { EmptyMessage } from './components/empty-message'
import { HeaderSpacer } from './components/header-spacer'
import { StickyTableHeader } from './components/sticky-table-header'
import {
  useFilteredMarketsNames,
  useTradingHistoryTableFiltersStore,
} from './hooks/use-trading-history-table-filters'
import { useColumns } from './trading-history-orders-history-table.columns'

const MARKET_HEADER_ID = 'market'
const ACTIONS_COLUMN_ID = 'actions'

export const TradingHistoryOrdersHistoryTable = () => {
  const filteredMarketsNames = useFilteredMarketsNames()
  const historicalOrdersFilters = useTradingHistoryTableFiltersStore(
    (state) => state.historicalOrders,
  )

  const { data } = useOrdersHistory({ marketsNames: filteredMarketsNames })
  const columns = useColumns()

  const filteredData = useMemo(
    () =>
      data.filter((item) => {
        if (
          historicalOrdersFilters.orderType &&
          !isEmpty(historicalOrdersFilters.orderType) &&
          !historicalOrdersFilters.orderType.includes(item.type)
        ) {
          return false
        }

        if (
          historicalOrdersFilters.orderStatus &&
          !isEmpty(historicalOrdersFilters.orderStatus) &&
          !historicalOrdersFilters.orderStatus.includes(item.status)
        ) {
          return false
        }

        if (
          historicalOrdersFilters.period &&
          !checkIfTimestampMatchesPeriod(historicalOrdersFilters.period, item.createdTime)
        ) {
          return false
        }

        return true
      }),
    [
      data,
      historicalOrdersFilters.orderStatus,
      historicalOrdersFilters.orderType,
      historicalOrdersFilters.period,
    ],
  )

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  if (filteredData.length === 0) {
    return <EmptyMessage tab={TradingHistoryTab.HistoricalOrders} />
  }

  return (
    <TradeWidget.ContentFixed>
      <Scrollable scrollX scrollY css={{ flex: 1, px: 's-8' }}>
        <table width="100%">
          <StickyTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sortDirection = header.id === MARKET_HEADER_ID && 'desc'
                  return (
                    <TableHeaderCell
                      key={header.id}
                      cellType={header.id === ACTIONS_COLUMN_ID ? 'action' : undefined}
                      sortDirection={sortDirection}
                      style={{ width: header.getSize() }}
                    >
                      <HeaderSpacer>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <TableHeaderSortDirectionIcon sortDirection={sortDirection} />
                      </HeaderSpacer>
                    </TableHeaderCell>
                  )
                })}
              </tr>
            ))}
          </StickyTableHeader>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className={css(rowStyle)}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Scrollable>

      <FeatureGate name="SHOW_TRADING_HISTORY_TABLES_MORE">
        <Box pl="s-12" w="s-24">
          <SvgIcon.SvgIconMore />
        </Box>
      </FeatureGate>
    </TradeWidget.ContentFixed>
  )
}
