import { type ScaleBand } from '@visx/vendor/d3-scale'

export const scaleBandInvert = <Domain extends { toString(): string }>(
  scaleBand: ScaleBand<Domain>,
  coordinate: number,
  defaultValue: Domain,
): Domain => {
  const domain = scaleBand.domain()

  const paddingOuter = scaleBand(domain[0] ?? defaultValue)
  const eachBand = scaleBand.step()

  if (paddingOuter === undefined) {
    return defaultValue
  }

  const index = Math.floor((coordinate - paddingOuter) / eachBand)
  return domain[Math.max(0, Math.min(index, domain.length - 1))] ?? defaultValue
}
