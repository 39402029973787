import { type FC } from 'react'
import { defaultStyles, TooltipWithBounds } from '@visx/tooltip'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { Cell } from '@x10/lib-ui-kit/components'

import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'
import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { calcOrderBookPriceImpact } from '@src/domain/trade/utils/calc/calc-order-book-price-impact'

const tooltipStyles = {
  ...defaultStyles,
  padding: 0,
  borderRadius: 'none',
  background: 'transparent',
}

export type TooltipData = AggOrderBookDataItem

type TooltipProps = {
  data: AggOrderBookDataItem
  midPrice: number | undefined
  top: number
  left: number
}

export const Tooltip: FC<TooltipProps> = ({ data, midPrice, top, left }) => {
  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })
  const formatPercent = useFormatPercent()

  const priceImpact = midPrice
    ? calcOrderBookPriceImpact({ price: data.price, midPrice })
    : undefined

  return (
    <TooltipWithBounds
      // Needed for bounds to update correctly: https://airbnb.io/visx/tooltip
      key={Math.random()}
      top={top}
      left={left}
      style={tooltipStyles}
    >
      <Box
        textStyle="small"
        p="s-16"
        bg="token.grey"
        color="token.white"
        borderColor={data.side === 'BUY' ? 'token.green' : 'token.red'}
        borderWidth={2}
        borderRadius="r-16"
        minW="15.625rem"
      >
        <Cell.Group>
          <Cell.Item>
            <Cell.Title>
              <FormattedMessage
                id="kit.component.depth-chart.tooltip.cell.price.title"
                defaultMessage="Price"
              />
            </Cell.Title>
            <Cell.Value>
              {formatMarketAsset({ amount: data.price, type: 'collateral' })}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Cell.Title>
              <FormattedMessage
                id="kit.component.depth-chart.tooltip.cell.total-size.title"
                defaultMessage="Total Size"
              />
            </Cell.Title>
            <Cell.Value>
              {formatMarketAsset({
                amount: data.sizeSum,
                type: 'synthetic',
              })}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Cell.Title>
              <FormattedMessage
                id="kit.component.depth-chart.tooltip.cell.total-value.title"
                defaultMessage="Total Value"
              />
            </Cell.Title>
            <Cell.Value>
              {formatMarketAsset({
                amount: data.sizeSumCollateral,
                type: 'collateral',
              })}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item>
            <Cell.Title>
              <FormattedMessage
                id="kit.component.depth-chart.tooltip.cell.price-impact.title"
                defaultMessage="Price Impact"
              />
            </Cell.Title>
            <Cell.Value>
              {priceImpact ? formatPercent(priceImpact) : EMPTY_CELL_VALUE}
            </Cell.Value>
          </Cell.Item>
        </Cell.Group>
      </Box>
    </TooltipWithBounds>
  )
}
