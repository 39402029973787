import { Decimal } from '@x10/lib-core/utils'

import type { Market, UserOrder, UserPosition } from '@src/domain/api/x10'
import { calcPositionValue } from '@src/domain/trade/utils/calc/calc-position-value'
import { calcSignedSizeSum } from '@src/domain/trade/utils/calc/calc-signed-size-sum'
import { getSignedSize } from '@src/domain/trade/utils/calc/get-signed-size'

import { calcInitialMarginRate } from './calc-initial-margin-rate'
import { calcMaxPositionValueForLeverageBasedOnRiskFactor } from './calc-max-position-value-for-leverage-based-on-risk-factor'
import { calcOrdersValueSumBySide } from './calc-orders-value-sum-by-side'

/**
 * Prevents user from selecting the maximum possible value for buy/sell
 * using slider, because of the risk of the order not being executed.
 */
const BUFFER_999 = Decimal(0.999)

export type MaxBuySellTradingConfig = Pick<
  Market['tradingConfig'],
  'maxPositionValue' | 'riskFactorConfig'
>

type CalcMaxBuySellArgs = {
  newOrderBuyPrice: Decimal
  newOrderSellPrice: Decimal
  isNewOrderHasQty: boolean
  marketMarkPrice: Decimal
  availableBalance: Decimal
  leverage: Decimal
  position: UserPosition | undefined
  orders: UserOrder[]
  maxOrderValue: Decimal
  tradingConfig: MaxBuySellTradingConfig
}

export const calcMaxBuySell = ({
  newOrderBuyPrice,
  newOrderSellPrice,
  isNewOrderHasQty,
  marketMarkPrice,
  availableBalance,
  leverage,
  position,
  orders,
  maxOrderValue,
  tradingConfig,
}: CalcMaxBuySellArgs): { buy: Decimal; sell: Decimal } => {
  const maxPositionForNewLeverageBasedOnRiskFactor =
    calcMaxPositionValueForLeverageBasedOnRiskFactor({
      leverage,
      riskFactorConfig: tradingConfig.riskFactorConfig,
    })

  if ((!position || orders.length === 0) && !isNewOrderHasQty) {
    const buySell = Decimal.min(
      BUFFER_999.times(availableBalance.maxZero().times(leverage)),
      maxPositionForNewLeverageBasedOnRiskFactor,
      tradingConfig.maxPositionValue,
      maxOrderValue,
    )

    return {
      buy: buySell,
      sell: buySell,
    }
  }

  const initialMarginRate = calcInitialMarginRate(leverage)
  const { longOrdersValueSum, shortOrdersValueSum } = calcOrdersValueSumBySide(orders)
  const positionsAndOrdersSizeSum = getSignedSize(position).plus(
    calcSignedSizeSum(orders),
  )

  const maxBuySize = BUFFER_999.times(
    availableBalance
      .maxZero()
      .minus(
        initialMarginRate
          .times(newOrderBuyPrice)
          .times(positionsAndOrdersSizeSum.times(2).minZero()),
      )
      .plus(
        positionsAndOrdersSizeSum
          .minZero()
          .times(marketMarkPrice.minus(newOrderBuyPrice))
          .maxZero(),
      ),
  ).div(
    initialMarginRate
      .times(newOrderBuyPrice)
      .minus(marketMarkPrice.minus(newOrderBuyPrice).minZero()),
  )
  const positionAndLongOrdersValue = calcPositionValue(position).plus(longOrdersValueSum)
  const maxBuy = Decimal.min(
    maxBuySize.times(newOrderBuyPrice),
    maxPositionForNewLeverageBasedOnRiskFactor.minus(positionAndLongOrdersValue),
    tradingConfig.maxPositionValue.minus(positionAndLongOrdersValue),
    maxOrderValue,
  )

  const maxSellSize = BUFFER_999.times(
    availableBalance
      .maxZero()
      .plus(
        initialMarginRate
          .times(newOrderSellPrice)
          .times(positionsAndOrdersSizeSum.times(2).maxZero()),
      )
      .plus(
        positionsAndOrdersSizeSum
          .maxZero()
          .times(marketMarkPrice.minus(newOrderSellPrice))
          .maxZero(),
      ),
  ).div(
    initialMarginRate
      .negated()
      .times(newOrderSellPrice)
      .minus(marketMarkPrice.minus(newOrderSellPrice).maxZero()),
  )
  const positionAndShortOrdersValue =
    calcPositionValue(position).plus(shortOrdersValueSum)
  const maxSell = Decimal.min(
    maxSellSize.negated().times(newOrderSellPrice),
    maxPositionForNewLeverageBasedOnRiskFactor.plus(positionAndShortOrdersValue),
    tradingConfig.maxPositionValue.plus(positionAndShortOrdersValue),
    maxOrderValue,
  )

  return {
    buy: maxBuy,
    sell: maxSell,
  }
}
