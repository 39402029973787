import { Suspense } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Center } from '@x10/lib-styled-system/jsx'
import { Skeleton, Spinner, Tabs } from '@x10/lib-ui-kit/components'

import { LoggedInGuard } from '@src/domain/auth/components/logged-in-guard'
import { useMaxBuySell } from '@src/domain/trade/ui/widgets/order-form/hooks/use-max-buy-sell'
import { useOrderCost } from '@src/domain/trade/ui/widgets/order-form/hooks/use-order-cost'

import { TradeWidget } from '../../components/trade-widget'
import { AdjustLeveragePopover } from './components/adjust-leverage-popover'
import { ConditionalOrder } from './components/conditional-order'
import { LimitOrder } from './components/limit-order'
import { MarketOrder } from './components/market-order'
import { NewOrderStoreActions, useNewOrderStore, type OrderType } from './store/new-order'

const TabContentFallback = () => (
  <Center h="100px">
    <Spinner />
  </Center>
)

const OrderCalc = () => {
  useMaxBuySell()
  useOrderCost()

  return null
}

const asOrderType = (value: OrderType) => value

export const OrderForm = () => {
  const orderType = useNewOrderStore((state) => state.orderType)
  const orderPlacedSeq = useNewOrderStore((state) => state.ui.orderPlacedSeq)

  return (
    <>
      <Suspense fallback={null}>
        <OrderCalc />
      </Suspense>

      <Tabs.Root
        // FIXME: Replace with a proper clean-up
        key={orderPlacedSeq}
        value={orderType}
        size="lg"
        asChild
        lazyMount
        unmountOnExit
        onValueChange={(e) => {
          NewOrderStoreActions.setOrderType(e.value as OrderType)
        }}
      >
        <TradeWidget.InnerRoot>
          <TradeWidget.Header>
            <Tabs.List>
              <Tabs.Trigger value={asOrderType('LIMIT')}>
                <FormattedMessage
                  id="workspace.trade.widget.order-form.mode.limit.title"
                  defaultMessage="Limit"
                />
              </Tabs.Trigger>
              <Tabs.Trigger value={asOrderType('MARKET')}>
                <FormattedMessage
                  id="workspace.trade.widget.order-form.mode.market.title"
                  defaultMessage="Market"
                />
              </Tabs.Trigger>
              <Tabs.Trigger value={asOrderType('CONDITIONAL')}>
                <FormattedMessage
                  id="workspace.trade.widget.order-form.mode.conditional.title"
                  defaultMessage="Conditional"
                />
              </Tabs.Trigger>
            </Tabs.List>

            <LoggedInGuard fallback={<Skeleton size="small" />}>
              <AdjustLeveragePopover />
            </LoggedInGuard>
          </TradeWidget.Header>

          <Tabs.Content value={asOrderType('LIMIT')} asChild>
            <TradeWidget.ContentScrollable>
              <Suspense fallback={<TabContentFallback />}>
                <LimitOrder />
              </Suspense>
            </TradeWidget.ContentScrollable>
          </Tabs.Content>

          <Tabs.Content value={asOrderType('MARKET')} asChild>
            <TradeWidget.ContentScrollable>
              <Suspense fallback={<TabContentFallback />}>
                <MarketOrder />
              </Suspense>
            </TradeWidget.ContentScrollable>
          </Tabs.Content>

          <Tabs.Content value={asOrderType('CONDITIONAL')} asChild>
            <TradeWidget.ContentScrollable>
              <Suspense fallback={<TabContentFallback />}>
                <ConditionalOrder />
              </Suspense>
            </TradeWidget.ContentScrollable>
          </Tabs.Content>
        </TradeWidget.InnerRoot>
      </Tabs.Root>
    </>
  )
}
