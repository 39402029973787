import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { type X10Interval } from '@src/domain/api/x10/common'
import { getStorageKey } from '@src/domain/core/utils/storage/get-storage-key'

export const SUPPORTED_INTERVALS: X10Interval[] = [
  'PT1M',
  'PT5M',
  'PT15M',
  'PT30M',
  'PT1H',
  'PT2H',
  'PT4H',
  'P1D',
]

export const DEFAULT_FAVORITES: X10Interval[] = ['PT1M', 'PT15M', 'PT30M', 'PT1H', 'PT4H']

type FavoriteIntervalsState = {
  favoriteIntervals: X10Interval[]
  toggleFavoriteInterval: (interval: X10Interval) => void
}

export const useFavoriteIntervals = create(
  persist<FavoriteIntervalsState>(
    (set) => ({
      favoriteIntervals: DEFAULT_FAVORITES,

      toggleFavoriteInterval: (interval) =>
        set((state) => {
          const newSet = new Set(state.favoriteIntervals)
          newSet.has(interval) ? newSet.delete(interval) : newSet.add(interval)
          return {
            favoriteIntervals: SUPPORTED_INTERVALS.filter((interval) =>
              newSet.has(interval),
            ),
          }
        }),
    }),
    {
      name: getStorageKey('favorite-intervals'),
    },
  ),
)
