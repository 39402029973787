import {
  MoneyInput as MoneyInputNew,
  MoneyInputOld,
  type MoneyInputProps,
} from '@x10/lib-ui-kit/components'

import { getClientConfig } from '@src/domain/core/config/env'

export const MoneyInput =
  getClientConfig().runtimeEnv === 'prod' ? MoneyInputOld : MoneyInputNew

export { type MoneyInputProps }
