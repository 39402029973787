import { type FC } from 'react'

import { css } from '@x10/lib-styled-system/css'
import { Flex, type FlexProps } from '@x10/lib-styled-system/jsx'
import { Scrollable } from '@x10/lib-ui-kit/components'

type TradeWidgetHeaderProps = FlexProps

export const TradeWidgetHeader: FC<TradeWidgetHeaderProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Scrollable data-scope="trade-widget" data-part="header" scrollX>
      <Flex
        {...restProps}
        css={css.raw(
          {
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 's-16',
            mb: 's-8',
          },
          restProps.css,
        )}
      >
        {children}
      </Flex>
    </Scrollable>
  )
}
