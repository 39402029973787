import { useMemo } from 'react'
import { groupBy } from 'lodash'

import { useOrdersSuspense } from '@src/domain/api/hooks/account/use-orders'
import { usePositionsSuspense } from '@src/domain/api/hooks/account/use-positions'
import { useMarketsStats } from '@src/domain/api/hooks/markets-info/use-markets-stats'
import type { MarketStats, UserOrder, UserPosition } from '@src/domain/api/x10'
import { type MarketName } from '@src/domain/api/x10/common'

export type PositionData = {
  position: UserPosition
  orders: UserOrder[]
  marketStats?: MarketStats
}

export const usePositionsData = (marketsNames?: MarketName[]) => {
  const { data: positions } = usePositionsSuspense({ marketsNames })
  const { data: orders } = useOrdersSuspense({ marketsNames })
  const { data: marketsStats } = useMarketsStats({ marketsNames })

  return useMemo<PositionData[]>(() => {
    const groupedOrders = groupBy(orders, 'market')

    return positions.map((position) => {
      return {
        position,
        orders: groupedOrders[position.market] ?? [],
        marketStats: marketsStats?.find((value) => value.market === position.market)
          ?.stats,
      }
    })
  }, [positions, orders, marketsStats])
}
