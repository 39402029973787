import { useCallback, useEffect, useRef, type FC } from 'react'

import { checkRequired, Decimal } from '@x10/lib-core/utils'
import { VStack } from '@x10/lib-styled-system/jsx'

import { LoggedInGuard } from '@src/domain/auth/components/logged-in-guard'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidgetContent } from '@src/domain/trade/ui/components/trade-widget'

import { useFormValidationWithCtx } from '../../hooks/use-form-validation'
import { NewOrderStoreActions, useNewOrderStore } from '../../store/new-order'
import { ActionButtons } from '../action-buttons'
import { AdvancedSection } from '../advanced-section'
import { MoneyInputWithSlider } from '../money-input-with-slider'
import { OrderInfo } from '../order-info'
import { OrderPriceInput, type OrderPriceInputApi } from './order-price-input'

export const LimitOrder: FC = () => {
  const market = useSelectedMarket()
  const price = useNewOrderStore((state) => state.buy.price)

  const { form, orderPriceError, orderSizeError } = useFormValidationWithCtx('LIMIT')
  const orderPriceInputApiRef = useRef<OrderPriceInputApi>(null)

  const getPriceInputApi = useCallback(() => {
    return checkRequired(orderPriceInputApiRef.current, 'orderPriceInputApiRef')
  }, [])

  const handleQtyValueChange = useCallback(
    (value: { buy: number; sell: number; pct?: number } | null) => {
      const newBuyQty = Decimal.fromNullableValue(value?.buy)
      const newSellQty = Decimal.fromNullableValue(value?.sell)

      getPriceInputApi().stopFollowingLatestPrice()

      NewOrderStoreActions.setAmountOfSynthetic(newBuyQty, newSellQty, value?.pct)
    },
    [getPriceInputApi],
  )

  useEffect(() => {
    getPriceInputApi().startFollowingLatestPrice()
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market.name, form.reset, getPriceInputApi])

  return (
    <TradeWidgetContent>
      <VStack css={{ px: 's-16', gap: 's-16', alignItems: 'start' }}>
        <OrderPriceInput
          ref={orderPriceInputApiRef}
          assets={market.assets}
          error={orderPriceError}
          onBlur={() => form.touch('orderPrice')}
        />

        <MoneyInputWithSlider
          aria-invalid={Boolean(orderSizeError)}
          message={orderSizeError}
          assets={market.assets}
          syntheticPrice={price}
          onBlur={() => form.touch('orderSize')}
          onSliderChange={() => form.reset(['orderSize'])}
          onValueChange={handleQtyValueChange}
        />

        <OrderInfo marketName={market.name} />
        <LoggedInGuard>
          <AdvancedSection allowPostOnlyAndTimeInForce />
        </LoggedInGuard>
      </VStack>

      <ActionButtons hideBelow="md" />
    </TradeWidgetContent>
  )
}
