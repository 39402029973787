import { type FC } from 'react'

import { DatePicker, Portal, SimpleDatePickerView } from '@x10/lib-ui-kit/components'

import { CSS_STATIC_COLUMN_WIDTH_VAR } from '@src/domain/core/config/static'

export const GoodTillDateDatePicker: FC<{
  open?: boolean
  onChange: (value: Date) => void
  onClose: () => void
}> = ({ open, onChange, onClose }) => {
  return (
    <DatePicker.Root
      open={open}
      positioning={{ offset: { mainAxis: 0, crossAxis: -100 }, placement: 'top' }}
      startOfWeek={1}
      onValueChange={(value) => onChange(value.value[0]!.toDate('UTC'))}
      onOpenChange={(value) => !value.open && onClose()}
    >
      <DatePicker.Control>
        <DatePicker.Trigger />
      </DatePicker.Control>

      <Portal>
        <DatePicker.Positioner
          left="0.3125rem!"
          style={{ width: `var(${CSS_STATIC_COLUMN_WIDTH_VAR})` }}
        >
          <DatePicker.Content w="100%!">
            <SimpleDatePickerView.Day />
            <SimpleDatePickerView.Month />
            <SimpleDatePickerView.Year />
          </DatePicker.Content>
        </DatePicker.Positioner>
      </Portal>
    </DatePicker.Root>
  )
}
