import { FormattedMessage } from '@x10/lib-core/i18n'

import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

export const LatestTradesHeader = () => {
  return (
    <TradeWidget.Header textStyle="primary">
      <FormattedMessage
        id="workspace.trade.widget.latest-trades.header.title"
        defaultMessage="Latest Trades"
      />
    </TradeWidget.Header>
  )
}
