import {
  axiosClient,
  type CandlePriceSource,
  type MarketName,
  type X10Interval,
} from '@src/domain/api/x10/common'

import { EnvelopedCandlesSchema } from './candles.schema'

export const getCandles = async (
  source: CandlePriceSource,
  marketName: MarketName,
  interval: X10Interval,
  limit: number,
  endTime?: number,
) => {
  const { data } = await axiosClient.get<unknown>(
    `/api/v1/info/candles/${marketName}/${source}`,
    {
      params: {
        interval,
        limit,
        endTime,
      },
    },
  )

  return EnvelopedCandlesSchema.parse(data)
}
