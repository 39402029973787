import { type FC } from 'react'
import { first } from 'lodash'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Cell } from '@x10/lib-ui-kit/components'

import { useBalance } from '@src/domain/api/hooks/account/use-balance'
import { useFees } from '@src/domain/api/hooks/account/use-fees'
import type { MarketName } from '@src/domain/api/x10/common'
import { useFormatBalance } from '@src/domain/core/hooks/use-format-balance'
import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'

type OrderInfoProps = {
  marketName: MarketName
}

export const OrderInfo: FC<OrderInfoProps> = ({ marketName }) => {
  const formatBalance = useFormatBalance()
  const formatPercent = useFormatPercent()

  const { data: balance, isLoading: isBalanceLoading } = useBalance()
  const { data: fees, isLoading: isFeesLoading } = useFees({ marketsNames: [marketName] })

  const marketFees = first(fees)

  return (
    <Cell.Group mt="s-4" gap="s-8" fontSize="fs-12">
      <Cell.Item>
        <Cell.Title>
          <FormattedMessage
            id="workspace.trade.widget.order-form.shared.order-info.cell.available-balance.title"
            defaultMessage="Available Balance"
          />
        </Cell.Title>

        <Cell.Value pending={isBalanceLoading}>
          {formatBalance(balance?.availableForTrade)}
        </Cell.Value>
      </Cell.Item>

      <Cell.Item>
        <Cell.Title>
          <FormattedMessage
            id="workspace.trade.widget.order-form.shared.order-info.cell.fee-level.title"
            defaultMessage="Fee Level"
          />
        </Cell.Title>

        <Cell.Value pending={isFeesLoading}>
          {marketFees ? (
            <FormattedMessage
              id="workspace.trade.widget.order-form.shared.order-info.cell.fee-level-value.title"
              defaultMessage="Maker {makerFee} / Taker {takerFee}"
              values={{
                makerFee: formatPercent(marketFees.makerFeeRate),
                takerFee: formatPercent(marketFees.takerFeeRate),
              }}
            />
          ) : null}
        </Cell.Value>
      </Cell.Item>
    </Cell.Group>
  )
}
