import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box, HStack, VStack } from '@x10/lib-styled-system/jsx'
import { DEFAULT_BACKDROP_BLUR_FILTER } from '@x10/lib-styled-system/presets'
import { Button } from '@x10/lib-ui-kit/components'
import type { SemanticColorVar } from '@x10/lib-ui-kit/types'

const VAR_COLORS_TOKEN_WHITE_TRANSPARENT_10: SemanticColorVar = '--colors-token-white-10'

export const RiskAlerts = ({
  alerts,
  confirm,
  close,
}: {
  alerts: string[]
  confirm: () => void
  close: () => void
}) => {
  return (
    <VStack
      css={{
        position: 'absolute',
        bottom: 0,
        w: '100%',
        p: 's-16',
        gap: 's-24',
        borderRadius: 'r-16',
        alignItems: 'stretch',
        bg: 'token.black-80',
        bgImage: `linear-gradient(0deg, var(${VAR_COLORS_TOKEN_WHITE_TRANSPARENT_10}) 0%, var(${VAR_COLORS_TOKEN_WHITE_TRANSPARENT_10}) 100%)`,
        backdropFilter: DEFAULT_BACKDROP_BLUR_FILTER,
      }}
    >
      <Box css={{ textStyle: 'primary' }}>Risk alert</Box>

      <Box css={{ textStyle: 'small' }}>
        {alerts.map((warning, idx) => (
          <div key={idx}>{warning}</div>
        ))}
      </Box>

      <HStack css={{ gap: 's-16' }}>
        <Button visual="secondary-grey" size="large" onClick={close}>
          <FormattedMessage id="common.action.cancel" defaultMessage="Cancel" />
        </Button>
        <Button visual="primary-green" size="large" onClick={confirm}>
          <FormattedMessage id="common.action.confirm" defaultMessage="Confirm" />
        </Button>
      </HStack>
    </VStack>
  )
}
