import { Suspense, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { Skeleton } from '@x10/lib-ui-kit/components'

import { useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { AccountSelector } from './account-selector'

export const AccountHeader: FC = () => {
  const isLoggedIn = useSuspendedIsLoggedIn()

  return (
    <TradeWidget.Header>
      <Box textStyle="primary">
        <FormattedMessage
          id="workspace.trade.widget.account.header.title"
          defaultMessage="Account"
        />
      </Box>

      {isLoggedIn && (
        <Suspense fallback={<Skeleton size="small" />}>
          <AccountSelector />
        </Suspense>
      )}
    </TradeWidget.Header>
  )
}
