import { useEffect, useMemo } from 'react'
import { debounce, first } from 'lodash'

import { useBalance } from '@src/domain/api/hooks/account/use-balance'
import { useLeverage } from '@src/domain/api/hooks/account/use-leverage'
import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import type { CachedMarket } from '@src/domain/api/x10'
import type { OrderType } from '@src/domain/api/x10/common'
import { useOrdersForCalc } from '@src/domain/trade/hooks/use-orders-for-calc'
import { usePositionForCalc } from '@src/domain/trade/hooks/use-position-for-calc'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { NewOrderStoreActions, useNewOrderStore } from '../../store/new-order'
import { useNewOrderCostStore } from '../../store/new-order-cost'
import { useFormValidation } from './use-form-validation'

const VALIDATION_WAIT = 150
const VALIDATION_MAX_WAIT = 1000

const useNewOrder = () => {
  const priceType = useNewOrderStore((state) => state.priceType)
  const buyPrice = useNewOrderStore((state) => state.buy.price)
  const sellPrice = useNewOrderStore((state) => state.sell.price)
  const buyAmountOfSynthetic = useNewOrderStore((state) => state.buy.amountOfSynthetic)
  const sellAmountOfSynthetic = useNewOrderStore((state) => state.sell.amountOfSynthetic)
  const triggerPrice = useNewOrderStore((state) => state.conditional.triggerPrice)
  const triggerPriceType = useNewOrderStore((state) => state.conditional.triggerPriceType)
  const triggerDirection = useNewOrderStore((state) => state.conditional.triggerDirection)

  const { orderCost } = useNewOrderCostStore()

  return useMemo(
    () => ({
      triggerPrice,
      triggerPriceType,
      triggerDirection,
      orderPriceBuy: buyPrice,
      orderPriceSell: sellPrice,
      orderPriceType: priceType,
      orderSizeBuy: buyAmountOfSynthetic,
      orderSizeSell: sellAmountOfSynthetic,
      orderCost,
    }),
    [
      triggerPrice,
      triggerPriceType,
      triggerDirection,
      buyPrice,
      sellPrice,
      priceType,
      buyAmountOfSynthetic,
      sellAmountOfSynthetic,
      orderCost,
    ],
  )
}

const useValidationCtx = (
  market: CachedMarket,
  orderType: OrderType,
  reduceOnly: boolean | undefined,
) => {
  const { data: marketStats } = useMarketStats({ marketName: market.name })
  const { data: balance } = useBalance()
  const { data: leverage } = useLeverage({ marketsNames: [market.name] })
  const position = usePositionForCalc(market.name)
  const orders = useOrdersForCalc(market.name)

  return useMemo(
    () => ({
      market,
      marketStats,
      balance,
      leverage: first(leverage)?.leverage,
      position,
      orders,
      orderType,
      reduceOnly,
    }),
    [market, marketStats, balance, leverage, position, orders, orderType, reduceOnly],
  )
}

export const useFormValidationWithCtx = (orderType: OrderType) => {
  const market = useSelectedMarket()
  const buyErrors = useNewOrderStore((state) => state.buy.errors)
  const sellErrors = useNewOrderStore((state) => state.sell.errors)
  const reduceOnly = useNewOrderStore((state) => state.reduceOnly)

  const form = useFormValidation()
  const newOrder = useNewOrder()
  const ctx = useValidationCtx(market, orderType, reduceOnly)

  const validate = useMemo(() => {
    return debounce(
      (
        newOrder: ReturnType<typeof useNewOrder>,
        ctx: ReturnType<typeof useValidationCtx>,
      ) => {
        const { buyErrors, buyAlerts, sellErrors, sellAlerts } = form.validate(
          newOrder,
          ctx,
        )

        NewOrderStoreActions.setAlertsAndErrors(
          buyAlerts,
          buyErrors,
          sellAlerts,
          sellErrors,
        )
      },
      VALIDATION_WAIT,
      {
        trailing: true,
        maxWait: VALIDATION_MAX_WAIT,
      },
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.validate])

  useEffect(() => {
    validate(newOrder, ctx)
  }, [newOrder, ctx, validate])

  const triggerPriceError = form.isTouched.triggerPrice
    ? (buyErrors?.triggerPrice ?? sellErrors?.triggerPrice)
    : undefined
  const orderPriceError = form.isTouched.orderPrice
    ? (buyErrors?.orderPrice ?? sellErrors?.orderPrice)
    : undefined
  const orderSizeError = form.isTouched.orderSize
    ? (buyErrors?.orderSize ?? sellErrors?.orderSize)
    : undefined

  return { form, triggerPriceError, orderPriceError, orderSizeError }
}
