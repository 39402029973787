import { invariant } from '@x10/lib-core/utils'

import { usePositions } from '@src/domain/api/hooks/account/use-positions'
import type { UserPosition } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'

export const usePositionForCalc = (marketName: MarketName): UserPosition | undefined => {
  const { data: positions } = usePositions({ marketsNames: [marketName] })

  invariant(positions.length <= 1, 'only one position per market is allowed')

  return positions[0]
}
