import { FormattedMessage } from '@x10/lib-core/i18n'
import { Flex, styled } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Popover,
  Portal,
  Scrollable,
  ShevronTrigger,
  SvgIcon,
  Tooltip,
} from '@x10/lib-ui-kit/components'

import { useAccounts } from '@src/domain/api/hooks/account/use-accounts'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { CSS_STATIC_COLUMN_WIDTH_VAR } from '@src/domain/core/config/static'

import { AccountEntry } from './account-entry'
import { CreateAccountForm } from './create-account-form'
import { EditAccountForm } from './edit-account-form'
import { useAccountSelectorState } from './state'

export const AccountSelector = () => {
  const accounts = useAccounts()
  const accountId = useAccountId()
  const {
    isCreatingFormOpen,
    isCurrentlyEditing,
    accountIndexUnderEdit,
    setIsCreatingFormOpen,
    onClose,
    onOpen,
    isSelectorOpen,
  } = useAccountSelectorState()

  const isAccountLimitReached = accounts.length >= 10

  return (
    <Popover.Root
      open={isSelectorOpen}
      portalled
      positioning={{ placement: 'top-end', offset: { mainAxis: 18 } }}
      onOpenChange={(details) => {
        if (details.open) {
          onOpen()
        } else {
          onClose()
        }
      }}
    >
      <Popover.Context>
        {({ open }) => {
          return (
            <>
              <Popover.Trigger>
                <Flex>
                  <styled.span textStyle="primary">
                    {accounts.find((item) => item.accountId.toString() === accountId)
                      ?.description || ''}
                  </styled.span>
                  <ShevronTrigger open={open} />
                </Flex>
              </Popover.Trigger>
              <Portal>
                <Popover.Positioner>
                  <Popover.Content
                    style={{ width: `var(${CSS_STATIC_COLUMN_WIDTH_VAR})` }}
                    css={{
                      mr: '-s-16',
                    }}
                    display="flex"
                    flexDirection="column"
                    borderRadius="r-12"
                    overflow="hidden"
                  >
                    <Flex p="s-16" gap="s-16" justify="space-between">
                      <styled.span fontSize="fs-16">
                        <FormattedMessage
                          id="workspace.trade.widget.account.header.account-selector.title"
                          defaultMessage="Accounts"
                        />
                      </styled.span>
                      {!isCreatingFormOpen && !isCurrentlyEditing && (
                        <Tooltip.Root
                          lazyMount
                          unmountOnExit
                          closeDelay={0}
                          openDelay={50}
                          disabled={!isAccountLimitReached}
                        >
                          <Tooltip.Trigger asChild>
                            <ActionIcon
                              disabled={isAccountLimitReached}
                              onClick={() => {
                                setIsCreatingFormOpen(true)
                              }}
                            >
                              <SvgIcon.SvgIconPlus />
                            </ActionIcon>
                          </Tooltip.Trigger>
                          <Tooltip.Positioner>
                            <Tooltip.Content>
                              <FormattedMessage
                                id="workspace.trade.widget.account.header.account-selector.create-account.button.tooltip"
                                defaultMessage="You can create up to 10 trading accounts per L1 wallet."
                              />
                            </Tooltip.Content>
                          </Tooltip.Positioner>
                        </Tooltip.Root>
                      )}
                    </Flex>
                    {isCreatingFormOpen && <CreateAccountForm />}
                    <Scrollable
                      css={{
                        maxHeight: '21rem',
                      }}
                      scrollY
                    >
                      {accounts.map((account) => {
                        if (accountIndexUnderEdit === account.accountIndex) {
                          return (
                            <EditAccountForm
                              account={account}
                              key={account.accountIndex}
                            />
                          )
                        }
                        return (
                          <AccountEntry account={account} key={account.accountIndex} />
                        )
                      })}
                    </Scrollable>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
