import { sortBy } from 'lodash'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import {
  removeRestEnvelope,
  removeRestEnvelopeSuspense,
} from '@src/domain/api/utils/remove-rest-envelope'
import { API, type UserOrder } from '@src/domain/api/x10'
import type { OrdersArgs } from '@src/domain/api/x10/trading/account/orders'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

const PLACEHOLDER_DATA: UserOrder[] = []

export const useOrders = (args: OrdersArgs = {}) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(args.marketsNames)

  const { data } = useQuery({
    queryKey: [
      QueryKey.UserOrders,
      sortedMarketsNames,
      args.orderType,
      args.orderSide,
      accountId,
    ],
    queryFn: () => {
      return API.trading.account.getOrders(
        {
          marketsNames: sortedMarketsNames,
          orderType: args.orderType,
          orderSide: args.orderSide,
        },
        accountId,
      )
    },
    enabled: Boolean(accountId),
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelope(data) ?? PLACEHOLDER_DATA,
  }
}

export const useOrdersSuspense = (args: OrdersArgs = {}) => {
  const accountId = useAccountId()
  const sortedMarketsNames = sortBy(args.marketsNames)

  const { data } = useSuspenseQuery({
    queryKey: [
      QueryKey.UserOrders,
      sortedMarketsNames,
      args.orderType,
      args.orderSide,
      accountId,
    ],
    queryFn: () => {
      return API.trading.account.getOrders(
        {
          marketsNames: sortedMarketsNames,
          orderType: args.orderType,
          orderSide: args.orderSide,
        },
        accountId,
      )
    },
    ...getCommonPeriodicQueryConfig(),
  })

  return {
    data: removeRestEnvelopeSuspense(data),
  }
}
