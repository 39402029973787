import { ok } from 'neverthrow'
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query'

import type { CryptoCurrencyCollateralCode } from '@x10/lib-core/config'

import { getCommonPeriodicQueryConfig } from '@src/domain/api/utils/get-common-periodic-query-config'
import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import { QueryKey } from '@src/domain/trade/constants'

export const useWithdraw = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: API.trading.account.withdrawal,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.UserBalance] })
    },
  })
}

export const useWithdrawalFee = () => {
  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.WithdrawalFee],
    queryFn: API.trading.account.getWithdrawalFee,
    ...getCommonPeriodicQueryConfig(),
  })
  if (data.isOk()) {
    return ok(removeRestEnvelopeSuspense(data.value))
  } else {
    return data
  }
}

export const useWithdrawalRemainingLimit = ({
  asset,
}: {
  asset: CryptoCurrencyCollateralCode
}) => {
  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.WithdrawalRemainingLimit, asset],
    queryFn: () => API.trading.account.getWithdrawalRemainingLimit({ asset }),
  })
  return removeRestEnvelopeSuspense(data)
}

export const useWithdrawalTarget = () => {
  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.WithdrawalTarget],
    queryFn: API.trading.account.getWithdrawalTarget,
  })
  return removeRestEnvelopeSuspense(data)
}
