import { useCallback, useState } from 'react'

import { checkRequired, getLogger } from '@x10/lib-core/utils'

import { useFetchFees } from '@src/domain/api/hooks/account/use-fees/use-fees'
import { useFetchMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import { usePlaceOrder } from '@src/domain/api/hooks/order-management/use-place-order'
import type { UserPosition } from '@src/domain/api/x10'
import { AppErrorCode, X10AppError } from '@src/domain/core/errors/base'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import { StarkPerpetualOrder } from '@src/domain/starkex/stark-perpetual-order'
import { getOppositePositionSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'
import { calcMarketOrderPrice } from '@src/domain/trade/utils/calc/calc-market-order-price'

import { useGetOrderCtx } from './use-get-order-ctx'

const LOGGER = getLogger('app-exchange.trade.use-close-position-with-market-price')

export const useClosePositionWithMarketPrice = () => {
  const getOrderCtx = useGetOrderCtx()
  const fetchFees = useFetchFees()
  const fetchMarketStats = useFetchMarketStats()
  const getCachedMarket = useGetCachedMarket()
  const { placeOrderAsync } = usePlaceOrder()

  const [isClosingPosition, setClosingPosition] = useState(false)

  const closePositionWithMarketPrice = useCallback(
    async (position: UserPosition) => {
      const side = toOrderSide(getOppositePositionSide(position.side))

      setClosingPosition(true)

      try {
        const market = getCachedMarket(position.market)
        const marketFees = await fetchFees({ marketsNames: [market.name] })
        const marketStats = await fetchMarketStats({ marketName: market.name })

        const orderCtx = await getOrderCtx(
          market.l2Config,
          market.tradingConfig,
          checkRequired(marketFees?.[0], 'marketFees'),
        )
        const bestPrice = side === 'BUY' ? marketStats?.askPrice : marketStats?.bidPrice
        const price = calcMarketOrderPrice(
          side,
          checkRequired(bestPrice, 'bestPrice'),
          market.tradingConfig.minPriceChange,
        )

        const order = StarkPerpetualOrder.create({
          marketName: market.name,
          orderType: 'MARKET',
          side,
          amountOfSynthetic: position.size,
          price,
          timeInForce: 'IOC',
          expiryTime: undefined,
          reduceOnly: true,
          postOnly: false,
          ctx: orderCtx,
        })

        LOGGER.debug('Close position order = %o', order.toJSON())

        await placeOrderAsync({ order })
      } catch (error) {
        if (X10AppError.is(error, AppErrorCode.CantGetStarkPrivateKey)) {
          return
        }

        throw error
      } finally {
        setClosingPosition(false)
      }
    },
    [
      getOrderCtx,
      placeOrderAsync,
      getCachedMarket,
      fetchFees,
      fetchMarketStats,
      setClosingPosition,
    ],
  )

  return { closePositionWithMarketPrice, isClosingPosition }
}
