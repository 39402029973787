import type { Computed, ErrorMessage, Rule, Value } from '../types'

/**
 * [70:Risk Alert]
 * Conditional Market Order (both regular & post-only) with trigger price that leads to immediate execution
 */
export const riskForImmediateConditionalMarket: Rule<
  Pick<Value, 'orderPriceType'>,
  unknown,
  Pick<Computed, 'isConditionalTriggeredAtPlacement'>,
  Pick<ErrorMessage, 'getExecutedImmediatelyAsMarket'>
> = (value, _ctx, computed, alerts, _errors, errorMessage) => {
  if (!computed.isConditionalTriggeredAtPlacement || value.orderPriceType !== 'MARKET') {
    return
  }

  alerts.push(errorMessage.getExecutedImmediatelyAsMarket())
}
