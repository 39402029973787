import { keepPreviousData, useQuery } from '@tanstack/react-query'

import { checkRequired } from '@x10/lib-core/utils'

import { removeRestEnvelope } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { type OpenInterestsInterval } from '@src/domain/api/x10/trading/markets-info/open-interests'
import { type MarketOpenInterest } from '@src/domain/api/x10/trading/markets-info/open-interests.schema'
import { QueryKey } from '@src/domain/trade/constants'

const EMPTY_OPEN_INTERESTS: MarketOpenInterest[] = []

export const useOpenInterests = ({
  marketName,
  startTime,
  endTime,
  interval,
}: {
  marketName: MarketName
  startTime: number
  endTime: number
  interval: OpenInterestsInterval
}) => {
  const { data, isLoading, isFetching } = useQuery({
    queryKey: [QueryKey.OpenInterests, marketName, startTime, endTime, interval],
    queryFn: () => {
      return API.trading.marketsInfo.getMarketOpenInterests(
        marketName,
        startTime,
        endTime,
        interval,
      )
    },
    placeholderData: keepPreviousData,
  })

  const openInterests = data
    ? checkRequired(removeRestEnvelope(data), 'openInterests')
    : EMPTY_OPEN_INTERESTS

  return { data: openInterests, isLoading, isFetching }
}
