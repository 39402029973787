import { css } from '@x10/lib-styled-system/css'

export enum TradingHistoryTab {
  Positions = 'POSITIONS',
  OpenOrders = 'OPEN_ORDERS',
  HistoricalOrders = 'HISTORICAL_ORDERS',
  Trades = 'TRADES',
  Funding = 'FUNDING',
  RealisedPnl = 'REALISED_PNL',
  Transfers = 'TRANSFERS',
}

export const rowStyle = css.raw({
  _hover: {
    '& > td': {
      bg: 'dark.white-10',
    },
  },
  '& > td:first-child': {
    borderLeftRadius: 'r-16',
  },
  '& > td:last-child': {
    borderRightRadius: 'r-16',
  },
})
