import { useState, type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { LONG_MENU_MAX_HEIGHT } from '@x10/lib-styled-system/presets'
import {
  Cell,
  Popover,
  Portal,
  Scrollable,
  SearchInput,
  ShevronTrigger,
} from '@x10/lib-ui-kit/components'

type IndicatorsListProps = {
  indicators: readonly string[]
  filter: string
  onIndicatorClick: (indicator: string) => void
}

const matchIndicator = (title: string, filter: string) =>
  title.toLowerCase().includes(filter.toLowerCase())

const IndicatorsList: FC<IndicatorsListProps> = ({
  filter,
  indicators,
  onIndicatorClick,
}) => {
  const filteredIndicators = indicators.filter((indicator) =>
    matchIndicator(indicator, filter),
  )

  return (
    <Scrollable
      css={{
        maxHeight: LONG_MENU_MAX_HEIGHT,
      }}
      scrollY
    >
      {filteredIndicators.map((indicator) => {
        return (
          <Cell.Item
            key={indicator}
            css={{
              py: 's-12',
              px: 's-16',
              color: 'token.white',
              textStyle: 'secondary',
              _hover: { color: 'token.white', bg: 'token.white-10', cursor: 'pointer' },
            }}
            onClick={() => onIndicatorClick(indicator)}
          >
            <Cell.Value>{indicator}</Cell.Value>
          </Cell.Item>
        )
      })}
    </Scrollable>
  )
}

type IndicatorSelectProps = {
  options: readonly string[]
  onValueSelected: (value: string) => void
}

export const IndicatorSelect: FC<IndicatorSelectProps> = ({
  options,
  onValueSelected,
}) => {
  const [indicatorsFilter, setIndicatorsFilter] = useState('')
  return (
    <Popover.Root
      portalled
      lazyMount
      unmountOnExit
      positioning={{ placement: 'bottom-start' }}
      onExitComplete={() => setIndicatorsFilter('')}
    >
      <Popover.Context>
        {({ setOpen, open }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <ShevronTrigger open={open}>
                  <FormattedMessage
                    id="workspace.trade.widget.chart.tab.market.toolbar.indicators.title"
                    defaultMessage="Indicators"
                  />
                </ShevronTrigger>
              </Popover.Trigger>
              <Portal>
                <Popover.Positioner>
                  <Popover.Content
                    css={{
                      borderRadius: 'r-16',
                    }}
                  >
                    <Box
                      css={{
                        width: '21.4375rem',
                      }}
                    >
                      <Box
                        css={{
                          textStyle: 'caption',
                          py: 's-12',
                          px: 's-16',
                        }}
                      >
                        <FormattedMessage
                          id="workspace.trade.widget.chart.tab.market.toolbar.indicators.title"
                          defaultMessage="Indicators"
                        />
                      </Box>
                      <Box
                        css={{
                          m: 's-16',
                        }}
                      >
                        <SearchInput onChange={(value) => setIndicatorsFilter(value)} />
                      </Box>
                      <IndicatorsList
                        filter={indicatorsFilter}
                        indicators={options}
                        onIndicatorClick={(indicator) => {
                          onValueSelected(indicator)
                          setOpen(false)
                        }}
                      />
                    </Box>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
