import { type Context, type ErrorMessage, type Rule, type Value } from '../types'

export const requireTriggerPrice: Rule<
  Pick<Value, 'triggerPrice'>,
  Pick<Context, 'orderType'>,
  unknown,
  Pick<ErrorMessage, 'getTriggerPrice'>
> = (value, ctx, _computed, _alerts, errors, errorMessage) => {
  if (ctx.orderType === 'CONDITIONAL' && value.triggerPrice === null) {
    errors.triggerPrice = errorMessage.getTriggerPrice()
  }
}
