import { first } from 'lodash'

import { useLeverage } from '@src/domain/api/hooks/account/use-leverage'
import type { MarketStats, UserOrder, UserPosition } from '@src/domain/api/x10'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import { getOppositeOrderSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'
import { calcMarketOrderPrice } from '@src/domain/trade/utils/calc/calc-market-order-price'
import { calcOrderCost } from '@src/domain/trade/utils/calc/calc-order-cost'

type MarketOrderCostValueProps = {
  position: UserPosition
  triggeredOrders: UserOrder[]
  marketStats?: MarketStats
}

export const MarketOrderCostValue = ({
  position,
  triggeredOrders,
  marketStats,
}: MarketOrderCostValueProps) => {
  const getCachedMarket = useGetCachedMarket()

  const market = getCachedMarket(position.market)
  const { data: leverage } = useLeverage({ marketsNames: [market.name] })

  const formatMarketAsset = useFormatMarketAsset({
    marketName: market.name,
    showSymbol: true,
  })

  const marketLeverage = first(leverage)?.leverage

  if (!marketLeverage || !marketStats) {
    return EMPTY_CELL_VALUE
  }

  const orderSide = getOppositeOrderSide(toOrderSide(position.side))
  const orderPrice = calcMarketOrderPrice(
    orderSide,
    orderSide === 'BUY' ? marketStats.askPrice : marketStats.bidPrice,
    market.tradingConfig.minPriceChange,
  )

  const orderCost = calcOrderCost({
    leverage: marketLeverage,
    position,
    orders: triggeredOrders,
    newOrder: {
      price: orderPrice,
      qty: position.size,
      side: orderSide,
    },
    markPrice: marketStats.markPrice,
  })

  return formatMarketAsset({
    amount: orderCost,
    type: 'collateral',
  })
}
