import { type FC } from 'react'
import { defaultStyles, TooltipWithBounds } from '@visx/tooltip'

import { FormattedMessage, useFormatDateTime } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { Cell } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { formatTime } from '@src/domain/core/utils/format-time'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'

const tooltipStyles = {
  ...defaultStyles,
  padding: 0,
  borderRadius: 'none',
  background: 'transparent',
}

export type TooltipData = {
  market: MarketName
  openInterestCollateral: number
  openInterestSynthetic: number
  timestamp: number
}

type TooltipProps = {
  data: TooltipData
  top: number
  left: number
}

export const OpenInterestPopoverChartTooltip: FC<TooltipProps> = ({
  data,
  top,
  left,
}) => {
  const formatMarketAsset = useFormatMarketAsset({ marketName: data.market })
  const { formatDate } = useFormatDateTime()

  const { syntheticCode, collateralCode } = parseMarketName(data.market)

  return (
    <TooltipWithBounds
      // Needed for bounds to update correctly: https://airbnb.io/visx/tooltip
      key={Math.random()}
      top={top}
      left={left}
      style={tooltipStyles}
    >
      <Box
        textStyle="small"
        p="s-16"
        bg="token.grey"
        color="token.white"
        borderRadius="r-16"
      >
        <Cell.Group>
          <Cell.Item gap="s-16">
            <Cell.Title>
              <FormattedMessage
                id="workspace.trade.widget.market-stats.item.open-interest.details.column.open-interest.title"
                defaultMessage="Open Interest {currency}"
                values={{ currency: collateralCode }}
              />
            </Cell.Title>
            <Cell.Value>
              {formatMarketAsset({
                amount: data.openInterestCollateral,
                type: 'collateral',
              })}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item gap="s-16">
            <Cell.Title>
              <FormattedMessage
                id="workspace.trade.widget.market-stats.item.open-interest.details.column.open-interest.title"
                defaultMessage="Open Interest {currency}"
                values={{ currency: syntheticCode }}
              />
            </Cell.Title>
            <Cell.Value>
              {formatMarketAsset({
                amount: data.openInterestSynthetic,
                type: 'synthetic',
              })}
            </Cell.Value>
          </Cell.Item>

          <Cell.Item gap="s-16">
            <Cell.Title>
              <FormattedMessage
                id="workspace.trade.widget.market-stats.item.open-interest.details.column.time.title"
                defaultMessage="Time"
              />
            </Cell.Title>
            <Cell.Value>
              {formatDate(data.timestamp, { dateStyle: 'medium' })}{' '}
              {formatTime(data.timestamp)}
            </Cell.Value>
          </Cell.Item>
        </Cell.Group>
      </Box>
    </TooltipWithBounds>
  )
}
