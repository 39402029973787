import { HStack } from '@x10/lib-styled-system/jsx'

import { ToggleCollapsedButton } from '@src/domain/trade/components/toggle-collapsed-button'
import { useFullScreenContext } from '@src/domain/trade/providers/full-screen-provider'
import { ChartFullScreenButton } from '@src/domain/trade/ui/components/chart-full-screen-button'
import {
  toggleChartHorizontallyExpanded,
  useChartHorizontallyExpanded,
} from '@src/domain/trade/ui/components/flexible-layout-with-widgets'

export const TradingViewChartQuickActions = () => {
  const isChartHorizontallyExpanded = useChartHorizontallyExpanded()
  const { fullScreen } = useFullScreenContext()

  return (
    <HStack
      css={{
        gap: 's-12',
      }}
      hideBelow="md"
    >
      <ChartFullScreenButton />

      {!fullScreen && (
        <ToggleCollapsedButton
          variant="horizontal"
          collapsed={isChartHorizontallyExpanded}
          onClick={toggleChartHorizontallyExpanded}
        />
      )}
    </HStack>
  )
}
