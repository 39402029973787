import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { getStorageKey } from '@src/domain/core/utils/storage/get-storage-key'

export const useConfirmationStore = create<{
  dontConfirmMarketClose?: boolean
  dontConfirmLimitClose?: boolean

  setDontConfirmMarketClose(value: boolean): void
  setDontConfirmLimitClose(value: boolean): void
}>()(
  persist(
    (set) => ({
      setDontConfirmMarketClose: (value: boolean) => {
        set({ dontConfirmMarketClose: value })
      },
      setDontConfirmLimitClose: (value: boolean) => {
        set({ dontConfirmLimitClose: value })
      },
    }),
    {
      name: getStorageKey('confirmation'),
    },
  ),
)
