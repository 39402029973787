import { checkRequired, Decimal } from '@x10/lib-core/utils'

import type { UserOrder } from '@src/domain/api/x10'
import { getSignedSize } from '@src/domain/trade/utils/calc/get-signed-size'

export const calcOrdersValueSumBySide = (orders: UserOrder[]) => {
  const [longOrdersValueSum, shortOrdersValueSum] = orders.reduce(
    (acc, order) => {
      const orderPrice = checkRequired(order.price, 'orderPrice')

      if (order.side === 'BUY') {
        return [acc[0].plus(getSignedSize(order).times(orderPrice)), acc[1]]
      } else {
        return [acc[0], acc[1].plus(getSignedSize(order).times(orderPrice))]
      }
    },
    [Decimal.ZERO, Decimal.ZERO],
  )

  return { longOrdersValueSum, shortOrdersValueSum }
}
