import { Suspense, useMemo, useState, type FC } from 'react'
import isUndefined from 'lodash/isUndefined'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { notReachable } from '@x10/lib-core/utils'
import { Box, Flex, HStack, VStack } from '@x10/lib-styled-system/jsx'
import {
  Button,
  Cell,
  Checkbox,
  Dialog,
  Portal,
  RadioButtonGroup,
  SimpleSelect,
} from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { CSS_STATIC_COLUMN_WIDTH_VAR } from '@src/domain/core/config/static'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import type { TpSlEntrySide } from '@src/domain/core/types/common'
import { getOrderSideIntlKey } from '@src/domain/i18n/utils/get-order-side-intl-key'
import type { OrderTpSlType } from '@src/domain/starkex/stark-perpetual-order'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import type { OrderTriggerState } from '@src/domain/trade/types/common'

import { useTpSlFormValidationWithCtx } from './hooks/use-tp-sl-form-validation-with-ctx'
import { OrderPriceInput } from './order-price-input'
import { PnlNote } from './pnl-note'
import { TriggerPriceInput } from './trigger-price-input'
import { type TpSlDialogInitiator } from './types'

const asTpSlType = (value: OrderTpSlType) => value

const TP_SL_ENTRY_SIDE_OPTIONS: TpSlEntrySide[] = ['LONG', 'SHORT'] as const
const INITIAL_TP_SL_STATE: OrderTriggerState = {
  triggerPrice: null,
  triggerPriceType: 'LAST',
  price: null,
  priceType: 'MARKET',
}

export type TpSlValue = {
  tpSlEntrySide: TpSlEntrySide | undefined
  tpSlType: OrderTpSlType | undefined
  tp: OrderTriggerState | undefined | null
  sl: OrderTriggerState | undefined | null
}

type DialogContentProps = {
  initiator: TpSlDialogInitiator
  marketName: MarketName
  confirming?: boolean
  value: TpSlValue
  onValueChange: (value: TpSlValue) => void
  onCancel: () => void
}

export const DialogContent: FC<DialogContentProps> = ({
  initiator,
  marketName,
  confirming,
  value,
  onValueChange,
  onCancel,
}) => {
  const { formatMessage } = useIntl()
  const getCachedMarket = useGetCachedMarket()

  const market = getCachedMarket(marketName)

  const allowedTypes = useMemo(() => {
    switch (initiator) {
      case 'ORDER_FORM':
        return ['POSITION', 'ORDER']
      case 'POSITIONS_TABLE':
        return ['POSITION']
      default:
        throw notReachable(initiator)
    }
  }, [initiator])
  const applicableToOptions = useMemo(() => {
    return [
      {
        label: formatMessage({
          id: 'trade.component.order-tp-sl.applicable-to.entire-position.title',
          defaultMessage: 'Entire Position',
        }),
        value: asTpSlType('POSITION'),
      },
      {
        label: formatMessage({
          id: 'trade.component.order-tp-sl.applicable-to.current-order.title',
          defaultMessage: 'Current Order',
        }),
        value: asTpSlType('ORDER'),
      },
    ].filter((item) => allowedTypes.includes(item.value))
  }, [allowedTypes, formatMessage])

  const [tpSlEntrySide, setTpSlEntrySide] = useState<TpSlEntrySide>(
    value.tpSlEntrySide ?? 'LONG',
  )
  const [applicableTo, setApplicableTo] = useState<OrderTpSlType>(
    value.tpSlType ?? 'POSITION',
  )
  const [tpTriggerState, setTpTriggerState] = useState<
    OrderTriggerState | undefined | null
  >(isUndefined(value.tp) ? INITIAL_TP_SL_STATE : value.tp)
  const [slTriggerState, setSlTriggerState] = useState<
    OrderTriggerState | undefined | null
  >(isUndefined(value.sl) ? INITIAL_TP_SL_STATE : value.sl)

  const formData = useMemo(
    () => ({
      tpSlType: applicableTo,
      tpSlSide: tpSlEntrySide,

      tpChecked: Boolean(tpTriggerState),
      tpTriggerPrice: tpTriggerState?.triggerPrice,
      tpTriggerPriceType: tpTriggerState?.triggerPriceType,
      tpOrderPrice: tpTriggerState?.price,
      tpOrderPriceType: tpTriggerState?.priceType,

      slChecked: Boolean(slTriggerState),
      slTriggerPrice: slTriggerState?.triggerPrice,
      slTriggerPriceType: slTriggerState?.triggerPriceType,
      slOrderPrice: slTriggerState?.price,
      slOrderPriceType: slTriggerState?.priceType,
    }),
    [applicableTo, tpSlEntrySide, tpTriggerState, slTriggerState],
  )
  const {
    form,
    isValid,
    tpTriggerPriceError,
    tpOrderPriceError,
    slTriggerPriceError,
    slOrderPriceError,
  } = useTpSlFormValidationWithCtx(initiator, market, formData)

  const handleConfirm = () => {
    const hasTpOrSl = Boolean(tpTriggerState || slTriggerState)

    onValueChange({
      tpSlEntrySide: hasTpOrSl ? tpSlEntrySide : undefined,
      tpSlType: hasTpOrSl ? applicableTo : undefined,
      tp: tpTriggerState ?? (hasTpOrSl ? null : undefined),
      sl: slTriggerState ?? (hasTpOrSl ? null : undefined),
    })
  }

  const collateralPrecisionDp = market.assets.collateral.precision.getDecimalPlaces()

  return (
    <VStack
      css={{ gap: 's-16', alignItems: 'stretch' }}
      style={{ width: `var(${CSS_STATIC_COLUMN_WIDTH_VAR})` }}
    >
      {!value.tpSlEntrySide ? (
        <RadioButtonGroup.Root
          value={tpSlEntrySide}
          onValueChange={({ value }) => setTpSlEntrySide(value as TpSlEntrySide)}
        >
          {TP_SL_ENTRY_SIDE_OPTIONS.map((optionValue, id) => (
            <RadioButtonGroup.Item
              key={id}
              value={optionValue}
              css={{
                colorPalette:
                  tpSlEntrySide === optionValue
                    ? tpSlEntrySide === 'SHORT'
                      ? 'token.button.red'
                      : 'token.button.green'
                    : undefined,
              }}
            >
              <RadioButtonGroup.ItemControl />
              <RadioButtonGroup.ItemText>
                <FormattedMessage {...getOrderSideIntlKey(optionValue)} />
              </RadioButtonGroup.ItemText>
              <RadioButtonGroup.ItemHiddenInput />
            </RadioButtonGroup.Item>
          ))}
        </RadioButtonGroup.Root>
      ) : (
        <Box textStyle="primary">
          <FormattedMessage
            id="trade.component.order-tp-sl.title"
            defaultMessage="Add Take Profit / Stop Loss"
          />
        </Box>
      )}

      <Cell.Item>
        <Cell.Title>
          <FormattedMessage
            id="trade.component.order-tp-sl.applicable-to.title"
            defaultMessage="Applicable to"
          />
        </Cell.Title>
        <Cell.Value>
          <SimpleSelect.Root
            css={{ fontWeight: 'fw-500' }}
            visual="compact"
            positioning={{ sameWidth: false }}
            value={applicableTo}
            options={applicableToOptions}
            onValueChange={(value) => {
              setApplicableTo(value as OrderTpSlType)
            }}
          >
            <SimpleSelect.Control />
            <SimpleSelect.Content>
              {applicableToOptions.map((item) => (
                <SimpleSelect.Item key={item.value} item={item} />
              ))}
            </SimpleSelect.Content>
          </SimpleSelect.Root>
        </Cell.Value>
      </Cell.Item>

      <VStack css={{ gap: 's-16', alignItems: 'stretch' }}>
        <Flex>
          <Checkbox
            checked={Boolean(tpTriggerState)}
            onCheckedChange={({ checked }) => {
              if (!checked) {
                form.reset(['tpTriggerPrice', 'tpOrderPrice'])
              }

              setTpTriggerState(checked ? INITIAL_TP_SL_STATE : undefined)
            }}
          >
            <FormattedMessage
              id="trade.component.order-tp-sl.tp.checkbox.title"
              defaultMessage="Take Profit"
            />
          </Checkbox>
        </Flex>

        {tpTriggerState && (
          <>
            <TriggerPriceInput
              value={tpTriggerState.triggerPrice}
              valueType={tpTriggerState.triggerPriceType}
              currency={market.assets.collateral.code}
              precision={collateralPrecisionDp}
              disabled={!value}
              error={tpTriggerPriceError}
              onBlur={() => form.touch('tpTriggerPrice')}
              onValueChange={(inputValue) =>
                setTpTriggerState({
                  ...tpTriggerState,
                  triggerPrice: inputValue,
                })
              }
              onValueTypeChange={(inputValueType) => {
                setTpTriggerState({
                  ...tpTriggerState,
                  triggerPriceType: inputValueType,
                })
              }}
            />
            <OrderPriceInput
              value={tpTriggerState.price}
              valueType={tpTriggerState.priceType}
              currency={market.assets.collateral.code}
              precision={collateralPrecisionDp}
              disabled={!value}
              error={tpOrderPriceError}
              onBlur={() => form.touch('tpOrderPrice')}
              onValueChange={(inputValue) =>
                setTpTriggerState({
                  ...tpTriggerState,
                  price: inputValue,
                })
              }
              onValueTypeChange={(inputValueType) => {
                form.reset(['tpOrderPrice'])
                setTpTriggerState({
                  ...tpTriggerState,
                  priceType: inputValueType,
                })
              }}
            />

            <Suspense>
              <PnlNote
                initiator={initiator}
                applicableTo={applicableTo}
                entrySide={tpSlEntrySide}
                marketName={marketName}
                value={tpTriggerState}
              />
            </Suspense>
          </>
        )}
      </VStack>

      <VStack css={{ gap: 's-16', alignItems: 'stretch' }}>
        <Flex>
          <Checkbox
            checked={Boolean(slTriggerState)}
            onCheckedChange={({ checked }) => {
              if (!checked) {
                form.reset(['slTriggerPrice', 'slOrderPrice'])
              }

              setSlTriggerState(checked ? INITIAL_TP_SL_STATE : undefined)
            }}
          >
            <FormattedMessage
              id="trade.component.order-tp-sl.sl.checkbox.title"
              defaultMessage="Stop Loss"
            />
          </Checkbox>
        </Flex>

        {slTriggerState && (
          <>
            <TriggerPriceInput
              value={slTriggerState.triggerPrice}
              valueType={slTriggerState.triggerPriceType}
              currency={market.assets.collateral.code}
              precision={collateralPrecisionDp}
              disabled={!value}
              error={slTriggerPriceError}
              onBlur={() => form.touch('slTriggerPrice')}
              onValueChange={(inputValue) =>
                setSlTriggerState({
                  ...slTriggerState,
                  triggerPrice: inputValue,
                })
              }
              onValueTypeChange={(inputValueType) => {
                setSlTriggerState({
                  ...slTriggerState,
                  triggerPriceType: inputValueType,
                })
              }}
            />
            <OrderPriceInput
              value={slTriggerState?.price ?? null}
              valueType={slTriggerState?.priceType ?? 'LIMIT'}
              currency={market.assets.collateral.code}
              precision={collateralPrecisionDp}
              disabled={!value}
              error={slOrderPriceError}
              onBlur={() => form.touch('slOrderPrice')}
              onValueChange={(inputValue) =>
                setSlTriggerState({
                  ...slTriggerState,
                  price: inputValue,
                })
              }
              onValueTypeChange={(inputValueType) => {
                form.reset(['slOrderPrice'])
                setSlTriggerState({
                  ...slTriggerState,
                  priceType: inputValueType,
                })
              }}
            />

            <Suspense>
              <PnlNote
                initiator={initiator}
                applicableTo={applicableTo}
                entrySide={tpSlEntrySide}
                marketName={marketName}
                value={slTriggerState}
              />
            </Suspense>
          </>
        )}
      </VStack>

      <HStack w="100%" gap="s-16">
        <Button w="100%" visual="secondary-grey" size="large" onClick={() => onCancel()}>
          <FormattedMessage id="common.action.cancel.title" defaultMessage="Cancel" />
        </Button>

        <Button
          visual="primary-green"
          size="large"
          disabled={!isValid}
          css={{ w: '100%' }}
          loading={confirming}
          onClick={() => handleConfirm()}
        >
          <FormattedMessage id="common.action.confirm.title" defaultMessage="Confirm" />
        </Button>
      </HStack>
    </VStack>
  )
}

type OrderTpSlDialogProps = {
  open?: boolean
  initiator: TpSlDialogInitiator
  value: TpSlValue
  onValueChange: (value: TpSlValue) => void
  onClose: () => void
}

export const OrderTpSlDialog: FC<OrderTpSlDialogProps> = ({
  open,
  initiator,
  value,
  onValueChange,
  onClose,
}) => {
  const market = useSelectedMarket()

  const handleValueChange = (value: TpSlValue) => {
    onValueChange(value)
    onClose()
  }

  return (
    <Dialog.Root
      open={open}
      modal
      lazyMount
      unmountOnExit
      onOpenChange={({ open: newOpen }) => {
        if (!newOpen) {
          onClose()
        }
      }}
    >
      <Portal>
        <Dialog.Backdrop />
        <Dialog.Positioner>
          <Dialog.Content minW="unset!">
            <DialogContent
              initiator={initiator}
              marketName={market.name}
              value={value}
              onValueChange={handleValueChange}
              onCancel={onClose}
            />
          </Dialog.Content>
        </Dialog.Positioner>
      </Portal>
    </Dialog.Root>
  )
}
