import { useCallback } from 'react'

import { useFormatDecimal } from '@x10/lib-core/i18n'
import { invariant, type Decimal } from '@x10/lib-core/utils'

import { USDC_BALANCE_PRECISION } from '@src/domain/core/config/static'

export const useFormatUsd = ({ usdcSymbol = false }: { usdcSymbol?: boolean } = {}) => {
  const formatDecimal = useFormatDecimal()

  return useCallback(
    (
      amount: number | Decimal,
      {
        showSymbol = true,
        showSign = 'negative',
      }: { showSymbol?: boolean; showSign?: 'negative' | 'always' } = {},
    ) => {
      const formattedValue = formatDecimal(amount, {
        signDisplay: showSign,
        minimumFractionDigits: USDC_BALANCE_PRECISION,
        maximumFractionDigits: USDC_BALANCE_PRECISION,
      })

      invariant(formattedValue, '`formattedValue` is required')

      if (!showSymbol) {
        return formattedValue
      }

      return `${formattedValue} ${usdcSymbol ? 'USDC' : 'USD'}`
    },
    [formatDecimal, usdcSymbol],
  )
}
