import React, { type PropsWithChildren } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Portal, Tooltip } from '@x10/lib-ui-kit/components'

export const HeaderTooltip = ({
  tooltipMessage,
  children,
  interactive,
}: PropsWithChildren<{
  tooltipMessage: React.ComponentProps<typeof FormattedMessage>
  interactive?: boolean
}>) => {
  return (
    <Tooltip.Root
      lazyMount
      unmountOnExit
      closeDelay={50}
      openDelay={200}
      interactive={interactive}
    >
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Portal>
        <Tooltip.Positioner>
          <Tooltip.Content
            css={{
              whiteSpace: 'break-spaces',
            }}
          >
            <FormattedMessage {...tooltipMessage} />
          </Tooltip.Content>
        </Tooltip.Positioner>
      </Portal>
    </Tooltip.Root>
  )
}
