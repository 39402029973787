import type { WalletAddress } from '@x10/lib-core/types'

import { maskString } from '@src/domain/core/mask-string'

export const maskAddress = (value: WalletAddress | undefined) => {
  if (!value) {
    return value
  }

  return maskString(value)
}
