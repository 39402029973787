import { type FC, type PropsWithChildren } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { type Decimal } from '@x10/lib-core/utils'
import { Cell, HoverCard, Portal } from '@x10/lib-ui-kit/components'
import { useBreakpoints } from '@x10/lib-ui-kit/hooks'

import type { CachedMarket } from '@src/domain/api/x10'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'
import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { calcOrderBookPriceImpact } from '@src/domain/trade/utils/calc/calc-order-book-price-impact'

type OrderBookTableCellHoverProps = PropsWithChildren<{
  placement: 'left' | 'right'
  dataItem: AggOrderBookDataItem | undefined
  midPrice: Decimal | undefined
  market: CachedMarket
}>

// FIXME: Use single hover component for all cells
export const OrderBookTableCellHover: FC<OrderBookTableCellHoverProps> = ({
  placement,
  dataItem,
  midPrice,
  market,
  children,
}) => {
  const { md: breakpointMdMatched } = useBreakpoints()

  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })
  const formatPercent = useFormatPercent()

  if (!dataItem || !breakpointMdMatched) {
    return children
  }

  const priceImpact = midPrice
    ? calcOrderBookPriceImpact({ price: dataItem.price, midPrice })
    : undefined

  return (
    <HoverCard.Root
      animated={false}
      lazyMount
      positioning={{ placement, gutter: 2 }}
      openDelay={0}
      closeDelay={0}
    >
      <HoverCard.Trigger asChild>{children}</HoverCard.Trigger>

      <Portal>
        <HoverCard.Positioner>
          <HoverCard.Content>
            <Cell.Group>
              <Cell.Item>
                <Cell.Title>
                  <FormattedMessage
                    id="workspace.trade.widget.order-book.item.hint.average-price.title"
                    defaultMessage="Average Price"
                  />
                </Cell.Title>
                <Cell.Value>
                  {formatMarketAsset({
                    amount: dataItem.priceAvg,
                    type: 'collateral',
                  })}
                </Cell.Value>
              </Cell.Item>

              <Cell.Item>
                <Cell.Title>
                  <FormattedMessage
                    id="workspace.trade.widget.order-book.item.hint.total-amount.title"
                    defaultMessage="Total {currency}"
                    values={{
                      currency: market.assets.synthetic.code,
                    }}
                  />
                </Cell.Title>
                <Cell.Value>
                  {formatMarketAsset({
                    amount: dataItem.sizeSum,
                    type: 'synthetic',
                  })}
                </Cell.Value>
              </Cell.Item>

              <Cell.Item>
                <Cell.Title>
                  <FormattedMessage
                    id="workspace.trade.widget.order-book.item.hint.total-amount.title"
                    defaultMessage="Total {currency}"
                    values={{
                      currency: market.assets.collateral.code,
                    }}
                  />
                </Cell.Title>
                <Cell.Value>
                  {formatMarketAsset({
                    amount: dataItem.sizeSumCollateral,
                    type: 'collateral',
                  })}
                </Cell.Value>
              </Cell.Item>

              <Cell.Item>
                <Cell.Title>
                  <FormattedMessage
                    id="workspace.trade.widget.order-book.item.hint.price-impact.title"
                    defaultMessage="Price Impact"
                  />
                </Cell.Title>
                <Cell.Value>
                  {priceImpact ? formatPercent(priceImpact) : EMPTY_CELL_VALUE}
                </Cell.Value>
              </Cell.Item>
            </Cell.Group>
          </HoverCard.Content>
        </HoverCard.Positioner>
      </Portal>
    </HoverCard.Root>
  )
}
