import { Decimal, invariant } from '@x10/lib-core/utils'

import {
  type Computed,
  type Context,
  type ErrorMessage,
  type Rule,
  type Value,
} from '../types'

/**
 * [53:Price Validations]
 * 1. [Short Conditional Limit Orders] Order Price ≥ Trigger price * (1-Limit Order Floor Ratio)
 * 2. [Long Conditional Limit Orders] Order Price ≤ Trigger Price * (1+Limit Order Price Cap)
 */
export const conditionalOrderPrice: Rule<
  Pick<Value, 'orderPriceType' | 'triggerPrice'>,
  Pick<Context, 'market' | 'marketStats' | 'orderType'>,
  Pick<Computed, 'orderPrice' | 'orderSide'>,
  Pick<ErrorMessage, 'getOrderPriceTrigger'>
> = (value, ctx, computed, _alerts, errors, errorMessage) => {
  if (
    errors.triggerPrice ||
    errors.orderPrice ||
    ctx.orderType !== 'CONDITIONAL' ||
    value.orderPriceType !== 'LIMIT'
  ) {
    return
  }

  invariant(value.triggerPrice, '`triggerPrice` is required')
  invariant(computed.orderPrice, '`orderPrice` is required')

  if (computed.orderSide === 'SELL') {
    const minPrice = value.triggerPrice.times(
      Decimal.ONE.minus(ctx.market.tradingConfig.limitPriceFloor),
    )

    if (computed.orderPrice.lt(minPrice)) {
      errors.form = errorMessage.getOrderPriceTrigger(
        computed.orderSide,
        ctx.market.tradingConfig.limitPriceFloor,
      )
    }
  } else {
    const maxPrice = value.triggerPrice.times(
      Decimal.ONE.plus(ctx.market.tradingConfig.limitPriceCap),
    )

    if (computed.orderPrice.gt(maxPrice)) {
      errors.form = errorMessage.getOrderPriceTrigger(
        computed.orderSide,
        ctx.market.tradingConfig.limitPriceCap,
      )
    }
  }
}
