import { invariant } from '@x10/lib-core/utils'

import { calcSignedSizeSum } from '@src/domain/trade/utils/calc/calc-signed-size-sum'
import { getSignedSize } from '@src/domain/trade/utils/calc/get-signed-size'

import type { Computed, Context, ErrorMessage, Rule } from '../types'

/**
 * [62:Validation]
 * Abs(Reduce-only Order size + Triggered Orders in the same direction as New Reduce-only order) ≤ abs(Position size)
 * (Validation Not applied to TP/SL and Conditional Reduce-Only Orders)
 */
export const reduceOnlySum: Rule<
  unknown,
  Pick<Context, 'reduceOnly' | 'position' | 'orders' | 'orderType'>,
  Pick<Computed, 'isConditionalReduceOnly' | 'orderSize' | 'orderSide'>,
  Pick<ErrorMessage, 'getReduceOnlySum'>
> = (_value, ctx, computed, _alerts, errors, errorMessage) => {
  if (
    errors.orderSize ||
    !ctx.reduceOnly ||
    computed.isConditionalReduceOnly ||
    !ctx.position ||
    ctx.orderType !== 'LIMIT'
  ) {
    return
  }

  invariant(computed.orderSize, '`orderSize` is required')

  const sameSideOrders = ctx.orders.filter((order) => order.side === computed.orderSide)
  const signedOrderSize = getSignedSize({
    side: computed.orderSide,
    qty: computed.orderSize,
  })

  if (
    signedOrderSize.plus(calcSignedSizeSum(sameSideOrders)).abs().gt(ctx.position.size)
  ) {
    errors.orderSize = errorMessage.getReduceOnlySum()
  }
}
