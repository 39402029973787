import { findKey } from 'lodash'

import { checkRequired } from '@x10/lib-core/utils'

import type { X10Interval, X10Timeframe } from '@src/domain/api/x10/common'
import {
  type ResolutionString,
  type TimeFramePeriodBack,
} from '@src/types/charting-library'

type TVTimeframe = '3H' | '1D' | '2D' | '4D' | '5D' | '7D' | '14D' | '1M' | '3M' | '6M'
type TVInterval = '1' | '5' | '15' | '30' | '60' | '120' | '240' | '1D'

/**
 * https://www.tradingview.com/charting-library-docs/v25/core_concepts/Time-Frames
 */
export const X10ToTVTimeframe: Record<X10Timeframe, TVTimeframe> = {
  PT3H: '3H',
  P1D: '1D',
  P2D: '2D',
  P4D: '4D',
  P5D: '5D',
  P1W: '7D',
  P2W: '14D',
  P1M: '1M',
  P3M: '3M',
  P6M: '6M',
}

/**
 * https://www.tradingview.com/charting-library-docs/v25/core_concepts/Resolution
 */
export const X10ToTVInterval: Record<X10Interval, TVInterval> = {
  PT1M: '1',
  PT5M: '5',
  PT15M: '15',
  PT30M: '30',
  PT1H: '60',
  PT2H: '120',
  PT4H: '240',
  P1D: '1D',
}

export const DEFAULT_TIMEFRAME_INTERVAL: Record<X10Timeframe, X10Interval> = {
  PT3H: 'PT1M',
  P1D: 'PT1M',
  P2D: 'PT1M',
  P4D: 'PT5M',
  P5D: 'PT5M',
  P1W: 'PT5M',
  P2W: 'PT15M',
  P1M: 'PT30M',
  P3M: 'PT2H',
  P6M: 'PT4H',
}

export const DEFAULT_INTERVAL_TIMEFRAME: Record<X10Interval, X10Timeframe> = {
  PT1M: 'PT3H',
  PT5M: 'P1D',
  PT15M: 'P2D',
  PT30M: 'P4D',
  PT1H: 'P1W',
  PT2H: 'P2W',
  PT4H: 'P1M',
  P1D: 'P3M',
}

export const toX10Interval = (value: ResolutionString) => {
  return checkRequired(
    findKey(X10ToTVInterval, (interval) => interval === value),
    'interval',
  ) as X10Interval
}

export const toResolutionString = (value: X10Interval): ResolutionString => {
  return X10ToTVInterval[value] as ResolutionString
}

export const toPeriodBack = (value: X10Timeframe) => {
  return {
    type: 'period-back',
    value: X10ToTVTimeframe[value],
  } as TimeFramePeriodBack
}
