import { type FC } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { type Decimal } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'

import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { OrderBookAggregationLevel } from './order-book-aggregation-level'
import { OrderBookDisplaySizeSelection } from './order-book-display-size-selection'

type OrderBookHeaderProps = {
  minPriceChange: Decimal
}

export const OrderBookHeader: FC<OrderBookHeaderProps> = ({ minPriceChange }) => {
  return (
    <TradeWidget.Header>
      <Box flex={1} textStyle="primary">
        <FormattedMessage
          id="workspace.trade.widget.order-book.header.title"
          defaultMessage="Order Book"
        />
      </Box>
      <HStack
        css={{
          gap: 's-16',
        }}
      >
        <OrderBookAggregationLevel minPriceChange={minPriceChange} />
        <OrderBookDisplaySizeSelection />
      </HStack>
    </TradeWidget.Header>
  )
}
