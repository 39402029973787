import { type FC } from 'react'

import { Flex, HStack, styled, VStack } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Portal,
  SvgIcon,
  Tooltip,
  ValueColored,
} from '@x10/lib-ui-kit/components'

import type { MarketStats } from '@src/domain/api/x10'
import type { MarketName } from '@src/domain/api/x10/common'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'
import { usePinnedMarkets } from '@src/domain/trade/hooks/use-pinned-markets'
import { useManageSelectedMarket } from '@src/domain/trade/store/market'

type Props = {
  marketName: MarketName
  marketStats: MarketStats | undefined
  index: number
}

export const PinnedMarket: FC<Props> = ({ marketName, marketStats, index }) => {
  const setMarket = useManageSelectedMarket()
  const getCachedMarket = useGetCachedMarket()

  const formatMarketAsset = useFormatMarketAsset({ showSymbol: true })
  const formatPercent = useFormatPercent()

  const market = getCachedMarket(marketName)

  return (
    <Tooltip.Root
      positioning={{ placement: 'bottom-start' }}
      openDelay={0}
      disabled={!marketStats}
    >
      <Tooltip.Trigger asChild>
        <Flex
          aria-label={`Pinned market ${marketName}`}
          className="group"
          css={{
            position: 'relative',
            cursor: 'pointer',
            alignItems: 'center',
          }}
          onClick={() => {
            setMarket(market)
            usePinnedMarkets.setSelectedMarketIndex(index)
          }}
        >
          <CryptoLogo currency={market.assets.synthetic.code} />

          <ActionIcon
            aria-label={`Unpin market ${market.name}`}
            css={{
              position: 'absolute',
              display: 'none',
              top: '-s-8',
              right: '-s-8',
              zIndex: 1,
              _groupHover: {
                display: 'block',
              },
            }}
            onClick={(event) => {
              event.stopPropagation() // in order to prevent triggering setMarket in parent div
              usePinnedMarkets.removePinnedMarket(index)
            }}
          >
            <SvgIcon.SvgIconMinusCircle size={16} />
          </ActionIcon>
        </Flex>
      </Tooltip.Trigger>

      <Portal>
        <Tooltip.Positioner>
          <Tooltip.Content css={{ minW: 'auto' }}>
            <VStack alignItems="flex-start">
              <styled.span whiteSpace="nowrap">
                {formatMarketAsset(
                  {
                    amount: marketStats?.lastPrice,
                    type: 'collateral',
                  },
                  { marketName },
                )}
              </styled.span>
              <HStack gap="s-12">
                <ValueColored>
                  {formatMarketAsset(
                    {
                      amount: marketStats?.dailyPriceChange,
                      type: 'collateral',
                    },
                    {
                      marketName,
                      showSign: 'exceptZero',
                    },
                  )}
                </ValueColored>
                <ValueColored>
                  {formatPercent(marketStats?.dailyPriceChangePercentage)}
                </ValueColored>
              </HStack>
            </VStack>
          </Tooltip.Content>
        </Tooltip.Positioner>
      </Portal>
    </Tooltip.Root>
  )
}
