import { type FC } from 'react'

import { type Decimal } from '@x10/lib-core/utils'

import { type MarketName } from '@src/domain/api/x10/common'
import { EMPTY_CELL_VALUE } from '@src/domain/core/config/static'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'

export type TpSlValuesProps = {
  takeProfit?: Decimal
  stopLoss?: Decimal
  marketName: MarketName
}

export const TpSlValues: FC<TpSlValuesProps> = ({ takeProfit, stopLoss, marketName }) => {
  const formatMarketAsset = useFormatMarketAsset({ marketName: marketName })

  return (
    <>
      <div>
        {takeProfit
          ? formatMarketAsset({ amount: takeProfit, type: 'collateral' })
          : EMPTY_CELL_VALUE}
      </div>
      <div>
        {stopLoss
          ? formatMarketAsset({ amount: stopLoss, type: 'collateral' })
          : EMPTY_CELL_VALUE}
      </div>
    </>
  )
}
