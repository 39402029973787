import dynamic from 'next/dynamic'

import { Center, Flex } from '@x10/lib-styled-system/jsx'
import { Header as KitHeader, Link } from '@x10/lib-ui-kit/components'

import { homeRoute } from '@src/domain/core/utils/routes'

const WalletItem = dynamic(
  () => import('./wallet-item').then((module) => module.WalletItem),
  {
    ssr: false,
  },
)

export const ClosedMainnetHeader = () => {
  return (
    <KitHeader.Root
      css={{
        display: 'block',
        py: 'unset!',
        px: 'unset!',
        h: 'unset!',
        bg: 'transparent',
      }}
    >
      <Flex
        css={{
          w: '100%',
          px: 's-16',
          py: 's-24',
          h: '4.5rem',
          justifyContent: 'right',
        }}
      >
        <Link
          href={homeRoute({}).trade({}).$}
          css={{
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, 0)',
          }}
        >
          <KitHeader.Logo />
        </Link>

        <Center>
          <WalletItem />
        </Center>
      </Flex>
    </KitHeader.Root>
  )
}
