import { useMemo } from 'react'
import { isEmpty } from 'lodash'
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'

import { css } from '@x10/lib-styled-system/css'
import {
  Scrollable,
  TableHeaderCell,
  TableHeaderSortDirectionIcon,
} from '@x10/lib-ui-kit/components'

import { useFundingHistory } from '@src/domain/api/hooks/account/use-funding'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'
import { checkIfTimestampMatchesPeriod } from '@src/domain/trade/utils/matchers'

import { rowStyle, TradingHistoryTab } from './common'
import { EmptyMessage } from './components/empty-message'
import { HeaderSpacer } from './components/header-spacer'
import { StickyTableHeader } from './components/sticky-table-header'
import {
  useFilteredMarketsNames,
  useTradingHistoryTableFiltersStore,
} from './hooks/use-trading-history-table-filters'
import { useColumns } from './trading-history-funding-table.columns'

const MARKET_HEADER_ID = 'marketName'

export const TradingHistoryFundingTable = () => {
  const filteredMarketsNames = useFilteredMarketsNames()
  const fundingFilters = useTradingHistoryTableFiltersStore((state) => state.funding)

  const { data } = useFundingHistory()
  const columns = useColumns()

  const filteredData = useMemo(
    () =>
      data.filter((item) => {
        if (
          filteredMarketsNames &&
          !isEmpty(filteredMarketsNames) &&
          !filteredMarketsNames.includes(item.market)
        ) {
          return false
        }

        if (
          fundingFilters.period &&
          !checkIfTimestampMatchesPeriod(fundingFilters.period, item.paidTime.getTime())
        ) {
          return false
        }

        return true
      }),
    [data, filteredMarketsNames, fundingFilters.period],
  )

  const table = useReactTable({
    data: filteredData,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  if (filteredData.length === 0) {
    return <EmptyMessage tab={TradingHistoryTab.Funding} />
  }

  return (
    <TradeWidget.ContentFixed>
      <Scrollable scrollX scrollY css={{ flex: 1, px: 's-8' }}>
        <table width="100%">
          <StickyTableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sortDirection = header.id === MARKET_HEADER_ID && 'desc'
                  return (
                    <TableHeaderCell
                      key={header.id}
                      sortDirection={sortDirection}
                      style={{ width: header.getSize() }}
                    >
                      <HeaderSpacer>
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        <TableHeaderSortDirectionIcon sortDirection={sortDirection} />
                      </HeaderSpacer>
                    </TableHeaderCell>
                  )
                })}
              </tr>
            ))}
          </StickyTableHeader>

          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <tr key={row.id} className={css(rowStyle)}>
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Scrollable>
    </TradeWidget.ContentFixed>
  )
}
