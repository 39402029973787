import type { FC } from 'react'

import { invariant } from '@x10/lib-core/utils'
import { MarketTableCell } from '@x10/lib-ui-kit/components'

import type { MarketName } from '@src/domain/api/x10/common'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'
import { useAddPinnedMarket } from '@src/domain/trade/hooks/use-pinned-markets'
import { useAllMarkets, useManageSelectedMarket } from '@src/domain/trade/store/market'

export const ActionableMarketTableCell: FC<{ market: MarketName }> = ({ market }) => {
  const setMarket = useManageSelectedMarket()
  const allMarkets = useAllMarkets()
  const addPinnedMarket = useAddPinnedMarket()

  const { syntheticCode, collateralCode } = parseMarketName(market)

  return (
    <MarketTableCell
      css={{
        cursor: 'pointer',
      }}
      market={[syntheticCode, collateralCode]}
      onClick={() => {
        const foundMarket = allMarkets.find((m) => m.name === market)

        invariant(foundMarket, 'Market not found')

        addPinnedMarket(foundMarket.name)
        setMarket(foundMarket)
      }}
    />
  )
}
