import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'

import type { Computed, Context, ErrorMessage, Rule } from '../types'

/**
 * [60:Validation]
 * Reduce-only order in the same direction as the position is not allowed.
 */
export const reduceOnlySameDirection: Rule<
  unknown,
  Pick<Context, 'reduceOnly' | 'position'>,
  Pick<Computed, 'orderSide'>,
  Pick<ErrorMessage, 'getReduceOnlySameSide'>
> = (_value, ctx, computed, _alerts, errors, errorMessage) => {
  if (!ctx.reduceOnly || !ctx.position) {
    return
  }

  if (computed.orderSide === toOrderSide(ctx.position.side)) {
    errors.form = errorMessage.getReduceOnlySameSide()
  }
}
