import { getTransferMsgHashWithFee } from '@starkware-industries/starkware-crypto-utils'

import type { HexString } from '@x10/lib-core/types'
import { toHexString, type Long } from '@x10/lib-core/utils'

import type { StarkSignature } from '../types'
import { signMessage } from '../utils/sign-message'

const FEE_TOKEN = toHexString('0') // StarkWare requirement
const FEE_LIMIT = '0'

export class StarkTransferSettlement {
  private readonly amount: Long
  private readonly assetId: HexString
  private readonly expirationTimestamp: number
  private readonly nonce: Long
  private readonly receiverPositionId: Long
  private readonly receiverPublicKey: HexString
  private readonly senderPositionId: Long
  private readonly senderPublicKey: HexString
  private readonly signature: StarkSignature

  private constructor({
    amount,
    assetId,
    expirationTimestamp,
    nonce,
    receiverPositionId,
    receiverPublicKey,
    senderPositionId,
    senderPublicKey,
    signature,
  }: {
    amount: Long
    assetId: HexString
    expirationTimestamp: number
    nonce: Long
    receiverPositionId: Long
    receiverPublicKey: HexString
    senderPositionId: Long
    senderPublicKey: HexString
    signature: StarkSignature
  }) {
    this.amount = amount
    this.assetId = assetId
    this.expirationTimestamp = expirationTimestamp
    this.nonce = nonce
    this.receiverPositionId = receiverPositionId
    this.receiverPublicKey = receiverPublicKey
    this.senderPositionId = senderPositionId
    this.senderPublicKey = senderPublicKey
    this.signature = signature
  }

  toJSON() {
    return {
      amount: this.amount.toString(10),
      assetId: this.assetId,
      expirationTimestamp: this.expirationTimestamp,
      nonce: this.nonce.toString(10),
      receiverPositionId: this.receiverPositionId,
      receiverPublicKey: this.receiverPublicKey,
      senderPositionId: this.senderPositionId,
      senderPublicKey: this.senderPublicKey,
      signature: this.signature,
    }
  }

  static create({
    amount,
    assetId,
    expirationTimestamp,
    nonce,
    receiverPositionId,
    receiverPublicKey,
    senderPositionId,
    senderPublicKey,
    starkPrivateKey,
  }: {
    amount: Long
    assetId: HexString
    expirationTimestamp: number
    nonce: Long
    receiverPositionId: Long
    receiverPublicKey: HexString
    senderPositionId: Long
    senderPublicKey: HexString
    starkPrivateKey: HexString
  }) {
    const transferHash = getTransferMsgHashWithFee(
      /* amount              */ amount.toString(10),
      /* nonce               */ nonce.toNumber(),
      /* senderVaultId       */ senderPositionId.toNumber(),
      /* token               */ assetId,
      /* receiverVaultId     */ receiverPositionId.toNumber(),
      /* receiverStarkKey    */ receiverPublicKey,
      /* expirationTimestamp */ expirationTimestamp,
      /* feeToken            */ FEE_TOKEN,
      /* feeVaultId          */ senderPositionId.toNumber(),
      /* feeLimit            */ FEE_LIMIT,
    )

    const transferSignature = signMessage(transferHash, starkPrivateKey)

    return new StarkTransferSettlement({
      amount,
      assetId,
      expirationTimestamp,
      nonce,
      receiverPositionId,
      receiverPublicKey,
      senderPositionId,
      senderPublicKey,
      signature: transferSignature.signature,
    })
  }
}
