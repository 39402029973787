import { type ReactNode } from 'react'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Decimal } from '@x10/lib-core/utils'
import { Link } from '@x10/lib-ui-kit/components'

import { useFormatBalance } from '@src/domain/core/hooks/use-format-balance'
import type { FormValidationResult } from '@src/domain/trade/validation/types'

type Value = {
  availableBalance: Decimal
  requiredInitialMarginCurrent: Decimal
  requiredInitialMarginNew: Decimal
  isPositionAndOrdersValueAboveMax: boolean
  isLeverageIncreased: boolean
}
type Result = FormValidationResult<{ message: string | null }, { message: ReactNode }>

export const MARGIN_SCHEDULE_URL =
  'https://docs.x10.exchange/x10-resources/trading/margin-schedule'

export const useFormValidation = ({
  availableBalance,
  requiredInitialMarginCurrent,
  requiredInitialMarginNew,
  isPositionAndOrdersValueAboveMax,
  isLeverageIncreased,
}: Value): Result => {
  const { formatMessage } = useIntl()
  const formatBalance = useFormatBalance()

  if (availableBalance.eq(Decimal.ZERO)) {
    return {
      success: false,
      message: formatMessage({
        id: 'workspace.trade.widget.order-form.popover.leverage.validation.zero-balance',
        defaultMessage:
          'Your available balance is insufficient to change the leverage. Please add funds to your account.',
      }),
    }
  }

  if (requiredInitialMarginNew.eq(Decimal.ZERO)) {
    return {
      success: true,
      message: null,
    }
  }

  if (isPositionAndOrdersValueAboveMax) {
    return {
      success: false,
      message: (
        <FormattedMessage
          id="workspace.trade.widget.order-form.popover.leverage.validation.position-and-orders-above-max"
          defaultMessage="Value of your current position and open orders is bigger than the maximum permitted position value for this leverage. Please refer to the <a>margin schedule</a> for details."
          values={{
            a: (chunks) => (
              <Link href={MARGIN_SCHEDULE_URL} external>
                {chunks}
              </Link>
            ),
          }}
        />
      ),
    }
  }

  const incrementalRequiredInitialMarginOrZero = Decimal.max(
    Decimal.ZERO,
    requiredInitialMarginNew.minus(requiredInitialMarginCurrent),
  )

  if (
    !isLeverageIncreased &&
    availableBalance.lt(incrementalRequiredInitialMarginOrZero)
  ) {
    return {
      success: false,
      message: formatMessage({
        id: 'workspace.trade.widget.order-form.popover.leverage.validation.balance-not-sufficient',
        defaultMessage:
          'Your available balance is insufficient to change the leverage. Please adjust the leverage or add funds to your account. Alternatively, reduce the open position size or cancel any open orders.',
      }),
    }
  }

  return {
    success: true,
    message: formatMessage(
      {
        id: 'workspace.trade.widget.order-form.popover.leverage.margin.after-adjustment-change',
        defaultMessage: 'After adjustment, your required margin will be {margin}.',
      },
      {
        margin: formatBalance(requiredInitialMarginNew),
      },
    ),
  }
}
