import { Decimal, invariant } from '@x10/lib-core/utils'

export const calcEntirePositionSize = (
  /**
   * TP or SL order price
   */
  price: Decimal,
  minOrderSizeChange: Decimal,
  maxPositionValue: Decimal,
) => {
  invariant(price.gt(0), '`price` must be greater than 0')

  return maxPositionValue
    .times(50)
    .div(price)
    .setDecimalPlaces(minOrderSizeChange.getDecimalPlaces(), Decimal.ROUND_DOWN)
}
