import { useEffect, useState, type FC } from 'react'

import { useFormatDateTime } from '@x10/lib-core/i18n'
import { Box } from '@x10/lib-styled-system/jsx'
import { LONG_MENU_MAX_HEIGHT } from '@x10/lib-styled-system/presets'
import {
  Cell,
  Popover,
  Portal,
  Scrollable,
  SearchInput,
  ShevronTrigger,
  Skeleton,
} from '@x10/lib-ui-kit/components'

import type { TimezoneId, TimezoneInfo } from '@src/types/charting-library'

const REFRESH_INTERVAL_MS = 500

const isTzMatched = (tzTitle: string, filter: string) => {
  return tzTitle.toLowerCase().includes(filter.toLowerCase())
}

type TimezoneSelectProps = {
  value: TimezoneInfo | undefined
  options: readonly TimezoneInfo[]
  onValueChange: (valueId: TimezoneId) => void
}

export const TimezoneSelect: FC<TimezoneSelectProps> = ({
  value,
  options,
  onValueChange,
}) => {
  const { formatTime } = useFormatDateTime()
  const [formattedValue, setFormattedValue] = useState<string>()
  const [tzFilter, setTzFilter] = useState('')

  useEffect(() => {
    const timerId = setInterval(() => {
      if (!value) {
        return
      }

      const formattedTime = formatTime(Date.now(), {
        hourCycle: 'h23',
        timeStyle: 'medium',
        timeZone: value.id,
      })
      const utcOffset = value.offset ? value.title.match('UTC[+-:d]+')?.[0] : 'UTC'

      setFormattedValue(`${formattedTime} (${utcOffset})`)
    }, REFRESH_INTERVAL_MS)

    return () => clearInterval(timerId)
  }, [value, formatTime, setFormattedValue])

  if (!formattedValue) {
    return <Skeleton size="small" />
  }

  return (
    <Popover.Root
      portalled
      lazyMount
      positioning={{ placement: 'top-start' }}
      onExitComplete={() => setTzFilter('')}
    >
      <Popover.Context>
        {({ open, setOpen }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <ShevronTrigger css={{ tabularNums: true }} open={open}>
                  {formattedValue}
                </ShevronTrigger>
              </Popover.Trigger>
              <Portal>
                <Popover.Positioner>
                  <Popover.Content
                    css={{
                      borderRadius: 'r-16',
                      overflow: 'hidden',
                      w: 'auto',
                      minW: '18.75rem',
                    }}
                  >
                    <Box m="s-16">
                      <SearchInput
                        value={tzFilter}
                        onChange={(value) => setTzFilter(value)}
                      />
                    </Box>

                    <Scrollable scrollY css={{ maxHeight: LONG_MENU_MAX_HEIGHT }}>
                      <Cell.Group gap={0}>
                        {options
                          .filter((timezone) => isTzMatched(timezone.title, tzFilter))
                          .map((timezone) => {
                            return (
                              <Cell.Item
                                key={timezone.id}
                                css={{
                                  alignItems: 'center',
                                  color: 'token.white-50',
                                  py: 's-8',
                                  px: 's-16',
                                  bg:
                                    timezone.id === value?.id
                                      ? 'token.white-10'
                                      : undefined,
                                  _hover: {
                                    cursor: 'pointer',
                                    color: 'token.white',
                                    bg: 'token.white-10',
                                  },
                                }}
                              >
                                <Cell.Title
                                  css={{ fontWeight: 'normal' }}
                                  mr="s-16"
                                  whiteSpace="nowrap"
                                  onClick={() => {
                                    onValueChange(timezone.id as TimezoneId)
                                    setOpen(false)
                                  }}
                                >
                                  {timezone.title}
                                </Cell.Title>
                              </Cell.Item>
                            )
                          })}
                      </Cell.Group>
                    </Scrollable>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
