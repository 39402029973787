import { FormattedMessage } from '@x10/lib-core/i18n'
import { checkRequired } from '@x10/lib-core/utils'
import { HStack } from '@x10/lib-styled-system/jsx'
import { TableCell } from '@x10/lib-ui-kit/components'

import type { UserOrder } from '@src/domain/api/x10'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'

const directionSymbol = {
  DOWN: '≤',
  UP: '≥',
  UNKNOWN: 'UNKNOWN',
} as const

export const OrderTriggerPriceCell = ({ order }: { order: UserOrder }) => {
  const formatMarketAsset = useFormatMarketAsset()

  if (order.type === 'CONDITIONAL') {
    const trigger = checkRequired(order.trigger, 'trigger')

    return (
      <TableCell>
        <HStack gap="s-4">
          <span>{directionSymbol[trigger.direction]}</span>
          <span>
            {formatMarketAsset(
              {
                amount: trigger.triggerPrice,
                type: 'collateral',
              },
              { marketName: order.market },
            )}
          </span>
        </HStack>
      </TableCell>
    )
  }

  if (order.type === 'TPSL') {
    const tp = order.takeProfit
    const sl = order.stopLoss

    return (
      <TableCell>
        {tp && (
          <div>
            <FormattedMessage
              id="common.price.table-cell.take-profit.trigger-price"
              defaultMessage="TP {price}"
              values={{
                price: formatMarketAsset(
                  { amount: tp.triggerPrice, type: 'collateral' },
                  { marketName: order.market },
                ),
              }}
            />
          </div>
        )}

        {sl && (
          <div>
            <FormattedMessage
              id="common.price.table-cell.stop-loss.trigger-price"
              defaultMessage="SL {price}"
              values={{
                price: formatMarketAsset(
                  { amount: sl.triggerPrice, type: 'collateral' },
                  { marketName: order.market },
                ),
              }}
            />
          </div>
        )}
      </TableCell>
    )
  }

  return <TableCell />
}
