import { useSuspenseQuery } from '@tanstack/react-query'

import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import { QueryKey } from '@src/domain/trade/constants'

export const useSuspendedWithdrawals = () => {
  const { data } = useSuspenseQuery({
    queryKey: [QueryKey.Withdrawals],
    queryFn: () => API.trading.account.getWithdrawals(),
    refetchInterval: 30_000,
  })

  return removeRestEnvelopeSuspense(data)
}
