import {
  forwardRef,
  Suspense,
  useEffect,
  useState,
  type FC,
  type PropsWithChildren,
} from 'react'

import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { Spinner, Tabs } from '@x10/lib-ui-kit/components'

import { useAccountId, useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { ToggleCollapsedButton } from '@src/domain/trade/components/toggle-collapsed-button'

import { useFullScreenContext } from '../../../providers/full-screen-provider'
import { ChartFullScreenButton } from '../../components/chart-full-screen-button'
import {
  toggleHistoryTableCollapsed,
  useHistoryTableCollapsed,
} from '../../components/flexible-layout-with-widgets'
import { TradeWidget } from '../../components/trade-widget'
import { TradingHistoryTab } from './common'
import { EmptyMessage } from './components/empty-message'
import { TradingHistoryFilters } from './trading-history-filters'
import { TradingHistoryFundingTable } from './trading-history-funding-table'
import { TradingHistoryOpenOrdersTable } from './trading-history-open-orders-table'
import { TradingHistoryOrdersHistoryTable } from './trading-history-orders-history-table'
import { TradingHistoryPositionsTable } from './trading-history-positions-table'
import { TradingHistoryRealisedPnlTable } from './trading-history-realised-pnl-table'
import { TradingHistoryTabsList } from './trading-history-tabs-list'
import { TradingHistoryTradesTable } from './trading-history-trades-table'
import { TradingHistoryTransfersTable } from './trading-history-transfers-table'

const tableSpinner = (
  <Box
    css={{
      textAlign: 'center',
      mt: 's-16',
    }}
  >
    <Spinner />
  </Box>
)

const SuspenseWrapper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<{ tab: TradingHistoryTab }>
>(({ children, tab }, _ref) => {
  const isLoggedIn = useSuspendedIsLoggedIn()

  if (!isLoggedIn) {
    return <EmptyMessage tab={tab} />
  }

  return <Suspense fallback={tableSpinner}>{children}</Suspense>
})

SuspenseWrapper.displayName = 'SuspenseWrapper'

export const TradingHistory: FC = () => {
  const { fullScreen } = useFullScreenContext()
  const [activeTab, setActiveTab] = useState(TradingHistoryTab.Positions)

  // FIXME: https://linear.app/ex10/issue/X10-1640/remove-tab-syncing-effect-in-tradinghistorytsx-once-its-somehow-fixed
  //  Remove this once Tabs component gets fixed by itself. Now this is needed as `activeTab` is correct, but Tab keeps on rendering wrong content.
  const accountId = useAccountId()
  const isTableCollapsed = useHistoryTableCollapsed()

  useEffect(() => {
    setActiveTab(TradingHistoryTab.Positions)
  }, [accountId])

  return (
    <Tabs.Root
      size="lg"
      asChild
      lazyMount
      unmountOnExit
      value={activeTab}
      onValueChange={({ value }) => {
        setActiveTab(value as TradingHistoryTab)
        if (isTableCollapsed) {
          toggleHistoryTableCollapsed()
        }
      }}
    >
      <TradeWidget.InnerRoot>
        <TradeWidget.Header
          css={{
            mb: 0,
          }}
        >
          <TradingHistoryTabsList />

          <HStack css={{ ml: 's-24', gap: 's-24', textStyle: 'caption' }}>
            <TradingHistoryFilters tab={activeTab} />

            <HStack css={{ gap: 's-12' }}>
              <ChartFullScreenButton />

              {!fullScreen && (
                <ToggleCollapsedButton
                  variant="vertical"
                  collapsed={isTableCollapsed}
                  onClick={toggleHistoryTableCollapsed}
                />
              )}
            </HStack>
          </HStack>
        </TradeWidget.Header>

        {(!isTableCollapsed || fullScreen) && (
          <>
            <Tabs.Content value={TradingHistoryTab.Positions} asChild>
              <SuspenseWrapper tab={TradingHistoryTab.Positions}>
                <TradingHistoryPositionsTable />
              </SuspenseWrapper>
            </Tabs.Content>

            <Tabs.Content value={TradingHistoryTab.OpenOrders} asChild>
              <SuspenseWrapper tab={TradingHistoryTab.OpenOrders}>
                <TradingHistoryOpenOrdersTable />
              </SuspenseWrapper>
            </Tabs.Content>

            <Tabs.Content value={TradingHistoryTab.HistoricalOrders} asChild>
              <SuspenseWrapper tab={TradingHistoryTab.HistoricalOrders}>
                <TradingHistoryOrdersHistoryTable />
              </SuspenseWrapper>
            </Tabs.Content>

            <Tabs.Content value={TradingHistoryTab.Trades} asChild>
              <SuspenseWrapper tab={TradingHistoryTab.Trades}>
                <TradingHistoryTradesTable />
              </SuspenseWrapper>
            </Tabs.Content>

            <Tabs.Content value={TradingHistoryTab.Funding} asChild>
              <SuspenseWrapper tab={TradingHistoryTab.Funding}>
                <TradingHistoryFundingTable />
              </SuspenseWrapper>
            </Tabs.Content>

            <Tabs.Content value={TradingHistoryTab.RealisedPnl} asChild>
              <SuspenseWrapper tab={TradingHistoryTab.RealisedPnl}>
                <TradingHistoryRealisedPnlTable />
              </SuspenseWrapper>
            </Tabs.Content>

            <Tabs.Content value={TradingHistoryTab.Transfers} asChild>
              <SuspenseWrapper tab={TradingHistoryTab.Transfers}>
                <TradingHistoryTransfersTable />
              </SuspenseWrapper>
            </Tabs.Content>
          </>
        )}
      </TradeWidget.InnerRoot>
    </Tabs.Root>
  )
}
