import { type FC, type PropsWithChildren } from 'react'

import { css } from '@x10/lib-styled-system/css'
import { Box, type HTMLStyledProps } from '@x10/lib-styled-system/jsx'

type TradeWidgetActionsProps = PropsWithChildren<HTMLStyledProps<'div'>>

export const TradeWidgetActions: FC<TradeWidgetActionsProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Box
      {...restProps}
      css={css.raw(
        { md: { position: 'absolute', bottom: 0, left: 0 }, w: '100%', bg: 'token.grey' },
        restProps.css,
      )}
    >
      {children}
    </Box>
  )
}
