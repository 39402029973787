import { useEffect, useMemo } from 'react'
import { debounce, first } from 'lodash'

import { getLogger } from '@x10/lib-core/utils'

import { useLeverage } from '@src/domain/api/hooks/account/use-leverage'
import { useMarketStats } from '@src/domain/api/hooks/markets-info/use-market-stats'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { useOrdersForCalc } from '@src/domain/trade/hooks/use-orders-for-calc'
import { usePositionForCalc } from '@src/domain/trade/hooks/use-position-for-calc'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { calcMarketOrderPrice } from '@src/domain/trade/utils/calc/calc-market-order-price'
import { calcOrderCost } from '@src/domain/trade/utils/calc/calc-order-cost'

import { useNewOrderStore } from '../store/new-order'
import { useNewOrderCostStore } from '../store/new-order-cost'

const LOGGER = getLogger('trade.hooks.use-order-cost')
const CALC_WAIT = 1000

const useOrderCostCtx = () => {
  const market = useSelectedMarket()
  const { data: marketStats } = useMarketStats({ marketName: market.name })

  const priceType = useNewOrderStore((state) => state.priceType)
  const buyPrice = useNewOrderStore((state) => state.buy.price)
  const sellPrice = useNewOrderStore((state) => state.sell.price)
  const buyAmountOfSynthetic = useNewOrderStore((state) => state.buy.amountOfSynthetic)
  const sellAmountOfSynthetic = useNewOrderStore((state) => state.sell.amountOfSynthetic)

  const { data: leverage } = useLeverage({ marketsNames: [market.name] })
  const position = usePositionForCalc(market.name)
  const orders = useOrdersForCalc(market.name)

  return useMemo(
    () => ({
      market,
      marketStats,
      buyPrice,
      sellPrice,
      priceType,
      buyAmountOfSynthetic,
      sellAmountOfSynthetic,
      leverage: first(leverage)?.leverage,
      position,
      orders,
    }),
    [
      market,
      marketStats,
      buyPrice,
      sellPrice,
      priceType,
      buyAmountOfSynthetic,
      sellAmountOfSynthetic,
      leverage,
      position,
      orders,
    ],
  )
}

export const useOrderCost = () => {
  const isFeatureEnabled = useIsFeatureEnabled()
  const { setOrderCost } = useNewOrderCostStore()

  const ctx = useOrderCostCtx()

  const updateOrderCost = useMemo(() => {
    return debounce(
      (ctx: ReturnType<typeof useOrderCostCtx>) => {
        if (
          !ctx.marketStats ||
          !ctx.leverage ||
          !ctx.buyPrice ||
          !ctx.sellPrice ||
          !ctx.buyAmountOfSynthetic ||
          !ctx.sellAmountOfSynthetic
        ) {
          setOrderCost(null)
          return
        }

        const buyPrice =
          ctx.priceType === 'MARKET'
            ? calcMarketOrderPrice(
                'BUY',
                ctx.buyPrice,
                ctx.market.tradingConfig.minPriceChange,
              )
            : ctx.buyPrice
        const costBuy = calcOrderCost({
          leverage: ctx.leverage,
          position: ctx.position,
          orders: ctx.orders,
          newOrder: {
            price: buyPrice,
            qty: ctx.buyAmountOfSynthetic,
            side: 'BUY',
          },
          markPrice: ctx.marketStats.markPrice,
        })

        const sellPrice =
          ctx.priceType === 'MARKET'
            ? calcMarketOrderPrice(
                'SELL',
                ctx.sellPrice,
                ctx.market.tradingConfig.minPriceChange,
              )
            : ctx.sellPrice
        const costSell = calcOrderCost({
          leverage: ctx.leverage,
          position: ctx.position,
          orders: ctx.orders,
          newOrder: {
            price: sellPrice,
            qty: ctx.sellAmountOfSynthetic,
            side: 'SELL',
          },
          markPrice: ctx.marketStats.markPrice,
        })

        if (isFeatureEnabled('DEBUG_INFO')) {
          LOGGER.debug('Order cost: %o', {
            newOrder: {
              buyPrice5Pct: buyPrice,
              sellPrice5Pct: sellPrice,
              buyQty: ctx.buyAmountOfSynthetic,
              sellQty: ctx.sellAmountOfSynthetic,
            },
            ctx: {
              buyPrice: ctx.buyPrice,
              sellPrice: ctx.sellPrice,
              leverage: ctx.leverage,
              position: ctx.position,
              orders: ctx.orders,
              markPrice: ctx.marketStats.markPrice,
            },
            result: {
              costBuy,
              costSell,
            },
          })
        }

        setOrderCost({ buy: costBuy, sell: costSell })
      },
      CALC_WAIT,
      {
        trailing: true,
        maxWait: CALC_WAIT,
      },
    )
  }, [isFeatureEnabled, setOrderCost])

  useEffect(() => {
    updateOrderCost(ctx)
  }, [ctx, updateOrderCost])
}
