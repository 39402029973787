import { useMemo } from 'react'

import { type ChartPropertiesOverrides } from '@src/types/charting-library'

import { getPresetTokens } from './utils/get-preset-tokens'

/**
 * https://www.tradingview.com/charting-library-docs/latest/customization/overrides/
 */
export const useThemeAndOverrides = () => {
  const { colorGreen, colorGrey, colorRed, colorWhite, colorWhite50 } = getPresetTokens()

  const overrides = useMemo<Partial<ChartPropertiesOverrides>>(() => {
    return {
      'scalesProperties.fontSize': 10,
      'scalesProperties.textColor': colorWhite,

      'paneProperties.background': colorGrey,
      'paneProperties.backgroundGradientStartColor': colorGrey,
      'paneProperties.backgroundGradientEndColor': colorGrey,
      'paneProperties.vertGridProperties.color': colorWhite50,
      'paneProperties.horzGridProperties.color': colorWhite50,

      // Bars
      'mainSeriesProperties.barStyle.upColor': colorGreen,
      'mainSeriesProperties.barStyle.downColor': colorRed,
      'mainSeriesProperties.barStyle.barColorsOnPrevClose': false,
      'mainSeriesProperties.barStyle.dontDrawOpen': false,
      'mainSeriesProperties.barStyle.thinBars': true,

      // Candles
      'mainSeriesProperties.candleStyle.upColor': colorGreen,
      'mainSeriesProperties.candleStyle.downColor': colorRed,
      'mainSeriesProperties.candleStyle.borderUpColor': colorGreen,
      'mainSeriesProperties.candleStyle.borderDownColor': colorRed,
      'mainSeriesProperties.candleStyle.wickUpColor': colorGreen,
      'mainSeriesProperties.candleStyle.wickDownColor': colorRed,

      // Hollow Candles
      'mainSeriesProperties.hollowCandleStyle.upColor': colorGreen,
      'mainSeriesProperties.hollowCandleStyle.downColor': colorRed,
      'mainSeriesProperties.hollowCandleStyle.drawWick': true,
      'mainSeriesProperties.hollowCandleStyle.drawBorder': true,
      'mainSeriesProperties.hollowCandleStyle.borderColor': colorGreen,
      'mainSeriesProperties.hollowCandleStyle.borderUpColor': colorGreen,
      'mainSeriesProperties.hollowCandleStyle.borderDownColor': colorRed,
      'mainSeriesProperties.hollowCandleStyle.wickColor': colorWhite50,
      'mainSeriesProperties.hollowCandleStyle.wickUpColor': colorGreen,
      'mainSeriesProperties.hollowCandleStyle.wickDownColor': colorRed,
      'mainSeriesProperties.hollowCandleStyle.drawBody': true,

      // Heikin Ashi
      'mainSeriesProperties.haStyle.upColor': colorGreen,
      'mainSeriesProperties.haStyle.downColor': colorRed,
      'mainSeriesProperties.haStyle.drawWick': true,
      'mainSeriesProperties.haStyle.drawBorder': true,
      'mainSeriesProperties.haStyle.borderColor': colorGreen,
      'mainSeriesProperties.haStyle.borderUpColor': colorGreen,
      'mainSeriesProperties.haStyle.borderDownColor': colorRed,
      'mainSeriesProperties.haStyle.wickColor': colorWhite50,
      'mainSeriesProperties.haStyle.wickUpColor': colorGreen,
      'mainSeriesProperties.haStyle.wickDownColor': colorRed,
      'mainSeriesProperties.haStyle.barColorsOnPrevClose': false,
      'mainSeriesProperties.haStyle.drawBody': true,

      // Line
      'mainSeriesProperties.lineStyle.color': colorGreen,
      'mainSeriesProperties.lineStyle.linewidth': 1,
      'mainSeriesProperties.lineStyle.priceSource': 'close',

      // Area
      'mainSeriesProperties.areaStyle.color1': colorGreen,
      'mainSeriesProperties.areaStyle.color2': colorGrey,
      'mainSeriesProperties.areaStyle.linecolor': colorGreen,
      'mainSeriesProperties.areaStyle.linewidth': 1,
      'mainSeriesProperties.areaStyle.priceSource': 'close',
      'mainSeriesProperties.areaStyle.transparency': 80,

      // Baseline
      'mainSeriesProperties.baselineStyle.baselineColor': colorWhite50,
      'mainSeriesProperties.baselineStyle.topFillColor1': colorGreen,
      'mainSeriesProperties.baselineStyle.topFillColor2': colorGrey,
      'mainSeriesProperties.baselineStyle.bottomFillColor1': colorGrey,
      'mainSeriesProperties.baselineStyle.bottomFillColor2': colorRed,
      'mainSeriesProperties.baselineStyle.topLineColor': colorGreen,
      'mainSeriesProperties.baselineStyle.bottomLineColor': colorRed,
      'mainSeriesProperties.baselineStyle.topLineWidth': 1,
      'mainSeriesProperties.baselineStyle.bottomLineWidth': 1,
      'mainSeriesProperties.baselineStyle.priceSource': 'close',
      'mainSeriesProperties.baselineStyle.transparency': 80,
      'mainSeriesProperties.baselineStyle.baseLevelPercentage': 50,
    }
  }, [colorGreen, colorGrey, colorRed, colorWhite, colorWhite50])

  return {
    overrides,
  }
}
