import { useMemo, type FC } from 'react'

import { checkRequired, type Decimal } from '@x10/lib-core/utils'
import { Portal, Select, ShevronTrigger } from '@x10/lib-ui-kit/components'

import { useSelectedMarket } from '@src/domain/trade/store/market'

import { useOrderBookDisplayParamsStore } from '../../store'

const MULTIPLIERS = [1, 5, 10, 100, 1000]

const useLevelSelectorOptions = (minPriceChange: Decimal) => {
  return useMemo(() => {
    return MULTIPLIERS.map((value) => ({
      label: minPriceChange.times(value).toString(),
      value: value.toString(),
    }))
  }, [minPriceChange])
}

type OrderBookAggregationLevelProps = {
  minPriceChange: Decimal
}

export const OrderBookAggregationLevel: FC<OrderBookAggregationLevelProps> = ({
  minPriceChange,
}) => {
  const market = useSelectedMarket()
  const { aggregationLevel, setAggregationLevel } = useOrderBookDisplayParamsStore()
  const levelSelectorOptions = useLevelSelectorOptions(minPriceChange)

  return (
    <Select.Root
      key={market.name}
      visual="compact"
      positioning={{ placement: 'bottom-end' }}
      items={levelSelectorOptions}
      value={[aggregationLevel.toString()]}
      onValueChange={(details) => {
        const nextValue = parseInt(
          checkRequired(details.value[0], 'selected aggregation level is undefined'),
        )

        setAggregationLevel(nextValue)
      }}
    >
      <Select.Context>
        {({ open }) => {
          return (
            <>
              <Select.Control>
                <Select.Trigger>
                  <Select.ValueText textStyle="primary" />
                  <ShevronTrigger
                    open={open}
                    boxProps={{
                      _groupHover: { color: 'token.white' },
                    }}
                  />
                </Select.Trigger>
              </Select.Control>

              <Portal>
                <Select.Positioner>
                  <Select.Content>
                    {levelSelectorOptions.map((item) => {
                      return (
                        <Select.Item key={item.value} item={item}>
                          <Select.ItemText>{item.label}</Select.ItemText>
                        </Select.Item>
                      )
                    })}
                  </Select.Content>
                </Select.Positioner>
              </Portal>
            </>
          )
        }}
      </Select.Context>
    </Select.Root>
  )
}
