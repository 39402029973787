import { useCallback, useEffect, useMemo, useRef } from 'react'
import { create } from 'zustand'

import { checkRequired } from '@x10/lib-core/utils'

import type {
  CandlePriceSource,
  X10Interval,
  X10Timeframe,
} from '@src/domain/api/x10/common'
import { useGlobalSettingsStore } from '@src/domain/core/store/user-settings'
import { openExternalLink } from '@src/domain/core/utils/open-external-link'
import { asExternalRoute, tradingViewRoute } from '@src/domain/core/utils/routes'
import { type TimezoneId, type TimezoneInfo } from '@src/types/charting-library'

import {
  TradingViewPriceScaleMode,
  type CompareOrAddMode,
  type TradingViewTicker,
} from '../../types/common'
import { type TradingViewChartApi } from '../../utils/trading-view-chart-api'
import {
  DEFAULT_INTERVAL_TIMEFRAME,
  DEFAULT_TIMEFRAME_INTERVAL,
  toPeriodBack,
  toResolutionString,
} from '../../utils/trading-view-chart-api/constants'

const DEFAULT_INTERVAL: X10Interval = 'PT1H'
const INITIAL_INTERVAL =
  useGlobalSettingsStore.getState().chartInterval ?? DEFAULT_INTERVAL

type TradingViewChartStoreState = {
  priceSource: CandlePriceSource
  indicators: string[]
  timezone: TimezoneInfo | undefined
  timezones: TimezoneInfo[]
  interval: X10Interval
  timeframe: X10Timeframe
  priceScaleMode: TradingViewPriceScaleMode
  priceScaleModeAuto: boolean
}

export const useInternalStore = create<TradingViewChartStoreState>(() => ({
  priceSource: 'trades',
  indicators: [],
  timezone: undefined,
  timezones: [],
  timeframe: DEFAULT_INTERVAL_TIMEFRAME[INITIAL_INTERVAL],
  interval: INITIAL_INTERVAL,
  priceScaleMode: TradingViewPriceScaleMode.Normal,
  priceScaleModeAuto: false,
}))

export function useTradingViewChartMachineStore<T>(
  selector: (state: TradingViewChartStoreState) => T,
): Readonly<T> {
  return useInternalStore(selector)
}

export type TradingViewChartMachineApi = {
  connect: (api: TradingViewChartApi) => void

  addIndicator: (value: string) => void
  compareOrAddSymbol: (symbolName: TradingViewTicker, mode: CompareOrAddMode) => void
  getSymbol: () => TradingViewTicker
  showChartSettings: () => void
  takeSnapshot: () => void

  setChartType: (value: number) => void
  setInterval: (value: X10Interval) => void
  setPriceScaleMode: (value: TradingViewPriceScaleMode) => void
  setPriceScaleModeAuto: (value: boolean) => void
  setPriceSource: (value: CandlePriceSource) => void
  setTimeframe: (value: X10Timeframe) => void
  setTimezone: (value: TimezoneId) => void
}

export const useTradingViewChartMachineApi = (): TradingViewChartMachineApi => {
  const apiRef = useRef<TradingViewChartApi>()

  const getApi = useCallback(() => {
    return checkRequired(apiRef.current, 'api')
  }, [])

  const connect = useCallback((value: TradingViewChartApi) => {
    value.onChartReady(() => {
      apiRef.current = value

      useInternalStore.setState({
        timezone: value.getTimezone(),
        timezones: value.getAvailableTimezones(),
        indicators: value.getStudies(),
      })

      value.onChartZoom(() => {
        useInternalStore.setState({ timeframe: undefined })
      })
    })

    value.onScreenshotReady((url) => {
      openExternalLink(asExternalRoute(tradingViewRoute({ snapshotId: url }).$))
    })
  }, [])

  const addIndicator = useCallback(
    (value: string) => {
      getApi().executeCommand({
        name: 'add-indicator',
        args: { indicatorName: value },
      })
    },
    [getApi],
  )

  const compareOrAddSymbol = useCallback(
    (symbolName: TradingViewTicker, mode: CompareOrAddMode) => {
      getApi().executeCommand({
        name: 'compare-or-add-symbol',
        args: { symbolName, mode },
      })
    },
    [getApi],
  )

  const getSymbol = useCallback(() => {
    return getApi().getSymbol()
  }, [getApi])

  const showChartSettings = useCallback(() => {
    getApi().executeCommand({ name: 'show-chart-settings' })
  }, [getApi])

  const takeSnapshot = useCallback(() => {
    getApi().executeCommand({ name: 'take-snapshot' })
  }, [getApi])

  const setChartType = useCallback(
    (value: number) => {
      getApi().executeCommand({
        name: 'set-chart-type',
        args: { chartType: value },
      })
    },
    [getApi],
  )

  const setInterval = useCallback((value: X10Interval) => {
    useInternalStore.setState({ interval: value })
    useGlobalSettingsStore.getState().setChartInterval(value)
  }, [])

  const setPriceScaleMode = useCallback((value: TradingViewPriceScaleMode) => {
    useInternalStore.setState({ priceScaleMode: value })
  }, [])

  const setPriceScaleModeAuto = useCallback((value: boolean) => {
    useInternalStore.setState({ priceScaleModeAuto: value })
  }, [])

  const setPriceSource = useCallback((value: CandlePriceSource) => {
    useInternalStore.setState({ priceSource: value })
  }, [])

  const setTimeframe = useCallback((value: X10Timeframe) => {
    const interval = DEFAULT_TIMEFRAME_INTERVAL[value]

    useInternalStore.setState({ timeframe: value, interval })
    useGlobalSettingsStore.getState().setChartInterval(interval)
  }, [])

  const setTimezone = useCallback((value: TimezoneId) => {
    const currState = useInternalStore.getState()
    const timezone = currState.timezones.find((tz) => tz.id === value)

    useInternalStore.setState({
      timezone: checkRequired(timezone, 'timezone'),
    })
  }, [])

  useEffect(() => {
    return useInternalStore.subscribe((currState, prevState) => {
      if (!apiRef.current) {
        return
      }

      if (currState.timeframe && currState.timeframe !== prevState.timeframe) {
        getApi().executeCommand({
          name: 'set-range',
          args: {
            range: {
              val: toPeriodBack(currState.timeframe),
              res: toResolutionString(currState.interval),
            },
          },
        })
      } else if (currState.interval !== prevState.interval) {
        const timeframe = DEFAULT_INTERVAL_TIMEFRAME[currState.interval]

        apiRef.current.executeCommand({
          name: 'set-range',
          args: {
            range: {
              val: toPeriodBack(timeframe),
              res: toResolutionString(currState.interval),
            },
          },
        })
      } else if (currState.priceScaleMode !== prevState.priceScaleMode) {
        getApi().executeCommand({
          name: 'set-price-scale-mode',
          args: { mode: currState.priceScaleMode, auto: undefined },
        })
      } else if (currState.priceScaleModeAuto !== prevState.priceScaleModeAuto) {
        getApi().executeCommand({
          name: 'set-price-scale-mode',
          args: { mode: undefined, auto: currState.priceScaleModeAuto },
        })
      } else if (currState.timezone !== prevState.timezone) {
        getApi().executeCommand({
          name: 'set-timezone',
          args: { timezoneId: checkRequired(currState.timezone, 'timezone').id },
        })
      }
    })
  }, [getApi])

  return useMemo(
    () => ({
      connect,

      addIndicator,
      compareOrAddSymbol,
      getSymbol,
      showChartSettings,
      takeSnapshot,

      setChartType,
      setInterval,
      setPriceScaleMode,
      setPriceScaleModeAuto,
      setPriceSource,
      setTimeframe,
      setTimezone,
    }),
    [
      connect,

      addIndicator,
      compareOrAddSymbol,
      getSymbol,
      showChartSettings,
      takeSnapshot,

      setChartType,
      setInterval,
      setPriceScaleMode,
      setPriceScaleModeAuto,
      setPriceSource,
      setTimeframe,
      setTimezone,
    ],
  )
}
