import { Suspense, type FC } from 'react'

import { Box, type HTMLStyledProps } from '@x10/lib-styled-system/jsx'

import { StartTradingButton } from '@src/domain/auth/components/start-trading-button'
import { useSuspendedIsLoggedIn } from '@src/domain/auth/hooks/use-auth'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { PlaceOrderButtons } from './place-order-buttons'

type OrderFormActionButtonsProps = HTMLStyledProps<'div'>

export const ActionButtons: FC<OrderFormActionButtonsProps> = (props) => {
  const isLoggedIn = useSuspendedIsLoggedIn()

  return (
    <TradeWidget.Actions {...props}>
      {isLoggedIn ? (
        <Suspense>
          <PlaceOrderButtons />
        </Suspense>
      ) : (
        <Box css={{ p: 's-16' }}>
          <StartTradingButton />
        </Box>
      )}
    </TradeWidget.Actions>
  )
}
