import { useMemo, type FC, type PropsWithChildren } from 'react'

import { CRYPTO_CURRENCY_INFO } from '@x10/lib-core/config'
import { FormattedMessage } from '@x10/lib-core/i18n'
import { Decimal } from '@x10/lib-core/utils'
import { HStack, styled } from '@x10/lib-styled-system/jsx'
import { Popover, Portal, Scrollable, SvgIcon } from '@x10/lib-ui-kit/components'
import { rangeChain } from '@x10/lib-ui-kit/utils'

import {
  LeveragePrecision,
  useFormatLeverage,
} from '@src/domain/core/hooks/use-format-leverage'
import {
  AssetPrecision,
  useFormatMarketAsset,
} from '@src/domain/core/hooks/use-format-market-asset'
import {
  PercentPrecision,
  useFormatPercent,
} from '@src/domain/core/hooks/use-format-percent'
import { NavigationLink } from '@src/domain/core/ui/components/navigation-link'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import { useSelectedMarket } from '@src/domain/trade/store/market'

const HeaderCell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <styled.th
      css={{
        color: 'token.white-50',
        textAlign: 'left',
        textStyle: 'caption',
        fontWeight: 'fw-500',
        pr: 's-64',
        pb: 's-24',
        _last: { pr: 0 },
      }}
    >
      {children}
    </styled.th>
  )
}

const DataCell: FC<PropsWithChildren> = ({ children }) => {
  return (
    <styled.td css={{ pb: 's-24', pr: 's-64', _last: { pr: 0 } }}>{children}</styled.td>
  )
}

// Prevents max leverage from being non integer (e.g. for COMP-USD it is 15.38)
// Also see `AdjustLeveragePopoverContent` for the same logic
const patchMaxLeverageAndRiskFactor = (riskFactor: Decimal, idx: number) => {
  const maxLeverage = Decimal.ONE.div(riskFactor).setDecimalPlaces(
    idx === 0 ? 0 : LeveragePrecision.MarginSchedule,
    Decimal.ROUND_DOWN,
  )

  return {
    maxLeverage,
    riskFactor: idx === 0 ? Decimal.ONE.div(maxLeverage).setDecimalPlaces(3) : riskFactor,
  }
}

const calcMaintenanceMargin = (riskFactor: Decimal) => {
  return riskFactor.div(2).setDecimalPlaces(3, Decimal.ROUND_DOWN)
}

export const MarginSchedule: FC = () => {
  const market = useSelectedMarket()
  const {
    tradingConfig: { riskFactorConfig },
  } = market

  const formatMarketAsset = useFormatMarketAsset()
  const formatPercent = useFormatPercent()
  const formatLeverage = useFormatLeverage()

  const data = useMemo(() => {
    let prevLowerBound = Decimal.ZERO

    return riskFactorConfig.map((item, idx) => {
      const { maxLeverage, riskFactor } = patchMaxLeverageAndRiskFactor(
        item.riskFactor,
        idx,
      )

      const result = {
        tier: idx + 1,
        lowerBound: prevLowerBound,
        upperBound: item.upperBound,
        maxLeverage,
        minInitialMargin: riskFactor,
        maintenanceMargin: calcMaintenanceMargin(riskFactor),
      }

      prevLowerBound = item.upperBound.plus(1)

      return result
    })
  }, [riskFactorConfig])

  return (
    <Popover.Root
      portalled
      lazyMount
      unmountOnExit
      positioning={{ placement: 'bottom-start' }}
    >
      <Popover.Context>
        {({ open }) => {
          return (
            <>
              <Popover.Trigger asChild>
                <HStack
                  css={{
                    gap: 's-8',
                    cursor: 'pointer',
                    textStyle: 'primary',
                    color: open ? 'token.white' : 'token.white-50',
                    _hover: { color: 'token.white' },
                  }}
                >
                  <FormattedMessage
                    id="workspace.trade.widget.chart.margin-schedule.title"
                    defaultMessage="Margin Schedule"
                  />
                  <SvgIcon.SvgIconInfoCircle size={16} />
                </HStack>
              </Popover.Trigger>

              <Portal>
                <Popover.Positioner>
                  <Popover.Content css={{ borderRadius: 'r-16', p: 's-16', w: 'unset!' }}>
                    <HStack
                      css={{
                        textStyle: 'primary',
                        mb: 's-24',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        <FormattedMessage
                          id="workspace.trade.widget.chart.margin-schedule.title"
                          defaultMessage="Margin Schedule"
                        />
                      </div>

                      <NavigationLink
                        url={asExternalRoute(
                          documentationRoute({}).trading({}).marginSchedule({}).$,
                        )}
                        openInNewTab
                      >
                        <FormattedMessage
                          id="common.link.documentation"
                          defaultMessage="Documentation"
                        />
                      </NavigationLink>
                    </HStack>

                    <Scrollable scrollY css={{ maxH: '52vh' }}>
                      <styled.table css={{ textStyle: 'small' }}>
                        <styled.thead
                          css={{
                            position: 'sticky',
                            top: 0,
                            bg: 'token.grey-90',
                          }}
                        >
                          <tr>
                            <HeaderCell>
                              <FormattedMessage
                                id="workspace.trade.widget.chart.margin-schedule.column.tier.title"
                                defaultMessage="Tier"
                              />
                            </HeaderCell>
                            <HeaderCell>
                              <FormattedMessage
                                id="workspace.trade.widget.chart.margin-schedule.column.position-bracket.title"
                                defaultMessage="Position Bracket, {symbol}"
                                values={{
                                  symbol: CRYPTO_CURRENCY_INFO['USD'].code,
                                }}
                              />
                            </HeaderCell>
                            <HeaderCell>
                              <FormattedMessage
                                id="workspace.trade.widget.chart.margin-schedule.column.max-leverage.title"
                                defaultMessage="Max Leverage"
                              />
                            </HeaderCell>
                            <HeaderCell>
                              <FormattedMessage
                                id="workspace.trade.widget.chart.margin-schedule.column.min-initial-margin.title"
                                defaultMessage="Min Initial Margin"
                              />
                            </HeaderCell>
                            <HeaderCell>
                              <FormattedMessage
                                id="workspace.trade.widget.chart.margin-schedule.column.maintenance-margin.title"
                                defaultMessage="Maintenance Margin"
                              />
                            </HeaderCell>
                          </tr>
                        </styled.thead>

                        <tbody>
                          {data.map((item) => {
                            const lowerBound = formatMarketAsset(
                              { amount: item.lowerBound, type: 'collateral' },
                              {
                                precision: AssetPrecision.MarginSchedule,
                              },
                            )
                            const upperBound = formatMarketAsset(
                              { amount: item.upperBound, type: 'collateral' },
                              {
                                precision: AssetPrecision.MarginSchedule,
                              },
                            )

                            return (
                              <styled.tr
                                key={item.tier}
                                css={{ _last: { '& > td': { pb: 0 } } }}
                              >
                                <DataCell>{item.tier}</DataCell>
                                <DataCell>
                                  {item.tier === data.length
                                    ? `> ${lowerBound}`
                                    : rangeChain(lowerBound, upperBound)}
                                </DataCell>
                                <DataCell>
                                  {formatLeverage(item.maxLeverage, {
                                    precision: LeveragePrecision.MarginSchedule,
                                  })}
                                </DataCell>
                                <DataCell>
                                  {formatPercent(item.minInitialMargin, {
                                    precision: PercentPrecision.MarginSchedule,
                                  })}
                                </DataCell>
                                <DataCell>
                                  {formatPercent(item.maintenanceMargin, {
                                    precision: PercentPrecision.MarginSchedule,
                                  })}
                                </DataCell>
                              </styled.tr>
                            )
                          })}
                        </tbody>
                      </styled.table>
                    </Scrollable>
                  </Popover.Content>
                </Popover.Positioner>
              </Portal>
            </>
          )
        }}
      </Popover.Context>
    </Popover.Root>
  )
}
