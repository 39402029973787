import { useEffect, useRef, useState, type FC } from 'react'

import { useLatestRef } from '@x10/lib-core/hooks'
import type { MarketAssets, MarketAssetType } from '@x10/lib-core/types'
import { Decimal, getLogger } from '@x10/lib-core/utils'
import { Box } from '@x10/lib-styled-system/jsx'
import { InputMessage, Slider, TransitionCollapse } from '@x10/lib-ui-kit/components'
import { useOutsideClick } from '@x10/lib-ui-kit/hooks'

import { useFormatPercent } from '@src/domain/core/hooks/use-format-percent'
import { useIsFeatureEnabled } from '@src/domain/core/hooks/use-is-feature-enabled'
import { MoneyInput } from '@src/domain/trade/components/money-input'
import { useNewOrderStore } from '@src/domain/trade/ui/widgets/order-form/store/new-order'
import { useNewOrderMaxValueStore } from '@src/domain/trade/ui/widgets/order-form/store/new-order-max-value'
import { calcOrderSizeBasedOnMaxValuePct } from '@src/domain/trade/utils/calc/calc-order-size-based-on-max-value-pct'
import { getPlaceholderForPrecision } from '@src/domain/trade/utils/get-placeholder-for-precision'

import { calcMarketPrices, roundDownToSyntheticPrecision } from './calc'
import { PctOrderSize } from './pct-order-size'

const LOGGER = getLogger('trade.order-form.money-input-with-slider')

type MoneyInputWithSliderProps = {
  assets: MarketAssets
  syntheticPrice: Decimal | null
  marketPriceType?: boolean
  'aria-invalid'?: boolean
  message?: string
  onBlur?: () => void
  onSliderChange?: () => void
  onValueChange: (value: { buy: number; sell: number; pct?: number } | null) => void
}

export const MoneyInputWithSlider: FC<MoneyInputWithSliderProps> = ({
  assets,
  syntheticPrice,
  marketPriceType,
  'aria-invalid': ariaInvalid,
  message,
  onBlur,
  onSliderChange,
  onValueChange,
}) => {
  const formatPercent = useFormatPercent()
  const isFeatureEnabled = useIsFeatureEnabled()

  const defaultAmountOfSynthetic = useNewOrderStore(
    (state) => state.buy.amountOfSynthetic,
  )
  const defaultMaxValuePct = useNewOrderStore((state) => state.ui.amountOfSyntheticPct)

  const [syntheticValue, setSyntheticValue] = useState<Decimal | null>(
    () => defaultAmountOfSynthetic,
  )
  const [collateralValue, setCollateralValue] = useState<Decimal | null>(null)
  const [maxValuePct, setMaxValuePct] = useState<number | undefined>(
    () => defaultMaxValuePct,
  )
  const [orderQtyPctBased, setOrderQtyPctBased] = useState<{
    buy: Decimal
    sell: Decimal
  }>()
  const [isSliderVisible, setSliderVisible] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)
  const shouldRecalculateOnPriceChangeRef = useRef<MarketAssetType>('collateral')
  const latestSyntheticValueRef = useLatestRef(syntheticValue)
  const latestCollateralValueRef = useLatestRef(collateralValue)

  const { orderMaxValue } = useNewOrderMaxValueStore()

  const isMaxValuePctSpecified = maxValuePct !== undefined

  // Recalculate values when the synthetic/collateral is provided by user
  useEffect(() => {
    if (isMaxValuePctSpecified) {
      return
    }

    const assetToRecalculate = shouldRecalculateOnPriceChangeRef.current

    if (assetToRecalculate === 'collateral') {
      if (latestSyntheticValueRef.current && syntheticPrice) {
        const nextCollateralValue = latestSyntheticValueRef.current.times(syntheticPrice)

        setCollateralValue(nextCollateralValue)
      } else {
        setCollateralValue(null)
      }
    } else {
      if (latestCollateralValueRef.current && syntheticPrice) {
        const nextSyntheticValue = roundDownToSyntheticPrecision(
          latestCollateralValueRef.current.div(syntheticPrice),
          assets.synthetic.precision,
        )

        setSyntheticValue(nextSyntheticValue)
        onValueChange({
          buy: nextSyntheticValue.toNumber(),
          sell: nextSyntheticValue.toNumber(),
        })
      } else {
        setSyntheticValue(null)
        onValueChange(null)
      }
    }

    setOrderQtyPctBased(undefined)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assets, syntheticPrice, isMaxValuePctSpecified, setOrderQtyPctBased, onValueChange])

  // Recalculate values when the synthetic/collateral is selected with slider
  useEffect(() => {
    if (!isMaxValuePctSpecified) {
      return
    }

    const orderPrices = marketPriceType
      ? calcMarketPrices(assets.collateral)
      : {
          buy: syntheticPrice,
          sell: syntheticPrice,
        }

    if (!orderMaxValue || !orderPrices.buy || !orderPrices.sell) {
      onValueChange({ buy: 0, sell: 0, pct: maxValuePct })

      return
    }

    const pct = Decimal(maxValuePct).div(100)
    const buyQty = calcOrderSizeBasedOnMaxValuePct({
      maxValue: orderMaxValue.buy,
      pct,
      orderPrice: orderPrices.buy,
      precision: assets.synthetic.precision,
    })
    const sellQty = calcOrderSizeBasedOnMaxValuePct({
      maxValue: orderMaxValue.sell,
      pct,
      orderPrice: orderPrices.sell,
      precision: assets.synthetic.precision,
    })

    if (isFeatureEnabled('DEBUG_INFO')) {
      LOGGER.debug('Slider: %o', {
        price: orderPrices,
        qty: {
          buy: buyQty,
          sell: sellQty,
        },
        pct,
        orderMaxValue,
      })
    }

    onValueChange({
      buy: buyQty.toNumber(),
      sell: sellQty.toNumber(),
      pct: maxValuePct,
    })
    setOrderQtyPctBased({ buy: buyQty, sell: sellQty })
  }, [
    assets,
    syntheticPrice,
    marketPriceType,
    maxValuePct,
    isMaxValuePctSpecified,
    orderMaxValue,
    isFeatureEnabled,
    setOrderQtyPctBased,
    onValueChange,
  ])

  useOutsideClick({
    ref: containerRef,
    handler: () => setSliderVisible(false),
  })

  const collateralPrecisionDp = assets.collateral.precision.getDecimalPlaces()
  const syntheticPrecisionDp = assets.synthetic.precision.getDecimalPlaces()

  return (
    <Box
      data-invalid={!isMaxValuePctSpecified && ariaInvalid}
      ref={containerRef}
      w="100%"
      className="group"
    >
      <MoneyInput.Group forceCompact>
        <MoneyInput.Item
          currency={assets.synthetic.code}
          precision={syntheticPrecisionDp}
          placeholder={getPlaceholderForPrecision(syntheticPrecisionDp)}
          value={Decimal.toNullableNumber(syntheticValue)}
          overlayValue={
            isMaxValuePctSpecified ? formatPercent(maxValuePct / 100) : undefined
          }
          unit={assets.synthetic.code}
          onBlur={onBlur}
          onFocus={() => {
            shouldRecalculateOnPriceChangeRef.current = 'collateral'

            if (isMaxValuePctSpecified) {
              setSyntheticValue(null)
              setCollateralValue(null)
              setMaxValuePct(undefined)

              onValueChange(null)
            }

            setSliderVisible(true)
          }}
          onChange={(value) => {
            const nextSyntheticValue = Decimal.toNullableDecimal(value)
            const nextCollateralValue =
              nextSyntheticValue !== null && syntheticPrice
                ? nextSyntheticValue.times(syntheticPrice)
                : null

            setSyntheticValue(nextSyntheticValue)
            setCollateralValue(nextCollateralValue)

            onValueChange(
              nextSyntheticValue
                ? {
                    buy: nextSyntheticValue.toNumber(),
                    sell: nextSyntheticValue.toNumber(),
                  }
                : null,
            )
          }}
        />
        <MoneyInput.Item
          currency={assets.collateral.code}
          precision={collateralPrecisionDp}
          placeholder={getPlaceholderForPrecision(collateralPrecisionDp)}
          value={Decimal.toNullableNumber(collateralValue)}
          overlayValue={
            isMaxValuePctSpecified ? formatPercent(maxValuePct / 100) : undefined
          }
          unit={assets.collateral.code}
          onBlur={onBlur}
          onFocus={() => {
            shouldRecalculateOnPriceChangeRef.current = 'synthetic'

            if (isMaxValuePctSpecified) {
              setSyntheticValue(null)
              setCollateralValue(null)
              setMaxValuePct(undefined)

              onValueChange(null)
            }

            setSliderVisible(true)
          }}
          onChange={(value) => {
            const nextCollateralValue = Decimal.toNullableDecimal(value)
            const nextSyntheticValue =
              nextCollateralValue && syntheticPrice
                ? roundDownToSyntheticPrecision(
                    nextCollateralValue.div(syntheticPrice),
                    assets.synthetic.precision,
                  )
                : null

            setCollateralValue(nextCollateralValue)
            setSyntheticValue(nextSyntheticValue)

            onValueChange(
              nextSyntheticValue
                ? {
                    buy: nextSyntheticValue.toNumber(),
                    sell: nextSyntheticValue.toNumber(),
                  }
                : null,
            )
          }}
        />
      </MoneyInput.Group>

      {!isMaxValuePctSpecified && <InputMessage>{message}</InputMessage>}

      {!isSliderVisible && orderQtyPctBased && (
        <Box css={{ w: '100%', pt: 's-16' }}>
          {<PctOrderSize buy={orderQtyPctBased.buy} sell={orderQtyPctBased.sell} />}
        </Box>
      )}

      <TransitionCollapse open={isSliderVisible}>
        <Box css={{ w: '100%', pt: 's-16' }}>
          <PctOrderSize
            buy={orderQtyPctBased?.buy ?? Decimal.ZERO}
            sell={orderQtyPctBased?.sell ?? Decimal.ZERO}
          />

          <Slider
            minLabel="0"
            maxLabel="100%"
            value={isMaxValuePctSpecified ? maxValuePct : 0}
            onChange={({ value }) => {
              setSyntheticValue(null)
              setCollateralValue(null)
              setMaxValuePct(value)
              onSliderChange?.()
            }}
          />
        </Box>
      </TransitionCollapse>
    </Box>
  )
}
