export type MaskOptions = {
  keepOnStart: number
  keepOnEnd: number
  maskSymbols: string
}

export const DefaultMaskOptions = {
  keepOnStart: 5,
  keepOnEnd: 4,
  maskSymbols: '····',
}

export const maskString = (value: string, options?: Partial<MaskOptions>) => {
  const finalOptions = options
    ? {
        ...DefaultMaskOptions,
        ...options,
      }
    : DefaultMaskOptions
  return `${value.slice(0, finalOptions.keepOnStart)}${finalOptions.maskSymbols}${value.slice(-finalOptions.keepOnEnd)}`
}
