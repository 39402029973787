import { forwardRef, Suspense } from 'react'

import { css } from '@x10/lib-styled-system/css'
import { Box, type HTMLStyledProps } from '@x10/lib-styled-system/jsx'

import {
  FullScreenProvider,
  useFullScreenContext,
} from '@src/domain/trade/providers/full-screen-provider'

import { TradeWidgetErrorBoundary } from './trade-widget-error-boundary'

type TradeWidgetProps = {
  fullScreenDisabled?: boolean
} & HTMLStyledProps<'div'>

const TradeWidgetRoot = forwardRef<HTMLDivElement, Omit<TradeWidgetProps, 'id'>>(
  ({ children, fullScreenDisabled, ...restProps }, ref) => {
    const { fullScreen } = useFullScreenContext()

    return (
      <Box
        data-scope="trade-widget"
        data-part="root"
        ref={ref}
        {...restProps}
        css={css.raw(
          {
            position: 'relative',
            bg: 'token.grey',
            borderRadius: 'r-16',
            overflow: 'hidden',
            w: { base: '100%', md: 'auto' },
          },
          fullScreen &&
            !fullScreenDisabled && {
              position: 'fixed',
              top: 0,
              left: 0,
              borderRadius: 0,
              h: '100vh',
              w: '100vw',
              zIndex: 'token.fullscreen',
            },
          restProps.css,
        )}
      >
        <TradeWidgetErrorBoundary>
          <Suspense fallback={<Box css={{ h: '100%', w: '100%' }} />}>
            {children}
          </Suspense>
        </TradeWidgetErrorBoundary>
      </Box>
    )
  },
)

TradeWidgetRoot.displayName = 'TradeWidgetRoot'

export const TradeWidgetRootProviders = forwardRef<HTMLDivElement, TradeWidgetProps>(
  ({ children, ...restProps }, ref) => {
    return (
      <FullScreenProvider>
        <TradeWidgetRoot ref={ref} {...restProps}>
          {children}
        </TradeWidgetRoot>
      </FullScreenProvider>
    )
  },
)

TradeWidgetRootProviders.displayName = 'TradeWidgetRootProviders'
