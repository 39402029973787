import { Suspense, type PropsWithChildren } from 'react'

import { FormattedMessage } from '@x10/lib-core/i18n'
import { Flex, HStack, VStack } from '@x10/lib-styled-system/jsx'
import {
  ActionIcon,
  Button,
  Dialog,
  GreenDot,
  Menu,
  NeedHelpLink,
  Portal,
  Skeleton,
  SvgIcon,
} from '@x10/lib-ui-kit/components'

import { useSuspendedBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import { DialogContent } from '@src/domain/core/ui/components/dialog/dialog-content'
import { asExternalRoute, documentationRoute } from '@src/domain/core/utils/routes'
import {
  useWithdrawalState,
  useWithdrawalStore,
} from '@src/domain/trade/hooks/use-withdrawal-state'

import { WithdrawDialog } from './withdraw-dialog'

const WithdrawDialogStaticContent = ({ children }: PropsWithChildren) => {
  return (
    <Portal>
      <Dialog.Backdrop />
      <Dialog.Positioner>
        <DialogContent>
          <Flex
            css={{
              textStyle: 'primary',
              w: '100%',
              justifyContent: 'space-between',
              pr: 's-32',
            }}
          >
            <Dialog.Title>
              <FormattedMessage
                id="domain.trade.dialog.withdraw.title"
                defaultMessage="Withdraw"
              />
            </Dialog.Title>

            <NeedHelpLink
              href={asExternalRoute(
                documentationRoute({}).accountOperations({}).withdrawals({}).$,
              )}
            />
          </Flex>
          <Dialog.CloseTrigger asChild>
            <ActionIcon
              css={{
                top: 's-16',
                right: 's-16',
                pos: 'absolute',
              }}
            >
              <SvgIcon.SvgIconCross />
            </ActionIcon>
          </Dialog.CloseTrigger>
          <Suspense
            fallback={
              <VStack
                css={{
                  gap: 's-32',
                  mt: 's-16',
                }}
              >
                <Skeleton
                  css={{
                    h: '27.4375rem',
                    w: '100%',
                    borderRadius: 'r-12',
                  }}
                />
                <Skeleton
                  css={{
                    h: '3.25rem',
                    w: '100%',
                    borderRadius: 'r-16',
                  }}
                />
              </VStack>
            }
          >
            {children}
          </Suspense>
        </DialogContent>
      </Dialog.Positioner>
    </Portal>
  )
}

export const WithdrawButton = () => {
  const { isPendingWithdrawalBalanceLoading, readyForClaimWithdrawals, withdraw } =
    useWithdrawalState()
  const isWritingContractPending = useWithdrawalStore(
    (state) => state.isWritingContractPending,
  )
  const isPendingWithdrawalAvailable = useWithdrawalStore(
    (state) => state.isPendingWithdrawalAvailable,
  )
  const balanceResponse = useSuspendedBalance()
  const hasZeroBalance = balanceResponse.equity.eq(0)

  if (hasZeroBalance) {
    return null
  }

  if (!isPendingWithdrawalAvailable) {
    return (
      <Dialog.Root modal lazyMount unmountOnExit>
        <Dialog.Trigger asChild>
          <Button
            css={{ w: '100%' }}
            visual="secondary-grey"
            loading={isPendingWithdrawalBalanceLoading}
          >
            <FormattedMessage
              id="workspace.trade.widget.account.action.withdraw.title"
              defaultMessage="Withdraw"
            />
          </Button>
        </Dialog.Trigger>
        <WithdrawDialogStaticContent>
          <WithdrawDialog />
        </WithdrawDialogStaticContent>
      </Dialog.Root>
    )
  }
  return (
    <Dialog.Root modal lazyMount unmountOnExit>
      <Menu.Root>
        <Menu.Trigger asChild>
          <Button
            css={{ w: '100%' }}
            visual="secondary-grey"
            loading={isPendingWithdrawalBalanceLoading}
          >
            <FormattedMessage
              id="workspace.trade.widget.account.action.withdraw.title"
              defaultMessage="Withdraw"
            />
            <Menu.Context>
              {({ open }) =>
                !open && (
                  <GreenDot
                    css={{
                      position: 'absolute',
                      top: '-0.5rem',
                      right: '0',
                    }}
                  >
                    {readyForClaimWithdrawals.length > 0
                      ? readyForClaimWithdrawals.length
                      : ''}
                  </GreenDot>
                )
              }
            </Menu.Context>
          </Button>
        </Menu.Trigger>
        <Portal>
          <Menu.Context>
            {({ setOpen }) => (
              <Menu.Positioner>
                <Menu.Content css={{ width: '19.5rem' }}>
                  <Dialog.Trigger asChild>
                    <Menu.Item
                      css={{ px: 's-16!', py: 's-12!' }}
                      disabled={isWritingContractPending}
                      value="open-dialog"
                      onClick={() => setOpen(false)}
                    >
                      <FormattedMessage
                        id="workspace.trade.widget.account.action.menu.withdraw.title"
                        defaultMessage="New Withdrawal"
                      />
                    </Menu.Item>
                  </Dialog.Trigger>
                  <Menu.Item
                    asChild
                    css={{ px: 's-16!', py: 's-12!' }}
                    value="withdraw-pending"
                    disabled={isWritingContractPending}
                    onClick={() =>
                      withdraw().then(() => {
                        setOpen(false)
                      })
                    }
                  >
                    <HStack css={{ justifyContent: 'space-between' }}>
                      <FormattedMessage
                        id="workspace.trade.widget.account.action.menu.claim-slow-withdrawal.title"
                        defaultMessage="Claim Slow Withdrawal(s)"
                      />
                      <GreenDot>
                        {readyForClaimWithdrawals.length > 0
                          ? readyForClaimWithdrawals.length
                          : ''}
                      </GreenDot>
                    </HStack>
                  </Menu.Item>
                </Menu.Content>
              </Menu.Positioner>
            )}
          </Menu.Context>
        </Portal>
      </Menu.Root>
      <WithdrawDialogStaticContent>
        <WithdrawDialog />
      </WithdrawDialogStaticContent>
    </Dialog.Root>
  )
}
