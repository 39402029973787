import { type FC } from 'react'

import { useCountdown } from '@src/domain/core/hooks/use-countdown'

type Props = {
  nextFundingRateTimestamp: number
}

export const NextFundingRateCountdown: FC<Props> = ({ nextFundingRateTimestamp }) => {
  const countdown = useCountdown({
    targetDate: nextFundingRateTimestamp,
    hoursDisplay: 'except-zero',
  })

  return <div>{countdown}</div>
}
