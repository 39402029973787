import { useMutation, useQueryClient } from '@tanstack/react-query'

import { removeRestEnvelopeSuspense } from '@src/domain/api/utils/remove-rest-envelope'
import { API } from '@src/domain/api/x10'
import type { Claim } from '@src/domain/api/x10/trading/account/claim-funds.schema'
import { useAccountId } from '@src/domain/auth/hooks/use-auth'
import { QueryKey } from '@src/domain/trade/constants'

export const useClaimFunds = () => {
  const queryClient = useQueryClient()
  const accountId = useAccountId()
  return useMutation({
    mutationFn: async () => {
      const claim = removeRestEnvelopeSuspense(
        await API.trading.account.claimFunds(accountId),
      )
      return new Promise((resolve, reject) => {
        let result: Claim | undefined
        // Making polling inside the mutation function to keep isPending "true" till the claim of the funds is done.
        // Claiming is very fast process and takes no longer than 3-5 seconds, so we can afford to do polling
        // inside the mutation function.
        const interval = window.setInterval(async () => {
          result = removeRestEnvelopeSuspense(
            await API.trading.account.getClaimById({
              id: claim.id.toString(),
            }),
          )
          if (result.status === 'PROCESSED') {
            window.clearInterval(interval)
            window.clearTimeout(timeout)
            return resolve(result)
          }
          if (result.status === 'REJECTED') {
            window.clearInterval(interval)
            window.clearTimeout(timeout)
            return reject(new Error('Claim funds rejected'))
          }
        }, 1000)
        const timeout = setTimeout(() => {
          window.clearInterval(interval)
          reject(new Error('Claim funds failed due to timeout. Please try again.'))
        }, 15000)
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QueryKey.UserNextClaim] })
    },
  })
}
