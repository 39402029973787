import { Suspense } from 'react'
import { isEmpty } from 'lodash'

import { Box, HStack } from '@x10/lib-styled-system/jsx'

import { useMarketsStats } from '@src/domain/api/hooks/markets-info/use-markets-stats'
import { CryptoLogo } from '@src/domain/core/ui/components/crypto-logo'
import { parseMarketName } from '@src/domain/core/utils/parse-market-name'
import { PINNED_MARKETS_LIMIT } from '@src/domain/trade/constants'
import { usePinnedMarkets } from '@src/domain/trade/hooks/use-pinned-markets'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

import { MarketSelect } from './components/market-select'
import { AddPinnedMarketButton, PinnedMarket } from './components/pinned-market'

export const MarketSelector = () => {
  const market = useSelectedMarket()
  const { pinnedMarkets, selectedMarketIndex } = usePinnedMarkets()

  const { data: marketsStats } = useMarketsStats({ marketsNames: pinnedMarkets })

  return (
    <TradeWidget.InnerRoot css={{ px: 's-16' }}>
      <Box css={{ display: 'flex', h: '100%' }}>
        <HStack css={{ gap: 's-16' }}>
          {!isEmpty(pinnedMarkets) ? (
            pinnedMarkets.map((marketName, index) => {
              const key = `marketName_${index}` // index is required as market names can repeat
              const { syntheticCode } = parseMarketName(marketName)
              const marketStats = marketsStats?.find(
                (value) => value.market === marketName,
              )?.stats

              return index === selectedMarketIndex ? (
                <MarketSelect
                  key={key}
                  marketName={marketName}
                  onMarketRowClick={(newMarket) => {
                    const pinnedMarketIndex = pinnedMarkets.findIndex(
                      (pinnedMarketName) => pinnedMarketName === newMarket.name,
                    )

                    if (pinnedMarketIndex > -1) {
                      usePinnedMarkets.setSelectedMarketIndex(pinnedMarketIndex)
                    } else {
                      usePinnedMarkets.replacePinnedMarket(newMarket.name, index)
                    }
                  }}
                />
              ) : (
                <Suspense key={key} fallback={<CryptoLogo currency={syntheticCode} />}>
                  <PinnedMarket
                    marketName={marketName}
                    marketStats={marketStats}
                    index={index}
                  />
                </Suspense>
              )
            })
          ) : (
            <MarketSelect marketName={market.name} />
          )}

          {pinnedMarkets.length < PINNED_MARKETS_LIMIT && <AddPinnedMarketButton />}
        </HStack>
      </Box>
    </TradeWidget.InnerRoot>
  )
}
