import { useCallback, useEffect, type FC } from 'react'

import { Decimal } from '@x10/lib-core/utils'
import { VStack } from '@x10/lib-styled-system/jsx'

import { LoggedInGuard } from '@src/domain/auth/components/logged-in-guard'
import { useLatestTradesStore } from '@src/domain/trade/store/latest-trades'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidgetContent } from '@src/domain/trade/ui/components/trade-widget'

import { useFormValidationWithCtx } from '../../hooks/use-form-validation'
import { NewOrderStoreActions } from '../../store/new-order'
import { ActionButtons } from '../action-buttons'
import { AdvancedSection } from '../advanced-section'
import { MoneyInputWithSlider } from '../money-input-with-slider'
import { OrderInfo } from '../order-info'
import { useUpdateMarketPrice } from './use-update-market-price'

export const MarketOrder: FC = () => {
  const market = useSelectedMarket()
  const { lastPrice } = useLatestTradesStore()

  const { form, orderSizeError } = useFormValidationWithCtx('MARKET')

  const handleQtyValueChange = useCallback(
    (value: { buy: number; sell: number; pct?: number } | null) => {
      const newBuyQty = Decimal.fromNullableValue(value?.buy)
      const newSellQty = Decimal.fromNullableValue(value?.sell)

      NewOrderStoreActions.setAmountOfSynthetic(newBuyQty, newSellQty, value?.pct)
    },
    [],
  )

  useUpdateMarketPrice(market.name)

  useEffect(() => {
    form.reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [market.name, form.reset])

  return (
    <TradeWidgetContent>
      <VStack css={{ px: 's-16', gap: 's-16', alignItems: 'start' }}>
        <MoneyInputWithSlider
          aria-invalid={Boolean(orderSizeError)}
          message={orderSizeError}
          assets={market.assets}
          syntheticPrice={lastPrice}
          marketPriceType
          onBlur={() => form.touch('orderSize')}
          onSliderChange={() => form.reset(['orderSize'])}
          onValueChange={handleQtyValueChange}
        />

        <OrderInfo marketName={market.name} />
        <LoggedInGuard>
          <AdvancedSection />
        </LoggedInGuard>
      </VStack>

      <ActionButtons hideBelow="md" />
    </TradeWidgetContent>
  )
}
