import { useMemo } from 'react'

import type { UserOrder } from '@src/domain/api/x10'
import type { OrderStatus } from '@src/domain/api/x10/common'

const CALC_STATUSES: OrderStatus[] = ['NEW', 'PARTIALLY_FILLED']

export const useFilterOrdersForCalc = (orders: UserOrder[]) => {
  return useMemo(() => {
    return orders.filter((order) => CALC_STATUSES.includes(order.status))
  }, [orders])
}
