import { useMemo, type FC } from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import { Box, Flex, HStack } from '@x10/lib-styled-system/jsx'
import { ActionLink } from '@x10/lib-ui-kit/components'

import type { X10Timeframe } from '@src/domain/api/x10/common'

import {
  useTradingViewChartMachineStore,
  type TradingViewChartMachineApi,
} from '../trading-view-chart/hooks/use-trading-view-chart'
import { TradingViewPriceScaleMode } from '../trading-view-chart/types/common'
import { TimezoneSelect } from './components/timezone-select'

type TradingViewChartToolbarProps = {
  machine: TradingViewChartMachineApi
}

const useRangesOptions = () => {
  const { formatMessage } = useIntl()

  return useMemo<Array<{ label: string; value: X10Timeframe }>>(() => {
    return [
      {
        label: formatMessage({
          id: 'common.range.timeframe.P1D.short',
          defaultMessage: '1D',
        }),
        value: 'P1D',
      },
      {
        label: formatMessage({
          id: 'common.range.timeframe.P5D.short',
          defaultMessage: '5D',
        }),
        value: 'P5D',
      },
      {
        label: formatMessage({
          id: 'common.range.timeframe.P1M.short',
          defaultMessage: '1M',
        }),
        value: 'P1M',
      },
      {
        label: formatMessage({
          id: 'common.range.timeframe.P3M.short',
          defaultMessage: '3M',
        }),
        value: 'P3M',
      },
      {
        label: formatMessage({
          id: 'common.range.timeframe.P6M.short',
          defaultMessage: '6M',
        }),
        value: 'P6M',
      },
    ]
  }, [formatMessage])
}

export const TradingViewChartBottomToolbar: FC<TradingViewChartToolbarProps> = ({
  machine,
}) => {
  const ranges = useRangesOptions()

  const currentTimeframe = useTradingViewChartMachineStore((state) => state.timeframe)
  const currentTimezone = useTradingViewChartMachineStore((state) => state.timezone)
  const currentPriceScaleModeAuto = useTradingViewChartMachineStore(
    (state) => state.priceScaleModeAuto,
  )
  const timezones = useTradingViewChartMachineStore((state) => state.timezones)

  return (
    <Flex
      hideBelow="md"
      css={{
        py: '0.3125rem',
        px: 's-16',
        justifyContent: 'space-between',
        color: 'token.white-50',
        fontSize: 'fs-14',
      }}
    >
      <HStack
        css={{
          gap: 's-16',
        }}
      >
        {ranges.map((range) => {
          return (
            <ActionLink
              key={range.value}
              data-active={currentTimeframe === range.value ? true : undefined}
              onClick={() => machine.setTimeframe(range.value)}
            >
              {range.label}
            </ActionLink>
          )
        })}
      </HStack>

      <HStack
        css={{
          gap: 's-16',
        }}
      >
        <TimezoneSelect
          value={currentTimezone}
          options={timezones}
          onValueChange={(valueId) => {
            machine.setTimezone(valueId)
          }}
        />

        <Box
          w="0.0625rem"
          h="1.5em"
          borderLeftWidth="0.0625rem"
          borderLeftColor="token.white-10"
        />

        <ActionLink
          onClick={() => {
            machine.setPriceScaleMode(TradingViewPriceScaleMode.Percentage)
          }}
        >
          %
        </ActionLink>
        <ActionLink
          onClick={() => {
            machine.setPriceScaleMode(TradingViewPriceScaleMode.Log)
          }}
        >
          log
        </ActionLink>
        <ActionLink
          onClick={() => {
            machine.setPriceScaleModeAuto(!currentPriceScaleModeAuto)
          }}
        >
          auto
        </ActionLink>
      </HStack>
    </Flex>
  )
}
