import { useCallback } from 'react'

import { useFormatDecimal } from '@x10/lib-core/i18n'
import type { Decimal } from '@x10/lib-core/utils'

import { USDC_BALANCE_PRECISION } from '@src/domain/core/config/static'

import { useGetCachedMarket } from './use-get-cached-market'

const BalancePrecision = {
  UserOrTradeMetric: USDC_BALANCE_PRECISION,
} as const

export const useFormatBalance = () => {
  const formatDecimal = useFormatDecimal()
  const getCachedMarket = useGetCachedMarket()

  return useCallback(
    (amount: number | Decimal | undefined) => {
      if (amount === undefined) {
        return undefined
      }

      const { assets } = getCachedMarket()

      const formattedValue = formatDecimal(amount, {
        minimumFractionDigits: BalancePrecision.UserOrTradeMetric,
        maximumFractionDigits: BalancePrecision.UserOrTradeMetric,
      })

      return `${formattedValue} ${assets.collateral.code}`
    },
    [formatDecimal, getCachedMarket],
  )
}
