import { Fragment, type FC } from 'react'

import { FormattedMessage, useFormatDateTime } from '@x10/lib-core/i18n'
import { getTimezoneOffset, getUserTimeZone } from '@x10/lib-core/utils'
import { Grid, GridItem } from '@x10/lib-styled-system/jsx'

import type { PublicTrade } from '@src/domain/api/x10/stream/public-trades.schema'
import { useFormatMarketAsset } from '@src/domain/core/hooks/use-format-market-asset'
import { OrderSideColored } from '@src/domain/core/ui/components/order-side-colored'
import { useSelectedMarket } from '@src/domain/trade/store/market'
import { TradeWidget } from '@src/domain/trade/ui/components/trade-widget'

type LatestTradesTableProps = {
  data: PublicTrade[]
}

const userTimeZone = getUserTimeZone()
const userTimezoneOffset = getTimezoneOffset(userTimeZone)

export const LatestTradesTable: FC<LatestTradesTableProps> = ({ data }) => {
  const formatMarketAsset = useFormatMarketAsset()
  const { formatTime } = useFormatDateTime()
  const market = useSelectedMarket()

  return (
    <TradeWidget.ContentFixed>
      <Grid columns={3} css={{ gap: 0, px: 's-16' }}>
        <GridItem mb="s-8" textStyle="caption">
          <FormattedMessage
            id="workspace.trade.widget.latest-trades.column.price.title"
            defaultMessage="Price"
          />
        </GridItem>
        <GridItem textAlign="center" textStyle="caption" px="s-16">
          <FormattedMessage
            id="workspace.trade.widget.latest-trades.column.size.title"
            defaultMessage="Size"
          />
        </GridItem>
        <GridItem textAlign="right" textStyle="caption">
          <FormattedMessage
            id="workspace.trade.widget.latest-trades.column.time.title"
            defaultMessage="Time"
          />
        </GridItem>

        <GridItem mb="s-8" textStyle="small">
          {market.assets.collateral.code}
        </GridItem>
        <GridItem textAlign="center" px="s-16" textStyle="small">
          {market.assets.synthetic.code}
        </GridItem>
        <GridItem textAlign="right" textStyle="small">
          UTC{userTimezoneOffset.valueString}
        </GridItem>
      </Grid>

      <TradeWidget.ContentScrollable>
        <Grid columns={3} css={{ gap: 0, px: 's-16' }}>
          {data.map((value, index) => {
            return (
              <Fragment key={index}>
                <GridItem textStyle="small" py="s-2">
                  <OrderSideColored side={value.side}>
                    {formatMarketAsset({
                      amount: value.price,
                      type: 'collateral',
                    })}
                  </OrderSideColored>
                </GridItem>

                <GridItem textAlign="center" textStyle="small" py="s-2" px="s-16">
                  {formatMarketAsset({
                    amount: value.qty,
                    type: 'synthetic',
                  })}
                </GridItem>

                <GridItem textAlign="right" textStyle="small" py="s-2">
                  {formatTime(value.timestamp, {
                    hourCycle: 'h24',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    timeZone: userTimeZone,
                  })}
                </GridItem>
              </Fragment>
            )
          })}
        </Grid>
      </TradeWidget.ContentScrollable>
    </TradeWidget.ContentFixed>
  )
}
