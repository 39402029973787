import { differenceInCalendarDays } from 'date-fns'

import { notReachable } from '@x10/lib-core/utils'
import { FilterPeriodValue } from '@x10/lib-ui-kit/components'

const getDataDaysAmount = (oldestDateTimestamp: number) =>
  differenceInCalendarDays(Date.now(), oldestDateTimestamp) + 1

const DEFAULT_COLUMN_TICKS = 1

export const getChartColumnTicks = (
  period: FilterPeriodValue,
  oldestDateTimestamp: number | undefined,
  maxTicks: number = 16,
) => {
  switch (period) {
    case FilterPeriodValue.OneDay:
      return 8
    case FilterPeriodValue.OneWeek:
      return oldestDateTimestamp
        ? getDataDaysAmount(oldestDateTimestamp)
        : DEFAULT_COLUMN_TICKS
    case FilterPeriodValue.OneMonth:
      const daysAmount = oldestDateTimestamp
        ? getDataDaysAmount(oldestDateTimestamp)
        : DEFAULT_COLUMN_TICKS
      return daysAmount > maxTicks ? Math.floor(daysAmount / 2) : daysAmount
    default:
      throw notReachable(period)
  }
}
