import { useAccount } from 'wagmi'

import { useSettings } from '@src/domain/api/hooks/info/use-settings'
import { useReadContract } from '@src/domain/core/hooks/use-read-contract'
import { useSelectedMarket } from '@src/domain/trade/store/market'

import { starkexAbi } from '../abi/starkex'

export const usePendingWithdrawalBalance = () => {
  const { address } = useAccount()
  const market = useSelectedMarket()
  const { starkExContractAddress } = useSettings()

  return useReadContract({
    abi: starkexAbi,
    address: starkExContractAddress,
    functionName: 'getWithdrawalBalance',
    args: address ? [BigInt(address), BigInt(market.l2Config.collateralId)] : undefined,
    query: {
      enabled: Boolean(address),
      refetchInterval: 30_000,
    },
  })
}
