import { useEffect, useMemo, useRef, useState, type FC } from 'react'

import { useIntl } from '@x10/lib-core/i18n'
import { Decimal, invariant, roundToPrecision } from '@x10/lib-core/utils'
import { css } from '@x10/lib-styled-system/css'
import { Box } from '@x10/lib-styled-system/jsx'

import { useSuspendedBalance } from '@src/domain/api/hooks/account/use-balance/use-balance'
import type { MarketStats, UserOrder, UserPosition } from '@src/domain/api/x10'
import { useGetCachedMarket } from '@src/domain/core/hooks/use-get-cached-market'
import { getOppositePositionSide } from '@src/domain/starkex/utils/get-opposite-side'
import { toOrderSide } from '@src/domain/starkex/utils/to-order-side'
import { InputTooltip } from '@src/domain/trade/components/input-tooltip'
import { MoneyInput } from '@src/domain/trade/components/money-input'
import { useInputFloatingElement } from '@src/domain/trade/hooks/use-input-floating-element'
import { calcSignedSizeSum } from '@src/domain/trade/utils/calc/calc-signed-size-sum'
import { getSignedSize } from '@src/domain/trade/utils/calc/get-signed-size'
import { getValidationErrorText } from '@src/domain/trade/validation/get-validation-error-text'

import { useLimitOrderFormValidation } from '../../hooks/use-limit-order-form-validation'
import { LimitCloseActionButton } from './limit-close-action-button'
import { useLimitCloseFormContext } from './limit-close-form-provider'
import { LimitCloseFormQtySelector } from './limit-close-form-qty-selector'
import { LimitOrderCostCalc } from './limit-order-cost-calc'

const calcDefaultOrderSize = (position: UserPosition, reduceOnlyOrders: UserOrder[]) => {
  const ordersSize = calcSignedSizeSum(reduceOnlyOrders)

  return getSignedSize(position).abs().minus(ordersSize.abs()).maxZero()
}

type LimitCloseFormProps = {
  position: UserPosition
  triggeredOrders: UserOrder[]
  marketStats?: MarketStats
  loading?: boolean
  disabled?: boolean
  onConfirm: (orderSize: Decimal, orderPrice: Decimal) => Promise<void>
}

export const LimitCloseForm: FC<LimitCloseFormProps> = ({
  position,
  triggeredOrders,
  marketStats,
  loading,
  disabled,
  onConfirm,
}) => {
  const { formatMessage } = useIntl()
  const getCachedMarket = useGetCachedMarket()
  const balance = useSuspendedBalance()
  const orderCost = useLimitCloseFormContext((state) => state.orderCost)

  const [orderSize, setOrderSize] = useState<Decimal | null>(null)
  const [orderPrice, setOrderPrice] = useState<Decimal | null>(null)

  const reduceOnlyOrders = useMemo(
    () => triggeredOrders.filter((order) => order.reduceOnly),
    [triggeredOrders],
  )
  const lastPrice = marketStats?.lastPrice
  const shouldFollowTheLastPriceRef = useRef(true)
  const shouldFollowOrderSizeRef = useRef(true)

  useEffect(() => {
    if (shouldFollowTheLastPriceRef.current && lastPrice) {
      setOrderPrice(lastPrice)
    }
  }, [lastPrice, setOrderPrice])

  useEffect(() => {
    if (shouldFollowOrderSizeRef.current) {
      setOrderSize(calcDefaultOrderSize(position, reduceOnlyOrders))
    }
  }, [position, reduceOnlyOrders])

  const market = getCachedMarket(position.market)
  const collateralPrecisionDp = market.assets.collateral.precision.getDecimalPlaces()
  const syntheticPrecisionDp = market.assets.synthetic.precision.getDecimalPlaces()

  const validation = useLimitOrderFormValidation(market.name)
  const validationResult = useMemo(() => {
    const validationCtx = {
      orderType: 'LIMIT' as const,
      reduceOnly: true,
      market,
      marketStats,
      position,
      orders: triggeredOrders,
      balance,
    }

    return validation.validate(
      {
        orderSide: toOrderSide(getOppositePositionSide(position.side)),
        orderSize: orderSize,
        orderPrice: orderPrice,
        orderCost: orderCost ? { buy: orderCost, sell: orderCost } : null,
      },
      validationCtx,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderSize,
    orderPrice,
    orderCost,
    market,
    marketStats,
    position,
    triggeredOrders,
    balance,
    validation.validate,
  ])

  const handleLimitPositionClose = async () => {
    invariant(orderSize, '`orderSize` is required')
    invariant(orderPrice, '`orderPrice` is required')

    await onConfirm(orderSize, orderPrice)

    validation.reset()

    shouldFollowTheLastPriceRef.current = true
    shouldFollowOrderSizeRef.current = true
  }

  const handleQtySelected = (value: number) => {
    setOrderSize(
      roundToPrecision(
        position.size.abs().times(value),
        market.assets.synthetic.precision,
        Decimal.ROUND_DOWN,
      ),
    )
  }

  const orderPriceTooltip = useInputFloatingElement()
  const orderSizeTooltip = useInputFloatingElement()
  const qtyHover = useInputFloatingElement()

  const errors = !validationResult.success ? validationResult.errors : undefined
  const orderPriceError = errors?.orderPrice
  // Don't show order size error if it is a result of the default size calculation
  const orderSizeError = !shouldFollowOrderSizeRef.current ? errors?.orderSize : undefined
  const submitError = getValidationErrorText(errors)

  const canSubmitLimitOrder = orderSize && orderPrice && validationResult.success

  return (
    <>
      <LimitOrderCostCalc
        position={position}
        triggeredOrders={triggeredOrders}
        orderSize={orderSize}
        orderPrice={orderPrice}
        marketStats={marketStats}
      />

      <LimitCloseActionButton
        position={position}
        reduceOnlyOrders={reduceOnlyOrders}
        orderSize={orderSize}
        orderPrice={orderPrice}
        alerts={validationResult.success ? validationResult.alerts : undefined}
        error={submitError}
        loading={loading}
        disabled={disabled || !canSubmitLimitOrder}
        onConfirm={handleLimitPositionClose}
      />

      <Box w="10.625rem" position="relative">
        <InputTooltip
          open={orderPriceTooltip.isVisible}
          visual={orderPriceError ? 'error' : 'info'}
        >
          {orderPriceError ??
            formatMessage(
              {
                id: 'workspace.trade.widget.trading-history.mode.positions.limit-close-form.input.price.tooltip.title',
                defaultMessage: 'Price, {asset}',
              },
              { asset: market.assets.collateral.code },
            )}
        </InputTooltip>

        <InputTooltip
          open={orderSizeTooltip.isVisible}
          visual={orderSizeError ? 'error' : 'info'}
          positionerCss={css.raw({ ml: '5.25rem' })}
        >
          {orderSizeError ??
            formatMessage(
              {
                id: 'workspace.trade.widget.trading-history.mode.positions.limit-close-form.input.size.tooltip.title',
                defaultMessage: 'Size, {asset}',
              },
              { asset: market.assets.synthetic.code },
            )}
        </InputTooltip>

        <MoneyInput.Group size="small">
          <MoneyInput.Item
            aria-invalid={Boolean(orderPriceError)}
            currency={market.assets.collateral.code}
            precision={collateralPrecisionDp}
            placeholder={formatMessage({
              id: 'workspace.trade.widget.trading-history.mode.positions.limit-close-form.input.price.placeholder.title',
              defaultMessage: 'Price',
            })}
            value={Decimal.toNullableNumber(orderPrice)}
            onFocus={() => {
              shouldFollowTheLastPriceRef.current = false
            }}
            onBlur={() => {
              validation.touch('orderPrice')
            }}
            onMouseEnter={() => {
              orderPriceTooltip.show()
            }}
            onMouseLeave={() => {
              orderPriceTooltip.hide()
            }}
            onChange={(value) => {
              const nextCollateralValue = Decimal.toNullableDecimal(value)

              setOrderPrice(nextCollateralValue)
            }}
          />
          <MoneyInput.Item
            aria-invalid={Boolean(orderSizeError)}
            currency={market.assets.synthetic.code}
            precision={syntheticPrecisionDp}
            placeholder={formatMessage({
              id: 'workspace.trade.widget.trading-history.mode.positions.limit-close-form.input.size.placeholder.title',
              defaultMessage: 'Size',
            })}
            value={Decimal.toNullableNumber(orderSize)}
            onFocus={() => {
              shouldFollowOrderSizeRef.current = false
              qtyHover.show()
            }}
            onBlur={() => {
              validation.touch('orderSize')
              qtyHover.hide()
            }}
            onMouseEnter={() => {
              orderSizeTooltip.show()
            }}
            onMouseLeave={() => {
              orderSizeTooltip.hide()
            }}
            onChange={(value) => {
              const nextSyntheticValue = Decimal.toNullableDecimal(value)

              setOrderSize(nextSyntheticValue)
            }}
          />
        </MoneyInput.Group>

        <LimitCloseFormQtySelector
          open={qtyHover.isVisible}
          onSelect={handleQtySelected}
        />
      </Box>
    </>
  )
}
