import { type Computed, type ErrorMessage, type Rule } from '../types'

export const requireOrderSize: Rule<
  unknown,
  unknown,
  Pick<Computed, 'orderSize'>,
  Pick<ErrorMessage, 'getOrderSize'>
> = (_value, _ctx, computed, _alerts, errors, errorMessage) => {
  if (computed.orderSize === null) {
    errors.orderSize = errorMessage.getOrderSize()
  }
}
