import { useEffect, useState, type FC } from 'react'

import { Popover } from '@x10/lib-ui-kit/components'

import { useFullScreenContext } from '@src/domain/trade/providers/full-screen-provider'

export const MarketStatsPopoverRoot: FC<React.ComponentProps<typeof Popover.Root>> = (
  props,
) => {
  const { fullScreen } = useFullScreenContext()
  const [fullScreenWasEnabled, setFullScreenWasEnabled] = useState<boolean>(fullScreen) // preventing closing on chart full screen button click when chart is in modal responsive mode

  useEffect(() => {
    setFullScreenWasEnabled(fullScreen)
  }, [fullScreen])

  return (
    <Popover.Root
      portalled
      lazyMount
      unmountOnExit
      displayMode={fullScreen ? 'fullScreen' : 'default'}
      responsive={fullScreen ? 'fullScreen' : 'bottomSheet'}
      positioning={{ placement: 'bottom-start' }}
      closeOnInteractOutside={!fullScreenWasEnabled}
      onInteractOutside={() => {
        if (fullScreenWasEnabled) {
          setFullScreenWasEnabled(false)
        }
      }}
      {...props}
    />
  )
}
