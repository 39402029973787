import { type FC, type PropsWithChildren } from 'react'

import { notReachable } from '@x10/lib-core/utils'
import { Box } from '@x10/lib-styled-system/jsx'

import { type OrderSide } from '@src/domain/core/types/common'

type OrderSideColoredProps = PropsWithChildren<{
  side: OrderSide
}>

export const OrderSideColored: FC<OrderSideColoredProps> = ({ side, children }) => {
  switch (side) {
    case 'LONG':
    case 'BUY':
      return <Box color="token.green">{children}</Box>
    case 'SHORT':
    case 'SELL':
      return <Box color="token.red">{children}</Box>
    default:
      notReachable(side)
  }
}
