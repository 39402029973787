import { orderBy } from 'lodash'

import { checkRequired, Decimal } from '@x10/lib-core/utils'

import type { OrderSide } from '@src/domain/api/x10/common'
import { type AggOrderBookDataItem } from '@src/domain/core/types/trade'
import { roundOrderSizeToPrecision } from '@src/domain/core/utils/round-order-size-to-precision'

type AggOrderBookData = AggOrderBookDataItem[]

export const aggregateOrderBookData = (
  data: ReadonlyArray<{ qty: Decimal; price: Decimal }>,
  side: OrderSide,
  minAggregationPriceChange: Decimal,
) => {
  const sortedData = orderBy(
    data,
    (item) => item.price.toNumber(),
    side === 'SELL' ? 'asc' : 'desc',
  )
  const bestPrice = sortedData[0]?.price
  const aggregatedData = sortedData.map((item) => ({
    qty: item.qty,
    price: item.price,
    // This is the price that is used for grouping and will be displayed in the table
    aggregatePrice: roundOrderSizeToPrecision(
      side,
      item.price,
      minAggregationPriceChange,
    ),
  }))
  const rows: AggOrderBookData = []

  let priceAvgSum = Decimal(0)
  let qtySum = Decimal(0)
  let qtySumCollateral = Decimal(0)
  let prevAggregatePrice = Decimal(-1)

  for (const value of aggregatedData) {
    const qty = value.qty

    const sizeCollateral = value.price.times(qty)

    priceAvgSum = priceAvgSum.plus(sizeCollateral)
    qtySum = qtySum.plus(qty)
    qtySumCollateral = qtySumCollateral.plus(sizeCollateral)

    if (prevAggregatePrice.eq(value.aggregatePrice)) {
      const prevItem = checkRequired(rows[rows.length - 1], 'prevItem')

      prevItem.priceAvg = priceAvgSum.div(qtySum).toNumber()
      prevItem.size += qty.toNumber()
      prevItem.sizeCollateral += sizeCollateral.toNumber()
      prevItem.sizeSum = qtySum.toNumber()
      prevItem.sizeSumCollateral = qtySumCollateral.toNumber()
    } else {
      rows.push({
        price: value.aggregatePrice.toNumber(),
        priceAvg: priceAvgSum.div(qtySum).toNumber(),
        size: qty.toNumber(),
        sizeCollateral: sizeCollateral.toNumber(),
        sizeSum: qtySum.toNumber(),
        sizeSumCollateral: qtySumCollateral.toNumber(),
        sizeSumRatio: 0,
        side,
      })
    }

    prevAggregatePrice = value.aggregatePrice
  }

  for (const value of rows) {
    value.sizeSumRatio = (value.sizeSum / qtySum.toNumber()) * 100
  }

  return { bestPrice, rows }
}
