import { useEffect, useRef, useState, type FC } from 'react'
import { useDraggable } from '@dnd-kit/core'

import { FormattedMessage, useIntl } from '@x10/lib-core/i18n'
import { Flex, HStack, styled } from '@x10/lib-styled-system/jsx'
import { Button, Input, SvgIcon } from '@x10/lib-ui-kit/components'

type QuickTradingLimitProps = {
  dragId: string
  x: number
  y: number
  lastPrice: string | undefined
}

export const QuickTradingLimit: FC<QuickTradingLimitProps> = ({
  dragId,
  x,
  y,
  lastPrice,
}) => {
  const { formatMessage } = useIntl()

  const [hovered, setHovered] = useState(false)
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: dragId,
  })

  const shouldUpdateLastPriceValueRef = useRef(true)
  const [lastPriceValue, setLastPriceValue] = useState<string>('')

  useEffect(() => {
    if (shouldUpdateLastPriceValueRef.current && lastPrice) {
      setLastPriceValue(lastPrice)
    }
  }, [lastPrice])

  return (
    <HStack
      gap="s-4"
      position="absolute"
      style={{
        left: x,
        top: y,
        transform: transform
          ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
          : undefined,
      }}
      ref={setNodeRef}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <styled.button
        cursor="grabbing"
        alignSelf="stretch"
        visibility={hovered || isDragging ? 'visible' : 'hidden'}
        bg="token.grey"
        color="token.white-50"
        {...listeners}
        {...attributes}
      >
        <Flex
          bg="token.white-10"
          minW="1.25rem"
          h="100%"
          alignItems="center"
          justifyContent="center"
          borderRadius="r-8"
        >
          <SvgIcon.SvgIconDrag size={16} />
        </Flex>
      </styled.button>

      <HStack gap={0} bg="token.grey" borderRadius="r-16">
        <Button
          visual="primary-green"
          size="large"
          center={false}
          borderRightRadius={0}
          px="s-16"
          py="0.96875rem"
          textStyle="secondary"
          whiteSpace="nowrap"
          w="auto"
        >
          <FormattedMessage
            id="workspace.trade.quick-trading.mode.limit.action.buy.title"
            defaultMessage="Limit Buy"
          />
        </Button>

        <HStack gap="s-2">
          <Input
            visual="square"
            label={formatMessage({
              id: 'workspace.trade.quick-trading.mode.limit.input.amount.title',
              defaultMessage: 'Amount',
            })}
            placeholder="0"
            maxW="6.25rem"
          />
          <Input
            visual="square"
            label={formatMessage({
              id: 'workspace.trade.quick-trading.mode.limit.input.price.title',
              defaultMessage: 'Price',
            })}
            placeholder="0"
            maxW="6.25rem"
            value={lastPriceValue}
            onChange={(event) => setLastPriceValue(event.currentTarget.value)}
          />
        </HStack>

        <Button
          visual="primary-red"
          size="large"
          center={false}
          borderLeftRadius={0}
          px="s-16"
          py="0.96875rem"
          textStyle="secondary"
          whiteSpace="nowrap"
          w="auto"
        >
          <FormattedMessage
            id="workspace.trade.quick-trading.mode.limit.action.sell.title"
            defaultMessage="Limit Sell"
          />
        </Button>
      </HStack>
    </HStack>
  )
}
