import { Decimal } from '@x10/lib-core/utils'

export const calcOrderBookPriceImpact = ({
  price,
  midPrice,
}: {
  price: Decimal | number
  midPrice: Decimal | number
}) => {
  return Decimal(price).minus(midPrice).div(midPrice).abs()
}
