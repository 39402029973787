import { FormattedMessage } from '@x10/lib-core/i18n'
import type { Long } from '@x10/lib-core/utils'
import { Box, HStack } from '@x10/lib-styled-system/jsx'
import { ActionIcon, SvgIcon, TableCell } from '@x10/lib-ui-kit/components'

import type { UserOrder, UserPosition } from '@src/domain/api/x10'
import { useFilterTpSlOrders } from '@src/domain/trade/hooks/use-filter-tp-sl-orders'

import { TpSlValues } from '../../components/tp-sl-values'

export const PositionTpSlCell = ({
  position,
  orders,
  onTpSlEdit,
}: {
  position: UserPosition
  orders: UserOrder[]
  onTpSlEdit: (positionId: Long) => void
}) => {
  const tpSlOrders = useFilterTpSlOrders(orders)

  const firstOrder = tpSlOrders[0]
  const takeProfit = firstOrder?.takeProfit?.triggerPrice
  const stopLoss = firstOrder?.stopLoss?.triggerPrice

  return (
    <TableCell>
      <ActionIcon
        onClick={(e) => {
          e.stopPropagation()
          onTpSlEdit(position.id)
        }}
      >
        <HStack>
          <Box css={{ color: 'token.white', textAlign: 'left' }}>
            {tpSlOrders.length > 1 ? (
              <FormattedMessage
                id="workspace.trade.widget.trading-history.mode.positions.column.tp-sl.orders-count.title"
                defaultMessage="{count} items"
                values={{ count: tpSlOrders.length }}
              />
            ) : (
              <TpSlValues
                takeProfit={takeProfit}
                stopLoss={stopLoss}
                marketName={position.market}
              />
            )}
          </Box>

          <SvgIcon.SvgIconPencil size={16} />
        </HStack>
      </ActionIcon>
    </TableCell>
  )
}
