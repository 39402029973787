import { useCallback, type FC } from 'react'

import { useBreakpoints } from '@x10/lib-ui-kit/hooks'

import { TradingViewChart } from '../trading-view-chart'
import {
  TradingViewChartBottomToolbar,
  TradingViewChartTopToolbar,
} from '../trading-view-chart-toolbar'
import { useTradingViewChartMachineApi } from '../trading-view-chart/hooks/use-trading-view-chart'
import { type TradingViewChartApi } from '../trading-view-chart/utils/trading-view-chart-api'

export const MarketTabContent: FC = () => {
  const machine = useTradingViewChartMachineApi()

  const { md: breakpointMdMatched } = useBreakpoints()

  const handleApiReady = useCallback(
    (api: TradingViewChartApi) => {
      machine.connect(api)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [machine.connect],
  )

  return (
    <>
      <TradingViewChartTopToolbar machine={machine} />
      <TradingViewChart
        hideLeftToolbar={!breakpointMdMatched}
        onApiReady={handleApiReady}
      />
      <TradingViewChartBottomToolbar machine={machine} />
    </>
  )
}
