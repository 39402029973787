import { type FC } from 'react'

import { ActionIcon, SvgIcon } from '@x10/lib-ui-kit/components'

import { type X10Interval } from '@src/domain/api/x10/common'

import { useFavoriteIntervals } from '../hooks/use-favorite-intervals'

type FavoriteIntervalToggleProps = {
  value: X10Interval
}

export const FavoriteIntervalToggle: FC<FavoriteIntervalToggleProps> = ({ value }) => {
  const { favoriteIntervals, toggleFavoriteInterval } = useFavoriteIntervals()

  const isIncluded = favoriteIntervals.includes(value)
  return (
    <ActionIcon
      css={
        isIncluded
          ? undefined
          : {
              display: 'none',
              _groupHover: {
                display: 'block',
              },
            }
      }
      onClick={(e) => {
        e.stopPropagation()
        toggleFavoriteInterval(value)
      }}
    >
      {isIncluded ? (
        <SvgIcon.SvgIconStarFilled size={24} />
      ) : (
        <SvgIcon.SvgIconStarEmpty size={24} />
      )}
    </ActionIcon>
  )
}
