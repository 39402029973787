import { checkRequired, Decimal } from '@x10/lib-core/utils'

import type { OrderTpSlTriggerParam } from '@src/domain/starkex/stark-perpetual-order'
import type { OrderTriggerState } from '@src/domain/trade/types/common'

export const toOrderTpSlTriggerParam = (
  value: OrderTriggerState | undefined | null,
): OrderTpSlTriggerParam | undefined => {
  if (!value) {
    return undefined
  }

  return {
    triggerPrice: checkRequired(value.triggerPrice, 'triggerPrice'),
    triggerPriceType: value.triggerPriceType,
    price:
      value.priceType !== 'MARKET' ? checkRequired(value.price, 'price') : Decimal.ZERO,
    priceType: value.priceType,
  }
}
