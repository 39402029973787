import { invariant } from '@x10/lib-core/utils'

import { type Computed, type Context, type ErrorMessage, type Rule } from '../types'

/**
 * [50:Price Validations]
 * Order / Trigger Prices ≥ Min Price Change
 * (relevant for all order types apart from market)
 */
export const minOrderPrice: Rule<
  unknown,
  Pick<Context, 'market' | 'orderType'>,
  Pick<Computed, 'orderPrice'>,
  Pick<ErrorMessage, 'getOrderPriceMin'>
> = (_value, ctx, computed, _alerts, errors, errorMessage) => {
  if (errors.orderPrice || ctx.orderType === 'MARKET') {
    return
  }

  invariant(computed.orderPrice, '`orderPrice` is required')

  if (computed.orderPrice.lt(ctx.market.tradingConfig.minPriceChange)) {
    errors.orderPrice = errorMessage.getOrderPriceMin(
      ctx.market.tradingConfig.minPriceChange,
    )
  }
}
